import { createContext, useMemo, useState } from "react";

import { deleteSession } from "./utils/session";

export const AuthContext = createContext();

function Provider({ children }) {
  const [isAuth, setIsAuth] = useState(null);

  const value = useMemo(
    () => ({
      isAuth,
      userData: null,
      activateAuth: () => {
        setIsAuth(true);
      },
      removeAuth: () => {
        setIsAuth(false);
        deleteSession();
      },
    }),
    [isAuth],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { Provider, Consumer: AuthContext.Consumer };
