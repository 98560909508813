import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Typography, Divider, Avatar, ListItem, ListItemText, ListItemAvatar, IconButton, Box, Stack, Badge, useMediaQuery, Modal, useTheme, Button, Tooltip } from "@mui/material";
import { IconMenu2 } from "@tabler/icons-react";
import { formatDistanceToNowStrict } from "date-fns";
import { useParams } from "react-router";
import ChatInsideSidebar from "./ChatInsideSidebar";
import useQuery from "../../hooks/allQuery";
import { getTicket } from "../../gql/queries/ticket";
import DeleteConfirmation from "./DeleteConfirmation";
function ChatContent({ toggleChatSidebar, chatupdate }) {
  const [open, setOpen] = useState(true);
  const [currentImage, setCurrentImage] = useState(null);
  const [refreshChat, setRefreshChat] = useState(false);
  const theme = useTheme();
  const handleImageClick = imageurl => {
    setCurrentImage(imageurl);
  };
  const handleClose = () => {
    setCurrentImage(null);
  };

  const chatMsgWrapper = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "80%" },
    height: 600,
    bgcolor: theme.palette.background.default,
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    "& .closeIcon": {
      position: "absolute",
      top: "10px",
      right: "10px",
      background: theme.palette.primary.main,
      color: "#fff",
      fontWeight: "700",
      zIndex: 9999,
      minWidth: "30px",
      padding: "5px",
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
  };

  const lgUp = useMediaQuery(themes => themes?.breakpoints?.up("lg"));
  const [chatDetails, setChatDetails] = useState();
  const [TICKET_GET] = useLazyQuery(getTicket, { fetchPolicy: "cache-and-network" });
  const { allQueryObject } = useQuery();
  const { id } = useParams();
  const [isHovered, setIsHovered] = useState({});
  const handleMouseEnter = chatId => {
    setIsHovered(prevState => ({
      ...prevState,
      [chatId]: true,
    }));
  };

  const handleMouseLeave = chatId => {
    setIsHovered(prevState => ({
      ...prevState,
      [chatId]: false,
    }));
  };
  useEffect(() => {
    if (id) {
      setChatDetails();
      allQueryObject({
        QUERY: TICKET_GET,
        variables: {
          ticketFindUniqueId: id,
        },
        onCompleteFunc: ({ ticketFindUnique }) => setChatDetails(ticketFindUnique),
      });
    }
  }, [id, chatupdate, refreshChat]);
  return (
    <Box>
      {chatDetails ? (
        <Box>
          {/* ------------------------------------------- */}
          {/* Header Part */}
          {/* ------------------------------------------- */}
          <Box>
            <Box display="flex" alignItems="center" p={2}>
              <Box
                sx={{
                  display: { xs: "block", md: "block", lg: "none" },
                  mr: "10px",
                }}
              >
                <IconMenu2 stroke={1.5} onClick={toggleChatSidebar} />
              </Box>
              <ListItem key={chatDetails?.userId} dense disableGutters>
                <ListItemAvatar>
                  <Badge
                    color={chatDetails?.status === "online" ? "success" : chatDetails?.status === "busy" ? "error" : chatDetails?.status === "away" ? "warning" : "secondary"}
                    variant="dot"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    overlap="circular"
                  >
                    <Avatar alt={chatDetails.name} src={chatDetails?.thumb} />
                  </Badge>
                </ListItemAvatar>
                <ListItemText primary={<Typography variant="h5">{chatDetails.name}</Typography>} secondary={chatDetails.status} />
              </ListItem>
              <Stack direction="row">
                <Tooltip title="Refresh Chat" placement="top">
                  <IconButton aria-label="phone" onClick={() => setRefreshChat(!refreshChat)}>
                    <RefreshIcon stroke={1.5} />
                  </IconButton>
                </Tooltip>
                <IconButton aria-label="sidebar" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowDownIcon stroke={1.5} /> : <KeyboardArrowRightIcon stroke={1.5} />}
                </IconButton>
              </Stack>
            </Box>
            <Divider />
          </Box>
          {/* ------------------------------------------- */}
          {/* Chat Content */}
          {/* ------------------------------------------- */}

          <Box display="flex">
            {/* ------------------------------------------- */}
            {/* Chat msges */}
            {/* ------------------------------------------- */}

            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  height: "calc(100vh - 275px)",
                  // maxHeight: "800px",
                  overflow: "hidden",
                  overflowY: "auto",
                  "&::-webkit-scrollbar-track": {
                    // WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                    borderRadius: "10px",
                    backgroundColor: theme.palette.text.primary + "0d",
                  },
                  "&::-webkit-scrollbar": { width: "8px", backgroundColor: "transparent" },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "10px",
                    WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
              >
                <Box p={3}>
                  {chatDetails?.messages?.map(chat => (
                    <Box>
                      {!chat.isDeleted && (
                        <Box key={chat.id + chat.createdAt}>
                          {chatDetails.userId !== chat.sender ? (
                            <Box display="flex">
                              <ListItemAvatar>
                                <Avatar alt={chatDetails.name} src={chatDetails.thumb} sx={{ width: 40, height: 40 }} />
                              </ListItemAvatar>
                              <Box>
                                {chat.createdAt ? (
                                  <Typography variant="body2" sx={{ color: theme.palette.text.primary }} color="grey.400" mb={1}>
                                    Admin{" "}
                                    {formatDistanceToNowStrict(new Date(chat.createdAt), {
                                      addSuffix: false,
                                    })}{" "}
                                    ago
                                  </Typography>
                                ) : null}
                                {chat.chatType === "text" ? (
                                  <Box
                                    mb={2}
                                    sx={{
                                      p: 1,
                                      backgroundColor: theme.palette.primary.light,
                                      mr: "auto",
                                      maxWidth: "320px",
                                      whiteSpace: "pre-line",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {chat.msg}
                                  </Box>
                                ) : null}
                                {chat.chatType === "image" ? (
                                  <Box
                                    mb={1}
                                    sx={{
                                      overflow: "hidden",
                                      lineHeight: "0px",
                                    }}
                                  >
                                    {" "}
                                    <Box onClick={() => handleImageClick(chat.msg)}>
                                      <img src={chat.msg} alt="attach" width="150" />
                                    </Box>
                                    <Modal open={currentImage === chat?.msg} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                                      <Box sx={chatMsgWrapper}>
                                        <img src={chat.msg} alt="attach" />

                                        <Button className="closeIcon" onClick={handleClose}>
                                          <CloseIcon />
                                        </Button>
                                      </Box>
                                    </Modal>
                                  </Box>
                                ) : null}
                                {chat.chatType === "emoji" ? (
                                  <Box
                                    sx={{
                                      pl: 1,
                                      pr: 1,
                                      // backgroundColor: "primary.light",
                                      mb: "15px",
                                      ml: "auto",
                                      maxWidth: "320px",
                                      whiteSpace: "pre-line",
                                      wordBreak: "break-word",
                                      fontSize: "35px",
                                      lineHeight: "1",
                                    }}
                                  >
                                    {chat.msg}
                                  </Box>
                                ) : null}
                              </Box>
                            </Box>
                          ) : (
                            <Box
                              onMouseEnter={() => handleMouseEnter(chat.id)}
                              onMouseLeave={() => handleMouseLeave(chat.id)}
                              sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "end",
                                " .moreicon": {
                                  width: "42px",
                                },
                              }}
                            >
                              <Box alignItems="flex-end" display="flex" flexDirection="column">
                                {chat.createdAt ? (
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      "@media screen and (max-width:600px)": {
                                        padding: "8px",
                                      },
                                    }}
                                    color="grey.400"
                                    mb={1}
                                  >
                                    you{" "}
                                    {formatDistanceToNowStrict(new Date(chat.createdAt), {
                                      addSuffix: false,
                                    })}{" "}
                                    ago
                                  </Typography>
                                ) : null}
                                {chat.chatType === "text" ? (
                                  <Box
                                    mb={1}
                                    sx={{
                                      p: 1,
                                      backgroundColor: "primary.light",
                                      ml: "auto",
                                      maxWidth: "320px",
                                      whiteSpace: "pre-line",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {chat.msg}{" "}
                                  </Box>
                                ) : null}
                                {chat.chatType === "image" ? (
                                  <Box>
                                    <Box mb={1} sx={{ overflow: "hidden", lineHeight: "0px" }} onClick={() => handleImageClick(chat.msg)}>
                                      <img src={chat.msg} alt="attach" width="250" />
                                    </Box>

                                    <Modal open={currentImage === chat?.msg} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                                      <Box sx={chatMsgWrapper}>
                                        <img src={chat.msg} alt="attach" />

                                        <Button className="closeIcon" onClick={handleClose}>
                                          <CloseIcon />
                                        </Button>
                                      </Box>
                                    </Modal>
                                  </Box>
                                ) : null}
                                {chat.chatType === "emoji" ? (
                                  <Box
                                    sx={{
                                      pl: 1,
                                      pr: 1,
                                      // backgroundColor: "primary.light",
                                      mb: "15px",
                                      ml: "auto",
                                      maxWidth: "320px",
                                      whiteSpace: "pre-line",
                                      wordBreak: "break-word",
                                      fontSize: "35px",
                                      lineHeight: "1",
                                    }}
                                  >
                                    {chat.msg}
                                  </Box>
                                ) : null}
                              </Box>
                              <Box className="moreicon">{isHovered[chat.id] && <DeleteConfirmation ticketId={chatDetails.ticketId} msgId={chat.id} />}</Box>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>

            {/* ------------------------------------------- */}
            {/* Chat right sidebar Content */}
            {/* ------------------------------------------- */}
            {open ? (
              <Box
                flexShrink={0}
                sx={{
                  "& .InsideWrapper": {
                    height: "calc(100vh - 275px)",
                    // maxHeight: "800px",
                    overflow: "hidden",
                    overflowY: "auto",
                    "&::-webkit-scrollbar-track": {
                      // WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                      borderRadius: "10px",
                      backgroundColor: theme.palette.text.primary + "0d",
                    },
                    "&::-webkit-scrollbar": { width: "8px", backgroundColor: "transparent" },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "10px",
                      WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                      backgroundColor: theme.palette.primary.main,
                    },
                  },
                }}
              >
                <ChatInsideSidebar isInSidebar={lgUp ? open : !open} chatDetails={chatDetails} />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" p={2} pb={1} pt={1}>
          {/* ------------------------------------------- */}
          {/* if No Chat Content */}
          {/* ------------------------------------------- */}
          <Box
            sx={{
              display: { xs: "flex", md: "flex", lg: "none" },
              mr: "10px",
            }}
          >
            <IconMenu2 stroke={1.5} onClick={toggleChatSidebar} />
          </Box>
          <Typography variant="h4">Select Ticket</Typography>
        </Box>
      )}
    </Box>
  );
}

export default ChatContent;
