/* eslint-disable */
import { Box, Button, Chip, styled, Typography } from "@mui/material";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import { subscriptionHistory } from "../../gql/queries/subcriptions";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainLoader from "../MainLoader/MainLoader";
import PlanDetails from "./modal/PlanDetails";

const BoxWrapper = styled(Box)(({ theme }) => ({
  maxHeight: "calc(100vh - 315px)",
  overflow: "auto",
  "@media screen and (max-width:600px)": {
    maxHeight: "none",
  },
  "&::-webkit-scrollbar-track": {
    WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    borderRadius: "10px",
    backgroundColor: "#F5F5F5",
  },
  "&::-webkit-scrollbar": { width: "5px", backgroundColor: "transparent" },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "10px",
    WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
    backgroundColor: theme.palette.primary.main,
  },
  "& .HistoryBoxWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "15px",
    padding: "14px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:last-of-type": { borderBottom: "none" },
    "@media screen and (max-width:600px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "24px",
    },
    "& .PlanStatus": {
      "& .MainHeading": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: 1,
        color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
        marginBottom: "8px",
        "& > span": {
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: 1,
          color: theme.palette.primary.main,
        },
        "& .MuiChip-root": {
          marginLeft: "15px",
          border: "1px solid #00CF00",
          backgroundColor: "#00cf001f",
          height: "28px",
          "& .MuiChip-label": {
            lineHeight: "0.8",
            color: "#00CF00",
          },
        },
      },
      "& .Label": {
        fontSize: "14px",
        color: theme.palette.mode === "dark" ? "#ffffff" : "#757575",
        fontWeight: 500,
        lineHeight: 1,
        marginBottom: "12px",
      },
      "& .StartEndDateWrapper": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "15px",
        "& p": {
          fontSize: "14px",
          color: theme.palette.mode === "dark" ? "#ffffff" : "#757575",
          fontWeight: 500,
          lineHeight: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "6px",
          "& span": {
            fontSize: "14px",
            color: "#2A3547",
            fontWeight: 500,
            lineHeight: 1,
            backgroundColor: "#E3E3E3",
            padding: "4px 12px 4px",
            borderRadius: "100px",
          },
        },
      },
    },
    "& .PaymentMethod": {
      "& .Label": {
        fontSize: "14px",
        color: theme.palette.mode === "dark" ? "#ffffff" : "#757575",
        fontWeight: 500,
        lineHeight: 1,
        marginBottom: "8px",
        textAlign: "center",
      },
      "& .PaymentCardDetails": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "6px",
        "& .CardIconWrapper": {
          backgroundColor: "#F2F6FA",
          padding: "8px 8px 8px",
          borderRadius: "6px",
          "& svg": {
            color: "#2A3547",
            fontSize: "24px",
          },
        },
        "& .CardDetails": {
          "& .CardTitle": {
            fontSize: "14px",
            color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
            fontWeight: 700,
            lineHeight: 1,
            marginBottom: "6px",
          },
          "& .Value": {
            fontSize: "14px",
            color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
            fontWeight: 700,
            lineHeight: 1,
          },
        },
      },
    },
    "& .PaymentDate": {
      "& .Label": {
        fontSize: "14px",
        color: theme.palette.mode === "dark" ? "#ffffff" : "#757575",
        fontWeight: 500,
        lineHeight: 1,
        marginBottom: "8px",
        textAlign: "center",
      },
      "& .PaymentDate": {
        fontSize: "14px",
        color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
        fontWeight: 700,
        lineHeight: 1,
      },
    },
    "& .PayAmount": {
      "& .Label": { fontSize: "14px", color: theme.palette.mode === "dark" ? "#ffffff" : "#757575", fontWeight: 500, lineHeight: 1, marginBottom: "8px", textAlign: "center" },
      "& .PaymentAmount": {
        fontSize: "18px",
        color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
        fontWeight: 700,
        lineHeight: 1,
      },
    },
    "& .BtnWrapper": {
      "& button": {
        textTransform: "none",
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        borderRadius: "5px",
        boxShadow: "none",
        padding: "6px 22px",
        "&:focus": {
          outline: "none",
        },
      },
    },
  },
}));

const formatTimestampToDate = timestamp => {
  const date = new Date(timestamp * 1000);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return `${date.toLocaleDateString("en-US", options)}`;
};
function SubscriptionHistoryTab() {
  const [userHistory, setUserHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [openPlan, setPlanOpen] = useState(false);
  const handlePlanOpen = key => {
    setProjectName(key);
    setPlanOpen(true);
  };
  const handlePlanClose = () => setPlanOpen(false);
  const [getUserPaymentHistory] = useLazyQuery(subscriptionHistory, {
    fetchPolicy: "network-only",
  });
  const { userData } = useSelector(state => state.reducer);
  const isSubscriptionActive = userData?.subscriptionCredits?.subscriptionType || "";

  const fetchPlan = () => {
    setLoading(true);
    getUserPaymentHistory({
      onCompleted: data => {
        if (data && data.getUserPaymentHistory) {
          const { getUserPaymentHistory } = data;
          setUserHistory(getUserPaymentHistory);
        }
        setLoading(false);
      },
      onError: err => {
        console.error("Error fetching plan:", err);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    fetchPlan();
  }, []);

  return (
    <>
      {loading ? (
        MainLoader
      ) : (
        <BoxWrapper>
          {userHistory
            // .filter(history => history.card && history.planInfo)

            .map((history, index) => {
              return (
                <Box className="HistoryBoxWrapper" key={index}>
                  <Box className="PlanStatus">
                    <Typography component="h3" className="MainHeading">
                      {index === 0 ? "Current Plan" : "Previous Plan"} : &nbsp;<Typography component="span">{history?.planInfo?.planName}</Typography>
                      {index === 0 && isSubscriptionActive === history?.planInfo?.planName && <Chip label="Active" variant="outlined" />}
                    </Typography>
                    <Typography component="p" className="Label">
                      {history?.planInfo?.planType}
                    </Typography>
                    <Box className="StartEndDateWrapper">
                      <Typography component="p">
                        Start <Typography component="span">{formatTimestampToDate(history?.card?.created_at)}</Typography>
                      </Typography>
                      <Typography component="p">
                        End <Typography component="span">{formatTimestampToDate(history?.card?.endTime)}</Typography>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="PaymentMethod">
                    <Typography component="p" className="Label">
                      Payment Method
                    </Typography>
                    <Box className="PaymentCardDetails">
                      <Typography component="span" className="CardIconWrapper">
                        <CreditCardOutlinedIcon />
                      </Typography>
                      <Box className="CardDetails">
                        <Typography component="p" className="CardTitle">
                          {history?.card?.network}
                        </Typography>
                        <Typography component="p" className="Value">
                          *****{history?.card?.last4}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="PaymentDate">
                    <Typography component="p" className="Label">
                      Payment Date
                    </Typography>
                    <Typography component="h5" className="PaymentDate">
                      {formatTimestampToDate(history?.card?.created_at)}
                    </Typography>
                  </Box>
                  <Box className="PayAmount">
                    <Typography component="p" className="Label">
                      Pay Amount
                    </Typography>
                    <Typography component="h4" className="PaymentAmount">
                      ₹{history?.planInfo?.amount}
                    </Typography>
                  </Box>
                  <Box className="BtnWrapper">
                    <Button className="OutlineBtn" onClick={() => handlePlanOpen(history?.planInfo?.planName)}>
                      View Details
                    </Button>
                  </Box>
                </Box>
              );
            })}
          <PlanDetails handleClose={handlePlanClose} open={openPlan} viewDetails={projectName || ""} />
        </BoxWrapper>
      )}
    </>
  );
}

export default SubscriptionHistoryTab;
