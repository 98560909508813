/* eslint-disable */
import { uniqueId } from "lodash";
import { IconPoint, IconNotes, IconUserCircle, IconTicket } from "@tabler/icons-react";
import { Dashboard, DeviceHub, Insights, ListAlt } from "@mui/icons-material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { OneDotSvg } from "../../../common/utils";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";

export const MenuitemsUser = [
  {
    id: uniqueId(),
    title: "Projects",
    icon: AccountTreeIcon,
    href: "/projects",
    children: [
      {
        id: uniqueId(),
        title: "Discover",
        icon: IconPoint,
        href: "/",
        children: [
          {
            id: uniqueId(),
            title: "Overview",
            icon: OneDotSvg,
            href: "/",
          },
          {
            id: uniqueId(),
            title: "Site Audit",
            icon: OneDotSvg,
            href: `/`,
          },
          {
            id: uniqueId(),
            title: "Desktop Performance",
            icon: OneDotSvg,
            href: `/report/desktop`,
          },
          {
            id: uniqueId(),
            title: "Uptime Monitoring",
            icon: OneDotSvg,
            href: "/uptime-monitoring",
          },
        ],
      },
    ],
  },

  {
    navlabel: true,
    subheader: "Pages",
  },

  {
    id: uniqueId(),
    title: "Dashboard",
    icon: Dashboard,
    href: "/dashboard?menu=dashboard",
  },
  // {
  //   id: uniqueId(),
  //   title: "Organisation",
  //   icon: DeviceHub,
  //   href: "/organisationsdashboard",
  // },
  {
    id: uniqueId(),
    title: "List Of Websites",
    icon: ListAlt,
    href: "/websitelist",
  },
  {
    id: uniqueId(),
    title: "Website Analyzing",
    icon: Insights,
    href: "/website",
  },
  {
    id: uniqueId(),
    title: "Account Settings",
    icon: IconUserCircle,
    href: "/account-settings",
  },
  {
    id: uniqueId(),
    title: "Notes",
    icon: IconNotes,
    href: "/notes",
  },
  {
    id: uniqueId(),
    title: "App Integration",
    icon: IntegrationInstructionsIcon,
    href: "/app-integration",
  },
];

export const MenuitemsAdmin = [
  {
    id: uniqueId(),
    title: "Projects",
    icon: AccountTreeIcon,
    href: "/",
    children: [
      {
        id: uniqueId(),
        title: "Discover",
        icon: IconPoint,
        href: "/",
        children: [
          {
            id: uniqueId(),
            title: "Overview",
            icon: OneDotSvg,
            href: "/",
          },
          {
            id: uniqueId(),
            title: "Site Audit",
            icon: OneDotSvg,
            href: `/`,
          },
          {
            id: uniqueId(),
            title: "Desktop Performance",
            icon: OneDotSvg,
            href: `/report/desktop`,
          },
          {
            id: uniqueId(),
            title: "Uptime Monitoring",
            icon: OneDotSvg,
            href: "/uptime-monitoring",
          },
        ],
      },
    ],
  },

  {
    navlabel: true,
    subheader: "Pages",
  },

  {
    id: uniqueId(),
    title: "Dashboard",
    icon: Dashboard,
    href: "/dashboard?menu=dashboard",
  },
  {
    id: uniqueId(),
    title: "List Of Websites",
    icon: ListAlt,
    href: "/websitelist",
  },
  {
    id: uniqueId(),
    title: "Website Analyzing",
    icon: Insights,
    href: "/website",
  },
  {
    id: uniqueId(),
    title: "Account Settings",
    icon: IconUserCircle,
    href: "/account-settings",
  },
  {
    id: uniqueId(),
    title: "Notes",
    icon: IconNotes,
    href: "/notes",
  },
  {
    id: uniqueId(),
    title: "App Integration",
    icon: IntegrationInstructionsIcon,
    href: "/app-integration",
  },
];
