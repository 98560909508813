import gql from "graphql-tag";

const getOrgsByAdmin = gql`
  query Query($authorId: String!) {
    findOrgByAdmin(authorId: $authorId) {
      success
      Orgdata {
        id
        name
        address
        limit
      }
    }
  }
`;

const getOrgById = gql`
  query Query($findOrgByIdId: String!) {
    findOrgById(id: $findOrgByIdId) {
      GSTN
      address
      address2
      authorId
      city
      id
      isActive
      landmark
      license
      limit
      name
      pin
      state
      expiry
      invitationsInOrg {
        email
        organizationId
        id
        memberData {
          name
          email
        }
      }
      userData {
        name
        email
        id
      }
      membersInOrg {
        id
        memberId
        memberData {
          email
          id
          name
        }
      }
    }
  }
`;

const deleteMemberFromOrg = gql`
  mutation DeleteMemberInOrg($memberOrgId: String!) {
    deleteMemberInOrg(memberOrgId: $memberOrgId) {
      id
    }
  }
`;
export { getOrgsByAdmin, getOrgById, deleteMemberFromOrg };
