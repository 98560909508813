import { Box } from "@mui/material";

const SvgAnimationWrapper = {
  "& .RightMark": {
    transformOrigin: "center",
    animation: "zoom-in 1s cubic-bezier(0.4, 0, 1, 1)",
    "@keyframes zoom-in": {
      "0%": { transform: "scale(0)" },
      "100%": { transform: "scale(1)" },
    },
  },
  "& .MarkCircle": {
    transformOrigin: "center",
    animation: "opacity-up 2s cubic-bezier(0.4, 0, 1, 1)",
    "@keyframes opacity-up": {
      "0%": { opacity: "0" },
      "100%": { opacity: "1" },
    },
  },
  "& .Flag": {
    transformOrigin: "center",
    animation: "fade-flag1 2.5s infinite ease 1.5s",
    "@keyframes fade-flag1": {
      "0%, 100%": { opacity: "0.2" },
      "50%": { opacity: "0" },
    },
  },
};

function SuccessIcon() {
  return (
    <Box sx={SvgAnimationWrapper} component="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 351 154" fill="none" className="SuccessIcon">
      <path opacity="0" d="M62.7232 119.285L49 105.285L62.7232 108.308L72.6566 121.658L62.7232 119.285Z" fill="url(#paint0_linear_2474_28)" className="Flag" />
      <path opacity="0" d="M225.723 124.658L212 138.657L225.723 135.635L235.657 122.285L225.723 124.658Z" fill="url(#paint1_linear_2474_28)" className="Flag" />
      <path opacity="0" d="M97.7232 13.6574L84 27.6567L97.7232 24.6341L107.657 11.2843L97.7232 13.6574Z" fill="url(#paint2_linear_2474_28)" className="Flag" />
      <path opacity="0" d="M103.723 76.6574L90 90.6567L103.723 87.6341L113.657 74.2843L103.723 76.6574Z" fill="url(#paint3_linear_2474_28)" className="Flag" />
      <path opacity="0" d="M257.723 49.6574L244 63.6567L257.723 60.6341L267.657 47.2843L257.723 49.6574Z" fill="url(#paint4_linear_2474_28)" className="Flag" />
      <path opacity="0" d="M9.93358 19.6574L23.6567 33.6567L9.93358 30.6341L0.00010334 17.2843L9.93358 19.6574Z" fill="url(#paint5_linear_2474_28)" className="Flag" />
      <path opacity="0" d="M340.723 31.6574L327 45.6567L340.723 42.6341L350.657 29.2843L340.723 31.6574Z" fill="url(#paint6_linear_2474_28)" className="Flag" />
      <path opacity="0" d="M161.406 14.7854L157.183 3.00146L163.683 0.78607L171.34 17.1585L161.406 14.7854Z" fill="url(#paint7_linear_2474_28)" className="Flag" />
      <path opacity="0" d="M51.4063 73.7854L47.1831 62.0015L53.6831 59.7861L61.3397 76.1585L51.4063 73.7854Z" fill="url(#paint8_linear_2474_28)" className="Flag" />
      <path opacity="0" d="M144.406 148.786L140.183 137.002L146.683 134.787L154.34 151.159L144.406 148.786Z" fill="url(#paint9_linear_2474_28)" className="Flag" />
      <path opacity="0" d="M234.934 20.7854L239.157 9.00146L232.657 6.78607L225 23.1585L234.934 20.7854Z" fill="url(#paint10_linear_2474_28)" className="Flag" />
      <path opacity="0" d="M290.406 71.7854L286.183 60.0015L292.683 57.7861L300.34 74.1585L290.406 71.7854Z" fill="url(#paint11_linear_2474_28)" className="Flag" />
      <path opacity="0" d="M239.934 100.786L244.157 89.002L237.657 86.7866L230 103.159L239.934 100.786Z" fill="url(#paint12_linear_2474_28)" className="Flag" />
      <circle cx="166.5" cy="82" r="42.5" stroke="#00CF00" strokeWidth="5" className="MarkCircle" />
      <path
        d="M190.25 61.1636C188.14 68.0506 186.68 74.4491 186.665 79.7636C181.395 81.7561 170.674 90.9826 163.323 99.6521L161.286 102.104C160.456 103.105 158.908 103.076 158.121 102.041L154.722 97.5811C151.977 93.9811 148.24 89.6686 146.233 88.0371C144.323 86.4156 142.731 84.9846 142.75 84.8381L153.755 79.5876C155.597 78.6396 155.719 78.8496 158.058 85.2531L160.378 91.7981L162.923 87.6416C165.824 83.0116 169.663 78.1661 173.649 73.9511C177.634 69.7361 181.772 66.1511 185.264 64.0311C187.926 62.4046 190.197 61.1636 190.25 61.1636Z"
        fill="#00CF00"
        className="RightMark"
      />
      <defs>
        <linearGradient id="paint0_linear_2474_28" x1="55.0987" y1="113.427" x2="70.9325" y2="110.891" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00CF00" />
          <stop offset="1" stopColor="#008000" />
        </linearGradient>
        <linearGradient id="paint1_linear_2474_28" x1="218.099" y1="130.515" x2="233.933" y2="133.051" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00CF00" />
          <stop offset="1" stopColor="#008000" />
        </linearGradient>
        <linearGradient id="paint2_linear_2474_28" x1="90.0987" y1="19.5146" x2="105.933" y2="22.0507" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00CF00" />
          <stop offset="1" stopColor="#008000" />
        </linearGradient>
        <linearGradient id="paint3_linear_2474_28" x1="96.0987" y1="82.5146" x2="111.933" y2="85.0507" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00CF00" />
          <stop offset="1" stopColor="#008000" />
        </linearGradient>
        <linearGradient id="paint4_linear_2474_28" x1="250.099" y1="55.5146" x2="265.933" y2="58.0507" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00CF00" />
          <stop offset="1" stopColor="#008000" />
        </linearGradient>
        <linearGradient id="paint5_linear_2474_28" x1="17.5581" y1="25.5146" x2="1.7242" y2="28.0507" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00CF00" />
          <stop offset="1" stopColor="#008000" />
        </linearGradient>
        <linearGradient id="paint6_linear_2474_28" x1="333.099" y1="37.5146" x2="348.933" y2="40.0507" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00CF00" />
          <stop offset="1" stopColor="#008000" />
        </linearGradient>
        <linearGradient id="paint7_linear_2474_28" x1="153.782" y1="8.92877" x2="169.616" y2="6.39266" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00CF00" />
          <stop offset="1" stopColor="#008000" />
        </linearGradient>
        <linearGradient id="paint8_linear_2474_28" x1="43.7818" y1="67.9288" x2="59.6156" y2="65.3927" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00CF00" />
          <stop offset="1" stopColor="#008000" />
        </linearGradient>
        <linearGradient id="paint9_linear_2474_28" x1="136.782" y1="142.929" x2="152.616" y2="140.393" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00CF00" />
          <stop offset="1" stopColor="#008000" />
        </linearGradient>
        <linearGradient id="paint10_linear_2474_28" x1="242.558" y1="14.9288" x2="226.724" y2="12.3927" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00CF00" />
          <stop offset="1" stopColor="#008000" />
        </linearGradient>
        <linearGradient id="paint11_linear_2474_28" x1="282.782" y1="65.9288" x2="298.616" y2="63.3927" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00CF00" />
          <stop offset="1" stopColor="#008000" />
        </linearGradient>
        <linearGradient id="paint12_linear_2474_28" x1="247.558" y1="94.9293" x2="231.724" y2="92.3931" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00CF00" />
          <stop offset="1" stopColor="#008000" />
        </linearGradient>
      </defs>
    </Box>
  );
}

export default SuccessIcon;
