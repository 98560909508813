import { Link, useNavigate } from "react-router-dom";
import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent, timelineOppositeContentClasses } from "@mui/lab";
import { Button, CircularProgress, Tooltip, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { styled } from "@mui/material/styles";
import DashboardCard from "../../shared/DashboardCard";
import { convertISOToDateFormat } from "../../../utils/myUtills";

const ReportList = styled("span")(() => ({
  display: "flex",
  alignItems: "center",
  "@media screen and (min-width: 1576px)": {
    gap: "10px",
  },
  "& .failedReport": {
    marginLeft: "-1px",
    padding: "2px",
    color: "#FA896B",
    fontWeight: 600,
    display: "inline-flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .complateReport": {
    marginLeft: "-1px",
    padding: "2px",
    color: "#008000 ",
    fontWeight: 600,
    display: "inline-flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .canceledReport": {
    marginLeft: "-1px",
    padding: "2px",
    color: "#17A589 ",
    fontWeight: 600,
    display: "inline-flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .crawlReport": {
    background: "none",
    color: "#007BFF",
    border: "none",
    padding: "0",
    font: "inherit",
    cursor: "pointer",
    display: "inline-flex",
    // "@media screen and (min-width: 1576px)": {
    //   marginLeft: "10px",
    // },
  },
}));

function RecentReports({ ProjectData }) {
  const { GetGraphIssueCount = [] } = ProjectData || {};
  const navigate = useNavigate();

  return (
    <DashboardCard title="Recent Reports" subtitle={GetGraphIssueCount ? GetGraphIssueCount[0]?.project : ""} ClassName="HeightFull">
      <Timeline
        className="theme-timeline"
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        sx={{
          p: 0,
          // mb: "-40px",
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.5,
            paddingLeft: 0,
          },
        }}
      >
        {(GetGraphIssueCount || [])?.length &&
          (GetGraphIssueCount || []).map(item => {
            const { createdAt, sessionId, basicCompleted, isCompleted, projectId, isFailed, reason, isCanceled, project } = item || {};
            const show = basicCompleted ? isCompleted : false;
            return (
              <TimelineItem key={sessionId}>
                <TimelineOppositeContent>{convertISOToDateFormat(createdAt).formattedDate}</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary" variant="outlined" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <ReportList>
                    <Link to={`/project-detail/${sessionId}?show=${show}&origin=${projectId}`}>View Report</Link>
                    <Button
                      className="crawlReport"
                      onClick={() => {
                        if (sessionId) {
                          navigate(`/crawl-progress/${sessionId}`, { state: { project } });
                        }
                      }}
                    >
                      Crawl Report
                    </Button>
                    {isFailed && !isCanceled ? (
                      <Tooltip title={reason} placement="top-start">
                        <Typography component="span" className="failedReport">
                          <ErrorOutlineIcon fontSize="small" />
                        </Typography>
                      </Tooltip>
                    ) : isCompleted && !isCanceled ? (
                      <Tooltip title={reason} placement="top-start">
                        <Typography component="span" className="complateReport">
                          <CheckCircleOutlineIcon fontSize="small" />
                        </Typography>
                      </Tooltip>
                    ) : isCanceled ? (
                      <Tooltip title={reason} placement="top-start">
                        <Typography component="span" className="canceledReport">
                          <WarningAmberIcon fontSize="small" />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Report in progress" placement="top-start">
                        <Typography component="span" className="canceledReport">
                          <AccessTimeIcon fontSize="small" />
                        </Typography>
                      </Tooltip>
                    )}
                    {(!isCompleted || !basicCompleted) && (
                      <Typography component="span" className="InProgressReport">
                        <CircularProgress fontSize="small" className="inprogresscircle" />
                        in Progress...
                      </Typography>
                    )}
                  </ReportList>
                </TimelineContent>
              </TimelineItem>
            );
          })}
      </Timeline>
    </DashboardCard>
  );
}

export default RecentReports;
