/* eslint-disable */
import { useEffect, useState, useRef, memo, useMemo } from "react";
import {
  Pagination,
  Box,
  Button,
  Container,
  Grid,
  styled,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Card,
  useTheme,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TableCell from "@mui/material/TableCell";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { GET_GRAPH_DATA, getLinks, issueDetailsPagination } from "../../gql/queries/projectDetail";
import BackButton from "../BackButton/BackButton";
import useQuery from "../../hooks/allQuery";
import { usePopup } from "../../context/commonPop";
import YearlySales from "../dashboards/ecommerce/YearlySales";
import LinkedImage from "./LinkedImage";
import { GetSessionList } from "../../hooks/userWebsiteData";
import { formatDate } from "../../common/utils";
import { contentNewTableArray } from "./common/utils";
import TitleForm from "./TitleForm";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "50px",
}));
const BoxWrapper = styled(Box)(({ theme }) => ({
  "& h3": {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "0px",
    color: theme.palette.text.primary,
  },
  "& .notFound": {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  "& .TableWrapper": {
    overflowX: "scroll",

    "& table": {
      tableLayout: "fixed",
      "& .tableHead": {
        "& .TableHeadRow": {
          "& .URL": { width: "22%" },
          "& .CanonicalUrl": { width: "22%" },
          "& .RedirectURL": { width: "28%" },
          "& .LinkedCSS": { width: "28%" },
          "& .Metadescription": { width: "22%" },
          "& .LinkedImages": { width: "50%" },
          "& .HTTPstatuscode": { textAlign: "center" },
          "& .Isindexablepage": { textAlign: "center" },
        },
        "& th": {
          color: theme.palette.text.primary,
          fontWeight: 600,
          fontSize: "15px",
          minWidth: "200px",
        },
      },
      "& tbody": {
        "& tr": {
          "& td": {
            verticalAlign: "top",
            "&:first-of-type": {
              "& .tbodyContent": { textAlign: "left" },
            },
          },
        },
        "& .tbodyContent": {
          color: theme.palette.text.primary,
          fontWeight: 400,
          fontSize: "14px",
          wordBreak: "break-word",
          "& .statusBtn": {
            backgroundColor: "#4caf50",
            padding: "2px 0px 2px",
            color: "#ffffff",
            fontSize: "12px",
            borderRadius: "2px",
            minWidth: "45px",
            "&:focus": {
              outline: "none",
            },
          },
        },
        "& .statusButton": {
          backgroundColor: theme.palette.primary.main,
          color: "#fff",
        },
        "& .indexPageUrl": {
          color: theme.palette.text.primary,
          fontWeight: 400,
          fontSize: "14px",
          overflowWrap: "break-word",
          hyphens: "manual",
          inlineSize: "78px",
        },
      },
    },
    "&.TitleMetaTableWrapper": {
      overflowX: "auto",
      "& table": {
        "& .tableHead": {
          "& .TableHeadRow": {
            "& .NumberCount": { width: "60px" },
            "& .Action": { width: "150px" },
          },
        },
        "& tbody": {
          "& tr": {
            "& td": {
              verticalAlign: "middle",
              "& .AccordionWrapper": {
                width: "100%",
                boxShadow: "0px 0px 3px" + theme.palette.divider,
                "& .MuiAccordionSummary-root": {
                  justifyContent: "flex-start",
                  "& .MuiAccordionSummary-content": {
                    overflow: "hidden",
                    "& p": {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    },
                  },
                },
                "& .BodyContentWrapper": {
                  whiteSpace: "normal",
                  "& p": {
                    border: "1px solid" + theme.palette.divider,
                    marginBottom: "10px",
                    padding: "12px 15px 12px",
                    borderRadius: "10px",
                    "& span": {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "6px",
                      fontWeight: 900,
                      "& svg": {
                        fontSize: "16px",
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                      },
                    },
                  },
                },
              },
              "& button": {
                padding: "11.75px 14px",
                whiteSpace: "nowrap",
                "&:focus": {
                  outline: "none",
                },
              },
            },
          },
        },
      },
    },
  },
}));

const TabDataWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  "& > button": {
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    borderRadius: "5px",
    padding: "10px 16px",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
    },
  },
  "& .tabItem": {
    color: theme.palette.text.primary,
    fontWeight: 600,
    border: `3px solid ${theme.palette.common.hoverBtn}`,
    flexDirection: "row",
    textTransform: "none",
    background: "transparent",
    transition: "0.3s all",
    borderRadius: "0px",
    padding: "10px 18px 10px",
    minHeight: "auto",
    marginRight: "0px",

    "&:first-of-type": {
      borderTopLeftRadius: "50px",
      borderBottomLeftRadius: "50px",
    },
    "&:last-child": {
      borderTopRightRadius: "50px",
      borderBottomRightRadius: "50px",
    },
    "& .countTag": {
      fontSize: "14px",
      marginLeft: "5px",
      color: theme.palette.common.black,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
      "& .countTag": {
        color: "#ffffff ",
      },
    },
    "&:focus": { outline: "none" },
  },
}));

const itemsPerPage = 5; // Number of URLs to show per page

function upgradeArray(length) {
  const upgradedArray = [];

  for (let i = 1; i <= length; i++) {
    upgradedArray.push(itemsPerPage);
  }

  return upgradedArray;
}

function forPdfStatus(data) {
  const hasTrueValue = data.some(childArr => Array.isArray(childArr) && childArr.some(item => Array.isArray(item) && item.length > itemsPerPage));
  return hasTrueValue;
}
const defaultValue = 20;
function ProjectDetailDataInner() {
  const [linksData, setLinksData] = useState({ issueCounts: 20, tableHeader: [], tableBody: [] });
  const [pageCount, setPageCount] = useState(1);
  const [graphData, setGraphData] = useState({});
  const [visibleUrls, setVisibleUrls] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ formUrl: "", formMetaDesc: "", index: 0 });
  const [isPdf, setIsPdf] = useState(false);
  const [searchParams] = useSearchParams();
  const customizer = useSelector(state => state.customizer);
  const theme = useTheme();
  const pdfDownload = useRef();
  const { allQuery } = useQuery();
  const params = useParams();
  const [GET_GRAPH] = useLazyQuery(GET_GRAPH_DATA, { fetchPolicy: "cache-first" });
  const [GET_LINKS] = useLazyQuery(getLinks, { fetchPolicy: "cache-first" });
  const [GET_PAGINATION_DATA] = useLazyQuery(issueDetailsPagination, { fetchPolicy: "network-only" });
  const name = searchParams.get("name");
  const { sessionId } = params;
  const projectParams = searchParams.get("origin");
  const listData = GetSessionList(projectParams);
  const { allSession } = (Array.isArray(listData) && listData.length && listData[0]) || {};
  const projectNSessionCreateAt = useMemo(() => {
    if (allSession) {
      const { createAt, domain } = allSession.find(item => item.sessionId === sessionId) || {};
      const { projectName, website } = Array.isArray(listData) && listData.length && listData[0];
      return { projectName, website, sessionCreatedAt: createAt, sessionDomain: domain };
    }
    return null;
  }, [allSession, sessionId, listData]);

  const GetPaginationIssuedetail = async inputArg => {
    const res = await GET_PAGINATION_DATA({ variables: { inputArg } });
    if (res && res.data && res.data.GetIssueDetailPagination) {
      return res.data.GetIssueDetailPagination;
    }
    return [];
  };

  useEffect(() => {
    allQuery({
      QUERY: GET_LINKS,
      variables: {
        sessionId: params.sessionId,
        flag: params.res,
      },
      onCompleteFunc: async data => {
        const { tableHeader, issueCounts } = data.GetScrapyLinks || {};

        const callData = await GetPaginationIssuedetail({
          PaginationCount: 0,
          flag: params?.res,
          limit: defaultValue,
          session_id: params?.sessionId,
        });
        setPageCount(1);
        const upgradedArray = await upgradeArray(callData.length);
        setVisibleUrls(upgradedArray);
        setIsPdf(forPdfStatus(callData));
        setLinksData({ issueCounts, tableHeader, tableBody: callData });
      },
    });
    allQuery({
      QUERY: GET_GRAPH,
      variables: {
        projectId: searchParams.get("origin"),
        flag: params.res,
      },
      onCompleteFunc: data => {
        setGraphData(data.GraphShowByCreateAt);
      },
    });
    // eslint-disable-next-line
  }, []);
  const handlePageChange = async (event, newPage) => {
    setPageCount(newPage);
    const pageNumber1 = newPage - 1;
    const pageNumber = defaultValue * pageNumber1;
    const callData = await GetPaginationIssuedetail({
      PaginationCount: pageNumber,
      flag: params?.res,
      limit: defaultValue,
      session_id: params?.sessionId,
    });

    setIsPdf(forPdfStatus(callData));
    setLinksData({ ...linksData, tableBody: callData });
  };

  const handleEventGenerated = async (index, id) => {
    const url = linksData?.tableBody[index][0]?.url;
    setFormData({ ...formData, formUrl: url, formMetaDesc: "", index });
    setOpen(true);
  };
  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={12}>
              <MemoizedCardComponent graphData={graphData} projectNSessionCreateAt={projectNSessionCreateAt} />
              <BoxWrapper>
                <Card
                  sx={{ padding: "30px 45px 24px 40px", border: `1px solid ${theme.palette.divider}` }}
                  elevation={customizer.isCardShadow ? 9 : 0}
                  variant={!customizer.isCardShadow ? "outlined" : undefined}
                >
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography component="h3">Issues / {name && name}</Typography>
                    <MemoizedDownloadForm isPdf={isPdf} pdfDownload={pdfDownload} linksData={linksData} issueName={name} projectNSessionCreateAt={projectNSessionCreateAt} />
                  </Box>
                  <Box className="TableWrapper" ref={pdfDownload}>
                    <Table>
                      <TableHead className="tableHead">
                        <TableRow className="TableHeadRow">
                          {linksData?.tableHeader &&
                            linksData?.tableHeader.map(item => (
                              <TableCell align="left" className={item?.replaceAll(" ", "")}>
                                {item}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.isArray(linksData?.tableBody) &&
                          linksData?.tableBody.map((item, index) => (
                            <TableRow key={Math.random().toString(16).slice(2)} hover>
                              {Array.isArray(item) &&
                                item?.map(cellContent => (
                                  <TableCell key={Math.random().toString(16).slice(2)}>
                                    {cellContent && Array.isArray(cellContent) ? (
                                      <LinkedImage cellContent={cellContent} index={index} visibleUrls={visibleUrls} />
                                    ) : typeof cellContent === "object" ? (
                                      cellContent.url ? (
                                        <Box className="tbodyContent" sx={{ wordBreak: "break-all" }}>
                                          <Typography variant="h6" component="div">
                                            {cellContent.title}
                                          </Typography>
                                          <Typography component="a" href={cellContent.url} target="_blank">
                                            {cellContent.url}
                                          </Typography>
                                        </Box>
                                      ) : (
                                        // for responce status
                                        <Box className="tbodyContent" align="center">
                                          <Button className="statusBtn" align="center">
                                            {cellContent?.status}
                                          </Button>
                                        </Box>
                                      )
                                    ) : (
                                      <Box className="tbodyContent" align="center">
                                        {cellContent}
                                      </Box>
                                    )}
                                  </TableCell>
                                ))}
                              {contentNewTableArray.includes(params.res) && (
                                <TableCell>
                                  <Button onClick={() => handleEventGenerated(index, item)}>Action</Button>
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    {/* eslint-disable-next-line no-unsafe-optional-chaining */}
                    {linksData?.issueCounts > defaultValue && (
                      <Pagination count={Math.ceil(linksData?.issueCounts / defaultValue)} page={pageCount} onChange={handlePageChange} color="primary" size="large" />
                    )}
                  </Box>
                </Card>
              </BoxWrapper>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <TitleForm open={open} setOpen={setOpen} url={formData.formUrl} params={params} />
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default ProjectDetailDataInner;

function CardComponent({ graphData, projectNSessionCreateAt }) {
  const { projectName, website, sessionCreatedAt, sessionDomain } = projectNSessionCreateAt || {};
  const { day, month, year, formattedHours, minute, ampm } = formatDate(sessionCreatedAt);
  const customizer = useSelector(state => state.customizer);
  const theme = useTheme();
  const websiteProject = {
    width: { xs: "100%", sm: "auto" },
    mb: "15px",
    "& a": {
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: "13px",
    },
    "& span svg": {
      height: "12px",
      color: theme.palette.primary.main,
      fontWeight: 600,
      width: "15px",
    },
  };
  const CrawlReportDate = {
    minWidth: { xs: "auto", sm: "300px" },
    padding: { xs: "0", sm: "10px 20px" },
    background: { xs: "transparent", sm: theme.palette.primary.main },
    borderRadius: "5px",
    marginBottom: "25px",
    display: "flex",
    justifyContent: { xs: "space-between", sm: "center" },
    gap: { xs: "0", sm: "10px" },
    alignItems: "center",
    flexDirection: { xs: "column", sm: "row" },
    "& p": {
      color: { xs: theme.palette.text.primary, sm: "#fff" },
      fontWeight: 600,
      fontSize: { xs: "15px", sm: "14px" },
      width: { xs: "100%", sm: "auto" },
    },
    "& .crawlReportWrapper": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      "& span": {
        color: { xs: theme.palette.text.primary, sm: "#fff" },
        fontWeight: 600,
        fontSize: "14px",
        margin: "0 5px",
      },
    },
  };
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", gap: "15px", alignItems: "center" }}>
        <BackButton />
        <Box sx={{ display: "flex", gap: "15px", width: { xs: "100%", sm: "auto" } }}>
          <Box sx={websiteProject}>
            <Typography component="p" sx={{ color: theme.palette.text.primary, fontWeight: 600, fontSize: "15px" }}>
              {projectName}
            </Typography>
            <Typography sx={{ mt: "-5px" }} component="p">
              <Typography component="a" href={website} target="_blank">
                {sessionDomain}
                <Typography component="span">
                  <OpenInNewIcon />
                </Typography>
              </Typography>
            </Typography>
          </Box>
          <Box sx={CrawlReportDate}>
            <Typography component="p">Crawl Date :- </Typography>
            <Box className="crawlReportWrapper">
              <Typography component="span">{`${day} ${month} ${year} ${formattedHours}:${minute} ${ampm}`}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Card
        sx={{
          padding: "0",
          border: `1px solid ${theme.palette.divider}`,
          mb: "24px",
        }}
        elevation={customizer.isCardShadow ? 9 : 0}
        variant={!customizer.isCardShadow ? "outlined" : undefined}
      >
        <YearlySales graphData={graphData} />
      </Card>
    </>
  );
}

function DownloadForm({ isPdf, pdfDownload, linksData, projectNSessionCreateAt, issueName }) {
  const [selectedValue, setSelectedValue] = useState("");
  const theme = useTheme();
  const { handleOpen } = usePopup();
  const { projectName, sessionCreatedAt } = projectNSessionCreateAt || {};
  const { day, month, year } = formatDate(sessionCreatedAt);

  const handlePdf = useReactToPrint({
    content: () => pdfDownload.current,
    documentTitle: `${projectName}_${day}_${month}_${year}_${issueName}`,
  });
  const handleDownload = () => {
    try {
      if (linksData.tableHeader.length > 0 && linksData.tableBody.length > 0) {
        // Convert data to CSV format
        let csvContent = linksData.tableHeader.join(",") + "\n";
        linksData.tableBody.forEach(row => {
          const csvRow = row.map(cell => {
            if (typeof cell === "object" && cell !== null) {
              if ("url" in cell) {
                return cell.url;
              }
              if ("status" in cell) {
                return cell.status;
              }
              if (Array.isArray(cell)) {
                const combinedContent = cell
                  .map(innerCell => {
                    if (typeof innerCell !== "object") {
                      return innerCell;
                    }
                    const { content, btnContent } = innerCell || {};
                    if (content && btnContent) {
                      return `${content}`;
                    }
                    return "";
                  })
                  .join("; "); // Separate by a semicolon

                return `"${combinedContent}"`; // Wrap combined content in double quotes
              }
            }
            return cell;
          });
          csvContent += csvRow.join(",") + "\n";
        });

        // Create a Blob with the CSV data
        const blob = new Blob([csvContent], { type: "text/csv" });

        // Create a temporary URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create an anchor element for download
        const a = document.createElement("a");
        a.href = url;
        a.download = `${projectName}_${day}_${month}_${year}_${issueName}.csv`; // Name of the downloaded file
        a.style.display = "none";

        // Append the anchor to the body and trigger a click event
        document.body.appendChild(a);
        a.click();

        // Clean up: remove the temporary URL and anchor element
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      console.log("error", error);
      handleOpen(true, "something went wrong");
    }
  };
  const handleChangeExport = event => {
    setSelectedValue(event.target.value);
    if (event.target.value === "pdf") {
      handlePdf();
    }
    if (event.target.value === "csv") {
      handleDownload();
    }
    // You can add your custom logic here based on the selected value
  };
  return (
    <TabDataWrapper>
      <FormControl
        sx={{
          m: 1,
          minWidth: 120,
        }}
      >
        <InputLabel sx={{ backgroundColor: "#fff", fontWeight: 600, padding: "0 5px" }} htmlFor="export-select">
          Export
        </InputLabel>
        <Select
          sx={{
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            fontWeight: 600,
            "& svg": {
              color: theme.palette.text.primary,
            },
            "& #demo-simple-select": { padding: "16.5px 14px!important" },
          }}
          className="menuitemlist "
          value={selectedValue}
          onChange={handleChangeExport}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
        >
          {!isPdf && (
            <MenuItem
              sx={{
                color: theme.palette.text.primary,
                transition: "0.3s all",
                fontWeight: 700,
                "&:hover": {
                  backgroundColor: `${theme.palette.primary.light} !important`,
                  color: "#000",
                },
                "&.Mui-selected": {
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  color: "#fff",
                },
              }}
              className="menuitemlist"
              value="pdf"
            >
              PDF
            </MenuItem>
          )}
          <MenuItem
            sx={{
              color: theme.palette.text.primary,
              transition: "0.3s all",
              fontWeight: 700,
              "&:hover": {
                backgroundColor: `${theme.palette.primary.light} !important`,
                color: "#000",
              },
              "&.Mui-selected": {
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: "#fff",
              },
            }}
            className="menuitemlist"
            value="csv"
          >
            CSV
          </MenuItem>
        </Select>
      </FormControl>
    </TabDataWrapper>
  );
}

const MemoizedCardComponent = memo(CardComponent);
const MemoizedDownloadForm = memo(DownloadForm);