/*eslint-disable */
import { Box, Button, Card, CircularProgress, Container, FormHelperText, Grid, TextField, Typography, styled, useTheme } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_MAGENTO_USERDATA, CREATE_SHOPIFY_USERDATA, CREATE_WORDPRESS_USERDATA, CREATE_WORPRESS_USERDATA } from "../../gql/mutations/userWebSiteVisit";
import { usePopup } from "../../context/commonPop";
import { validateApiVersion, validateShopName, validateShopifyToken, validateUserPassword, validateUsername } from "../../utils/validations";
import { useSelector } from "react-redux";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  display: "flex",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  padding: "30px 0",
  "& .MainHeading": {
    fontSize: "20px",
    fontWeight: 700,
    marginBottom: "25px",
  },
  "& .InputWrapper": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "20px",
    "& .MuiFormControl-root": {
      flex: "1",
      "&.FullWidthInput": {
        flex: "100%",
      },
      "& label": { lineHeight: "1" },
    },
  },
  "& .BtnWrapper": {
    marginTop: "20px",
    textAlign: "right",
    "& button": {
      minWidth: "80px",
      minHeight: "37px",
      "& .Loader": {
        width: "25px",
        height: "25px",
        "& svg": {
          color: "#ffffff",
        },
      },
    },
  },
}));

const UserDetailsForm = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { handleOpen } = usePopup();
  const location = useLocation();
  const theme = useTheme();
  const customizer = useSelector(state => state.customizer);
  const {
    state: { toolName, domain },
  } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    shopName: "",
    apiVersion: "",
    shopifyToken: "",
    username: "",
    password: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    shopName: "",
    apiVersion: "",
    shopifyToken: "",
    username: "",
    password: "",
  });
  const [getShopifyResponse] = useMutation(CREATE_SHOPIFY_USERDATA);
  const [getWordpressResponse] = useMutation(CREATE_WORDPRESS_USERDATA);
  const [getMagentoResponse] = useMutation(CREATE_MAGENTO_USERDATA);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { shopName, apiVersion, shopifyToken, username, password } = formData;
      let shopNameError = "",
        apiVersionError = "",
        shopifyTokenError = "",
        usernameError = "",
        passwordError = "";

      if (toolName === "Wordpress"||toolName === "Magento") {
        usernameError = validateUsername(username);
        passwordError = validateUserPassword(password);
      } else if (toolName === "Shopify"){
        shopNameError = validateShopName(shopName);
        apiVersionError = validateApiVersion(apiVersion);
        shopifyTokenError = validateShopifyToken(shopifyToken);
      } 

      if (shopNameError || apiVersionError || shopifyTokenError || usernameError || passwordError) {
        setValidationErrors({
          shopName: shopNameError,
          apiVersion: apiVersionError,
          shopifyToken: shopifyTokenError,
          username: usernameError,
          password: passwordError,
        });
        setIsLoading(false);
        return;
      }

      let response;

      if (toolName === "Wordpress") {
        response = await getWordpressResponse({
          variables: {
            userInput: {
              projectId,
              integrationToken: { username, password },
              domain,
            },
          },
        });
      } else if(toolName === "Magento") {
        response = await getMagentoResponse({
          variables: {
            userInput: {
              projectId,
              integrationToken: { username, password },
              domain,
            },
          },
        });
      }else if(toolName === "Shopify") {
        response = await getShopifyResponse({
          variables: {
            userInput: {
              projectId,
              integrationToken: { shopName, apiVersion, shopifyToken },
            },
          },
        });
      }

      const { data } = response;
      if (data.CreateWordpressUserData) {
        handleOpen(true, "User Connected Successfully");
        navigate(`/app-integration`);
        setFormData({});
      } else if (data.CreateShopifyUserData) {
        handleOpen(true, "User Connected Successfully");
        navigate(`/app-integration`);
        setFormData({});
      }
      else if (data.CreateMagentoUserData) {
        handleOpen(true, "User Connected Successfully");
        navigate(`/app-integration`);
        setFormData({});
      }
      setIsLoading(false);
    } catch (error) {
      handleOpen(true, error.message || "Something went wrong");
      console.error("Error occurred:", error);
      setIsLoading(false);
    }
  };

  return (
    <SectionWrapper>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} md={12}>
              <Card
                sx={{ padding: { xs: "8px 15px 20px 15px", sm: "30px 45px 24px 40px" }, border: `1px solid ${theme.palette.divider}` }}
                elevation={customizer.isCardShadow ? 9 : 0}
                variant={!customizer.isCardShadow ? "outlined" : undefined}
              >
                <Typography component="h2" className="MainHeading">
                  API Integration
                </Typography>
                <Box className="InputWrapper">
                  {(toolName === "Wordpress"|| toolName==="Magento") ? (
                    <>
                      <TextField
                        label="Username"
                        variant="outlined"
                        name="username"
                        value={formData.username}
                        onChange={handleInputChange}
                        helperText={validationErrors.username}
                        error={!!validationErrors.username}
                      />
                      <TextField
                        label="Password"
                        variant="outlined"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        helperText={validationErrors.password}
                        error={!!validationErrors.password}
                      />
                    </>
                  ) : (
                    <>
                      <TextField
                        label="Shop Name"
                        variant="outlined"
                        name="shopName"
                        value={formData.shopName}
                        onChange={handleInputChange}
                        helperText={validationErrors.shopName}
                        error={!!validationErrors.shopName}
                      />
                      <TextField
                        label="API Version"
                        variant="outlined"
                        name="apiVersion"
                        value={formData.apiVersion}
                        onChange={handleInputChange}
                        helperText={validationErrors.apiVersion}
                        error={!!validationErrors.apiVersion}
                      />
                      <TextField
                        label="Token"
                        variant="outlined"
                        name="shopifyToken"
                        value={formData.shopifyToken}
                        onChange={handleInputChange}
                        helperText={validationErrors.shopifyToken}
                        error={!!validationErrors.shopifyToken}
                        className="FullWidthInput"
                      />
                    </>
                  )}
                </Box>
                <Box className="BtnWrapper">
                  <Button variant="contained" onClick={e => handleSubmit(e)} disabled={isLoading}>
                    {isLoading ? <CircularProgress className="Loader" /> : "Submit"}
                  </Button>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

export default UserDetailsForm;
