/* eslint-disable */

import { Box, useTheme } from "@mui/material";
import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgress = ({ value, label, sxSize, marker, isParcentage = true, totalValue = 100 }) => {
  const theme = useTheme();
  const getTextColor = colorValue => {
    if (label) {
      switch (label) {
        case "Good-Url":
          return "rgb(0, 204, 102)";
        case "Average-Url":
          return "rgb(255, 170, 51)";
        case "Poor-Url":
          return "rgb(255, 51, 51)";
      }
    }
    if (colorValue < totalValue * 0.5) return "rgb(255, 51, 51)";
    if (colorValue < totalValue * 0.9) return "rgb(255, 170, 51)";
    return "rgb(0, 204, 102)";
  };
  return (
    <Box>
      <Box sx={{ position: "relative", ml: "auto", mr: "auto", ...sxSize }}>
        <CircularProgressbar
          value={value}
          maxValue={totalValue}
          styles={buildStyles({
            pathColor: getTextColor(value),
            trailColor: "#F0F0F0",
            strokeLinecap: "round",
          })}
          strokeWidth={5}
        />
        <Box
          sx={{
            position: "absolute",
            top: "12.5%",
            left: "12.5%",
            width: "75%",
            height: "75%",
            backgroundColor: "#F0F0F0",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            zIndex: 1,
          }}
        >
          <Box component="span" sx={{ color: "#000000", fontSize: "14px", fontWeight: "bold" }}>
            {isParcentage ? `${value}%` : value}
          </Box>
        </Box>
      </Box>
      {label && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px", mt: "8px" }}>
          {marker && <Box component="span" sx={{ display: "block", backgroundColor: getTextColor(value), width: "10px", height: "10px", borderRadius: "50%" }}></Box>}
          <Box component="span" sx={{ color: theme.palette.mode === "dark" ? "#B3B3B3" : "#000000", fontSize: "12px", fontWeight: "600" }}>
            {label}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CircularProgress;
