/* eslint-disable */
import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Modal, styled, Typography, useTheme } from "@mui/material";
import { IconCheck } from "@tabler/icons-react";

const BoxWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: theme.palette.background.default,
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: "6px",
  padding: "30px 35px",
  "& h4": {
    color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "44px",
  },
  "& .BtnWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
    "& button": {
      textTransform: "none",
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
      borderRadius: "5px",
      boxShadow: "none",
      padding: "7px 22px",
      whiteSpace: "nowrap",
      width: "100%",
      "&:focus": {
        outline: "none",
      },
      "&.OutlineBtn": {
        color: theme.palette.primary.main,
        backgroundColor: "transparent",
        border: `2px solid ${theme.palette.primary.main}`,
        padding: "6px 22px",
      },
    },
  },
}));

function ConfirmationModal({ open, handleClose, handleCancelFunc, label }) {
  return (
    <Modal open={open}>
      <BoxWrapper>
        <Typography component="h4">{label}</Typography>
        <Box className="BtnWrapper">
          <Button onClick={handleCancelFunc}>Okay</Button>
          <Button className="OutlineBtn" onClick={handleClose}>
            Back
          </Button>
        </Box>
      </BoxWrapper>
    </Modal>
  );
}

export default ConfirmationModal;
