import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
// import Loader from "../Report/NewLoader";

function AdminAuthenticated({ children }) {
  const { userData } = useSelector(state => state.reducer);
  if (Object.keys(userData).length === 0) {
    // return <Loader message="Loading.." />;
    return children;
  }
  if (userData && (userData.role === "SUPERADMIN" || userData.role === "COORDINATOR")) {
    return children;
  }
  return <Navigate to="/dashboard?menu=dashboard" />;
}

export function UserAuthenticated({ children }) {
  const { userData } = useSelector(state => state.reducer);
  if (Object.keys(userData).length === 0) {
    //   return <Loader message="Loading.." />;
    return children;
  }
  if (userData && userData.role === "USER") {
    return children;
  }
  return <Navigate to="/dashboard?menu=dashboard" />;
}

export default AdminAuthenticated;
