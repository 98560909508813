import { Box, Button, Card, CardContent, Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import welcomeBlur from "../../Images/backgrounds/welcomeBlur.png";

function WelcomeCard() {
  const theme = useTheme();
  const btnGroup = {
    display: "flex",
    gap: "20px",
    mt: "30px",
    "& .view-details": {
      backgroundColor: "transparent",
      fontWeight: 700,
      color: theme.palette.primary.main,
      padding: "10px 35px",
      marginBottom: "15px",
      border: `1px solid ${theme.palette.primary.main}`,
    },
    "& .purchase-plan": {
      backgroundColor: theme.palette.primary.main,
      fontWeight: 700,
      color: "#fff",
      padding: "10px 35px",
      marginBottom: "15px",
      border: `1px solid ${theme.palette.primary.main}`,
    },
  };
  return (
    <Card elevation={0} sx={{ backgroundColor: theme.palette.primary.light, py: 0, height: "100%" }}>
      <CardContent sx={{ py: 4, px: 2 }}>
        <Grid container justifyContent="space-between">
          <Grid item sm={6} display="flex" alignItems="center">
            <Box>
              <Box
                gap="16px"
                sx={{
                  display: {
                    xs: "block",
                  },
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="h5" sx={{ whiteSpace: { xs: "wrap", sm: "nowrap" }, fontSize: "28px", fontWeight: "700", mb: "12px" }}>
                    Currently you have an free plan
                  </Typography>
                  <Typography component="span" sx={{ fontSize: "14px", fontWeight: "500" }}>
                    If you want to see more information then buy our premium plan
                  </Typography>
                </Box>
                <Box sx={btnGroup}>
                  <Link to="/payments">
                    <Button className="purchase-plan">Purchase plan</Button>
                  </Link>
                </Box>
              </Box>
              <Stack spacing={2} direction="row" divider={<Divider orientation="vertical" flexItem />}></Stack>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box sx={{ mb: { xs: "-25px", sm: "-51px" }, textAlign: "right" }}>
              <Typography component="img" src={welcomeBlur} alt="img" />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default WelcomeCard;
