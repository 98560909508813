import { lazy } from "react";
import { useNavigate } from "react-router";
import { Box, Button, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DashboardCard from "../../shared/DashboardCard";
const Chart = lazy(() => import("react-apexcharts"), { ssr: false });
const BoxWrapper = styled(Box)(() => ({
  "& .ChartWrapper": { filter: "blur(2px)", pointerEvents: "none" },
}));

function DummyChart({ data, projectid }) {
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const secondary = theme.palette.secondary.main;
  const navigate = useNavigate();

  const dummyData = {
    dates: ["Jan", "Feb", "Mar", "Apr", "May"],
    uptimePerDay: [100, 150, 120, 80, 110],
    downtimePerDay: [20, 10, 30, 15, 25],
  };

  const optionscolumnchart = {
    chart: {
      type: "bar",
      fontFamily: "'Plus Jakarta Sans', sans-serif;",
      foreColor: "#adb0bb",
      toolbar: {
        show: true,
      },
      height: 360,
      stacked: true,
    },
    colors: [primary, secondary],
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: "60%",
        columnWidth: "60%",
        borderRadius: [6],
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
      },
    },

    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      borderColor: "rgba(0,0,0,0.1)",
      strokeDashArray: 3,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      min: -24,
      max: 24,
      tickAmount: 10,
    },
    xaxis: {
      categories: dummyData.dates,
      axisBorder: {
        show: false,
      },
    },
    tooltip: {
      theme: theme.palette.mode === "dark" ? "dark" : "light",
      fillSeriesColor: false,
    },
  };
  const seriescolumnchart = [
    {
      name: "UpTime",
      data: dummyData.uptimePerDay,
    },
    {
      name: "Down Time",
      data: dummyData.downtimePerDay,
    },
  ];
  // const blurStyle = {
  //   filter: "blur(2px)",
  // };

  return (
    // <BoxWrapper>
    //   <Card sx={{ padding: "30px 45px 24px 40px" }} elevation={9}>
    //     <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
    //       <Grid item xs={12} lg={8}>
    //         <Box className="HeaderWrapper">
    //           <Box>
    //             <Typography component="h5" className="MainHeading">
    //               {data ? " Uptime / Downtime Graph" : "Your uptime Monitoring is off. Please turn it on to show updates"}
    //             </Typography>
    //           </Box>
    //         </Box>

    //         <Box className="GraphMainBoxWrapper" sx={{ ...blurStyle }}>
    //           <Chart options={optionscolumnchart} series={seriescolumnchart} type="bar" height={360} width="100%" />
    //         </Box>
    //       </Grid>
    //     </Grid>
    //   </Card>
    // </BoxWrapper>

    <DashboardCard title="Uptime / Downtime Graph" subtitle={!data ? "" : `Your uptime Monitoring is off. Please turn it on to show updates`} ClassName="HeightFull">
      <Box className="BtnWrapper">
        <Button onClick={() => navigate(`/uptime-setting/${projectid}`)}>Uptime-Setting</Button>
      </Box>
      <BoxWrapper>
        <Box className="ChartWrapper">
          <Chart options={optionscolumnchart} series={seriescolumnchart} type="bar" height={300} width="100%" />
        </Box>
      </BoxWrapper>
    </DashboardCard>
  );
}

export default DummyChart;
