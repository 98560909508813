/*eslint-disable */
import { Box, Card, Container, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, useTheme } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation, useNavigate } from "react-router";
import { GET_MAGENTO_WEBSITES, GET_USER_SHOPIFI_WEBSITES, GET_WORDPRESS_WEBSITES } from "../../gql/queries/userWebSiteList";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import MainLoader from "../MainLoader/MainLoader";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  display: "flex",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  padding: "30px 0",
  "& .MainHeading": {
    fontSize: "20px",
    fontWeight: 700,
    marginBottom: "25px",
  },
  "& .TableMainWrapper": {
    boxShadow: "none",
    borderRadius: "0",
    "& table": {
      "& thead": {
        "& tr": {
          "& th": {
            fontSize: "14px",
            fontWeight: 600,
            whiteSpace: "nowrap",
            "&:last-of-type": {
              width: "200px",
              textAlign: "center",
            },
          },
        },
      },
      "& tbody": {
        "& tr": {
          "& td": {
            paddingTop: "15px",
            paddingBottom: "15px",
            fontSize: "12px",
            fontWeight: 600,
            "&:last-of-type": {
              width: "200px",
              textAlign: "center",
            },
            "& .ViewReportBtn": {
              whiteSpace: "nowrap",
            },
          },
        },
      },
    },
  },
}));

const WebsiteContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const customizer = useSelector(state => state.customizer);
  const {
    state: { toolName },
  } = location;

  const [websiteData, setWebsiteData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allWebsiteQuery] = useLazyQuery(GET_USER_SHOPIFI_WEBSITES, { fetchPolicy: "network-and-cache" });
  const [wordpressWebsiteQuery] = useLazyQuery(GET_WORDPRESS_WEBSITES, { fetchPolicy: "network-and-cache" });
  const [magentoWebsiteQuery] = useLazyQuery(GET_MAGENTO_WEBSITES, { fetchPolicy: "network-and-cache" });

  

  useEffect(() => {
    if (toolName === "Shopify") {
      allWebsiteQuery();
    } else if (toolName === "Wordpress") {
      wordpressWebsiteQuery();
    }else if (toolName === "Magento") {
      magentoWebsiteQuery();
    }
  }, [toolName]);

  useEffect(() => {
    const onCompleted = data => {
      console.log("first", data?.GetWorpressUserWebsites);
      if (toolName === "Shopify") {
        setLoading(false);
        setWebsiteData(data?.GetShopifyUserWebsites || []);
      } else if (toolName === "Wordpress") {
        setLoading(false);
        setWebsiteData(data?.GetWorpressUserWebsites || []);
        // setLoading(false);
      }
      else if (toolName === "Magento") {
        setLoading(false);
        setWebsiteData(data?.GetMagentoUserWebsites || []);
      
      }else{
        setLoading(false);
      }
    };

    const onError = error => {
      console.error(error);
    };

    if (toolName === "Shopify") {
      allWebsiteQuery({ onCompleted, onError });
    } else if (toolName === "Wordpress") {
      wordpressWebsiteQuery({ onCompleted, onError });
    }
    else if (toolName === "Magento") {
      magentoWebsiteQuery({ onCompleted, onError });
    }
  }, [toolName]);

  const handleWebsiteClick = item => {
    if (item && !item?.isConnected) {
      const pathname = `/app-integrate/website-details/${item.id}`;
      const state = {
        toolName,
        domain: item.website,
      };
      navigate(pathname, { state });
    }
  };

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} md={12}>
              <Card
                sx={{ padding: { xs: "8px 15px 20px 15px", sm: "30px 45px 24px 40px" }, border: `1px solid ${theme.palette.divider}` }}
                elevation={customizer.isCardShadow ? 9 : 0}
                variant={!customizer.isCardShadow ? "outlined" : undefined}
              >
                <Typography component="h2" className="MainHeading">
                  Website Url's List
                </Typography>
                <TableContainer component={Paper} className="TableMainWrapper">
                  <Table
                    sx={{
                      border: customizer.activeMode === "dark" ? "1px solid #333F55" : "1px solid #e5eaef",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Website</TableCell>
                        <TableCell>Connected</TableCell>
                      </TableRow>
                    </TableHead>

                    {loading ? (
                      <MainLoader />
                    ) : (
                      <TableBody>
                        {websiteData.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={2} align="center" style={{ fontStyle: 'italic', color: '#999' }}>
                              No data found
                            </TableCell>
                          </TableRow>
                        ) : (
                          websiteData.map((item, index) => (
                            <TableRow key={index} onClick={() => handleWebsiteClick(item)} style={{ cursor: !item?.isConnected ? "pointer" : "default" }}>
                              <TableCell>
                                <Typography variant="body1">{item.website}</Typography>
                              </TableCell>
                              <TableCell>{item?.isConnected ? <CheckCircleIcon sx={{ color: "#008000" }} /> : <CancelIcon sx={{ color: "#ff0000" }} />}</TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

export default WebsiteContent;
