import { useState, useMemo, useEffect } from "react";
import { useLazyQuery, useSubscription, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import Papa from "papaparse";
import { SCRAPY_SUBSCRIPTION } from "../../gql/utils";
import { SCRAP_WEBSITE } from "../../gql/mutations/scrapy";
import { GET_SCRAPY_BY_SESSION } from "../../gql/queries/scrapy";
import { Spinner } from "../Spinner";

function Scrapy() {
  const [ScrapUrl] = useMutation(SCRAP_WEBSITE);
  const [getScrapyBySession] = useLazyQuery(GET_SCRAPY_BY_SESSION, { fetchPolicy: "network-only" });
  const [scrapedSession, setScrapedSession] = useState();
  const [scrapLoading, setScrapLoading] = useState(false);

  const { userData } = useSelector(state => state.reducer);
  const { id } = userData || {};
  const { data } = useSubscription(SCRAPY_SUBSCRIPTION, {
    variables: { sessionId: localStorage.getItem("sessionId") },
  });
  const { scrapyResponse } = data || {};
  const { isCompleted, sessionId, authorId } = scrapyResponse || {};

  const formData = {
    url: "",
    message: "",
    // error: "",
    showForm: true,
  };
  const [values, setValues] = useState(formData);
  const { url } = values;

  const canBuy = useMemo(() => id === authorId && sessionId === localStorage.getItem("sessionId") && isCompleted, [isCompleted, sessionId, authorId, id]);

  useEffect(() => {
    if (canBuy) {
      setScrapedSession(sessionId);
      setScrapLoading(false);
    }
    // eslint-disable-next-line
  }, [canBuy]);

  const pattern = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\n?]+)/;

  const handleChange = name => e => {
    setValues({ ...values, message: "", error: "", [name]: e.target.value });
  };
  const handleSubmit = async e => {
    e.preventDefault();
    if (id && url) {
      const match = url.match(pattern);
      setScrapLoading(true);
      await ScrapUrl({
        variables: {
          payload: {
            domain: match[1],
            authorId: id,
          },
        },
      })
        .then(res => {
          if (res.data && res.data.ScrapUrl) {
            localStorage.setItem("sessionId", res.data.ScrapUrl.sessionId);
          } else {
            setScrapLoading(false);
          }
        })
        .catch(() => {
          setScrapLoading(false);
        });
    }
  };

  const scrapResp = async () =>
    new Promise((resolve, reject) => {
      getScrapyBySession({
        variables: {
          sessionId: scrapedSession,
        },
      })
        .then(res => {
          if (res.data && res.data.GetScrapyBySession) {
            resolve(res.data.GetScrapyBySession);
          } else {
            reject(new Error("error"));
          }
        })
        .catch(() => {
          reject(new Error("error"));
        });
    });

  const config = {
    quotes: true, // or array of booleans
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ",",
    header: true,
    newline: "\r\n",
    skipEmptyLines: false, // other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
    columns: null, // or array of strings
  };

  const downloadScrapy = () => {
    scrapResp()
      .then(res => {
        const csvRes = Papa.unparse(res, config);
        const blob = new Blob([csvRes], { type: "text/csv;charset=utf-8;" });
        const urlD = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = urlD;
        link.setAttribute("download", "myData.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(() => {});
  };

  const refresh = () => {
    setScrapedSession("");
    setScrapLoading(false);
    setValues(formData);
  };

  return (
    <div className="container">
      <h2>Create a project</h2>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="form-group pt-5">
          <input type="text" onChange={handleChange("url")} className="form-control" value={url} placeholder="Provide Domain" required />
        </div>
        {!scrapLoading ? (
          !scrapedSession && (
            <div>
              <button className="btn btn-primary" type="submit">
                Send
              </button>
            </div>
          )
        ) : (
          <Spinner />
        )}
      </form>
      {scrapedSession && (
        <button className="btn btn-primary" onClick={downloadScrapy} type="button">
          Download CSV
        </button>
      )}

      {scrapedSession && (
        <button style={{ marginLeft: "10px" }} className="btn btn-primary" onClick={refresh} type="button">
          Try New
        </button>
      )}
    </div>
  );
}

export { Scrapy };
