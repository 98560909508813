/* eslint-disable */
import { lazy, useState } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { Box, Card, Container, Grid, ToggleButton, ToggleButtonGroup, Typography, styled, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

import { useAllUserDetails } from "../../hooks/user";
import MainLoader from "../MainLoader/MainLoader";
import { DatePickerRangeComponent } from "../Analytic-Dashboard/DatePickerRange";
import { DownTimeGraphValue, GetUptimStatus, useDownTimeGraphValue } from "../../hooks/getUptimeRecords";
import { useParams } from "react-router";
// import { DatePickerRangeComponent } from "./DatePickerRange";

const Chart = lazy(() => import("react-apexcharts"), { ssr: false });
const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .MainHeadingWrapper": {
    "& .MainHeading": { fontSize: "18px", fontWeight: "600" },
  },
}));
const BoxStyled = styled(Box)(() => ({
  padding: "30px",
  transition: "0.1s ease-in",
  cursor: "pointer",
  color: "inherit",
  textAlign: "center",
  borderRadius: "4px",
  "&:hover": {
    transform: "scale(1.03)",
  },
}));
const BoxMainWrapper = styled(Box)(({ theme }) => ({
  "& .datagridnopage": {
    // borderColor: "rgba(251, 252, 253, 1)",
    border: "2px solid" + theme.palette.divider,

    // "& .datagridnopage .MuiDataGrid-footerContainer": {
    //   display: "none",
    // },
    "& .MuiDataGrid-main": {
      "& .MuiDataGrid-columnHeaders": {
        borderColor: theme.palette.divider,
        borderWidth: "2px",
        "& .MuiDataGrid-columnSeparator": {
          color: theme.palette.text.primary + "26",
          visibility: "visible",
          // opacity: "0.6 !important",
        },
        "& .MuiDataGrid-columnHeadersInner": {
          width: "100%",
          '& div[role="row"]': {
            width: "100%",
            "& .MuiDataGrid-columnHeader": {
              flex: 1,
              width: "auto !important",
              maxWidth: "unset !important",
              outline: "none !important",
              "&:first-child": { maxWidth: "100px !important", "& .MuiDataGrid-columnHeaderTitleContainer": { justifyContent: "left" } },
              "& .MuiDataGrid-columnHeaderTitleContainer": { justifyContent: "start" },
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "14px",
            color: theme.palette.text.primary,
            fontWeight: 600,
          },
        },
      },
      "& .MuiDataGrid-virtualScroller": {
        // minHeight: "350px",
        "&::-webkit-scrollbar-track": {
          // WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
          borderRadius: "10px",
          backgroundColor: theme.palette.text.primary + "0d",
        },
        "&::-webkit-scrollbar": { width: "6px", backgroundColor: "transparent" },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: theme.palette.primary.main,
        },
        "& .MuiDataGrid-virtualScrollerContent": {
          height: "470px !important",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              boxShadow: "inset 0px 1px " + theme.palette.divider,
              minHeight: "70px !important",
              border: "none",
            },
            width: "100%",
            '& div[role="row"]': {
              width: "100%",
              cursor: "pointer",
              "& .MuiDataGrid-cell": {
                minHeight: "70px !important",
                flex: 1,
                width: "auto !important",
                maxWidth: "unset !important",
                justifyContent: "start",
                borderColor: theme.palette.divider,
                "&:focus-within": { outline: "none" },
                "&:first-child": { maxWidth: "100px !important", justifyContent: "left" },
                "&:last-child": {
                  display: "none",
                },
              },
              "& .MuiDataGrid-cellContent": {
                color: theme.palette.text.primary,
                fontWeight: 600,
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-footerContainer": {
      borderColor: theme.palette.divider,
      "& .MuiTablePagination-toolbar": {
        "& > p": { marginBottom: "0" },
      },
    },
  },
  "& h3": {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "20px",
    color: theme.palette.text.primary,
  },
}));
// const DatePickerStyleWrapper = {
//   justifyContent: "flex-end",
//   flexDirection: "row",
//   gap: "15px",
//   paddingTop: "0",
//   "@media screen and (max-width: 500px)": {
//     flexDirection: "column",
//   },
//   "& .MuiFormControl-root.MuiTextField-root": {
//     minWidth: "500px",
//     "@media screen and (max-width: 821px)": {
//       minWidth: "auto",
//       flex: 1,
//     },
//   },
//   "& .FetchBtn": { marginTop: "0" },
// };

function DowntimeMonitoring() {
  const theme = useTheme();
  const { projectid } = useParams();
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const { downTimeData, isLoading, getData, totalDownTime, totalUpTime } = useDownTimeGraphValue(projectid, startDate, endDate);
  const [isFetchDate, setIsFetchDate] = useState(false);
  const { downtimeArray, datesArray } = downTimeData || {};

  const customizer = useSelector(state => state.customizer);
  const borderColor = theme.palette.divider;
  const primary = theme.palette.primary.main;
  const secondary = theme.palette.secondary.main;

  const handleDateRangeChange = newValue => {
    const [newStart, newEnd] = newValue;
    setStartDate(newStart ? new Date(newStart) : null);
    setEndDate(newEnd ? new Date(newEnd) : null);
    setIsFetchDate(false);
  };
  const handleFatch = () => {
    try {
      getData();
      setIsFetchDate(true);
    } catch (err) {
      console.log(err);
    }
  };

  const optionscolumnchart = {
    chart: {
      type: "bar",
      fontFamily: "'Plus Jakarta Sans', sans-serif;",
      foreColor: "#adb0bb",
      toolbar: {
        show: true,
      },
      height: 360,
      stacked: true,
    },
    colors: [secondary],
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: "60%",
        columnWidth: "20%",
        borderRadius: [6],
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
      },
    },

    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      borderColor: "rgba(0,0,0,0.1)",
      strokeDashArray: 3,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      min: -24,
      max: 24,
      tickAmount: 10,
    },
    xaxis: {
      categories: datesArray || [],
      axisBorder: {
        show: false,
      },
    },
    tooltip: {
      theme: theme.palette.mode === "dark" ? "dark" : "light",
      fillSeriesColor: false,
    },
  };
  //   const seriescolumnchart = [
  //     {
  //       name: "UpTime",
  //       data: data?.success ? data.uptimePerDay.map(item => Math.round(item / 60)) : 0,
  //     },
  //     {
  //       name: "Down Time",
  //       data: data?.success ? data.downtimePerDay.map(item => Math.round(item / 60)) : 0,
  //     },
  //   ];
  const seriescolumnchart = [
    {
      name: "Down Time",
      data: Array.isArray(downtimeArray) && downTimeData ? downTimeData?.downtimeArray.map(item => Math.round(item / 60)) : 0,
    },
  ];
  // const seriescolumnchart = [];
  const columns = [
    { field: "id", headerName: "No." },
    { field: "date", headerName: "Date" },
    { field: "duration", headerName: "Duration" },
  ];
  const durationFormatter = values => {
    if (!values) return 0;
    const duration = values / 60;
    const formattedDuration = duration === 0 ? duration.toFixed(2) : duration.toFixed(2);
    const hours = Math.floor(formattedDuration);
    const minutes = Math.round((formattedDuration - hours) * 60);
    return `${hours} Hour ${minutes} minute`;
  };
  const rows = datesArray?.map((item, indx) => ({
    id: indx + 1,
    date: item,
    duration: `${-downtimeArray[indx] < 60 ? -downtimeArray[indx] : durationFormatter(-downtimeArray[indx])} ${-downtimeArray[indx] < 60 ? "Minute" : ""}`,
  }));
  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} lg={12}>
              <Card
                sx={{ padding: "30px 45px 24px 40px", border: customizer.isCardShadow ? `1px solid ${borderColor}` : "none", height: "100%" }}
                elevation={customizer.isCardShadow ? 9 : 0}
                variant={!customizer.isCardShadow ? "outlined" : undefined}
              >
                <Grid container spacing={2} mt={0} sx={{ justifyContent: "center", alignItems: "center" }}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box className="MainHeadingWrapper">
                      <Typography component="h5" className="MainHeading">
                        Website down time checker
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={8}>
                    <DatePickerRangeComponent handleFetchData={handleFatch} handleDateRangeChange={handleDateRangeChange} startDate={startDate} endDate={endDate} />
                  </Grid>
                </Grid>
                {isLoading ? (
                  <MainLoader message="Loading..." />
                ) : (
                  <Grid container spacing={2} mt={0} sx={{}}>
                    <Grid item xs={12} md={6} lg={3}>
                      <BoxStyled sx={{ backgroundColor: "primary.light", color: "primary.main" }}>
                        <Typography variant="h3">{Math.round(totalUpTime / 60)} hrs</Typography>
                        <Typography variant="h6">Total uptime</Typography>
                      </BoxStyled>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <BoxStyled sx={{ backgroundColor: "secondary.light", color: "secondary.main" }}>
                        <Typography variant="h3">{Math.round(totalDownTime / 60)} hrs</Typography>
                        <Typography variant="h6">Total downtime</Typography>
                      </BoxStyled>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="HeaderWrapper">
                        {" "}
                        <Chart options={optionscolumnchart} series={seriescolumnchart} type="bar" height={360} width="100%" />
                      </Box>
                    </Grid>
                    {/*  <Grid>
                      <ToggleButtonGroup color="primary" value={toggleValue} exclusive onChange={e => handleToggleValueChange(e)} aria-label="Platform">
                        <ToggleButton value="signup">Signup</ToggleButton>
                        <ToggleButton value="login">Login</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>*/}
                    <Grid item xs={12} md={12} lg={12}>
                      <BoxMainWrapper>
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          className="datagridnopage"
                          disableSelectionOnClick
                          pagination={rows.length > 30}
                          pageSize={rows.length > 30 ? 5 : rows.length}
                          rowsPerPageOptions={rows.length > 30 ? [5, 10, 20, 50] : []}
                          hideFooterPagination={rows.length <= 30}
                        />
                      </BoxMainWrapper>
                    </Grid>
                  </Grid>
                )}
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default DowntimeMonitoring;
