/* eslint-disable */
import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Box, Button, CircularProgress, Modal, Tab, Typography, styled } from "@mui/material";
import CustomTextField from "../forms/theme-elements/CustomTextField";
import CustomFormLabel from "../forms/theme-elements/CustomFormLabel";
import { validateDescription } from "../../utils/validations";
import { usePopup } from "../../context/commonPop";
import useQuery from "../../hooks/allQuery";
import { CHECK_METADATA_N_TITLE, GENERATE_METADATA_N_TITLE } from "../../gql/mutations/scrapy";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DataGrid } from "@mui/x-data-grid";
import { GetMetaDataDescTitle } from "../../hooks/utils";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

const ModalStyleWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  backgroundColor: theme.palette.background.default,
  borderRadius: "15px",
  padding: 30,
  "@media screen and (max-width:820px)": {
    width: 600,
  },
  "@media screen and (max-width:600px)": {
    width: "90%",
  },
  "& form": {
    marginBottom: "0",
    "& .MainHeading": {
      fontSize: "20px",
      color: theme.palette.text.primary,
      fontWeight: "700",
    },
    "& .TabLabelWrapper": {
      marginTop: "28px",
      "& .MuiTabs-scroller": {
        "& .MuiTabs-flexContainer": {
          gap: "25px",
          "& button": {
            flex: "1",
            fontWeight: 600,
            fontSize: "16px",
            letterSpacing: "1px",
            border: "2px solid transparent",
            maxWidth: "unset !important",
            minWidth: "auto !important",
            borderRadius: "7px",
            backgroundColor: theme.palette.primary.main + "30",
            color: theme.palette.primary.main,
            "&.Mui-selected": {
              backgroundColor: theme.palette.primary.main,
              color: "#ffffff",
            },
            "&:focus": { outline: "none" },
          },
        },
        "& span.MuiTabs-indicator": {
          display: "none",
        },
      },
    },
    "& .TabBodyDataWrapper": {
      maxHeight: "500px",
      minHeight: "500px",
      overflowY: "auto",
      "&::-webkit-scrollbar-track": {
        borderRadius: "10px",
        backgroundColor: theme.palette.text.primary + "0d",
      },
      "&::-webkit-scrollbar": { width: "5px", backgroundColor: "transparent" },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
        backgroundColor: theme.palette.primary.main,
      },
      "& .MuiTabPanel-root": {
        padding: "0",
        "& label": {
          color: theme.palette.text.primary,
          "& a.URL": { color: theme.palette.primary.main },
        },
      },
      "& .datagridnopage": {
        border: "2px solid" + theme.palette.divider,
        marginTop: "37px",
        "& .MuiDataGrid-main": {
          "& .MuiDataGrid-columnHeaders": {
            borderColor: theme.palette.divider,
            borderWidth: "2px",
            "& .MuiDataGrid-columnSeparator": {
              color: theme.palette.text.primary + "26",
              visibility: "visible",
            },
            "& .MuiDataGrid-columnHeadersInner": {
              width: "100%",
              '& div[role="row"]': {
                width: "100%",
                "& .MuiDataGrid-columnHeader": {
                  flex: 1,
                  width: "auto !important",
                  maxWidth: "unset !important",
                  outline: "none !important",
                  "&:first-of-type": { maxWidth: "60px !important", minWidth: "auto !important", "& .MuiDataGrid-columnHeaderTitleContainer": { justifyContent: "left" } },
                  // "&:nth-of-type(2)": { maxWidth: "245px !important", minWidth: "auto !important", "& .MuiDataGrid-columnHeaderTitleContainer": { justifyContent: "left" } },
                  "&:last-of-type": { "& .MuiDataGrid-columnHeaderTitleContainer": { justifyContent: "left" } },
                  "& .MuiDataGrid-columnHeaderTitleContainer": { justifyContent: "start" },
                },
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontSize: "14px",
                color: theme.palette.text.primary,
                fontWeight: 600,
              },
            },
          },
          "& .MuiDataGrid-virtualScroller": {
            "&::-webkit-scrollbar-track": {
              borderRadius: "10px",
              backgroundColor: theme.palette.text.primary + "0d",
            },
            "&::-webkit-scrollbar": { width: "6px", backgroundColor: "transparent" },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
              backgroundColor: theme.palette.primary.main,
            },
            "& .MuiDataGrid-virtualScrollerContent": {
              height: "400px !important",
              "& .MuiDataGrid-virtualScrollerRenderZone": {
                "& .MuiDataGrid-row": {
                  boxShadow: "inset 0px 1px " + theme.palette.divider,
                  minHeight: "70px !important",
                  maxHeight: "unset !important",
                  border: "none",
                },
                width: "100%",
                '& div[role="row"]': {
                  width: "100%",
                  cursor: "pointer",
                  "& .MuiDataGrid-cell": {
                    minHeight: "70px !important",
                    maxHeight: "unset !important",
                    flex: 1,
                    width: "auto !important",
                    maxWidth: "unset !important",
                    justifyContent: "start",
                    borderColor: theme.palette.divider,
                    "&:focus-within": { outline: "none" },
                    "&:first-of-type": { maxWidth: "60px !important", minWidth: "auto !important", justifyContent: "left" },
                    // "&:nth-last-child(3)": { maxWidth: "245px !important", justifyContent: "left" },
                    "&:last-child": {
                      display: "none",
                    },
                    "& .AccordionWrapper": {
                      marginTop: "10px !important",
                      marginBottom: "10px !important",
                      width: "100%",
                      "& .BodyContentWrapper": {
                        whiteSpace: "normal",
                        "& p": {
                          border: "1px solid" + theme.palette.divider,
                          marginBottom: "10px",
                          padding: "12px 15px 12px",
                          borderRadius: "10px",
                          "& span": {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "6px",
                            fontWeight: 900,
                            "& svg": {
                              fontSize: "20px",
                              color: theme.palette.primary.main,
                            },
                          },
                        },
                      },
                    },
                  },
                  "& .MuiDataGrid-cellContent": {
                    color: theme.palette.text.primary,
                    fontWeight: 600,
                    whiteSpace: "break-spaces",
                  },
                },
              },
            },
          },
        },
        "& .MuiDataGrid-footerContainer": {
          display: "none",
          borderColor: theme.palette.divider,
          "& .MuiTablePagination-toolbar": {
            "& > p": { marginBottom: "0" },
          },
        },
      },
      "& .GenerateInnerTabWrapper": {
        "& .MuiTabs-root": {
          "& button": {
            "&:focus": {
              outline: "none",
            },
          },
        },
        "& .AiGenerateRefWrapper": {
          marginTop: "24px",
          marginBottom: "24px",
          "& .URL": { color: theme.palette.primary.main },
        },
        "& .GenerateByAi": {
          minWidth: "128px",
          minHeight: "35px",
        },
        "& .TitleMetaDescriptionWrapper": {
          marginTop: "24px",
          "& p": {
            marginBottom: "10px",
            "& strong": {
              display: "block",
              marginBottom: "2px",
              fontSize: "16px",
            },
          },
        },
      },
    },

    "& .SubmitBtn": {
      marginTop: "15px",
      "& button": {
        minWidth: "128px",
        minHeight: "35px",
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: "#fff",
        },
      },
    },
  },
}));

const descriptionTableColumns = [
  { field: "id", headerName: "No." },
  { field: "meta_description", headerName: "Meta Description" },
];

const titleTableColumns = [
  { field: "id", headerName: "No." },
  { field: "title", headerName: "Title" },
];

function TitleForm({ open, setOpen, url, params }) {
  const [data, setData] = useState({ keywords: "", description: "", submitDisable: false });
  const [errorClass, setErrorClass] = useState({
    keywords: false,
    description: false,
  });
  const { handleOpen } = usePopup();
  const [valuetab, setValueTab] = useState("1");
  const [nestedTab, setNestedTab] = useState("3");
  const [titleData, setTitledata] = useState(null);
  const [manualData, setManualData] = useState(null);
  const { DataGridData, callTableData, setDataGridData } = GetMetaDataDescTitle();
  const [loading, setLoading] = useState(false);
  const [checkMetaNTitle] = useMutation(CHECK_METADATA_N_TITLE);
  const { sessionId, res } = params || {};
  const subFlag = res.split("&")[0];
  const handleTabChange = (event, newValue) => {
    setValueTab(newValue);
  };
  const handleNestedTabChange = (event, newValue) => {
    setNestedTab(newValue);
  };

  const [generateMetaDataAndTitle] = useMutation(GENERATE_METADATA_N_TITLE, { fetchPolicy: "network-only" });
  const { allQuery } = useQuery();

  const handleChange = async e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  function checkError(item) {
    const keywords = validateDescription(item.keywords);
    const description = validateDescription(item.description);
    setErrorClass({
      ...errorClass,
      keywords,
      description,
    });
    return keywords || description;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const check = checkError(data);
    if (check) return;
    try {
      const { keywords, description } = data || {};

      setData({ ...data, submitDisable: true });

      if (keywords.trim().length == 0 && description.trim().length == 0) {
        setData({ keywords: "", description: "", submitDisable: false });
        throw new Error("required tiltle or meta description keyword");
      }

      allQuery({
        QUERY: generateMetaDataAndTitle,
        variables: {
          payload: {
            keywords: data.keywords.trim(),
            description: data.description.trim(),
            flag: res,
            sessionId: sessionId,
            url,
          },
        },
        onCompleteFunc: ({ generateMetaDataAndTitle }) => {
          const { title, meta_description } = generateMetaDataAndTitle || {};
          if (title && meta_description) {
            const NewData = { id: DataGridData?.length + 1, title, meta_description };
            setDataGridData(prev => {
              let newDataArray = [...prev, NewData];
              if (newDataArray.length > 10) {
                newDataArray = newDataArray.slice(newDataArray.length - 10);
              }
              return newDataArray;
            });
          }
          setManualData(generateMetaDataAndTitle);
          setData({ keywords: "", description: "", submitDisable: false });
        },
        onErrorFunc: ({ message }) => {
          handleOpen(true, message);
          setData({ keywords: "", description: "", submitDisable: false });
        },
      });
    } catch (error) {
      handleOpen(true, error.message || "Server Error");
    }
  }
  useEffect(() => {
    if (url) {
      callTableData({ url, sessionId, flag: res });
    }
  }, [url]);

  const handleClick = async () => {
    setLoading(true);
    try {
      if (url) {
        const response = await checkMetaNTitle({
          variables: {
            inputArg: {
              flag: res,
              sessionId,
              url,
            },
          },
        });
        setTitledata(response?.data?.checkMetaDataAndTitle);
        if (response && response.data && response?.data?.checkMetaDataAndTitle) {
          const newData = {
            id: DataGridData?.length + 1,
            title: subFlag === "titleSort" || subFlag === "titleTagMissing" ? response?.data?.checkMetaDataAndTitle?.title : undefined,
            meta_description: subFlag !== "titleSort" && subFlag !== "titleTagMissing" ? response?.data?.checkMetaDataAndTitle?.meta_description : undefined,
          };

          setDataGridData(prev => {
            const newDataArray = [...prev, newData];
            return newDataArray.length > 10 ? newDataArray.slice(-10) : newDataArray;
          });
        }
      }
      setLoading(false);
    } catch (err) {
      handleOpen(true, err?.message || "Server Error");
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setData({ keywords: "", description: "" });
        setOpen(false);
        setValueTab("1");
        setTitledata(null);
        setManualData(null);
      }}
    >
      <ModalStyleWrapper>
        <Box component="form">
          <Typography component="h3" className="MainHeading">
            Title and Meta Description
          </Typography>

          <TabContext value={valuetab}>
            <Box className="TabLabelWrapper">
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label="Generate" value="1" />
                <Tab label="Generated Title and MetaDescription" value="2" />
              </TabList>
            </Box>
            <Box className="TabBodyDataWrapper">
              <TabPanel value="1">
                <TabContext value={nestedTab}>
                  <Box className="GenerateInnerTabWrapper">
                    <TabList onChange={handleNestedTabChange}>
                      <Tab label="Ai Generate" value="3" />
                      <Tab label="Manual Generate" value="4" />
                    </TabList>
                    <TabPanel value="3">
                      <Typography component="p" className="AiGenerateRefWrapper">
                        Enhance your webpage effortlessly! With a simple click, generate captivating titles and descriptions tailored to your page's URL:{" "}
                        <Typography component="a" href={url} target="_blank" className="URL">
                          {url}
                        </Typography>
                        . Our user-friendly AI tool streamlines optimization, boosting your site's visibility and attracting more visitors. Say goodbye to guesswork and hello to
                        effective SEO strategies. Elevate your online presence today!
                      </Typography>
                      <Button onClick={() => handleClick()} className="GenerateByAi" disabled={loading ? true : false}>
                        {loading ? <CircularProgress size={20} thickness={7} /> : "Generate By Ai"}
                      </Button>
                      {titleData && (
                        <Box className="TitleMetaDescriptionWrapper">
                          {titleData.title && (
                            <Typography component="p">
                              <strong>Title</strong> {titleData?.title || ""}
                            </Typography>
                          )}
                          {titleData?.meta_description && (
                            <Typography component="p">
                              <strong>MetaDescription</strong> {titleData.meta_description || ""}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </TabPanel>
                    <TabPanel value="4">
                      <CustomFormLabel htmlFor="url">
                        URL :-{" "}
                        <Typography component="a" href={url} target="_blank" className="URL">
                          {url || ""}
                        </Typography>
                      </CustomFormLabel>
                      <CustomFormLabel htmlFor="keywords">Keywords</CustomFormLabel>
                      <CustomTextField
                        id="keywords"
                        name="keywords"
                        multiline
                        rows={1}
                        variant="outlined"
                        fullWidth
                        required
                        value={data.keywords}
                        onChange={handleChange}
                        error={errorClass.keywords}
                        helperText={errorClass.keywords && "please enter keywords atleast 15 character long!"}
                      />
                      <CustomFormLabel htmlFor="description">Description</CustomFormLabel>
                      <CustomTextField
                        id="description"
                        name="description"
                        multiline
                        rows={1}
                        variant="outlined"
                        fullWidth
                        required
                        value={data.description}
                        onChange={handleChange}
                        error={errorClass.description}
                        helperText={errorClass.description && "please enter a description atleast 15 character long!"}
                      />
                      <Box className="SubmitBtn">
                        <Button onClick={handleSubmit} disabled={data.submitDisable ? true : false}>
                          {data.submitDisable ? <CircularProgress size={20} thickness={7} /> : "Submit"}
                        </Button>
                      </Box>
                      {manualData && (
                        <Box className="TitleMetaDescriptionWrapper">
                          {manualData.title && (
                            <Typography component="p">
                              <strong>Title</strong> {manualData?.title || ""}
                            </Typography>
                          )}
                          {manualData.meta_description && (
                            <Typography component="p">
                              <strong>MetaDescription</strong> {manualData?.meta_description || ""}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </TabPanel>
                  </Box>
                </TabContext>
              </TabPanel>
              <TabPanel value="2">
                <DataGrid
                  rows={DataGridData}
                  columns={subFlag === "titleSort" || subFlag === "titleTagMissing" ? titleTableColumns : descriptionTableColumns}
                  className="datagridnopage"
                  pageSize={5}
                  disableSelectionOnClick
                />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </ModalStyleWrapper>
    </Modal>
  );
}

export default TitleForm;
