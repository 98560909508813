import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router";

function Analytic() {
  const navigate = useNavigate();

  const handleClick = data => {
    navigate(data);
  };
  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Button onClick={() => handleClick("/users")}>Signup User</Button>
      <Button onClick={() => handleClick("/reports?menu=headerAnalyticDashboard")}>Report Details</Button>
      <Button onClick={() => handleClick("/all")}>All Details</Button>
      <Button>Uptime Monitoring</Button>
      <Button onClick={() => handleClick("/ticketList?menu=headerAnalyticDashboard")}>Tickets</Button>
    </Box>
  );
}

export default Analytic;
