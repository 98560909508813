import gql from "graphql-tag";

export const SCRAPYREPORT_SUBSCRIPTION = gql`
  subscription Subscription($sessionId: String!) {
    documentUpdated(sessionId: $sessionId)
  }
`;

export const SCREAPYSESSIONDATA_SUBSCRIPTION = gql`
  subscription Subscription {
    screapyComplete
  }
`;
