import { useState } from "react";
import { Box, Container, Grid, styled } from "@mui/material";
import Breadcrumb from "../shared/Breadcrumb";
import ChildCard from "../shared/ChildCard";
import TicketAdminFilter from "./TicketFilter";
import TicketAdminListing from "./TicketListing";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  padding: "30px 0 60px",
  "& .createTicket": {
    marginBottom: "20px",
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  "& h4": {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
}));
const BCrumb = [
  {
    to: "/",
    title: "Home",
  },
  {
    title: "Tickets",
  },
];

function TicketAdminList() {
  const [ticketsFetch, setTicketsFetch] = useState([]);

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={12}>
              <Breadcrumb title="Tickets" items={BCrumb} />
              <ChildCard>
                <TicketAdminFilter ticketsFetch={ticketsFetch} />
                <TicketAdminListing setTicketsFetch={setTicketsFetch} ticketsFetch={ticketsFetch} />
              </ChildCard>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default TicketAdminList;
