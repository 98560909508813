// import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import lightlogoImage from "../../Images/hotSpotLogoWhite.svg";
import darklogoImage from "../../Images/hotSpotLogoBlue.svg";

const LoaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100vh - 64px)",
  backgroundColor: theme.palette.background.default,
  "& .Logo": {
    width: "95px",
    height: "auto",
    animation: "Blink 1.2s infinite linear both",
    "@keyframes Blink": {
      "0%": { opacity: "0.2" },
      "50%": { opacity: "1" },
      "100%": { opacity: "0.2" },
    },
  },
  "& .LoaderText": {
    fontSize: "18px",
    marginTop: "6px",
    fontWeight: 700,
    marginRight: "-40px",
    "& span": {
      display: " inline-block",
      animation: "sk-bouncedelay 1.2s infinite ease-in-out both",
      width: "10px",
      height: "10px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      "&.bounce1": {
        animationDelay: "-0.32s",
      },
      "&.bounce2": {
        animationDelay: "-0.16s",
      },

      "@keyframes sk-bouncedelay": {
        "0%, 80%, 100%": { transform: "scale(0)" },
        "40%": { transform: "scale(0.5)" },
      },
    },
  },
}));

function MainLoader({ message, SX }) {
  //   const theme = useTheme();
  const customizer = useSelector(state => state.customizer);
  return (
    <LoaderContainer sx={SX}>
      {/* <CircularProgress sx={{ color: theme.palette.primary.main }} size={100} /> */}
      <Typography component="img" src={customizer.activeMode === "dark" ? lightlogoImage : darklogoImage} alt="" className="Logo" />
      {message && (
        <Typography component="h5" color="textSecondary" className="LoaderText">
          {message}
          <Box component="span" className="bounce1"></Box>
          <Box component="span" className="bounce2"></Box>
          <Box component="span" className="bounce3"></Box>
        </Typography>
      )}
    </LoaderContainer>
  );
}

export default MainLoader;
