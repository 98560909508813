import { useLocation, useParams } from "react-router-dom";
import { Box, Card, Container, Grid, styled, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { useAllSiteAuditData } from "../../hooks/scrapyData";
import MainLoader from "../MainLoader/MainLoader";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .MainHeadingWrapper": {
    "& .MainHeading": { fontSize: "18px", fontWeight: "600", marginBottom: "15px" },
  },
}));
const BoxMainWrapper = styled(Box)(({ theme }) => ({
  "& .datagridnopage": {
    // borderColor: "rgba(251, 252, 253, 1)",
    border: "2px solid" + theme.palette.divider,

    // "& .datagridnopage .MuiDataGrid-footerContainer": {
    //   display: "none",
    // },
    "& .MuiDataGrid-main": {
      "& .MuiDataGrid-columnHeaders": {
        borderColor: theme.palette.divider,
        borderWidth: "2px",
        "& .MuiDataGrid-columnSeparator": {
          color: theme.palette.text.primary + "26",
          visibility: "visible",
          // opacity: "0.6 !important",
        },
        "& .MuiDataGrid-columnHeadersInner": {
          width: "100%",
          '& div[role="row"]': {
            width: "100%",
            "& .MuiDataGrid-columnHeader": {
              flex: 1,
              width: "auto !important",
              maxWidth: "unset !important",
              outline: "none !important",
              "&:first-child": { maxWidth: "100px !important", "& .MuiDataGrid-columnHeaderTitleContainer": { justifyContent: "left" } },
              "& .MuiDataGrid-columnHeaderTitleContainer": { justifyContent: "start" },
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "14px",
            color: theme.palette.text.primary,
            fontWeight: 600,
          },
        },
      },
      "& .MuiDataGrid-virtualScroller": {
        // minHeight: "350px",
        "&::-webkit-scrollbar-track": {
          // WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
          borderRadius: "10px",
          backgroundColor: theme.palette.text.primary + "0d",
        },
        "&::-webkit-scrollbar": { width: "6px", backgroundColor: "transparent" },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: theme.palette.primary.main,
        },
        "& .MuiDataGrid-virtualScrollerContent": {
          height: "470px !important",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              boxShadow: "inset 0px 1px " + theme.palette.divider,
              minHeight: "70px !important",
              border: "none",
            },
            width: "100%",
            '& div[role="row"]': {
              width: "100%",
              cursor: "pointer",
              "& .MuiDataGrid-cell": {
                minHeight: "70px !important",
                flex: 1,
                width: "auto !important",
                maxWidth: "unset !important",
                justifyContent: "start",
                borderColor: theme.palette.divider,
                "&:focus-within": { outline: "none" },
                "&:first-child": { maxWidth: "100px !important", justifyContent: "left" },
                "&:last-child": {
                  display: "none",
                },
              },
              "& .MuiDataGrid-cellContent": {
                color: theme.palette.text.primary,
                fontWeight: 600,
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-footerContainer": {
      borderColor: theme.palette.divider,
      "& .MuiTablePagination-toolbar": {
        "& > p": { marginBottom: "0" },
      },
    },
  },
  "& h3": {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "20px",
    color: theme.palette.text.primary,
  },
}));
const DataNotWrapper = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100vh - 64px)",
};
const columns = [
  {
    field: "id",
    headerName: "No.",
  },
  {
    field: "userName",
    headerName: "User Name",
    flex: 0.2,
    maxWidth: 270,
  },
  {
    field: "projectName",
    headerName: "Project Name",
    flex: 0.2,
    maxWidth: 270,
  },
  {
    field: "schedule",
    headerName: "Schedule Time",
    flex: 0.2,
    maxWidth: 270,
  },
];

function ScheduledReport() {
  const { frequency } = useParams();
  const location = useLocation();
  const { startDate, endDate } = location?.state || {};
  const { siteAuditDetails, loading } = useAllSiteAuditData(startDate, endDate, frequency);
  const theme = useTheme();
  const customizer = useSelector(state => state.customizer);
  const borderColor = theme.palette.divider;

  return loading ? (
    <MainLoader message="Loading..." />
  ) : siteAuditDetails && siteAuditDetails.length ? (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} lg={12}>
              <Card
                sx={{ padding: "30px 45px 24px 40px", border: customizer.isCardShadow ? `1px solid ${borderColor}` : "none", height: "100%" }}
                elevation={customizer.isCardShadow ? 9 : 0}
                variant={!customizer.isCardShadow ? "outlined" : undefined}
              >
                <BoxMainWrapper>
                  <DataGrid className="datagridnopage" columns={columns} rows={siteAuditDetails} />
                </BoxMainWrapper>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  ) : (
    <Box sx={DataNotWrapper}>Data Not Found</Box>
  );
}

export default ScheduledReport;
