import * as React from "react";
import { Button, Dialog, Fab, DialogContent, TextField, DialogActions, DialogContentText, Typography, styled } from "@mui/material";
import { useMutation } from "@apollo/client";
import { IconCheck } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { createNote } from "../../../gql/mutations/notes";
import { getAllNotes } from "../../../gql/queries/notes";
import useQuery from "../../../hooks/allQuery";

const NotesBtnWrapper = styled("Box")(({ theme }) => ({
  "& button": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    marginLeft: "10px",
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
}));

function AddNotes({ colors, notesFilter }) {
  const [noteText, setNoteText] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [scolor, setScolor] = React.useState("primary");
  const { userData } = useSelector(state => state.reducer);
  const [CREATE_NOTE] = useMutation(createNote, { fetchPolicy: "network-only" });
  const { allQuery } = useQuery();

  const handleCreateNoteClick = () => {
    allQuery({
      QUERY: CREATE_NOTE,
      variables: {
        data: {
          userId: userData.id,
          note: noteText,
          color: scolor,
        },
      },
      type: "create",
      cacheQueryData: {
        cacheQuery: getAllNotes,
        variablesCache: {
          userId: userData.id,
          searchOptions: notesFilter.searchOptions,
        },
      },
      onCompleteFunc: () => {
        setOpen(false);
        setNoteText("");
      },
    });
  };

  const setColor = e => {
    setScolor(e);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" disableElevation color="primary" onClick={handleClickOpen}>
        Add Notes
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography variant="h5" mb={2} fontWeight={700}>
            Add New Note
          </Typography>
          <DialogContentText>To add new notes please enter your description and choose note colors. and press the submit button to add new note.</DialogContentText>
          <TextField
            multiline
            rows={5}
            value={noteText}
            onChange={e => setNoteText(e.target.value)}
            margin="normal"
            id="description"
            label="Add Note Description"
            type="text"
            fullWidth
            size="small"
            variant="outlined"
          />
          <Typography variant="h6" my={2}>
            Choose Color
          </Typography>
          {colors.map(color => (
            <Fab
              color={color.disp}
              sx={{
                marginRight: "3px",
                transition: "0.1s ease-in",
                scale: scolor === color.disp ? "0.9" : "0.7",
              }}
              size="small"
              key={color.disp}
              onClick={() => setColor(color.disp)}
            >
              {scolor === color.disp ? <IconCheck /> : ""}
            </Fab>
          ))}
        </DialogContent>
        <DialogActions>
          <NotesBtnWrapper>
            <Button onClick={handleClose}>Cancel</Button>
            <Button disabled={noteText === ""} onClick={handleCreateNoteClick} variant="contained">
              Submit
            </Button>
          </NotesBtnWrapper>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddNotes;
