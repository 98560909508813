/* eslint-disable */
import Accordion from "@mui/material/Accordion";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  styled,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { useLocation, useParams } from "react-router";
import { GetUrlListWithKeyword } from "../../hooks/userWebsiteData";
import BackButton from "../BackButton/BackButton";
import MainLoader from "../MainLoader/MainLoader";
import { useEffect, useState } from "react";
import locationJson from "../../utils/location.json";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_KEYWORD } from "../../gql/utils";
import { useNavigate } from "react-router-dom";
import { getLinks200 } from "../../gql/queries/projectDetail";
import useQuery from "../../hooks/allQuery";
import { usePopup } from "../../context/commonPop";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  display: "flex",
  // alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  padding: "30px 0",
  "& .SomethingWrong": {
    color: theme.palette.text.primary,
  },
  "& .AccordionListWrapper": {
    "& .MuiAccordion-root": {
      boxShadow: "none",
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: "10px",
      marginBottom: "10px",
      "&::before": {
        display: "none",
      },
      "& .MuiAccordionSummary-root": {
        "& .MuiAccordionSummary-content": {
          "& p": { wordBreak: "break-all" },
        },
      },
      "& .MuiAccordionDetails-root": {
        "& .KeywordListWrapper": {
          "& .MainHeading": {
            fontSize: "18px",
            fontWeight: 600,
            marginBottom: "18px",
            "&.VerifiedKeywords": {
              marginTop: "22px",
            },
          },
          "& .NotFoundWrapper": {
            padding: "10px 0 10px",
            "& h5": {
              textAlign: "center",
              fontSize: "14px",
            },
          },
          "& .KeywordListInner": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            gap: "15px",
            maxHeight: "200px",
            overflowY: "auto",
            "@media screen and (max-width: 600px)": {
              flexDirection: "column",
              flexWrap: "nowrap",
              alignItems: "flex-start",
            },
            "&::-webkit-scrollbar-track": {
              borderRadius: "10px",
              backgroundColor: theme.palette.text.primary + "0d",
            },
            "&::-webkit-scrollbar": { width: "8px", backgroundColor: "transparent" },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
              backgroundColor: theme.palette.primary.main,
            },
            "& span.Tags": {
              backgroundColor: theme.palette.primary.main + "1a",
              fontSize: "12px",
              padding: "3px 14px 3px",
              borderRadius: "12px",
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
  },
  "& .PaginationWrapper": {
    marginTop: "25px",
    "& nav": {
      "& ul": {
        justifyContent: "flex-end",
        "@media screen and (max-width: 600px)": {
          justifyContent: "center",
        },
        "& li": {
          "& button": {
            "&.Mui-selected": {
              backgroundColor: theme.palette.primary.main + "2b",
              color: theme.palette.primary.main,
            },
          },
          "@media screen and (max-width: 600px)": {
            "&:first-of-type, &:last-of-type": {
              display: "none",
            },
          },
        },
      },
    },
  },

  "& .KeywordsWrapper": {
    "& .FindInputWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "15px",
      // marginBottom: "30px",
      "& .InputStyleWrapper": {
        flex: "1",
        "& .selectWrapper": {
          color: theme.palette.text.primary,
          "& .MuiOutlinedInput-root": {
            "&:hover": {
              border: "none !important",
              outline: "none",
            },
          },
          "&::placeholder": {
            color: theme.palette.text.primary,
          },
          "& input": {
            color: theme.palette.text.primary,
            "&:hover": {
              border: `none `,
              borderRadius: "5px",
            },
          },
        },
      },
      "& .GenerateBtn": {
        padding: "15px 20px 15px",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        "&:focus": {
          border: "none",
          outline: "none",
        },
      },
    },
    "& .KeywordTableWrapper": {
      "& .TableMainHeading": {
        fontSize: "18px",
        fontWeight: 900,
        marginBottom: "20px",
        marginTop: "30px",
      },
      "& .TableMainWrapper": {
        boxShadow: "none",
        border: `1px solid ${theme.palette.divider}`,
        "& table": {
          "& thead": {
            "& tr": {
              "& th": {
                fontSize: "14px",
                fontWeight: 600,
                whiteSpace: "nowrap",
              },
            },
          },
          "& tbody": {
            "& tr": {
              "& td": {
                paddingTop: "15px",
                paddingBottom: "15px",
                fontSize: "12px",
                fontWeight: 600,
                "& .ViewReportBtn": {
                  whiteSpace: "nowrap",
                },
              },
            },
          },
        },
      },
      "& .TableMainWrapperSuggestion": {
        maxHeight: "470px",
        overflow: "auto",
        boxShadow: "none",
        "&::-webkit-scrollbar-track": {
          borderRadius: "10px",
          backgroundColor: theme.palette.text.primary + "0d",
        },
        "&::-webkit-scrollbar": { width: "8px", backgroundColor: "transparent" },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: theme.palette.primary.main,
        },
        border: `1px solid ${theme.palette.divider}`,
        "& table": {
          "& thead": {
            "& tr": {
              "& th": {
                fontSize: "14px",
                fontWeight: 600,
                whiteSpace: "nowrap",
              },
            },
          },
          "& tbody": {
            "& tr": {
              "& td": {
                paddingTop: "15px",
                paddingBottom: "15px",
                fontSize: "12px",
                fontWeight: 600,
                "& .ViewReportBtn": {
                  whiteSpace: "nowrap",
                },
              },
            },
          },
        },
      },
    },
  },
}));

const obj = { url: null, location: null };
const errorObj = { url: false, location: false };
const initialCompetitionFilters = {
  high: false,
  medium: false,
  low: false,
  unspecified: false,
};

function UrlKeywordinAccordion() {
  const [errorState, setErrorState] = useState(errorObj);
  const [queryObj, setQueryObj] = useState(obj);
  const { sessionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [resData, setResData] = useState(null);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [urlData, setUrlData] = useState([]);
  const [KEYWORD_GET] = useMutation(GET_KEYWORD);
  const [disabledInput, setDisabledInput] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [competitionFilters, setCompetitionFilters] = useState(initialCompetitionFilters);
  const { handleOpen } = usePopup();
  const location = useLocation();
  const {
    state: { projectId },
  } = location || {};
  const navigate = useNavigate();

  const handleCheckboxChange = (event, rowData) => {
    const isChecked = event.target.checked;

    setSelectedKeywords(prevSelected => {
      if (isChecked) {
        return [...prevSelected, rowData];
      } else {
        return prevSelected.filter(item => item !== rowData);
      }
    });
  };

  const handleSearchClick = () => {
    const pathname = `/urlKeywords/${sessionId}/selected-keywords?menu=headerKeywords`;
    const state = {
      selectedKeywords: selectedKeywords,
      projectId: projectId,
    };
    navigate(pathname, { state: state });
  };

  const isSearchButtonDisabled = selectedKeywords.length === 0;

  const handleChangeDrop = event => {
    const { name, value } = event.target;
    if (name === "url") {
      setQueryObj({ ...queryObj, [name]: value });
      setErrorState({ ...errorState, url: false });
    } else if (name === "location") {
      const updatedValue = Array.isArray(value) ? value : [value];
      setQueryObj({ ...queryObj, [name]: updatedValue });
    }
  };
  const theme = useTheme();
  const customizer = useSelector(state => state.customizer);

  const { allQuery } = useQuery();
  const [GET_LINKS] = useLazyQuery(getLinks200, { fetchPolicy: "cache-first" });
  const { listData, loading, setUrlCount, urlCount, allUrl } = GetUrlListWithKeyword(sessionId);

  const HandleClickFunc = async () => {
    setSelectedKeywords([]);
    setIsLoading(true);
    if (!queryObj?.url) {
      setErrorState({ ...errorState, url: true });
      setIsLoading(false);
      return;
    }
    const input = { ...queryObj, sessionId: sessionId };

    if (!input.location) input.location = null;
    try {
      setDisabledInput(true);
      const res = await KEYWORD_GET({ variables: { keywordInput: input } });
      if (res && res.data && res.data.getKeywordTag && res.data.getKeywordTag.context) {
        setResData(res.data.getKeywordTag.context);
        setIsLoading(false);
        setDisabledInput(false);
      }
    } catch (error) {
      console.log("error", error.message);
      setIsLoading(false);
      setDisabledInput(false);
      handleOpen(true, error.message);
    }
  };
  const handleSelectedCheckboxChange = level => {
    setCompetitionFilters(prevFilters => ({
      ...prevFilters,
      [level]: !prevFilters[level],
    }));
  };

  const loadPage = () => {
    allQuery({
      QUERY: GET_LINKS,
      variables: {
        sessionId,
      },
      onCompleteFunc: ({ GetLinks200 }) => {
        setUrlData(GetLinks200);
      },
      onErrorFunc: errdata => {
        console.log("errdata", errdata);
      },
    });
  };

  useEffect(() => {
    loadPage();
  }, [sessionId]);

  return loading ? (
    <MainLoader message="Loading" />
  ) : listData.length > 0 ? (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} md={12}>
              <BackButton />
              <Card
                sx={{ padding: { xs: "15px 15px 50px 15px", sm: "30px 45px 24px 40px" }, border: `1px solid ${theme.palette.divider}` }}
                elevation={customizer.isCardShadow ? 9 : 0}
                variant={!customizer.isCardShadow ? "outlined" : undefined}
              >
                <Box className="KeywordsWrapper">
                  <Box className="FindInputWrapper">
                    <FormControl className="InputStyleWrapper" fullWidth>
                      <Autocomplete
                        disabled={disabledInput}
                        value={urlData.find(el => el.url === queryObj.url) || null}
                        onChange={(event, newValue) => {
                          handleChangeDrop({ target: { name: "url", value: newValue ? newValue.url : "" } });
                        }}
                        options={urlData}
                        getOptionLabel={option => option.url || "searched url"}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="searched url"
                            error={errorState.url} // Show error if urlError is true
                            helperText={errorState.url ? "select a url" : ""}
                            variant="outlined"
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl className="InputStyleWrapper" fullWidth>
                      <Autocomplete
                        disabled={disabledInput}
                        multiple
                        value={locationJson.filter(item => (queryObj.location || []).includes(item.Geo_ID))}
                        onChange={(event, newValue) => {
                          handleChangeDrop({ target: { name: "location", value: newValue.map(item => item.Geo_ID) } });
                        }}
                        options={locationJson}
                        getOptionLabel={option => option.Name || "select Geo location"}
                        renderTags={(value, getTagProps) => value.map((option, index) => <Chip key={option.Geo_ID} label={option.Name} {...getTagProps({ index })} />)}
                        renderInput={params => <TextField {...params} variant="outlined" label="select Geo location" />}
                      />
                    </FormControl>
                    <Button className="GenerateBtn" onClick={() => HandleClickFunc()} disabled={isLoading}>
                      Generate
                    </Button>
                  </Box>
                  {isLoading ? (
                    <MainLoader message="Loading" />
                  ) : (
                    resData &&
                    resData?.direct_matches && (
                      <Box className="KeywordTableWrapper">
                        <Typography component="h3" className="TableMainHeading">
                          Direct Matches
                        </Typography>
                        <TableContainer component={Paper} className="TableMainWrapper">
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Keyword</TableCell>
                                <TableCell align="center">Monthly Searches</TableCell>
                                <TableCell align="center">Competition</TableCell>
                                <TableCell align="center">Select</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {resData?.direct_matches.map((item, index) => (
                                <TableRow key={index} sx={{ cursor: "pointer" }}>
                                  <TableCell>{item.keyword}</TableCell>
                                  <TableCell align="center">{item.monthly_searches}</TableCell>
                                  <TableCell align="center">{item.competition}</TableCell>
                                  <TableCell align="center">
                                    <Checkbox checked={selectedKeywords.includes(item)} onChange={event => handleCheckboxChange(event, item)} />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <Typography component="h3" className="TableMainHeading">
                          Suggestions
                        </Typography>
                        <Grid container spacing={2} sx={{ mb: "20px", justifyContent: "space-between" }}>
                          <Grid item xs={12} md={6}>
                            <TextField label="Search Suggestions" variant="outlined" fullWidth value={searchFilter} onChange={e => setSearchFilter(e.target.value)} />
                          </Grid>
                          <Grid item xs={12} md={6} sx={{ textAlign: "end" }}>
                            {["high", "medium", "low", "unspecified"].map(level => (
                              <FormControlLabel
                                key={level}
                                control={<Checkbox checked={competitionFilters[level]} onChange={() => handleSelectedCheckboxChange(level)} />}
                                label={level.charAt(0).toUpperCase() + level.slice(1)}
                              />
                            ))}
                          </Grid>
                        </Grid>
                        <TableContainer component={Paper} className="TableMainWrapperSuggestion">
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Keyword</TableCell>
                                <TableCell align="center">Monthly Searches</TableCell>
                                <TableCell align="center">Competition</TableCell>
                                <TableCell align="center">Select</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {resData &&
                                resData?.suggestions &&
                                resData?.suggestions
                                  .filter(item => {
                                    if (Object.values(competitionFilters).some(Boolean)) {
                                      const competition = item.competition.toLowerCase();
                                      return (
                                        (competitionFilters.high && competition === "high") ||
                                        (competitionFilters.medium && competition === "medium") ||
                                        (competitionFilters.low && competition === "low") ||
                                        (competitionFilters.unspecified && competition === "unspecified")
                                      );
                                    } else {
                                      return true;
                                    }
                                  })
                                  .filter(item => item.keyword.toLowerCase().includes(searchFilter.toLowerCase()))
                                  .map((item, index) => (
                                    <TableRow key={index} sx={{ cursor: "pointer" }}>
                                      <TableCell>{item.keyword}</TableCell>
                                      <TableCell align="center">{item.monthly_searches}</TableCell>
                                      <TableCell align="center">{item.competition}</TableCell>
                                      <TableCell align="center">
                                        <Checkbox checked={selectedKeywords.includes(item)} onChange={event => handleCheckboxChange(event, item)} />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: "20px", flexWrap: "wrap" }}>
                          <Button variant="contained" color="primary" onClick={handleSearchClick} disabled={isSearchButtonDisabled}>
                            Generate Titles
                          </Button>
                        </Box>
                      </Box>
                    )
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  ) : (
    <SectionWrapper sx={{ alignItems: "center" }}>
      <Typography component="p" className="SomethingWrong">
        {" "}
        Not Found
      </Typography>
    </SectionWrapper>
  );
}

export default UrlKeywordinAccordion;
