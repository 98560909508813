/* eslint-disable */
import { Box, Button, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select, styled, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import MannualSteps from "./MannualSteps";
import { GetScrapyData } from "../../hooks/scrapyData";
import { createUserVisit } from "../../gql/mutations/userWebSiteVisit";
import { SCRAPY_DATA } from "../../gql/queries/userWebSiteList";
import { ownwerDomainCreate } from "../../redux/action";
import { usePopup } from "../../context/commonPop";
import useQuery from "../../hooks/allQuery";
import { validateString } from "../Validate/validate";
import { ValidationHttp, ValidationUri } from "../../common/validations";
import MessagePopUp from "../Modal/messagePopUp";
import BackButton from "../BackButton/BackButton";
import darkLogoImage from "../../Images/hotSpotLogoBlue.svg";
import { extractDomainAndProtocol } from "../../common/utils";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "100vh",
  backgroundColor: "#EFF3FB",
  paddingTop: "30px",
  paddingBottom: "30px",
  "& .LogoWrapper": {
    textAlign: "center",
    "& img": { width: "105px", height: "auto" },
  },
  "& .importProjectbox": {
    backgroundColor: "#fff",
    padding: "20px",
    border: "1px solid #E3E3E3",
    borderRadius: "8px",
    "& .InputWrapper": {
      "& .selectInput": {
        width: "40%",
      },
      "& .MuiFormControl-root": {
        marginTop: "7px",
        "& fieldset": {
          borderColor: " #E3E3E3 !important",
        },
        "&.FullWidthInput": {
          flex: "100%",
        },
        "& label": { lineHeight: 1 },
      },
      "& label": { color: theme.palette.text.primary, fontSize: "12px", fontWeight: 600, lineHeight: "1", textAlign: "left" },
    },
  },
  "& .ImportMainHead": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "@media screen and (max-width:600px)": {
      flexDirection: "column",
      marginBottom: "20px",
      gap: "20px",
    },
    "& .MainHeading": { color: "#2A3547", textAlign: "center", lineHeight: 1, fontSize: "24px", fontWeight: 700, marginBottom: "10px" },
    "& .SubHeading": {
      color: "#757575",
      textAlign: "center",
      lineHeight: 1,
      fontSize: "14px",
      fontWeight: 500,
    },
  },
  "& .continueBtn": {
    textTransform: "none",
    backgroundColor: "#45C0CB",
    color: "#ffffff",
    borderRadius: "5px",
    boxShadow: "none",
    padding: "12px 22px 10px",
    width: "100%",
    "&:hover": { backgroundColor: "#45C0CB" },
    "&:focus": {
      outline: "0",
      backgroundColor: "#45C0CB",
    },
    "& .MuiCircularProgress-circle": {
      color: "#ffffff",
    },
  },
  "& .backBtnBox": {
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  "& .MainHeading h2": {
    color: "#2A3547",
    textAlign: "center",
    lineHeight: 1,
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "10px",
  },
  "& .SubHeading": {
    color: "#757575",
    textAlign: "center",
    lineHeight: 1,
    fontSize: "14px",
    fontWeight: 500,
  },
}));
function CreatProject() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const { handleOpen } = usePopup();
  const { allQuery } = useQuery();
  const { ownerId } = useParams();
  const [formState, setFormState] = useState({
    scope: "",
    websiteUrl: "",
    projectName: "",
  });
  const [errorState, setErrorState] = useState({
    scope: false,
    projectName: false,
    websiteUrl: false,
  });
  const { userData } = useSelector(state => state.reducer);
  const [createAPI] = useMutation(createUserVisit, { fetchPolicy: "network-only" });
  const navigate = useNavigate();
  const { scrapyData } = GetScrapyData();
  const [varifiedPrtojectLength, setVarifiedPrtojectLength] = useState(0);
  useEffect(() => {
    if (scrapyData && Array.isArray(scrapyData)) {
      const varifiedProjects = scrapyData.filter(project => project?.isVerified === false);
      if (varifiedProjects.length > 0) {
        // const [objValue] = objectValue;4r
        const { website, projectName ,id} = varifiedProjects[0];
        const { domain, protocol } = extractDomainAndProtocol(website);
        const splitScope = `${protocol}//`;
        setFormState({ ...formState, scope: splitScope, websiteUrl: domain, projectName });
        navigate(`/first-project-config/update/${id}`);
        setVarifiedPrtojectLength(varifiedProjects.length);
      }
    }
  }, [scrapyData]);
  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const errCheck = async data => {
    const scope = await validateString(data.scope);
    const projectName = await validateString(data.projectName);
    const websiteUrl = await validateString(data.websiteUrl);

    await setErrorState({
      ...errorState,
      scope,

      websiteUrl,
      projectName,
    });
    if (scope || projectName || websiteUrl) {
      return true;
    }
    return false;
  };

  const createOwnerSiteData = async payload => {
    allQuery({
      QUERY: createAPI,
      variables: {
        payload,
      },
      type: "create",
      cacheQueryData: {
        cacheQuery: SCRAPY_DATA,
        variablesCache: {
          params: userData?.id,
        },
      },
      onCompleteFunc: async ({ userWebSite }) => {
        setLoader(false);
        dispatch(ownwerDomainCreate(userWebSite));
        if (userWebSite && userWebSite.id) {
          navigate(`/first-project-config/${userWebSite.id}`);
        }
      },
      onErrorFunc: err => {
        setLoader(false);
        handleOpen(true, err.message || "something went wrong please try again later");
        console.log("err createApi", err.message);
      },
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (userData?.subscriptionCredits?.projects <= varifiedPrtojectLength) {
      handleOpen(true, "You have reached the limit of creating projects. To create more projects, please purchase or upgrade your plan!");
      return;
    }
    setLoader(true);
    const { projectName, websiteUrl, scope } = formState || {};
    const status = await errCheck(formState);
    if (!status && formState.scope !== null) {
      if (ValidationHttp({ url: websiteUrl })) {
        const hash = await hashCode(websiteUrl, userData.email);
        const str = `dwt-${hash}`;
        const payload = {
          token: str,
          userId: userData.id,
          projectName,
          website: websiteUrl,
        };
        await createOwnerSiteData(payload);
      } else {
        const Createstr = `${scope}${websiteUrl}`;
        if (ValidationUri({ uri: Createstr }) && scope) {
          const hash = await hashCode(Createstr, userData.email);
          const str = `dwt-${hash}`;
          const payload = {
            token: str,
            userId: userData.id,
            website: Createstr,
            projectName,
          };

          await createOwnerSiteData(payload);
        } else {
          setLoader(false);
          setOpen(true);
          setMessage("Please enter valid domain or path");
        }
      }
    } else {
      setLoader(false);
      console.log("this not call");
    }
  };

  const hashCode = (website, email) => {
    const str = `${website}${email}`;
    let hash = 0; // Use 'let' instead of 'var'
    let chr; // Declare 'chr' using 'let'
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = (hash << 5) - hash + chr;
      // eslint-disable-next-line no-bitwise
      hash |= 0;
    }
    return Math.abs(hash);
  };
  return (
    <SectionWrapper>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          {open && <MessagePopUp message={message} open={open} setOpen={setOpen} />}
          <Grid container spacing={2} mt={0} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Box className="LogoWrapper">
                <Typography component="img" src={darkLogoImage} alt="Logo" />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={0} justifyContent="center">
            <Grid item xs={12} md={12}>
              <Box className="importProjectbox">
                <Grid container spacing={2} mt={0}>
                  <Grid item xs={12}>
                    <Box className="ImportMainHead">
                      <Box sx={{ flex: 1, width: { xs: "100%", sm: "unset" } }}>
                        <Box sx={{ width: { xs: "100%", sm: "50%" }, m: "auto", mb: { xs: "15px", sm: "30px" } }}>
                          <MannualSteps activeStep={0} />
                        </Box>
                      </Box>
                      <BackButton
                        sx={{
                          marginLeft: "-80px",
                          zIndex: 99999999,
                          "@media screen and (max-width:600px)": { marginLeft: "0" },
                          "& button": { marginBottom: "0 !important", backgroundColor: "#45C0CB !important" },
                        }}
                      />
                    </Box>
                    <Box className="MainHeading">
                      <Typography component={"h2"}>Create your first project</Typography>
                      <Typography className="SubHeading">Set up your website to start analyzing it</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box width="100%" className="InputWrapper" component="form" mb={0} onSubmit={event => handleSubmit(event)}>
                      <Box display="flex" justifyContent="space-between" width="100%" flexDirection={{ xs: "column", sm: "row" }} mb={2} gap={2}>
                        <Box flex={1}>
                          <Typography component={"label"}>Protocols</Typography>
                          <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel id="protocol-select-label">Select http or https</InputLabel>
                            <Select
                              labelId="protocol-select-label"
                              label="Select http or https"
                              onChange={handleInputChange}
                              displayEmpty
                              error={errorState?.scope}
                              name="scope"
                              value={formState.scope ?? ""}
                            >
                              <MenuItem value="http://">http</MenuItem>
                              <MenuItem value="https://">https</MenuItem>
                              <MenuItem value="http+https">http + https</MenuItem>
                            </Select>
                            <Typography component="span" sx={{ color: "red", fontSize: "12px", ml: "14px" }}>
                              {errorState?.scope && "please select this scope"}
                            </Typography>
                          </FormControl>
                        </Box>
                        <Box flex={1}>
                          <Typography component={"label"}>Domain Name</Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            label="Enter your domain name"
                            name="websiteUrl"
                            value={formState.websiteUrl}
                            onChange={handleInputChange}
                            error={errorState?.websiteUrl}
                            helperText={errorState.websiteUrl && <span style={{ color: "red" }}>Please enter a valid domain or path</span>}
                          />
                        </Box>
                      </Box>
                      <Typography component={"p"} mb={2}>
                        We recommend using the "http + https" protocol along with the non-www version of your domain. You'll get the most complete backlink profile and accurate
                        tracking data this way.
                      </Typography>
                      <Box mb={2}>
                        <Typography component={"label"}>Project Name</Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          label="Enter your project name"
                          name="projectName"
                          value={formState.projectName}
                          placeholder="Project Name"
                          onChange={handleInputChange}
                          error={errorState?.projectName}
                          helperText={errorState.projectName && <span style={{ color: "red" }}> Please enter valid project Name</span>}
                        />
                      </Box>
                      <Button className="continueBtn" type="submit">
                        {loader ? <CircularProgress size={24.5} /> : "Continue"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default CreatProject;
