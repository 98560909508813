import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Box, Button, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { FORGETPASSWORD } from "../../gql/mutations/auth";
import CustomFormLabel from "../forms/theme-elements/CustomFormLabel";
import { regexPassword } from "../../utils/validations";
import { usePopup } from "../../context/commonPop";

function AuthForgotPassword() {
  const [forgetPassword] = useMutation(FORGETPASSWORD);
  const navigate = useNavigate();
  const { handleOpen } = usePopup();
  const { token } = useParams();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .matches(regexPassword, "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be 8-15 characters long")
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .max(15, "Password must be at least 15 characters short"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = values => {
    setIsLoading(true);
    forgetPassword({
      variables: {
        data: { token, password: values?.password },
      },
    })
      .then(() => {
        handleOpen(true, "Password Reset Successfuly");
        setErrorMessage(null);
        navigate("/");
      })
      .catch(error => {
        setIsLoading(false);
        setErrorMessage(error.message || "Server Error");
      });
  };
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      <Form className="resetForm">
        <Box>
          <CustomFormLabel htmlFor="reset-password">Reset Password</CustomFormLabel>
          <Field type="password" id="password" name="password" />
          <ErrorMessage name="password" component="div" className="error" />
        </Box>
        <Box>
          <CustomFormLabel htmlFor="reset-password">Confirm Password</CustomFormLabel>
          <Field type="password" id="confirmPassword" name="confirmPassword" />
          <ErrorMessage name="confirmPassword" component="div" className="error" />
        </Box>
        {errorMessage && (
          <Box component="p" sx={{ marginTop: "15px", color: "red", fontWeight: "600", marginBottom: 0 }}>
            {errorMessage}
          </Box>
        )}
        <Button className="resetBtn" type="submit">
          {" "}
          {isLoading ? <CircularProgress size={20} /> : "Reset"}
        </Button>
      </Form>
    </Formik>
  );
}

export default AuthForgotPassword;
