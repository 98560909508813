/* eslint-disable */
import { Box, Button, Grid, styled, Typography } from "@mui/material";
import React from "react";
import personImage from "../../Images/personImage.png";
const BoxWrapper = styled(Box)(({ theme }) => ({
  padding: "20px",
  borderRadius: "7px",
  backgroundColor: "#ffffff",
  border: "1px solid #E3E3E3",
  "& .PersonImageWrapper": {
    height: "100%",
    backgroundColor: "#45c0cb17",
    borderRadius: "7px",
    "& img": {
      height: "100%",
    },
  },
  "& .FreePlanWrapper": {
    "& .TopHeaderWrapper": {
      borderBottom: "1px solid #D9D9D9",
      "& h2": {
        color: "#2A3547",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "1",
        marginBottom: "8px",
      },
      "& p": {
        color: "#2A3547",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "1",
        marginBottom: "8px",
      },
    },
    "& .FeaturesWrapper": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "end",
      gap: "20px",
      "@media screen and (max-width:600px)": {
        flexDirection: "column",
        alignItems: "start",
      },
      "& .FeaturesListWrapper": {
        paddingTop: "14px",
        "& h3": {
          color: "#2A3547",
          fontSize: "15px",
          fontWeight: 600,
          lineHeight: "1",
          marginBottom: "8px",
        },
        "& ul": {
          paddingLeft: "22px",
          marginBottom: "0px",
          "& li": {
            color: "#757575",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "1",
            marginBottom: "8px",
            "&:last-of-type": {
              marginBottom: "0px",
            },
          },
        },
      },
      "& .BtnWrapper": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "15px",
        "& .OutlineBtn": {
          textTransform: "none",
          backgroundColor: "transparent",
          color: "#45C0CB",
          borderRadius: "5px",
          boxShadow: "none",
          border: "1px solid #45C0CB",
          padding: "11px 22px 9px",
          fontWeight: "600",
          "&:focus": {
            outline: "0",
          },
        },
        "& .FilledBtn": {
          textTransform: "none",
          backgroundColor: "#45C0CB",
          color: "#ffffff",
          borderRadius: "5px",
          boxShadow: "none",
          padding: "12px 22px 10px",
          "&:focus": {
            outline: "0",
          },
        },
      },
    },
  },
}));
const StartFreePlan = () => {
  return (
    <BoxWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Box className="PersonImageWrapper">
            <Typography component={"img"} src={personImage} alt="" />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box className="FreePlanWrapper">
            <Box className="TopHeaderWrapper">
              <Typography component={"h2"}>Start free plan</Typography>
              <Typography component={"p"}>You can create two projects in free plan</Typography>
            </Box>
            <Box className="FeaturesWrapper">
              <Box className="FeaturesListWrapper">
                <Typography component="h3">Features</Typography>
                <Box component="ul">
                  <Typography component="li">Lorem Ipsum is simply dummy text</Typography>
                  <Typography component="li">Lorem Ipsum is simply dummy text</Typography>
                  <Typography component="li">Lorem Ipsum is simply dummy text</Typography>
                </Box>
              </Box>
              <Box className="BtnWrapper">
                <Button className="OutlineBtn">Learn More</Button>
                <Button className="FilledBtn">Our Subscription </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </BoxWrapper>
  );
};

export default StartFreePlan;
