/* eslint-disable */
import { Box, Button, Card, CardHeader, Container, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import useQuery from "../hooks/allQuery";
import { getCheckOutSession, getPrice } from "../gql/queries/subcriptions";
import { useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import RecipeReviewCard from "../components/Buypage/Card";

const backgroundColors = {
  Basic: "rgb(104, 219, 104)",
  Standard: "rgb(185, 42, 23, 0.835)",
  Premium: "pink",
};

const BuyPage = () => {
  const [prices, setPrices] = useState([]);
  const [GET_PRICE] = useLazyQuery(getPrice, { fetchPolicy: "cache-first" });
  const [GET_CHECKOUT_SESSION] = useLazyQuery(getCheckOutSession, { fetchPolicy: "network-only" });
  const { allQuery } = useQuery();
  const userData = useSelector(state => state.reducer.userData);

  useEffect(() => {
    fetchPrices();
  }, []);

  const fetchPrices = async () => {
    try {
      allQuery({
        QUERY: GET_PRICE,

        onCompleteFunc: ({ GetPrice }) => {
          console.log(GetPrice.data);
          setPrices(GetPrice?.data || []);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const createSession = useCallback(
    async priceId => {
      if (!userData?.id) {
        console.log("userId is not available!");
        return;
      }
      try {
        allQuery({
          QUERY: GET_CHECKOUT_SESSION,
          variables: {
            priceId,
            userId: userData.id,
          },
          onCompleteFunc: ({ GetCheckOutSession }) => {
            window.location.href = GetCheckOutSession;
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    [userData, allQuery, GET_CHECKOUT_SESSION],
  );

  return (
    <Container sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: "calc(100vh - 75px)", width: "100%", alignItems: "center" }}>
      {prices.map(price => {
        return <RecipeReviewCard price={price} key={price.nickname} createSession={createSession} />;
      })}
    </Container>
  );
};

export default BuyPage;
