import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { IconButton, Box, Stack, Typography, TextField, Tooltip, Alert, styled } from "@mui/material";
import { IconTrash } from "@tabler/icons-react";
import Scrollbar from "../../NavBar/sidebar/Scrollbar";
import { deleteNote } from "../../../gql/mutations/notes";
import { getAllNotes } from "../../../gql/queries/notes";
import ColorsCheckbox from "../../forms/form-elements/checkbox/Colors";
import useQuery from "../../../hooks/allQuery";

const BtnWrapper = styled("Box")(({ theme }) => ({
  "& button": {
    color: theme.palette.text.primary,

    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
}));

function NoteList({ setNoteDetails, allNotes, notesFilter, setNotesFilter }) {
  const { allQuery } = useQuery();
  const { userData } = useSelector(state => state.reducer);
  const [DELETE_NOTE] = useMutation(deleteNote, { fetchPolicy: "network-only" });
  const {
    searchOptions: { searchTerm },
  } = notesFilter;
  const handleChange = ({ target: { value } }) => {
    setNotesFilter({
      ...notesFilter,
      searchOptions: {
        ...notesFilter.searchOptions,
        searchTerm: value,
      },
    });
  };

  const handleDelete = async id => {
    try {
      allQuery({
        QUERY: DELETE_NOTE,
        variables: { deleteNoteId: id },
        type: "delete",
        cacheQueryData: {
          cacheQuery: getAllNotes,
          variablesCache: {
            userId: userData.id,
            searchOptions: notesFilter.searchOptions,
          },
        },
      });
      console.log("Note deleted successfully!");
    } catch (error) {
      console.error("Failed to delete note:", error);
    }
  };

  const handleNoteSelection = noteId => {
    const selectedNote = allNotes.find(note => note.id === noteId);
    setNoteDetails(selectedNote);
  };

  return (
    <>
      <Box p={3} px={2}>
        <TextField
          id="search"
          name="search"
          value={searchTerm}
          placeholder="Search Notes"
          inputProps={{ "aria-label": "Search Notes" }}
          size="small"
          type="search"
          variant="outlined"
          fullWidth
          onChange={e => handleChange(e)}
        />

        <ColorsCheckbox setNotesFilter={setNotesFilter} />
        <Typography variant="h6" mb={0} mt={4} pl={1}>
          All Notes
        </Typography>
      </Box>
      <Box>
        <Scrollbar
          sx={{
            height: { lg: "calc(100vh - 300px)", sm: "100vh" },
            maxHeight: "700px",
          }}
        >
          {allNotes && allNotes.length ? (
            <>
              {allNotes.map(note => (
                <Box key={note.id} px={2}>
                  <Box
                    p={2}
                    sx={{
                      position: "relative",
                      cursor: "pointer",
                      mb: 1,
                      transition: "0.1s ease-in",
                      transform: note.id ? "scale(1)" : "scale(0.95)",
                      backgroundColor: `${note.color}.light`,
                    }}
                    onClick={() => handleNoteSelection(note.id)}
                  >
                    <Typography variant="h6" noWrap color={note.color + ".main"}>
                      {note.note}
                    </Typography>
                    <BtnWrapper>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="caption">{new Date(note.createdAt).toLocaleDateString()}</Typography>
                        <Tooltip title="Delete">
                          <IconButton aria-label="delete" size="small" onClick={() => handleDelete(note.id)}>
                            <IconTrash width={18} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </BtnWrapper>
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Box m={2}>
              <Alert severity="error" variant="filled" sx={{ color: "white" }}>
                No Notes Found!
              </Alert>
            </Box>
          )}
        </Scrollbar>
      </Box>
    </>
  );
}

export default NoteList;
