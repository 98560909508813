/* eslint-disable */
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useSubscription } from "@apollo/client";
import { MESSAGE_USER_SUB } from "../gql/mutations/auth";
import { deleteLocalTokens, saveLocalTokens } from "../utils/session";
import { gql } from "@apollo/client";

const UseRefreshToken = async apolloClient => {
  try {
    await new Promise(resolve => setTimeout(resolve, 1000));

    const {
      data: { refreshToken: tokens },
    } = await apolloClient?.mutate({
      mutation: gql`
        mutation RefreshToken {
          refreshToken {
            accessToken
            refreshToken
          }
        }
      `,
    });

    saveLocalTokens({
      accessToken: tokens.accessToken,
      refreshToken: tokens.refreshToken,
    });

    return tokens.accessToken;
  } catch (err) {
    deleteLocalTokens();
    window.location.href = "/";
    throw err;
  }
};

const useSubscriptionCall = async () => {
  const [data, setData] = useState({});
  const { email } = useSelector(state => state.reducer.userData);

  async function getData() {
    try {
      const { data: resData } = await useSubscription(MESSAGE_USER_SUB, {
        variables: { email },
      });
      setData(resData);
    } catch (err) {
      // console.log(err);
    }
  }

  useEffect(() => {
    if (email) {
      getData();
    }
  }, [email]);

  return { data };
};

export { UseRefreshToken, useSubscriptionCall };
