/* eslint-disable */
import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import CircularProgressBar from "./CircularProgressBar";
import DonutChart from "./DonutChart";

const BoxWrapper = styled(Box)(({ theme }) => ({}));

function OtherPerformanceChartBox({ label, data, title, heading, projectId }) {
  const theme = useTheme();
  const [goodUrl, avgUrl, poorUrl] = data || [];

  return (
    <BoxWrapper>
      <Grid container spacing={0} mt={0} sx={{ justifyContent: "center", mb: "20px", alignItems: "center" }}>
        <Grid item xs={12} md={4} sx={{ mb: { xs: "40px", sm: "0" } }}>
          <DonutChart totalValue={goodUrl + avgUrl + poorUrl} data={data} title={title} heading={heading} projectId={projectId} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={{ xs: 3, sm: 0 }} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={6} md={4}>
              <Link to={`/websitePerformance/${projectId}/${title}/${heading}/Good?menu=performanceDashboard&tab=${title}`}>
                <CircularProgressBar
                  value={goodUrl || "00"}
                  label={"Good-Url"}
                  sxSize={{ width: 50, height: 50 }}
                  marker={false}
                  isParcentage={false}
                  totalValue={goodUrl + avgUrl + poorUrl}
                />
              </Link>
            </Grid>
            <Grid item xs={6} md={4}>
              <Link to={`/websitePerformance/${projectId}/${title}/${heading}/Avg?menu=performanceDashboard&tab=${title}`}>
                <CircularProgressBar
                  value={avgUrl || "00"}
                  label={"Average-Url"}
                  sxSize={{ width: 50, height: 50 }}
                  marker={false}
                  isParcentage={false}
                  totalValue={goodUrl + avgUrl + poorUrl}
                />
              </Link>
            </Grid>
            <Grid item xs={6} md={4}>
              <Link to={`/websitePerformance/${projectId}/${title}/${heading}/Poor?menu=performanceDashboard&tab=${title}`}>
                <CircularProgressBar
                  value={poorUrl || "00"}
                  label={"Poor-Url"}
                  sxSize={{ width: 50, height: 50 }}
                  marker={false}
                  isParcentage={false}
                  totalValue={goodUrl + avgUrl + poorUrl}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0} mt={0} sx={{ justifyContent: "center" }}>
        <Grid item xs={12} lg={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: "35px",
              "& span": {
                backgroundColor: "#F0F0F0",
                borderRadius: "2px",
                color: theme.palette.mode === "dark" ? "#000000" : "#000000",
                fontSize: "16px",
                fontWeight: 600,
                padding: "8px 25px 8px",
              },
            }}
          >
            <Typography component="span">{label}</Typography>
          </Box>
        </Grid>
      </Grid>
    </BoxWrapper>
  );
}
export default OtherPerformanceChartBox;
