/* eslint-disable */
import { useEffect, useState } from "react";
import { Box, Button, MenuItem, Modal, TextField, styled } from "@mui/material";
import { useMutation } from "@apollo/client";
import CustomSelect from "../forms/theme-elements/CustomSelect";
import CustomFormLabel from "../forms/theme-elements/CustomFormLabel";
import ParentCard from "../shared/ParentCard";
import { validateDescription } from "../../utils/validations";
import { usePopup } from "../../context/commonPop";
import useQuery from "../../hooks/allQuery";
import { updateTicket } from "../../gql/mutations/ticket";
import { getTicketsAdmin } from "../../gql/queries/ticketadmin";
const SubmitBtn = styled(Box)(({ theme }) => ({
  marginTop: "15px",
  "& button": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
}));

const numbers = [
  {
    value: "Pending",
  },
  {
    value: "Open",
  },
  {
    value: "Closed",
  },
];

function TicketAdminUpdateForm({ open, setOpen, selectedTicket }) {
  const [data, setData] = useState({ Status: "", description: "", err: false ,updateDisable:false});
  const { handleOpen } = usePopup();
  const { allQuery } = useQuery();
  const [UPDATE_TICKET] = useMutation(updateTicket, { fetchPolicy: "network-only" });

  useEffect(() => {
    setData({ ...data, Status: selectedTicket.Status, description: selectedTicket.description });
  }, [selectedTicket]);
  const handleChange = async e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  function handleSubmit(e) {
    e.preventDefault();
    const check = validateDescription(data.description);
    if (check) {
      setData({ ...data, err: true });
      return;
    }
    setData({...data,updateDisable:true})
    allQuery({
      QUERY: UPDATE_TICKET,
      variables: {
        updateData: {
          Status: data.Status,
          id: selectedTicket.id,
          description: data.description,
        },
      },
      type: "update",
      cacheQueryData: {
        cacheQuery: getTicketsAdmin,
      },
      onCompleteFunc: () => {
        setOpen(false);
        setData({ ...data, updateDisable: false })
        handleOpen(true, "Ticket Updated Successfully");
      },
    });
  }
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ParentCard>
        <Box component="form" onSubmit={handleSubmit}>
          <CustomFormLabel htmlFor="standard-select-number">Ticket id</CustomFormLabel>
          {selectedTicket.ticketId}
          <CustomFormLabel htmlFor="standard-select-number">Status</CustomFormLabel>
          <CustomSelect
            required
            fullWidth
            id="standard-select-number"
            variant="outlined"
            name="Status"
            value={data.Status}
            onChange={handleChange}
            sx={{
              mb: 2,
            }}
          >
            {numbers.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </CustomSelect>

          <CustomFormLabel htmlFor="description">Description</CustomFormLabel>
          <TextField
            id="description"
            name="description"
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            value={data.description}
            onChange={handleChange}
            error={data.err}
            helperText={data.err && "please enter a description atleast 15 character long!"}
          />
          <SubmitBtn>
            <Button variant="contained" type="submit" disabled={data.updateDisable}>
              Update
            </Button>
          </SubmitBtn>
        </Box>
      </ParentCard>
    </Modal>
  );
}

export default TicketAdminUpdateForm;
