import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Typography, useTheme } from "@mui/material";
import { memo } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
function ComfirmationPopup({ open, handleClosePopup, handleFunc, msg, sucessBtnName = "" }) {
  const theme = useTheme();

  return (
    <Modal open={open} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
      <Box sx={{ ...style, backgroundColor: theme.palette.background.default }}>
        <Typography component="p" sx={{ color: theme.palette.text.primary, fontWeight: 600 }} id="child-modal-description">
          {msg}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: "15px" }}>
          <Button sx={{ backgroundColor: theme.palette.primary.main, color: "#fff", fontWeight: 600, display: "inline-block", ":focus": { outline: "none" } }} onClick={handleFunc}>
            {sucessBtnName}
          </Button>
          <Button
            sx={{ backgroundColor: theme.palette.primary.main, color: "#fff", fontWeight: 600, display: "inline-block", ":focus": { outline: "none" } }}
            onClick={handleClosePopup}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default memo(ComfirmationPopup);
