import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { recoverSession } from "../../utils/session";

export function RequireUnauthenticated({ children }) {
  const { isAuth, activateAuth } = useContext(AuthContext);

  useEffect(() => {
    if (recoverSession("accessToken") !== undefined && recoverSession("accessToken") !== null) {
      activateAuth(recoverSession("accessToken"));
    }
  }, [activateAuth]);
  if (isAuth) {
    return <Navigate to="/dashboard" />;
  }
  return children;
}
