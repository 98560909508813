/* eslint-disable */
import { Box, Card, Grid, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import ApexChart from "./ApexChart";
import { MemorizedStatRow } from "../../dashboards/modern/WeeklyStats";
import AllDetails from "./AllDetails";
import CircularProgressBar from "./CircularProgressBar";
// import CircularStatic from "../../CircularProgressBar";

const BoxWrapper = styled(Box)(({ theme }) => ({}));

function AveragePerformancecChartBoxs({ label, projectId, lightData, urlCount, tabName }) {
  const [seoValue, bestPracticeValue, accessibilityValue, performanceValue] = lightData || [];
  const theme = useTheme();
  const is1200To1300Breakpoint = useMediaQuery("(max-width: 1300px) and (min-width: 1200px)");
  const primary = theme.palette.primary.main;
  const secondary = theme.palette.secondary.main;
  const primarylight = theme.palette.primary.light;
  const error = theme.palette.error.main;
  const errorlight = theme.palette.error.light;
  const secondarylight = theme.palette.success.light;
  const stats = [
    {
      title: "All Url",
      percent: (Array.isArray(urlCount) && urlCount.length === 4 && urlCount[0]) || "00",
      color: error,
      lightcolor: errorlight,
      url: `/websiteurllist/${projectId}/all?menu=performanceDashboard${tabName}`,
    },
    {
      title: "Report Generated",
      percent: (Array.isArray(urlCount) && urlCount.length === 4 && urlCount[1]) || "00",
      color: secondary,
      lightcolor: secondarylight,
      url: `/websiteurllist/${projectId}/success?menu=performanceDashboard${tabName}`,
    },
    {
      title: "Failed Or Not Generated",
      percent: (Array.isArray(urlCount) && urlCount.length === 4 && urlCount[2]) || "00",
      color: primary,
      lightcolor: primarylight,
      url: `/websiteurllist/${projectId}/failed?menu=performanceDashboard${tabName}`,
    },
    {
      title: "Progress",
      percent: (Array.isArray(urlCount) && urlCount.length === 4 && urlCount[3]) || "00",
      color: primary,
      lightcolor: primarylight,
      url: `/websiteurllist/${projectId}/progress?menu=performanceDashboard${tabName}`,
    },
  ];

  return (
    <BoxWrapper>
      <Grid container spacing={0} mt={0} sx={{ justifyContent: "center", mb: "40px" }}>
        <Grid item xs={12} md={7}>
          <ApexChart seriesValue={lightData || [0, 0, 0, 0]} />
        </Grid>
        <Grid item xs={12} md={5}>
          <Stack spacing={3} mt={{ xs: 5, sm: 1 }} sx={{ "& .css-1fdhh1r-MuiStack-root": { cursor: "pointer" } }}>
            {(stats || []).map(stat => (
              <AllDetails stat={stat} key={stat.title} />
            ))}
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 3, sm: 0 }} mt={0} sx={{ justifyContent: "center" }}>
        <Grid item xs={6} md={3}>
          <CircularProgressBar
            value={performanceValue || "00"}
            label={"Performance"}
            sxSize={{ width: is1200To1300Breakpoint ? 55 : 80, height: is1200To1300Breakpoint ? 55 : 80 }}
            marker={true}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CircularProgressBar
            value={accessibilityValue || "00"}
            label={"Accessibility"}
            sxSize={{ width: is1200To1300Breakpoint ? 55 : 80, height: is1200To1300Breakpoint ? 55 : 80 }}
            marker={true}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CircularProgressBar
            value={bestPracticeValue || "00"}
            label={"Best Practices"}
            sxSize={{ width: is1200To1300Breakpoint ? 55 : 80, height: is1200To1300Breakpoint ? 55 : 80 }}
            marker={true}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <CircularProgressBar
            value={seoValue || "00"}
            label={"SEO"}
            sxSize={{ width: is1200To1300Breakpoint ? 55 : 80, height: is1200To1300Breakpoint ? 55 : 80 }}
            marker={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0} mt={0} sx={{ justifyContent: "center" }}>
        <Grid item xs={12} lg={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: "35px",
              "& span": {
                backgroundColor: "#F0F0F0",
                borderRadius: "2px",
                color: theme.palette.mode === "dark" ? "#000000" : "#000000",
                fontSize: "16px",
                fontWeight: 600,
                padding: "8px 25px 8px",
              },
            }}
          >
            <Typography component="span">{label}</Typography>
          </Box>
        </Grid>
      </Grid>
    </BoxWrapper>
  );
}
export default AveragePerformancecChartBoxs;
