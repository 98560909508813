// eslint-disable
import { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CancelIcon from "@mui/icons-material/Cancel";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate, useParams } from "react-router";
import { useGoogleLogin } from "@react-oauth/google";
import { useSelector, useDispatch } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Button, CircularProgress, Container, Grid, IconButton, Link } from "@mui/material";
import MannualSteps from "./ProjectList/MannualSteps";
import { findDomain, GoogleAuthApi, HTML_FILE_VERIFY, HTML_TAG_VERIFY } from "../gql/queries/userWebSiteList";
import { usePopup } from "../context/commonPop";
import { UpdateDataUserVerify } from "../gql/mutations/userWebSiteVisit";
import { ownwerDomainCreate } from "../redux/action";
import darkLogoImage from "../Images/hotSpotLogoBlue.svg";
import { GetUserWebsiteData } from "../hooks/userWebsiteData";

const AccordionsBoxWrapper = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.common.secondory}`,
  borderRadius: "10px",
  "& h4.AccordionSubHeading": {
    marginBottom: "15px",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "17.64px",
    textAlign: "left",
    color: "#2A3547",
  },
  "& > .MuiAccordion-root": {
    boxShadow: "none",
    border: "1px solid #dedede",
    marginBottom: "10px",
    borderRadius: "5px",
    "&::before": { content: "none" },
  },
  "& .AccordionHeaderTitle": {
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
    },
    "& h6": {
      color: "#2A3547",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "17.64px",
      textAlign: "left",
    },
  },
  "& .MuiAccordionDetails-root": {
    "& p": {
      fontSize: "16px",
      color: theme.palette.text.primary,

      marginBottom: "15px",
    },
    "& .GoogleConnectBTN": {
      display: "flex",
      alignItems: "center",
      padding: "8px 12px 8px",
      fontSize: "16px",
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      "& svg": {
        marginTop: "-1px",
        marginRight: "11px",
        fontSize: "26px",
        color: "#fff",
      },
      "&:hover": {
        backgroundColor: theme.palette.common.link,
        color: theme.palette.common.white,
        "& svg": { color: theme.palette.common.white },
      },
      "&:focus": { outline: "none" },
    },
  },
  "& .DNSRecordBodyContent": {
    "& p": { "& strong": { fontWeight: "700" } },
    "& .MetaDataBlock": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.white,
      borderRadius: "5px",
      padding: "12px 18px 12px",
      marginBottom: "15px",
      "& p": {
        color: theme.palette.primary.main,
        marginBottom: "5px",
        "&:last-child": { marginBottom: "0" },
      },
    },
    "& .ErrorText": { color: "#ff0000" },
    "& .VerifyBtn": {
      boxShadow: "none",
      textTransform: "none",
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      borderRadius: "5px",
      padding: "4px 18px 4px",
      "&:hover": {
        backgroundColor: theme.palette.common.link,
        color: theme.palette.common.white,
      },
      "&:focus": { outline: "none" },
      "& span.MuiCircularProgress-root": {
        width: "25px !important",
        height: "25px !important",
        "& svg": { color: "#ffffff" },
      },
    },
  },
  "& .HtmlFileBodyContent": {
    "& p": {
      "& a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
      },
    },
    "& .VerificationDownloadBtn": {
      boxShadow: "none",
      textTransform: "uppercase",
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      borderRadius: "5px",
      marginBottom: "15px",
      padding: "4px 18px 4px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
      },
      "&:focus": { outline: "none" },
    },
    "& .VerifyBtn": {
      boxShadow: "none",
      textTransform: "none",
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      borderRadius: "5px",
      padding: "4px 18px 4px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
      },
      "&:focus": { outline: "none" },
      "& span.MuiCircularProgress-root": {
        width: "25px !important",
        height: "25px !important",
        "& svg": { color: "#ffffff" },
      },
    },
  },
  "& .HtmlTagBodyContent": {
    "& .MetaDataBlock": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      borderRadius: "5px",
      padding: "12px 18px 12px",
      marginBottom: "15px",
      "& p": { color: theme.palette.primary.main, marginBottom: "5px", "&:last-child": { marginBottom: "0" } },
    },
    "& .VerifyBtn": {
      boxShadow: "none",
      textTransform: "none",
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      borderRadius: "5px",
      padding: "4px 18px 4px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
      },
      "&:focus": { outline: "none" },
      "& span.MuiCircularProgress-root": {
        width: "25px !important",
        height: "25px !important",
        "& svg": { color: "#ffffff" },
      },
    },
  },
  "& .accordianItem": {
    backgroundColor: "#F7F7F7",
  },
}));
const verificationBox = {
  backgroundColor: "#ECF9FA",
  borderRadius: "6px",
  padding: "20px",
  mt: "10px",
  "& ul li": {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "17.64px",
    color: "#2A3547",
    mb: "8px",
  },
};
const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "100vh",
  backgroundColor: "#EFF3FB",
  paddingTop: "30px",
  paddingBottom: "30px",
  "& .LogoWrapper": {
    textAlign: "center",
    "& img": { width: "105px", height: "auto" },
  },
  "& .ImportMainHead": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "@media screen and (max-width:600px)": {
      flexDirection: "column",
      marginBottom: "20px",
    },
    "& .MainHeading": { color: "#2A3547", textAlign: "center", lineHeight: 1, fontSize: "24px", fontWeight: 700, marginBottom: "10px" },
    "& .SubHeading": {
      color: "#757575",
      textAlign: "center",
      lineHeight: 1,
      fontSize: "14px",
      fontWeight: 500,
    },
  },
  "& .continueBtn": {
    textTransform: "none",
    backgroundColor: "#45C0CB",
    color: "#ffffff",
    borderRadius: "5px",
    boxShadow: "none",
    padding: "12px 22px 10px",
    width: "100%",
    marginTop: "10px",
    "&:hover": { backgroundColor: "#45C0CB" },
    "&:focus": {
      outline: "0",
      backgroundColor: "#45C0CB",
    },
  },
  "& .MainHeading h2": {
    color: "#2A3547",
    textAlign: "center",
    lineHeight: 1,
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "10px",
  },
  "& .SubHeading": {
    color: "#757575",
    textAlign: "center",
    lineHeight: 1,
    fontSize: "14px",
    fontWeight: 500,
  },
  "& .importProjectbox": {
    backgroundColor: "#fff",
    padding: "20px",
    border: "1px solid #E3E3E3",
    borderRadius: "8px",
    "& .InputWrapper": {
      "& button": {
        backgroundColor: theme.palette.primary.main,
        padding: "12px 0",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "17.64px",
        textAlign: "left",
      },
      "& .selectInput": {
        width: "40%",
      },
      "& .MuiFormControl-root": {
        marginTop: "7px",
        "& fieldset": {
          borderColor: " #E3E3E3 !important",
        },
        "&.FullWidthInput": {
          flex: "100%",
        },
        "& label": { lineHeight: 1 },
      },
      "& label": { color: theme.palette.text.primary, fontSize: "12px", fontWeight: 600, lineHeight: "15.12px", textAlign: "left" },
    },
  },
}));

const BackButtonBox = styled(Box)(({ theme }) => ({
  "@media screen and (max-width:600px)": {
    width: "100%",
  },
  "& button": {
    textTransform: "none",
    backgroundColor: "#45C0CB", // Set hover color as default
    color: "#ffffff",
    borderRadius: "5px",
    boxShadow: "none",
    marginBottom: "15px",
    "@media screen and (max-width:600px)": {
      width: "100%",
    },
    "&:focus": {
      outline: "0",
    },
    "& svg": {
      fontSize: "22px",
      marginLeft: "-7px",
    },
  },
  "& a": {
    color: "#ffffff",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "'Plus Jakarta Sans', sans-serif !important",
    "& svg": { marginRight: "6px", fontSize: "22px", transition: "0.9s ease-out" },
  },
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "45C0CB", // Retain hover color here
    color: theme.palette.common.white,
    "& a": { color: "#45C0CB" },
    "& svg": { transform: "rotateZ(720deg)" },
  },
  "&:focus": { outline: "none" },
}));

const loderfor = {
  isLoading: false,
  htmlTag: false,
  htmlFile: false,
  dnsLoading: false,
  googleLoding: false,
};
function VerifyOwner({ ProjectData }) {
  const [errMessage, setErrMessage] = useState({});
  const [loder, setLoder] = useState(loderfor);
  const [tagStr, setTagStr] = useState("");
  const [googleMsg, setGoogleMsg] = useState("");
  const [splitValue, setSplitValue] = useState([]);
  // const [searchParams] = useSearchParams();
  const { handleOpen } = usePopup();
  const { userData } = useSelector(state => state.reducer);
  const { ownerId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const ownerId = searchParams.get("origin");
  const [GOOGLE_AUTH_VARIFY] = useLazyQuery(GoogleAuthApi, { fetchPolicy: "network-only" });
  const [CALL_DOMAIN] = useLazyQuery(findDomain, { fetchPolicy: "network-only" }, { fetchPolicy: "network-only" });
  const [HTML_VERIFY] = useLazyQuery(HTML_FILE_VERIFY, { fetchPolicy: "network-only" });
  const [HTML_TAG] = useLazyQuery(HTML_TAG_VERIFY, { fetchPolicy: "network-only" });
  const [UPDATE_USER_WEB_VISIT] = useMutation(UpdateDataUserVerify);
  // const { createScrapy } = CreateScrapyReport();
  // const { CreatePageSpeed } = PageSpeedCreate();

  const { token, website, isDns, isGoogle, isHtmlFile, isHtmlTag } = ProjectData || {};
  const updateMutationWebSite = async payload => {
    await UPDATE_USER_WEB_VISIT({
      variables: { payload },
      onCompleted: async ({ updateUserWebSiteVerify }) => {
        // const { data } = await createScrapy(updateUserWebSiteVerify);
        // const { ScrapUrl } = data || {};
        // if (ScrapUrl && ScrapUrl?.sessionId) {
        //   const { sessionId } = ScrapUrl || {};
        //   await navigate("/dashboard?menu=dashboard");
        //   handleOpen(true, `Scraping is in progress. Results will be on the dashboard shortly.`);
        //   await CreatePageSpeed(ownerId, projectId, website, sessionId);
        // }

        dispatch(ownwerDomainCreate(updateUserWebSiteVerify));
        navigate("/dashboard?menu=dashboard");
      },
      onError: errdata => {
        handleOpen(true, "Something went wrong please wait some time");
        console.log("UPDATE_USERVISIT", errdata.message);
      },
    });
  };
  const dnsRecordVerifyBtn = async (inputDomain, id) => {
    if (inputDomain) {
      setLoder({ ...loder, dnsLoading: true });
      await CALL_DOMAIN({
        variables: {
          domain: inputDomain,
        },
        onCompleted: async ({ performDnsLookup }) => {
          if (token) {
            const arr = [];
            performDnsLookup.forEach(el => {
              const result = el[0].search(token);
              if (result > -1) {
                arr.push(result);
              }
            });
            const payload = {
              isDns: true,
              isGoogle,
              isHtmlFile,
              isHtmlTag,
              verificationType: "meta_data",
              isVerified: true,
              id,
            };
            if (arr.length > 0) {
              await updateMutationWebSite(payload);
              await setLoder({ ...loder, dnsLoding: false });
              // if (data && data?.updateUserWebSiteVerify) {
              //   navigate("/dashboard");
              // }
            } else {
              await setLoder({ ...loder, dnsLoding: false });
              await setErrMessage({
                ...errMessage,
                dnsRecord: "TXT record not found. Please check your DNS configuration and try again.",
              });
            }
          }
        },
        onError: errdata => {
          setLoder({ ...loder, dnsLoading: false });
          setErrMessage({
            ...errMessage,
            dnsRecord: "TXT record not found. Please check your DNS configuration and try again.",
          });
          console.log(errdata.message);
        },
      });
    } else {
      console.log({ dnsRecord: "Domain missing" });
    }
  };

  const findOwnerById = async () => {
    setLoder({ ...loder, isLoading: true });

    try {
      const arr = website.split("/");
      await setSplitValue(arr);
      // await dispatch(ownwerDomainCreate(ProjectData));
      const str = `<meta name="report-tool-verification" content=${token}>`;
      await setTagStr(str);
      setLoder({ ...loder, isLoading: false });
    } catch (error) {
      setLoder({ ...loder, isLoading: false });
      console.log("errdata", error.message);
      handleOpen(true, "Something went wrong please wait some time");
    }
  };
  const GoogleAuthlogin = useGoogleLogin({
    scope: "https://www.googleapis.com/auth/siteverification",
    onSuccess: async tokenResponse => {
      if (tokenResponse?.access_token) {
        setLoder({ ...loder, googleLoding: true });
        const res = await GOOGLE_AUTH_VARIFY({
          variables: { token: tokenResponse?.access_token },
          onError: errdata => {
            console.log("errdata", errdata);
          },
        });
        const result = res?.data?.googleAuthApi.search(website);

        if (result > -1) {
          console.log(userData.id, true, ownerId);
          await setGoogleMsg("Verify by Gooogle");
          const payload = {
            verificationType: "search_console",
            isVerified: true,
            id: ownerId,
            isDns,
            isGoogle: true,
            isHtmlFile,
            isHtmlTag,
          };
          await updateMutationWebSite(payload);
        } else {
          setLoder({ ...loder, googleLoding: false });
          setErrMessage({
            ...errMessage,
            googleErr: `Website Not Found! 
                     Please make sure you are a verified owner or try other methods`,
          });
        }
      }
    },
  });
  const downloadFile = () => {
    const element = document.createElement("a");
    const file = new Blob([`report-tool-verification_${token}`], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${token}.file`;
    document.body.appendChild(element);
    element.click();
  };
  const HandleHTMLfile = async id => {
    if (id) {
      setLoder({ ...loder, htmlFile: true });
      await HTML_VERIFY({
        variables: {
          htmlFileVerifyId: id,
        },
        onCompleted: async ({ htmlFileVerify }) => {
          setLoder({ ...loder, htmlFile: false });
          if (htmlFileVerify) {
            const payload = {
              verificationType: "meta_data",
              isVerified: true,
              isHtmlFile: true,
              id,
              isGoogle,
              isHtmlTag,
              isDns,
            };
            await updateMutationWebSite(payload);

            // if (resp?.data && resp?.data?.updateUserWebSiteVerify) {
            //   navigate("/dashboard");
            // }
          } else {
            setErrMessage({
              ...errMessage,
              htmlFileVerify: "website is not verify",
            });
          }
        },
        onError: errdata => {
          setLoder({ ...loder, htmlFile: false });
          console.log("errdata HandleHTMLfile", errdata);
        },
      });
    } else {
      console.log("owner id not found");
    }
  };
  const handleHTMLtag = async id => {
    setLoder({ ...loder, htmlTag: true });
    if (id) {
      await HTML_TAG({
        variables: {
          htmlTagVerifyId: id,
        },
        onCompleted: async ({ htmlTagVerify }) => {
          if (htmlTagVerify) {
            setLoder({ ...loder, htmlTag: false });
            const payload = {
              verificationType: "meta_data",
              isVerified: true,
              isHtmlTag: true,
              id,
              isDns,
              isGoogle,
              isHtmlFile,
            };
            await updateMutationWebSite(payload);
          } else {
            setLoder({ ...loder, htmlTag: false });
            setErrMessage({
              ...errMessage,
              htmlTagVerify: "website is not verify",
            });
          }
        },
        onError: errdata => {
          setLoder({ ...loder, htmlTag: false });
          console.log("errdata handleHTMLtag", errdata);
        },
      });
    } else {
      setLoder({ ...loder, htmlTag: false });
      setErrMessage({
        ...errMessage,
        htmlTagVerify: "website is not verify",
      });
      console.log("owner id not found");
    }
  };
  useEffect(() => {
    if (ProjectData) findOwnerById();
    // eslint-disable-next-line
  }, [ProjectData]);

  const handleVerifyOwnerShip = () => {
    navigate(`/first-project-config/site/${ownerId}`);
    // setManageComponent(null);
  };
  const handleBackClick = () => {
    navigate(`/first-project-config/update/${ownerId}`);
  };
  return (
    <SectionWrapper>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Box className="LogoWrapper">
                <Typography component="img" src={darkLogoImage} alt="Logo" />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={0} justifyContent="center">
            <Grid item xs={12} md={12}>
              <Box className="importProjectbox">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box className="ImportMainHead">
                      <Box sx={{ flex: 1, width: { xs: "100%", sm: "unset" } }}>
                        <Box sx={{ width: { xs: "100%", sm: "50%" }, m: "auto", mb: { xs: "15px ", sm: "30px" } }}>
                          <MannualSteps activeStep={1} />
                        </Box>
                      </Box>
                      <BackButtonBox>
                        <Button
                          onClick={handleBackClick}
                          sx={{
                            marginLeft: "-80px",
                            zIndex: 99999999,
                            "@media screen and (max-width:600px)": { marginLeft: "0" },
                            "&:hover": { backgroundColor: "#45C0CB !important" },
                          }}
                        >
                          <KeyboardArrowLeftIcon />
                          Back
                        </Button>
                      </BackButtonBox>
                    </Box>
                    <Box className="MainHeading">
                      <Typography component="h2">Verify ownership</Typography>
                      <Typography className="SubHeading">To use Hotspot and check your website for free, please prove that you website</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={verificationBox}>
                      <Typography component="ul">
                        <Typography component="li">Verification proves that you own the website to be audited</Typography>
                        <Typography component="li">You can use any of the following verification methods</Typography>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <AccordionsBoxWrapper>
                      <Typography component="h4" className="AccordionSubHeading">
                        Recommended method
                      </Typography>
                      <Accordion className="accordianItem">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className="AccordionHeaderTitle">
                          <IconButton>{ProjectData && isGoogle ? <CheckCircleIcon sx={{ color: "#008000" }} /> : <CancelIcon sx={{ color: "#2A3547" }} />}</IconButton>
                          <Typography component="h6">Google Search {googleMsg}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography component="p">
                            Connect your Google Account and allow access to your Search Console data. If your website is verified in your Console, it will be automatically verified
                            in Ahrefs.
                          </Typography>
                          <Button className="GoogleConnectBTN" onClick={() => GoogleAuthlogin()} disabled={isGoogle || loder.googleLoding}>
                            {loder.googleLoding ? (
                              <CircularProgress />
                            ) : (
                              <>
                                <GoogleIcon />
                                Link Google Account{" "}
                              </>
                            )}
                          </Button>
                          <Typography component="span" color="red">
                            {errMessage?.googleErr}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Typography component="h4" className="AccordionSubHeading">
                        Other methods
                      </Typography>
                      <Accordion className="accordianItem">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className="AccordionHeaderTitle">
                          <IconButton>{ProjectData && isDns ? <CheckCircleIcon sx={{ color: "#008000" }} /> : <CancelIcon sx={{ color: "#2A3547" }} />}</IconButton>
                          <Typography component="h6">DNS record</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="DNSRecordBodyContent">
                          <Typography component="p">
                            Sign in to your domain name provider (e.g. godaddy.com or namecheap.com) and copy the TXT record below to the DNS configuration for{" "}
                            <Typography component="strong">{splitValue[2]}</Typography>
                          </Typography>
                          <Box className="MetaDataBlock">
                            <Typography component="p">Host Record: @</Typography>
                            <Typography component="p">Value: report-tool-verification_{token}</Typography>
                          </Box>
                          <Typography>Complete TXT Record Resolution may take up to 24-48 hours.</Typography>
                          <Typography component="p">
                            Note:{" "}
                            <Typography component="i">
                              {" "}
                              DNS changes may take some time. If Site Audit doesn’t find the record immediately, wait a day before trying again.
                            </Typography>
                          </Typography>
                          <Button
                            className="VerifyBtn"
                            disabled={isDns || loder.dnsLoading}
                            onClick={() => {
                              // HandleDnsRecord(ownerId);
                              if (splitValue) dnsRecordVerifyBtn(splitValue[2], ownerId);
                            }}
                          >
                            {loder.dnsLoading ? <CircularProgress /> : "Verify"}
                          </Button>
                          <Typography className="ErrorText" component="p">
                            {errMessage?.dnsRecord}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="accordianItem">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header" className="AccordionHeaderTitle">
                          <IconButton>{ProjectData && isHtmlFile ? <CheckCircleIcon sx={{ color: "#008000" }} /> : <CancelIcon sx={{ color: "#2A3547" }} />}</IconButton>
                          <Typography component="h6">HTML file</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="HtmlFileBodyContent">
                          <Typography component="p">Upload the HTML verification file below to your domain’s root folder discoverwebtech.com/ .</Typography>
                          <Button onClick={downloadFile} className="VerificationDownloadBtn">
                            download verification file
                          </Button>
                          <Typography component="p" sx={{ wordBreak: "break-all" }}>
                            If the upload was successful, the file will be viewable at:
                            <Link href={`${splitValue[0]}//${splitValue[2]}/${ProjectData?.token}.file`}>
                              {splitValue && `${splitValue[0]}//${splitValue[2]}/${ProjectData?.token}.file`}
                            </Link>
                          </Typography>
                          <Button
                            className="VerifyBtn"
                            onClick={() => {
                              HandleHTMLfile(ownerId);
                            }}
                            disabled={isHtmlFile || loder.htmlFile}
                          >
                            {loder.htmlFile ? <CircularProgress /> : "Verify"}
                          </Button>
                          <Typography component="span" color="red">
                            {errMessage.htmlFileVerify}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="accordianItem">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className="AccordionHeaderTitle">
                          <IconButton>{ProjectData && isHtmlTag ? <CheckCircleIcon sx={{ color: "#008000" }} /> : <CancelIcon sx={{ color: "#2A3547" }} />}</IconButton>
                          <Typography component="h6">HTML tag</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="HtmlTagBodyContent">
                          <Typography component="p">
                            Copy the meta tag below and paste it into the HTML code of your site’s homepage. It should go in the section, before the first section..
                          </Typography>
                          <Box className="MetaDataBlock">
                            <Typography> {ProjectData && tagStr}</Typography>
                          </Box>

                          <Button
                            className="VerifyBtn"
                            onClick={() => {
                              handleHTMLtag(ownerId);
                            }}
                            disabled={isHtmlTag || loder.htmlTag}
                          >
                            {loder.htmlTag ? <CircularProgress /> : "Verify"}
                          </Button>
                          <Typography component="span" color="red">
                            {errMessage.htmlTagVerify}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </AccordionsBoxWrapper>
                    <Button className="continueBtn" disabled={!isHtmlFile && !isHtmlTag && !isGoogle && !isDns} fullWidth onClick={handleVerifyOwnerShip}>
                      Continue
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

function VerifyOwnership() {
  const { ownerId } = useParams();
  const { ProjectData } = GetUserWebsiteData(ownerId);
  return <VerifyOwner ProjectData={ProjectData} />;
}
export default VerifyOwnership;
