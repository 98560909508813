/* eslint-disable */
import * as React from "react";
import { Grid, Tabs, Tab, Box, Divider, Container, styled, Card, useTheme } from "@mui/material";
import { IconUserCircle, IconArticle } from "@tabler/icons-react";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import Breadcrumb from "../../shared/Breadcrumb";
import AccountTab from "../../Account-setting/AccountTab";
import BillsTab from "../../Account-setting/BillsTab";
import SubscriptionHistoryTab from "../../Account-setting/SubscriptionHistoryTab";
import { useSelector } from "react-redux";
import { defultSubscriptionType } from "../../../common/constant";

const BCrumb = [
  {
    to: "/",
    title: "Home",
  },
  {
    title: "Account Setting",
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingTop: "16px",
  paddingBottom: "30px",
  "& h4": {
    color: theme.palette.text.primary,
  },
}));
function AccountSetting() {
  const theme = useTheme();
  const { userData } = useSelector(state => state.reducer);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Breadcrumb title="Account Setting" items={BCrumb} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ padding: "0px", border: `1px solid ${theme.palette.divider}`, boxShadow: "none !important" }}>
              <Box sx={{ maxWidth: { xs: 320, sm: 480 } }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  allowScrollButtonsMobile
                  sx={{
                    "& button": {
                      "&:focus": { outline: "none" },
                    },
                  }}
                  scrollButtons="auto"
                  variant="scrollable"
                  aria-label="basic tabs example"
                >
                  <Tab iconPosition="start" icon={<IconUserCircle size="22" />} label="Account" {...a11yProps(0)} />
                  <Tab iconPosition="start" icon={<IconArticle size="22" />} label="Bills" {...a11yProps(1)} />
                  {userData?.subscriptionCredits?.subscriptionType == defultSubscriptionType || userData?.subscriptionCredits?.subscriptionType == null ? (
                    ""
                  ) : (
                    <Tab iconPosition="start" icon={<CurrencyExchangeIcon sx={{ fontSize: "20px" }} />} label="Subscription History" {...a11yProps(2)} />
                  )}
                </Tabs>
              </Box>
              <Divider />
              <Card sx={{ padding: "0px", border: `none`, boxShadow: "none !important", borderRadius: "0" }}>
                <TabPanel value={value} index={0}>
                  <AccountTab />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <BillsTab setValue={setValue} />
                </TabPanel>
                {userData?.subscriptionCredits?.subscriptionType == defultSubscriptionType || userData?.subscriptionCredits?.subscriptionType == null ? (
                  ""
                ) : (
                  <TabPanel value={value} index={2}>
                    <SubscriptionHistoryTab />
                  </TabPanel>
                )}
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </SectionWrapper>
  );
}

export default AccountSetting;
