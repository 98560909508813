import gql from "graphql-tag";

export const getPrice = gql`
  query Query {
    GetPrice
  }
`;
export const getCheckOutSession = gql`
  query Query($priceId: String!, $userId: String!) {
    GetCheckOutSession(priceId: $priceId, userId: $userId)
  }
`;
export const subscriptionCancelQuery = gql`
  query Query {
    getCancelSubscription
  }
`;

export const subscriptionHistory = gql`
  query Card {
    getUserPaymentHistory {
      planInfo {
        amount
        currency
        planId
        planName
        planType
      }
      razorpay_payment_id
      razorpay_signature
      razorpay_subscription_id
      updatedAt
      userId
      card {
        created_at
        endTime
        entity
        last4
        name
        network
        type
      }
    }
  }
`;
