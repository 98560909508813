/* eslint-disable */
import { useState } from "react";
import { Box, Button, MenuItem, Modal, TextField, styled } from "@mui/material";
import CustomTextField from "../forms/theme-elements/CustomTextField";
import CustomSelect from "../forms/theme-elements/CustomSelect";
import CustomFormLabel from "../forms/theme-elements/CustomFormLabel";
import ParentCard from "../shared/ParentCard";
import { validateDescription, validateString } from "../../utils/validations";
import { useLazyQuery, useMutation } from "@apollo/client";
import { createChat, createTicket } from "../../gql/mutations/ticket";
import { useSelector } from "react-redux";
import { convertToBase64 } from "../../utils/myUtills";
import { usePopup } from "../../context/commonPop";
import { createFetch, getChat } from "../../gql/queries/ticket";
import useQuery from "../../hooks/allQuery";
import { useEffect } from "react";
const SubmitBtn = styled(Box)(({ theme }) => ({
  marginTop: "15px",
  "& button": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
}));

const numbers = [
  {
    value: "Account",
  },
  {
    value: "Profile",
  },
  {
    value: "Payment",
  },
  {
    value: "Website audit",
  },
  {
    value: "Other",
  },
];

function TicketUpdateForm({ open, setOpen, selectedTicket, mode = 'user' }) {
  const [data, setData] = useState({ type: "", description: "", image: "", id: "", message: "" });
  const [chat, setChat] = useState([]);

  const [errorClass, setErrorClass] = useState({
    message: false,
  });
  const { userData } = useSelector(state => state.reducer);
  const { handleOpen } = usePopup();
  const [CREATE_TICKET] = useMutation(createTicket, { fetchPolicy: "network-only" });
  const [CREATE_CHAT] = useMutation(createChat, { fetchPolicy: "network-only" });
  const [GET_CHAT] = useLazyQuery(getChat, { fetchPolicy: "network-only" });
  const { allQuery } = useQuery();

  useEffect(() => {
    setData({ ...data, description: selectedTicket.description, type: selectedTicket.type, image: selectedTicket.image, id: selectedTicket.id });
  }, [selectedTicket]);

  useEffect(() => {
    if (selectedTicket?.id){
    allQuery({
      QUERY: GET_CHAT,
      variables: {
        chatFindUniqueId: selectedTicket.id,
      },
      onCompleteFunc: info => {
        const { chatFindUnique } = info || {};
        setChat(chatFindUnique?.chat);
      },
    });
  }
  }, [selectedTicket]);

  const handleChange = async e => {
    const { name, value } = e.target;

    switch (name) {
      case "image":
        const file = e.target.files[0];
        if (!file) {
          return;
        }

        if (file.type.split("/")[0] !== "image" || file.size > 1e7) {
          setErrorClass({
            ...errorClass,
            image: true,
          });
          return; // Stop further processing if the file size is too large
        } else {
          setErrorClass({
            ...errorClass,
            image: false,
          });
        }

        const base64 = await convertToBase64(file);

        setData({
          ...data,
          [name]: base64,
        });
        break;

      default:
        setData({ ...data, [name]: value });
    }
  };

  async function checkError(data) {
    let message = await validateString(data.message);
    await setErrorClass({
      ...errorClass,
      message,
    });

    if (message) return true;
    else return false;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const check = await checkError(data);

    if (check) return;

    allQuery({
      QUERY: CREATE_CHAT,
      variables: {
        createData: {
          ticketId: data.id,
          user: mode == 'user' && data.message,
          admin: mode == 'admin' && data.message,
        },
      },
      onCompleteFunc: () => {
        handleOpen(true, "message sent");
        setData({ ...data, message: "" });
        setOpen(false);
      },
    });
  }
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ParentCard>
        <Box component={"form"} onSubmit={handleSubmit}>
          <CustomFormLabel htmlFor="standard-select-number">Ticket id</CustomFormLabel>
          {data.id}
          <CustomFormLabel htmlFor="standard-select-number">Type</CustomFormLabel>
          {data.type}
          <CustomFormLabel htmlFor="image">Image</CustomFormLabel>
          <img src={data?.image} alt="image" width={100} height={100} />
          <CustomFormLabel htmlFor="description">Description</CustomFormLabel> {data.description}
          <CustomFormLabel htmlFor="message">Admin chat</CustomFormLabel>
          {chat &&
            chat.map(item => {
              return <Box><Box>{item.admin && item.admin} {item.user&& item?.user}</Box></Box>;
            })}
          <CustomFormLabel htmlFor="message">Comment on This</CustomFormLabel>
          <TextField
            id="message"
            name="message"
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            value={data.message}
            onChange={handleChange}
            error={errorClass.message}
            helperText={errorClass.message && "please enter a valid message"}
          />
          <SubmitBtn>
            <Button variant="contained" type="submit">
              Send
            </Button>
          </SubmitBtn>
        </Box>
      </ParentCard>
    </Modal>
  );
}

export default TicketUpdateForm;
