import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Button, MenuItem, Select, Typography, Switch, FormControlLabel } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router";
import { styled, useTheme } from "@mui/material/styles";
import { AUDIT_DATA, AUDIT_OFF, SITE_AUDIT } from "../../gql/mutations/siteAudit";
import { usePopup } from "../../context/commonPop";
import { GetUserWebsiteData } from "../../hooks/userWebsiteData";
// import lightTheme from "../theme";

const SiteAuditWrapper = styled(Box)(({ theme }) => ({
  "& h1": {
    color: theme.palette.text.primary,
    fontSize: "25px",
    fontWeight: 600,
  },
  "& .autositeaudit": {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  "& .saveBtn": {
    // boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    marginBottom: "15px",
    backgroundColor: theme.palette.primary.main,
    fontWeight: 600,
    color: "#fff",
    "&[disabled]": { color: "#ffffff38" },
  },
  "& .switchBtn .css-ab0lf1-MuiButtonBase-root-MuiSwitch-switchBase": {
    color: "black",
  },
  "& .switchBtn .css-ab0lf1-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked": {
    color: "#1976d2",
  },
  "& .switchBtn .css-mghcik-MuiButtonBase-root-MuiSwitch-switchBase": {
    color: "black",
  },
  "& .switchBtn .css-dnkzfa-MuiSwitch-track": {
    backgroundColor: " #001e5b",
    opacity: 0.38,
  },
  "& .switchBtn .Mui-checked .css-jsexje-MuiSwitch-thumb": {
    backgroundColor: theme.palette.primary.main,
  },
  "& .switchBtn .css-mghcik-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    backgroundColor: " #1976d2",
  },
  " .css-u3jdsl-MuiSwitch-thumb": {
    color: theme.palette.text.primary,
  },
  "& .Mui-checked .css-u3jdsl-MuiSwitch-thumb": {
    color: theme.palette.primary.main,
  },
  "& .generatingreport": {
    color: theme.palette.common.black,
  },
  "& .siteAuditWrapper": {
    display: "flex",
    margin: "30px 0",
    alignItems: "center",
    gap: "30px",
    // border: `1px solid ${theme.palette.common.secondory}`,
    padding: "15px ",
    borderRadius: "15px",
    "& h2": {
      color: theme.palette.text.primary,
      fontWeight: 600,
      width: "100px",
    },
    "& .TimePicker": {
      "& label,input,svg": {
        color: theme.palette.text.primary,
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.text.primary}`,
      },
    },
    "& .endTime": {
      "& .Mui-disabled": {
        textFillColor: `${theme.palette.text.primary}`,
        opacity: "0.5",
      },
    },
    "& .FrequencySelect": {
      // boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",

      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.text.primary}`,
      },
      "& .MuiSelect-select": {
        color: `${theme.palette.text.primary} !important`,
        fontWeight: 600,
      },
      "& .MuiMenuItem-root ": {
        color: `${theme.palette.text.primary} !important`,
      },
      "& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon, .css-1wu7ecg-MuiSvgIcon-root-MuiSelect-icon, .css-bpeome-MuiSvgIcon-root-MuiSelect-icon, .css-gofekn-MuiSvgIcon-root-MuiSelect-icon":
        {
          fill: theme.palette.text.primary,
        },
    },
  },
  // "& .css-at26bj-MuiPaper-root-MuiMenu-paper-MuiPopover-paper": {
  //   backgroundColor: "red !important",
  // },
  "& .startDay": {
    "& button": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    },
    "& .active": {
      backgroundColor: "black",
      fontWeight: 600,
    },
  },
}));

const MenuItemWrapper = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

function SiteAudit() {
  const themen = useTheme();
  const { ownerId } = useParams();
  const [state, setState] = useState({
    frequency: "weekly",
    day: "1",
    startTime: dayjs("2023-05-25T12:00"),
    endTime: dayjs("2023-05-25T13:00"),
    week: "monday",
    checked: false,
  });
  const [savebutton, setSavebutton] = useState(false);

  const { handleOpen } = usePopup();

  const [siteaudit] = useMutation(SITE_AUDIT);
  const [auditdata] = useLazyQuery(AUDIT_DATA, { fetchPolicy: "network-and-cache" });
  const [auditoff] = useMutation(AUDIT_OFF);
  let startday;
  useEffect(() => {
    auditdata({
      variables: {
        projectId: ownerId,
      },
      onCompleted: res => {
        setSavebutton(true);
        const { GetSiteAudit } = res || {};
        const { frequency, startTime, endTime, startDay, isActive } = GetSiteAudit || {};
        const parsedStartTime = dayjs(startTime, "hh:mm A").format("YYYY-MM-DDTHH:mm");
        const parsedEndTime = dayjs(endTime, "hh:mm A").format("YYYY-MM-DDTHH:mm");
        if (frequency === "monthly") {
          setState({ ...state, frequency, startTime: dayjs(parsedStartTime), endTime: dayjs(parsedEndTime), day: startDay, checked: isActive });
        } else if (frequency === "weekly") {
          setState({ ...state, frequency, startTime: dayjs(parsedStartTime), endTime: dayjs(parsedEndTime), week: startDay, checked: isActive });
        } else if (frequency) {
          setState({ ...state, frequency, startTime: dayjs(parsedStartTime), endTime: dayjs(parsedEndTime), checked: isActive });
        }
      },
      onError: err => {
        console.log("err", err);
      },
    });
  }, []);

  const arr = [
    ["monday", "Mn"],
    ["tuesday", "Tu"],
    ["wednesday", "We"],
    ["thursday", "Th"],
    ["friday", "Fr"],
    ["saturday", "Sa"],
    ["sunday", "Su"],
  ];

  const handleOptionChange = event => {
    setSavebutton(false);
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleTimeChange = (name, newTime) => {
    setSavebutton(false);
    setState(prevState => ({
      ...prevState,
      [name]: newTime,
      [`endTime`]: newTime.add(1, "hour"),
    }));
  };

  const handleReoprt = () => {
    setState({ ...state, checked: !state.checked });
    auditoff({
      variables: {
        projectId: ownerId,
      },
      onCompleted: res => {
        const { SiteAuditOff } = res;
        if (SiteAuditOff?.isActive) {
          handleOpen(true, "Automatic Generating Report On");
        } else {
          handleOpen(true, "Automatic Generating Report Off");
        }
      },
      onError: err => {
        console.log("error is", err);
      },
    });
  };

  const { ProjectData } = GetUserWebsiteData(ownerId);
  const { userId, website } = ProjectData || {};

  const handleSave = async () => {
    const formattedStartTime = dayjs(state.startTime?.$d).format("hh:mm A");
    const formattedEndTime = dayjs(state.startTime).add(1, "hour").format("hh:mm A");

    if (state.frequency === "monthly") {
      startday = String(state.day);
    } else if (state.frequency === "weekly") {
      startday = state.week;
    } else {
      startday = null;
    }

    siteaudit({
      variables: {
        payload: {
          frequency: state.frequency,
          projectId: ownerId,
          startDay: startday,
          startTime: formattedStartTime,
          endTime: formattedEndTime,
          userId,
          website,
        },
      },
      onCompleted: () => {
        handleOpen(true, "Updated Site Audit Schedule");
        setSavebutton(true);
      },
      onError: err => {
        console.log("Error", err);
      },
    });
  };

  return (
    <SiteAuditWrapper>
      <Typography component="h1">Site Audit</Typography>
      <FormControlLabel control={<Switch checked={state.checked} onChange={handleReoprt} color="primary" />} />
      <Typography className="autositeaudit" component="span">
        {state.checked ? "Automatic Site Audit On" : "Automatic Site Audit Off"}
      </Typography>
      <Box className="siteAuditWrapper">
        <Typography component="h2">Frequency</Typography>
        <Select name="frequency" value={state.frequency} onChange={handleOptionChange} disabled={!state.checked} className="FrequencySelect">
          <MenuItemWrapper value="daily">Daily</MenuItemWrapper>
          <MenuItemWrapper value="weekly">Weekly</MenuItemWrapper>
          <MenuItemWrapper value="monthly">Monthly</MenuItemWrapper>
        </Select>
      </Box>
      {state.frequency === "monthly" && (
        <Box className="siteAuditWrapper">
          <Typography component="h2">Start Day</Typography>

          <Select name="day" value={state.day} onChange={handleOptionChange}>
            {[...Array(31)].map((_, index) => (
              <MenuItem disabled={!state.checked} value={index + 1}>
                {index + 1}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
      {state.frequency === "weekly" && (
        <Box className="siteAuditWrapper startDay">
          <Typography component="h2">Start Day</Typography>

          {arr.map(items => (
            <Button
              disabled={!state.checked}
              onClick={handleOptionChange}
              sx={{ backgroundColor: (themen.palette.mode === "light" || "dark") && state.week === items[0] && `${themen.palette.common.orange} !important` }}
              name="week"
              value={items[0]}
              className={state.week === items[0] ? "active" : null}
            >
              {items[1]}
            </Button>
          ))}
        </Box>
      )}
      <Box>
        <Box className="siteAuditWrapper">
          <Typography component="h2"> Start Time</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              disabled={!state.checked}
              className="TimePicker"
              label="Select Start Time"
              value={state.startTime}
              onChange={newTime => handleTimeChange("startTime", newTime)}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box>
        <Button className="saveBtn" onClick={handleSave} disabled={savebutton || !state.checked}>
          Save
        </Button>
      </Box>
      {/* <FormControlLabel sx={{ mb: "0" }} control={<Switch checked={state.checked} onChange={handleReoprt} color="primary" className="switchBtn" />} />
      <Typography className="generatingreport" component="span">
        {state.checked ? "Tap off to stop generating report" : "Tap on to generating report"}
      </Typography> */}
    </SiteAuditWrapper>
  );
}

export default SiteAudit;
