import { memo } from "react";
import { IconButton, Box, AppBar, useMediaQuery, Toolbar, styled, Stack } from "@mui/material";
import { IconMenu2 } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../NavBar/sidebar/logo/logo";
import Notifications from "../../NavBar/header/Notification";
import Profile from "../../NavBar/header/Profile";
import Navigation from "../../NavBar/header/Navigation";
import Search from "../../NavBar/header/Search";
import { toggleMobileSidebar } from "../../../redux/action";

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  background: theme.palette.background.paper,
  justifyContent: "center",
  backdropFilter: "blur(4px)",

  [theme.breakpoints.up("lg")]: {
    minHeight: 70,
  },
}));
const ToolbarStyled = styled(Toolbar)(({ theme }) => ({ margin: "0 auto", width: "100%", color: `${theme.palette.text.secondary} !important` }));

function HorizontalHeader() {
  const lgDown = useMediaQuery(theme => theme.breakpoints.down("lg"));
  const lgUp = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const customizer = useSelector(state => state.customizer);
  const dispatch = useDispatch();

  return (
    <AppBarStyled position="sticky" color="default" elevation={8}>
      <ToolbarStyled
        sx={{
          maxWidth: customizer.isLayout === "boxed" ? "lg" : "100%!important",
        }}
      >
        <Box sx={{ width: lgDown ? "45px" : "auto", overflow: "hidden" }}>
          <Logo path="/dashboard" />
        </Box>

        {lgDown && (
          <IconButton color="inherit" aria-label="menu" onClick={() => dispatch(toggleMobileSidebar())}>
            <IconMenu2 />
          </IconButton>
        )}

        <Search />
        {lgUp && <Navigation />}
        <Box flexGrow={1} />
        <Stack spacing={1} direction="row" alignItems="center">
          <Notifications />
          <Profile />
        </Stack>
      </ToolbarStyled>
    </AppBarStyled>
  );
}

export default memo(HorizontalHeader);
