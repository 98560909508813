import { Box, Button, Container, Grid, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import SuccessIcon from "../SvgIcons/SuccessIcon";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .BoxWrapper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .SuccessIcon": {
      width: "351px",
      height: "154px",
      marginBottom: "14px",
    },
    "& h3": {
      color: "#00CF00",
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: 1,
      marginBottom: "20px",
    },
    "& p": {
      textAlign: "center",
      color: "#757575",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: 1.5,
      marginBottom: "20px",
    },
    "& button": {
      textTransform: "none",
      backgroundColor: "#00CF00",
      color: "#ffffff",
      borderRadius: "5px",
      boxShadow: "none",
      padding: "8px 22px",
      width: "280px",
      "@media screen and (max-width:600px)": {
        width: "100%",
      },
    },
  },
}));

function PaymentSuccess() {
  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} lg={12}>
              <Box className="BoxWrapper">
                <SuccessIcon />
                <Typography component="h3">Success!</Typography>
                <Typography component="p">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                  <br />
                  has been the industry standard dummy text ever since the 1500s,
                </Typography>
                <Link to="/payments">
                  <Button>Done</Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default PaymentSuccess;
