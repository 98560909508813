import { memo } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const BoxWrapperstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "5px solid #6edbff",
  p: "30px 35px 34px",
  borderRadius: "15px",
  "& h2": { fontSize: "18px", fontWeight: "100", mb: "10px" },
  "& p": { fontSize: "16px", mb: "18px", color: "#797979" },
  "& button": {
    boxShadow: "none",
    textTransform: "none",
    backgroundColor: "#1a73e8",
    color: "#fff",
    borderRadius: "7px",
    border: "1px solid #1a73e8",
    padding: "6px 24px 6px",
    width: "100%",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
      color: "#1a73e8",
    },
  },
};

function MessagePopUp({ message, open, setOpen, onSubmit }) {
  const handleClose = () => setOpen(false);
  const handleClosebtn = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={BoxWrapperstyle}>
          <Typography component="p">{message}</Typography>

          {message ? (
            <Button variant="contained" className="okButton" onClick={handleClosebtn}>
              ok
            </Button>
          ) : (
            <Button
              type="button"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                onSubmit();
              }}
            >
              Reset verify link from this mail
            </Button>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}

export default memo(MessagePopUp);
