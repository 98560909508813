import { useSelector } from "react-redux";
import { Box, Container, Grid, Card, styled, useTheme } from "@mui/material";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  display: "flex",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  padding: "30px 0",
  "& .BoxWrapper": {},
}));

function GoogleTrendsDashboard() {
  const theme = useTheme();
  const customizer = useSelector(state => state.customizer);

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} md={12}>
              <Card
                sx={{ padding: { xs: "8px 15px 20px 15px", sm: "30px 45px 24px 40px" }, border: `1px solid ${theme.palette.divider}` }}
                elevation={customizer.isCardShadow ? 9 : 0}
                variant={!customizer.isCardShadow ? "outlined" : undefined}
              >
                <Box className="BoxWrapper"></Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}
export default GoogleTrendsDashboard;
