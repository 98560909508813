/* eslint-disable */
import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Button, CardHeader, Container, Grid, Typography, CircularProgress, Alert, Card, Tooltip } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import LaunchIcon from "@mui/icons-material/Launch";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import { Link, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
// import AddIcon from "@mui/icons-material/Add";
import { useApolloClient, useSubscription } from "@apollo/client";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { CreateScrapyReport, GetCrawlProgessData, GetScrapyData, PageSpeedCreate } from "../../hooks/scrapyData";
import { GetScore, convertUTCDateToLocalDate, getDomain } from "../../utils/myUtills";
import { usePopup } from "../../context/commonPop";
import Chart from "../Chart";
import CircularStatic from "../CircularProgressBar";
import WelcomeCard from "../dashboards/ecommerce/WelcomeCard";
import TopCards from "../dashboards/modern/TopCards";
import { popupType } from "../../common/constant";
import CreateProjectLink from "../createProject/CreateProjectLink";
import MainLoader from "../MainLoader/MainLoader";
import { SCREAPYSESSIONDATA_SUBSCRIPTION } from "../../gql/subscriptions/scrapy";
import { SINGLE_SCREAPYSESSION_DATA } from "../../gql/queries/scrapy";
import { reportCount } from "../../gql/queries/projectDetail";
import { SCRAPY_DATA } from "../../gql/queries/userWebSiteList";
import { updateCrawlLimit } from "../../redux/action";

const ReportList = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  border: `1px solid ${theme.palette.common.secondory}`,
  padding: "10px 15px 10px",
  borderRadius: "6px",
  marginBottom: "15px",
  "@media screen and (max-width:600px)": {
    width: "450px",
    overflowX: "auto",
  },
  "& .DownloadIcon": {
    fill: theme.palette.text.primary,
  },
  "&:last-child": { marginBottom: "0" },
  "& p": {
    fontSize: "15px",
    // color: "#8d8d8d",
    color: theme.palette.text.primary,
    "&.report-type-wrapper": {
      display: "flex",
      alignItems: "center",
    },

    "& .report": {
      fontSize: "15px",
      color: theme.palette.text.primary,
      marginLeft: "6px",
    },
    "& .date": {
      fontSize: "12px",
      color: theme.palette.text.primary,
      fontWeight: "700",
      marginLeft: "6px",
    },
    "& .NewIcon": { color: theme.palette.primary.main },
  },
  "& .viewReport": {
    fontSize: "15px",
    color: theme.palette.text.primary,
    textDecoration: "none",
    cursor: "pointer",
    "& svg": {
      fontSize: "15px",
      marginLeft: "6px",
      marginTop: "-1px",
    },
  },
  "& .CSVDownloadIcon": { cursor: "pointer", mr: "10px", fontSize: "22px", color: "#ff642d" },
  "& .failedReport": {
    backgroundColor: "#FA896B40",
    marginLeft: "10px",
    padding: "4px 10px 4px 8px",
    color: "#FA896B",
    fontWeight: 600,
    display: "inline-flex",
    alignItems: "center",
    gap: "5px",
    borderRadius: "5px",
  },
  "& .complateReport": {
    backgroundColor: "#00800040",
    marginLeft: "10px",
    padding: "4px 10px 4px 8px",
    color: "#13af0b",
    fontWeight: 600,
    display: "inline-flex",
    alignItems: "center",
    gap: "5px",
    borderRadius: "5px",
  },
}));
const RecentListWrapper = {
  paddingTop: "15px",
  "& h4": {
    // color: "#6a6a6a",
    fontSize: "15px",
    fontWeight: 500,
    marginBottom: "15px",
  },
};

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
}));

const CreatProjectWrapper = styled(Box)(({ theme }) => ({
  "@media screen and (max-width:550px)": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginTop: "15px",
  },
  "& button": {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",

    "& a": {
      color: theme.palette.common.textColor,

      "&:hover": { textDecoration: "none" },
    },
    "&:hover": {
      backgroundColor: theme.palette.common.hoverBtn,

      color: theme.palette.common.white,

      "& a": {
        color: theme.palette.common.white,
      },
    },

    "@media screen and (max-width:550px)": { flex: "1" },
  },
  "& .OverviewBtn": {
    "@media screen and (min-width:550px)": { marginRight: "15px" },
  },
  "& .reportBtn": {
    width: "130px",
    "& span": {
      height: "25px !important",
      width: "25px !important",
      display: "flex",
    },
    "& svg": {
      color: "#fff",
      height: "25px",
      width: "25px",
    },
  },
  "& .Mui-disabled": {
    backgroundColor: theme.palette.common.orange,
    color: `${theme.palette.common.textColor} !important`,
    opacity: "0.5",
  },
}));

const VerifyButtonWrapper = styled(Box)(({ theme }) => ({
  marginLeft: "10px",
  padding: "6px 8px",
  backgroundColor: theme.palette.common.orange,
  color: `${theme.palette.common.textColor} `,
  borderRadius: "5px",
  border: "1px solid #ff642d !important",
  transition: "0.3s all",
  cursor: "pointer",
  "& a": {
    color: theme.palette.common.textColor,
    borderRadius: "5px",
    fontFamily: "Plus Jakarta Sans', sans-serif !important",
    fontSize: "0.875rem",
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    "&:hover": { textDecoration: "none" },
  },
  "&:hover": {
    backgroundColor: theme.palette.common.hoverBtn,
    transition: "0.3s all",

    color: theme.palette.common.white,

    "& a": {
      color: theme.palette.common.white,
    },
  },
}));
const BoxWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "7px",
  marginBottom: "20px",
  "& .settingIcon": {
    marginRight: "20px",
    cursor: "pointer",
    color: theme.palette.text.secondary,
    "@media screen and (max-width:550px)": { marginRight: "0" },
  },
  "& h4": {
    color: theme.palette.text.primary,
  },
  "& h3": {
    color: theme.palette.text.primary,
  },
  "& .projectnamewrapper": {
    padding: "0",
    flexGrow: 1,
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.common.secondory}`,
    paddingBottom: "15px",
    "@media screen and (max-width:600px)": {
      overflowX: "auto",
      "&::-webkit-scrollbar": { display: "none" },
    },
    "& .MuiAvatar-root": {
      backgroundColor: theme.palette.text.secondary,
      color: theme.palette.common.textColor,
    },
    "& .MuiCardHeader-action": {
      marginTop: "0",
      marginright: "0",
      marginBottom: "0",
      alignSelf: "center",
      flex: "1",
      "& button": {
        textTransform: "none",
        borderRadius: "5px",
        border: "none",
      },
    },
    "& .MuiCardHeader-content": {
      "& a": {
        color: "#f4b49e",
        textDecoration: "none",
        "& p": { fontSize: "14px" },
      },
    },
  },
}));

const ProjectNotFoundWrapper = styled(Box)(() => ({
  "& .NotFoundWrapper": { paddingTop: "15px", paddingBottom: "15px", "& h4": { textAlign: "center", fontSize: "18px", fontWeight: "800" } },
  "& .LoaderWrapper": { textAlign: "center  " },
}));

const isReportRecent = createdAt => {
  const reportTimestamp = new Date(createdAt).getTime();
  const sixHoursAgo = Date.now() - 6 * 60 * 60 * 1000;
  return reportTimestamp >= sixHoursAgo;
};

function Dashboard() {
  const { scrapyData, loading, isVerified } = GetScrapyData();
  const [subData, setSubData] = useState("");
  const client = useApolloClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.reducer);

  // subscription call when scrapy report is completed
  const { data } = useSubscription(SCREAPYSESSIONDATA_SUBSCRIPTION);
  useEffect(() => {
    if (data) {
      setSubData(data);
    }
  }, [data]);

  useMemo(() => {
    if (subData) {
      const { screapyComplete } = subData || {};
      const { projectId, sessionId, usedCrawl } = screapyComplete || {};
      if (subData && screapyComplete && sessionId) {
        const { imageUrl, project, website, userData: DataUser, srapyData, ...screapyCacheData } = screapyComplete || {};
        client.writeQuery({
          query: SINGLE_SCREAPYSESSION_DATA,
          variables: { sessionId },
          data: { GetSingleScreapySessionData: { ...screapyCacheData } },
        });
        const cacheData = client.readQuery({
          query: reportCount,
          variables: { reportCountId: userData?.id },
        });
        const newCount = Number(cacheData?.reportCount) + 1;
        client.writeQuery({
          query: reportCount,
          variables: { reportCountId: userData?.id },
          data: { reportCount: newCount },
        });
        if (usedCrawl) {
          dispatch(updateCrawlLimit({ usedCrawl }));
        }
      }
      if (subData && screapyComplete && projectId) {
        const { author, authorId, id, updatedAt, ...cacheDataUpdate } = screapyComplete || {};

        const cacheData = client.readQuery({
          query: SCRAPY_DATA,
          variables: { params: userData.id },
        });
        const updatedUserWebVisitList = cacheData.userWebVisitList.map(item =>
          item.id === projectId ? { ...item, isCrawlProgress: false, srapySessionData: [cacheDataUpdate, ...item.srapySessionData] } : item,
        );
        client.writeQuery({
          query: SCRAPY_DATA,
          variables: { params: userData.id },
          data: { userWebVisitList: updatedUserWebVisitList },
        });
      }
    }
  }, [subData]);

  useEffect(() => {
    if (isVerified && isVerified) {
      navigate("/first-project-config");
    }
  }, [isVerified]);

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} lg={12}>
              <WelcomeCard />
            </Grid>
            <Grid item xs={12} lg={12} id="report" sx={{ pt: "0 !important" }}>
              <TopCards />
            </Grid>
            <Grid item xs={12} lg={12} id="report">
              <CreateProjectLink />
            </Grid>
            <Grid item xs={12} md={12} id="projectSection">
              <ProjectNotFoundWrapper>
                {!loading ? (
                  scrapyData?.length > 0 ? (
                    (scrapyData || []).map(item => <MemorizedProjectDetail item={item} key={Math.random().toString(36).substr(2, 9)} />)
                  ) : (
                    <Box className="NotFoundWrapper">
                      <Typography component="h4">Projects Not Found</Typography>
                    </Box>
                  )
                ) : (
                  <MainLoader message="Loading" SX={{ height: "24vh !important" }} />
                )}
              </ProjectNotFoundWrapper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default Dashboard;

function MyCustomComponent() {
  return (
    <>
      {/* <Typography component="h2">Session Id:</Typography> */}
      {/* <Typography component="h3">{sessionId}</Typography> */}
      <Typography component="p">Data collection is in progress. The results will be displayed on the dashboard soon.</Typography>
    </>
  );
}

function ProjectDetail({ item }) {
  const [loader, setLoader] = useState(false);
  const { customPopupModel } = usePopup();
  const { CreatePageSpeed } = PageSpeedCreate();
  const { createScrapy } = CreateScrapyReport();
  const { srapySessionData, id, projectName, website, isVerified, isCrawlProgress, crawlingProgressReport } = item || {};
  const { cancelCreateReport, isLoading, cancelButtonDisplayed } = GetCrawlProgessData(crawlingProgressReport);
  const customizer = useSelector(state => state.customizer);
  const theme = useTheme();
  const borderColor = theme.palette.divider;
  const navigate = useNavigate();
  const handleCreateReport = async () => {
    setLoader(true);

    try {
      const scrapydata = await createScrapy(item);

      if (scrapydata && scrapydata.data && scrapydata.data.ScrapUrl && scrapydata.data.ScrapUrl.sessionId) {
        const { sessionId, authorId } = scrapydata.data.ScrapUrl;
        setLoader(false);

        customPopupModel({
          type: popupType.success,
          contentFunc: <MyCustomComponent sessionId={sessionId} />,
        });
        // handleOpen(true, `Scraping with session ID ${sessionId} is in progress. Results will be on the dashboard shortly.`);
        await CreatePageSpeed(authorId, id, website, sessionId);
      } else if (scrapydata && scrapydata.errors) {
        setLoader(false);
        customPopupModel({
          type: popupType.failed,
          contentFunc: <Typography component="p">{scrapydata.errors}</Typography>,
        });
        // handleOpen(true, scrapydata.errors);
      } else {
        setLoader(false);
        customPopupModel({
          type: popupType.failed,
          contentFunc: <Typography component="p">Your crawl limit is reached.</Typography>,
        });
        // handleOpen(true, "Your crawl limit is reached.");
      }
    } catch (error) {
      console.error("Error during scraping:", error);
      setLoader(false);
      customPopupModel({
        type: popupType.failed,
        contentFunc: <Typography component="p">An error occurred during scraping.</Typography>,
      });
      // handleOpen(true, "An error occurred during scraping.");
    }
  };

  // useEffect(() => {
  //   if (id) {
  //     screapylatestReport({
  //       variables: {
  //         projectId: id,
  //       },
  //       onCompleted: ({ screapyLatestReport }) => {
  //         const { sessionId } = screapyLatestReport || {};
  //         setProgressId(sessionId);
  //       },
  //       onError: err => {
  //         console.log("err", err);
  //       },
  //     });
  //   }
  // }, [id, isCrawlProgress]);

  const isShowChart = useMemo(() => {
    if (!Array.isArray(srapySessionData)) return false;
    if (srapySessionData.length < 1) return false;
    if (srapySessionData.length === 1 && srapySessionData[0].isFailed) return false;
    if (srapySessionData.length === srapySessionData.filter(report => report.isFailed === true).length) return false;
    return true;
  }, [srapySessionData]);
  return (
    <BoxWrapper key={id}>
      <Card
        sx={{ padding: { xs: "30px 20px 24px 20px", sm: "30px 45px 24px 40px" }, border: customizer.isCardShadow ? `1px solid ${borderColor}` : "none" }}
        elevation={customizer.isCardShadow ? 9 : 0}
        variant={!customizer.isCardShadow ? "outlined" : undefined}
      >
        <CardHeader
          className="projectnamewrapper"
          avatar={<Avatar>{projectName ? projectName.charAt(0) : "P"}</Avatar>}
          action={
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: { xs: "center", sm: "flex-end" } }}>
              {/* <Link to={`/websitesetting/${id}`}> */}
              <SettingsIcon
                className="settingIcon"
                sx={{}}
                onClick={() => {
                  navigate(`/websitesetting/${id}?origin=${projectName}`);
                }}
              />
              {/* </Link> */}
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <CreatProjectWrapper>
                  <Button
                    onClick={() => {
                      navigate(`/projects/${id}/${srapySessionData[0].sessionId}`);
                    }}
                    className="OverviewBtn"
                    disabled={!srapySessionData[0]?.sessionId}
                  >
                    Overview
                  </Button>
                  {/* {isVerified ? (
                    <Button className="reportBtn" onClick={handleCreateReport} disabled={loader || isCrawlProgress}>
                      {loader ? <CircularProgress width={30} /> : isVerified ? "Create Report" : "Not verified"}
                    </Button>
                  ) : (
                    <Button
                      className="reportBtn"
                      onClick={() => {
                        navigate(`/verify/${id}`);
                      }}
                    >
                      Verify
                    </Button>
                  )} */}
                  <Button
                    className="reportBtn"
                    onClick={() => {
                      if (isVerified) {
                        if (isCrawlProgress) {
                          cancelCreateReport();
                        } else {
                          handleCreateReport();
                        }
                      } else {
                        navigate(`/verify/${id}`);
                      }
                    }}
                    disabled={loader || isLoading}
                  >
                    {loader || isLoading ? <CircularProgress width={30} /> : isVerified ? isCrawlProgress ? "Cancel Report" : "Create Report" : "Not Verify"}
                  </Button>
                </CreatProjectWrapper>
              </Box>
            </Box>
          }
          title={<Typography component="h3">{projectName} </Typography>}
          subheader={
            <a href={item.website} target="_blank">
              <Typography component="p">{getDomain(website)}</Typography>
            </a>
          }
        />
        <Box sx={RecentListWrapper}>
          {isCrawlProgress && (
            <Alert
              severity="error"
              onClick={() => {
                if (!cancelButtonDisplayed && crawlingProgressReport) {
                  navigate(`/crawl-progress/${crawlingProgressReport}`, { state: { projectName } });
                }
              }}
            >
              Your report is in progress, click to check progress... <LaunchIcon fontSize="16px" />
            </Alert>
          )}
          {isShowChart ? (
            <MemorizedHealthScore srapySessionData={srapySessionData} isCrawlProgress={isCrawlProgress} id={id} />
          ) : (
            !isCrawlProgress && (
              <p>Kindly create or revise the report to incorporate the health score and corresponding chart, as the current submission did not meet the required standards.</p>
            )
          )}
          {srapySessionData?.length > 0 && <Typography component="h4">Recent Report</Typography>}
          <Box sx={{ "@media only screen and (max-width:600px)": { overflowX: "auto", "&::-webkit-scrollbar": { display: "none" } } }}>
            {" "}
            {srapySessionData?.length > 0
              ? srapySessionData?.length > 3
                ? (srapySessionData.slice(0, 3) || []).map((items, ind) => (
                    <MemorizedReports projectName={projectName} items={items} ind={ind} id={id} key={Math.random().toString(36).substr(2, 9)} />
                  ))
                : (srapySessionData || []).map((items, ind) => (
                    <MemorizedReports projectName={projectName} items={items} ind={ind} id={id} key={Math.random().toString(36).substr(2, 9)} />
                  ))
              : null}
          </Box>
        </Box>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <CreatProjectWrapper>
            <Button
              onClick={() => {
                navigate(`/projects/${id}/${srapySessionData[0].sessionId}`);
              }}
              className="OverviewBtn"
            >
              Overview
            </Button>
            <Button className="reportBtn" onClick={handleCreateReport} disabled={loader || !isVerified || isCrawlProgress}>
              {loader ? <CircularProgress width={30} /> : isVerified ? "Create Report" : "Not verified"}
            </Button>
          </CreatProjectWrapper>
          {isVerified ? null : (
            <VerifyButtonWrapper>
              <Link to={`/verify/${id}`} style={{ textDecoration: "none" }}>
                Verify
              </Link>
            </VerifyButtonWrapper>
          )}
        </Box>
      </Card>
    </BoxWrapper>
  );
}

function HealthScore({ srapySessionData, id }) {
  const theme = useTheme();
  const getWapper = ({ score }) =>
    score >= 90 ? "Excellent" : score >= 75 && score < 90 ? "Very Good" : score >= 60 && score < 75 ? "Good" : score >= 50 && score < 60 ? "Fair" : "Poor";

  const healthScore = Array.isArray(srapySessionData) && srapySessionData.length ? GetScore(srapySessionData) : 75;
  const HealthScoreWrapper = {
    width: {
      xs: "100%",
      sm: "30%",
    },
    "& .redScore": {
      width: "max-content",
      margin: "auto",
      display: "block",
      backgroundColor: "rgb(234, 82, 29)",
      color: "#fff",
      padding: "2px 10px 3px",
      borderRadius: "6px",
      marginBottom: "5px",
      marginTop: "-30px",
      fontWeight: "600 ",
    },
    "& .yellowScore": {
      width: "max-content",
      margin: "auto",
      display: "block",
      backgroundColor: "rgb(255, 215, 0)",
      color: "#000",
      padding: "2px 10px 3px",
      borderRadius: "6px",
      marginBottom: "5px",
      marginTop: "-30px",
      fontWeight: 600,
    },
    "& .greenScore": {
      width: "max-content",
      margin: "auto",
      display: "block",
      backgroundColor: "green",
      color: "#fff",
      padding: "2px 10px 3px",
      borderRadius: "6px",
      marginBottom: "5px",
      fontWeight: 600,

      marginTop: "-30px",
    },
    "& .noneScore": {
      width: "max-content",
      margin: "auto",
      display: "block",
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      padding: "2px 10px 3px",
      borderRadius: "6px",
      marginBottom: "5px",
      marginTop: "-30px",
      fontWeight: 600,
    },
  };
  const RecentReportWrapper = {
    display: {
      xs: "block",
      sm: "flex",
    },
    justifyContent: "center",
    pt: "20px",
    pb: "20px",
    "&.RecentReportBlur": {
      filter: "blur(2px)",
      PointerEvent: "none",
    },
  };
  return (
    <>
      {/* {isCrawlProgress && <Alert severity="error">Last Report is in progress..</Alert>} */}
      {srapySessionData && (
        <Box sx={RecentReportWrapper} className={srapySessionData?.length <= 1 && !srapySessionData[0].isCompleted ? "RecentReportBlur" : ""}>
          <Box sx={HealthScoreWrapper}>
            <CircularStatic score={healthScore} />
            <Typography
              component="span"
              className={healthScore <= 40 ? "redScore" : healthScore > 40 && healthScore <= 75 ? "yellowScore" : healthScore > 75 ? "greenScore" : "noneScore"}
            >
              <Typography
                component="span"
                sx={{
                  fontSize: "15px",
                  textAlign: "center",
                }}
              >
                {getWapper({
                  score: healthScore,
                })}
              </Typography>
            </Typography>
            <Typography
              component="h4"
              sx={{
                fontSize: "22px",
                textAlign: "center",
              }}
            >
              Health Score
            </Typography>
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "70%",
              },
            }}
          >
            <Chart projectId={id} srapySessionData={srapySessionData} />
          </Box>
        </Box>
      )}
    </>
  );
}

function Reports({ items, ind, id, projectName }) {
  const navigate = useNavigate();
  const { createdAt, domain, sessionId, basicCompleted, isCompleted, isFailed, reason, isCanceled } = items || {};
  const isRecentReport = isReportRecent(createdAt);
  const isMostRecentReport = ind === 0;
  const show = basicCompleted ? isCompleted : false;
  return (
    <ReportList key={createdAt}>
      <Typography component="p" className="report-type-wrapper">
        {ind + 1}
        <Typography component="span" className="report">
          {domain}
        </Typography>
        <Typography component="span" className="date">
          {convertUTCDateToLocalDate(createdAt)}
        </Typography>
        {isRecentReport && isMostRecentReport && <FiberNewIcon className="NewIcon" />}
        {/* {isFailed && (
          <Tooltip title={reason} placement="top-start">
            <Typography component="span" className="failedReport">
              <ErrorOutlineIcon fontSize="small" />
              Failed
            </Typography>
          </Tooltip>
        )} */}
        {isFailed && !isCanceled ? (
          <Tooltip title={reason} placement="top-start">
            <Typography component="span" className="failedReport">
              <ErrorOutlineIcon fontSize="small" />
              Failed
            </Typography>
          </Tooltip>
        ) : isCompleted && !isCanceled ? (
          <Tooltip title={reason} placement="top-start">
            <Typography component="span" className="complateReport">
              <CheckCircleOutlineIcon fontSize="small" />
              Completed
            </Typography>
          </Tooltip>
        ) : isCanceled ? (
          <Tooltip title={reason} placement="top-start">
            <Typography component="span" className="failedReport">
              <WarningAmberIcon fontSize="small" />
              Canceled
            </Typography>
          </Tooltip>
        ) : null}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          "& button": {
            padding: "3px 8px",
          },
        }}
      >
        <Button
          onClick={() => {
            if (sessionId) {
              navigate(`/crawl-progress/${sessionId}`, { state: { projectName } });
            }
          }}
        >
          Crawl Report
        </Button>
        <Typography
          component="span"
          className="viewReport"
          onClick={() => {
            if (sessionId) {
              navigate(`/project-detail/${sessionId}?show=${show}&origin=${id}`);
            }
          }}
        >
          View Report
          <LaunchIcon />
        </Typography>
      </Box>
    </ReportList>
  );
}

const MemorizedReports = memo(Reports);
const MemorizedProjectDetail = memo(ProjectDetail);
const MemorizedHealthScore = memo(HealthScore);
