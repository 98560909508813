/* eslint-disable */
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import { Box, Button, Container, FormControl, FormControlLabel, Grid, Radio, RadioGroup, styled, TextField, Typography, useTheme } from "@mui/material";
import darkLogoImage from "../../Images/hotSpotLogoBlue.svg";
import { UpdateUser } from "../../gql/mutations/auth";
import useQuery from "../../hooks/allQuery";
import { usePopup } from "../../context/commonPop";
import ImportProject from "../ProjectList/ImportProject";
import StartFreePlan from "../ProjectList/StartFreePlan";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import SiteAudit from "../ProjectList/SiteAudit";
import { GetScrapyData } from "../../hooks/scrapyData";
// import { GetUserWebsiteData } from "../../hooks/userWebsiteData";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "100vh",
  backgroundColor: "#EFF3FB",
  paddingTop: "30px",
  paddingBottom: "30px",
  "& .LogoWrapper": {
    textAlign: "center",
    "& img": { width: "105px", height: "auto" },
  },
  "& .AccountDetailsWrapper": {
    backgroundColor: "#ffffff",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "6px",
    padding: "28px 20px 20px",
    "& .MainHeading": { color: "#2A3547", textAlign: "center", lineHeight: 1, fontSize: "24px", fontWeight: 700, marginBottom: "10px" },
    "& .SubHeading": {
      color: "#757575",
      textAlign: "center",
      lineHeight: 1,
      fontSize: "14px",
      fontWeight: 500,
    },
    "& .ChooseListWrapper": {
      marginTop: "25px",
      "& .MuiFormControl-root": {
        width: "100%",
        "& .MuiFormGroup-root": {
          "& label": {
            marginLeft: "0",
            marginBottom: "10px",
            marginRight: "0",
            width: "100%",
            justifyContent: "space-between",
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: "6px",
            padding: "14px 14px 14px 14px",
            "& span.MuiRadio-root": {
              padding: "0px",
            },
            "& span.MuiFormControlLabel-label": {
              color: "#BEBEBE",
              textAlign: "center",
              lineHeight: 1,
              fontSize: "16px",
              fontWeight: 600,
            },
            "&.active": {
              borderColor: "#45C0CB",
              backgroundColor: "#45c0cb0f",
              "& span.MuiFormControlLabel-label": {
                color: "#2A3547",
              },
              "& span.MuiRadio-root": {
                "&.Mui-checked": { color: "#45C0CB" },
              },
            },
          },
        },
        "& .MuiFormControl-root": {
          marginTop: "0",
          marginBottom: "0",
          "& .MuiInputBase-root": {
            "& fieldset": { display: "none" },
            "& input": { border: `1px solid #45C0CB`, borderRadius: "6px", padding: "14px 14px 14px 14px" },
          },
        },
      },
    },
    "& .BtnWrapper": {
      marginTop: "15px",
      "& button": {
        textTransform: "none",
        backgroundColor: "#45C0CB",
        color: "#ffffff",
        borderRadius: "5px",
        boxShadow: "none",
        padding: "12px 22px 10px",
        width: "100%",
        "&:focus": {
          outline: "0",
        },
      },
    },
  },
}));
const initialState = [1, 2, 3, 4];
function SignUpUserProjectResister() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { allQuery } = useQuery();
  const { handleOpen } = usePopup();
  const ownerId = searchParams.get("origin");
  const [selectedValue, setSelectedValue] = useState("google");
  const [taxtFieldValue, setTaxtFieldValue] = useState(null);
  const [manageComponent, setManageComponent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [UPGRADEUSER] = useMutation(UpdateUser);
  const { userData } = useSelector(state => state.reducer);
   const { scrapyData } = GetScrapyData();
  // const { ProjectData } = GetUserWebsiteData(ownerId);

  const handleRadioChange = event => {
    setSelectedValue(event.target.value);
  };

  const handleSaveClick = async () => {
    // e.preventDefault();
    setLoading(true);
    try {
      // const check = await checkError1(formData);
      // if (check) {
      //   setLoading(false);

      //   return;
      // }
      allQuery({
        QUERY: UPGRADEUSER,
        variables: {
          data: {
            ReceivedDetailsFrom: selectedValue === "others" ? taxtFieldValue : selectedValue,
          },
        },

        onCompleteFunc: dataDis => {
          // dispatch(updateUser(dataDis.updateUser));
          setLoading(false);
          setManageComponent(2);
        },
        onErrorFunc: errdata => {
          handleOpen(true, errdata.message);
          setLoading(false);
        },
      });
    } catch {
      console.log(" Not update");
      setLoading(false);
    }
  };

    useEffect(() => {
      if (scrapyData && Array.isArray(scrapyData)) {
        const varifiedProjects = scrapyData.filter(project => project?.isVerified === false);
        if (varifiedProjects.length > 0) {
          const {id } = varifiedProjects[0];
          navigate(`/first-project-config/${id}`);
        }
      }
    }, [scrapyData]);

  return (
    <SectionWrapper>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Box className="LogoWrapper">
                <Typography component="img" src={darkLogoImage} alt="Logo" />
              </Box>
            </Grid>
          </Grid>
          {manageComponent === 1 && (
            <Grid container spacing={2} mt={0} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Box className="AccountDetailsWrapper">
                  <Typography component="h2" className="MainHeading">
                    Complete Your Account Details
                  </Typography>
                  <Typography component="p" className="SubHeading">
                    Where did you learn about Hotspot SEO
                  </Typography>
                  <Box className="ChooseListWrapper">
                    <FormControl>
                      <RadioGroup value={selectedValue} name="radio-buttons-group" onChange={handleRadioChange}>
                        <FormControlLabel value="google" control={<Radio />} label="Google" labelPlacement="start" className={selectedValue === "google" ? "active" : ""} />
                        <FormControlLabel value="youTube" control={<Radio />} label="YouTube" labelPlacement="start" className={selectedValue === "youTube" ? "active" : ""} />
                        <FormControlLabel value="facebook" control={<Radio />} label="Facebook" labelPlacement="start" className={selectedValue === "facebook" ? "active" : ""} />
                        <FormControlLabel
                          value="pinterest"
                          control={<Radio />}
                          label="Pinterest"
                          labelPlacement="start"
                          className={selectedValue === "pinterest" ? "active" : ""}
                        />
                        <FormControlLabel value="others" control={<Radio />} label="Others" labelPlacement="start" className={selectedValue === "others" ? "active" : ""} />
                      </RadioGroup>
                      {selectedValue === "others" && (
                        <TextField variant="outlined" margin="normal" placeholder="Others" onChange={e => setTaxtFieldValue(e.target.value)} fullWidth autoFocus />
                      )}
                    </FormControl>
                  </Box>
                  <Box className="BtnWrapper">
                    <Button onClick={handleSaveClick}>Continue</Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
          {manageComponent === 2 && (
            <Grid container spacing={2} mt={0} justifyContent="center">
              <Grid item xs={12} md={12}>
                <ImportProject />
              </Grid>
              <Grid item xs={12} md={12}>
                <StartFreePlan />
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default SignUpUserProjectResister;
