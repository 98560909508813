import { Box, Card, Grid, styled, Typography, useTheme } from "@mui/material";
import AveragePerformancecChartBoxs from "./AveragePerformancecChartBoxs";

const BoxWrapper = styled(Box)(({ theme }) => ({
  "& .AverageMainWrapper": {
    marginBottom: "40px",
    "& h4": {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "30px",
      color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
      marginBottom: "6px",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "17px",
      color: theme.palette.mode === "dark" ? "#B3B3B3" : "#B3B3B3",
      "& span": {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "17px",
        color: theme.palette.primary.main,
      },
    },
  },
}));

function AveragePerformance({ projectId, lightData }) {
  const theme = useTheme();
  return (
    <BoxWrapper>
      <Card sx={{ padding: "16px 20px 20px 20px", border: `1px solid ${theme.palette.divider}`, boxShadow: "none !important" }}>
        <Grid container spacing={0} mt={0} sx={{ justifyContent: "center" }}>
          <Grid item xs={12} md={12}>
            <Box className="AverageMainWrapper">
              <Typography component="h4">Average Performance</Typography>
              <Typography component="p">
                Values are estimated and may vary. The <Typography component="span">performance score is calculated</Typography> directly from these metrics.{" "}
                <Typography component="span">See calculator.</Typography>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ borderRight: { lg: `1px solid ${theme.palette.divider}` }, mb: { xs: "30px", lg: "0" } }}>
            <AveragePerformancecChartBoxs
              label="Desktop Performance"
              projectId={projectId}
              lightData={lightData?.DesktopAvg}
              Performance={lightData?.allUrlData?.DesktopPerformance}
              Accessibility={lightData?.allUrlData?.DesktopAccessibility}
              BestPractices={lightData?.allUrlData?.DesktopBestPractices}
              Seo={lightData?.allUrlData?.DesktopSeo}
              urlCount={lightData?.allUrlData?.Allcount}
              tabName="&tab=Desktop"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <AveragePerformancecChartBoxs
              label="Mobile Performance"
              projectId={projectId}
              lightData={lightData?.MobileAvg}
              Performance={lightData?.allUrlData?.MobilePerformance}
              Accessibility={lightData?.allUrlData?.MobileAccessibility}
              BestPractices={lightData?.allUrlData?.MobileBestPractices}
              Seo={lightData?.allUrlData?.MobileSeo}
              urlCount={lightData?.allUrlData?.Allcount}
              tabName="&tab=Mobile"
            />
          </Grid>
        </Grid>
      </Card>
    </BoxWrapper>
  );
}
export default AveragePerformance;
