import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { calculateDuration } from "../../utils/myUtills";

function DurationCalculator({ createdAt, isCompleted, reportEndTime }) {
  const [duration, setDuration] = useState(calculateDuration(createdAt, isCompleted, reportEndTime));
  useEffect(() => {
    let intervalId;
    if (!isCompleted) {
      intervalId = setInterval(() => {
        setDuration(calculateDuration(createdAt, isCompleted, reportEndTime));
      }, 1000);
    }
    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [createdAt, isCompleted]);

  return (
    <Box sx={{ width: { xs: "auto", sm: duration ? "125px" : 207 } }}>
      <Typography component="h3">Duration</Typography>
      <Typography component="p"> {duration}</Typography>
    </Box>
  );
}

export default DurationCalculator;
