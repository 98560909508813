/* eslint-disable */
import { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Tabs, Button, Card, useTheme, CircularProgress, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import WelcomeCard from "../dashboards/ecommerce/WelcomeCard";
import { LIGHT_HOUSE } from "../../gql/queries/scrapy";
import { ligtHouseSave } from "../../gql/mutations/scrapy";
import useQuery from "../../hooks/allQuery";
import { GetScrapyData } from "../../hooks/scrapyData";
import { usePopup } from "../../context/commonPop";
import { useSelector } from "react-redux";
import BackButton from "../BackButton/BackButton";
import MainLoader from "../MainLoader/MainLoader";
import DesktopIcon from "../SvgIcons/DesktopIcon";
import MobileIcon from "../SvgIcons/MobileIcon";
import AveragePerformance from "./chartUiComponent/AveragePerformance";
import OtherPerformance from "./chartUiComponent/OtherPerformance";
import BlurPage from "./BlurPage";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .MainHeadWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h3": { fontSize: "22px", fontWeight: 600, lineHeight: "35.28px", color: theme.palette.mode === "dark" ? "#ffffff" : "#000000" },
  },
  "& .DesktopMobileBoxWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "6px",
    gap: "15px",
    padding: "20px 20px 20px",
    "& .IconWrapper": {
      width: "60px",
      height: "60px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.primary.main,
      "& svg": {
        height: "30px",
        width: "auto",
      },
    },
    "& h4": {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "30px",
      color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
    },
  },
}));

const OtherPerformanceArry = [
  {
    label: "Performance",
    description: "",
    desktopData: null,
    mobileData: null,
    heading: "Performance",
  },
  {
    label: "Accessibility",
    description:
      "These checks highlight opportunities to <span>improve the accessibility of your web app</span>. Automatic detection can only detect a subset of issues<br /> and does not guarantee the accessibility of your web app, so <span>manual testing</span> is also encouraged.",
    desktopData: null,
    mobileData: null,
    heading: "Accessibility",
  },
  {
    label: "Best Practices",
    description: "",
    desktopData: null,
    mobileData: null,
    heading: "BestPractices",
  },
  {
    label: "SEO",
    description:
      "These checks ensure that your page is following basic search engine optimisation advice. There are many additional factors that Lighthouse does<br /> not score here that may affect your search ranking, including performance on <span>Core Web Vitals. Learn more about Google Search essentials</span>.",
    desktopData: null,
    mobileData: null,
    heading: "Seo",
  },
];

function PerformanceChartboard() {
  const isMax1025Breakpoints = useMediaQuery("(max-width: 1025px)");
  const [lightData, setLightData] = useState([]);
  const [donutData, setDonutData] = useState(OtherPerformanceArry);
  const [value, setValue] = useSearchParams({ tab: "desktop" });
  const [searchParams, setSearchParams] = useSearchParams();
  const { scrapyData } = GetScrapyData();
  const { allQuery } = useQuery();
  const [LIGHT_HOUSE_SAVE] = useMutation(ligtHouseSave);
  const [lightHouse] = useLazyQuery(LIGHT_HOUSE);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleOpen } = usePopup();
  const customizer = useSelector(state => state.customizer);
  const userData = useSelector(state => state.reducer.userData);
  const theme = useTheme();
  const sessionId = searchParams.get("sessionId");
  const projectId = searchParams.get("projectId");

  // const handleChange = (event, newValue) => {
  //   setValue({ ...Object.fromEntries([...value]), tab: newValue });
  // };

  const getLighthouseReport = () => {
    if (projectId) {
      setLoader(true);
      lightHouse({
        variables: {
          projectId,
        },
        onCompleted: ({ AverageReport }) => {
          if (AverageReport && AverageReport?.allUrlData) {
            const mapedData = OtherPerformanceArry.map(item => {
              switch (item?.label) {
                case "Performance":
                  item["desktopData"] = AverageReport?.allUrlData?.DesktopPerformance;
                  item["mobileData"] = AverageReport?.allUrlData?.MobilePerformance;
                  break;
                case "Accessibility":
                  item["desktopData"] = AverageReport?.allUrlData?.DesktopAccessibility;
                  item["mobileData"] = AverageReport?.allUrlData?.MobileAccessibility;
                  break;
                case "Best Practices":
                  item["desktopData"] = AverageReport?.allUrlData?.DesktopBestPractices;
                  item["mobileData"] = AverageReport?.allUrlData?.MobileBestPractices;
                  break;
                case "SEO":
                  item["desktopData"] = AverageReport?.allUrlData?.DesktopSeo;
                  item["mobileData"] = AverageReport?.allUrlData?.MobileSeo;
                  break;
                default:
                // code block
              }
              return item;
            });
            setDonutData(mapedData);
          }
          setLightData(AverageReport);
          setLoader(false);
        },
        onError: err => {
          console.log("err", err);
          setLoader(false);
          setLightData([]);
        },
        fetchPolicy: "network-only",
      });
    }
  };
  useEffect(() => {
    getLighthouseReport();
  }, [projectId]);

  const handleCreateReport = async () => {
    if (!sessionId || !projectId || sessionId == "undefined") {
      handleOpen(true, "First Create Screapy Report");
      return;
    }
    if (projectId && sessionId) {
      setLoading(true);
      allQuery({
        QUERY: LIGHT_HOUSE_SAVE,
        variables: { projectId, sessionId },
        onCompleteFunc: data => {
          setLoading(false);
        },
        onErrorFunc: err => {
          setLoading(false);
          handleOpen(true, err.message);
        },
        type: "create",
        cacheQueryData: {
          cacheQuery: LIGHT_HOUSE,
          variablesCache: {
            projectId,
          },
        },
      });
      setTimeout(() => {
        getLighthouseReport();
      }, 5000);
    }
  };

  if (!userData?.subscription?.active) {
    return <BlurPage />;
  }

  return (
    <>
      <SectionWrapper>
        <Container maxWidth="">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
              <Grid item xs={12} lg={12}>
                <WelcomeCard />
              </Grid>
            </Grid>
            {loader ? (
              <MainLoader message="Loading" SX={{ height: "calc(100vh - 290px) !important" }} />
            ) : scrapyData?.length > 0 ? (
              <>
                {lightData.length === 0 &&
                  (loader ? (
                    <MainLoader message="Loading" SX={{ height: "calc(100vh - 290px) !important" }} />
                  ) : (
                    projectId && (
                      <Grid container spacing={4} mt={0} sx={{ justifyContent: "center" }}>
                        <Grid item xs={12} lg={12}>
                          <Card
                            sx={{ padding: "30px 45px 30px 40px", border: `1px solid ${theme.palette.divider}` }}
                            elevation={customizer.isCardShadow ? 9 : 0}
                            variant={!customizer.isCardShadow ? "outlined" : undefined}
                          >
                            <Box className="CreateLighthouseWrapper">
                              <Typography component="p">Lighthouse Report is not created for this project. Click on button to Create report</Typography>
                              <Button onClick={handleCreateReport} disabled={loading || loader}>
                                {loading ? <CircularProgress size={24.5} /> : "Create Lighthouse Report"}
                              </Button>
                            </Box>
                          </Card>
                        </Grid>
                      </Grid>
                    )
                  ))}
                {!(lightData.length === 0) && (
                  <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
                    <Grid item xs={12} lg={12}>
                      <Box className="MainHeadWrapper">
                        <Typography component="h3">Working Process All Device</Typography>
                        <BackButton sx={{ "& button": { marginBottom: "0 !important" } }} />
                      </Box>
                    </Grid>
                    {isMax1025Breakpoints ? (
                      <Grid item xs={12} md={12}>
                        <Box className="DesktopMobileBoxWrapper">
                          {/* <Typography component="span" className="IconWrapper">
                            <DesktopIcon />
                          </Typography> */}
                          <Typography component="h4">Desktop & Mobile Performance</Typography>
                        </Box>
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={12} md={6}>
                          <Box className="DesktopMobileBoxWrapper">
                            <Typography component="span" className="IconWrapper">
                              <DesktopIcon />
                            </Typography>
                            <Typography component="h4">Desktop Performance</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box className="DesktopMobileBoxWrapper">
                            <Typography component="span" className="IconWrapper">
                              <MobileIcon />
                            </Typography>
                            <Typography component="h4">Mobile Performance</Typography>
                          </Box>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} lg={12}>
                      <AveragePerformance projectId={projectId} lightData={lightData} />
                    </Grid>
                    {donutData.map((item, index) => {
                      return (
                        <Grid item xs={12} lg={12} key={index}>
                          <OtherPerformance
                            label={item.label}
                            description={item.description}
                            desktopData={item.desktopData}
                            mobileData={item.mobileData}
                            heading={item?.heading}
                            projectId={projectId}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </>
            ) : (
              <Box className="NotFoundWrapper">
                <Typography component="h4">Projects Not Found</Typography>
              </Box>
            )}
          </Box>
        </Container>
      </SectionWrapper>
    </>
  );
}

export default PerformanceChartboard;
