import { memo, useEffect, useState } from "react";
import { Box, Divider, Fab, TextField, FormLabel, Typography } from "@mui/material";
import { IconCheck } from "@tabler/icons-react";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { updateNote } from "../../../gql/mutations/notes";
import { getAllNotes } from "../../../gql/queries/notes";
import useQuery from "../../../hooks/allQuery";

function NoteContent({ colorvariation, setNoteDetails, noteDetails, notesFilter, allNotes }) {
  const { userData } = useSelector(state => state.reducer);
  const [UPDATE_NOTE] = useMutation(updateNote, { fetchPolicy: "network-only" });
  const { allQuery } = useQuery();
  const [changeValue, setValueChange] = useState("");

  const handleNoteUpdate = (field, value) => {
    setNoteDetails(prevNoteDetails => ({
      ...prevNoteDetails,
      [field]: value,
    }));
    setValueChange(value);
  };

  useEffect(() => {
    // if
    const debouncedUpdateNote = debounce(() => {
      if (changeValue && noteDetails && noteDetails.note && noteDetails.color && noteDetails.id) {
        allQuery({
          QUERY: UPDATE_NOTE,
          variables: {
            data: {
              note: noteDetails?.note,
              color: noteDetails?.color,
            },
            updateNoteId: noteDetails.id,
          },
          type: "update",
          cacheQueryData: {
            cacheQuery: getAllNotes,
            variablesCache: {
              userId: userData.id,
              searchOptions: notesFilter.searchOptions,
            },
          },
        });
      }
    }, 2000);

    debouncedUpdateNote();

    return () => {
      debouncedUpdateNote.cancel();
    };
  }, [changeValue]);

  return (
    <Box sx={{ height: { lg: "calc(100vh - 250px)", sm: "100vh" }, maxHeight: "700px" }}>
      {/* ------------------------------------------- */}
      {/* Header Part */}
      {/* ------------------------------------------- */}

      <Divider />
      {/* ------------------------------------------- */}
      {/* Edit notes */}
      {/* ------------------------------------------- */}
      {allNotes.length > 0 ? (
        noteDetails ? (
          <Box p={3}>
            <FormLabel htmlFor="outlined-multiline-static">
              <Typography variant="h6" mb={2} fontWeight={600} color="text.primary">
                Edit Note
              </Typography>
            </FormLabel>

            <TextField
              id="outlined-multiline-static"
              placeholder="Edit Note"
              multiline
              fullWidth
              rows={5}
              variant="outlined"
              value={noteDetails.note}
              onChange={e => handleNoteUpdate("note", e.target.value)}
            />
            <br />
            <Typography variant="h6" mt={3} mb={2} fontWeight={600}>
              Change Note Color
            </Typography>

            {colorvariation.map(color1 => (
              <Fab
                sx={{
                  marginRight: "3px",
                  boxShadow: "none",
                  transition: "0.1s ease-in",
                  scale: noteDetails.color === color1.disp ? "0.9" : "0.7",
                }}
                size="small"
                key={color1.id}
                color={color1?.disp}
                onClick={() => handleNoteUpdate("color", color1.disp)}
              >
                {noteDetails.color === color1.disp ? <IconCheck width="16" /> : ""}
              </Fab>
            ))}
          </Box>
        ) : (
          <Box sx={{ fontSize: "24px", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>Select a Note</Box>
        )
      ) : (
        <Box sx={{ fontSize: "24px", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>You do not have any note!</Box>
      )}
    </Box>
  );
}

export default memo(NoteContent);
