/* eslint-disable */
import { useEffect, useState } from "react";
import { Avatar, Box, Button, CircularProgress, Divider, Grid, Stack, styled, Typography, useTheme } from "@mui/material";
import generateReport from "../../Images/backgrounds/generateReport.png";

const SectionWrapper = styled(Box)(({ theme }) => ({
  padding: "20px",
  marginTop: "20px",
  border: "1px solid #E3E3E3",
  borderRadius: "6px",
  "& .purchase-plan": {
    backgroundColor: theme.palette.primary.main,
    fontWeight: 700,
    color: "#fff",
    padding: "10px 35px",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "8px",
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
  "& .leftSectionBox": {
    textAlign: { xs: "center", sm: "left" },
    margin: { xs: "12px 0", sm: 0 },
    "& h5": {
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
    "& span": {
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
}));
function GenerateReport({ handleCreateReport, handleResumeReport, handlePauseReport, isActiveReport, reportStatus, genrateReportLoading }) {
  const theme = useTheme();
  const [reportState, setReportState] = useState({
    buttonLabel: "",
    buttonAction: () => () => {},
    statusText: "",
    infoText: "",
    title: "",
  });

  useEffect(() => {
    if (reportStatus) {
      switch (reportStatus) {
        case "Progress":
          setReportState({
            title: "Pause Report",
            buttonLabel: "Pause Report",
            buttonAction: handlePauseReport,
            statusText: "Lighthouse Report is in progress. Click to pause.",
            infoText: "A report is currently being generated. Please wait for it to complete or pause the process if necessary.",
          });
          break;
        case "Paused":
          setReportState({
            title: "Resume Report",
            buttonLabel: "Resume Report",
            buttonAction: handleResumeReport,
            statusText: "Lighthouse Report is paused. Click to resume.",
            infoText: "Your report is paused. You can resume the process by clicking the button.",
          });
          break;
        case "Not Found":
          setReportState({
            title: "Generate Report",
            buttonLabel: "Start Generate",
            buttonAction: handleCreateReport,
            statusText: "Lighthouse Report is not created for this project.",
            infoText: "Click the button to start generating the report.",
          });
          break;
        // case "Already In Progress":
        //   setReportState({
        //     title: "Report Already In Progress",
        //     buttonLabel: "Generate Report",
        //     buttonAction: () => {},
        //     statusText: "A report is already in progress.",
        //     infoText: "You cannot generate a new report until the current one is completed.",
        //     isButtonDisabled: true,
        //   });
        //   break;
        default:
          break;
      }
    }
  }, [reportStatus]);

  // const [buttonLabel, setButtonLabel] = useState("");
  // const [buttonAction, setButtonAction] = useState(() => () => {});

  // useEffect(() => {
  //   if (reportStatus) {
  //     switch (reportStatus) {
  //       case "Progress":
  //         setButtonLabel("Pause Report");
  //         setButtonAction(() => handlePauseReport);
  //         break;
  //       case "Paused":
  //         setButtonLabel("Resume Report");
  //         setButtonAction(() => handleResumeReport);
  //         break;
  //       case "Not Found":
  //         setButtonLabel("Start Generate");
  //         setButtonAction(() => handleCreateReport);
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }, [reportStatus]);

  return (
    <SectionWrapper>
      <Grid container justifyContent="space-between">
        <Grid item sm={8} display="flex" alignItems="center">
          <Box>
            <Box
              gap="16px"
              sx={{
                display: {
                  xs: "block",
                  sm: "flex",
                },
                alignItems: "center",
              }}
            >
              <Avatar src={generateReport} alt="img" sx={{ width: 60, height: 60, border: `1.5px solid ${theme.palette.primary.main}`, mb: { xs: "10px", sm: 0 } }} />
              <Box className="leftSectionBox">
                <Typography variant="h5" sx={{ whiteSpace: { xs: "wrap", sm: "nowrap" }, fontSize: "25px", fontWeight: "600", mb: "12px" }}>
                  {reportState.title}
                </Typography>
                <Typography component="span" sx={{ fontSize: "14px", fontWeight: "500" }}>
                  {reportState.statusText}
                </Typography>
                {isActiveReport && (
                  <Typography variant="body2" sx={{ fontWeight: "400", fontSize: "14px" }}>
                    {reportState.infoText}
                  </Typography>
                )}
              </Box>
            </Box>
            <Stack spacing={2} direction="row" divider={<Divider orientation="vertical" flexItem />}></Stack>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box mt="8px" textAlign="right">
            <Button className="purchase-plan" onClick={reportState?.buttonAction} disabled={genrateReportLoading || (reportStatus === "Not Found" && isActiveReport)}>
              {genrateReportLoading ? <CircularProgress width={30} /> : reportState?.buttonLabel}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
}

export default GenerateReport;
