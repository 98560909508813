import { Box, Button, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

function PurchasePlan() {
  const theme = useTheme();
  const MainHeadWrapper = {
    display: { xs: "block", sm: "flex" },

    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "auto",
    border: `1.5px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default,
    boxShadow: "0px 10px 20px 0px #25C8D033",

    padding: "20px",
    borderRadius: "6px",
    "& .leftSectionBox": {
      textAlign: { xs: "center", sm: "left" },
      "& h5": {
        color: theme.palette.mode === "dark" ? "#fff" : "#000",
      },
      "& span": {
        color: theme.palette.mode === "dark" ? "#fff" : "#000",
      },
    },
    "& button": {
      backgroundColor: theme.palette.primary.main,
      fontWeight: 700,
      color: "#fff",
      padding: "10px 35px",
      border: `1px solid ${theme.palette.primary.main}`,
      mt: { xs: "10px", sm: "0" },
    },
  };
  return (
    <Box sx={MainHeadWrapper}>
      <Box className="leftSectionBox">
        <Typography variant="h5" sx={{ whiteSpace: { xs: "wrap", sm: "nowrap" }, fontSize: "28px", fontWeight: "700", mb: "12px" }}>
          More Details & advanced features{" "}
        </Typography>
        <Typography component="span" sx={{ fontSize: "14px", fontWeight: "500" }}>
          If you want to see more information then buy our premium plan{" "}
        </Typography>
      </Box>
      <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
        <Link to="/payments">
          <Button sx={{ "& button": { marginBottom: "0 !important" } }}>Purchase plan</Button>
        </Link>
      </Box>
    </Box>
  );
}

export default PurchasePlan;
