import { useState } from "react";
import { Box, Button, Container, Grid, styled } from "@mui/material";
import Breadcrumb from "../shared/Breadcrumb";
import ChildCard from "../shared/ChildCard";
import TicketForm from "./TicketForm";
import TicketFilter from "./TicketFilter";
import TicketListing from "./TicketListing";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  padding: "30px 0 80px",
  "& .createTicket": {
    marginBottom: "20px",
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  "& h4": {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
}));
const BCrumb = [
  {
    to: "/",
    title: "Home",
  },
  {
    title: "Tickets",
  },
];

function TicketList() {
  const [open, setOpen] = useState(false);
  const [ticketsFetch, setTicketsFetch] = useState([]);

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={12}>
              <Breadcrumb title="Tickets" items={BCrumb} />
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}
                className="createTicket"
              >
                Create Ticket
              </Button>
              <ChildCard>
                <TicketFilter ticketsFetch={ticketsFetch} />
                <TicketListing setTicketsFetch={setTicketsFetch} ticketsFetch={ticketsFetch} />
              </ChildCard>
              <TicketForm open={open} setOpen={setOpen} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default TicketList;
