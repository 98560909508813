import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GET_ALLURL_WITH_KEYWORDS_ARRAY, GET_ALL_PROJECT, findOwnerSiteById } from "../gql/queries/userWebSiteList";
import useQuery from "./allQuery";
import { GET_LIST_DATA } from "../gql/queries/projectDetail";

export const GetUserWebsiteData = projectid => {
  const [ProjectData, setProjectData] = useState();

  const [ProjectDataById] = useLazyQuery(findOwnerSiteById, { fetchPolicy: "cache-first" });

  useEffect(() => {
    if (projectid) {
      ProjectDataById({
        variables: {
          userWebsiteFindByIdId: projectid,
        },
        onCompleted: res => {
          const { userWebsiteFindById } = res;
          setProjectData(userWebsiteFindById);
        },
        onError: err => {
          console.log("err", err);
        },
      });
    }
  }, [projectid]);

  return { ProjectData };
};

export const GetSessionList = projectId => {
  const [listData, setListData] = useState();
  const [ALL_SCRAPY_SESSION] = useLazyQuery(GET_LIST_DATA, { fetchPolicy: "cache-first" });
  const { allQuery } = useQuery();

  useEffect(() => {
    if (projectId) {
      allQuery({
        QUERY: ALL_SCRAPY_SESSION,
        variables: {
          projectId,
        },
        onCompleteFunc: data => {
          const { getAllScrapySessionId } = data || {};
          setListData(getAllScrapySessionId);
        },
        onErrorFunc: err => {
          console.log({ err });
        },
      });
    }
  }, [projectId]);

  return listData;
};

export const GetProjectByUserId = data => {
  const [allProjectWithSessionId, setAllProjectWithSessionId] = useState([]);
  const [paginationDetail, setPaginationDetail] = useState({ page: 1, projectPerBundle: 100 });
  const [search, setSearch] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const { userData } = useSelector(state => state.reducer);
  const { page = 1, projectPerBundle = 100 } = paginationDetail || {};
  const { allQuery } = useQuery();
  const { id } = userData || {};
  const [getScrapyData] = useLazyQuery(GET_ALL_PROJECT, { fetchPolicy: "cache-first" });
  const getData = async resid => {
    if (resid) {
      await allQuery({
        QUERY: getScrapyData,
        variables: {
          argumentData: { ...data, page, projectPerBundle, search },
        },
        onCompleteFunc: res => {
          if (res && res.getAllProjectByUserId) {
            setAllProjectWithSessionId([...res.getAllProjectByUserId.data]);
            setTotalPage(res.getAllProjectByUserId.totalProjectCount);
            setPaginationDetail({ ...paginationDetail, page: res.getAllProjectByUserId.currentPage });
          } else {
            setAllProjectWithSessionId([]);
          }
          setLoading(false);
        },
        onErrorFunc: err => {
          console.log(err);
          setLoading(false);
        },
      });
    }
  };

  useEffect(() => {
    if (id) {
      getData(id);
    }
    // eslint-disable-next-line
  }, [id, search]);

  return { allProjectWithSessionId, loading, paginationDetail, setPaginationDetail, totalPage, getData, search, setSearch, setLoading };
};

export const GetUrlListWithKeyword = sessionId => {
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [urlCount, setUrlCount] = useState(1);
  const [allUrl, setAllUrl] = useState(10);
  const [ALL_SCRAPY_SESSION] = useLazyQuery(GET_ALLURL_WITH_KEYWORDS_ARRAY, { fetchPolicy: "cache-first" });
  const { allQuery } = useQuery();

  const getAllUrlWithKeyword = () => {
    allQuery({
      QUERY: ALL_SCRAPY_SESSION,
      variables: {
        sessionId,
        urlCount,
      },
      onCompleteFunc: data => {
        const { KeywordArrayWithUrlReport } = data || {};
        const { urlData, totalPage } = KeywordArrayWithUrlReport || {};
        setListData(urlData);
        setAllUrl(totalPage);
        setLoading(false);
      },
      onErrorFunc: err => {
        console.log({ err });
        setLoading(false);
      },
    });
  };
  useEffect(() => {
    if (sessionId) {
      getAllUrlWithKeyword(sessionId);
    }
  }, [sessionId, urlCount]);

  return { listData, loading, urlCount, setUrlCount, allUrl };
};
