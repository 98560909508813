import { Box, Container, Grid, Typography, styled, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import MainTitle from "./MainTitle";
import StatusCard from "./StatusCard";
import RevenueUpdates from "./RevenueUpdate";
import ResponseTimeChart from "./ResponseTimeChart";
import UptimeDescription from "./UptimeDescription";
import { GetUptimStatus } from "../../hooks/getUptimeRecords";
import MainLoader from "../MainLoader/MainLoader";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
}));
function UptimeMonitoring() {
  const theme = useTheme();
  const { projectid } = useParams();
  const { uptimeRecordsData, isLoading } = GetUptimStatus(projectid);
  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} lg={12}>
              <MainTitle />
            </Grid>
            {isLoading ? (
              <MainLoader message="Loading" />
            ) : uptimeRecordsData?.success ? (
              <>
                <Grid item xs={12} lg={12} sx={{ pt: "0px !important" }}>
                  <StatusCard data={uptimeRecordsData} projectid={projectid} />
                </Grid>
                <Grid item xs={12} lg={12} sx={{ pt: "25px !important" }}>
                  <RevenueUpdates data={uptimeRecordsData} projectid={projectid} />
                </Grid>
                {uptimeRecordsData.responseMoments && (
                  <Grid item xs={12} lg={12} sx={{ pt: "25px !important" }}>
                    <ResponseTimeChart data={uptimeRecordsData} />
                  </Grid>
                )}
              </>
            ) : !uptimeRecordsData?.success && !uptimeRecordsData?.isMonitoring ? (
              <Grid item xs={12} lg={12}>
                <UptimeDescription />
              </Grid>
            ) : (
              <Grid item xs={12} lg={12}>
                <Box
                  sx={{
                    height: "calc(100vh - 260px)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& h3": { fontSize: "18px", fontWeight: "700", color: theme.palette.text.primary },
                  }}
                >
                  <Typography component="h3">Please wait for some time while we are collecting your uptime monitoring data!</Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default UptimeMonitoring;
