/* eslint-disable */
import { Box, Card, Button, Container, Grid, styled, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { useLazyQuery } from "@apollo/client";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { INCREASE_CRAWLLIMIT, TemporaryBasisQuery } from "../../gql/queries/users";
import { extractDomainAndProtocol } from "../../common/utils";
import { GetUserWebsiteCrawlData } from "../../hooks/user";
import SpiderMakkdi from "../../Images/svgs/SpiderMakkdi.svg";
import RewardBudget from "../../Images/RewardBudget.png";
import useQuery from "../../hooks/allQuery";
import BackButton from "../BackButton/BackButton";
import SearchBar from "../common/SearchBar";
import MainLoader from "../MainLoader/MainLoader";
import CreateProjectLink from "../createProject/CreateProjectLink";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .SearchTopBarWrapper": {
    "& .SearchBarWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "15px",
      "@media screen and (max-width:600px)": {
        flexDirection: "column",
      },
      "& button": {
        textTransform: "none",
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        borderRadius: "5px",
        boxShadow: "none",
        padding: "6px 22px",
        "@media screen and (max-width:600px)": {
          width: "100%",
        },
        "&:focus": {
          outline: "0",
        },
      },
    },
  },
  "& .BtnTopWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    "@media screen and (max-width:600px)": {
      flexDirection: "column",
    },
    "& .ClaimRewardWrapper": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      padding: "6px 16px",
      // animation: "move 2s ease-in infinite",
      // "@keyframes move": {
      //   "0%": { transformOrigin: "center center", transform: "scale(1.0)" },
      //   "50%": { transform: "scale(1.120)" },
      //   "100%": { transform: "scale(1.0)" },
      // },
      "@media screen and (max-width:600px)": {
        width: "100%",
      },
    },
  },

  "& .CrawlBoxWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    backgroundColor: theme.palette.primary.light,
    padding: "12px 12px 12px",
    borderRadius: "6px",
    "& img": {
      width: "50px",
      height: "50px",
      objectFit: "contain",
      objectPosition: "center",
      border: `1.5px solid ${theme.palette.primary.main}`,
      borderRadius: "50%",
      padding: "8px",
    },
    "& h5": {
      fontSize: "16px",
      fontWeight: 600,
      color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
      lineHeight: 1,
      marginBottom: "8px",
    },
    "& h2": { fontSize: "20px", fontWeight: 600, color: theme.palette.mode === "dark" ? "#ffffff" : "#000000", lineHeight: 1 },
  },
}));
const BoxMainWrapper = styled(Box)(({ theme }) => ({
  "& .CrawlStatusWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 20px 20px",
    boxShadow: theme.palette.primary.main + "4d" + " " + "0px 0px 2px 0px," + theme.palette.primary.main + "1f" + " " + "0px 0px 24px -4px",
    borderRadius: "8px",
    "@media screen and (max-width: 600px)": {
      flexDirection: "column",
    },
    "& .CrawlAvailableWrapper": {
      "& h2": {
        color: theme.palette.primary.main,
        fontSize: "15px",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        gap: "8px",
        "&:first-of-type": { marginBottom: "8px" },
        "@media screen and (max-width: 600px)": {
          fontSize: "12px",
        },
        "& img": {
          width: "18px",
        },
      },
    },
    "& .ClaimRewardWrapper": {
      "& button": {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        animation: "move 2s ease-in infinite",
        "@keyframes move": {
          "0%": { transformOrigin: "center center", transform: "scale(1.0)" },
          "50%": { transform: "scale(1.120)" },
          "100%": { transform: "scale(1.0)" },
        },
        "@media screen and (max-width: 600px)": {
          marginTop: "15px",
        },
      },
    },
  },
  "& .DataGridWrapper": {
    "& .datagridnopage": {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: "7px 7px 7px 7px",
      "&.PaginationHide .MuiDataGrid-footerContainer": {
        display: "none",
      },
    },
    "& .MuiDataGrid-main": {
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: theme.palette.mode === "dark" ? "#FFFFFF33" : "#2A3547",
        borderRadius: "7px",
        "& .MuiDataGrid-columnSeparator": {
          color: "#8F8F8F",
          visibility: "visible",
        },
        "& .MuiDataGrid-columnHeadersInner": {
          flex: "1",
          '& div[role="row"]': {
            flex: "1",
            width: "100%",
            "& .MuiDataGrid-columnHeader": {
              outline: "none !important",
              paddingLeft: "18px",
              paddingRight: "18px",
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                "& .MuiDataGrid-iconButtonContainer": {
                  display: "none",
                },
              },
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "14px",
            color: theme.palette.mode === "dark" ? "#ffffff" : "#ffffff",
            fontWeight: 600,
          },
        },
      },
      "& .MuiDataGrid-virtualScroller": {
        overflowX: "hidden",
        "&::-webkit-scrollbar-track": {
          borderRadius: "10px",
          backgroundColor: theme.palette.text.primary + "0d",
        },
        "&::-webkit-scrollbar": { width: "8px", backgroundColor: "transparent" },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: theme.palette.primary.main,
        },
        "& .MuiDataGrid-virtualScrollerContent": {
          // height: "400px !important",
          height: "calc(100vh - 295px) !important",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            width: "100%",
            "@media screen and (max-width:600px)": {
              width: "auto",
            },
            "& .MuiDataGrid-row": {
              boxShadow: "none",
              minHeight: "70px !important",
              width: "100%",
            },
            '& div[role="row"]': {
              cursor: "pointer",
              borderBottom: `2px solid ${theme.palette.divider}`,
              width: "100%",
              "&:last-of-type": { borderWidth: "1px" },
              "& .MuiDataGrid-cell": {
                minHeight: "70px !important",
                flex: 1,
                paddingLeft: "18px",
                paddingRight: "18px",
                "&:last-of-type": {
                  display: "none",
                  "@media screen and (max-width:600px)": {
                    display: "flex",
                  },
                },
                "&.MuiDataGrid-cell--withRenderer": {
                  "&:last-of-type": {
                    display: "flex",
                  },
                },
                "&:focus-within": { outline: "none" },
                "& .MuiDataGrid-cellContent": {
                  color: theme.palette.text.primary,
                  fontWeight: 600,
                },
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-footerContainer": {
      justifyContent: "center",
      "& .MuiTablePagination-root": {
        "& .MuiToolbar-root": {
          "& .MuiTablePagination-selectLabel": {
            marginBottom: "0",
          },
          "& .MuiTablePagination-displayedRows": {
            marginBottom: "0",
          },
        },
      },
    },
  },
}));

const rows = [
  {
    Projects: {
      name: "P1",
      LastCrawl: "25OCT",
      img: "https://images.unsplash.com/photo-1682685797660-3d847763208e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    id: "1",
    index: "1",
    createdDate: "=======",
    CrawlCount: 0,
  },
  {
    Projects: {
      name: "P2",
      img: "https://images.unsplash.com/photo-1682685797660-3d847763208e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    id: "2",
    index: "3",
    createdDate: "=======",
    CrawlCount: 0,
  },
  {
    Projects: {
      name: "P3",
      img: "https://images.unsplash.com/photo-1682685797660-3d847763208e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    id: "3",
    index: "3",
    createdDate: "=======",
    CrawlCount: 0,
  },
  {
    Projects: {
      name: "P4",
      LastCrawl: "25OCT",
      img: "https://images.unsplash.com/photo-1682685797660-3d847763208e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    id: "4",
    index: "4",
    createdDate: "=======",
    CrawlCount: 0,
  },
];

function CrawlLimitTable() {
  const wrapperRef = useRef(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [loader, setLoader] = useState(false);
  const isMblBreakpoint = useMediaQuery("(max-width: 600px)");
  const { authorId } = useParams();
  const navigate = useNavigate();
  const { ProjectData, setSearch, totalUsedCrawls } = GetUserWebsiteCrawlData();
  const theme = useTheme();
  const { allQuery } = useQuery();
  const [searchParams] = useSearchParams();
  const [getMoreCrawl] = useLazyQuery(TemporaryBasisQuery, { fetchPolicy: "cache-first" });
  const customizer = useSelector(state => state.customizer);

  const [increaseCrawl] = useLazyQuery(INCREASE_CRAWLLIMIT);

  const handleClick = () => {
    allQuery({
      QUERY: increaseCrawl,
      onCompleteFunc: data => {
        console.log("data", data);
      },
      onErrorFunc: errdata => {
        console.log("error", errdata);
      },
    });
  };

  const columns = [
    {
      field: "Projects",
      headerName: "Project",
      headerAlign: "left",
      align: "left",
      width: 250,
      overflow: "hidden",
      renderCell: params => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            "& img": {
              width: "40px",
              height: "auto",
            },
          }}
        >
          <Typography component="img" src={params?.value?.img} alt={params?.value?.name} />
          <Box
            sx={{
              "& h4": { fontSize: "16px", fontWeight: 600, color: theme.palette.mode === "dark" ? "#ffffff" : "#000000" },
              "& a": { fontSize: "12px", fontWeight: 600, fontWeight: 600, color: theme.palette.mode === "dark" ? "#757575" : "#757575" },
            }}
          >
            <Typography component="h4">{params?.value?.name}</Typography>
            <Typography component="a" target="_blank" href={params.value.website}>
              {params?.value?.website && extractDomainAndProtocol(params?.value?.website).domain}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: "CrawlCount",
      headerName: "Used Crawl Pages",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "left",
      align: "left",
      width: 150,
      renderCell: params => {
        const { status } = params.row.status;
        let text;
        let style;
        switch (status) {
          case "Completed":
            text = "Completed";
            style = { color: "#13DEB9", border: "1px solid #13DEB9", borderRadius: "50px", backgroundColor: "#13DEB933", fontSize: "12px", padding: "5px 14px 5px" };
            break;
          case "Failed":
            text = "Failed";
            style = { color: "#FA896B", border: "1px solid #FA896B", borderRadius: "50px", backgroundColor: "#FA896B33", fontSize: "12px", padding: "5px 14px 5px" };
            break;
          default:
            text = "Progress";
            style = { color: "#FFAE1F", border: "1px solid #FFAE1F", borderRadius: "50px", backgroundColor: "#FFAE1F33", fontSize: "12px", padding: "5px 14px 5px" };
            break;
        }
        return (
          <Typography component="span" sx={style}>
            {text}
          </Typography>
        );
      },
    },
  ];

  const debouncedHandleChange = useCallback(
    debounce(value => {
      setSearch(value);
    }, 300),
    [],
  );

  const handleChange = e => {
    debouncedHandleChange(e.target.value);
  };

  useEffect(() => {
    return () => {
      debouncedHandleChange.cancel();
    };
  }, [debouncedHandleChange]);

  // Data Table Dynamic Width Set Start

  useEffect(() => {
    const handleResize = entries => {
      for (let entry of entries) {
        setLoader(true);
        setWrapperWidth(entry.contentRect.width);
        setTimeout(() => {
          setLoader(false);
        }, 300);
      }
    };
    const observer = new ResizeObserver(handleResize);
    if (wrapperRef.current) {
      observer.observe(wrapperRef?.current);
    }
    () => {
      if (wrapperRef.current) {
        observer.unobserve(wrapperRef?.current);
      }
      return;
    };
  }, [wrapperRef.current]);

  // Data Table Dynamic Width Set End
  useEffect(() => {
    setLoader(!loader);
  }, [customizer.isCollapse]);

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} md={12}>
              <Box ref={wrapperRef} sx={{ width: "100%" }}>
                <Card
                  sx={{
                    padding: "16px 16px 16px 16px",
                    border: `1px solid ${theme.palette.divider}`,
                    boxShadow: "none !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "15px",
                    "@media screen and (max-width:600px)": {
                      flexDirection: "column",
                      alignItems: "stretch",
                    },
                  }}
                >
                  <Box className="BtnTopWrapper">
                    <BackButton sx={{ "& button": { marginBottom: "0 !important" } }} />
                    <CreateProjectLink />
                    <Button onClick={() => handleClick()} className="ClaimRewardWrapper">
                      Claim Reward
                    </Button>
                  </Box>
                  <Box className="SearchTopBarWrapper">
                    <Box className="SearchBarWrapper">
                      <SearchBar handleChange={handleChange} />
                      <Button>Search</Button>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={4}>
              <Box className="CrawlBoxWrapper">
                <Typography component={"img"} src={SpiderMakkdi} alt="total Reward" />
                <Box>
                  <Typography component="h5">Total Crawl</Typography>
                  <Typography component="h2">{parseInt(searchParams.get("limit")) + parseInt(totalUsedCrawls) || 0}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="CrawlBoxWrapper">
                <Typography component={"img"} src={SpiderMakkdi} alt="total Reward" />
                <Box>
                  <Typography component="h5">Total User Crawl</Typography>
                  <Typography component="h2">{totalUsedCrawls || 0}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="CrawlBoxWrapper">
                <Typography component={"img"} src={RewardBudget} alt="total Reward" />
                <Box>
                  <Typography component="h5">Total Reward Avallable</Typography>
                  <Typography component="h2">{parseInt(searchParams.get("limit")) || 0}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {loader ? (
            <MainLoader message="Loading" />
          ) : (
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} md={12}>
                <BoxMainWrapper>
                  <Box sx={{ width: "100%" }}>
                    <Box className="DataGridWrapper" style={{ width: wrapperWidth }}>
                      <DataGrid
                        rows={ProjectData?.dataArr || rows}
                        columns={columns}
                        pageSize={false}
                        pagination={false}
                        className="datagridnopage"
                        onCellClick={params => {
                          return navigate(`/crawl-progress/${params?.row?.sessionId}`, { state: { projectName: params?.row?.Projects?.name } });
                        }}
                        disableColumnMenu
                      />
                    </Box>
                  </Box>
                </BoxMainWrapper>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default CrawlLimitTable;
