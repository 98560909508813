import { Backdrop, Box, Button, Fade, Modal, Typography, styled } from "@mui/material";
import { memo } from "react";

const BoxWrapperstyle = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  backgroundColor: theme.palette.background.default,
  padding: "30px 35px 34px",
  borderRadius: "15px",
  "@media screen and (max-width:600px)": {
    width: "90%",
  },
  "& h2": { fontSize: "18px", fontWeight: "600", color: theme.palette.text.primary, marginBottom: "10px" },
  "& p": { fontSize: "16px", marginBottom: "18px", color: theme.palette.text.primary, "& strong": { fontSize: "16px", color: theme.palette.text.primary, fontWeight: "700" } },
  "& .BtnWrapper": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  "& button": {
    boxShadow: "none",
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    borderRadius: "7px",
    // border: "1px solid #ff642d",
    padding: "6px 24px 6px",
    // width: "100%",
    fontSize: "16px",
    minWidth: "0px",
    flex: "1",
    "@media screen and (max-width: 600px)": {
      padding: "6px 10px 6px",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "none",
      color: "#fff",
    },
    "&:focus": {
      outline: "none",
    },
  },
}));
function VerifyPopup({ open, setOpen, handleSubmit, email }) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <BoxWrapperstyle>
          <Typography component="p">
            Your email <Typography component="strong">{email}</Typography> is not verify. Do you want to Receive verification link again
          </Typography>
          <Box className="BtnWrapper">
            <Button variant="contained" className="okButton" onClick={handleSubmit}>
              Send Verify Link
            </Button>
            <Button variant="contained" className="okButton" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </BoxWrapperstyle>
      </Fade>
    </Modal>
  );
}

export default memo(VerifyPopup);
