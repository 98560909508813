import gql from "graphql-tag";

const createOrg = gql`
  mutation Mutation($payload: CreateInput!) {
    createOrg(payload: $payload) {
      isActive
      id
      authorId
      name
      limit
      name
      pin
      state
      landmark
      city
      address2
      address
      GSTN
      license
      userData {
        id
        name
        email
        crawlLimit
      }
    }
  }
`;

const addMemberInOrg = gql`
  mutation Mutation($payload: CreateOrgMemberInput!) {
    createMemberInOrg(payload: $payload) {
      authorId
      createdAt
      id
      memberId
      name
      organizationId
      updatedAt
    }
  }
`;

const inviteAddMemeber = gql`
  mutation InviteMembers($payload: InviteMember!) {
    inviteMembers(payload: $payload)
  }
`;

const invitationVerifications = gql`
  mutation Mutation($payload: VarifyAddMember!) {
    varifyMember(payload: $payload)
  }
`;

const cancelInvitation = gql`
  mutation Mutation($payload: RemoveInvitations!) {
    cancelInvitation(payload: $payload) {
      id
      email
      organizationId
    }
  }
`;
const MarkAsReadApi = gql`
  mutation Mutation($invitationId: String!) {
    markAsOpen(invitationId: $invitationId) {
      authorId
      createdAt
      isOpened
      organizationId
      email
      id
      updatedAt
    }
  }
`;

const updateOrg = gql`
  mutation Mutation($payload: OrganizationUpdateIn!) {
    updateOrg(payload: $payload) {
      GSTN
      address
      address2
      authorId
      city
      id
      isActive
      landmark
      license
      limit
      name
      pin
      state
      expiry
    }
  }
`;

const deleteOrgById = gql`
  mutation DeleteOrg($deleteOrgId: String!) {
    deleteOrg(id: $deleteOrgId) {
      id
      message
    }
  }
`;

const orgMemberDeleteByOrgId = gql`
  mutation Mutation($organizationId: String!) {
    deleteMemberInOrgByOrgId(organizationId: $organizationId) {
      id
      organizationId
    }
  }
`;

export { createOrg, addMemberInOrg, inviteAddMemeber, invitationVerifications, cancelInvitation, MarkAsReadApi, updateOrg, deleteOrgById, orgMemberDeleteByOrgId };
