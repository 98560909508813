/* eslint-disable */
import { styled, Container, Box, useTheme, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { memo, useEffect, useMemo, useContext, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Sidebar from "../NavBar/sidebar/Sidebar";
import { Header } from "../NavBar/header/Header";
import Navigation from "../horizontallayout/navbar/Navigation";
import Customizer from "../customizer/Customizer";
import HorizontalHeader from "../horizontallayout/header/Header";
import { deleteSession, recoverSession, removeToken } from "../../utils/session";
import { TOKENBYUSER } from "../../gql/queries/users";
import { loginUser, setThemeConfig } from "../../redux/action";
import { AuthContext } from "../../AuthContext";

const MainWrapper = styled("div")(() => ({
  display: "flex",
  minHeight: "100vh",
  width: "100%",
}));

const PageWrapper = styled("div")(() => ({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  zIndex: 1,
  width: "100%",
  backgroundColor: "transparent",
}));

function LayoutHOC({ children }) {
  const { isAuth, activateAuth, removeAuth } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [bytoken] = useLazyQuery(TOKENBYUSER, { fetchPolicy: "network-only" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customizer = useSelector(state => state.customizer);
  const theme = useTheme();
  const ShowNav = useMemo(
    () =>
      location.pathname === "/" ||
      location.pathname.includes("forgetPassword") ||
      location.pathname.includes("register") ||
      location.pathname.includes("preSignUp") ||
      location.pathname.includes("resendVerify") ||
      location.pathname === "verify" ||
      location.pathname.includes("forgot") ||
      location.pathname.includes("auth/login") ||
      location.pathname.includes("changepassword") ||
      location.pathname.includes("first-project-config") ||
      loading,
    [location.pathname, loading],
  );

  const callInUseEffect = async token => {
    if (token !== null && token !== undefined) {
      const { data } = await bytoken({
        variables: {
          token,
        },
      });
      if (!data?.tokenByUserData?.user) {
        removeToken();
        navigate("/");
        setLoading(false);
        return;
      }
      await dispatch(
        loginUser({ ...data?.tokenByUserData?.user, subscription: data?.tokenByUserData?.subscription, subscriptionCredits: data?.tokenByUserData?.subscriptionCredits } || {}),
      );
      if (data?.tokenByUserData?.user?.userTheme) {
        dispatch(setThemeConfig(data?.tokenByUserData?.user?.userTheme || {}));
      }
    } else {
      console.log("token not found");
    }
    setLoading(false);
  };
  useEffect(() => {
    if (!recoverSession("accessToken")) {
      deleteSession();
      setLoading(false);
    }
    if (recoverSession("accessToken") !== null && recoverSession("accessToken") !== undefined) {
      callInUseEffect(recoverSession("accessToken"));
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "calc(100vh - 100px)" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <MainWrapper>
        {!ShowNav && !customizer.isHorizontal && <Sidebar />}

        <PageWrapper
          className="page-wrapper"
          sx={{
            ...(customizer.isCollapse &&
              !ShowNav && {
                [theme.breakpoints.up("lg")]: {
                  ml: `${customizer.MiniSidebarWidth}px`,
                },
              }),
            "& .MuiContainer-root": {
              "@media screen and (min-width: 1900px)": { minWidth: "1600px" },
              "@media screen and (min-width: 2500px)": { minWidth: "2250px" },
            },
          }}
        >
          {!ShowNav && (
            <>
              {customizer.isHorizontal ? <HorizontalHeader /> : <Header />}
              {customizer.isHorizontal && <Navigation />}
              <Customizer />
            </>
          )}
          <Container
            sx={{
              maxWidth: !customizer.isLayout === "boxed" ? "lg" : "100%!important",
              p: "0px !important",
            }}
          >
            <Box sx={{ minHeight: "calc(100vh - 170px)" }}>{children}</Box>
          </Container>
        </PageWrapper>
      </MainWrapper>
    </>
  );
}

export default memo(LayoutHOC);
