export function convertUTCDateToLocalDate(utcDateString) {
  const dateObject = new Date(utcDateString);
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  const year = dateObject.getFullYear();
  const localDateString = `${month}/${day}/${year}`;
  return localDateString;
}

export function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = `data:${file.type};base64,${reader.result.split(",")[1]}`;
      resolve(base64String);
    };

    reader.onerror = error => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export const isEmpty = value => {
  if (typeof value !== "string") {
    return true;
  }

  return !value.trim().length;
};

export const isEmptyNumber = value => {
  if (typeof value !== "number") {
    return true;
  }
  return !value.trim().length;
};

export const isGSTN = value => {
  const regex = /^[A-Za-z0-9]{1,2}-[0-9]{7}$/;
  return regex.test(value);
};
export const isPositiveNumber = value => {
  const number = Number(value);
  return !Number.isNaN(number) && number > 0;
};
export const GetScore = data => {
  const itemWithNonZeroScore = data.find(item => item.websiteScore);
  return itemWithNonZeroScore ? itemWithNonZeroScore.websiteScore : 0;
};

export const GetErrorUrlCount = data => {
  const itemWithNonZeroScore = data.find(item => item.errorUrlCount);
  return itemWithNonZeroScore ? itemWithNonZeroScore.errorUrlCount : 0;
};
export function getDomain(url) {
  // Creating a URL object
  const urlObject = new URL(url);
  // Accessing the hostname property to get the domain
  return urlObject.hostname;
}

export function convertISOToDateFormat(createdAt) {
  // Convert the input string to a Date object
  const date = new Date(createdAt);

  // Define months array for formatting
  // const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

  // Extract date components
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const day = date.getDate();
  const month = date.getMonth() + 1; // Note: Month is 0-based
  const year = date.getFullYear();

  // Format hours to 12-hour clock and add AM/PM
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM

  // Format the day and month as strings with leading zeros if needed
  const formattedDay = day < 10 ? `0${day}` : day?.toString();
  const formattedMonth = month < 10 ? `0${month}` : month?.toString();

  // Create the formatted date string
  const formattedDate = `${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm} ${day}/${month}/${year}`;

  return {
    formattedDate,
    date: { day, month, year, formattedDay, formattedMonth },
    twelveHourFormat: { ampm, hour: formattedHours, minutes: minutes.toString().padStart(2, "0") },
  };
}
// export function convertMongoDBTimestampToDayMonth(mongoDBTimestamp) {
//   // Parse the MongoDB timestamp into a JavaScript Date object
//   const date = new Date(mongoDBTimestamp);

//   // Extract the day and month components
//   const day = date.getUTCDate();
//   const month = date.getUTCMonth() + 1; // Months are 0-based, so add 1

//   // Format the day and month as strings with leading zeros if needed
//   const formattedDay = day < 10 ? `0${day}` : day.toString();
//   const formattedMonth = month < 10 ? `0${month}` : month.toString();

//   // Return the day/month string
//   return `${formattedDay}/${formattedMonth}`;
// }

export function calculateDuration(createdAt, isCompleted, reportEndTime) {
  // Convert createdAt to a Date object
  const createdAtDate = new Date(createdAt);
  let currentTime;
  if (isCompleted && reportEndTime) {
    currentTime = new Date(reportEndTime);
  } else {
    currentTime = new Date();
  }
  // Calculate the time difference in milliseconds
  const timeDifference = currentTime - createdAtDate;
  const positivetimeDifference = timeDifference < 0 ? 0 : timeDifference;

  // Convert the time difference to seconds
  const secondsDifference = Math.floor(positivetimeDifference / 1000);

  // Calculate days, hours, minutes, and seconds
  const days = Math.floor(secondsDifference / (24 * 60 * 60));
  const hours = Math.floor((secondsDifference % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((secondsDifference % (60 * 60)) / 60);
  const seconds = secondsDifference % 60;

  // Format the result
  const durationFormatted = `${days}:${hours?.toString().padStart(2, "0")}:${minutes?.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  return durationFormatted;
}
