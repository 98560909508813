/* eslint-disable */
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { getGraphValueOfDowntime, uptimeStatus } from "../gql/queries/uptimeStatus";
import useQuery from "./allQuery";

export const GetUptimStatus = projectId => {
  const [uptimeRecordsData, setUptimeRecordsData] = useState(null);
  const { allQuery } = useQuery();
  const [isLoading, setIsLoading] = useState(true);
  const [GetStatus] = useLazyQuery(uptimeStatus, { fetchPolicy: "network-only" });
  async function getData() {
    setIsLoading(true);
    await allQuery({
      QUERY: GetStatus,
      variables: {
        projectId,
      },
      onCompleteFunc({ getUptimeStatus }) {
        setUptimeRecordsData(getUptimeStatus);
        setIsLoading(false);
      },
      onErrorFunc(errdata) {
        setIsLoading(false);
        console.log("errdata", errdata);
      },
    });
  }

  useEffect(() => {
    if (projectId) {
      getData();
    }
    // eslint-disable-next-line
  }, [projectId]);

  return { uptimeRecordsData, isLoading };
};

export const useDownTimeGraphValue = (projectId, startDate, endDate) => {
  const [downTimeData, setDownTimeData] = useState(null);
  const [totalDownTime, setTotalDownTime] = useState(null);
  const [totalUpTime, setTotalUpTime] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [GetStatus] = useLazyQuery(getGraphValueOfDowntime, { fetchPolicy: "network-only" });

  async function getData() {
    try {
      const { data } = await GetStatus({
        variables: {
          projectId,
          timeStamp: {
            startDate,
            endDate,
          },
        },
      });
      if (data) {
        const { getDownTimeGraphValue } = data;
        const { uptime, downtime } = getDownTimeGraphValue;
        console.log({ getDownTimeGraphValue, uptime, downtime });
        if (uptime > totalUpTime) setTotalUpTime(uptime);
        if (downtime > totalDownTime) setTotalDownTime(downtime);
        setDownTimeData(getDownTimeGraphValue);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("errdata", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    console.log(1);
    getData();
  }, [projectId]);
  return { downTimeData, isLoading, getData, totalDownTime, totalUpTime };
};
