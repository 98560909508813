import { Box, Card, CardContent, Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import welcomelighthouse from "../../Images/backgrounds/welcomelighthouse.png";

function WelcomeCardReport({ projectid, sessionId }) {
  const theme = useTheme();
  const btnGroup = {
    display: "flex",
    gap: "20px",
    mt: "30px",
    "& .view-details": {
      backgroundColor: "transparent",
      fontWeight: 700,
      color: theme.palette.primary.main,
      padding: { xs: "10px 20px", sm: "10px 35px" },

      marginBottom: "15px",
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "8px",
    },
    "& .purchase-plan": {
      backgroundColor: theme.palette.primary.main,
      fontWeight: 700,
      color: "#fff",
      padding: { xs: "10px 20px", sm: "10px 35px" },
      marginBottom: "15px",
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "8px",
    },
  };
  return (
    <Card elevation={0} sx={{ backgroundColor: theme.palette.primary.light, py: 0, height: "100%" }}>
      <CardContent sx={{ py: 4, px: 2 }}>
        <Grid container justifyContent="space-between">
          <Grid item sm={6} display="flex" alignItems="center">
            <Box>
              <Box
                gap="16px"
                sx={{
                  display: {
                    xs: "block",
                  },
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="h5" sx={{ whiteSpace: { xs: "wrap", sm: "nowrap" }, fontSize: { xs: "20px", sm: "28px" }, fontWeight: "700", mb: "12px" }}>
                    Currently you have an free plan
                  </Typography>
                  <Typography component="span" sx={{ fontSize: "14px", fontWeight: "500" }}>
                    If you want to see more information then buy our premium plan
                  </Typography>
                </Box>
                <Box sx={btnGroup}>
                  <Link className="view-details" to={`/performanceChartboard?menu=performanceDashboard&projectId=${projectid}&sessionId=${sessionId}`}>
                    View Details
                  </Link>
                  <Link className="purchase-plan" to="/payments">
                    Purchase plan
                  </Link>
                </Box>
              </Box>
              <Stack spacing={2} direction="row" divider={<Divider orientation="vertical" flexItem />}></Stack>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box mb="-24px" textAlign="right">
              <Typography component="img" src={welcomelighthouse} alt="img" />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default WelcomeCardReport;
