import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducer";
const { reducer, customizerReducer, ticketReducer } = reducers;
const rootReducer = combineReducers({
  reducer,
  customizer: customizerReducer,
  ticketReducer,
});

const middleware = [thunk];
const composeEnhancers = compose(applyMiddleware(...middleware));

const configureStore = () => createStore(rootReducer, composeEnhancers);
const store = configureStore();

export default store;
