/* eslint-disable */
import { Card } from "@mui/material";
import { useSelector } from "react-redux";

const AppCard = ({ children, ClassName }) => {
  const customizer = useSelector(state => state.customizer);

  return (
    <Card
      sx={{
        display: "flex",
        p: 0,
        margin: "20px 0",
        "&.mt-none": {
          mt: "0",
        },
        "&.mb-none": {
          mb: "0",
        },
        "@media screen and (max-width:600px)": {
          justifyContent: "center",
        },
      }}
      elevation={customizer.isCardShadow ? 9 : 0}
      variant={!customizer.isCardShadow ? "outlined" : undefined}
      className={ClassName}
    >
      {children}
    </Card>
  );
};

export default AppCard;
