/* eslint-disable */
import { Box, Button, CircularProgress, Grid, styled, Typography } from "@mui/material";
import { dateless10, formatDate } from "../../common/utils";
import { Poll } from "@mui/icons-material";

const BoxWrapper = styled(Box)(({ theme }) => ({
  "& .UrlWrapper": {
    "& p": { color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547", fontSize: "14px", fontWeight: 600, lineHeight: 1, marginBottom: "8px" },
    "& a": {
      color: theme.palette.primary.main,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: 1.3,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
    },
  },
  "& .CreateUpdateWrapper": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "16px",
    "& .CreateUpdateBox": {
      "& p": { color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547", fontSize: "14px", fontWeight: 600, lineHeight: 1, marginBottom: "8px" },
      "& span": {
        color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#FFFFFF33" : "#F7F7F7",
        padding: "6px 12px 7px",
        borderRadius: "100px",
      },
    },
  },
  "& .BtnWrapper": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    gap: "16px",
    "& .ViewReport": {
      textTransform: "none",
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      borderRadius: "5px",
      boxShadow: "none",
      padding: "6px 22px",
      border: `1.5px solid ${theme.palette.primary.main}`,
      "@media screen and (max-width:820px)": {
        width: "100%",
      },
      "&:focus": {
        outline: "0",
      },
    },
    "& .Regenerate": {
      textTransform: "none",
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
      borderRadius: "5px",
      boxShadow: "none",
      padding: "7px 22px",
      "@media screen and (max-width:820px)": {
        width: "100%",
      },
      "&:focus": {
        outline: "0",
      },
    },
  },
  "& .PerformanceMainWrapper": {
    backgroundColor: theme.palette.mode === "dark" ? "#FFFFFF33" : "#F7F7F7",
    padding: "12px 14px 12px",
    borderRadius: "6px",
    "& p": { color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547", fontSize: "14px", fontWeight: 600, lineHeight: 1, marginBottom: "8px" },
    "& .PerformanceBoxWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "10px",
      "& .MblDeskBox": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "5px",
        "& span": {
          color: theme.palette.mode === "dark" ? "#ffffff" : "#565656",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: 1,
          "@media screen and (max-width:1300px) and (min-width:1020px)": { fontSize: "11px" },
        },
      },
    },
  },
}));
const CircularProgressBar = ({ value, color }) => {
  const radius = 18;
  const strokeWidth = 4;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (value / 100) * circumference;

  return (
    <div style={{ position: "relative", width: "45px", height: "45px" }}>
      <svg width="45" height="45">
        <circle cx="22.5" cy="22.5" r={radius} stroke="#e5e5e5" strokeWidth={strokeWidth} fill="none" />
        <circle cx="22.5" cy="22.5" r={radius} stroke={color} strokeWidth={strokeWidth} fill="none" strokeDasharray={circumference} strokeDashoffset={offset} />
      </svg>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "13px",
          fontWeight: "bold",
        }}
      >
        {Math.round(value)}
      </div>
    </div>
  );
};
function WebsiteReportDetail({ row, viewReport, handleClick, disableReports, loaderMap }) {
  const getTextColor = value => {
    if (value < 50) return "rgb(255, 51, 51)";
    if (value < 90) return "rgb(255, 170, 51)";
    return "rgb(0, 204, 102)";
  };
  const mobilePerformanceColor = getTextColor(Math.round(row.mobilePerformance * 100) || 0);
  const desktopPerformanceColor = getTextColor(Math.round(row.desktopPerformance * 100) || 0);
  const mobileAccessibilityColor = getTextColor(Math.round(row.mobileAccessibility * 100) || 0);
  const desktopAccessibilityColor = getTextColor(Math.round(row.desktopAccessibility * 100) || 0);
  const mobileSeoColor = getTextColor(Math.round(row.mobileSeo * 100) || 0);
  const desktopSeoColor = getTextColor(Math.round(row.desktopSeo * 100) || 0);
  const mobileBestPracticesColor = getTextColor(Math.round(row.mobileBestPractices * 100) || 0);
  const desktopBestPracticesColor = getTextColor(Math.round(row.desktopBestPractices * 100) || 0);
  const createAtDate = formatDate(row.createdAt);
  const updateAtDate = formatDate(row.updatedAt);

  return (
    <BoxWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Box className="UrlWrapper">
            <Typography component="p">URL Name</Typography>
            <Typography component="a" href={row.url} target="_blank" title={row.url}>
              {row.url}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box className="CreateUpdateWrapper">
            <Box className="CreateUpdateBox">
              <Typography component="p">Created At</Typography>
              <Typography component="span">{`${dateless10(createAtDate.day)}-${dateless10(createAtDate.monthNumber)}-${createAtDate?.year}`}</Typography>
            </Box>
            <Box className="CreateUpdateBox">
              <Typography component="p">Update At</Typography>
              <Typography component="span">{`${dateless10(updateAtDate.day)}-${dateless10(updateAtDate.monthNumber)}-${createAtDate?.year}`}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className="BtnWrapper">
            <Button className="ViewReport" endIcon={<Poll />} onClick={() => viewReport(row.url)} disabled={row.isReportProgress}>
              {row.isReportProgress ? <CircularProgress size={24} /> : row.isReportGenerated ? "View report" : "Report Not Found!"}
            </Button>
            <Button
              className="Regenerate"
              disabled={loaderMap[row.url] || row.isReportProgress || disableReports}
              onClick={() => {
                handleClick({ url: row.url, id: row.id });
              }}
            >
              {loaderMap[row.url] || row.isReportProgress ? <CircularProgress size={24} /> : row.isReportGenerated ? "Regenerate" : "Generate Report"}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={0}>
        <Grid item xs={12} sm={6} md={3}>
          <Box className="PerformanceMainWrapper">
            <Typography component="p">Performance</Typography>
            <Box className="PerformanceBoxWrapper">
              <Box className="MblDeskBox">
                <Typography component="span">Mobile</Typography>
                <CircularProgressBar value={Math.round(row.mobilePerformance * 100) || 0} color={mobilePerformanceColor} />
              </Box>
              <Box className="MblDeskBox">
                <Typography component="span">Desktop</Typography>
                <CircularProgressBar value={Math.round(row.desktopPerformance * 100) || 0} color={desktopPerformanceColor} />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box className="PerformanceMainWrapper">
            <Typography component="p">Accessibility</Typography>
            <Box className="PerformanceBoxWrapper">
              <Box className="MblDeskBox">
                <Typography component="span">Mobile</Typography>
                <CircularProgressBar value={Math.round(row.mobileAccessibility * 100) || 0} color={mobileAccessibilityColor} />
              </Box>
              <Box className="MblDeskBox">
                <Typography component="span">Desktop</Typography>
                <CircularProgressBar value={Math.round(row.desktopAccessibility * 100) || 0} color={desktopAccessibilityColor} />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box className="PerformanceMainWrapper">
            <Typography component="p">SEO</Typography>
            <Box className="PerformanceBoxWrapper">
              <Box className="MblDeskBox">
                <Typography component="span">Mobile</Typography>
                <CircularProgressBar value={Math.round(row.mobileSeo * 100) || 0} color={mobileSeoColor} />
              </Box>
              <Box className="MblDeskBox">
                <Typography component="span">Desktop</Typography>
                <CircularProgressBar value={Math.round(row.desktopSeo * 100) || 0} color={desktopSeoColor} />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box className="PerformanceMainWrapper">
            <Typography component="p">Best Practices</Typography>
            <Box className="PerformanceBoxWrapper">
              <Box className="MblDeskBox">
                <Typography component="span">Mobile</Typography>
                <CircularProgressBar value={Math.round(row.mobileBestPractices * 100) || 0} color={mobileBestPracticesColor} />
              </Box>
              <Box className="MblDeskBox">
                <Typography component="span">Desktop</Typography>
                <CircularProgressBar value={Math.round(row.desktopBestPractices * 100) || 0} color={desktopBestPracticesColor} />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </BoxWrapper>
  );
}
export default WebsiteReportDetail;
