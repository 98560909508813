import { useLazyQuery, useMutation, useApolloClient, useSubscription } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { pageSpeed, SCRAP_WEBSITE } from "../gql/mutations/scrapy";
import {
  GET_IMAGES_TAG_COUNT_BY_SESSION,
  GET_SCRAPY_BY_SESSION,
  GET_SCRAPY_CONTENT_COUNT_BY_SESSION,
  GET_SCRAPY_COUNT_BY_SESSION,
  GET_SCRAPY_CSS_COUNT_BY_SESSION,
  GET_SCRAPY_EXTERNAL_PAGES_COUNT_BY_SESSION,
  GET_SCRAPY_INDEX_COUNT_BY_SESSION,
  GET_SCRAPY_INTERNAL_PAGE_COUNT_BY_SESSION,
  GET_SCRAPY_JAVASCRIPT_COUNT_BY_SESSION,
  GET_SCRAPY_SOCIAL_TAG_COUNT_BY_SESSION,
  SPEED_DATA,
  GET_SCRAPY_COUNT_LINK,
  speedDataReport,
  ISSUES_DATA,
  GET_SCRAPY_DUPLICATE_COUNT_BY_SESSION,
  GET_SCRAPY_REDIRECT_COUNT_BY_SESSION,
  CRAWLING_REPORT,
  CRAWLING_SUBSCRIPTION,
  SINGLE_SCREAPYSESSION_DATA,
  CRAWLPROGRESS_SCRAPY_DATA,
  ALL_REPORT,
  ALL_SITEAUDIT_COUNT,
  ALL_SITEAUDIT_DATA,
  DELETE_REPORT,
} from "../gql/queries/scrapy";
import { SCRAPY_DATA } from "../gql/queries/userWebSiteList";
import { extractDomainAndProtocol, preparePayloadWithoutId } from "../common/utils";
import useQuery from "./allQuery";
import { GET_LIST_DATA, reportCount } from "../gql/queries/projectDetail";
import { usePopup } from "../context/commonPop";
import { GET_ALL_DATA_SUPERADMIN } from "../gql/queries/ticketadmin";

const setStateFunc = (setState, updateFlag) => {
  setState(prev => {
    const newObj = { ...prev, [updateFlag]: { ...prev[updateFlag], turn: !prev[updateFlag].turn } };
    return newObj;
  });
};

export const GetScrapyData = () => {
  const [scrapyData, setScrapyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const { userData } = useSelector(state => state.reducer);
  const { allQuery } = useQuery();
  const { id } = userData || {};
  const [getScrapyData] = useLazyQuery(SCRAPY_DATA, { fetchPolicy: "cache-first" });

  const scrapyResponse = async resid => {
    if (resid) {
      await allQuery({
        QUERY: getScrapyData,
        variables: {
          params: resid,
        },
        onCompleteFunc: res => {
          if (res && res.userWebVisitList) {
            const findIsVerified = res.userWebVisitList.find(item => item.isVerified);
            if (findIsVerified) {
              setIsVerified(false);
            } else {
              setIsVerified(true);
            }
            setScrapyData([...res.userWebVisitList]);
          } else {
            setIsVerified(true);
            setScrapyData([]);
          }
          setLoading(false);
        },
        onErrorFunc: err => {
          console.log(err);
          setLoading(false);
        },
      });
    }
  };

  useEffect(() => {
    if (id) {
      scrapyResponse(id);
    }
    // eslint-disable-next-line
  }, [id]);

  return { scrapyData, loading, isVerified };
};

export const CreateScrapyReport = () => {
  const { userData } = useSelector(state => state.reducer);
  // const pattern = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\n?]+)/;
  const { id } = userData || {};

  const [createScrapyReportCall] = useMutation(SCRAP_WEBSITE);
  const client = useApolloClient();

  const createScrapy = async item => {
    // const match = item?.website.match(pattern);
    const domain = extractDomainAndProtocol(item?.website)?.domain;
    try {
      const res = await createScrapyReportCall({
        variables: {
          payload: {
            domain,
            authorId: id,
            projectId: item?.id,
          },
        },
      });
      try {
        if (res && res.data && res.data.ScrapUrl) {
          const cacheData = await client.readQuery({
            query: SCRAPY_DATA,
            variables: { params: id },
          });

          const newScrapyData = [res.data.ScrapUrl, ...cacheData.userWebVisitList];

          await client.writeQuery({
            query: SCRAPY_DATA,
            variables: { params: id },
            data: { userWebVisitList: newScrapyData },
          });

          const cache = await client.readQuery({
            query: GET_LIST_DATA,
            variables: {
              projectId: item?.id,
            },
          });
          const newCache = cache.getAllScrapySessionId?.[0];
          // console.log({ cache });

          await client.writeQuery({
            query: GET_LIST_DATA,
            variables: {
              projectId: item?.id,
            },
            data: { getAllScrapySessionId: [{ ...newCache, isCrawlProgress: true }] },
          });
        }
      } catch (error) {
        console.log(error);
      }

      return res;
    } catch (error) {
      console.error("Error creating Scrapy report:", error.message);
      return { errors: error.message };
    }
  };

  return { createScrapy };
};

export const SessionScrapyData = sessionId => {
  const [sessionData, setSessionData] = useState([]);

  const [getSessionScrapyData] = useLazyQuery(GET_SCRAPY_BY_SESSION, {
    fetchPolicy: "cache-first",
  });
  const scrapyResponse = async () => {
    if (sessionId) {
      const res = await getSessionScrapyData({
        variables: {
          sessionId,
        },
      });
      if (res && res.data && res.data.GetScrapyBySession) {
        setSessionData([...res.data.GetScrapyBySession]);
      } else {
        setSessionData([]);
      }
    }
  };

  useEffect(() => {
    if (sessionId) {
      scrapyResponse();
    }
    // eslint-disable-next-line
  }, [sessionId]);

  return { sessionData };
};

export const SessionScrapyDetailCount = (sessionId, projectId) => {
  const [data, setData] = useState({});
  const [getScrapyDetailCount] = useLazyQuery(GET_SCRAPY_COUNT_BY_SESSION, {
    fetchPolicy: "cache-first",
  });

  const scrapyResponse = async () => {
    if (sessionId) {
      const res = await getScrapyDetailCount({
        variables: {
          payload: {
            session_id: sessionId,
            projectId,
          },
        },
      });
      if (res && res.data && res.data.GetScrapyCounts) {
        setData({ ...res.data.GetScrapyCounts });
      } else {
        setData({});
      }
    }
  };

  function ScrapyDetailCount(updateFlag, key) {
    setData(prev => {
      const newObj = { ...prev, [key]: { ...prev[key], [updateFlag]: { ...prev[key][updateFlag], turn: !prev[key][updateFlag].turn } } };
      return newObj;
    });
  }
  useEffect(() => {
    if (sessionId) {
      scrapyResponse();
    }
    // eslint-disable-next-line
  }, [sessionId]);

  return { data, ScrapyDetailCount };
};

export const SessionScrapyIndexCount = sessionId => {
  const [data, setData] = useState({});

  const [getScrapyIndexCount] = useLazyQuery(GET_SCRAPY_INDEX_COUNT_BY_SESSION, {
    fetchPolicy: "cache-first",
  });

  const scrapyResponse = async () => {
    if (sessionId) {
      const res = await getScrapyIndexCount({
        variables: {
          sessionId,
        },
      });
      if (res && res.data && res.data.GetScrapyIndexCounts) {
        setData({ ...res.data.GetScrapyIndexCounts });
      } else {
        setData({});
      }
    }
  };

  useEffect(() => {
    if (sessionId) {
      scrapyResponse();
    }
    // eslint-disable-next-line
  }, [sessionId]);

  return { data };
};

export const SessionScrapyInternalPageCount = (sessionId, projectId) => {
  const [data, setData] = useState({});
  const [getScrapyInternalPageCount] = useLazyQuery(GET_SCRAPY_INTERNAL_PAGE_COUNT_BY_SESSION, {
    fetchPolicy: "cache-first",
  });
  const scrapyResponse = async () => {
    if (sessionId) {
      const res = await getScrapyInternalPageCount({
        variables: {
          payload: {
            session_id: sessionId,
            projectId,
          },
        },
      });
      if (res && res.data && res.data.GetMongooseInternalPagesCount) {
        setData({
          ...preparePayloadWithoutId("_id", res.data.GetMongooseInternalPagesCount),
        });
      } else {
        setData({});
      }
    }
  };
  function refresh(updateFlag) {
    setData(prev => {
      const newObj = { ...prev, [updateFlag]: { ...prev[updateFlag], turn: !prev[updateFlag].turn } };
      return newObj;
    });
  }

  useEffect(() => {
    if (sessionId) {
      scrapyResponse();
    }
    // eslint-disable-next-line
  }, []);
  return { data, refresh };
};
export const SessionScrapyContentCount = (sessionId, projectId) => {
  const [data, setData] = useState({});

  const [getScrapyContentCount] = useLazyQuery(GET_SCRAPY_CONTENT_COUNT_BY_SESSION, {
    fetchPolicy: "cache-first",
  });

  const scrapyResponse = async () => {
    if (sessionId) {
      const res = await getScrapyContentCount({
        variables: {
          payload: {
            session_id: sessionId,
            projectId,
          },
        },
      });
      if (res && res.data && res.data.GetScrapyContentCounts) {
        setData({ ...res.data.GetScrapyContentCounts });
      } else {
        setData({});
      }
    }
  };
  function getContent(updateFlag, key) {
    setData(prev => {
      const newObj = { ...prev, [key]: { ...prev[key], [updateFlag]: { ...prev[key][updateFlag], turn: !prev[key][updateFlag].turn } } };
      return newObj;
    });

    // return scrapyResponse();
  }
  useEffect(() => {
    if (sessionId) {
      scrapyResponse();
    }
    // eslint-disable-next-line
  }, [sessionId]);

  return { data, getContent };
};

export const SessionScrapySocialTagCount = (sessionId, projectId) => {
  const [data, setData] = useState({});
  const { allQuery } = useQuery();
  const [getScrapySocialtagCount] = useLazyQuery(GET_SCRAPY_SOCIAL_TAG_COUNT_BY_SESSION, {
    fetchPolicy: "cache-first",
  });

  const scrapyResponse = async () => {
    if (sessionId) {
      allQuery({
        QUERY: getScrapySocialtagCount,
        variables: {
          payload: {
            session_id: sessionId,
            projectId,
          },
        },
        onCompleteFunc: res => {
          if (res?.GetSocialTagsCounts) {
            setData({ ...res.GetSocialTagsCounts });
          } else {
            setData({});
          }
        },
      });
    }
  };
  function getScrapySocialtag(updateFlag) {
    setStateFunc(setData, updateFlag);
  }
  useEffect(() => {
    if (sessionId) {
      scrapyResponse();
    }
    // eslint-disable-next-line
  }, [sessionId]);

  return { data, getScrapySocialtag };
};

export const SessionScrapyImageTagCount = (sessionId, projectId) => {
  const [data, setData] = useState({});

  const [getScrapyImageTagCount] = useLazyQuery(GET_IMAGES_TAG_COUNT_BY_SESSION, {
    fetchPolicy: "cache-first",
  });

  const scrapyResponse = async () => {
    if (sessionId) {
      const res = await getScrapyImageTagCount({
        variables: {
          payload: {
            session_id: sessionId,
            projectId,
          },
        },
      });
      if (res && res.data && res.data.GetImagesCounts) {
        setData({ ...res.data.GetImagesCounts });
      } else {
        setData({});
      }
    }
  };
  function getScrapyImageTag(updateFlag) {
    setStateFunc(setData, updateFlag);
    // return scrapyResponse();
  }
  useEffect(() => {
    if (sessionId) {
      scrapyResponse();
    }
    // eslint-disable-next-line
  }, [sessionId]);

  return { data, getScrapyImageTag };
};

export const SessionScrapyJavascriptCount = (sessionId, projectId) => {
  const [data, setData] = useState({});

  const [getScrapyJavascriptCount] = useLazyQuery(GET_SCRAPY_JAVASCRIPT_COUNT_BY_SESSION, {
    fetchPolicy: "cache-first",
  });

  const scrapyResponse = async () => {
    if (sessionId) {
      const res = await getScrapyJavascriptCount({
        variables: {
          payload: {
            session_id: sessionId,
            projectId,
          },
        },
      });
      if (res && res.data && res.data.GetJsCount) {
        setData({ ...res.data.GetJsCount });
      } else {
        setData({});
      }
    }
  };
  function QueryJsScrapy(updateFlag) {
    setStateFunc(setData, updateFlag);
  }
  useEffect(() => {
    if (sessionId) {
      scrapyResponse();
    }
    // eslint-disable-next-line
  }, [sessionId]);

  return { data, QueryJsScrapy };
};

export const SessionScrapyCssCount = (sessionId, projectId) => {
  const [data, setData] = useState({});

  const [getScrapyCssCount] = useLazyQuery(GET_SCRAPY_CSS_COUNT_BY_SESSION, {
    fetchPolicy: "cache-first",
  });

  const scrapyResponse = async () => {
    if (sessionId) {
      const res = await getScrapyCssCount({
        variables: {
          payload: {
            session_id: sessionId,
            projectId,
          },
        },
      });
      if (res && res.data && res.data.GetCssCount) {
        setData({ ...res.data.GetCssCount });
      } else {
        setData({});
      }
    }
  };
  function QueryCssScrapy(updateFlag) {
    setStateFunc(setData, updateFlag);
  }

  useEffect(() => {
    if (sessionId) {
      scrapyResponse();
    }
    // eslint-disable-next-line
  }, [sessionId]);

  return { data, QueryCssScrapy };
};

export const SessionScrapyExternalPagesCount = (sessionId, projectId) => {
  const [data, setData] = useState({});

  const [getScrapyExternalPagesCount] = useLazyQuery(GET_SCRAPY_EXTERNAL_PAGES_COUNT_BY_SESSION, {
    fetchPolicy: "cache-first",
  });

  const scrapyResponse = async () => {
    if (sessionId) {
      const res = await getScrapyExternalPagesCount({
        variables: {
          payload: {
            session_id: sessionId,
            projectId,
          },
        },
      });
      if (res && res.data && res.data.GetExternalPagesCount) {
        setData({ ...res.data.GetExternalPagesCount });
      } else {
        setData({});
      }
    }
  };
  function QueryExtrnalPageScrapy(updateFlag) {
    setStateFunc(setData, updateFlag);
  }
  useEffect(() => {
    if (sessionId) {
      scrapyResponse();
    }
    // eslint-disable-next-line
  }, [sessionId]);

  return { data, QueryExtrnalPageScrapy };
};

export const PageSpeedCreate = () => {
  const [PAGE_SPEED] = useMutation(pageSpeed);
  const CreatePageSpeed = async (authorId, id, website, sessionId) => {
    const newdata = await PAGE_SPEED({
      variables: {
        data: {
          userId: authorId,
          projectId: id,
          website,
          sessionId,
        },
      },
      onCompleted: res => {
        const { PageSpeed } = res || {};
        return PageSpeed;
      },
      onError: errdata => errdata,
    });
    return newdata;
  };

  return { CreatePageSpeed };
};

export const GetSpeedData = projectid => {
  const [PageSpeedData, setPageSpeedData] = useState({});

  const [getPageSpeed] = useLazyQuery(SPEED_DATA, { fetchPolicy: "cache-first" });

  const SpeedResponse = async () => {
    try {
      if (projectid) {
        const res = await getPageSpeed({
          variables: {
            getPageSpeedId: projectid,
          },
        });
        if (res && res.data) {
          setPageSpeedData({ ...res.data });
        } else {
          setPageSpeedData({});
        }
      }
    } catch (e) {
      console.log(e, "GetGraphCountData");
    }
  };

  useEffect(() => {
    if (projectid) {
      SpeedResponse();
    }
    // eslint-disable-next-line
  }, [projectid]);
  return { PageSpeedData };
};

export const GetGraphCountData = (projectId, requireNumberData) => {
  const [countData, setCountData] = useState({});

  const [getGraphIssuesCount] = useLazyQuery(ISSUES_DATA, { fetchPolicy: "cache-first" });

  const CountIssueResponse = async () => {
    try {
      if (projectId) {
        const res = await getGraphIssuesCount({
          variables: { getGraphIssueCountId: projectId, requireNumberData },
        });
        if (res && res.data) {
          setCountData({ ...res.data });
        } else {
          setCountData({});
        }
      }
    } catch (e) {
      console.log(e, "GetGraphCountData");
    }
  };

  useEffect(() => {
    if (projectId) {
      CountIssueResponse();
    }
    // eslint-disable-next-line
  }, [projectId]);
  return { countData };
};

export const GetSpeedDataReport = projectId => {
  const [PageSpeedDatareport, setPageSpeedDatareport] = useState({});
  const [getPageSpeedreport] = useLazyQuery(speedDataReport, { fetchPolicy: "cache-first" });
  const SpeedResponse = async () => {
    try {
      if (projectId) {
        const res = await getPageSpeedreport({
          variables: {
            projectId,
          },
        });
        if (res && res.data) {
          setPageSpeedDatareport({ ...res.data });
        } else {
          setPageSpeedDatareport({});
        }
      }
    } catch (e) {
      console.log(e, "GetSpeedDataReport");
    }
  };

  useEffect(() => {
    if (projectId) {
      SpeedResponse();
    }
    // eslint-disable-next-line
  }, [projectId]);
  return { PageSpeedDatareport };
};

export const SessionScrapyLinkCount = (sessionId, projectId) => {
  const [data, setData] = useState({});
  const [getScrapyLinkCount] = useLazyQuery(GET_SCRAPY_COUNT_LINK, {
    fetchPolicy: "cache-first",
  });
  const scrapyResponse = async () => {
    if (sessionId) {
      const res = await getScrapyLinkCount({
        variables: {
          payload: {
            session_id: sessionId,
            projectId,
          },
        },
      });
      if (res && res.data && res.data.GetCanonicalIncomingLinks) {
        setData({ ...res.data.GetCanonicalIncomingLinks });
      } else {
        setData({});
      }
    }
  };
  function getScrapyLinkRefresh(updateFlag, key) {
    setData(prev => {
      const newObj = { ...prev, [key]: { ...prev[key], [updateFlag]: { ...prev[key][updateFlag], turn: !prev[key][updateFlag].turn } } };
      return newObj;
    });
  }
  useEffect(() => {
    if (sessionId) {
      scrapyResponse();
    }
    // eslint-disable-next-line
  }, []);

  return { data, getScrapyLinkRefresh };
};
export const SessionScrapyDuplicateCount = (sessionId, projectId) => {
  const [data, setData] = useState({});
  const [getScrapyDuplicateCount] = useLazyQuery(GET_SCRAPY_DUPLICATE_COUNT_BY_SESSION, {
    fetchPolicy: "cache-first",
  });
  const scrapyResponse = async () => {
    if (sessionId) {
      const res = await getScrapyDuplicateCount({
        variables: {
          payload: {
            session_id: sessionId,
            projectId,
          },
        },
      });
      if (res && res.data && res.data.GetDuplicateQuery) {
        setData(...res.data.GetDuplicateQuery);
      } else {
        setData({});
      }
    }
  };
  function getScrapyDuplicateRefresh() {
    const dataClone = { ...data };
    const check = { ...dataClone, duplicate: { ...dataClone.duplicate, turn: !dataClone.duplicate.turn } };
    setData(check);
  }
  useEffect(() => {
    if (sessionId) {
      scrapyResponse();
    }
    // eslint-disable-next-line
  }, []);

  return { data, getScrapyDuplicateRefresh };
};

export const GetTotalReportCount = () => {
  const [reportCountData, setReportCountData] = useState(0);
  const { userData } = useSelector(state => state.reducer);
  const { id } = userData || {};
  const [GET_REPORT_COUNT] = useLazyQuery(reportCount, {
    fetchPolicy: "network-and-cache",
  });
  const getReportCount = async Id => {
    const res = await GET_REPORT_COUNT({
      variables: {
        reportCountId: Id,
      },
    });
    if (res && res.data && res.data.reportCount) {
      setReportCountData(res.data.reportCount);
    }
  };

  useEffect(() => {
    if (id) {
      getReportCount(id);
    }
    // eslint-disable-next-line
  }, [id]);

  return { reportCountData };
};

export const SessionScrapyRedirectCount = (sessionId, projectId) => {
  const [data, setData] = useState({});
  const [getScrapyRedirectCount] = useLazyQuery(GET_SCRAPY_REDIRECT_COUNT_BY_SESSION, {
    fetchPolicy: "cache-first",
  });
  const scrapyResponse = async () => {
    if (sessionId) {
      const res = await getScrapyRedirectCount({
        variables: {
          payload: {
            session_id: sessionId,
            projectId,
          },
        },
      });
      if (res && res.data && res.data.GetRedirectCount) {
        setData(res.data.GetRedirectCount);
      } else {
        setData({});
      }
    }
  };
  function getScrapyRedirectRefresh(updateFlag) {
    setStateFunc(setData, updateFlag);
    // const dataClone = { ...data };
    // const check = { ...dataClone, duplicate: { ...dataClone.duplicate, turn: !dataClone.duplicate.turn } };
    // setData(check);
  }
  useEffect(() => {
    if (sessionId) {
      scrapyResponse();
    }
    // eslint-disable-next-line
  }, []);
  return { data, getScrapyRedirectRefresh };
};

export const CrawlingReport = sessionId => {
  const [crawlData, setCrawlData] = useState([]);
  const [crawlingReportQuery] = useLazyQuery(CRAWLING_REPORT, {
    fetchPolicy: "network-only",
  });
  const CrawlProgress = async () => {
    if (sessionId) {
      crawlingReportQuery({
        variables: {
          sessionId,
        },
        onCompleted: ({ GetSingleReportDetail }) => {
          setCrawlData(GetSingleReportDetail);
        },
        onError: err => {
          console.log("onError", err);
        },
      });
    }
  };

  useEffect(() => {
    CrawlProgress();
  }, [sessionId]);

  function reFresh() {
    if (sessionId) {
      CrawlProgress();
    }
  }
  return { crawlData, reFresh, setCrawlData };
};

export const GetCrawlProgessData = sessionId => {
  const { userData } = useSelector(state => state.reducer);
  const { id } = userData || {};
  const [listData, setListData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [cancelButtonDisplayed, setCancelButtonDisplayed] = useState(false);
  const [allUrlcount, setAllUrlCount] = useState(0);
  const [bannerUrlcount, setBannerUrlCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  // const [deletedData, setDeletedData] = useState({});
  const { handleOpen } = usePopup();
  const [ALL_SCRAPY_SESSION] = useLazyQuery(CRAWLPROGRESS_SCRAPY_DATA, { fetchPolicy: "network-only" });
  const [DELETE_SCRAPY_SESSION] = useLazyQuery(DELETE_REPORT, { fetchPolicy: "cache-first" });
  const client = useApolloClient();
  const { allQuery } = useQuery();

  const getCrawlProgressWithLimit = () => {
    allQuery({
      QUERY: ALL_SCRAPY_SESSION,
      variables: { page: pageNumber, sessionId, filterArr: selectedStatus },
      onCompleteFunc: data => {
        const { GetCrawlProgressReport } = data || {};
        const { reportDetail, allUrlCount, totalUrlCount } = GetCrawlProgressReport || {};
        setListData(reportDetail);
        if (bannerUrlcount < totalUrlCount) setBannerUrlCount(totalUrlCount);
        setAllUrlCount(allUrlCount);
        setLoader(false);
      },
      onErrorFunc: err => {
        console.log({ err });
        setLoader(false);
      },
    });
  };
  useEffect(() => {
    if (sessionId) {
      getCrawlProgressWithLimit(sessionId);
    }
  }, [sessionId, pageNumber, selectedStatus]);

  const reFreshDetails = () => {
    if (sessionId) {
      getCrawlProgressWithLimit(sessionId);
    }
  };
  const cancelCreateReport = async () => {
    setIsLoading(true);
    setCancelButtonDisplayed(true);
    try {
      const { data } = await DELETE_SCRAPY_SESSION({
        variables: {
          sessionId,
        },
      });

      if (data && data.DeleteProcessReport) {
        const deletedData = data.DeleteProcessReport;
        try {
          const scrapyData = await client.readQuery({
            query: SCRAPY_DATA,
            variables: { params: id },
          });
          const newScrapyData = [deletedData, ...(scrapyData?.userWebVisitList || [])];
          await client.writeQuery({
            query: SCRAPY_DATA,
            variables: { params: id },
            data: { userWebVisitList: newScrapyData },
          });
        } catch (error) {
          console.error("Failed to update SCRAPY_DATA cache:", error);
        }

        try {
          const singleSessionData = await client.readQuery({
            query: SINGLE_SCREAPYSESSION_DATA,
            variables: { sessionId },
          });

          if (!singleSessionData || !singleSessionData.GetSingleScreapySessionData) {
            throw new Error("Single session data not found");
          }

          const updatedSingleSessionData = {
            ...singleSessionData.GetSingleScreapySessionData,
            isCanceled: true,
            isCompleted: false,
            isFailed: false,
          };
          await client.writeQuery({
            query: SINGLE_SCREAPYSESSION_DATA,
            variables: { sessionId },
            data: { GetSingleScreapySessionData: updatedSingleSessionData },
          });
        } catch (error) {
          console.error("Failed to update SINGLE_SCREAPYSESSION_DATA cache:", error);
        }

        setIsLoading(false);
        setCancelButtonDisplayed(false);
        handleOpen(true, "Your report canceled successfully");
      }
    } catch (error) {
      setIsLoading(false);
      setCancelButtonDisplayed(false);
      handleOpen(true, error?.message || "Error canceling the report");
      console.error("Failed to cancel report generation:", error);
    }
  };

  return {
    cancelButtonDisplayed,
    listData,
    loader,
    pageNumber,
    isLoading,
    setPageNumber,
    allUrlcount,
    setListData,
    setAllUrlCount,
    reFreshDetails,
    cancelCreateReport,
    selectedStatus,
    setSelectedStatus,
    bannerUrlcount,
  };
};

export const CrawlingReportSubscription = sessionId => {
  const [crawlDataLive, setCrawlDataLive] = useState([]);
  const { handleOpen } = usePopup();
  const [crawlingSubscription] = useSubscription(CRAWLING_SUBSCRIPTION);
  const CrawlProgress = async () => {
    if (sessionId) {
      crawlingSubscription({
        variables: {
          sessionId,
        },
        onCompleted: data => {
          console.log("data", data);
          setCrawlDataLive(data);
        },
        onError: err => {
          console.log("onError", err);
          handleOpen(true, "Data Not Found");
        },
      });
    }
  };

  useEffect(() => {
    CrawlProgress();
  }, [sessionId]);

  return crawlDataLive;
};

export const ScreapySessionSingleData = sessionId => {
  const [sessionData, setSessionData] = useState(null);
  const [singleScreapyData] = useLazyQuery(SINGLE_SCREAPYSESSION_DATA, {
    fetchPolicy: "network-and-cache",
  });
  const CrawlProgress = async () => {
    if (sessionId) {
      singleScreapyData({
        variables: {
          sessionId,
        },
        onCompleted: ({ GetSingleScreapySessionData }) => {
          setSessionData(GetSingleScreapySessionData);
        },
        onError: err => {
          console.log("Error", err);
        },
      });
    }
  };

  useEffect(() => {
    CrawlProgress();
  }, [sessionId]);

  return { sessionData, setSessionData };
};

function checkStatus(data) {
  let status = "Completed";
  const { isCompleted, isDeleted, isFailed, isCanceled } = data || {};
  if (isDeleted) {
    status = "Deleted";
  } else if (isFailed) {
    status = "Failed";
  } else if (!isCompleted && !isFailed) {
    status = "Progress";
  } else if (isCanceled) {
    status = "Canceled";
  }
  console.log({ status });
  return status;
}

export const useAllReports = () => {
  const [reportsData, setReportsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [allReports] = useLazyQuery(ALL_REPORT, {
    fetchPolicy: "network-and-cache",
  });

  useEffect(() => {
    allReports({
      onCompleted: ({ getAllReports }) => {
        const newData = getAllReports.map((item, ind) => ({ ...item, id: ind + 1, status: checkStatus(item), createdAt: moment(item?.createdAt).format("DD/MM/YYYY, hh:mm a") }));
        setReportsData(newData);
        setLoading(false);
      },
      onError: err => {
        console.log(err);
        setLoading(false);
      },
    });
  }, []);

  return { reportsData, loading };
};
export const useAllTicket = (startDate, endDate) => {
  const [Data, setData] = useState([]);
  const [ticketCounts, setTicketCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const [allTickets] = useLazyQuery(GET_ALL_DATA_SUPERADMIN, {
    fetchPolicy: "network-and-cache",
  });
  const getAllTickes = () =>
    allTickets({
      variables: {
        timeStamp: {
          endDate: endDate || null,
          startDate: startDate || null,
        },
      },
      onCompleted: ({ getAllTicketsBySuperAdmin }) => {
        const { tickets, ticketCount } = getAllTicketsBySuperAdmin || {};
        setTicketCount(ticketCount);
        const newData = tickets.map((item, ind) => ({
          ...item,
          id: ind + 1,
          // status: checkStatus(item),
          createdAt: moment(item?.createdAt).format("DD/MM/YYYY, hh:mm a"),
        }));
        setData(newData);
        setLoading(false);
      },
      onError: err => {
        console.log(err);
        setLoading(false);
      },
    });
  useEffect(() => {
    getAllTickes();
    // eslint-disable-next-line
  }, []);

  return { Data, loading, getAllTickes, ticketCounts };
};

export const useAllSiteAuditCount = () => {
  const [siteAuditData, setSiteAuditData] = useState();
  const [loading, setLoading] = useState(true);

  const [allSiteAuditReports] = useLazyQuery(ALL_SITEAUDIT_COUNT, {
    fetchPolicy: "network-and-cache",
  });

  const getAllSiteAuditData = (startDate, endDate) => {
    allSiteAuditReports({
      variables: {
        startDate,
        endDate,
      },
      onCompleted: ({ GetAllSiteAuditReportsCount }) => {
        setSiteAuditData(GetAllSiteAuditReportsCount);
        setLoading(false);
      },
      onError: err => {
        console.log(err);
        setLoading(false);
      },
    });
  };
  useEffect(() => {
    getAllSiteAuditData();
  }, []);

  return { siteAuditData, loading, getAllSiteAuditData };
};

export const useAllSiteAuditData = (startDate, endDate, frequency) => {
  const [siteAuditDetails, setSiteAuditDetails] = useState();
  const [loading, setLoading] = useState(true);

  const [allSiteAuditReports] = useLazyQuery(ALL_SITEAUDIT_DATA, {
    fetchPolicy: "network-and-cache",
  });

  useEffect(() => {
    allSiteAuditReports({
      variables: {
        startDate,
        endDate,
        frequency,
      },
      onCompleted: ({ GetSiteAuditReportsData }) => {
        setSiteAuditDetails(GetSiteAuditReportsData);
        setLoading(false);
      },
      onError: err => {
        console.log(err);
        setLoading(false);
      },
    });
  }, []);

  return { siteAuditDetails, loading };
};
