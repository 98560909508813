/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import BackButton from "../BackButton/BackButton";
import CreateProjectLink from "../createProject/CreateProjectLink";
import SearchBar from "../common/SearchBar";
import WebsiteReportDetail from "./WebsiteReportDetail";
import { useLazyQuery, useMutation } from "@apollo/client";
import { LIGHT_HOUSE, LIGHTHOUSE_SINGLE_REPORT } from "../../gql/queries/scrapy";
import { Typography, Container, styled, useTheme, Grid, Card, Box, Button } from "@mui/material";
import { LightHouseReport } from "./WebsiteUrlList";
import { usePopup } from "../../context/commonPop";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";

const PerformanceSection = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  backgroundColor: theme.palette.background.default,
  // paddingBottom: "60px",
  "& .SearchTopBarWrapper": {
    "& .SearchBarWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "15px",
      "@media screen and (max-width:600px)": {
        flexDirection: "column",
      },
      "& button": {
        textTransform: "none",
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        borderRadius: "5px",
        boxShadow: "none",
        padding: "6px 22px",
        "@media screen and (max-width:600px)": {
          width: "100%",
        },
        "&:focus": {
          outline: "0",
        },
      },
    },
  },
  "& .BtnTopWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    "@media screen and (max-width:600px)": {
      flexDirection: "column",
    },
  },
}));

function PerformanceDetail() {
  const { Title, Heading, index, projectId } = useParams();
  const [lightData, setLightData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [dataFetch, setDataFetch] = useState(false);
  const [search, setSearch] = useState(null);
  const [lightHouse] = useLazyQuery(LIGHT_HOUSE);
  const [showReport, SetShowReport] = useState(false);
  const [reportProgress, setReportProgress] = useState(false);
  const [disableReports, setDisableReports] = useState(false);
  const [reportParams, setReportParams] = useState({
    url: null,
    projectId: null,
  });
  const [loaderMap, setLoaderMap] = useState({});
  const customizer = useSelector(state => state.customizer);
  const [ligthHouseSingleReport] = useMutation(LIGHTHOUSE_SINGLE_REPORT);
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const { handleOpen } = usePopup();
  const isMobile = searchParams.get("tab") === "Mobile";

  const findSearchValue = () => {
    const subString = search && search.trim().length > 0 ? search.trim() : "";
    const filterArr = filterData.filter(project => project.url.toLowerCase().includes(subString));
    if (filterArr && filterArr.length > 0) return setLightData(filterArr);

    // return;
  };
  useEffect(() => {
    lightData && lightData.length > 0 && findSearchValue();
  }, [search]);

  const debouncedHandleChange = useCallback(
    debounce(value => {
      setSearch(value);
    }, 300),
    [],
  );

  const handleChange = e => {
    debouncedHandleChange(e.target.value);
  };

  useEffect(() => () => debouncedHandleChange.cancel(), [debouncedHandleChange]); // eslint-disable-line

  useEffect(() => {
    lightHouse({
      variables: { projectId },
      onCompleted: ({ AverageReport }) => {
        AverageReport && AverageReport.allUrlData && setLightData(AverageReport.allUrlData[`${Title}${Heading}${index}`]);
        AverageReport && AverageReport.allUrlData && setFilterData(AverageReport.allUrlData[`${Title}${Heading}${index}`]);
      },
      onError: err => {
        console.log("err", err);
      },
    });
  }, [lightHouse, projectId, reportProgress, dataFetch]);

  const viewReport = url => {
    setReportParams({ url, projectId });
    SetShowReport(true);
  };

  const handleGoBack = () => {
    SetShowReport(false);
  };

  const handleClick = ({ url, id }) => {
    setLoaderMap(prevLoaderMap => ({ ...prevLoaderMap, [url]: true }));
    // setReportProgress(prev => !prev);
    setDisableReports(true);
    ligthHouseSingleReport({
      variables: { projectId, url, id },
      onCompleted: data => {
        setLoaderMap(prevLoaderMap => ({ ...prevLoaderMap, [url]: false }));
        setDataFetch(prevData => !prevData);
        setReportProgress(prev => !prev);
        setDisableReports(false);
      },
      onError: err => {
        setLoaderMap(prevLoaderMap => ({ ...prevLoaderMap, [url]: false }));
        setReportProgress(prev => !prev);
        setDisableReports(false);
        handleOpen(true, JSON.stringify(err.message));
      },
    });
  };

  return (
    <PerformanceSection>
      {showReport ? (
        <LightHouseReport handleGoBack={handleGoBack} reportParams={reportParams} isMobile={isMobile} />
      ) : (
        <Container maxWidth="">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} md={12}>
                <Card
                  sx={{
                    padding: "16px 16px 16px 16px",
                    border: `1px solid ${theme.palette.divider}`,
                    boxShadow: "none !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "15px",
                    "@media screen and (max-width:600px)": {
                      flexDirection: "column",
                      alignItems: "stretch",
                    },
                  }}
                >
                  <Box className="BtnTopWrapper">
                    <BackButton sx={{ "& button": { marginBottom: "0 !important" } }} />
                    <CreateProjectLink />
                  </Box>
                  <Box className="SearchTopBarWrapper">
                    <Box className="SearchBarWrapper">
                      <SearchBar handleChange={handleChange} />
                      <Button>Search</Button>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            {lightData && lightData.length ? (
              <Grid
                container
                spacing={2}
                mt={0}
                sx={{
                  maxHeight: "calc(100vh - 175px)",
                  overflow: "auto",
                  mt: "16px",
                  "&::-webkit-scrollbar-track": {
                    borderRadius: "10px",
                    backgroundColor: theme.palette.text.primary + "0d",
                  },
                  "&::-webkit-scrollbar": { width: "8px", backgroundColor: "transparent" },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "10px",
                    WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
              >
                {lightData.map((row, ind) => {
                  return (
                    <Grid item xs={12} md={12} key={ind} sx={{ "&:first-of-type": { pt: "0 !important" } }}>
                      <Card
                        sx={{
                          padding: "16px 16px 16px 16px",
                          border: `1px solid ${theme.palette.divider}`,
                          boxShadow: "none !important",
                        }}
                      >
                        <WebsiteReportDetail row={row} viewReport={viewReport} handleClick={handleClick} disableReports={disableReports} loaderMap={loaderMap} />
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Grid container spacing={2} mt={0}>
                <Grid item xs={12} md={12}>
                  <Typography
                    component="p"
                    sx={{
                      color: theme.palette.text.primary,
                      fontWeight: 700,
                      textAlign: "center",
                      width: "100%",
                      height: "calc(100vh - 175px)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    No Url Found
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        </Container>
      )}
    </PerformanceSection>
  );
}

export default PerformanceDetail;
