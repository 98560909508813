import "react-circular-progressbar/dist/styles.css";
import { useTheme } from "@mui/material/styles";
import { lazy, memo, Suspense, useMemo } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Box, Typography, styled } from "@mui/material";
import DashboardCard from "../../shared/DashboardCard";
import { convertISOToDateFormat, GetScore } from "../../../utils/myUtills";
const Chart = lazy(() => import("react-apexcharts"));

const BoxWrapper = styled(Box)(({ theme }) => ({
  height: "100%",
  "& .healthWrapper": { filter: "blur(2px)", pointerEvents: "none" },
  "& .GraphWrapper": {
    width: 200,
    height: 200,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    "& span.Status": {
      position: "absolute",
      bottom: "14px",
      left: "0",
      right: "0",
      textAlign: "center",
      // "& span": { backgroundColor: "#34a734", color: "#fff", padding: "2px 10px 3px", borderRadius: "6px" },
    },
  },
  "& .Subtitle": { textAlign: "center" },
  "& .CircularProgressbar-path": { stroke: theme.palette.primary.main },
  "& .CircularProgressbar-text": { fill: theme.palette.text.primary, fontSize: "22px" },
  "& .HealthLineChart": {
    // "& g.apexcharts-graphical": { transform: "translate(22px,0px)" },
  },
  "& .redScore": {
    width: "max-content",
    margin: "auto",
    display: "block",
    backgroundColor: "rgb(234, 82, 29)",
    color: "#fff",
    padding: "2px 10px 3px",
    borderRadius: "6px",
    marginBottom: "5px",
    marginTop: "-30px",
    fontWeight: "600 ",
  },
  "& .yellowScore": {
    width: "max-content",
    margin: "auto",
    display: "block",
    backgroundColor: "rgb(255, 215, 0)",
    color: "#000",
    padding: "2px 10px 3px",
    borderRadius: "6px",
    marginBottom: "5px",
    marginTop: "-30px",
    fontWeight: 600,
  },
  "& .greenScore": {
    width: "max-content",
    margin: "auto",
    display: "block",
    backgroundColor: "green",
    color: "#fff",
    padding: "2px 10px 3px",
    borderRadius: "6px",
    marginBottom: "5px",
    fontWeight: 600,

    marginTop: "-30px",
  },
  "& .noneScore": {
    width: "max-content",
    margin: "auto",
    display: "block",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    padding: "2px 10px 3px",
    borderRadius: "6px",
    marginBottom: "5px",
    marginTop: "-30px",
    fontWeight: 600,
  },
}));

const formattedDate = createdAt => {
  const { date } = convertISOToDateFormat(createdAt);
  const { formattedDay, formattedMonth } = date || {};
  return `${formattedDay}/${formattedMonth}`;
};

function HealthScoreOverview({ ProjectData }) {
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const { GetGraphIssueCount = [] } = ProjectData || {};
  const optionscolumnchart = useMemo(() => {
    const categoriesToStore =
      Array.isArray(GetGraphIssueCount) && GetGraphIssueCount.length
        ? GetGraphIssueCount.map(item => (item.websiteScore ? formattedDate(item?.createdAt) : undefined))
            .reverse()
            .filter(Boolean)
        : ["01/01", "01/01", "01/01", "01/01", "01/01", "01/01", "01/01", "01/01", "01/01", "01/01"];

    return {
      chart: {
        type: "bar",
        fontFamily: "'Plus Jakarta Sans', sans-serif;",
        foreColor: "#adb0bb",
        toolbar: {
          show: false,
        },
        height: 500,
      },
      colors: [primary],
      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: "80%",
          distributed: true,
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      xaxis: {
        categories: categoriesToStore,
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      tooltip: {
        theme: theme.palette.mode === "dark" ? "dark" : "light",
      },
    };
  }, [GetGraphIssueCount, theme, primary]);

  const seriescolumnchart = useMemo(() => {
    const dataToStore =
      Array.isArray(GetGraphIssueCount) && GetGraphIssueCount.length
        ? (GetGraphIssueCount || [])
            .map(item => (item?.websiteScore ? item?.websiteScore : undefined))
            .reverse()
            .filter(Boolean)
        : [10, 10, 10, 10, 10, 10, 10, 10, 19, 10];

    return [{ name: "", data: dataToStore }];
  }, [GetGraphIssueCount]);
  const healthScore = Array.isArray(GetGraphIssueCount) && GetGraphIssueCount.length ? GetScore(GetGraphIssueCount) : 75;
  return (
    <BoxWrapper>
      <DashboardCard title="Health Score" subtitle="Overview of Health Score" ClassName="HeightFull">
        <Box className={`GraphWrapper ${healthScore ? "" : "healthWrapper"}`}>
          <CircularProgressbar
            value={healthScore}
            text={`${healthScore}`}
            circleRatio={0.75}
            strokeWidth={20}
            styles={buildStyles({
              rotation: 1 / 2 + 1 / 8,
              strokeLinecap: "butt",
              trailColor: "#eee",
              path: {
                stroke: "#000",
              },
              text: {
                // Text color
                fill: "#f88 !important",
                // Text size
                fontSize: "5px",
              },
            })}
          />
          <Typography component="span" className="Status">
            {GetGraphIssueCount.length && (
              <Typography
                component="span"
                className={healthScore <= 40 ? "redScore" : healthScore > 40 && healthScore <= 75 ? "yellowScore" : healthScore > 75 ? "greenScore" : "noneScore"}
              >
                {healthScore >= 90
                  ? "Excellent"
                  : healthScore >= 75 && healthScore < 90
                  ? "Very Good"
                  : healthScore >= 60 && healthScore < 75
                  ? "Good"
                  : healthScore >= 50 && healthScore < 60
                  ? "Fair"
                  : "Poor"}
              </Typography>
            )}
          </Typography>
        </Box>
        <Typography variant="subtitle2" color="textSecondary" className="Subtitle">
          Health Score reflects the proportion of internal URLs on your site that don&apos;t have errors
        </Typography>
        <Box className="HealthLineChart">
          <Suspense fallback={<div>Loading...</div>}>
            <Chart
              className={` ${healthScore ? "healthWrappernoblr" : "healthWrapper"}`}
              options={optionscolumnchart}
              series={seriescolumnchart}
              type="bar"
              width="100%"
              height="200px"
            />
          </Suspense>
        </Box>
      </DashboardCard>
    </BoxWrapper>
  );
}

export default memo(HealthScoreOverview);
