/*eslint-disable*/
import { InputBase, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "6px",
  backgroundColor: theme.palette.background.default,
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: "0px 16px 0px 10px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.mode === "dark" ? "#808080" : "#808080",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "300px",
  "& .MuiInputBase-input": {
    padding: "8px 8px 8px 40px",
    transition: theme.transitions.create("width"),
    width: "100%",
    "&::placeholder": {
      color: theme.palette.mode === "dark" ? "#808080" : "#808080",
      fontWeight: 500,
      opacity: 1,
    },
  },
}));
function SearchBar({ handleChange }) {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase placeholder="Search site" inputProps={{ "aria-label": "Search site" }} onChange={handleChange} />
    </Search>
  );
}
export default SearchBar;
