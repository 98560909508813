import gql from "graphql-tag";

export const uptimeStatus = gql`
  query Query($projectId: String!) {
    getUptimeStatus(projectId: $projectId)
  }
`;

export const getUptimeProject = gql`
  query GetUptime($projectId: String!) {
    GetUptime(projectId: $projectId) {
      domain
      isMonitoring
      projectId
      userId
      gmails
    }
  }
`;

export const getGraphValueOfDowntime = gql`
  query Query($projectId: String!, $timeStamp: SuperAdmintimeStamp!) {
    getDownTimeGraphValue(projectId: $projectId, timeStamp: $timeStamp)
  }
`;
