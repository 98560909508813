/* eslint-disable */
import { FC, memo } from "react";
import { Link } from "react-router-dom";
import { Box, styled } from "@mui/material";
// import { Image } from "@mui/icons-material";
import { useSelector } from "react-redux";
// import lightlogoImage from "../../../../Images/hotSportLogo.webp";
// import darklogoImage from "../../../../Images/hotSportLogo.webp";
import rtldarklogoImage from "../../../../Images/hotSportLogo.webp";
import rtllightimage from "../../../../Images/hotSportLogo.webp";
import lightlogoImage from "../../../../Images/hotSpotLogoWhite.svg";
import darklogoImage from "../../../../Images/hotSpotLogoBlue.svg";
import hs from "../../../../Images/hs.png";
import hswhite from "../../../../Images/hswhite.png";

function Logo({ path = "/" }) {
  const customizer = useSelector(state => state.customizer);
  const LinkStyled = styled(Link)(() => ({
    overflow: "hidden",
    display: "block",
    textAlign: "center",
    "& img": { width: "90px" },
    "& .hslogo": {
      width: "37px",
    },
    "& .decreaseWidth": {
      width: `${customizer.isSidebarHover ? "90px" : "37px"}`,
      "@media screen and (max-width:600px)": {
        width: "90px",
      },
    },
  }));

  if (customizer.activeDir === "ltr") {
    return (
      <Box sx={{ height: "75px", padding: " 5px 0" }}>
        <LinkStyled to={path}>
          {customizer.isCollapse ? (
            customizer.isSidebarHover ? (
              <img src={customizer.activeMode === "dark" ? lightlogoImage : darklogoImage} alt="logo" priority="true" />
            ) : (
              <img className="hslogo" src={customizer.activeMode === "dark" ? hswhite : hs} alt="logo" />
            )
          ) : (
            <img src={customizer.activeMode === "dark" ? lightlogoImage : darklogoImage} alt="logo" priority="true" className="SSS" />
          )}{" "}
        </LinkStyled>
      </Box>
    );
  }

  return (
    <LinkStyled to={path}>
      {customizer.activeMode === "dark" ? (
        <img src={rtldarklogoImage} alt="logo" height={customizer.TopbarHeight} width={174} priority="true" />
      ) : (
        <img src={rtllightimage} alt="logo" height={customizer.TopbarHeight} width={174} priority="true" />
      )}
    </LinkStyled>
  );
}

export default memo(Logo);
