import { useState } from "react";
import { Divider, Box, styled, Container, Grid } from "@mui/material";
import ChatSidebar from "./ChatSidebar";
import ChatContent from "./ChatContent";
import ChatMsgSent from "./ChatMsgSent";
import AppCard from "../shared/AppCard";
// import Breadcrumb from "../shared/Breadcrumb";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& h4,h6": {
    color: theme.palette.text.primary,
    fontWeight: 700,
  },
}));
function Chats() {
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [chatupdate, setChatupdate] = useState(false);

  const onChatUpdate = () => setChatupdate(!chatupdate);

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} className="chatApp">
              {/* <Breadcrumb title="Chat app" subtitle="Messenger" /> */}
              <AppCard ClassName="mt-none mb-none">
                {/* ------------------------------------------- */}
                {/* Left part */}
                {/* ------------------------------------------- */}

                <ChatSidebar isMobileSidebarOpen={isMobileSidebarOpen} onSidebarClose={() => setMobileSidebarOpen(false)} />
                {/* ------------------------------------------- */}
                {/* Right part */}
                {/* ------------------------------------------- */}

                <Box flexGrow={1}>
                  <ChatContent chatupdate={chatupdate} toggleChatSidebar={() => setMobileSidebarOpen(true)} />
                  <Divider />
                  <ChatMsgSent onChatUpdate={onChatUpdate} />
                </Box>
              </AppCard>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default Chats;
