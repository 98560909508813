import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { lazy, Suspense } from "react";
import DashboardCard from "../../shared/DashboardCard";
const Chart = lazy(() => import("react-apexcharts"));

function Expence({ heading, val }) {
  const myval = Math.round(val * 100);
  const theme = useTheme();
  const error = theme.palette.error.main;
  const green = theme.palette.success.main;
  const yellow = theme.palette.warning.main;
  const lightWhite = theme.palette.grey[100];

  const optionsexpencechart = {
    chart: {
      type: "donut",
      fontFamily: "'Plus Jakarta Sans', sans-serif;",
      toolbar: {
        show: false,
      },
      height: 120,
    },
    labels: ["Performance", "issues"],
    colors: [error, lightWhite],
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
          background: "transparent",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      theme: theme.palette.mode === "dark" ? "dark" : "light",
      fillSeriesColor: false,
    },
  };

  if (myval >= 0 && myval <= 49) {
    optionsexpencechart.colors = [error, lightWhite];
  } else if (myval >= 50 && myval <= 89) {
    optionsexpencechart.colors = [yellow, lightWhite];
  } else {
    optionsexpencechart.colors = [green, lightWhite];
  }
  const seriesexpencechart = [myval, 100 - myval];

  return (
    <DashboardCard title={heading}>
      <Typography variant="h4">{myval}</Typography>
      <Suspense fallback={<div>Loading...</div>}>
        <Chart options={optionsexpencechart} series={seriesexpencechart} type="donut" height="120px" width="100%" />
      </Suspense>
    </DashboardCard>
  );
}

export default Expence;
