/* eslint-disable */

import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";

import { Button } from "@mui/material";

export default function RecipeReviewCard({ price, createSession }) {
  return (
    <Card sx={{ maxWidth: 345, backgroundColor: "lightblue", color: "white", height: "45vh" }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {price.nickname === "Basic" && "B"}
            {price.nickname === "Standard" && "S"}
            {price.nickname === "Premium" && "P"}
          </Avatar>
        }
        title={price.nickname}
      />
      <CardMedia component="img" height="194" image="https://www.zauca.in/wp-content/uploads/2017/10/planBasic.png" alt="Paella dish" />
      <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center" }}>
        <Typography variant="body2">
          {price.nickname === "Basic" && "Additional 5,000 Crawl limit."}
          {price.nickname === "Standard" && "Additional 12,000 Crawl limit."}
          {price.nickname === "Premium" && "Additional 20,000 Crawl limit."}
        </Typography>
        <Typography>Price: Rs. {price.unit_amount / 100}</Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ width: "100%" }}>
        <Button
          variant="contained"
          onClick={() => {
            createSession(price.id);
          }}
          sx={{ width: "100%" }}
        >
          Buy
        </Button>
      </CardActions>
    </Card>
  );
}
