/* eslint-disable */

const initialStateTicket = {
  tickets: [],
  currentFilter: "total_tickets",
  ticketSearch: "",
};

const ticketReducer = (state = initialStateTicket, action) => {
  switch (action.type) {
    case "GET_TICKETS":
      return {
        ...state,
        tickets: action.payload,
      };
    case "SET_VISIBILITY_FILTER":
      return {
        ...state,
        currentFilter: action.payload,
      };
    case "SEARCH_TICKET":
      return {
        ...state,
        ticketSearch: action.payload,
      };
    case "DELETE_TICKET":
      return {
        ...state,
        tickets: state.tickets.filter(ticket => ticket.Id !== action.payload),
      };
    default:
      return state;
  }
};

const initialState = {
  userData: {},
  name: "",
  role: "",
  theme: localStorage.getItem("theme"),
  isLogOut: false,
  ownwerSite: {},
  isOpenInvitation: false,
  loading: false,
  ticketId: "",
  loading: true,
};
const NOTE_URL = "/api/data/notes/NotesData";

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case "SAVE_WALLET":
      return {
        ...state,
        name: payload.name,
        role: payload.role,
      };
    case "SET_THEME":
      return {
        ...state,
        theme: payload,
      };
    case "LOGIN":
      return {
        ...state,
        userData: payload,
        loading: false,
      };
    case "SIGNUP":
      return {
        ...state,
        userData: payload,
      };
    case "UPDATEUSER":
      const userData = state.userData;
      return {
        ...state,
        userData: { ...userData, ...payload },
      };

    case "UPDATE_IMAGE": {
      const userData = state.userData;
      userData["imageUrl"] = payload.imageUrl;
      return { ...state, userData: userData };
    }
    case "UPDATE_CRAWL_LIMIT": {
      const userData = state.userData;
      userData["crawlLimit"] = userData.crawlLimit - payload.usedCrawl;
      console.log("userData after redux", userData);
      return { ...state, userData: userData };
    }
    // case 'IMAGE':
    // const { imageUrl } = payload;
    // const updatedUserData = {
    //   ...state.userData,
    //   imageUrl,
    // };

    // return {
    //   ...state,
    //   userData: updatedUserData,
    // };
    case "LOG_OUT":
      return {
        ...state,
        isLogOut: payload,
      };
    case "OWERNER":
      return {
        ...state,
        ownwerSite: payload,
      };
    case "INVITATION_POPUP":
      return {
        ...state,
        isOpenInvitation: payload,
      };
    case "SAVE_LOADING":
      return {
        ...state,
        loading: payload,
      };
    case "SET_TICKET":
      return {
        ...state,
        ticketId: payload,
      };
    default:
      return state;
  }
};

//new customizer reducers

const customizerInitialState = {
  activeDir: "ltr",
  activeMode: "light", // This can be light or dark
  activeTheme: "BLUE_THEME", // BLUE_THEME, GREEN_THEME, BLACK_THEME, PURPLE_THEME, ORANGE_THEME
  SidebarWidth: 270,
  MiniSidebarWidth: 87,
  TopbarHeight: 70,
  isLayout: "boxed", // This can be full or boxed
  isCollapse: false, // to make sidebar Mini by default
  isSidebarHover: false,
  isMobileSidebar: false,
  isHorizontal: false,
  isLanguage: "en",
  isCardShadow: true,
  borderRadius: 7,
};

const customizerReducer = (state = customizerInitialState, action) => {
  switch (action.type) {
    case "SET_THEME_CONFIG":
      return action.payload;

    case "SET_THEME":
      return {
        ...state,
        activeTheme: action.payload,
      };

    case "SET_DARK_MODE":
      return {
        ...state,
        activeMode: action.payload,
      };
    case "SET_DIR":
      return {
        ...state,
        activeDir: action.payload,
      };
    case "SET_LANGUAGE":
      return {
        ...state,
        isLanguage: action.payload,
      };
    case "SET_CARD_SHADOW":
      return {
        ...state,
        isCardShadow: action.payload,
      };
    case "TOGGLE_SIDEBAR":
      return {
        ...state,
        isCollapse: !state.isCollapse,
      };
    case "HOVER_SIDEBAR":
      return {
        ...state,
        isSidebarHover: action.payload,
      };
    case "TOGGLE_MOBILE_SIDEBAR":
      return {
        ...state,
        isMobileSidebar: !state.isMobileSidebar,
      };
    case "TOGGLE_LAYOUT":
      return {
        ...state,
        isLayout: action.payload,
      };
    case "TOGGLE_HORIZONTAL":
      return {
        ...state,
        isHorizontal: action.payload,
      };
    case "SET_BORDER_RADIUS":
      return {
        ...state,
        borderRadius: action.payload,
      };
    default:
      return state;
  }
};

const turned = {
  flagarr: [],
};

const flagReducer = (state = turned, action) => {
  switch (action.type) {
    case "ADD_FLAG":
      return {
        ...state,
        borderRadius: action.payload,
      };
    default:
      return state;
  }
};
export default {
  reducer,
  customizerReducer,
  ticketReducer,
};
