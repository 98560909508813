import { gql } from "graphql-tag";

export const GET_SCRAPY_BY_SESSION = gql`
  query Query($sessionId: String!) {
    GetScrapyBySession(sessionId: $sessionId) {
      google_analytics
      googletagmanager
      h1_tag_magento
      h1_tag_wordpress
      id
      image_data
      lengths_magento
      lengths_meta_description
      lengths_title
      lengths_wordpress
      meta_description
      meta_keyword
      object_id
      session_id
      title
      url
      alt_count
      canonical_tag
      css
      internal_links
      js
      internal_links_count
      og_description
      og_image
      og_title
      og_type
      og_url
      response_status
      robots_content
      src_count
      twitter_card
      twitter_description
      twitter_site
      twitter_title
    }
  }
`;

export const GET_SCRAPY_DUPLICATE_COUNT_BY_SESSION = gql`
  query Query($payload: scrapyPayload!) {
    GetDuplicateQuery(payload: $payload)
  }
`;
export const GET_SCRAPY_COUNT_BY_SESSION = gql`
  query Query($payload: scrapyPayload!) {
    GetScrapyCounts(payload: $payload)
  }
`;

export const GET_SCRAPY_INDEX_COUNT_BY_SESSION = gql`
  query Query($sessionId: String!) {
    GetScrapyIndexCounts(sessionId: $sessionId)
  }
`;

// export const GET_SCRAPY_CONTENT_COUNT_BY_SESSION = gql`
//   query Query($sessionId: String!) {
//     GetScrapyContentCounts(sessionId: $sessionId)
//   }
// `;
export const GET_SCRAPY_CONTENT_COUNT_BY_SESSION = gql`
  query Query($payload: scrapyPayload!) {
    GetScrapyContentCounts(payload: $payload)
  }
`;

// export const GET_SCRAPY_INTERNAL_PAGE_COUNT_BY_SESSION = gql`
//   query Query($sessionId: String!) {
//     GetMongooseInternalPagesCount(sessionId: $sessionId)
//   }
// `;
export const GET_SCRAPY_INTERNAL_PAGE_COUNT_BY_SESSION = gql`
  query Query($payload: scrapyPayload!) {
    GetMongooseInternalPagesCount(payload: $payload)
  }
`;

// export const GET_SCRAPY_SOCIAL_TAG_COUNT_BY_SESSION = gql`
//   query Query($sessionId: String!) {
//     GetSocialTagsCounts(sessionId: $sessionId)
//   }
// `;
export const GET_SCRAPY_SOCIAL_TAG_COUNT_BY_SESSION = gql`
  query Query($payload: scrapyPayload!) {
    GetSocialTagsCounts(payload: $payload)
  }
`;
// export const GET_IMAGES_TAG_COUNT_BY_SESSION = gql`
//   query Query($sessionId: String!) {
//     GetImagesCounts(sessionId: $sessionId)
//   }
// `;
export const GET_IMAGES_TAG_COUNT_BY_SESSION = gql`
  query Query($payload: scrapyPayload!) {
    GetImagesCounts(payload: $payload)
  }
`;
export const GET_SCRAPY_JAVASCRIPT_COUNT_BY_SESSION = gql`
  query Query($payload: scrapyPayload!) {
    GetJsCount(payload: $payload)
  }
`;

export const GET_SCRAPY_CSS_COUNT_BY_SESSION = gql`
  query Query($payload: scrapyPayload!) {
    GetCssCount(payload: $payload)
  }
`;

export const GET_SCRAPY_REDIRECT_COUNT_BY_SESSION = gql`
  query Query($payload: scrapyPayload!) {
    GetRedirectCount(payload: $payload)
  }
`;

export const GET_SCRAPY_EXTERNAL_PAGES_COUNT_BY_SESSION = gql`
  query Query($payload: scrapyPayload!) {
    GetExternalPagesCount(payload: $payload)
  }
`;
// export const GET_SCRAPY_COUNT_LINK = gql`
//   query Query($sessionId: String!) {
//     GetCanonicalIncomingLinks(sessionId: $sessionId)
//   }
// `;
export const GET_SCRAPY_COUNT_LINK = gql`
  query Query($payload: scrapyPayload!) {
    GetCanonicalIncomingLinks(payload: $payload)
  }
`;
export const SPEED_DATA = gql`
  query Query($getPageSpeedId: String!) {
    GetPageSpeed(id: $getPageSpeedId) {
      desktopSpeed
      mobileSpeed
    }
  }
`;

export const ISSUES_DATA = gql`
  query GetGraphIssueCount($getGraphIssueCountId: String!, $requireNumberData: Int!) {
    GetGraphIssueCount(id: $getGraphIssueCountId, requireNumberData: $requireNumberData) {
      websiteScore
      warnings
      sessionId
      domain
      createdAt
      isCompleted
      reason
      basicCompleted
      projectId
      issues
      notices
      isFailed
      project
      website
      id
      isCanceled
    }
  }
`;

export const speedDataReport = gql`
  query Query($projectId: String!) {
    GetPageSpeedreport(projectId: $projectId) {
      projectId
      sessionId
      userId
      website
      desktopSpeed
      desktopjson
      mobileSpeed
      mobilejson
      createdAt
      reportStatus
      isActiveReport
    }
  }
`;

export const GET_TURNOFF_STATUS = gql`
  query FlagTurnOff($payload: filterStatusInput!) {
    flagTurnOff(payload: $payload) {
      message
    }
  }
`;

export const LIGHT_HOUSE = gql`
  query Query($projectId: String!) {
    AverageReport(projectId: $projectId)
  }
`;
export const LIGHT_HOUSE_URL = gql`
  query Query($projectId: String!) {
    GetAllUrlWithStatus(projectId: $projectId)
  }
`;

export const LIGHTHOUSE_SINGLE_REPORT = gql`
  mutation Mutation($projectId: String!, $url: String!, $id: String!) {
    lightHouseSingleReport(projectId: $projectId, url: $url, id: $id)
  }
`;

export const CRAWLING_REPORT = gql`
  query Query($sessionId: String!) {
    GetSingleReportDetail(sessionId: $sessionId)
  }
`;

export const SCREAPY_LATEST_REPORT = gql`
  query Query($projectId: String!) {
    screapyLatestReport(projectId: $projectId)
  }
`;

export const CRAWLING_SUBSCRIPTION = gql`
  subscription Subscription($sessionId: String!) {
    documentUpdated(sessionId: $sessionId)
  }
`;

export const REPORT_PROGRESS_TRUE = gql`
  query Query($lightHouseUpdateSingleReportId: String!) {
    lightHouseUpdateSingleReport(id: $lightHouseUpdateSingleReportId)
  }
`;

export const GET_ALL_PROJECT_WITH_LIGHT_HOUSE_AVG = gql`
  query Query($search: String!) {
    getProjectWithLightHouseData(search: $search)
  }
`;

export const SINGLE_SCREAPYSESSION_DATA = gql`
  query Query($sessionId: String!) {
    GetSingleScreapySessionData(sessionId: $sessionId) {
      authorId
      basicCompleted
      createdAt
      domain
      errorUrlCount
      id
      isCompleted
      isDeleted
      isFailed
      isCanceled
      issues
      notices
      projectId
      reason
      sessionId
      updatedAt
      urlCrawled
      usedCrawl
      warnings
      websiteScore
      reportEndTime
    }
  }
`;

export const CRAWLPROGRESS_SCRAPY_DATA = gql`
  query Query($page: Int!, $sessionId: String!, $filterArr: [String!]) {
    GetCrawlProgressReport(page: $page, sessionId: $sessionId, filterArr: $filterArr)
  }
`;

export const ALL_REPORT = gql`
  query GetAllReports($startDate: String, $endDate: String) {
    getAllReports(startDate: $startDate, endDate: $endDate) {
      filterReport {
        project
        createdAt
        domain
        usedCrawl
        isCompleted
        isFailed
        basicCompleted
        isDeleted
      }
      reportCount {
        Completed
        Deleted
        Failed
        Progress
        totalCount
      }
    }
  }
`;
// export const GET_REPORT_DATE_WISE_FOR_ADMIN = gql`
//   query Query($timeStamp: SuperAdmintimeStamp!) {
//     getSuperAdminForAllReportsGraphValue(timeStamp: $timeStamp)
//   }
// `;
export const ALL_SITEAUDIT_COUNT = gql`
  query Query($endDate: String, $startDate: String) {
    GetAllSiteAuditReportsCount(endDate: $endDate, startDate: $startDate)
  }
`;

export const ALL_SITEAUDIT_DATA = gql`
  query Query($endDate: String, $frequency: String, $startDate: String) {
    GetSiteAuditReportsData(endDate: $endDate, frequency: $frequency, startDate: $startDate)
  }
`;

export const DELETE_REPORT = gql`
  query Query($sessionId: String!) {
    DeleteProcessReport(sessionId: $sessionId)
  }
`;
export const GET_REPORT_DATE_WISE_FOR_ADMIN = gql`
  query Query($timeStamp: SuperAdmintimeStamp!) {
    getSuperAdminForAllReportsGraphValue(timeStamp: $timeStamp)
  }
`;
// export const ALL_REPORT = gql`
//   query GetAllReports($startDate: String, $endDate: String) {
//     getAllReports(startDate: $startDate, endDate: $endDate) {
//       filterReport {
//         project
//         createdAt
//         domain
//         usedCrawl
//         isCompleted
//         isFailed
//         basicCompleted
//         isDeleted
//       }
//       reportCount {
//         Completed
//         Deleted
//         Failed
//         Progress
//         totalCount
//       }
//     }
//   }
// `;
// export const ALL_SITEAUDIT_COUNT = gql`
//   query Query($endDate: String, $startDate: String) {
//     GetAllSiteAuditReportsCount(endDate: $endDate, startDate: $startDate)
//   }
// `;
