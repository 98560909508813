import React, { useState } from "react";

// mui imports
import { ListItemIcon, ListItemButton, Collapse, styled, ListItemText, useTheme, useMediaQuery } from "@mui/material";

// custom imports

// plugins
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import NavItem from "../NavItem";
import { toggleMobileSidebar, toggleSidebar } from "../../../../redux/action";
// FC Component For Dropdown Menu
function NavCollapse({ menu, level, pathWithoutLastPart, pathDirect, hideMenu, onClick }) {
  const customizer = useSelector(state => state?.customizer);
  const lgUp = useMediaQuery(theme => theme.breakpoints.up("lg"));
  // const lgDown = useMediaQuery(theme => theme.breakpoints.down("lg"));
  const dispatch = useDispatch();
  const Icon = menu?.icon;
  const theme = useTheme();
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const menuIcon = level > 1 ? <Icon stroke={1.5} size="1rem" /> : <Icon stroke={1.5} size="1.3rem" />;

  const handleClick = () => {
    if (customizer?.isCollapse) {
      if (lgUp) {
        dispatch(toggleSidebar());
      } else {
        dispatch(toggleMobileSidebar());
      }
      // dispatch(toggleProjectSidebar());
      setOpen(!open);
    } else {
      setOpen(!open);
    }
  };

  // menu collapse for sub-levels
  React.useEffect(() => {
    if (level > 1) setOpen(false);
    menu?.children?.forEach(item => {
      const itemHrefWithoutQuery = item?.href.split("?")[0];
      const pathWithoutQuery = pathname.split("?")[0];
      // console.log(pathWithoutQuery);
      if (itemHrefWithoutQuery.includes("/projects/report/")) {
        const itemNameArr = itemHrefWithoutQuery.split("/");
        const pathNameArr = pathWithoutQuery.split("/");
        if (itemNameArr[1] === pathNameArr[1] && itemNameArr[2] === pathNameArr[2] && itemNameArr[4] === pathNameArr[4] && itemNameArr[5] === pathNameArr[5]) {
          setOpen(true);
        }
      } else if (itemHrefWithoutQuery === pathWithoutQuery) {
        setOpen(true);
      }
    });
    // eslint-disable-next-line
  }, [pathname, menu.children]);

  const ListItemStyled = styled(ListItemButton)(() => ({
    marginBottom: "2px",
    padding: "8px 10px",
    paddingLeft: hideMenu ? "7px" : level > 2 ? `${level * 15}px` : "10px",
    backgroundColor: open && level < 2 ? theme.palette.primary.main : "",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: pathname.includes(menu.href) || open ? theme.palette.primary.main : theme.palette.primary.light,
      color: pathname.includes(menu.href) || open ? "white" : theme.palette.primary.main,
    },
    color: open && level < 2 ? "white" : `inherit` && level > 1 && open ? theme.palette.primary.main : theme.palette.text.secondary,
    borderRadius: `${customizer.borderRadius}px`,
    "& .Icons svg.SvgIcons": { fill: open && level < 2 ? "white" : `inherit` && level > 1 && open ? theme.palette.primary.main : theme.palette.text.secondary },
  }));

  // If Menu has Children
  const submenus = menu.children?.map(item => {
    // console.log(item);
    if (item.children) {
      return <NavCollapse key={item?.id} menu={item} level={level + 1} pathWithoutLastPart={pathWithoutLastPart} pathDirect={pathDirect} hideMenu={hideMenu} onClick={onClick} />;
    }
    return <NavItem key={item.id} item={item} level={level + 1} pathDirect={pathDirect} hideMenu={hideMenu} onClick={onClick} />;
  });

  return (
    <>
      <ListItemStyled onClick={handleClick} selected={pathWithoutLastPart === menu.href} key={menu?.id}>
        <ListItemIcon
          sx={{
            minWidth: "36px",
            p: "3px 0",
            color: "inherit",
          }}
          className="Icons"
        >
          {menuIcon}
        </ListItemIcon>
        <ListItemText color="inherit">{hideMenu ? "" : <>{t(`${menu.title}`)}</>}</ListItemText>
        {!open ? <IconChevronDown size="1rem" /> : <IconChevronUp size="1rem" />}
      </ListItemStyled>
      <Collapse in={open && !customizer?.isCollapse} timeout="auto" unmountOnExit sx={{ ml: "15px" }}>
        {submenus}
      </Collapse>
    </>
  );
}

export default NavCollapse;
