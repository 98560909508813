import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

function maxValue(count2xx, count3xx, countOther) {
  const newArr = [];
  for (let i = 0; i < count2xx.length; i++) {
    newArr.push(count2xx[i] + count3xx[i] + countOther[i]);
  }
  return Math.max(...newArr);
}

function CrawlProgressChart({ createdAt, crawlData }) {
  const theme = useTheme();
  const success = theme.palette.success.main;
  const warning = theme.palette.warning.main;
  const error = theme.palette.error.main;
  const [seriesColumnChart, setSeriesColumnChart] = useState([
    {
      name: "Url 2XX",
      data: [0, 0],
    },
    {
      name: "Url 3XX",
      data: [0, 0],
    },
    {
      name: "Other",
      data: [0, 0],
    },
  ]);
  const columnChart = {
    chart: {
      type: "bar",
      fontFamily: "'Plus Jakarta Sans', sans-serif;",
      foreColor: "#adb0bb",
      toolbar: {
        show: false,
      },
      height: 360,
      stacked: true,
    },
    colors: [success, warning, error],
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: "60%",
        columnWidth: "20%",
        borderRadius: [6],
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "13px",
              fontWeight: 600,
            },
          },
        },
      },
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      borderColor: "rgba(0,0,0,0.1)",
      strokeDashArray: 3,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      title: {
        text: "URL Count",
      },
      min: 0,
      max: 50,
      tickAmount: 10,
    },
    xaxis: {
      title: {
        text: "Time Interval 1 Bar = 2 Min",
      },
      labels: {
        show: false,
      },
    },
    tooltip: {
      theme: theme.palette.mode === "dark" ? "dark" : "light",
      fillSeriesColor: false,
    },
  };
  const [optionsColumnChart, setOptionsColumnChart] = useState(columnChart);

  useEffect(() => {
    if (createdAt && crawlData && crawlData.length > 0) {
      const startTime = new Date(createdAt);
      const lastDocumentTime = new Date(Math.max(...crawlData.map(data => new Date(data.createdAt).getTime())));

      const endTime = new Date(Math.max(startTime.getTime(), lastDocumentTime.getTime()));
      const counts2xx = [];
      const counts3xx = [];
      const countsOther = [];

      let interval;
      if (endTime - startTime < 1200000) {
        interval = 1 * 60 * 1000;
      } else {
        interval = Math.ceil((endTime - startTime) / 20);
      }

      for (let time = startTime; time <= endTime; time = new Date(time.getTime() + interval)) {
        const intervalData = crawlData.filter(data => {
          const dataTime = new Date(data.createdAt);
          return dataTime >= time && dataTime < new Date(time.getTime() + interval);
        });

        const status2Count = intervalData.filter(data => {
          const status = String(data.response_status);
          return status.startsWith("2");
        }).length;

        const status3Count = intervalData.filter(data => {
          const status = String(data.response_status);
          return status.startsWith("3");
        }).length;

        const statusOtherCount = intervalData.filter(data => {
          const status = String(data.response_status);
          return !status.startsWith("2") && !status.startsWith("3");
        }).length;

        counts2xx.push(status2Count);
        counts3xx.push(status3Count);
        countsOther.push(statusOtherCount);
      }

      const maxCount = maxValue(counts2xx, counts3xx, countsOther);
      const maxYAxis = Math.ceil(maxCount / 10) * 10;

      const yaxisOptions = {
        title: {
          text: "URL Count",
        },
        min: 0,
        max: maxYAxis || 50,
        tickAmount: 10,
      };
      const intervalMinutes = Math.ceil(interval / 60000);
      const xaxisTitleText = `Time Interval 1 Bar = ${intervalMinutes} Min`;

      setOptionsColumnChart({
        ...optionsColumnChart,
        yaxis: yaxisOptions,
        xaxis: {
          title: {
            text: xaxisTitleText,
          },
          labels: {
            show: false,
          },
        },
      });
      setSeriesColumnChart([
        {
          name: "Url 2XX",
          data: counts2xx.length >= 2 ? counts2xx : [0, 0],
        },
        {
          name: "Url 3XX",
          data: counts3xx.length >= 2 ? counts3xx : [0, 0],
        },
        {
          name: "Other",
          data: countsOther.length >= 2 ? countsOther : [0, 0],
        },
      ]);
    }
  }, [createdAt, crawlData]);

  return <Box sx={{ mt: "20px" }}>{seriesColumnChart && <Chart options={optionsColumnChart} series={seriesColumnChart} type="bar" height={350} />}</Box>;
}

export default React.memo(CrawlProgressChart);
