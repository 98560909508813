import { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import { Fade, Menu, MenuItem, useTheme } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLazyQuery } from "@apollo/client";
import { getTicket, messageDelete } from "../../gql/queries/ticket";
import useQuery from "../../hooks/allQuery";
import DeletePopup from "../../common/deletePopup";

export default function DeleteConfirmation({ ticketId, msgId }) {
  const [open1, setOpen1] = useState(false);
  const [openChild, setOpenChild] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const [MSG_DELETE] = useLazyQuery(messageDelete, { fetchPolicy: "network-only" });
  const { allQueryObject } = useQuery();
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
    setOpen1(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function HandleClick() {
    setOpenChild(true);
    setOpen1(false);
  }

  const handleDelete = useCallback(() => {
    allQueryObject({
      QUERY: MSG_DELETE,
      variables: {
        payload: {
          ticketId,
          msgId,
        },
      },
      type: "delete",
      cacheQueryData: {
        cacheQuery: getTicket,
        variablesCache: {
          ticketFindUniqueId: ticketId,
        },
      },
    });
    setOpenChild(false);
  }, [ticketId, msgId, allQueryObject, MSG_DELETE, getTicket, setOpenChild]);
  return (
    <>
      <Button
        sx={{
          backgroundColor: "transparent",
          minWidth: "auto",
          pr: "12px",
          "& svg": {
            width: "15px",
          },
          "&:hover": {
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
          },
          "&:focus": { outline: "none" },
        }}
        width="1px"
        onClick={handleMenu}
      >
        <MoreVertIcon />
      </Button>
      {open1 && (
        <Menu
          id="fade-menu"
          sx={{
            "& .MuiPopover-paper": {
              overflow: "visible",
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: theme.palette.primary.main,
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
              "& ul": {
                p: "0",
                borderRadius: "6px",
                overflow: "hidden",
                "& .menuitem": {
                  color: "#ffffff",
                  backgroundColor: theme.palette.primary.main,
                  p: "12px 18px",
                },
              },
            },
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem className="menuitem" onClick={() => HandleClick()}>
            Delete
          </MenuItem>
        </Menu>
      )}

      {!open1 && <DeletePopup open={openChild} setOpen={setOpenChild} handleDelete={handleDelete} msg="Message" />}
    </>
  );
}
