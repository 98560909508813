import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { memo } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const BackButtonBox = styled(Box)(({ theme }) => ({
  "@media screen and (max-width:600px)": {
    width: "100%",
  },
  "& button": {
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    borderRadius: "5px",
    boxShadow: "none",
    marginBottom: "15px",
    "@media screen and (max-width:600px)": {
      width: "100%",
    },
    "&:focus": {
      outline: "0",
    },
    "& svg": {
      fontSize: "22px",
      marginLeft: "-7px",
    },
  },
  "& a": {
    color: "#ffffff",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Plus Jakarta Sans', sans-serif !important",
    "& svg": { marginRight: "6px", fontSize: "22px", transition: "0.9s ease-out" },
  },
  "&:hover": {
    boxShadow: "none",
    backgroundColor: theme.palette.common.hoverBtn,
    color: theme.palette.common.white,
    "& a": { color: "#ff642d" },
    "& svg": { transform: "rotateZ(720deg)" },
  },
  "&:focus": { outline: "none" },
}));

function BackButton({ sx }) {
  const navigate = useNavigate();
  return (
    <BackButtonBox sx={sx}>
      <Button onClick={() => navigate(-1)} variant="contained">
        <KeyboardArrowLeftIcon />
        Back
      </Button>
    </BackButtonBox>
  );
}
export default memo(BackButton);
