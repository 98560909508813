import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Button, Grid, Switch, Typography, MenuItem, Select, Container, FormControlLabel } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useLazyQuery, useMutation } from "@apollo/client";
import dayjs from "dayjs";
import MannualSteps from "./MannualSteps";
import BackButton from "../BackButton/BackButton";
import { AUDIT_DATA, AUDIT_OFF, SITE_AUDIT } from "../../gql/mutations/siteAudit";
import { usePopup } from "../../context/commonPop";
import { GetUserWebsiteData } from "../../hooks/userWebsiteData";
import downarrow from "../../Images/downarrow.png";
import darkLogoImage from "../../Images/hotSpotLogoBlue.svg";
const verificationBox = {
  backgroundColor: "#F7F7F7",
  borderRadius: "6px",
  padding: "20px",
  mt: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .switchBox": {
    "& .Mui-checked": {
      color: "#fff",
    },
  },
  "& ul li": {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "17.64px",
    color: "#2A3547",
    mb: "8px",
  },
};
const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "100vh",
  backgroundColor: "#EFF3FB",
  paddingTop: "30px",
  paddingBottom: "30px",
  "& .LogoWrapper": {
    textAlign: "center",
    "& img": { width: "105px", height: "auto" },
  },
  "& .ImportMainHead": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "@media screen and (max-width:600px)": {
      flexDirection: "column",
      marginBottom: "20px",
    },
    "& .MainHeading": { color: "#2A3547", textAlign: "center", lineHeight: 1, fontSize: "24px", fontWeight: 700, marginBottom: "10px" },
    "& .SubHeading": {
      color: "#757575",
      textAlign: "center",
      lineHeight: 1,
      fontSize: "14px",
      fontWeight: 500,
    },
  },
  "& .continueBtn": {
    textTransform: "none",
    backgroundColor: "#45C0CB",
    color: "#ffffff",
    borderRadius: "5px",
    boxShadow: "none",
    padding: "12px 22px 10px",
    width: "100%",
    "&:hover": { backgroundColor: "#45C0CB" },
    "&:focus": {
      outline: "0",
      backgroundColor: "#45C0CB",
    },
    "& .MuiCircularProgress-circle": {
      color: "#ffffff",
    },
  },

  "& .MainHeading h2": {
    color: "#2A3547",
    textAlign: "center",
    lineHeight: 1,
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "10px",
  },
  "& .SubHeading": {
    color: "#757575",
    textAlign: "center",
    lineHeight: 1,
    fontSize: "14px",
    fontWeight: 500,
  },
  "& .importProjectbox": {
    backgroundColor: "#fff",
    padding: "20px",
    border: "1px solid #E3E3E3",
    borderRadius: "8px",
    "& .InputWrapper": {
      "& button": {
        backgroundColor: theme.palette.primary.main,
        padding: "12px 0",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "17.64px",
        textAlign: "left",
      },
      "& .selectInput": {
        width: "40%",
      },
      "& .MuiFormControl-root": {
        marginTop: "7px",
        "& fieldset": {
          borderColor: " #E3E3E3 !important",
        },
        "&.FullWidthInput": {
          flex: "100%",
        },
        "& label": { lineHeight: 1 },
      },
      "& label": { color: theme.palette.text.primary, fontSize: "12px", fontWeight: 600, lineHeight: "15.12px", textAlign: "left" },
    },
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#45C0CB",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const SiteAuditWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  "& h1": {
    color: theme.palette.text.primary,
    fontSize: "25px",
    fontWeight: 600,
  },
  "& .autositeaudit": {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  "& .saveBtn": {
    marginBottom: "15px",
    backgroundColor: theme.palette.primary.main,
    fontWeight: 600,
    color: "#fff",
    "&[disabled]": { color: "#ffffff38" },
  },
  "& .switchBtn .css-ab0lf1-MuiButtonBase-root-MuiSwitch-switchBase": {
    color: "black",
  },
  "& .switchBtn .css-ab0lf1-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked": {
    color: "#1976d2",
  },
  "& .switchBtn .css-mghcik-MuiButtonBase-root-MuiSwitch-switchBase": {
    color: "black",
  },
  "& .switchBtn .css-dnkzfa-MuiSwitch-track": {
    backgroundColor: " #001e5b",
    opacity: 0.38,
  },
  "& .switchBtn .Mui-checked .css-jsexje-MuiSwitch-thumb": {
    backgroundColor: theme.palette.primary.main,
  },
  "& .switchBtn .css-mghcik-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    backgroundColor: " #1976d2",
  },
  " .css-u3jdsl-MuiSwitch-thumb": {
    color: theme.palette.text.primary,
  },
  "& .Mui-checked .css-u3jdsl-MuiSwitch-thumb": {
    color: theme.palette.primary.main,
  },
  "& .generatingreport": {
    color: theme.palette.common.black,
  },

  "& .siteAuditWrapper": {
    "& .daysboxes": {
      display: "flex",
      margin: "6px 0",
      alignItems: "center",
      gap: "16px",
      "@media screen and (max-width:600px)": {
        flexWrap: "wrap",
      },
    },
    display: "block",
    margin: "0px 0",
    alignItems: "center",
    gap: "30px",
    padding: "20px ",
    "@media screen and (max-width:600px)": {
      flexWrap: "wrap",
      paddingRight: 0,
      paddingBottom: 0,
    },

    borderRadius: "15px",
    "& h2": {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "15.12px",
      color: "#2A3547",
      marginBottom: "8px",
    },
    "& .TimePicker": {
      border: "1px solid #E3E3E3",
      borderRadius: "6px",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "17.64px",
      "@media screen and (max-width:600px)": {
        width: "100%",
      },
      "& svg": {
        color: "#45C0CB",
      },
      "& fieldset": {
        display: "none",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #E3E3E3",
      },
    },
    "& .endTime": {
      "& .Mui-disabled": {
        textFillColor: `${theme.palette.text.primary}`,
        opacity: "0.5",
      },
    },
    "& .FrequencySelect": {
      width: "70%",
      "@media screen and (max-width:600px)": {
        width: "100%",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #E3E3E3",
      },
      "& .MuiSelect-select": {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "17.64px",
        color: "#808080 !important",
      },
      "& .MuiMenuItem-root ": {
        color: `${theme.palette.text.primary} !important`,
      },
    },
  },
  "& .startDay": {
    "& button": {
      backgroundColor: "#F7F7F7",
      color: "#757575",
      height: "44px",
      width: "44px",
      borderRadius: "50%",
      padding: "0 !important",
      minWidth: "44px",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "20.16px",
      textAlign: "left",
    },
    "& .active": {
      backgroundColor: "#45C0CB",
      fontWeight: 600,
      color: "#fff",
    },
  },
}));

const MenuItemWrapper = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
function CustomIcon() {
  return <Typography sx={{ mr: "14px" }} component="img" src={downarrow} alt="Dropdown icon" />;
}
function SiteAudit() {
  const themen = useTheme();
  const { ownerId } = useParams();
  const [state, setState] = useState({
    frequency: "weekly",
    day: "1",
    startTime: dayjs("2023-05-25T12:00"),
    endTime: dayjs("2023-05-25T13:00"),
    week: "monday",
    checked: false,
  });
  const [savebutton, setSavebutton] = useState(false);
  const navigate = useNavigate();
  const { handleOpen } = usePopup();

  const [siteaudit] = useMutation(SITE_AUDIT);
  const [auditdata] = useLazyQuery(AUDIT_DATA, { fetchPolicy: "cache-first" });
  const [auditoff] = useMutation(AUDIT_OFF);
  let startday;
  useEffect(() => {
    auditdata({
      variables: {
        projectId: ownerId,
      },
      onCompleted: res => {
        setSavebutton(true);
        const { GetSiteAudit } = res || {};
        const { frequency, startTime, endTime, startDay, isActive } = GetSiteAudit || {};
        const parsedStartTime = dayjs(startTime, "hh:mm A").format("YYYY-MM-DDTHH:mm");
        const parsedEndTime = dayjs(endTime, "hh:mm A").format("YYYY-MM-DDTHH:mm");
        if (frequency === "monthly") {
          setState({ ...state, frequency, startTime: dayjs(parsedStartTime), endTime: dayjs(parsedEndTime), day: startDay, checked: isActive });
        } else if (frequency === "weekly") {
          setState({ ...state, frequency, startTime: dayjs(parsedStartTime), endTime: dayjs(parsedEndTime), week: startDay, checked: isActive });
        } else if (frequency) {
          setState({ ...state, frequency, startTime: dayjs(parsedStartTime), endTime: dayjs(parsedEndTime), checked: isActive });
        }
      },
      onError: err => {
        console.log("err", err);
      },
    });
  }, []);

  const arr = [
    ["monday", "Mn"],
    ["tuesday", "Tu"],
    ["wednesday", "We"],
    ["thursday", "Th"],
    ["friday", "Fr"],
    ["saturday", "Sa"],
    ["sunday", "Su"],
  ];

  const handleOptionChange = event => {
    setSavebutton(false);
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleTimeChange = (name, newTime) => {
    setSavebutton(false);
    setState(prevState => ({
      ...prevState,
      [name]: newTime,
      [`endTime`]: newTime.add(1, "hour"),
    }));
  };

  const handleReoprt = () => {
    setState({ ...state, checked: !state.checked });
    auditoff({
      variables: {
        projectId: ownerId,
      },
      onCompleted: res => {
        const { SiteAuditOff } = res;
        if (SiteAuditOff?.isActive) {
          handleOpen(true, "Automatic Generating Report On");
        } else {
          handleOpen(true, "Automatic Generating Report Off");
        }
      },
      onError: err => {
        console.log("error is", err);
      },
    });
  };

  const { ProjectData } = GetUserWebsiteData(ownerId);
  const { userId, website } = ProjectData || {};

  const handleSave = async () => {
    if (savebutton || !state.checked) {
      navigate(`/dashboard`);
      return;
    }

    const formattedStartTime = dayjs(state.startTime?.$d).format("hh:mm A");
    const formattedEndTime = dayjs(state.startTime).add(1, "hour").format("hh:mm A");

    if (state.frequency === "monthly") {
      startday = String(state.day);
    } else if (state.frequency === "weekly") {
      startday = state.week;
    } else {
      startday = null;
    }

    siteaudit({
      variables: {
        payload: {
          frequency: state.frequency,
          projectId: ownerId,
          startDay: startday,
          startTime: formattedStartTime,
          endTime: formattedEndTime,
          userId,
          website,
        },
      },
      onCompleted: () => {
        handleOpen(true, "Updated Site Audit Schedule");
        setSavebutton(true);
        navigate(`/dashboard`);
      },
      onError: err => {
        console.log("Error", err);
      },
    });
  };

  return (
    <SectionWrapper>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Box className="LogoWrapper">
                <Typography component="img" src={darkLogoImage} alt="Logo" />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={0} justifyContent="center">
            <Grid item xs={12} md={12}>
              <Box className="importProjectbox">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box className="ImportMainHead">
                      <Box sx={{ flex: 1, width: { xs: "100%", sm: "unset" } }}>
                        <Box sx={{ width: { xs: "100%", sm: "50%" }, m: "auto", mb: { xs: "15px ", sm: "30px" } }}>
                          <MannualSteps activeStep={2} />
                        </Box>
                      </Box>
                      <BackButton
                        sx={{
                          marginLeft: "-80px",
                          zIndex: 99999999,
                          "@media screen and (max-width:600px)": { marginLeft: "0" },
                          "& button": { marginBottom: "0 !important", backgroundColor: "#45C0CB !important" },
                        }}
                      />
                    </Box>
                    <Box className="MainHeading">
                      <Typography component="h2">Site Audit</Typography>
                      <Typography className="SubHeading">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={verificationBox}>
                      <Typography className="autositeaudit" component="span">
                        {state.checked ? "Automatic Site Audit On" : "Automatic Site Audit Off"}
                      </Typography>
                      <Box className="switchBox">
                        <FormControlLabel control={<AntSwitch checked={state.checked} onChange={handleReoprt} color="primary" />} />
                      </Box>
                    </Box>
                    {/* <Box sx={verificationBox}>
                      <Typography component="h2">Automatic Site Audit On</Typography>
                      <Box className="switchBox">
                        <AntSwitch defaultChecked inputProps={{ "aria-label": "ant design" }} />
                      </Box> */}
                    {/* </Box> */}
                  </Grid>
                  <Grid item xs={12}>
                    <SiteAuditWrapper>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                          <Box className="siteAuditWrapper">
                            <Typography component="h2">Frequency</Typography>
                            <Select name="frequency" value={state.frequency} onChange={handleOptionChange} className="FrequencySelect" IconComponent={CustomIcon}>
                              <MenuItemWrapper value="daily">Daily</MenuItemWrapper>
                              <MenuItemWrapper value="weekly">Weekly</MenuItemWrapper>
                              <MenuItemWrapper value="monthly">Monthly</MenuItemWrapper>
                            </Select>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={7}>
                          {state.frequency === "monthly" && (
                            <Box className="siteAuditWrapper">
                              <Typography component="h2">Start Day</Typography>

                              <Select name="day" value={state.day} onChange={handleOptionChange}>
                                {[...Array(31)].map((_, index) => (
                                  <MenuItem disabled={!state.checked} value={index + 1}>
                                    {index + 1}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          )}
                          {state.frequency === "weekly" && (
                            <Box className="siteAuditWrapper startDay">
                              <Typography component="h2">Start Day</Typography>
                              <Box className="daysboxes">
                                {arr.map(items => (
                                  <Button
                                    disabled={!state.checked}
                                    onClick={handleOptionChange}
                                    sx={{ backgroundColor: (themen.palette.mode === "light" || "dark") && state.week === items[0] && `${themen.palette.common.orange} !important` }}
                                    name="week"
                                    value={items[0]}
                                    className={state.week === items[0] ? "active" : null}
                                  >
                                    {items[1]}
                                  </Button>
                                ))}
                              </Box>
                            </Box>
                          )}
                          <Box>
                            <Box className="siteAuditWrapper">
                              <Typography component="h2"> Start Time</Typography>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker className="TimePicker" value={state.startTime} onChange={newTime => handleTimeChange("startTime", newTime)} />
                              </LocalizationProvider>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          {/* <Button onClick={handleSave} disabled={savebutton || !state.checked} className="continueBtn">
                            Get start project
                          </Button> */}
                          <Button
                            onClick={() => {
                              if (savebutton || !state.checked) {
                                navigate(`/dashboard`);
                              } else {
                                handleSave();
                              }
                            }}
                            className="continueBtn"
                          >
                            Get start project
                          </Button>
                        </Grid>
                      </Grid>
                    </SiteAuditWrapper>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default SiteAudit;
