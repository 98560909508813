import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import apolloClient from "./apollo/config";
import * as serviceWorker from "./serviceWorker";
import AuthContext from "./AuthContext";
import store from "./redux/store";
import { App } from "./App";
import Popupprovider from "./context/commonPop";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <GoogleOAuthProvider clientId="416863625326-bt3sf5pgkl3rb77da171a1htrhl7lg83.apps.googleusercontent.com">
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <AuthContext.Provider>
          <BrowserRouter>
            <Popupprovider>
              <App />
            </Popupprovider>
          </BrowserRouter>
        </AuthContext.Provider>
      </Provider>
    </ApolloProvider>
  </GoogleOAuthProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
