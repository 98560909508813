import { useMutation } from "@apollo/client";
import { Box, Button, Container, Grid, Typography, styled } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { TOKENINPUT } from "../../gql/mutations/auth";
import { SignupUser } from "../../redux/action";
import { deleteSession, saveLocalTokens } from "../../utils/session";
import DashboardCard from "../shared/DashboardCard";
import Logo from "../../Images/hotSportLogo.webp";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingTop: "50px",
  paddingBottom: "50px",
  "& .PaddingManage": { "& > .MuiCardContent-root": { padding: "0" } },
  "& .InnerWrapper": {
    "& .TopHeaderLogoWrapper": { textAlign: "center", paddingTop: "16px", paddingBottom: "16px", "& img": { width: "105px" } },
    "& .HeaderWrapper": {
      backgroundColor: theme.palette.primary.main,
      padding: "22px 20px 24px",
      textAlign: "center",
      "& svg": { marginBottom: "8px", color: "#ffffff", fontSize: "38px" },
      "& h6": { color: "#ffffff", marginBottom: "8px" },
      "& h3": { color: "#ffffff" },
    },
    "& .BodyWrapper": {
      padding: "40px 90px 45px",
      textAlign: "center",
      "& .Para1": { color: "#000000", marginBottom: "30px", display: "block" },
      "& .Para2": { color: "#000000", display: "block" },
      "& button": {
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        fontSize: "18px",
        padding: "5px 30px 5px",
        marginBottom: "28px",
        "&:focus": { outline: "none" },
      },
    },
    "& .FooterWrapper": {
      padding: "40px 90px 45px",
      textAlign: "center",
      backgroundColor: theme.palette.primary.main + "26",
      "& h4": { color: theme.palette.primary.main, marginBottom: "12px", display: "block" },
      "& .Para1": { color: "#000000", display: "block" },
      "& .Para2": { color: "#000000", marginBottom: "18px", display: "block" },
      "& .SocialWrapper": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        "& svg": { fontSize: "30px", color: "#000" },
      },
    },
  },
}));

function PreSignUp() {
  const dispatch = useDispatch();
  const { token } = useParams();
  const navigate = useNavigate();
  // const app = useSelector(state => state.reducer.userData);
  const [authSignup] = useMutation(TOKENINPUT);
  const handleClick = async () => {
    // const payload = { ...userData, ["isVerfied"]: true };
    try {
      await authSignup({
        variables: {
          data: {
            token: token?.toString(),
            isVerified: true,
          },
        },
      })
        .then(({ data }) => {
          if (data) {
            const { accessToken, refreshToken } = data.tokenData;
            saveLocalTokens({
              accessToken,
              refreshToken,
            });
            dispatch(
              SignupUser({
                ...data.tokenData.user,
                subscription: data?.tokenData?.subscription,
                subscriptionCredits: data?.tokenData?.subscriptionCredits,
              }),
            );
          } else {
            deleteSession();
            navigate("/");
          }
        })
        .catch(e => {
          console.log(e.message);
          deleteSession();
          navigate("/");
        });
      navigate("/dashboard");
    } catch {
      deleteSession();
      navigate("/");
    }
  };
  return (
    <SectionWrapper>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={8}>
              <DashboardCard ClassName="HeightFull PaddingManage">
                <Box className="InnerWrapper">
                  <Box className="TopHeaderLogoWrapper">
                    <Typography component="img" src={Logo} alt="Logo" />
                  </Box>
                  <Box className="HeaderWrapper">
                    <EmailIcon />
                    <Typography variant="h6">THANKS FOR SIGNING UP!</Typography>
                    <Typography variant="h3">Verify Your E-mail Address</Typography>
                  </Box>
                  <Box className="BodyWrapper">
                    <Typography variant="body" className="Para1">
                      <strong>Hi,</strong>
                      <br />
                      You&apos;re almost ready to get started. Please click on the button below to verify your email address and enjoy exclusive cleaning services with us!
                    </Typography>
                    <Button
                      onClick={() => {
                        handleClick();
                      }}
                      color="primary"
                    >
                      Verify Your Email
                    </Button>
                    <Typography variant="body" className="Para2">
                      <strong>Thanks,</strong>
                    </Typography>
                  </Box>
                  <Box className="FooterWrapper">
                    <Typography variant="h4">Get in touch</Typography>
                    <Typography variant="body" className="Para1">
                      0731-4288397
                    </Typography>
                    <Typography variant="body" className="Para2">
                      contact@discoverwebtech.com
                    </Typography>
                    <Box className="SocialWrapper">
                      <Typography component="a" href="https://www.facebook.com/people/Hotspot-SEO/100093008047961/" target="_blank">
                        <FacebookIcon />
                      </Typography>
                      <Typography component="a" href="https://twitter.com/hotspot_seo" target="_blank">
                        <TwitterIcon />
                      </Typography>
                      <Typography component="a" href="https://www.instagram.com/hotspotseo/" target="_blank">
                        <InstagramIcon />
                      </Typography>
                      <Typography component="a" href="https://www.linkedin.com/in/hotspot-seo-9b4915277/" target="_blank">
                        <LinkedInIcon />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </DashboardCard>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
  // ("return page");
}
export default PreSignUp;
