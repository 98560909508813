import { Box, Button, Link, Typography, styled } from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import DashboardCard from "../shared/DashboardCard";

const BoxWrapper = styled(Box)(({ theme }) => ({
  "& span.MainHeading": { color: theme.palette.text.primary, marginBottom: "15px", display: "block" },
  "& h5": { color: theme.palette.text.primary, marginBottom: "15px", display: "block" },
}));

function UptimeDescription() {
  const { projectid } = useParams();
  return (
    <DashboardCard ClassName="HeightFull">
      <BoxWrapper>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="body" className="MainHeading">
            Uptime monitoring is an automated way of checking whether a service such as a website or an application is available. When service goes down during an outage
            (downtime), uptime monitoring spots the issue and alerts the right person on the development team.
          </Typography>

          <Typography variant="h5" className="MainHeading">
            How does uptime monitoring work?
          </Typography>

          <Typography variant="body" className="MainHeading">
            The uptime monitoring process works by sending automated HTTP requests at a pre-defined frequency to a specific URL and checking for the desired response. HTTP GET
            requests are usually used. Other HTTP requests can be used as well, for example when monitoring APIs and other functionality. The pre-defined frequency of the checks
            depends on the specific user’s need but can generally range anywhere from 30 seconds for business websites to 10 or more minutes for hobby projects. The desired
            response from the monitored URL is the 200 OK HTTP response code (other codes might be acceptable as well in specific cases). Uptime monitor can also be set to monitor
            for the desired keyword in the response. This is often used in health checks or when assuring correct display of a critical part of the website. For instance, keywords
            like signup or subscribe, are often checked to assure that the most valuable user actions are working as they should. When the correct code or keyword is received from
            an URL no further action is taken and the monitoring continues. When a different code is returned (any of the 5xx server errors for example), the monitor starts what is
            called a downtime incident and starts alerting according to the on-call calendar.
          </Typography>
          <Link component={RouterLink} to={`/uptime-setting/${projectid}`}>
            <Button variant="contained" color="primary">
              Please check your uptime settings
            </Button>
          </Link>
        </Box>
      </BoxWrapper>
    </DashboardCard>
  );
}

export default UptimeDescription;
