import { Box } from "@mui/material";

const SvgAnimationWrapper = {
  "& .RightMark": {
    transformOrigin: "center",
    animation: "zoom-in 1s cubic-bezier(0.4, 0, 1, 1)",
    "@keyframes zoom-in": {
      "0%": { transform: "scale(0)" },
      "100%": { transform: "scale(1)" },
    },
  },
  "& .MarkCircle": {
    transformOrigin: "center",
    animation: "opacity-up 2s ease",
    "@keyframes opacity-up": {
      "0%": { opacity: "0" },
      "100%": { opacity: "0.2" },
    },
  },
  "& .Flag": {
    transformOrigin: "center",
    animation: "fade-flag1 2.5s infinite ease 1.5s",
    opacity: "0",
    "@keyframes fade-flag1": {
      "0%, 100%": { opacity: "1" },
      "50%": { opacity: "0" },
    },
  },
};

function FailedIcon() {
  return (
    <Box sx={SvgAnimationWrapper} component="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181 122" fill="none" className="FailedIcon">
      <path d="M89 44.5H98L115.5 119H105.5L89 44.5Z" fill="#FF6062" fillOpacity="0.2" className="MarkCircle" />
      <path
        d="M63.8 119L70.136 90.2M115.8 119L105.816 77.4M23 119H38.2M164.6 119H95M70.136 90.2L105.816 77.4M70.136 90.2L74.712 69.4M105.816 77.4L101.208 58.2M74.712 69.4L81.4 39H96.6L101.208 58.2M74.712 69.4L101.208 58.2M53.4 119H79"
        stroke="#FF6062"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="RightMark"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M138.208 11.2081C138.486 10.9306 138.935 10.9306 139.213 11.2081L142.5 14.4952L145.787 11.2081C146.065 10.9306 146.514 10.9306 146.792 11.2081C147.069 11.4856 147.069 11.9355 146.792 12.2129L143.505 15.5L146.792 18.7871C147.069 19.0645 147.069 19.5144 146.792 19.7919C146.514 20.0694 146.065 20.0694 145.787 19.7919L142.5 16.5048L139.213 19.7919C138.935 20.0694 138.486 20.0694 138.208 19.7919C137.931 19.5144 137.931 19.0645 138.208 18.7871L141.495 15.5L138.208 12.2129C137.931 11.9355 137.931 11.4856 138.208 11.2081Z"
        fill="#FF6062"
        className="Flag"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.208118 82.2081C0.485596 81.9306 0.935476 81.9306 1.21295 82.2081L4.5 85.4952L7.78706 82.2081C8.06453 81.9306 8.51441 81.9306 8.79189 82.2081C9.06937 82.4856 9.06937 82.9355 8.79189 83.2129L5.50484 86.5L8.79189 89.7871C9.06937 90.0645 9.06937 90.5144 8.79189 90.7919C8.51441 91.0694 8.06453 91.0694 7.78706 90.7919L4.5 87.5048L1.21294 90.7919C0.935467 91.0694 0.485586 91.0694 0.208108 90.7919C-0.0693695 90.5144 -0.0693695 90.0645 0.208108 89.7871L3.49517 86.5L0.208117 83.2129C-0.0693606 82.9355 -0.0693603 82.4856 0.208118 82.2081Z"
        fill="#FF6062"
        className="Flag"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M172.208 82.2081C172.486 81.9306 172.935 81.9306 173.213 82.2081L176.5 85.4952L179.787 82.2081C180.065 81.9306 180.514 81.9306 180.792 82.2081C181.069 82.4856 181.069 82.9355 180.792 83.2129L177.505 86.5L180.792 89.7871C181.069 90.0645 181.069 90.5144 180.792 90.7919C180.514 91.0694 180.065 91.0694 179.787 90.7919L176.5 87.5048L173.213 90.7919C172.935 91.0694 172.486 91.0694 172.208 90.7919C171.931 90.5144 171.931 90.0645 172.208 89.7871L175.495 86.5L172.208 83.2129C171.931 82.9355 171.931 82.4856 172.208 82.2081Z"
        fill="#FF6062"
        className="Flag"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.162 41.1619C120.378 40.946 120.728 40.946 120.943 41.1619L123.5 43.7185L126.057 41.1619C126.272 40.946 126.622 40.946 126.838 41.1619C127.054 41.3777 127.054 41.7276 126.838 41.9434L124.282 44.5L126.838 47.0566C127.054 47.2724 127.054 47.6223 126.838 47.8381C126.622 48.054 126.272 48.054 126.057 47.8381L123.5 45.2815L120.943 47.8381C120.728 48.054 120.378 48.054 120.162 47.8381C119.946 47.6223 119.946 47.2724 120.162 47.0566L122.718 44.5L120.162 41.9434C119.946 41.7276 119.946 41.3777 120.162 41.1619Z"
        fill="#FF6062"
        className="Flag"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.1156 0.115616C37.2698 -0.0385387 37.5197 -0.0385385 37.6739 0.115616L39.5 1.94176L41.3261 0.115616C41.4803 -0.0385386 41.7302 -0.0385386 41.8844 0.115616C42.0385 0.26977 42.0385 0.519704 41.8844 0.673858L40.0582 2.5L41.8844 4.32614C42.0385 4.4803 42.0385 4.73023 41.8844 4.88438C41.7302 5.03854 41.4803 5.03854 41.3261 4.88438L39.5 3.05824L37.6739 4.88438C37.5197 5.03854 37.2698 5.03854 37.1156 4.88438C36.9615 4.73023 36.9615 4.4803 37.1156 4.32614L38.9418 2.5L37.1156 0.673858C36.9615 0.519703 36.9615 0.26977 37.1156 0.115616Z"
        fill="#FF6062"
        className="Flag"
      />
    </Box>
  );
}

export default FailedIcon;
