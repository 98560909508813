import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { List, ListItemText, Box, Alert, ListItemButton, Typography, Button } from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router";
import Scrollbar from "../custom-scroll/Scrollbar";
import { createFetch } from "../../gql/queries/ticket";
import useQuery from "../../hooks/allQuery";

function ChatListing() {
  const navigate = useNavigate();
  const [ticketsFetch, setTicketsFetch] = useState([]);
  const { userData } = useSelector(state => state.reducer);
  const [CREATE_FETCH] = useLazyQuery(createFetch, { fetchPolicy: "cache-first" });
  const { allQuery } = useQuery();

  useEffect(() => {
    if (userData?.id) {
      allQuery({
        QUERY: CREATE_FETCH,
        variables: {
          userId: userData.id,
        },
        onCompleteFunc: ({ ticketgetAll }) => {
          setTicketsFetch(ticketgetAll);
        },
      });
    }
  }, [userData]);

  const handleClick = id => {
    navigate(`/support/chat/${id}`);
  };
  return (
    <Box>
      <List sx={{ px: 0 }}>
        <Box px={2.5} pb={1}>
          <Button id="basic-button" aria-haspopup="true" color="inherit">
            Recent Chat
          </Button>
        </Box>
        <Scrollbar
          sx={{
            height: { lg: "calc(100vh - 100px)", md: "100vh" },
            maxHeight: "600px",
          }}
        >
          {ticketsFetch && ticketsFetch?.length ? (
            ticketsFetch.map(chat => (
              <ListItemButton
                key={chat.ticketId}
                onClick={() => handleClick(chat?.ticketId)}
                sx={{
                  mb: 0.5,
                  py: 2,
                  px: 3,
                  alignItems: "start",
                }}
              >
                <ListItemText
                  primary={
                    <Typography sx={{ textOverflow: "ellipsis", overflow: "hidden" }} variant="subtitle2" fontWeight={600} mb={0.5}>
                      {chat?.ticketId}
                    </Typography>
                  }
                  secondaryTypographyProps={{
                    noWrap: true,
                  }}
                  sx={{ my: 0 }}
                />
                <Box sx={{ flexShrink: "0" }} mt={0.5}>
                  <Typography variant="body2">
                    {formatDistanceToNowStrict(new Date(chat?.createdAt), {
                      addSuffix: false,
                    })}
                  </Typography>
                </Box>
              </ListItemButton>
            ))
          ) : (
            <Box m={2}>
              <Alert severity="error" variant="filled" sx={{ color: "white" }}>
                No Tickets Found!
              </Alert>
            </Box>
          )}
        </Scrollbar>
      </List>
    </Box>
  );
}

export default ChatListing;
