/* eslint-disable */
import { useTheme } from "@mui/material/styles";
import { Card, CardContent, Typography, Stack, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { memo } from "react";

const DashboardCard = ({ title, subtitle, children, action, footer, cardheading, headtitle, headsubtitle, middlecontent, ClassName }) => {
  const customizer = useSelector(state => state.customizer);

  const theme = useTheme();
  const borderColor = theme.palette.divider;

  return (
    <Card
      sx={{ padding: 0, border: !customizer.isCardShadow ? `1px solid ${borderColor}` : "none", "&.HeightFull": { height: "100%" } }}
      elevation={customizer.isCardShadow ? 9 : 0}
      variant={!customizer.isCardShadow ? "outlined" : undefined}
      className={ClassName}
    >
      {cardheading ? (
        <CardContent>
          <Typography variant="h5">{headtitle}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {headsubtitle}
          </Typography>
        </CardContent>
      ) : (
        <CardContent sx={{ p: "30px", "@media screen and (max-width: 1370px) and (min-width: 1199px)": { p: "15px" } }}>
          {title ? (
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems={"center"} mb={3}>
              <Box sx={{ "@media screen and (max-width: 1370px) and (min-width: 1199px)": { "& h5": { fontSize: "15px", lineHeight: "1.4" } } }}>
                {title ? <Typography variant="h5">{title}</Typography> : ""}

                {subtitle ? (
                  <Typography variant="subtitle2" color="textSecondary">
                    {subtitle}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
              {action}
            </Stack>
          ) : null}

          {children}
        </CardContent>
      )}

      {middlecontent}
      {footer}
    </Card>
  );
};

export default memo(DashboardCard);
