import { Box, CardContent, Grid, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import icon1 from "../../../Images/svgs/dashboard-top-card/Projects.svg";
import icon2 from "../../../Images/svgs/dashboard-top-card/CrawlLimit.svg";
import icon3 from "../../../Images/svgs/dashboard-top-card/BlogGenerator.svg";
import icon4 from "../../../Images/svgs/dashboard-top-card/Reports.svg";
import icon5 from "../../../Images/svgs/dashboard-top-card/SiteAudit.svg";
import icon6 from "../../../Images/svgs/dashboard-top-card/Invitations.svg";
import icon7 from "../../../Images/svgs/dashboard-top-card/TeamMembers.svg";
import icon8 from "../../../Images/svgs/dashboard-top-card/Organizations.svg";
import { GetScrapyData, GetTotalReportCount } from "../../../hooks/scrapyData";
import { AvailNotifications, FindUserById } from "../../../hooks/organization";
import useQuery from "../../../hooks/allQuery";
import { getOrgsByAdmin } from "../../../gql/queries/org";
import { projectDetails } from "../../../common/constant";

function TopCards() {
  const theme = useTheme();
  const [topCards, setTopCards] = useState(null);
  const [orgData, setOrgData] = useState([]);
  const { userData } = useSelector(state => state.reducer);
  const { scrapyData } = GetScrapyData();
  const { reportCountData } = GetTotalReportCount();
  const { allQuery } = useQuery();
  const [GET_ORGNS] = useLazyQuery(getOrgsByAdmin, {
    fetchPolicy: "cache-first",
  });

  const { data } = FindUserById(userData?.id);
  const { email } = userData || {};
  const { data: invitations } = AvailNotifications(email);

  const getOrganigationByAdmin = async () => {
    allQuery({
      QUERY: GET_ORGNS,
      variables: {
        authorId: userData?.id,
      },
      onCompleteFunc: ({ findOrgByAdmin }) => {
        if (findOrgByAdmin?.success) {
          setOrgData(findOrgByAdmin?.Orgdata || []);
        }
      },
      // onErrorFunc: err => {
      //   console.log(err);
      // },
    });
  };
  useEffect(() => {
    if (userData?.id) getOrganigationByAdmin();
  }, [userData?.id]);

  useEffect(() => {
    if (scrapyData && orgData && data && invitations) {
      setTopCards([
        {
          icon: icon1,
          title: "Projects",
          digits: `${projectDetails[userData?.subscriptionCredits?.subscriptionType].project - (userData?.subscriptionCredits?.projects || 0)}/${
            projectDetails[userData?.subscriptionCredits?.subscriptionType]?.project
          }`,
          bgcolor: "#4DC0FF",
          Link: "/siteAudit?menu=headerSiteAudit",
          // href: "#",
        },
        {
          icon: icon2,
          title: "Crawl Limit",
          digits: `${projectDetails[userData?.subscriptionCredits?.subscriptionType].siteAudit - (userData?.subscriptionCredits?.siteAudit || 0)}/${
            projectDetails[userData?.subscriptionCredits?.subscriptionType]?.siteAudit
          }`,
          bgcolor: "#FA896B",
          Link: `/crawl-table/${userData?.id}?limit=${userData?.crawlLimit || 0}`,
        },
        {
          icon: icon3,
          title: "Blog Generator",
          digits: `${projectDetails[userData?.subscriptionCredits?.subscriptionType].blogGeneration - (userData?.subscriptionCredits?.blogGeneration || 0)}/${
            projectDetails[userData?.subscriptionCredits?.subscriptionType]?.blogGeneration
          }`,
          bgcolor: "#13DEB9",
          Link: `/keywordsDashboard?menu=headerKeywords`,
        },
        {
          icon: icon4,
          title: "Reports",
          digits: reportCountData,
          bgcolor: "#539BFF",
        },
        {
          icon: icon5,
          title: "Site Audit",
          digits: "0/10000",
          bgcolor: "#6089FF",
          Link: `/crawl-table/${userData?.id}?limit=${userData?.crawlLimit || 0}`,
        },
        {
          icon: icon6,
          title: "Invitations",
          digits: "Coming Soon",
          bgcolor: "#13DEB9",
        },
        {
          icon: icon7,
          title: "Coming Soon",
          digits: "Team Members",
          bgcolor: "#A77D73",
        },
        {
          icon: icon8,
          title: "Coming Soon",
          digits: "Organizations",
          bgcolor: "#FFAF22",
        },
      ]);
    }
  }, [scrapyData, orgData, data, invitations, reportCountData]);

  return (
    topCards && (
      <Grid container spacing={2} mt={0}>
        {topCards.map(topcard => (
          <Grid item xs={12} sm={4} lg={3} key={topcard.title}>
            {topcard?.Link ? (
              <Link to={topcard?.Link}>
                <Box
                  textAlign="center"
                  sx={{
                    height: "100%",
                    borderRadius: "7px",
                    backgroundColor: topcard.bgcolor + "2b",
                    scale: "1",
                    transition: "all 1s ease",
                    "&:hover": { boxShadow: "inset 0 0 3px 3px" + topcard.bgcolor, "& .cardConteentWrapper": { scale: "1.1" } },
                    "& *": { transition: "all 1s ease" },
                  }}
                >
                  <CardContent
                    className="cardConteentWrapper"
                    sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", padding: "30px 14px !important" }}
                  >
                    <img src={topcard.icon} alt="topcard.icon" width="50" />
                    <Box>
                      <Typography sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#000000" }} variant="h5" fontWeight={700} lineHeight={1} mb="8px">
                        {topcard?.digits}
                      </Typography>
                      <Typography sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#000000" }} variant="subtitle1" fontWeight={600} lineHeight={1}>
                        {topcard.title}
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Link>
            ) : (
              <a href={topcard.href}>
                <Box textAlign="center" sx={{ height: "100%", borderRadius: "7px", backgroundColor: topcard.bgcolor + "2b" }}>
                  <CardContent sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", padding: "30px 14px !important" }}>
                    <img src={topcard.icon} alt="topcard.icon" width="50" />
                    <Box>
                      <Typography sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#000000" }} variant="h5" fontWeight={700} lineHeight={1} mb="8px">
                        {topcard?.digits}
                      </Typography>
                      <Typography sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#000000" }} variant="subtitle1" fontWeight={600} lineHeight={1}>
                        {topcard.title}
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </a>
            )}
          </Grid>
        ))}
      </Grid>
    )
  );
}

export default memo(TopCards);
