import gql from "graphql-tag";

export const SITE_AUDIT = gql`
  mutation Mutation($payload: SiteAuditInput!) {
    CreateSiteAudit(payload: $payload) {
      frequency
      projectId
      startDay
      website
      startTime
      endTime
      userId
      id
      isActive
      sessionId
      createdAt
      updatedAt
    }
  }
`;

export const AUDIT_DATA = gql`
  query Query($projectId: String!) {
    GetSiteAudit(projectId: $projectId) {
      frequency
      projectId
      startDay
      website
      startTime
      endTime
      userId
      id
      isActive
      sessionId
      createdAt
      updatedAt
    }
  }
`;

export const AUDIT_OFF = gql`
  mutation Mutation($projectId: String!) {
    SiteAuditOff(projectId: $projectId) {
      frequency
      projectId
      startDay
      website
      startTime
      endTime
      userId
      id
      isActive
      sessionId
      createdAt
      updatedAt
    }
  }
`;
