function CameraIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none" className="CameraIcon">
      <path
        d="M15.875 2.625H13.5703C13.4531 2.625 13.3078 2.54922 13.1945 2.42969L12.1812 0.830469C12.1652 0.805089 12.1473 0.780934 12.1277 0.758203C11.7777 0.35 11.3047 0.125 10.7969 0.125H7.20312C6.69531 0.125 6.22227 0.35 5.87227 0.758203C5.85266 0.780934 5.83477 0.805089 5.81875 0.830469L4.80547 2.43203C4.71875 2.52656 4.59688 2.62734 4.46875 2.62734V2.31484C4.46875 2.14908 4.4029 1.99011 4.28569 1.8729C4.16848 1.75569 4.00951 1.68984 3.84375 1.68984H2.90625C2.74049 1.68984 2.58152 1.75569 2.46431 1.8729C2.3471 1.99011 2.28125 2.14908 2.28125 2.31484V2.62734H2.125C1.62788 2.62786 1.15126 2.82557 0.799746 3.17709C0.448227 3.52861 0.250517 4.00522 0.25 4.50234V12C0.250517 12.4971 0.448227 12.9737 0.799746 13.3253C1.15126 13.6768 1.62788 13.8745 2.125 13.875H15.875C16.3721 13.8745 16.8487 13.6768 17.2003 13.3253C17.5518 12.9737 17.7495 12.4971 17.75 12V4.5C17.7495 4.00288 17.5518 3.52626 17.2003 3.17475C16.8487 2.82323 16.3721 2.62552 15.875 2.625ZM9 11.375C8.25832 11.375 7.5333 11.1551 6.91661 10.743C6.29993 10.331 5.81928 9.74529 5.53545 9.06006C5.25162 8.37484 5.17736 7.62084 5.32206 6.89341C5.46675 6.16598 5.8239 5.4978 6.34835 4.97335C6.8728 4.4489 7.54098 4.09175 8.26841 3.94706C8.99584 3.80236 9.74984 3.87662 10.4351 4.16045C11.1203 4.44428 11.706 4.92493 12.118 5.54161C12.5301 6.1583 12.75 6.88332 12.75 7.625C12.7489 8.61921 12.3534 9.57238 11.6504 10.2754C10.9474 10.9784 9.99421 11.3739 9 11.375Z"
        fill="#ffffff"
      />
    </svg>
  );
}

export default CameraIcon;
