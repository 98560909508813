import { useTheme } from "@mui/material";

function BrushShape() {
  const theme = useTheme();
  return (
    <svg width="140" height="12" fill="none" xmlns="http://www.w3.org/2000/svg" className="BrushShape">
      <path fill="none" d="M0 0h140v12H0z" />
      <path
        d="M127 3.9c-.418-.63-2.885-.394-6-.9-2.25-.366.9-.906-2.399-1.2C102.064.326 74.251-.212 54.146.539c-14.167.516-40.068 2.32-40.04 5.497.026.806 1.51 2.958 0 3.864-1.51.906-2.106 1.5 1.394.9 3.09-.53 8.374-1.414 11-1.783 42.053-5.908 73.663-5.438 91-4.74 10.18.411 9.938.3 9.5-.377z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
}

export default BrushShape;
