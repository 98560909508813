export const emailValid = ({ email }) => {
  const emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  return emailRegex.test(email);
};

export const ValidationUri = ({ uri }) => {
  const regex = /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})(?:\/|$)/;
  // const urlRegex = /^(http[s]?:\/\/)?([w]{3}\.)?[a-zA-Z0-9\-\.]{2,}\.[a-zA-Z]{2,}(\/.*)?$/;
  return regex.test(uri);
};

export const ValidationHttp = ({ url }) => {
  const httpRegex = /^https?:\/\//i;
  return httpRegex.test(url);
};

export const ValidateGSTIN = data => {
  const gstinRegex = /^(\d{2})([A-Z]{5})(\d{4})([A-Z]{1})(\d{1})([A-Z\d]{1})(\w{1})([A-Z\d]{1})$/;
  // const gstin = "29ABCDE1234F1Z5"; // Example GSTIN
  const isValidGstin = gstinRegex.test(data);
  return isValidGstin;
};
