import gql from "graphql-tag";

export const createFetch = gql`
  query Query($userId: String!) {
    ticketgetAll(userId: $userId) {
      Status
      comment
      createdAt
      description
      id
      image
      isDeleted
      messages
      name
      show
      type
      updatedAt
      userId
      ticketId
    }
  }
`;

export const deleteTicket = gql`
  query Query($ticketIsDeleteId: String!) {
    ticketIsDelete(id: $ticketIsDeleteId)
  }
`;

export const getChat = gql`
  query Query($chatFindUniqueId: String!) {
    chatFindUnique(id: $chatFindUniqueId)
  }
`;

export const getTicket = gql`
  query Query($ticketFindUniqueId: String!) {
    ticketFindUnique(id: $ticketFindUniqueId)
  }
`;

export const messageDelete = gql`
  query Query($payload: deleteInput!) {
    msgDelete(payload: $payload)
  }
`;
export const GET_ALL_TICKET_GRAPH_VALUE = gql`
  query Query($timeStamp: SuperAdmintimeStamp!) {
    getGraphAllTicketsForSuperAdmin(timeStamp: $timeStamp)
  }
`;
