import gql from "graphql-tag";

export const uptimeMonitoring = gql`
  mutation CreateUptime($data: UpTimeInputs!) {
    createUptime(data: $data) {
      userId
      projectId
      isMonitoring
      domain
      gmails
    }
  }
`;
export const uptimeGmailDelete = gql`
  mutation DeleteEmail($projectId: String!, $index: Int!) {
    deleteEmail(projectId: $projectId, index: $index) {
      gmails
    }
  }
`;
