import gql from "graphql-tag";

export const getAllNotes = gql`
  query Query($userId: String!, $searchOptions: SearchOptionsDto) {
    getAllNotesByUserId(userId: $userId, searchOptions: $searchOptions) {
      color
      createdAt
      id
      note
      updatedAt
      userId
    }
  }
`;
