/* eslint-disable */

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = ["Scope", "Ownership", "Site Audit"];

export default function MannualSteps({ activeStep }) {
  const stepsBox = {
    width: "100%",
    "& .MuiStepConnector-line": {
      borderTopStyle: "dotted",
      borderWidth: "3px",
      borderColor: "#757575",
    },
    "& .MuiStepConnector-root": {
      top: "15px",
    },
    "& .MuiStepLabel-alternativeLabel": {
      marginTop: "2px !important",
    },
    "&  .Mui-active": {
      color: "#45C0CB !important",
    },
    "& .Mui-completed": {
      color: "#45C0CB !important",
    },
  };
  return (
    <Box sx={stepsBox}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel className="activeStep">{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
