export const saveWallet = payload => ({
  type: "SAVE_USER",
  payload,
});

export const setLightTheme = payload => {
  localStorage.setItem("theme", "light");
  return {
    type: "SET_THEME",
    payload,
  };
};

export const setDarkTheme = payload => {
  localStorage.setItem("theme", "dark");
  return {
    type: "SET_THEME",
    payload,
  };
};
export const loginUser = payload => ({
  type: "LOGIN",
  payload,
});
export const SignupUser = payload => ({
  type: "SIGNUP",
  payload,
});

export const updateUser = payload => ({
  type: "UPDATEUSER",
  payload,
});
export const updateImage = payload => ({
  type: "UPDATE_IMAGE",
  payload,
});
export const updateCrawlLimit = payload => ({
  type: "UPDATE_CRAWL_LIMIT",
  payload,
});
export const logoutUser = payload => ({
  type: "LOG_OUT",
  payload,
});

export const ownwerDomainCreate = payload => ({
  type: "OWERNER",
  payload,
});

export const controlInvitationPopup = payload => ({
  type: "INVITATION_POPUP",
  payload,
});

export const hendelLoading = payload => ({
  type: "SAVE_LOADING",
  payload,
});

// new customizer actions

export const setThemeConfig = config => ({
  type: "SET_THEME_CONFIG",
  payload: config,
});

export const setTheme = theme => ({
  type: "SET_THEME",
  payload: theme,
});

export const setDarkMode = mode => ({
  type: "SET_DARK_MODE",
  payload: mode,
});

export const setDir = dir => ({
  type: "SET_DIR",
  payload: dir,
});

export const setLanguage = language => ({
  type: "SET_LANGUAGE",
  payload: language,
});

export const setCardShadow = shadow => ({
  type: "SET_CARD_SHADOW",
  payload: shadow,
});

export const toggleSidebar = () => ({
  type: "TOGGLE_SIDEBAR",
});

export const hoverSidebar = isHovered => ({
  type: "HOVER_SIDEBAR",
  payload: isHovered,
});

export const toggleMobileSidebar = () => ({
  type: "TOGGLE_MOBILE_SIDEBAR",
});

export const toggleLayout = layout => ({
  type: "TOGGLE_LAYOUT",
  payload: layout,
});

export const toggleHorizontal = isHorizontal => ({
  type: "TOGGLE_HORIZONTAL",
  payload: isHorizontal,
});

export const setBorderRadius = radius => ({
  type: "SET_BORDER_RADIUS",
  payload: radius,
});

export const getTickets = tickets => ({
  type: "GET_TICKETS",
  payload: tickets,
});

export const setVisibilityFilter = filter => ({
  type: "SET_VISIBILITY_FILTER",
  payload: filter,
});

export const searchTicket = searchTerm => ({
  type: "SEARCH_TICKET",
  payload: searchTerm,
});

export const deleteTicket = ticketId => ({
  type: "DELETE_TICKET",
  payload: ticketId,
});

export const setTicket = id => ({
  type: "SET_TICKET",
  payload: id,
});
export const addFlag = data => ({
  type: "ADD_FLAG",
  payload: data,
});
