/* eslint-disable */
import { Box } from "@mui/material";
import { useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

function Chart({ projectId, srapySessionData }) {
  const [size, setsize] = useState(210);

  let graphData = [];
  if (Array.isArray(srapySessionData)) {
    graphData = (srapySessionData || [])
      .map(
        (data, index) =>
          !data?.isFailed &&
          data?.isCompleted && {
            Issues: data.issues || 0,
            Warning: data.warnings || 0,
            Notices: data.notices || 0,
            createdAt: data.createdAt || 0,
            ReportNo: index,
          },
      )
      .filter(Boolean);

    graphData.reverse();
  }
  return (
    <Box sx={{ width: "100%", height: 200 }}>
      <ResponsiveContainer width="100%" height={size}>
        <LineChart data={graphData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          {/* <XAxis tickMargin={10} interval={10} dataKey="ReportNo" /> */}
          {/* <YAxis type="number" domain={["Issues", "Warning", "Notices"]} /> */}
          <Tooltip />
          <Legend />

          <Line type="monotone" dataKey="Issues" stroke="#EA521D" activeDot={{ r: 8 }} strokeWidth={2} />
          <Line type="monotone" dataKey="Warning" stroke="#FFD700" strokeWidth={2} />
          <Line type="monotone" dataKey="Notices" stroke="#0057B7" strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default Chart;
