import { useState } from "react";
import { IconButton, Box, Popover, TextField, TextareaAutosize, styled, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import EmojiPicker, { EmojiStyle, Emoji } from "emoji-picker-react";
import { IconMoodSmile, IconPaperclip, IconSend } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router";
import { newMessage } from "../../gql/mutations/ticket";
import useQuery from "../../hooks/allQuery";
import { convertToBase64 } from "../../utils/myUtills";
import { getTicket } from "../../gql/queries/ticket";
const ChatInputWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  "& textarea": {
    width: "100%",
    border: "none",
    fontSize: "13px",

    backgroundColor: theme.palette.text.primary + "0d",
    paddingTop: "11px",
    paddingRight: "10px",
    paddingLeft: "18px",
    borderRadius: "25px",
    color: theme.palette.text.primary,
    "&::-webkit-scrollbar-track": {
      WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      borderRadius: "10px",
      backgroundColor: "#F5F5F5",
    },
    "&::-webkit-scrollbar": { width: "0px", backgroundColor: "transparent" },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
      backgroundColor: theme.palette.primary.main,
    },
    "&[name=none]": { resize: "none" },
    "&:focus-visible": { outline: "none" },
    "&::placeholder": { fontSize: "13px", color: theme.palette.text.primary, opacity: 0.42, transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms" },
  },
  "& .ImageAttachmentWrapper": {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "start",
    gap: "15px",
    marginTop: "10px",
    "& .ImageWrapper": {
      position: "relative",
      width: "calc(16.6% - 15px)",
      height: "103px",
      backgroundColor: theme.palette.text.primary + "0d",
      padding: "8px",
      borderRadius: "5px",
      "& img": { width: "100%", height: "100%", objectFit: "cover", objectPosition: "top" },
      "& svg": {
        backgroundColor: theme.palette.text.primary + "0d",
        color: theme.palette.primary.main,
        position: "absolute",
        top: "-12px",
        right: "-12px",
        borderRadius: "50px",
        padding: "2px",
        cursor: "pointer",
      },
    },
  },
}));
function ChatMsgSentAdmin() {
  const [msg, setMsg] = useState("");
  const [image, setImage] = useState("");
  const [doc, setDoc] = useState("");
  const [NEW_MSG] = useMutation(newMessage, { fetchPolicy: "network-only" });
  const { userData } = useSelector(state => state.reducer);
  const { id } = useParams();
  const { allQueryObject } = useQuery();
  const [anchorEl, setAnchorEl] = useState(null);
  const [chosenEmoji, setChosenEmoji] = useState("");
  const onEmojiClick = emojiData => {
    setChosenEmoji(emojiData.unified);
    setMsg(emojiData.emoji);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChatMsgChange = async e => {
    const { name, value } = e.target;

    if (name === "image") {
      const file = e.target.files[0];
      if (!file) {
        return;
      }

      if (file.type.split("/")[0] !== "image") {
        const fileName = file.name;
        const fileSize = (file.size / (1024 * 1024)).toFixed(2);
        setDoc({ file: fileName, fileSize });
      } else {
        const base64 = await convertToBase64(file);
        setImage(base64);
      }
    } else {
      setMsg(value);
    }
  };
  const onChatMsgSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    allQueryObject({
      QUERY: NEW_MSG,
      variables: {
        updateData: {
          ticketId: id,
          msg,
          image,
          doc,
          userId: userData?.id,
        },
      },
      type: "update",
      cacheQueryData: {
        cacheQuery: getTicket,
        variablesCache: {
          ticketFindUniqueId: id,
        },
      },
    });
    setMsg("");
    setImage("");
    setDoc("");
  };
  const handleImageRemove = () => {
    setImage(null);
  };
  return (
    <Box p={2}>
      {/* ------------------------------------------- */}
      {/* sent chat */}
      {/* ------------------------------------------- */}
      <form onSubmit={onChatMsgSubmit} style={{ display: "flex", gap: "10px", alignItems: "start" }}>
        {/* ------------------------------------------- */}
        {/* Emoji picker */}
        {/* ------------------------------------------- */}
        <IconButton aria-label="more" id="long-button" aria-controls="long-menu" aria-expanded="true" aria-haspopup="true" onClick={handleClick}>
          <IconMoodSmile />
        </IconButton>
        <Popover
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <EmojiPicker onEmojiClick={onEmojiClick} />
          <Box p={2}>Selected: {chosenEmoji ? <Emoji unified={chosenEmoji} emojiStyle={EmojiStyle.APPLE} size={22} /> : ""}</Box>
        </Popover>
        <ChatInputWrapper>
          <TextareaAutosize
            id="msg-sent"
            fullWidth
            value={msg}
            placeholder="Type a Message"
            size="small"
            type="text"
            inputProps={{ "aria-label": "Type a Message" }}
            onChange={handleChatMsgChange}
            minRows={1.5}
            maxRows={5}
            name="none"
          />
          {image && (
            <Box className="ImageAttachmentWrapper">
              {/* ---- Start Map ---- */}
              <Box className="ImageWrapper">
                <Typography component="img" src={image} alt="Selected Images" />
                <CancelOutlinedIcon onClick={handleImageRemove} />
              </Box>
              {/* ---- End Map ---- */}
            </Box>
          )}
        </ChatInputWrapper>
        <IconButton aria-label="delete" onClick={onChatMsgSubmit} disabled={!msg && !image} color="primary">
          <IconSend stroke={1.5} size="20" />
        </IconButton>
        <IconButton component="label" onChange={handleChatMsgChange}>
          <IconPaperclip stroke={1.5} size="20" />
          <TextField type="file" name="image" style={{ display: "none" }} />
        </IconButton>
      </form>
    </Box>
  );
}

export default ChatMsgSentAdmin;
