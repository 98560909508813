/* eslint-disable */
import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Modal, styled, Typography, useTheme } from "@mui/material";
import { IconCheck } from "@tabler/icons-react";
import { projectDetails } from "../../../common/constant";
import { useSelector } from "react-redux";

const BoxWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: theme.palette.background.default,
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: "6px",
  padding: "30px 50px",
  "& .BtnWrapper": {
    "& button": {
      textTransform: "none",
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
      borderRadius: "5px",
      boxShadow: "none",
      padding: "7px 22px",
      whiteSpace: "nowrap",
      width: "100%",
      "&:focus": {
        outline: "none",
      },
      "&.OutlineBtn": {
        color: theme.palette.primary.main,
        backgroundColor: "transparent",
        border: `2px solid ${theme.palette.primary.main}`,
        padding: "6px 22px",
      },
    },
  },
}));

function PlanDetails({ open, handleClose, viewDetails }) {
  const { userData } = useSelector(state => state.reducer);
  const theme = useTheme();
  return (
    <Modal open={open}>
      <BoxWrapper>
        <Typography variant="subtitle1" fontSize="24px" mb={3} fontWeight={700} lineHeight={1} textAlign="center" color={theme.palette.mode === "dark" ? "#ffffff" : "#2A3547"}>
          {projectDetails[viewDetails ? viewDetails : userData?.subscriptionCredits?.subscriptionType]?.projectName}
        </Typography>
        <Box>
          <Box display="flex" justifyContent="center" color={theme.palette.mode === "dark" ? "#ffffff" : "#2A3547"}>
            <Typography fontSize="40px" fontWeight="700">
              ₹ {projectDetails[viewDetails ? viewDetails : userData?.subscriptionCredits?.subscriptionType]?.price}
            </Typography>
            <Typography fontSize="15px" fontWeight={400} ml={1} mt={1}>
              /monthly
            </Typography>
          </Box>
        </Box>
        <Box mt={2}>
          <List>
            <Box>
              <ListItem disableGutters>
                <ListItemIcon sx={{ color: "primary.main", minWidth: "32px" }}>
                  <IconCheck width={18} />
                </ListItemIcon>
                <ListItemText sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#212529" }}>
                  {projectDetails[viewDetails ? viewDetails : userData?.subscriptionCredits?.subscriptionType]?.project} Project
                </ListItemText>
              </ListItem>
            </Box>
            <Box>
              <ListItem disableGutters>
                <ListItemIcon sx={{ color: "primary.main", minWidth: "32px" }}>
                  <IconCheck width={18} />
                </ListItemIcon>
                <ListItemText sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#212529" }}>
                  {projectDetails[viewDetails ? viewDetails : userData?.subscriptionCredits?.subscriptionType]?.siteAudit} Site Audit
                </ListItemText>
              </ListItem>
            </Box>
            <Box>
              <ListItem disableGutters>
                <ListItemIcon sx={{ color: "primary.main", minWidth: "32px" }}>
                  <IconCheck width={18} />
                </ListItemIcon>
                <ListItemText sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#212529" }}>
                  {projectDetails[viewDetails ? viewDetails : userData?.subscriptionCredits?.subscriptionType]?.blogGeneration} Blog Generation
                </ListItemText>
              </ListItem>
            </Box>
          </List>
        </Box>
        <Box className="BtnWrapper">
          <Button className="OutlineBtn" onClick={handleClose}>
            Back
          </Button>
        </Box>
      </BoxWrapper>
    </Modal>
  );
}

export default PlanDetails;
