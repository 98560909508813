import { useState, useEffect, useMemo } from "react";
import { Box, Container, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { IconMenu2 } from "@tabler/icons-react";
import Breadcrumb from "../shared/Breadcrumb";
import AppCard from "../shared/AppCard";
import NoteContent from "./notesitem/NoteContent";
import NoteSidebar from "./notesitem/NoteSidebar";
import { getAllNotes } from "../../gql/queries/notes";
import useQuery from "../../hooks/allQuery";
import AddNotes from "./notesitem/AddNotes";
import MainLoader from "../MainLoader/MainLoader";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  padding: "30px 0px 60px",
  "& h4": {
    color: theme.palette.text.primary,
  },
  "& button:focus": {
    border: "nne",
    outline: "none",
  },
  "& .cancle": {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
}));

const BCrumb = [
  {
    to: "/",
    title: "Home",
  },
  {
    title: "Notes",
  },
];

function Notes() {
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(true);
  const [notesFilter, setNotesFilter] = useState({
    searchOptions: { searchTerm: "", selectedColors: [] },
  });
  const [noteDetails, setNoteDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allNotes, setAllNotes] = useState([]);
  const theme = useTheme();
  const { allQuery } = useQuery();
  const { userData } = useSelector(state => state.reducer);
  const lgDown = useMediaQuery(theme1 => theme1.breakpoints.down("lg"));
  const [GET_NOTES] = useLazyQuery(getAllNotes, {
    fetchPolicy: "cache-first",
  });

  const colorvariation = useMemo(
    () => [
      {
        id: 1,
        lineColor: theme.palette.warning.main,
        disp: "warning",
      },
      {
        id: 2,
        lineColor: theme.palette.info.main,
        disp: "info",
      },
      {
        id: 3,
        lineColor: theme.palette.error.main,
        disp: "error",
      },
      {
        id: 4,
        lineColor: theme.palette.success.main,
        disp: "success",
      },
      {
        id: 5,
        lineColor: theme.palette.primary.main,
        disp: "primary",
      },
    ],
    [theme],
  );

  useEffect(() => {
    if (lgDown) {
      setMobileSidebarOpen(!isMobileSidebarOpen);
    } else {
      setMobileSidebarOpen(true);
    }
  }, [lgDown]);

  useEffect(() => {
    if (userData?.id) {
      allQuery({
        QUERY: GET_NOTES,
        variables: {
          userId: userData.id,
          searchOptions: notesFilter.searchOptions,
        },
        onCompleteFunc: ({ getAllNotesByUserId }) => {
          setAllNotes(getAllNotesByUserId);
          const mostRecentNote = getAllNotesByUserId[getAllNotesByUserId.length - 1];
          setNoteDetails(mostRecentNote);
          setLoading(false);
        },
        onErrorFunc: err => {
          console.log(err);
          setLoading(false);
        },
      });
    }
  }, [userData, notesFilter.searchOptions]);
  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={12}>
              <Breadcrumb title="Notes" items={BCrumb} />
              {!loading ? (
                <AppCard>
                  {isMobileSidebarOpen && (
                    <NoteSidebar
                      isMobileSidebarOpen={isMobileSidebarOpen}
                      onSidebarClose={() => setMobileSidebarOpen(false)}
                      allNotes={allNotes}
                      noteDetails={noteDetails}
                      notesFilter={notesFilter}
                      setNotesFilter={setNotesFilter}
                      setNoteDetails={setNoteDetails}
                    />
                  )}

                  <Box flexGrow={1}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
                      <IconButton onClick={() => setMobileSidebarOpen(!isMobileSidebarOpen)}>
                        <IconMenu2 stroke={1.5} />
                      </IconButton>
                      <AddNotes colors={colorvariation} notesFilter={notesFilter} />
                    </Box>
                    <NoteContent allNotes={allNotes} notesFilter={notesFilter} noteDetails={noteDetails} setNoteDetails={setNoteDetails} colorvariation={colorvariation} />
                  </Box>
                </AppCard>
              ) : (
                <MainLoader message="Loading" />
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default Notes;
