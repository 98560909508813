/* eslint-disable */
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, split, Observable } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { setContext } from "@apollo/client/link/context";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "apollo-utilities";
import { GraphQLError } from "graphql/error";
import { recoverSession, getLocalTokens, deleteLocalTokens } from "../utils/session";
import { UseRefreshToken } from "../hooks/auth";

/* Configuration imported from '.env' file */
// const backendProtocol = process.env.REACT_APP_PROTOCOL;
const backendHost = process.env.REACT_APP_HOST;
const backendPort = process.env.REACT_APP_PORT;
const backendGraphql = process.env.REACT_APP_GRAPHQL;

// const backendAddress = `${backendProtocol}://${backendHost}:${backendPort}${backendGraphql}`;

const getAuthToken = () => {
  const tokens = getLocalTokens();
  return tokens?.accessToken;
};

let apolloClient;

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URI}/graphql`,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_WSS_URI,
    connectionParams: () => {
      return {
        authorization: `Bearer ${getAuthToken()}`,
      };
    },
  }),
);

const authMiddleware = new ApolloLink((operation, forward) => {
  // const tokens = UseRefreshToken();
  // const token = recoverSession("token");
  // const authorization = token ? `Bearer ${token}` : "";
  const tokens = getLocalTokens();
  const token = operation.operationName === "RefreshToken" ? tokens.refreshToken : tokens.accessToken;
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }));

  return forward(operation);
});

const authLink = setContext((operation, prevContext) => {
  const tokens = getLocalTokens();
  const token = operation.operationName === "RefreshToken" ? tokens.refreshToken : tokens.accessToken;

  if (!token) {
    return prevContext;
  }

  return {
    headers: {
      ...prevContext.headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  // dont remove commented code it just for testing

  // if (graphQLErrors) {
  //   graphQLErrors.forEach(err => {
  //     // err.message, err.locations, err.path, err.extensions
  //     if (err.extensions.code === "UNAUTHENTICATED" || err.extensions.code === "FORBIDDEN") {
  //       deleteSession();
  //       window.location.href = "/";
  //     }

  //     if (err.extensions.code === "INTERNAL_SERVER_ERROR") {
  //       // err.message = "An error has occurred";
  //     }
  //   });
  // }

  // if (networkError && networkError.response === "invalid_token") {
  //   deleteSession();
  //   window.location.href = "/";
  // }

  // const tokens = getLocalTokens();

  // if (!graphQLErrors) {
  //   return;
  // }

  // if (!tokens.refreshToken && !tokens.accessToken) {
  //   return;
  // }
  // // if (graphQLErrors[0].extensions.code !== "invalid-jwt") {
  // //   return;
  // // }

  // if (graphQLErrors[0].extensions.code !== "UNAUTHENTICATED") {
  //   return;
  // }

  // if (operation.operationName == "RefreshToken" && graphQLErrors[0].extensions.code == "UNAUTHENTICATED") {
  //   deleteLocalTokens();
  //   return;
  // }

  // return new Observable(observer => {
  //   (async () => {
  //     try {
  //       const accessToken = await UseRefreshToken();
  //       const oldHeaders = operation.getContext().headers;
  //       operation.setContext({
  //         headers: {
  //           ...oldHeaders,
  //           authorization: `Bearer ${accessToken}`,
  //         },
  //       });

  //       const subscriber = {
  //         next: observer.next.bind(observer),
  //         error: observer.error.bind(observer),
  //         complete: observer.complete.bind(observer),
  //       };

  //       forward(operation).subscribe(subscriber);
  //     } catch {
  //       throw new GraphQLError("Missing tokens");
  //     }
  //   })();
  // });

  const tokens = getLocalTokens();

  if (!graphQLErrors) {
    return;
  }

  if (!tokens.refreshToken && !tokens.accessToken) {
    return;
  }

  if (graphQLErrors && graphQLErrors[0]?.extensions?.code === "UNAUTHENTICATED" && operation?.operationName === "RefreshToken") {
    return;
  }

  if (graphQLErrors && graphQLErrors[0]?.extensions?.code !== "UNAUTHENTICATED") {
    return;
  }

  return new Observable(observer => {
    (async () => {
      try {
        const accessToken = await UseRefreshToken(apolloClient);

        const oldHeaders = operation.getContext().headers;
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${accessToken}`,
          },
        });

        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };

        forward(operation).subscribe(subscriber);
      } catch {
        deleteLocalTokens();
        window.location.href = "/";
        throw new GraphQLError("Missing tokens");
      }
    })();
  });
});

const wslinks = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink,
);
const apolloLink = ApolloLink.from([authLink, errorLink, wslinks]);

apolloClient = new ApolloClient({
  link: apolloLink,
  cache: new InMemoryCache(),
});

export default apolloClient;
