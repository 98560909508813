import { useNavigate } from "react-router";
import { Box, useMediaQuery } from "@mui/material";
import ReactApexChart from "react-apexcharts";

function DonutChart({ totalValue = 100, data, title, heading, projectId }) {
  const is1200To1300Breakpoint = useMediaQuery("(max-width: 1300px) and (min-width: 1200px)");
  const navigate = useNavigate();

  const options = {
    chart: {
      width: totalValue,
      type: "donut",
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const seriesIndex = config.dataPointIndex;
          if (seriesIndex || seriesIndex === 0 || seriesIndex !== undefined) {
            setTimeout(() => {
              switch (seriesIndex) {
                case 0:
                  navigate(`/websitePerformance/${projectId}/${title}/${heading}/Good?menu=performanceDashboard`);
                  break;
                case 1:
                  navigate(`/websitePerformance/${projectId}/${title}/${heading}/Avg?menu=performanceDashboard`);
                  break;
                case 2:
                  navigate(`/websitePerformance/${projectId}/${title}/${heading}/Poor?menu=performanceDashboard`);
                  break;
                default:
                  navigate(`/websitePerformance/${projectId}/${title}/${heading}/Good?menu=performanceDashboard`);
                  break;
              }
            }, 0);
          }
        },
      },
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      // Colors representing good URLs (green), average URLs (yellow), bad URLs (red)
      colors: ["#34E3C2", "#FFB93E", "#FF6347"], // Green, Yellow, Red
    },
    stroke: {
      // Colors for the borders of the segments, same as fill colors
      colors: ["#34E3C2", "#FFB93E", "#FF6347"], // Green, Yellow, Red
    },
    legend: {
      show: false,
    },
    labels: ["Good URLs", "Average URLs", "Poor URLs"],

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <Box sx={{ "& .apexcharts-canvas": { ml: "auto", mr: "auto" } }}>
      <div id="chart">
        <ReactApexChart options={options} series={data} type="donut" width={is1200To1300Breakpoint ? 170 : 220} />
      </div>
    </Box>
  );
}

export default DonutChart;
