import gql from "graphql-tag";

export const GET_ALL_PRODUCTS = gql`
  query GetAllProducts {
    getAllProducts {
      amount
      id
      name
    }
  }
`;
export const GET_USER_SUBSCRIPTION_PLAN = gql`
  query Query {
    getUserPlan
  }
`;
