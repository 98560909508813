import gql from "graphql-tag";

export const getLinks = gql`
  query Query($sessionId: String!, $flag: String!) {
    GetScrapyLinks(sessionId: $sessionId, flag: $flag)
  }
`;
export const GET_GRAPH_DATA = gql`
  query Query($flag: String!, $projectId: String!) {
    GraphShowByCreateAt(flag: $flag, projectId: $projectId)
  }
`;
export const getLinks200 = gql`
  query Query($sessionId: String!) {
    GetLinks200(sessionId: $sessionId)
  }
`;
export const GET_LIST_DATA = gql`
  query Query($projectId: String!) {
    getAllScrapySessionId(projectId: $projectId)
  }
`;

export const reportCount = gql`
  query Query($reportCountId: String!) {
    reportCount(id: $reportCountId)
  }
`;

export const issueDetailsPagination = gql`
  query Query($inputArg: PageinationInput!) {
    GetIssueDetailPagination(inputArg: $inputArg)
  }
`;

export const tableBodyDataBySessionId = gql`
  query Query($flag: String!, $sessionId: String!) {
    allTablebodyDataBySessionId(flag: $flag, session_id: $sessionId)
  }
`;
