import { memo } from "react";
import { useParams } from "react-router";
import { Box, Typography, Card, CardContent, Grid } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import welcomeImage from "../../Images/breadcrumb/ChatBc.png";
import { GetUserWebsiteData } from "../../hooks/userWebsiteData";

function MainTitle() {
  const { projectid } = useParams();
  const { ProjectData } = GetUserWebsiteData(projectid);
  const { website, projectName } = ProjectData || {};
  const domain = website?.replace(/^https?:\/\//i, "").split("/")[0];
  return (
    <Card elevation={0} sx={{ backgroundColor: theme => theme.palette.primary.light, py: 0, height: "100%" }}>
      <CardContent sx={{ py: 4, px: 2 }}>
        <Grid container>
          <Grid item xs={12} sm={6} lg={8} mb={1}>
            <Typography variant="h4">Uptime Monitoring</Typography>
            <Typography color="textSecondary" variant="h6" fontWeight={400} mt={0.8} mb={0}>
              {projectName || ""}&nbsp;&nbsp;
              <Typography component="a" href={website} target="_blank">
                {domain || ""}
                <Typography component="span" sx={{ "& svg": { width: "15px", ml: "2px", height: "15px" } }}>
                  <LaunchIcon />
                </Typography>
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} display="flex" alignItems="flex-end">
            <Box
              sx={{
                display: { xs: "none", md: "block", lg: "flex" },
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
                position: "relative",
              }}
            >
              <Box sx={{ mb: "-82px", mt: "-82px", "& img": { width: "165px" } }}>
                <Typography component="img" src={welcomeImage} alt="" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default memo(MainTitle);
