import Stack from "@mui/material/Stack";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { controlInvitationPopup } from "../../../redux/action";
import { AcceptInvitation } from "../../../hooks/organization";
import { getAuthToken, graphqlErrorParse } from "../../../common/utils";
import { MarkAsReadApi } from "../../../gql/mutations/org";

const SectionWrapper = {
  borderBottom: "1px solid #cecece",
  pt: "10px",
  pb: "10px",
  "&:last-child": { borderBottom: "none" },
};
const BoxMainWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  pt: "5px",
  pb: "5px",

  "& p": {
    fontSize: "12px",
    mr: "10px",
    "& strong": { fontSize: "12px", fontWeight: "700" },
  },
};
const ButtonsWrapper = {
  "& button": {
    textTransform: "none",
    background: "#ff642d",
    color: "#fff",
    borderRadius: "50px",
    border: "1px solid #ff642d",
    fontSize: "10px",
    padding: "3px 10px 0px",
    mr: "4px",
    "&:last-child": { mr: "0px" },
    "&:hover": {
      backgroundColor: "transparent",
      color: "#ff642d",
    },
  },
};

function Invitations({ data = {}, refresh, notFound = false }) {
  const { handleAccept } = AcceptInvitation({
    token: getAuthToken(),
    otp: data.token,
    organizationId: data.organizationId,
  });
  const { invitationsOrgData } = data || {};
  // const { name } = invitationsOrgData || {};
  // const { data: invitations } = AvailNotifications(email);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [MarkAsRead] = useMutation(MarkAsReadApi);

  const handleClickMarkAs = async id => {
    MarkAsRead({
      variables: {
        invitationId: id,
      },
      onCompleted: resData => {
        console.log("data message", resData);
      },
      onError: errdata => {
        console.log("errdata", errdata);
      },
    })
      .then(() => {
        refresh();
      })
      .catch(() => {
        refresh();
      });
  };

  const handleClick = async () => {
    await handleAccept()
      .then(res => {
        if (res && res.data && res.data.varifyMember) {
          dispatch(controlInvitationPopup(false));
          toast.success("You are added successfully in " + res.data.varifyMember.name + " Organization");
          refresh();
        }
        return res;
      })
      .catch(err => {
        const errJson = graphqlErrorParse({ err });
        toast.error(errJson.message);
        refresh();
        return err;
      });
  };

  return (
    <Stack sx={SectionWrapper}>
      {notFound ? (
        <Typography> No Notification Found</Typography>
      ) : (
        <Box sx={BoxMainWrapper}>
          <Typography component="p">
            You Are Invited to <Typography component="strong"> {invitationsOrgData?.name && invitationsOrgData?.name} </Typography> organization
          </Typography>
          <Box sx={ButtonsWrapper}>
            {" "}
            <Button
              onClick={() => {
                navigate(`/org/${data.organizationId}`);
                // dispatch(controlInvitationPopup(false));
              }}
              variant="text"
            >
              Detail
            </Button>
            <Button variant="text" onClick={handleClick}>
              Accept
            </Button>
            {/* {MarkAsRead &&  } */}
            {!data.isOpened && (
              <Button variant="text" onClick={() => handleClickMarkAs(data.id)}>
                MarkAsRead
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Stack>
  );
}

export default Invitations;
