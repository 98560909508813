import { lazy, memo, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { Stack, Typography, Avatar, Box, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { IconGridDots } from "@tabler/icons-react";
import LaunchIcon from "@mui/icons-material/Launch";
import DashboardCard from "../../shared/DashboardCard";
import { convertISOToDateFormat } from "../../../utils/myUtills";

const Chart = lazy(() => import("react-apexcharts"));

function DonutChart({ options, series }) {
  return <Chart options={options} series={series} type="donut" width={280} />;
}

function AreaChart({ options, series }) {
  return <Chart options={options} series={series} type="area" height="230px" width="100%" />;
}

const GetScore = (data, issue) => {
  const itemWithNonZeroScore = data.find(item => Object.prototype.hasOwnProperty.call(item, issue) && item[issue] !== undefined && item[issue] !== null);
  return itemWithNonZeroScore
    ? { issueValue: itemWithNonZeroScore[issue], createdAt: convertISOToDateFormat(itemWithNonZeroScore.createdAt).formattedDate }
    : { issueValue: 98, createdAt: "7:16 AM 15/11/2023 IST" };
};

function WeeklyStats({ countData }) {
  // chart color
  const theme = useTheme();
  const navigate = useNavigate();
  const primary = theme.palette.primary.main;
  const primarylight = theme.palette.primary.light;
  const error = theme.palette.error.main;
  const errorlight = theme.palette.error.light;
  const secondary = theme.palette.success.main;
  const secondarylight = theme.palette.success.light;
  const params = useParams();
  const { GetGraphIssueCount = [] } = countData || {};
  const { basicCompleted, isCompleted, sessionId, projectId } = GetGraphIssueCount && GetGraphIssueCount.length && GetGraphIssueCount[0];
  const show = basicCompleted ? isCompleted : false;

  const checkIsData = useMemo(() => {
    const data = (GetGraphIssueCount || []).filter(({ isFailed, isCompleted: isReportCompleted }) => Boolean(!isFailed && Boolean(isReportCompleted)));
    if (data.length > 0) return true;
    return false;
  }, [GetGraphIssueCount]);

  const graphData = useMemo(() => {
    if (Array.isArray(GetGraphIssueCount) && GetGraphIssueCount.length > 0 && checkIsData) {
      return [
        {
          name: "Issues",
          color: error,
          data: (GetGraphIssueCount || [])
            .filter(({ isFailed, isCanceled }) => Boolean(!isFailed) && Boolean(!isCanceled))
            .map(data => data?.issues || 0)
            .reverse(),
        },
        {
          name: "Warnings",
          color: secondary,
          data: (GetGraphIssueCount || [])
            .filter(({ isFailed, isCanceled }) => Boolean(!isFailed) && Boolean(!isCanceled))
            .map(data => data?.warnings || 0)
            .reverse(),
        },
        {
          name: "Notices",
          color: primary,
          data: (GetGraphIssueCount || [])
            .filter(({ isFailed, isCanceled }) => Boolean(!isFailed) && Boolean(!isCanceled))
            .map(data => data?.notices || 0)
            .reverse(),
        },
      ];
    }
    return [
      {
        name: "Issues",
        color: error,
        data: [25, 56, 58, 74, 85, 98],
      },
      {
        name: "Warnings",
        color: secondary,
        data: [35, 26, 78, 73, 85, 48],
      },
      {
        name: "Notices",
        color: primary,
        data: [98, 51, 58, 74, 52, 38],
      },
    ];
  }, [GetGraphIssueCount, error, secondary, primary, checkIsData]);

  const optionscolumnchart = useMemo(() => {
    const newVar = {
      chart: {
        type: "area",
        fontFamily: "'Plus Jakarta Sans', sans-serif;",
        foreColor: "#adb0bb",
        toolbar: {
          show: false,
        },
        height: 130,
        sparkline: {
          enabled: true,
        },
        group: "sparklines",
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0,
          inverseColors: false,
          opacityFrom: 0.45,
          opacityTo: 0,
          stops: [20, 180],
        },
      },
      markers: {
        size: 0,
      },
      tooltip: {
        theme: theme.palette.mode === "dark" ? "dark" : "light",
        x: {
          show: false,
        },
      },
    };
    return newVar;
  }, [theme]);

  const stats = useMemo(
    () => [
      {
        title: "Issues",
        subtitle: (Array.isArray(GetGraphIssueCount) && GetScore(GetGraphIssueCount, "issues").createdAt) || "7:16 AM 15/11/2023 IST",
        percent: (Array.isArray(GetGraphIssueCount) && GetScore(GetGraphIssueCount, "issues").issueValue) || "0",
        color: error,
        lightcolor: errorlight,
        icon: <IconGridDots width={18} />,
        url: GetGraphIssueCount && GetGraphIssueCount.length ? `/project-detail/${sessionId}?show=${show}&origin=${projectId}&filter=error` : "",
      },
      {
        title: "Warnings",
        subtitle: (Array.isArray(GetGraphIssueCount) && GetScore(GetGraphIssueCount, "warnings").createdAt) || "7:16 AM 15/11/2023 IST",
        percent: (Array.isArray(GetGraphIssueCount) && GetScore(GetGraphIssueCount, "warnings").issueValue) || "0",
        color: secondary,
        lightcolor: secondarylight,
        icon: <IconGridDots width={18} />,
        url: GetGraphIssueCount && GetGraphIssueCount.length ? `/project-detail/${sessionId}?show=${show}&origin=${projectId}&filter=warning` : "",
      },
      {
        title: "Notices",
        subtitle: (Array.isArray(GetGraphIssueCount) && GetScore(GetGraphIssueCount, "notices").createdAt) || "7:16 AM 15/11/2023 IST",
        percent: (Array.isArray(GetGraphIssueCount) && GetScore(GetGraphIssueCount, "notices").issueValue) || "0",
        color: primary,
        lightcolor: primarylight,
        icon: <IconGridDots width={18} />,
        url: GetGraphIssueCount && GetGraphIssueCount.length ? `/project-detail/${sessionId}?show=${show}&origin=${projectId}&filter=info` : "",
      },
    ],
    [GetGraphIssueCount, secondary, secondarylight, primary, primarylight, error, errorlight, sessionId, show, projectId],
  );

  const optionss = {
    chart: {
      width: 280,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["Issues", "Notices", "Warnings"],

    fill: {
      colors: ["#FBB39F", "#5d87ff", "#13deb9"],
    },
    colors: ["#FBB39F", "#5d87ff", "#13deb9"],
    // title: {
    //   text: "Gradient Donut with custom Start-angle",
    // },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const donutDataSeries = useMemo(() => {
    if (GetGraphIssueCount && Array.isArray(GetGraphIssueCount) && GetGraphIssueCount.filter(({ isFailed }) => Boolean(!isFailed)).length === 1) {
      return { isDonut: true, series: [GetGraphIssueCount[0].issues, GetGraphIssueCount[0].notices, GetGraphIssueCount[0].warnings] };
    }
    return { isDonut: false, series: [0, 0, 0] };
  }, [GetGraphIssueCount]);

  return (
    <DashboardCard title="Reports Overview" ClassName="HeightFull">
      <>
        <Box
          sx={{
            "& .healthWrapper": {
              filter: "blur(2px)",
              pointerEvents: "none",
            },
          }}
        >
          {Array.isArray(GetGraphIssueCount) && GetGraphIssueCount.length > 0 && graphData.length > 0 ? (
            <Stack mt={4} className={!checkIsData ? "healthWrapper" : ""} sx={{ alignItems: "center" }}>
              {!donutDataSeries.isDonut ? (
                <AreaChart options={optionscolumnchart} series={graphData || []} />
              ) : (
                <DonutChart options={optionss} series={donutDataSeries.series || []} />
              )}
            </Stack>
          ) : (
            ""
          )}
        </Box>
        <Box
          sx={{
            "& .healthWrapper": {
              filter: "blur(2px)",
              pointerEvents: "none",
            },
          }}
        >
          <Stack spacing={3} mt={3} className={!checkIsData ? "healthWrapper" : ""}>
            {(stats || []).map(stat => (
              <MemorizedStatRow stat={stat} key={stat.title} />
            ))}
          </Stack>
        </Box>
        <Button
          sx={{ marginTop: "15px" }}
          onClick={() => {
            navigate(`/project-detail/${params?.sessionId}?show=${show}&origin=${params?.projectid}`);
          }}
        >
          View Latest Report
        </Button>
      </>
    </DashboardCard>
  );
}

export default WeeklyStats;

function StatRow({ stat }) {
  const navigate = useNavigate();
  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" spacing={2}>
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: stat.lightcolor,
            color: stat.color,
            width: 40,
            height: 40,
          }}
        >
          {stat.icon}
        </Avatar>
        <Box>
          <Typography
            variant="h6"
            mb="4px"
            onClick={() => {
              if (stat.percent > 0) {
                navigate(`${stat.url}`, { state: { isMobile: stat?.isMobile } });
              }
            }}
          >
            {stat.title} <LaunchIcon fontSize="16px" />
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {stat.subtitle}
          </Typography>
        </Box>
      </Stack>
      <Avatar
        sx={{
          bgcolor: stat.lightcolor,
          color: stat.color,
          width: 42,
          height: 24,
          borderRadius: "4px",
        }}
      >
        <Typography variant="subtitle2" fontWeight="600">
          {stat.percent}
        </Typography>
      </Avatar>
    </Stack>
  );
}

export const MemorizedStatRow = memo(StatRow);
