import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Typography, Button, InputAdornment, Box, Grid } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { SET_PASSWORD } from "../../gql/mutations/auth";
import CustomTextField from "../dashboards/forms/theme-elements/CustomTextField";
import CustomFormLabel from "../dashboards/forms/theme-elements/CustomFormLabel";
import { regexPassword } from "../../utils/validations";
import { usePopup } from "../../context/commonPop";
import { updateUser } from "../../redux/action";

function SetPasswordGoogle() {
  const [values, setValues] = useState({
    newpassword: "",
    repeatpassword: "",
  });
  const [visibility, setVisibility] = useState({
    newpassword: false,
    repeatpassword: false,
  });
  const [err, setErr] = useState(null);
  const dispatch = useDispatch();
  const { handleOpen } = usePopup();

  const userData = useSelector(state => state.reducer.userData);
  const [setpassword] = useMutation(SET_PASSWORD);

  const [errorState, setErrorState] = useState({
    newpassword: false,
    repeatpassword: false,
  });

  const yupSchema = Yup.object({
    newpassword: Yup.string()
      .matches(regexPassword, "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be 8-15 characters long")
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .max(15, "Password must be at most 15 characters long"),
    repeatpassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("newpassword"), null], "Passwords must match"),
  });

  const onhandlechange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  async function checkError() {
    try {
      const data = await yupSchema.validate(values, { abortEarly: false });
      setErrorState({ newpassword: false, repeatpassword: false });
      return data;
    } catch (validationErrors) {
      const newErrors = {};
      validationErrors.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });
      setErrorState(newErrors);
      return null;
    }
  }

  async function handleclick() {
    const check = await checkError();

    setErr(null);
    if (check) {
      setpassword({
        variables: {
          data: {
            newPassword: values.newpassword,
            userId: userData.id,
            isGoogleAuth: false,
            googlePassword: true,
          },
        },
        onCompleted: ({ setNewPassword }) => {
          dispatch(updateUser(setNewPassword));
          setErr(null);
          handleOpen(true, "Password Saved Successfully");
        },
        onError: errdata => {
          setErr(errdata.message);
        },
      });
    }
  }
  return (
    <Box className="FormWrapper">
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <CustomFormLabel
              sx={{
                mt: 0,
              }}
              htmlFor="text-cpwd"
            >
              Set Password
            </CustomFormLabel>
            <CustomTextField
              id="text-cpwd"
              type={visibility.newpassword ? "text" : "password"}
              name="newpassword"
              placeholder="Enter new password"
              onChange={e => {
                onhandlechange(e);
              }}
              variant="outlined"
              fullWidth
              value={values.newpassword}
              error={errorState.newpassword}
              helperText={errorState.newpassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setVisibility(prev => ({ ...prev, newpassword: !prev.newpassword }));
                    }}
                  >
                    {visibility.newpassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CustomFormLabel htmlFor="text-npwd"> Confirm Set Password</CustomFormLabel>
            <CustomTextField
              type={visibility.repeatpassword ? "text" : "password"}
              name="repeatpassword"
              variant="outlined"
              fullWidth
              placeholder="Confirm password"
              onChange={e => {
                onhandlechange(e);
              }}
              value={values.repeatpassword}
              error={errorState.repeatpassword}
              helperText={errorState.repeatpassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setVisibility(prev => ({ ...prev, repeatpassword: !prev.repeatpassword }));
                    }}
                  >
                    {visibility.repeatpassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="UpdateBtnWrapper">
              <Button
                onClick={e => {
                  handleclick(e);
                }}
                variant="contained"
                color="primary"
                sx={{ minWidth: "80px" }}
              >
                Update
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      {err && (
        <Typography component="p" sx={{ marginTop: 0, color: "red", fontWeight: "600", marginBottom: 0 }}>
          {err}
        </Typography>
      )}
    </Box>
  );
}

export default SetPasswordGoogle;
