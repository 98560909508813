import { memo } from "react";
import { Link } from "react-router-dom";
import { Grid, Box, Typography, Stack } from "@mui/material";
import Logo from "../NavBar/sidebar/logo/logo";
import AuthRegister from "../authForms/AuthRegister";
// import loginImage from "../../Images/backgrounds/login-bg.svg";
import loginImage from "../../Images/LoginBg.png";
function NewRegister() {
  return (
    <Box component="section">
      <Grid container spacing={0} justifyContent="center" sx={{ overflowX: "hidden" }}>
        <Grid
          item
          xs={12}
          sm={12}
          lg={7}
          xl={8}
          sx={{
            position: "relative",
            "&:before": {
              content: '""',
              background: "radial-gradient(#d2f1df, #d3d7fa, #bad8f4)",
              backgroundSize: "400% 400%",
              animation: "gradient 15s ease infinite",
              position: "absolute",
              height: "100%",
              width: "100%",
              opacity: "0.3",
            },
          }}
        >
          <Box position="relative">
            <Box px={3}>
              <Logo />
            </Box>
            <Box
              alignItems="center"
              justifyContent="center"
              height="calc(100vh - 75px)"
              sx={{
                display: {
                  xs: "none",
                  lg: "flex",
                },
              }}
            >
              <img
                src={loginImage}
                alt="bg"
                style={{
                  width: "100%",
                  maxWidth: "500px",
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} lg={5} xl={4} display="flex" justifyContent="center" alignItems="center">
          <Box p={4} sx={{ width: "370px" }}>
            <AuthRegister title="Welcome to Hotspot SEO" subtitle={<MemoizedSubtitle />} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

NewRegister.layout = "Blank";
export default NewRegister;

function Subtitle() {
  return (
    <Stack direction="row" spacing={1} mt={3}>
      <Typography color="textSecondary" variant="h6" fontWeight="400">
        Already have an account?
      </Typography>
      <Typography
        component={Link}
        to="/"
        fontWeight="500"
        sx={{
          textDecoration: "none",
          color: "primary.main",
        }}
      >
        Sign In
      </Typography>
    </Stack>
  );
}

const MemoizedSubtitle = memo(Subtitle);
