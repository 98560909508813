import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Box, Typography, FormGroup, FormControlLabel, Button, Stack, Divider } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomCheckbox from "../forms/theme-elements/CustomCheckbox";
import CustomTextField from "../forms/theme-elements/CustomTextField";
import CustomFormLabel from "../forms/theme-elements/CustomFormLabel";
import { usePopup } from "../../context/commonPop";
import { login, verifyLink } from "../../gql/mutations/auth";
import { validateEmail, validateString } from "../Validate/validate";
import { loginUser } from "../../redux/action";
import useQuery from "../../hooks/allQuery";
import LoginGoogle from "../../googleAuth/loginGoogle";
import { saveLocalTokens } from "../../utils/session";
import VerifyPopup from "../../common/verifyPopup";

function AuthLogin({ title, subtitle, subtext }) {
  const [FIRSTCALL] = useMutation(verifyLink);
  const [AUTH_USER] = useMutation(login);
  const navigate = useNavigate();
  const { allQuery } = useQuery();
  const dispatch = useDispatch();
  const { handleOpen } = usePopup();
  const [verifyPopupOpen, setVerifyPopupOpen] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [emailForPopup, setEmailForPopup] = useState("");
  const [errorState, setErrorState] = useState({
    email: false,
    password: false,
  });

  const onValueChange = e => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const checkError = async data => {
    const email = await validateEmail(data.email);
    const password = await validateString(data.password);

    await setErrorState({
      ...errorState,
      email,
      password,
    });
    if (email || password) {
      return true;
    }
    return false;
  };
  const HandleSubmit = async () => {
    if (!emailForPopup) return;
    allQuery({
      QUERY: FIRSTCALL,
      variables: {
        email: emailForPopup,
      },
      onCompleteFunc: ({ sendVerifyLink }) => {
        handleOpen(true, sendVerifyLink?.message || "Verification Link sent successfully on your mail");
      },
      onErrorFunc: error => {
        console.log("error", error);
        handleOpen(true, error?.message || "Something went wrong");
      },
    });
    setVerifyPopupOpen(false);
  };

  const handleLoginUser = async e => {
    e.preventDefault();
    try {
      const check = await checkError(loginData);
      if (check) return;
      allQuery({
        QUERY: AUTH_USER,
        variables: {
          data: loginData,
        },
        onCompleteFunc: data => {
          const { accessToken, refreshToken } = data.login;
          localStorage.setItem("token", accessToken);
          saveLocalTokens({
            accessToken,
            refreshToken,
          });
          dispatch(loginUser({ ...data.login.user, subscription: data?.login?.subscription, subscriptionCredits: data?.login?.subscriptionCredits }));
          window.dispatchEvent(new Event("storage"));
          navigate("/dashboard?menu=dashboard");
        },
        onErrorFunc: errdata => {
          const { message, isVerificationLink } = JSON.parse(errdata?.message) || {};
          if (isVerificationLink) {
            setEmailForPopup(loginData.email);
            setVerifyPopupOpen(true);
          } else {
            handleOpen(true, message || "Something went worng");
          }
          if (false) {
            const err = errdata?.message ? JSON.parse(errdata?.message) : null;
            if (err.isVerificationLink) {
              setEmailForPopup(loginData.email);
            } else {
              handleOpen(true, err?.message || "Something went worng");
            }
          }
        },
      });
    } catch (error) {
      console.log(error);
      handleOpen(true, "Something went worng");
    }
  };

  return (
    <>
      {verifyPopupOpen && <VerifyPopup open={verifyPopupOpen} setOpen={setVerifyPopupOpen} handleSubmit={HandleSubmit} email={loginData.email} />}
      {title ? (
        <Typography fontWeight="700" variant="h3" mb={1}>
          {title}
        </Typography>
      ) : null}

      {subtext}
      <Box>
        <LoginGoogle />
      </Box>
      <Box mt={3}>
        <Divider>
          <Typography component="span" color="textSecondary" variant="h6" fontWeight="400" position="relative" px={2}>
            or sign in with
          </Typography>
        </Divider>
      </Box>

      <Stack>
        <Box>
          <CustomFormLabel htmlFor="email">Username</CustomFormLabel>
          <CustomTextField
            id="email"
            type="email"
            variant="outlined"
            fullWidth
            name="email"
            value={loginData.email}
            onChange={e => onValueChange(e)}
            error={errorState.email}
            helperText={errorState.email && "Please enter valid email"}
          />
        </Box>
        <Box>
          <CustomFormLabel htmlFor="password">Password</CustomFormLabel>
          <CustomTextField
            id="password"
            type="password"
            variant="outlined"
            name="password"
            fullWidth
            value={loginData.password}
            onChange={e => onValueChange(e)}
            error={errorState.password}
            helperText={errorState.password && "Please enter password"}
          />
        </Box>
        <Stack justifyContent="space-between" direction="row" alignItems="center" my={2}>
          <FormGroup sx={{ "& label": { marginBottom: "0" } }}>
            <FormControlLabel control={<CustomCheckbox defaultChecked />} label="Remember this Device" />
          </FormGroup>
          <Typography
            component={Link}
            to="/forgot"
            fontWeight="500"
            sx={{
              textDecoration: "none",
              color: "primary.main",
            }}
          >
            Forgot Password ?
          </Typography>
        </Stack>
      </Stack>
      <Box>
        <Button color="primary" variant="contained" size="large" fullWidth component={Link} to="/dashboard?menu=dashboard" type="submit" onClick={e => handleLoginUser(e)}>
          Sign In
        </Button>
      </Box>
      {subtitle}
    </>
  );
}

export default AuthLogin;
