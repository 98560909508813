/* eslint-disable */
import { Box, Button, Card, Container, Grid, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useState, useRef } from "react";
import { debounce } from "lodash";
import SettingsIcon from "@mui/icons-material/Settings";
import { GetScore, convertISOToDateFormat } from "../utils/myUtills";
import { GetScrapyData } from "../hooks/scrapyData";
import ImageNotFound from "../Images/image-not-found-icon.png";
import CreateProjectLink from "../components/createProject/CreateProjectLink";
import MainLoader from "../components/MainLoader/MainLoader";
import SearchBar from "../components/common/SearchBar";
import BackButton from "../components/BackButton/BackButton";
import { useSelector } from "react-redux";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .SearchTopBarWrapper": {
    "& .SearchBarWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "15px",
      "@media screen and (max-width:600px)": {
        flexDirection: "column",
      },
      "& button": {
        textTransform: "none",
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        borderRadius: "5px",
        boxShadow: "none",
        padding: "6px 22px",
        "@media screen and (max-width:600px)": {
          width: "100%",
        },
        "&:focus": {
          outline: "0",
        },
      },
    },
  },
  "& .BtnTopWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    "@media screen and (max-width:600px)": {
      flexDirection: "column",
    },
  },
}));
const BoxMainWrapper = styled(Box)(({ theme }) => ({
  "& .DataGridWrapper": {
    "& .datagridnopage": {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: "7px 7px 7px 7px",
      "&.PaginationHide .MuiDataGrid-footerContainer": {
        display: "none",
      },
    },
    "& .MuiDataGrid-main": {
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: theme.palette.mode === "dark" ? "#FFFFFF33" : "#2A3547",
        borderRadius: "7px",
        "& .MuiDataGrid-columnSeparator": {
          color: "#8F8F8F",
          visibility: "visible",
        },
        "& .MuiDataGrid-columnHeadersInner": {
          flex: "1",
          '& div[role="row"]': {
            flex: "1",
            width: "100%",
            "& .MuiDataGrid-columnHeader": {
              outline: "none !important",
              paddingLeft: "18px",
              paddingRight: "18px",
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                "& .MuiDataGrid-iconButtonContainer": {
                  display: "none",
                },
              },
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "14px",
            color: theme.palette.mode === "dark" ? "#ffffff" : "#ffffff",
            fontWeight: 600,
          },
        },
      },
      "& .MuiDataGrid-virtualScroller": {
        overflowX: "hidden",
        "&::-webkit-scrollbar-track": {
          borderRadius: "10px",
          backgroundColor: theme.palette.text.primary + "0d",
        },
        "&::-webkit-scrollbar": { width: "8px", backgroundColor: "transparent" },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: theme.palette.primary.main,
        },
        "& .MuiDataGrid-virtualScrollerContent": {
          // height: "400px !important",
          height: "calc(100vh - 295px) !important",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            width: "100%",
            "@media screen and (max-width:600px)": {
              width: "auto",
            },
            "& .MuiDataGrid-row": {
              boxShadow: "none",
              minHeight: "70px !important",
              width: "100%",
            },
            '& div[role="row"]': {
              cursor: "pointer",
              borderBottom: `2px solid ${theme.palette.divider}`,
              width: "100%",
              "&:last-of-type": { borderWidth: "1px" },
              "& .MuiDataGrid-cell": {
                minHeight: "70px !important",
                flex: 1,
                paddingLeft: "18px",
                paddingRight: "18px",
                "&:last-of-type": {
                  display: "none",
                  "@media screen and (max-width:600px)": {
                    display: "flex",
                  },
                },
                "&.MuiDataGrid-cell--withRenderer": {
                  "&:last-of-type": {
                    display: "flex",
                  },
                },
                "&:focus-within": { outline: "none" },
                "& .MuiDataGrid-cellContent": {
                  color: theme.palette.text.primary,
                  fontWeight: 600,
                },
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-footerContainer": {
      justifyContent: "center",
      "& .MuiTablePagination-root": {
        "& .MuiToolbar-root": {
          "& .MuiTablePagination-selectLabel": {
            marginBottom: "0",
          },
          "& .MuiTablePagination-displayedRows": {
            marginBottom: "0",
          },
        },
      },
    },
  },
}));

const rows = [
  {
    Projects: {
      name: "Jon",
      img: "https://images.unsplash.com/photo-1682685797660-3d847763208e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    LastCrawl: "25OCT",
    Status: "Snow",
    HealthScore: "100%",
    URLsCrawled: "0",
    Scheduled: "-",
    Setting: "0",
    id: "1",
  },
  {
    Projects: {
      name: "Cersei",
      img: "https://images.unsplash.com/photo-1682685797660-3d847763208e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    LastCrawl: "25OCT",
    Status: "Lannister",
    HealthScore: "100%",
    URLsCrawled: "3",
    Scheduled: "-",
    Setting: "5",
    id: "2",
  },
  {
    Projects: {
      name: "Jaime",

      img: "https://images.unsplash.com/photo-1682685797660-3d847763208e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    LastCrawl: "25OCT",
    Status: "Lannister",
    HealthScore: "100%",
    URLsCrawled: "2",
    Scheduled: "-",
    Setting: "9",
    id: "3",
  },
  {
    Projects: {
      name: "Arya",
      img: "https://images.unsplash.com/photo-1682685797660-3d847763208e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    LastCrawl: "25OCT",
    Status: "Stark",
    HealthScore: "100%",
    URLsCrawled: "4",
    Scheduled: "-",
    Setting: "6",
    id: "4",
  },
];
function SiteAuditPage() {
  const isMblBreakpoint = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const { scrapyData, loading } = GetScrapyData();
  const navigate = useNavigate();
  const [search, setSearch] = useState(null);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [loader, setLoader] = useState(false);
  const customizer = useSelector(state => state.customizer);

  const findSearchValue = () => {
    const subString = search && search.trim().length > 0 ? search.trim() : "";
    const filterArr = scrapyData.filter(project => project.projectName.toLowerCase().includes(subString));

    if (filterArr.length > 0) {
      const dataArr = [];
      // eslint-disable-next-line
      filterArr.forEach(
        project =>
          project.isVerified &&
          dataArr.push({
            Projects: {
              name: project.projectName,
              img: project?.imageUrl && project?.imageUrl ? project?.imageUrl : ImageNotFound,
            },
            LastCrawl:
              project?.srapySessionData.length && project.srapySessionData[0]?.createdAt ? convertISOToDateFormat(project.srapySessionData[0]?.createdAt).formattedDate : "---",
            Status: !project?.isCrawlProgress ? "Completed" : "In Progress",
            HealthScore: project?.srapySessionData.length ? GetScore(project?.srapySessionData) : 0,
            URLsCrawled: project?.srapySessionData.length && project.srapySessionData[0]?.urlCrawled ? project.srapySessionData[0]?.urlCrawled : 0,
            Scheduled:
              project.projectSiteAudit && project.projectSiteAudit.isActive
                ? `${project.projectSiteAudit.startDay ? project.projectSiteAudit.startDay : ""} ${project.projectSiteAudit.frequency} ${project.projectSiteAudit.startTime}-${
                    project.projectSiteAudit.endTime
                  }`
                : "Not Scheduled",
            Setting: project?.id,
            id: project?.id,
            sessionId: project?.srapySessionData.length && project.srapySessionData[0].sessionId ? project.srapySessionData[0].sessionId : 0,
          }),
      );
      setFilteredRows(dataArr);
    }
  };
  const debouncedHandleChange = useCallback(
    debounce(value => {
      setSearch(value);
    }, 300),
    [],
  );

  const handleChange = e => {
    debouncedHandleChange(e.target.value);
  };

  useEffect(() => () => debouncedHandleChange.cancel(), [debouncedHandleChange]); // eslint-disable-line

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const dataArr = [];
    scrapyData.forEach(
      project =>
        project.isVerified &&
        dataArr.push({
          Projects: {
            name: project.projectName,
            img: project?.imageUrl && project?.imageUrl ? project?.imageUrl : ImageNotFound,
          },
          LastCrawl:
            project?.srapySessionData.length && project.srapySessionData[0]?.createdAt ? convertISOToDateFormat(project.srapySessionData[0]?.createdAt).formattedDate : "---",
          Status: !project?.isCrawlProgress ? "Completed" : "In Progress",
          HealthScore: project?.srapySessionData.length ? GetScore(project?.srapySessionData) : 0,
          URLsCrawled: project?.srapySessionData.length && project.srapySessionData[0]?.urlCrawled ? project.srapySessionData[0]?.urlCrawled : 0,
          Scheduled:
            project.projectSiteAudit && project.projectSiteAudit.isActive
              ? `${project.projectSiteAudit.startDay ? project.projectSiteAudit.startDay : ""} ${project.projectSiteAudit.frequency} ${project.projectSiteAudit.startTime}-${
                  project.projectSiteAudit.endTime
                }`
              : "Not Scheduled",
          Setting: project?.id,
          id: project?.id,
          sessionId: project?.srapySessionData.length && project.srapySessionData[0].sessionId ? project.srapySessionData[0].sessionId : 0,
        }),
    );
    setFilteredRows(dataArr);
  }, [scrapyData]);

  useEffect(() => {
    findSearchValue();
  }, [search]);

  const columns = [
    {
      field: "Projects",
      headerName: "Project",
      width: 280,
      overflow: "hidden",
      renderCell: params => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "10px",
            "& img": { width: "40px", height: "auto" },
            "& h4": { fontSize: "16px", fontWeight: 600, color: theme.palette.mode === "dark" ? "#ffffff" : "#000000" },
          }}
        >
          <Typography component="img" src={params.value.img} alt={params.value.name} />
          <Typography component="h4" title={params.value.name}>
            {params.value.name}
          </Typography>
        </Box>
      ),
    },
    {
      field: "LastCrawl",
      headerName: "Last Crawl",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: "Status",
      headerName: "Status",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "left",
      align: "left",
      width: 150,
      renderCell: params => {
        const { Status } = params.row;
        let text;
        let style;
        switch (Status) {
          case "Completed":
            text = "Completed";
            style = { color: "#13DEB9", border: "1px solid #13DEB9", borderRadius: "50px", backgroundColor: "#13DEB933", fontSize: "12px", padding: "5px 14px 5px" };
            break;
          case "In Progress":
            text = "In Progress";
            style = { color: "#FFAE1F", border: "1px solid #FFAE1F", borderRadius: "50px", backgroundColor: "#FFAE1F33", fontSize: "12px", padding: "5px 14px 5px" };
            break;
          default:
            // text = "Completed";
            // style = { color: "#13DEB9", border: "1px solid #13DEB9", borderRadius: "50px", backgroundColor: "#13DEB933", fontSize: "12px", padding: "5px 14px 5px" };
            break;
        }
        return (
          <Typography component="span" sx={style}>
            {text}
          </Typography>
        );
      },
    },
    {
      field: "HealthScore",
      headerName: "Health Score",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: "URLsCrawled",
      headerName: "URLs crawled	",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: "Scheduled",
      headerName: "Scheduled",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "left",
      align: "left",
      width: 150,
      renderCell: params => (
        <Box sx={{ color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547", fontWeight: "600", "&::first-letter": { textTransform: "capitalize" } }} title={params.value}>
          {params.value}
        </Box>
      ),
    },
    {
      field: "Setting",
      headerName: "Setting",
      headerAlign: "right",
      align: "right",
      width: 100,
      renderCell: () => <SettingsIcon sx={{ color: theme.palette.primary.main }} />,
    },
  ];
  const handleRowClick = id => {
    navigate(`/projects/${id}`);
  };

  // Data Table Dynamic Width Set Start

  const wrapperRef = useRef(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  useEffect(() => {
    const handleResize = entries => {
      for (let entry of entries) {
        setLoader(true);
        setWrapperWidth(entry.contentRect.width);
        setTimeout(() => {
          setLoader(false);
        }, 300);
      }
    };
    const observer = new ResizeObserver(handleResize);
    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }
    return () => {
      if (wrapperRef.current) {
        observer.unobserve(wrapperRef.current);
      }
    };
  }, [wrapperRef]);

  // Data Table Dynamic Width Set End

  useEffect(() => {
    setLoader(!loader);
  }, [customizer.isCollapse]);

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} md={12}>
              <Box ref={wrapperRef} sx={{ width: "100%" }}>
                <Card
                  sx={{
                    padding: "16px 16px 16px 16px",
                    border: `1px solid ${theme.palette.divider}`,
                    boxShadow: "none !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "15px",
                    "@media screen and (max-width:600px)": {
                      flexDirection: "column",
                      alignItems: "stretch",
                    },
                  }}
                >
                  <Box className="BtnTopWrapper">
                    <BackButton sx={{ "& button": { marginBottom: "0 !important" } }} />
                    <CreateProjectLink />
                  </Box>
                  <Box className="SearchTopBarWrapper">
                    <Box className="SearchBarWrapper">
                      <SearchBar handleChange={handleChange} />
                      <Button>Search</Button>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
          {loading || loader ? (
            <MainLoader message="Loading..." />
          ) : (
            <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
              <Grid item xs={12} lg={12}>
                <BoxMainWrapper>
                  {scrapyData.length ? (
                    <Box className="DataGridWrapper" style={{ width: wrapperWidth }}>
                      <DataGrid
                        rows={filteredRows && filteredRows ? filteredRows : rows}
                        columns={columns}
                        pageSize={false}
                        pagination={false}
                        className="datagridnopage"
                        onCellClick={params => {
                          if (params.field === "Setting") return navigate(`/websitesetting/${params.id}`);

                          if (params?.row?.URLsCrawled > 0) return navigate(`/projects/${params.id}/${params?.row?.sessionId}`);
                          return null;
                        }}
                        disableColumnMenu
                      />
                    </Box>
                  ) : (
                    <Typography variant="h6">NO Projects Found!</Typography>
                  )}
                </BoxMainWrapper>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default SiteAuditPage;
