/* eslint-disable */
import React, { lazy, memo, Suspense } from "react";
const Chart = lazy(() => import("react-apexcharts"));
import { useTheme } from "@mui/material/styles";
import DashboardWidgetCard from "../../shared/DashboardWidgetCard";

const YearlySales = ({ graphData, type = "bar", height = "295px", width = "100%", chartHeader = "", chartSubtitle = "", statusArr }) => {
  // chart color
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const primarylight = theme.palette.grey[100];

  const optionscolumnchart = {
    chart: {
      id: "column-chart",
      fontFamily: "'Plus Jakarta Sans', sans-serif",
      foreColor: "#adb0bb",
      toolbar: {
        show: false,
      },
    },
    colors: [primary],
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        columnWidth: "20%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: graphData?.CreateAt || [],
    },
    yaxis: {
      title: {
        text: "Filter Result",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val}`;
        },
      },
      theme: "dark",
    },
    grid: {
      show: true,
    },
    legend: {
      show: true,
      position: "bottom",
      width: "50px",
    },
  };
  const seriescolumnchart = [
    {
      name: "",
      data: graphData?.countData || [],
    },
  ];

  // chart
  const optionscolumnchartDemo = {
    chart: {
      type: "bar",
      fontFamily: "'Plus Jakarta Sans', sans-serif;",
      foreColor: "#adb0bb",
      toolbar: {
        show: false,
      },
      height: 295,
    },
    colors: [primarylight, primarylight, primary, primarylight, primarylight, primarylight],
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "45%",
        distributed: true,
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      categories: [["Apr"], ["May"], ["June"], ["July"], ["Aug"], ["Sept"]],
      axisBorder: {
        show: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      labels: {
        show: false,
      },
    },
    tooltip: {
      theme: theme.palette.mode === "dark" ? "dark" : "light",
    },
  };
  const seriescolumnchartDemo = [
    {
      name: "",
      data: [20, 15, 30, 25, 10, 15],
    },
  ];
  return (
    <DashboardWidgetCard
      title={chartHeader}
      subtitle={chartSubtitle}
      statusArr={statusArr}
      dataLabel1="Salary"
      dataItem1="$36,358"
      dataLabel2="Expance"
      dataItem2="$5,296"
      // ClassName="HeightFull"
    >
      <>
        <Suspense fallback={<div>Loading...</div>}>
          <Chart
            options={graphData ? optionscolumnchart : optionscolumnchartDemo}
            series={graphData ? seriescolumnchart : seriescolumnchartDemo}
            type={type}
            height={height}
            width={width}
          />
        </Suspense>
      </>
    </DashboardWidgetCard>
  );
};

export default memo(YearlySales);
