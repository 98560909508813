/* eslint-disable */

import apolloClient from "../apollo/config";
import { usePopup } from "../context/commonPop";

function createObjectWithDynamicKey(key, data) {
  const newObj = {};
  newObj[key] = data;
  return newObj;
}

function updateArrayById(array, newObject) {
  const index = array.findIndex(obj => obj.id === newObject.id);

  if (index !== -1) {
    array.splice(index, 1, newObject);
  }

  return array;
}

const cacheUpdate = (type, { cacheQuery, variablesCache }, newData) => {
  const fetchCache = apolloClient.readQuery({
    query: cacheQuery,
    variables: variablesCache,
  });
  if (!fetchCache) return;

  const key = Object.keys(fetchCache)[0];
  const newArr = [...fetchCache[key]];
  let dataNew;
  switch (type) {
    case "create":
      dataNew = newArr.push(newData);
      break;
    case "update":
      dataNew = updateArrayById(newArr, newData);
      break;
    case "delete":
      dataNew = newArr.filter(item => item.id !== newData.id);

      break;
    default:
      break;
  }
  const newDataObj = createObjectWithDynamicKey(key, dataNew);
  apolloClient.writeQuery({ query: cacheQuery, data: { ...newDataObj }, variables: variablesCache });
};

const cacheDataUpdate = ({ cacheQuery, variablesCache }, newData) => {
  const fetchCache = apolloClient.readQuery({
    query: cacheQuery,
    variables: variablesCache,
  });
  if (!fetchCache) return;
  const key = Object.keys(fetchCache)?.[0];
  const newDataObj = createObjectWithDynamicKey(key, newData);
  apolloClient.writeQuery({ query: cacheQuery, data: newDataObj, variables: variablesCache });
};
const useQuery = () => {
  const { handleOpen } = usePopup();

  const allQuery = ({ QUERY, variables, type, cacheQueryData, onCompleteFunc, onErrorFunc }) => {
    QUERY({
      variables,
      onCompleted: async res => {
        const key = Object.keys(res)[0];
        type && cacheUpdate(type, cacheQueryData, res[key]);
        onCompleteFunc && onCompleteFunc(res);
        return res;
      },
      onError: errdata => {
        console.log("errdata", errdata.message);
        onErrorFunc ? onErrorFunc(errdata) : handleOpen(true, errdata?.message || "Something went wrong");

        return null;
      },
    });
  };
  const allQueryObject = ({ QUERY, variables, type, cacheQueryData, onCompleteFunc, onErrorFunc }) => {
    QUERY({
      variables,
      onCompleted: async res => {
        const key = Object.keys(res)[0];
        type && cacheDataUpdate(cacheQueryData, res[key]);
        onCompleteFunc && onCompleteFunc(res);
        return res;
      },
      onError: errdata => {
        console.log("errdata", errdata.message);
        onErrorFunc ? onErrorFunc(errdata) : handleOpen(true, errdata?.message || "Something went wrong");

        return null;
      },
    });
  };

  return { allQuery, allQueryObject };
};

export default useQuery;
