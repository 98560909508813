/* eslint-disable */
import { memo } from "react";
import { useMediaQuery, Box, Drawer, useTheme } from "@mui/material";
import SidebarItems from "./SidebarItems";
import Logo from "./logo/logo";
import { Profile } from "./SidebarProfile/Profile";
import Scrollbar from "./Scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { hoverSidebar, toggleMobileSidebar } from "../../../redux/action";
import { SidebarSupportComponent } from "./support-sidebar-component";

function Sidebar() {
  const lgUp = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const customizer = useSelector(state => state.customizer);
  const dispatch = useDispatch();
  const theme = useTheme();
  const toggleWidth = customizer.isCollapse && !customizer.isSidebarHover ? customizer.MiniSidebarWidth : customizer.SidebarWidth;

  // const onHoverEnter = () => {
  //   if (customizer.isCollapse) {
  //     dispatch(hoverSidebar(true));
  //   }
  // };

  // const onHoverLeave = () => {
  //   dispatch(hoverSidebar(false));
  // };

  if (lgUp) {
    return (
      <Box
        sx={{
          width: toggleWidth,
          flexShrink: 0,
          ...(customizer.isCollapse && {
            position: "absolute",
          }),
        }}
      >
        <Drawer
          anchor="left"
          open
          variant="permanent"
          PaperProps={{
            sx: {
              transition: theme.transitions.create("width", {
                duration: theme.transitions.duration.shortest,
              }),
              width: toggleWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Box
            sx={{
              height: "100%",
            }}
          >
            <Box px={3}>
              <Logo path={"/dashboard"} />
            </Box>
            <Scrollbar sx={{ height: "calc(100% - 190px)" }}>
              <SidebarItems />
            </Scrollbar>
            {/* <Profile /> */}
            <SidebarSupportComponent />
          </Box>
        </Drawer>
      </Box>
    );
  }

  return (
    <Drawer
      anchor="left"
      open={customizer.isMobileSidebar}
      onClose={() => dispatch(toggleMobileSidebar())}
      variant="temporary"
      PaperProps={{
        sx: {
          width: customizer.SidebarWidth,

          // backgroundColor:
          //   customizer.activeMode === 'dark'
          //     ? customizer.darkBackground900
          //     : customizer.activeSidebarBg,
          // color: customizer.activeSidebarBg === '#ffffff' ? '' : 'white',
          border: "0 !important",
          boxShadow: theme => theme.shadows[8],
        },
      }}
    >
      {/* ------------------------------------------- */}
      {/* Logo */}
      {/* ------------------------------------------- */}
      <Box px={2}>
        <Logo path={"/dashboard"} />
      </Box>
      {/* ------------------------------------------- */}
      {/* Sidebar For Mobile */}
      {/* ------------------------------------------- */}
      <SidebarItems />
    </Drawer>
  );
}

export default memo(Sidebar);
