import { Button } from "@mui/material";
import { LocalizationProvider, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import dayjs from "dayjs";
// import moment from "moment";

const DatePickerStyleWrapper = {
  justifyContent: "flex-end",
  flexDirection: "row",
  gap: "15px",
  paddingTop: "0",
  "@media screen and (max-width: 500px)": {
    flexDirection: "column",
  },
  "& .MuiFormControl-root.MuiTextField-root": {
    minWidth: "500px",
    "@media screen and (max-width: 821px)": {
      minWidth: "auto",
      flex: 1,
    },
  },
  "& .FetchBtn": { marginTop: "0" },
};

export function DatePickerRangeComponent({ handleFetchData = () => "function not Working", startDate, endDate, handleDateRangeChange = () => null }) {
  // const defaultStartDate = startDate || moment().startOf("month").toDate();
  // const defaultEndDate = endDate || moment().toDate();
  function textHideFunction() {
    const specificText = "MUI X Missing license key";
    const xpath = `//div[text()[contains(., '${specificText}')]]`;
    const matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

    if (matchingElement) {
      matchingElement.style.display = "none";
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer sx={DatePickerStyleWrapper} components={["SingleInputDateRangeField"]}>
        <DateRangePicker
          slots={{ field: SingleInputDateRangeField }}
          name="allowedRange"
          defaultValue={[startDate || dayjs().startOf("month"), endDate || dayjs()]}
          onChange={handleDateRangeChange}
          renderInput={params => <SingleInputDateRangeField {...params} />}
          onOpen={() => {
            setTimeout(() => {
              textHideFunction();
            }, 200);
          }}
          disableFuture
        />
        <Button className="FetchBtn" onClick={() => handleFetchData()}>
          Fetch
        </Button>
      </DemoContainer>
    </LocalizationProvider>
  );
}
