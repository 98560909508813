import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

const BoxWrapperstyle = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "#ffffff",
  // border: "5px solid #6edbff",
  padding: "30px 35px 34px",
  borderRadius: "15px",
  "& h2": { fontSize: "18px", fontWeight: "100", marginBottom: "10px" },
  "& p": { fontSize: "16px", marginBottom: "18px", color: "#797979" },
  "& button": {
    boxShadow: "none",
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    borderRadius: "7px",
    // border: "1px solid #ff642d",
    padding: "6px 24px 6px",
    width: "100%",
    fontSize: "16px",
    // "&:hover": {
    //   backgroundColor: "transparent",
    //   boxShadow: "none",
    //   color: "#ff642d",
    // },
  },
}));

export default function LogoutPopUp({ open, setOpen, onLogout }) {
  const handleClose = () => setOpen(false);
  const handleLogout = () => {
    setOpen(false);
    onLogout();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <BoxWrapperstyle>
          <Typography component="p">Would you like to gracefully sign out?</Typography>
          <Box sx={{ display: "flex", gap: "15px" }}>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleLogout}>
              Logout
            </Button>
          </Box>
        </BoxWrapperstyle>
      </Fade>
    </Modal>
  );
}
