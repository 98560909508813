/* eslint-disable */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { CardContent, Grid, Typography, MenuItem, Box, Avatar, Button, CircularProgress, InputAdornment, LinearProgress, styled, Card, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import BlankCard from "../shared/BlankCard";
import CustomTextField from "../dashboards/forms/theme-elements/CustomTextField";
import CustomFormLabel from "../dashboards/forms/theme-elements/CustomFormLabel";
import CustomSelect from "../dashboards/forms/theme-elements/CustomSelect";
import { validateContact, validateString, regexPassword, validateName } from "../../utils/validations";
import { UpdateUser, changPassword } from "../../gql/mutations/auth";
import { usePopup } from "../../context/commonPop";
import { updateImage, updateUser } from "../../redux/action";
import useQuery from "../../hooks/allQuery";
import { updateImageProfile } from "../../gql/mutations/profileImage";
import SetPasswordGoogle from "./SetPasswordGoogle";
import defultUserImg from "../../Images/profile/user-1.jpg";
import countryCode from "../../utils/countryCode.json";
import countryCurrencyCode from "../../utils/countryCurrencyCode.json";
import CameraIcon from "../SvgIcons/CameraIcon";

const BoxWrapper = styled(Box)(({ theme }) => ({
  padding: "16px",
  "& .AccountProfile": {
    "& .MainHeading": {
      color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: 1,
      marginBottom: "12px",
    },
    "& .SubHeading": {
      color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1,
      marginBottom: "30px",
    },
    "& .ProfileImgWrapper": {
      marginBottom: "30px",
      "& .ProfileAvatarWrapper": {
        width: 100,
        height: 100,
        position: "relative",
        "& .MuiAvatar-root": {
          width: 100,
          height: 100,
          border: `2px solid ${theme.palette.primary.main}`,
        },
        "& .UploadBtn": {
          minWidth: "auto",
          padding: "0",
          width: "30px",
          height: "30px",
          borderRadius: "100px",
          position: "absolute",
          bottom: "0",
          right: "0",
          "& .LoaderWrapper": {
            color: "#ffffff",
          },
        },
      },
      "& .FileUploadError": {
        color: "#7C8FAC",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: 1.5,
        marginTop: "10px",
      },
    },
    "& .FormWrapper": {
      "& form": {
        marginBottom: "0px",
        "& label": {
          color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: 1,
          marginTop: "0px",
          marginBottom: "7px",
        },
        "& p.MuiFormHelperText-root": {
          position: "absolute",
          top: "45px",
          left: "0",
          lineHeight: 1.1,
        },
        "& .UpdateBtnWrapper": {
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          height: "100%",
        },
      },
    },
  },
  "& .ChangePassword": {
    "& .MainHeading": {
      color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: 1,
      marginBottom: "12px",
    },
    "& .SubHeading": {
      color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1,
      marginBottom: "30px",
    },
    "& .FormWrapper": {
      "& form": {
        marginBottom: "0px",
        "& label": {
          color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: 1,
          marginTop: "0px",
          marginBottom: "7px",
        },
        "& p.MuiFormHelperText-root": {
          position: "absolute",
          top: "45px",
          left: "0",
          lineHeight: 1.1,
        },
        "& .UpdateBtnWrapper": {
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          height: "100%",
        },
      },
    },
  },
}));

// profileData
const profileData = {
  name: "",
  address: "",
  phone: "",
  location: "India",
  currency: "India",
  country_phone_code: "91",
};

// errorObj
const errorObj = {
  name: false,
  phone: false,
  location: false,
  currency: false,
  country_phone_code: false,
};

function AccountTab() {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(profileData);
  const [errorClass, setErrorClass] = useState(errorObj);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadError, setIsUploadError] = useState(false);
  const { handleOpen } = usePopup();
  const [UPGRADEUSER] = useMutation(UpdateUser);
  const [UPDATEPROFILEIMAGE] = useMutation(updateImageProfile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allQuery } = useQuery();
  const userData = useSelector(state => state.reducer.userData);
  const checkError1 = async data => {
    const name = await validateName(data.name);
    const phone = await validateContact(data.phone);
    const location = await validateString(data.location);
    const currency = await validateString(data.currency);
    const country_phone_code = await validateString(data.country_phone_code);
    const address = await validateString(data.address);

    await setErrorClass({
      ...errorClass,
      name,
      phone,
      location,
      currency,
      country_phone_code,
      address,
    });

    if (name || phone) {
      return true;
    }
    return false;
  };

  const handleSaveClick = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const check = await checkError1(formData);
      if (check) {
        setLoading(false);

        return;
      }
      allQuery({
        QUERY: UPGRADEUSER,
        variables: {
          data: {
            id: userData.id,
            name: formData.name,
            address: formData.address,
            phone: formData.phone,
            country_phone_code: formData.country_phone_code,
            country_currency: formData.currency,
            location: formData.location,
          },
        },

        onCompleteFunc: dataDis => {
          navigate("/account-settings");
          dispatch(updateUser(dataDis.updateUser));
          setLoading(false);
        },
        onErrorFunc: errdata => {
          handleOpen(true, errdata.message);
          setLoading(false);
        },
      });
    } catch {
      console.log(" Not update");
      setLoading(false);
    }
  };

  const handleUpdateProfileImage = async base64ImageData => {
    if (!base64ImageData) {
      return;
    }
    const mimeTypeMatch = base64ImageData.match(/^data:([A-Za-z-+\/]+);base64/);
    if (!mimeTypeMatch) {
      console.error("Unable to extract file type from base64ImageData");
      return;
    }
    setIsUploading(true);

    const mimeType = mimeTypeMatch[1];
    const [_, fileExtension] = mimeType.split("/");
    try {
      const response = await UPDATEPROFILEIMAGE({
        variables: {
          profileImageInput: {
            userId: userData.id,
            imageData: base64ImageData,
            fileType: fileExtension,
          },
        },
      });
      const imageData = response.data.uploadProfileImage.imageUrl;

      dispatch(updateImage({ imageUrl: imageData }));
      setIsUploading(false);
    } catch (error) {
      // Handle errors here
      console.error("Error updating profile image:", error);
      setIsUploading(false);
    }
  };
  useEffect(() => {
    setFormData({
      name: userData?.name || "",
      address: userData?.address || "",
      phone: userData?.phone || "",
      address: userData.address || "",
      location: userData?.location || "",
      currency: userData?.country_currency,
      country_phone_code: userData?.country_phone_code,
    });
  }, [userData]);

  return (
    <BoxWrapper>
      <Card sx={{ padding: "22px 24px 26px 24px", border: `1px solid ${theme.palette.divider}`, boxShadow: "none !important", mb: "16px" }} className="AccountProfile">
        <Typography component="h5" className="MainHeading">
          Account Profile
        </Typography>
        <Typography component="p" className="SubHeading">
          You can change profile picture and edit profile details
        </Typography>
        <Box className="ProfileImgWrapper">
          <Box className="ProfileAvatarWrapper">
            <Avatar src={userData?.imageUrl || defultUserImg} alt="user1" />
            <Button variant="contained" color="primary" className="UploadBtn" component="label" title="Upload Image">
              {isUploading ? <CircularProgress size={15} thickness={5} className="LoaderWrapper" /> : <CameraIcon />}
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={e => {
                  const file = e.target.files[0];
                  if (file) {
                    if (file.size > 1048576) {
                      e.target.value = "";
                      setIsUploadError(true);
                      return;
                    }
                    const reader = new FileReader();
                    reader.onload = event => {
                      const base64ImageData = event.target.result;
                      handleUpdateProfileImage(base64ImageData);
                    };
                    reader.readAsDataURL(file);
                    setIsUploadError(false);
                  }
                }}
              />
            </Button>
          </Box>
          {isUploadError && (
            <Typography component="p" className="FileUploadError">
              FILE SIZE IS TOO LARGE!
              <br />
              Allowed JPG, GIF or PNG. Max size of 1MB
            </Typography>
          )}
        </Box>
        <Box className="FormWrapper">
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <CustomFormLabel htmlFor="text-name">Your Name</CustomFormLabel>
                <CustomTextField
                  id="text-name"
                  name="name"
                  value={formData.name}
                  placeholder="Your Name"
                  onChange={e => setFormData({ ...formData, name: e.target.value })}
                  className="InputStyleFiled"
                  fullWidth
                  error={errorClass.name}
                  helperText={errorClass.name && "Please enter name"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomFormLabel htmlFor="text-location">Location</CustomFormLabel>
                <CustomSelect
                  fullWidth
                  id="text-location"
                  variant="outlined"
                  value={formData?.location || profileData.location}
                  onChange={e => setFormData({ ...formData, location: e.target.value })}
                >
                  {countryCode.map(option => (
                    <MenuItem key={option.label} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomFormLabel htmlFor="text-currency">Currency</CustomFormLabel>
                <CustomSelect
                  fullWidth
                  id="text-currency"
                  variant="outlined"
                  value={formData?.currency || profileData.currency}
                  onChange={e => setFormData({ ...formData, currency: e.target.value })}
                >
                  {countryCurrencyCode.map(option => (
                    <MenuItem key={option.country} value={option.country}>
                      {`${option.country} (${option.currency_code})`}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomFormLabel htmlFor="text-address">Address</CustomFormLabel>
                <CustomTextField
                  id="text-address"
                  name="address"
                  placeholder="Your Address"
                  value={formData.address}
                  onChange={e => setFormData({ ...formData, address: e.target.value })}
                  className="InputStyleFiled"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomFormLabel htmlFor="text-phone">Phone</CustomFormLabel>
                <Box sx={{ display: "flex", alignItems: "flex-start", gap: "5px" }}>
                  <CustomSelect
                    id="text-phone"
                    variant="outlined"
                    value={formData?.country_phone_code || profileData.country_phone_code}
                    onChange={e => setFormData({ ...formData, country_phone_code: e.target.value })}
                  >
                    {countryCode.map(option => (
                      <MenuItem key={option.label} value={option.phone}>
                        {option.label} (+{option?.phone})
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  <CustomTextField
                    id="text-phone"
                    name="phone"
                    placeholder="Your Phone Number"
                    onChange={e => {
                      if (!/^[0-9]*$/.test(e.target.value)) {
                        return setErrorClass({ ...errorClass, phone: true });
                      }
                      setFormData({ ...formData, phone: e.target.value });
                      return setErrorClass({ ...errorClass, phone: false });
                    }}
                    className="InputStyleFiled"
                    value={formData.phone}
                    fullWidth
                    error={errorClass.phone}
                    helperText={errorClass.phone && "Please enter a working phone number. Account verification is the purpose of it."}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box className="UpdateBtnWrapper">
                  <Button onClick={handleSaveClick} variant="contained" color="primary" disabled={loading} sx={{ minWidth: "80px" }}>
                    {loading ? <CircularProgress size={25} /> : "Update"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
      <Card sx={{ padding: "22px 24px 26px 24px", border: `1px solid ${theme.palette.divider}`, boxShadow: "none !important" }} className="ChangePassword">
        <Typography component="h5" className="MainHeading">
          {userData?.googlePassword ? "Change Password" : "Set Password"}
        </Typography>
        <Typography component="p" className="SubHeading">
          {userData?.googlePassword
            ? "To change your password please confirm here"
            : "If you logged in through Google Login. You can create a new password here. To set your password please confirm here."}
        </Typography>
        {userData?.googlePassword ? <ChangePassword /> : <SetPasswordGoogle />}
      </Card>
    </BoxWrapper>
  );
}

export default AccountTab;

function ChangePassword() {
  const [err, setErr] = useState("");
  const [errorState, setErrorState] = useState({
    newpassword: false,
    repeatpassword: false,
  });
  const [values, setValues] = useState({
    oldpassword: "",
    newpassword: "",
    repeatpassword: "",
  });
  const [visibility, setVisibility] = useState({
    oldpassword: false,
    newpassword: false,
    repeatpassword: false,
  });
  const [PASSDATA] = useMutation(changPassword);
  const { allQuery } = useQuery();
  const { handleOpen } = usePopup();
  const userData = useSelector(state => state.reducer.userData);

  const yupSchema = Yup.object({
    newpassword: Yup.string()
      .matches(regexPassword, "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be 8-15 characters long")
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .max(15, "Password must be at most 15 characters long"),
    repeatpassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("newpassword"), null], "Passwords must match"),
  });

  function onhandlechange(e) {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  }
  async function checkError() {
    try {
      const data = await yupSchema.validate(values, { abortEarly: false });
      setErrorState({ newpassword: false, repeatpassword: false });
      return data;
    } catch (validationErrors) {
      const newErrors = {};
      validationErrors.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });
      setErrorState(newErrors);
      return null;
    }
  }
  async function handleclick() {
    const check = await checkError();
    setErr(null);
    if (check) {
      allQuery({
        QUERY: PASSDATA,
        variables: {
          data: {
            newPassword: values.newpassword,
            oldPassword: values.oldpassword,
            userId: userData.id,
          },
        },
        onCompleteFunc: () => {
          setErr("");
          setValues({
            oldpassword: "",
            newpassword: "",
            repeatpassword: "",
          });
          handleOpen(true, "Password changed Successfully");
        },
        onErrorFunc: errdata => {
          setErr(errdata.message);
        },
      });
    }
  }
  return (
    <Box className="FormWrapper">
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomFormLabel
              sx={{
                mt: 0,
              }}
              htmlFor="text-cpwd"
            >
              Old Password
            </CustomFormLabel>
            <CustomTextField
              id="text-cpwd"
              placeholder="Current Password"
              onChange={e => {
                onhandlechange(e);
              }}
              variant="outlined"
              fullWidth
              name="oldpassword"
              type={visibility.oldpassword ? "text" : "password"}
              value={values.oldpassword}
              error={errorState.oldpassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setVisibility(prev => ({ ...prev, oldpassword: !prev.oldpassword }));
                    }}
                  >
                    {visibility.oldpassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CustomFormLabel htmlFor="text-npwd">New Password</CustomFormLabel>
            <CustomTextField
              id="text-npwd"
              placeholder="New Password"
              onChange={e => {
                onhandlechange(e);
              }}
              variant="outlined"
              fullWidth
              name="newpassword"
              type={visibility.newpassword ? "text" : "password"}
              value={values.newpassword}
              error={errorState.newpassword}
              helperText={errorState.newpassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setVisibility(prev => ({ ...prev, newpassword: !prev.newpassword }));
                    }}
                  >
                    {visibility.newpassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CustomFormLabel htmlFor="text-conpwd">Confirm Password</CustomFormLabel>
            <CustomTextField
              id="text-conpwd"
              placeholder="Confirm  Password"
              onChange={e => {
                onhandlechange(e);
              }}
              variant="outlined"
              fullWidth
              name="repeatpassword"
              type={visibility.repeatpassword ? "text" : "password"}
              value={values.repeatpassword}
              error={errorState.repeatpassword}
              helperText={errorState.repeatpassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setVisibility(prev => ({ ...prev, repeatpassword: !prev.repeatpassword }));
                    }}
                  >
                    {visibility.repeatpassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="UpdateBtnWrapper">
              <Button
                onClick={e => {
                  handleclick(e);
                }}
                variant="contained"
                color="primary"
                sx={{ minWidth: "80px" }}
              >
                Update
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      {err && (
        <Typography component="p" sx={{ marginTop: -1, color: "red", fontWeight: "600", marginBottom: 2 }}>
          {err}
        </Typography>
      )}
    </Box>
  );
}
