import { Box, Typography, useMediaQuery } from "@mui/material";
import blurmobileimg from "../../Images/backgrounds/blurmobileimg.png";
import blurdesktopimg from "../../Images/backgrounds/blurdesktopimg.png";

function BlurSection() {
  const isMblBreakpoint = useMediaQuery("(max-width: 600px)");

  return (
    <Box>
      {isMblBreakpoint ? (
        <Typography component="img" sx={{ width: "100%" }} src={blurmobileimg} alt="" />
      ) : (
        <Typography component="img" sx={{ width: "100%" }} src={blurdesktopimg} alt="" />
      )}
    </Box>
  );
}

export default BlurSection;
