import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Box, Typography, Button, Divider, Stack, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import CustomTextField from "../forms/theme-elements/CustomTextField";
import CustomFormLabel from "../forms/theme-elements/CustomFormLabel";
import { preSignUp } from "../../gql/mutations/auth";
import { validateEmail, validatePassword, validateString } from "../Validate/validate";
import MessagePopUp from "../Modal/messagePopUp";
import useQuery from "../../hooks/allQuery";
import { usePopup } from "../../context/commonPop";
import LoginGoogle from "../../googleAuth/loginGoogle";

function AuthRegister({ title, subtitle, subtext }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [signup, setSignup] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
  });
  const [errorState, setErrorState] = useState({
    name: false,
    email: false,
    password: false,
    password2: false,
  });

  const [PRESIGNUP] = useMutation(preSignUp);
  const { allQuery } = useQuery();
  const { handleOpen } = usePopup();

  const handleChange = e => {
    const { name, value } = e.target;
    setSignup({ ...signup, [name]: value });
  };

  const checkError = async data => {
    const name = await validateString(data.name);
    const email = await validateEmail(data.email);
    const password = await validatePassword(data.password);
    let password2;
    if (data.password !== data.password2) {
      password2 = true;
    } else {
      password2 = false;
    }
    await setErrorState({
      ...errorState,
      name,
      email,
      password,
      password2,
    });
    if (name || email || password || password2) {
      return true;
    }
    return false;
  };

  const signupUser = async e => {
    setIsLoading(true);
    e.preventDefault();
    const check = await checkError(signup);
    if (check) {
      setIsLoading(false);
      return;
    }
    allQuery({
      QUERY: PRESIGNUP,
      variables: {
        data: {
          name: signup.name,
          email: signup.email,
          password: signup.password,
        },
      },
      onCompleteFunc: data => {
        setIsLoading(false);
        if (data?.preSignUp.message) {
          setMessage(data.preSignUp.message);
          setOpen(true);
        }
        if (data?.preSignUp.response) {
          setMessage(data.preSignUp.response);
          setOpen(true);
        }
      },
      onErrorFunc: errdata => {
        setIsLoading(false);
        console.log("err", errdata?.message);
        handleOpen(true, errdata?.message || "Something went wrong");
      },
    });
  };
  return (
    <>
      {title ? (
        <Typography fontWeight="700" variant="h3" mb={1}>
          {title}
        </Typography>
      ) : null}

      {subtext}
      <LoginGoogle />
      <Box mt={3}>
        <Divider>
          <Typography component="span" color="textSecondary" variant="h6" fontWeight="400" position="relative" px={2}>
            or sign up with
          </Typography>
        </Divider>
      </Box>
      {open && <MessagePopUp message={message} open={open} setOpen={setOpen} />}
      <Box>
        <Stack mb={3}>
          <CustomFormLabel htmlFor="name">Name</CustomFormLabel>
          <CustomTextField
            id="name"
            name="name"
            type="text"
            variant="outlined"
            fullWidth
            value={signup.name}
            onChange={e => handleChange(e)}
            error={errorState.name}
            helperText={errorState.name && "Please enter valid name"}
          />
          <CustomFormLabel htmlFor="email">Email Adddress</CustomFormLabel>
          <CustomTextField
            id="email"
            name="email"
            type="email"
            variant="outlined"
            fullWidth
            value={signup.email}
            onChange={e => handleChange(e)}
            error={errorState.email}
            helperText={errorState.email && "Please enter valid email"}
          />
          <CustomFormLabel htmlFor="password">Password</CustomFormLabel>
          <CustomTextField
            id="password"
            name="password"
            type="password"
            variant="outlined"
            fullWidth
            value={signup.password}
            onChange={e => handleChange(e)}
            error={errorState.password}
            helperText={
              errorState.password
                ? "Please enter strong password, Password should be 8 to 15 digit long and contain atleast one small letter, one capital letter, one special character and one number"
                : "Password should be 8 to 15 digit long and contain atleast one small letter, one capital letter, one special character and one number"
            }
            pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!*^?+-_@#$%&]{8,}$"
          />
          <CustomFormLabel htmlFor="password">Confirm Password</CustomFormLabel>
          <CustomTextField
            id="password"
            name="password2"
            type="password"
            variant="outlined"
            fullWidth
            onChange={e => handleChange(e)}
            value={signup.password2}
            error={errorState.password2}
            helperText={errorState.password2 && "Password does not match"}
          />
        </Stack>
        <Button color="primary" variant="contained" size="large" fullWidth component={Link} to="/" onClick={signupUser}>
          {isLoading ? <CircularProgress sx={{ color: "#ffffff" }} size={26.25} /> : "Sign Up"}
        </Button>
      </Box>
      {subtitle}
    </>
  );
}

export default AuthRegister;
