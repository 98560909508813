import gql from "graphql-tag";

export const getOrgInvitations = gql`
  query GetInvitations($email: String!) {
    getInvitations(email: $email) {
      authorId
      createdAt
      email
      id
      organizationId
      token
      updatedAt
      isOpened
      invitationsOrgData {
        name
      }
    }
  }
`;
