import { Box, Button, Card, Checkbox, Container, Grid, Typography, useTheme } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { Link, useSearchParams } from "react-router-dom";
import UpdateProject from "../components/UpdateProject";
import { deleteWebsite } from "../gql/queries/deleteWebsite";
import VerifyOwnership from "../components/Verify/verifywebsite";
import BackButton from "../components/BackButton/BackButton";
import SiteAudit from "../components/SiteAudit/SiteAudit";
import { GetUserWebsiteData } from "../hooks/userWebsiteData";
import UptimeMonitoringSetting from "../components/uptime-monitoring/UptimeMonitoringSetting";
import useQuery from "../hooks/allQuery";
import { SCRAPY_DATA } from "../gql/queries/userWebSiteList";
import { getDomain } from "../utils/myUtills";

const TabWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  bgcolor: "background.paper",
  display: "flex",
  minHeight: 224,

  "& button:focus": {
    outline: "0",
  },
  "& button.Mui-selected": {
    color: theme.palette.common.white,
  },

  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.common.orange,
  },
  "& .BtnWrapper": {
    "& button": {
      background: theme.palette.primary.main,
    },
  },
  "& .css-u3jdsl-MuiSwitch-thumb": {
    color: theme.palette.text.primary,
  },
  "& .Mui-checked .css-u3jdsl-MuiSwitch-thumb": {
    color: theme.palette.primary.main,
  },
}));

const TabBtnWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& .tabItem": {
    color: theme.palette.text.primary,

    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
    },
  },
  "@media screen and (max-width:768px)": {
    flexDirection: "column",
    justifyContent: "center",
    border: `1 px solid ${theme.palette.text.primary}`,
    paddingTop: "15px",
    "& .MuiTabs-flexContainer": {
      flexWrap: "nowrap",
    },
  },

  "@media screen and (max-width:600px)": {
    overflow: "scroll",

    display: "block",

    "& .tabItem": {
      color: theme.palette.text.primary,
      width: "auto",
      borderRadius: "0px",
      fontWeight: 600,

      "&:first-of-type": {
        borderTopLeftRadius: "50px",
        borderBottomLeftRadius: "50px",
      },
      "&:last-child": {
        borderTopRightRadius: "50px",
        borderBottomRightRadius: "50px",
      },
    },
    "& button:focus": {
      outline: "0",
    },
    "& button.Mui-selected": {
      color: "#fff",
      background: theme.palette.primary.main,
    },

    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.common.orange,
    },
    "& .CreateProjectBtn": {
      textTransform: "none",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "5px",
      boxShadow: "none",
      marginBottom: "15px",
      color: "#ffffff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Plus Jakarta Sans', sans-serif !important",
      marginLeft: "auto",
    },
  },
}));

const InnerTabWrapper = {
  minHeight: "auto",
  display: "flex",
  borderRadius: "50px",
  marginBottom: "15px",
  "@media screen and (max-width:600px)": {
    borderRadius: "0",
  },
  "& .css-heg063-MuiTabs-flexContainer": {
    "@media screen and (max-width:600px)": {
      flexWrap: "nowrap",
    },
    overflow: "revert",
  },
  "& button": {
    flexDirection: "row",
    textTransform: "none",

    borderRadius: "2px",
    p: "10px 18px 10px",
    minHeight: "auto",
    mr: "0px",
    "& .countTag": {
      fontSize: "14px",
      ml: "5px",
      color: "#ff642d94 ",
    },
    "&.Mui-selected": {
      "& .countTag": {
        color: "#ffffff ",
      },
    },
    "&:focus": { outline: "none" },
  },
  "& .MuiTabs-indicator": { display: "none" },
};
const CreateProjectButton = {
  textTransform: "none",
  backgroundColor: "#ff642d",
  color: "#fff",
  borderRadius: "5px",
  boxShadow: "none",
  mb: "15px",
  mr: "15px",
  "& a": {
    color: "#ffffff",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Plus Jakarta Sans', sans-serif !important",
    "& svg": { mr: "6px", fontSize: "22px", transition: "0.9s ease-out" },
  },
  "&:hover": { backgroundColor: "transparent", boxShadow: "none", "& a": { color: "#ff642d" }, "& svg": { transform: "rotateZ(720deg)" } },
  "&:focus": { outline: "none" },
};

const BoxWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "7px",
  "& h3": {
    fontSize: "24px",
    fontWeight: 100,
    marginBottom: "20px",
  },
  "& .ProjectWrapper1": {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
    "& h2": { fontSize: "18px", fontWeight: 600, marginBottom: "4px" },
    "& a": {
      color: theme.palette.primary.main,
      "& svg": {
        fontSize: "14px",
        marginLeft: "5px",
        marginTop: "0px",
      },
    },
  },
}));
const TabDataWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& > button": {
    textTransform: "none",
    background: "#ff642d",
    color: "#fff",
    borderRadius: "2px",
    p: "5px 16px",
    border: "1px solid #ff642d",
    "&:hover": {
      background: "transparent",
      color: "#ff642d",
    },
  },
};
const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
}));

const ImportantWrapper = styled(Box)(({ theme }) => ({
  "& h4": {
    color: theme.palette.text.primary,
    fontSize: "25px",
    fontWeight: 600,
  },
  "& .DeleteCheckboxWrapper": {
    color: theme.palette.text.primary,
    fontWeight: 600,
    marginTop: "30px",
    marginBottom: "30px",
    "& span.Label": { fontWeight: 600, color: theme.palette.text.primary, marginBottom: "3px" },
    "& span.MuiCheckbox-root": { paddingLeft: "0" },
  },
  "& .ProjectWrapper1": {
    display: "flex",
    flexDirection: "column",
    "& a": {
      color: theme.palette.primary.main,
      "& svg": {
        width: "2px",
        height: "2px",
        marginLeft: "5px",
        marginTop: "1px",
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box sx={{ flexGrow: "1" }} role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: { xs: "20px 0px 20px 0px", sm: "20px 40px 20px 0px" } }}>{children}</Box>}
    </Box>
  );
}
function WebSettingTab({ ProjectData }) {
  const { ownerId } = useParams();
  const [searchParams] = useSearchParams();
  const [deleteClicked, setDeleteClicked] = useState();
  const [value, setValue] = useState(0);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const { allQuery } = useQuery();
  const { userData } = useSelector(state => state.reducer);
  const [deleteUserWebsite] = useLazyQuery(deleteWebsite, { fetchPolicy: "network-and-cache" });
  const customizer = useSelector(state => state.customizer);
  const theme = useTheme();
  const borderColor = theme.palette.divider;

  const [propsdata, setPropsData] = useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleDeleteSubmit = async ({ id }) => {
    allQuery({
      QUERY: deleteUserWebsite,
      variables: {
        deleteWebsiteId: id,
      },
      type: "delete",
      cacheQueryData: {
        cacheQuery: SCRAPY_DATA,
        variablesCache: {
          params: userData.id,
        },
      },
      onCompleteFunc: async data => {
        if (data.deleteWebsite) {
          navigate("/dashboard?menu=dashboard");
        }
      },
    });
  };

  const handleDeleteClose = () => {
    setDeleteClicked(null);
  };

  const handleDelete = id => {
    setDeleteClicked(id);
  };

  const handleCheckboxChange = event => {
    setDeleteButtonDisabled(!event.target.checked);
  };

  useEffect(() => {
    if (ProjectData) {
      setPropsData(ProjectData);
    }
    // eslint-disable-next-line
  }, [ProjectData]);
  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  "& h2": {
                    mb: "15px",
                    color: theme.palette.text.primary,
                    fontWeight: 700,
                    fontSize: "18px",
                  },
                }}
              >
                <BackButton />
              </Box>
              <BoxWrapper>
                <Card
                  sx={{ padding: "30px 45px 24px 40px", border: customizer.isCardShadow ? `1px solid ${borderColor}` : "none" }}
                  elevation={customizer.isCardShadow ? 9 : 0}
                  variant={!customizer.isCardShadow ? "outlined" : undefined}
                >
                  <Box>
                    <Box className="ProjectWrapper1">
                      <Typography component="h2">{searchParams.get("origin")}</Typography>
                      <Typography component="a" target="_blank" href={propsdata && propsdata.website}>
                        {propsdata && getDomain(propsdata.website)}
                        <LaunchIcon fontSize="16px" />
                      </Typography>
                    </Box>
                  </Box>
                  <TabBtnWrapper>
                    <Box>
                      <Tabs
                        value={value}
                        scrollButtons="auto"
                        aria-label="basic tabs example"
                        onChange={handleChange}
                        sx={{ ...InnerTabWrapper, border: customizer.isCardShadow ? `1px solid ${borderColor}` : "none" }}
                      >
                        <Tab label="Scope" className="tabItem" />
                        <Tab label="Verify" className="tabItem" />
                        <Tab label="Site Audit" className="tabItem" />
                        <Tab
                          label="Delete"
                          className="tabItem"
                          onClick={() => {
                            handleDelete(ownerId);
                          }}
                        />
                        <Tab label="Uptime Monitoring" className="tabItem" />
                      </Tabs>
                    </Box>
                    <Button variant="contained" className="CreateProjectBtn">
                      <Link to="/website" style={{ color: "#fff" }}>
                        <AddIcon />
                        Create Project
                      </Link>
                    </Button>
                  </TabBtnWrapper>

                  <Box sx={TabDataWrapper}>
                    <TabWrapper sx={{}}>
                      <TabPanel value={value} index={0}>
                        <UpdateProject propsdata={propsdata} setPropsData={setPropsData} />
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <VerifyOwnership varifyBoolean />
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <SiteAudit />
                      </TabPanel>
                      <TabPanel value={value} index={3}>
                        {deleteClicked && (
                          <ImportantWrapper>
                            <Typography component="h4">Important</Typography>
                            <Box className="DeleteCheckboxWrapper">
                              <Checkbox onChange={handleCheckboxChange} />
                              <Typography component="span" className="Label">
                                {" "}
                                Are you sure you want to delete, <strong>{propsdata.projectName}</strong>
                              </Typography>
                            </Box>
                            <Box className="BtnWrapper">
                              <Button variant="contained" sx={CreateProjectButton} onClick={() => handleDeleteSubmit({ id: ownerId })} disabled={deleteButtonDisabled}>
                                Delete
                              </Button>
                              <Button variant="contained" sx={CreateProjectButton} onClick={handleDeleteClose}>
                                Cancel
                              </Button>
                            </Box>
                          </ImportantWrapper>
                        )}
                      </TabPanel>
                      <TabPanel value={value} index={4}>
                        <UptimeMonitoringSetting projectid={ownerId} />
                      </TabPanel>
                    </TabWrapper>
                  </Box>
                </Card>
              </BoxWrapper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}
function WebsiteSetting() {
  const { ownerId } = useParams();
  const { ProjectData } = GetUserWebsiteData(ownerId);
  return <WebSettingTab ProjectData={ProjectData} />;
}
export default WebsiteSetting;
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
