export function indexbilityTurnOffPreFunc(splitFlag) {
  let payload = {};
  const canonicalFlag = ["status200", "status2xx", "status300", "status4xx", "status400", "status404", "status5xx", "status500", "pointToRedirect"];
  const robotsContentFlag = ["noFollow", "noIndex", "noFollowIndex", "noFollowInHttpHeader", "noIndexInHttpHeader", "httpToHttps", "httpsToHttp"];
  if (canonicalFlag.includes(splitFlag)) {
    payload = { flag: splitFlag, key: "canonicalData" };
  }
  if (robotsContentFlag.includes(splitFlag)) {
    payload = { flag: splitFlag, key: "robotsContent" };
  }
  return payload;
}

export const acordingTabFilterArr = (dataArr, selectedStatus) => {
  const dataArrAll = [];
  const dataArrTurnOff = [];
  const dataArrActual = [];
  const dataArrNew = [];
  dataArr.forEach(data => {
    if (selectedStatus?.length === 0 || selectedStatus.includes(data.status)) {
      if (data.turnOff !== true) dataArrAll.push(data);
      if (data.turnOff !== true && data.newIssue > 0 && typeof data.newIssue === "number") dataArrNew.push(data);
      if (data.turnOff === true) dataArrTurnOff.push(data);
      if (data.turnOff !== true && data.count > 0) dataArrActual.push(data);
    }
    // if (selectedStatus.includes(data.status)) {
    //   if (data.turnOff === true) dataArrTurnOff.push(data);
    //   if (data.turnOff !== true && data.count > 0) dataArrActual.push(data);
    // }
  });
  return { dataArrAll, dataArrTurnOff, dataArrActual, dataArrNew };
};

export const contentNewTableArray = [
  "metaDescMissing&content&indexable",
  "titleTagMissing&content&indexable",
  "titleSort&content&indexable",
  "metaDescSort&content&indexable",
  "metaDescMissing&content&nonIndexable",
  "titleTagMissing&content&nonIndexable",
  "titleSort&content&nonIndexable",
  "metaDescSort&content&nonIndexable",
];
