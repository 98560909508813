import gql from "graphql-tag";

export const login = gql`
  mutation Login($data: LoginInput!) {
    login(data: $data) {
      accessToken
      refreshToken
      user {
        name
        lastname
        id
        email
        address
        isActive
        isVerified
        bio
        phone
        isGoogleAuth
        crawlLimit
        imageUrl
        role
        googlePassword
        userTheme
        razorpaySubscriptionId
      }
      subscription {
        plan
        active
      }
      subscriptionCredits {
        _id
        blogGeneration
        endTime
        projects
        siteAudit
        subscriptionType
        userId
      }
    }
  }
`;

export const changPassword = gql`
  mutation Mutation($data: ChangePasswordInput!) {
    changeMyPassword(data: $data) {
      email
      id
      isActive
      phone
      name
      bio
      role
      crawlLimit
      googlePassword
    }
  }
`;
export const SET_PASSWORD = gql`
  mutation Mutation($data: SetPasswordInput!) {
    setNewPassword(data: $data) {
      googlePassword
      address
      bio
      crawlLimit
      email
      id
      imageUrl
      isActive
      isGoogleAuth
      isInOrganization
      isPersonal
      isVerified
      lastname
      name
      phone
      role
    }
  }
`;

export const REGISTER = gql`
  mutation registerUser($email: String!, $password: String!) {
    registerUser(email: $email, password: $password) {
      email
      name
      password
      token
    }
  }
`;

export const UpdateUser = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      address
      bio
      email
      id
      lastname
      imageUrl
      name
      phone
      role
      crawlLimit
      googlePassword
      userTheme
      country_phone_code
      country_currency
      location
    }
  }
`;
export const authSignupByGoogle = gql`
  mutation Mutation($tokenId: String!) {
    signInByGoogleAuth(tokenId: $tokenId) {
      accessToken
      refreshToken
      user {
        name
        lastname
        id
        email
        address
        isActive
        isVerified
        bio
        phone
        isGoogleAuth
        crawlLimit
        imageUrl
        role
        googlePassword
        userTheme
        razorpaySubscriptionId
      }
      subscription {
        plan
        active
      }
      subscriptionCredits {
        _id
        blogGeneration
        endTime
        projects
        siteAudit
        subscriptionType
        userId
      }
    }
  }
`;

export const preSignUp = gql`
  mutation Mutation($data: SignupInput!) {
    preSignUp(data: $data)
  }
`;

export const TOKENINPUT = gql`
  mutation Mutation($data: TokenInput!) {
    tokenData(data: $data) {
      accessToken
      refreshToken
      user {
        address
        imageUrl
        bio
        email
        id
        isVerified
        isGoogleAuth
        lastname
        name
        phone
        role
        crawlLimit
        googlePassword
        razorpaySubscriptionId
      }
      subscription {
        plan
        active
      }
      subscriptionCredits {
        _id
        blogGeneration
        endTime
        projects
        siteAudit
        subscriptionType
        userId
      }
    }
  }
`;

export const FORGETPASSWORD = gql`
  mutation Mutation($data: ForgetPassInput!) {
    forgetPassword(data: $data) {
      accessToken
    }
  }
`;

export const POST_CREATED = gql`
  subscription PostCreated {
    postCreated {
      id
      title
      content
      author {
        address
        id
        isVerified
        name
        role
      }
    }
  }
`;

export const MESSAGE_SUBSCRIPTION = gql`
  subscription Subscription {
    authCreated {
      accessToken
      user {
        bio
        email
        id
        isVerified
        name
        phone
      }
    }
  }
`;

export const MESSAGE_USER_SUB = gql`
  subscription Subscription($email: String!) {
    userCreate(email: $email) {
      address
      bio
      email
      id
      isVerified
      phone
      name
      address
      role
    }
  }
`;

export const RESEND_MAIL_VERIFY = gql`
  mutation ResendVerifyLink($data: TokenInput!) {
    resendVerifyLink(data: $data) {
      accessToken
      user {
        address
        email
        id
        name
        role
        isVerified
        bio
        phone
        googlePassword
      }
      subscription {
        plan
        active
      }
      subscriptionCredits {
        _id
        blogGeneration
        endTime
        projects
        siteAudit
        subscriptionType
        userId
      }
    }
  }
`;

export const verifyLink = gql`
  mutation Mutation($email: String!) {
    sendVerifyLink(email: $email)
  }
`;

// export const SEND_VERIFY_LINK = gql`
//   mutation Mutation($email: String!) {
//     sendVerifyLink(email: $email)
//   }
// `;

export const refreshJwtToken = gql`
  mutation Mutation {
    refreshToken {
      accessToken
      refreshToken
    }
  }
`;
