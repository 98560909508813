import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Avatar, List, ListItemText, TextField, Box, Alert, Badge, ListItemButton, Typography, InputAdornment, Button } from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router";
import { IconSearch } from "@tabler/icons-react";
import Scrollbar from "../custom-scroll/Scrollbar";
import useQuery from "../../hooks/allQuery";
import { getTicketsAdmin } from "../../gql/queries/ticketadmin";
import defultUserImg from "../../Images/profile/user-1.jpg";

function ChatListingAdmin() {
  const [ticketsFetch, setTicketsFetch] = useState([]);
  const [searchTicket, setSearchTicket] = useState("");
  const navigate = useNavigate();
  const [GET_ALL_TICKETS] = useLazyQuery(getTicketsAdmin, { fetchPolicy: "cache-first" });
  const { userData } = useSelector(state => state.reducer);
  const { allQuery } = useQuery();

  const onSearchChange = e => {
    setSearchTicket(e.target.value);
  };
  useEffect(() => {
    allQuery({
      QUERY: GET_ALL_TICKETS,
      variables: {
        ticketId: searchTicket,
      },
      onCompleteFunc: ({ getTickets }) => {
        setTicketsFetch(getTickets);
      },
    });
  }, [searchTicket]);

  const handleClick = id => {
    navigate(`/tickets/chat/${id}`);
  };
  return (
    <Box>
      {/* ------------------------------------------- */}
      {/* Profile */}
      {/* ------------------------------------------- */}
      <Box display="flex" alignItems="center" gap="10px" p={3}>
        <Badge
          variant="dot"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          overlap="circular"
          color="success"
        >
          <Avatar alt="Remy Sharp" src={userData?.imageUrl || defultUserImg} sx={{ width: 54, height: 54 }} />
        </Badge>
        <Box>
          <Typography variant="body1" fontWeight={600}>
            {userData?.name}
          </Typography>
          <Typography variant="body2">Marketing Manager</Typography>
        </Box>
      </Box>
      {/* ------------------------------------------- */}
      {/* Search */}
      {/* ------------------------------------------- */}
      <Box px={3} py={1}>
        <TextField
          id="outlined-search"
          placeholder="Search Tickets"
          size="small"
          type="search"
          variant="outlined"
          value={searchTicket}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconSearch size="16" />
              </InputAdornment>
            ),
          }}
          fullWidth
          onChange={onSearchChange}
        />
      </Box>
      {/* ------------------------------------------- */}
      {/* Ticket List */}
      {/* ------------------------------------------- */}
      <List sx={{ px: 0 }}>
        <Box px={2.5} pb={1}>
          <Button id="basic-button" aria-haspopup="true" color="inherit">
            Recent Chat
          </Button>
        </Box>
        <Scrollbar
          sx={{
            height: { lg: "calc(100vh - 100px)", md: "100vh" },
            maxHeight: "600px",
          }}
        >
          {ticketsFetch && ticketsFetch?.length ? (
            ticketsFetch.map(chat => (
              <ListItemButton
                key={chat.ticketId}
                onClick={() => handleClick(chat?.ticketId)}
                sx={{
                  mb: 0.5,
                  py: 2,
                  px: 3,
                  alignItems: "start",
                }}
              >
                <ListItemText
                  primary={
                    <Typography sx={{ textOverflow: "ellipsis", overflow: "hidden" }} variant="subtitle2" fontWeight={600} mb={0.5}>
                      {chat?.ticketId}
                    </Typography>
                  }
                  secondaryTypographyProps={{
                    noWrap: true,
                  }}
                  sx={{ my: 0 }}
                />
                <Box sx={{ flexShrink: "0" }} mt={0.5}>
                  <Typography variant="body2">
                    {formatDistanceToNowStrict(new Date(chat?.createdAt), {
                      addSuffix: false,
                    })}
                  </Typography>
                </Box>
              </ListItemButton>
            ))
          ) : (
            <Box m={2}>
              <Alert severity="error" variant="filled" sx={{ color: "white" }}>
                No Tickets Found!
              </Alert>
            </Box>
          )}
        </Scrollbar>
      </List>
    </Box>
  );
}

export default ChatListingAdmin;
