/* eslint-disable */
import React from "react";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import MannualSteps from "./MannualSteps";
import GoogleSearchConsoleIcon from "../SvgIcons/GoogleSearchConsoleIcon";
import RightCheckMark from "../SvgIcons/RightCheckMark";
import BackButton from "../registerUser/backButton";
import { useNavigate } from "react-router";

const BoxWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "20px",
  border: "1px solid #E3E3E3",
  borderRadius: "8px",
  "& .ImportMainHead": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "@media screen and (max-width:600px)": {
      flexDirection: "column",
      gap: "20px",
    },
    "& .MainHeading": { color: "#2A3547", textAlign: "center", lineHeight: 1, fontSize: "24px", fontWeight: 700, marginBottom: "10px" },
    "& .SubHeading": {
      color: "#757575",
      textAlign: "center",
      lineHeight: 1,
      fontSize: "14px",
      fontWeight: 500,
    },
  },
  "& .ImportAndAddProject": {
    backgroundColor: "#F7F7F7",
    borderRadius: "7px",
    padding: "20px",
    height: "100%",
    "& .SearchConsole": {
      marginBottom: "35px",
    },
    "& .StepsWrapper": {
      marginBottom: "17px",
    },
    "& .ListMainHeading": {
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "1",
      color: theme.palette.text.primary,
      marginBottom: "20px",
    },
    "& .PermissionList": {
      marginBottom: "30px",
      "& .ListBox": {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        gap: "8px",
        marginBottom: "10px",
        "&:last-of-type": {
          marginBottom: "0px",
        },
        "& svg.RightCheckMark": {},
        "& p": { fontSize: "14px", fontWeight: 500, lineHeight: "1", color: "#757575" },
      },
    },
    "& .BtnWrapper": {
      "& button": {
        textTransform: "none",
        backgroundColor: "#45C0CB",
        color: "#ffffff",
        borderRadius: "5px",
        boxShadow: "none",
        padding: "12px 22px 10px",
        width: "100%",
        "&:focus": {
          outline: "0",
        },
      },
    },
    "& .BottomDescription": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "17.64px",
      textAlign: "left",
      marginTop: "16px",
      color: "#757575",
    },
  },
}));
function ImportProject() {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate(`/first-project-config/project`);
  };

  return (
    <BoxWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box className="ImportMainHead">
            <Box sx={{ flex: "1" }}>
              <Typography component="h2" className="MainHeading">
                Import or add your project
              </Typography>
              <Typography component="p" className="SubHeading">
                Where did you learn about Hotspot SEO
              </Typography>
            </Box>
            <BackButton
              sx={{
                marginLeft: "-80px",
                zIndex: 99999999,
                "@media screen and (max-width:600px)": { marginLeft: "0" },
                "& button": { marginBottom: "0 !important", backgroundColor: "#45C0CB !important" },
              }}
              setState={() => setManageComponent(1)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="ImportAndAddProject">
            <Box className="SearchConsole">
              <GoogleSearchConsoleIcon />
            </Box>
            <Typography component={"h2"} className="ListMainHeading">
              Import From GSC
            </Typography>
            <Box className="PermissionList">
              <Box className="ListBox">
                <RightCheckMark />
                <Typography component="p">Automatic ownership verification</Typography>
              </Box>
              <Box className="ListBox">
                <RightCheckMark />
                <Typography component="p">Import multiple projects at once</Typography>
              </Box>
              <Box className="ListBox">
                <RightCheckMark />
                <Typography component="p">Edit project settings later</Typography>
              </Box>
            </Box>
            <Box className="BtnWrapper">
              <Button>Import</Button>
            </Box>
            <Typography component="p" className="BottomDescription">
              We’ll ask you to connect your Google Account and select which projects to import
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="ImportAndAddProject">
            <Box className="StepsWrapper">
              <MannualSteps activeStep={-1} />
            </Box>
            <Typography component={"h2"} className="ListMainHeading">
              Add Manually
            </Typography>
            <Box className="PermissionList">
              <Box className="ListBox">
                <RightCheckMark />
                <Typography component="p">Manual ownership verification required</Typography>
              </Box>
              <Box className="ListBox">
                <RightCheckMark />
                <Typography component="p">Add one project at a time</Typography>
              </Box>
              <Box className="ListBox">
                <RightCheckMark />
                <Typography component="p">Edit project settings later</Typography>
              </Box>
            </Box>
            <Box className="BtnWrapper">
              <Button onClick={handleSubmit}>Continue With Manually</Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </BoxWrapper>
  );
}

export default ImportProject;
