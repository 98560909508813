import gql from "graphql-tag";

export const LIST_ALL_USERS = gql`
  query listAllUsers {
    listAllUsers {
      email
      isAdmin
      isActive
      registrationDate
      lastLogin
      uuid
    }
  }
`;

export const forgotPassword = gql`
  query Query($email: String!) {
    preForgetPassword(email: $email)
  }
`;

export const ScrapyUser = gql`
  query Query($email: String!) {
    scrapy(email: $email)
  }
`;

export const TOKENBYUSER = gql`
  query TokenByUserData($token: String!) {
    tokenByUserData(token: $token) {
      accessToken
      refreshToken
      subscription {
        plan
        active
      }
      subscriptionCredits {
        _id
        blogGeneration
        endTime
        projects
        siteAudit
        subscriptionType
        userId
      }
      user {
        address
        imageUrl
        bio
        email
        id
        isVerified
        isGoogleAuth
        lastname
        name
        phone
        location
        country_currency
        country_phone_code
        role
        crawlLimit
        googlePassword
        userTheme
        razorpayCustomerId
        razorpaySubscriptionId
      }
    }
  }
`;

export const searchUsers = gql`
  query SearchUsers($searchText: String!) {
    searchUsers(searchText: $searchText) {
      id
      email
      name
    }
  }
`;

export const FIND_USER_BY_ID = gql`
  query ExampleQuery($findUserByIdId: String!) {
    findUserById(id: $findUserByIdId) {
      memberOrgs {
        organizationData {
          name
          limit
          license
          authorId
          address
          GSTN
        }
        name
        organizationId
        authorId
      }
      id
      email
      name
      isPersonal
      crawlLimit
    }
  }
`;

export const UserBasisFindCrawlimt = gql`
  query GetAuthorBasisCrawlLimit($searchString: String) {
    GetAuthorBasisCrawlLimit(searchString: $searchString) {
      authorId
      basicCompleted
      createdAt
      id
      isCompleted
      isFailed
      isDeleted
      project
      sessionId
      usedCrawl
      website
      imageUrl
    }
  }
`;

export const TemporaryBasisQuery = gql`
  query TemporaryBasisQuery($temporaryBasisQueryId: String!) {
    temporaryBasisQuery(id: $temporaryBasisQueryId) {
      crawlLimit
      email
      id
    }
  }
`;

export const INCREASE_CRAWLLIMIT = gql`
  query Query {
    IncreaseRewards {
      crawlLimit
    }
  }
`;

export const ALL_USER = gql`
  query GetAllUserBySuperAdmin($signInpage: Int!, $signUpPage: Int!, $timeStamp: SuperAdmintimeStamp!) {
    getAllUserBySuperAdmin(signInpage: $signInpage, signUpPage: $signUpPage, timeStamp: $timeStamp) {
      users {
        name
        email
        crawlLimit
        createdAt
        isGoogleAuth
        isVerified
        role
        updatedAt
      }
      usersCount
      updatedAtUsers {
        name
        email
        crawlLimit
        createdAt
        isGoogleAuth
        isVerified
        role
        updatedAt
      }
      pageObject {
        createAtUsersCount
        totalSignInPage
        totalSignUpPage
        updatedAtUsersCount
      }
    }
  }
`;
export const GET_ALL_USER_GRAPH_VALUE = gql`
  query Query($timeStamp: SuperAdmintimeStamp!) {
    getAllUserGraphValueForAdminId(timeStamp: $timeStamp)
  }
`;

export const ALL_USER_TABLE_DATA = gql`
  query GetAdminTableData($page: Int!, $tabData: String!, $timeStamp: SuperAdmintimeStamp!) {
    getAdminTableData(page: $page, tabData: $tabData, timeStamp: $timeStamp) {
      users {
        name
        email
        crawlLimit
        createdAt
        isGoogleAuth
        isVerified
        role
        updatedAt
      }
      pageObject {
        UsersCount
        totalPage
        currentpage
      }
      usersCount
      LoginCountCount
    }
  }
`;
