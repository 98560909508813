import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  CircularProgress,
  styled,
  Box,
  useTheme,
  Container,
  Grid,
  Card,
} from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Check, Clear, Poll, ArrowBack } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { LIGHTHOUSE_SINGLE_REPORT, LIGHT_HOUSE_URL } from "../../gql/queries/scrapy";
import { getLighthouseReportForUrl } from "../../gql/queries/lighthouseReport";
import { usePopup } from "../../context/commonPop";
import MainLoader from "../MainLoader/MainLoader";
import BackButton from "../BackButton/BackButton";
import CreateProjectLink from "../createProject/CreateProjectLink";
import SearchBar from "../common/SearchBar";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .SearchTopBarWrapper": {
    "& .SearchBarWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "15px",
      "@media screen and (max-width:600px)": {
        flexDirection: "column",
      },
      "& button": {
        textTransform: "none",
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        borderRadius: "5px",
        boxShadow: "none",
        padding: "6px 22px",
        "@media screen and (max-width:600px)": {
          width: "100%",
        },
        "&:focus": {
          outline: "0",
        },
      },
    },
  },
  "& .BtnTopWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    "@media screen and (max-width:600px)": {
      flexDirection: "column",
    },
  },
  "& .TableMainWrapper": {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "7px 7px 7px 7px",
    "& table": {
      overflow: "hidden",
      "& thead": {
        backgroundColor: theme.palette.mode === "dark" ? "#FFFFFF33" : "#2A3547",
        borderRadius: "7px",
        "& tr": {
          "& th": {
            fontSize: "14px",
            fontWeight: 600,
            whiteSpace: "nowrap",
            color: "#ffffff",
          },
        },
      },
      "& tbody": {
        "& tr": {
          "& td": {
            paddingTop: "8px",
            paddingBottom: "8px",
            "& .ViewReportBtn": {
              whiteSpace: "nowrap",
            },
            "& .RegenerateBtn": {
              whiteSpace: "nowrap",
              minWidth: "108px",
            },
            "& > a": { color: theme.palette.primary.main },
          },
        },
      },
    },
  },
}));

function WebsiteUrl() {
  const [lightData, setLightData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [showReport, SetShowReport] = useState(false);
  const [reportProgress, setReportProgress] = useState(false);
  const [disableReports, setDisableReports] = useState(false);
  const [reportParams, setReportParams] = useState({
    url: null,
    projectId: null,
  });
  const [search, setSearch] = useState(null);
  const [loaderMap, setLoaderMap] = useState({});
  const [dataFetch, setDataFetch] = useState(false);
  const [lightHouseUrl] = useLazyQuery(LIGHT_HOUSE_URL, { fetchPolicy: "network-only" });
  const [ligthHouseSingleReport] = useMutation(LIGHTHOUSE_SINGLE_REPORT);
  const { handleOpen } = usePopup();
  const [searchParams] = useSearchParams();

  const { status, projectId } = useParams();
  const theme = useTheme();

  const filteredData = useMemo(
    () =>
      (lightData || []).filter(row => {
        if (status === "all") {
          return true; // Show all rows
        }
        if (status === "success") {
          return row.isReportGenerated === true; // Show rows with isReportGenerated true
        }
        if (status === "failed") {
          return row.isReportGenerated === false && row.isReportProgress === false; // Show rows with isReportGenerated false
        }
        if (status === "progress") {
          return row.isReportProgress === true; // Show rows with isReportProgress true
        }
        return false; // Default to not showing anything if the status is unknown
      }),
    [lightData, status],
  );

  const isMobile = searchParams.get("tab") === "Mobile";

  const handleClick = ({ url, id }) => {
    setLoaderMap(prevLoaderMap => ({ ...prevLoaderMap, [url]: true }));
    // setReportProgress(prev => !prev);
    setDisableReports(true);
    ligthHouseSingleReport({
      variables: { projectId, url, id },
      onCompleted: data => {
        console.log("data", data);
        setLoaderMap(prevLoaderMap => ({ ...prevLoaderMap, [url]: false }));
        setDataFetch(prevData => !prevData);
        setReportProgress(prev => !prev);
        setDisableReports(false);
      },
      onError: err => {
        setLoaderMap(prevLoaderMap => ({ ...prevLoaderMap, [url]: false }));
        setReportProgress(prev => !prev);
        setDisableReports(false);
        handleOpen(true, JSON.stringify(err.message));
      },
    });
  };

  const viewReport = url => {
    setReportParams({ url, projectId });
    SetShowReport(true);
  };

  const handleGoBack = () => {
    SetShowReport(false);
  };

  const findSearchValue = () => {
    const subString = search && search.trim().length > 0 ? search.trim() : "";
    const filterArr = filterData.filter(project => project.url.toLowerCase().includes(subString));
    if (filterArr && filterArr.length > 0) return setLightData(filterArr);
    return setLightData(filterArr);
  };

  useEffect(() => {
    if (lightData && lightData.length > 0) {
      findSearchValue();
    }
  }, [search]);

  const debouncedHandleChange = useCallback(
    debounce(value => {
      setSearch(value);
    }, 300),
    [],
  );

  const handleChange = e => {
    debouncedHandleChange(e.target.value);
  };

  useEffect(() => () => debouncedHandleChange.cancel(), [debouncedHandleChange]); // eslint-disable-line

  useEffect(() => {
    lightHouseUrl({
      variables: {
        projectId,
      },
      onCompleted: ({ GetAllUrlWithStatus }) => {
        setLightData(GetAllUrlWithStatus);
        setFilterData(GetAllUrlWithStatus);
      },
      onError: err => {
        console.log("err", err);
      },
    });
  }, [dataFetch, reportProgress]);

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          {showReport ? (
            <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
              <Grid item xs={12} lg={12}>
                <LightHouseReport handleGoBack={handleGoBack} reportParams={reportParams} isMobile={isMobile} />
              </Grid>
            </Grid>
          ) : lightData ? (
            <>
              <Grid container spacing={2} mt={0}>
                <Grid item xs={12} md={12}>
                  <Card
                    sx={{
                      padding: "16px 16px 16px 16px",
                      border: `1px solid ${theme.palette.divider}`,
                      boxShadow: "none !important",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "15px",
                      "@media screen and (max-width:600px)": {
                        flexDirection: "column",
                        alignItems: "stretch",
                      },
                    }}
                  >
                    <Box className="BtnTopWrapper">
                      <BackButton sx={{ "& button": { marginBottom: "0 !important" } }} />
                      <CreateProjectLink />
                    </Box>
                    <Box className="SearchTopBarWrapper">
                      <Box className="SearchBarWrapper">
                        <SearchBar handleChange={handleChange} />
                        <Button>Search</Button>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
                <Grid item xs={12} lg={12}>
                  {filteredData && filteredData.length ? (
                    <TableContainer className="TableMainWrapper">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>URL</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Created At</TableCell>
                            <TableCell align="center">Updated At</TableCell>
                            <TableCell align="center">Reports</TableCell>
                            <TableCell align="center">Regenerate Reports</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredData.map((row, ind) => (
                            <TableRow key={`${row.url + ind}`}>
                              <TableCell scope="row">
                                <Typography component="a" href={row.url} target="_blank">
                                  {row.url}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                {row.isReportGenerated ? <Check color="primary" /> : row.isReportProgress ? <CircularProgress size={24} /> : <Clear color="error" />}
                              </TableCell>
                              <TableCell scope="row" sx={{ whiteSpace: "nowrap" }}>
                                {new Date(row.createdAt).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" })}
                              </TableCell>
                              <TableCell scope="row" sx={{ whiteSpace: "nowrap" }}>
                                {new Date(row.updatedAt).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" })}
                              </TableCell>
                              <TableCell align="center">
                                {row.isReportGenerated ? (
                                  <Button className="ViewReportBtn" endIcon={<Poll />} onClick={() => viewReport(row.url)} disabled={row.isReportProgress}>
                                    View Report
                                  </Button>
                                ) : (
                                  "Report Not Found!"
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  disabled={loaderMap[row.url] || row.isReportProgress || disableReports}
                                  onClick={() => {
                                    // handleReportProgress({ id: row.id });
                                    handleClick({ url: row.url, id: row.id });
                                  }}
                                  className="RegenerateBtn"
                                >
                                  {loaderMap[row.url] || row.isReportProgress ? <CircularProgress size={24} /> : row.isReportGenerated ? "Regenerate" : "Generate Report"}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={7}>
                              <Typography
                                component="p"
                                sx={{
                                  color: theme.palette.text.primary,
                                  fontWeight: 700,
                                  textAlign: "center",
                                  width: "100%",
                                }}
                              >
                                No Url Found
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <MainLoader message="Loading" />
          )}
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export function LightHouseReport({ handleGoBack, reportParams, isMobile }) {
  const [lightHouseReport] = useLazyQuery(getLighthouseReportForUrl, { fetchPolicy: "network-only" });
  const [html, setHtml] = useState(null);

  useEffect(() => {
    lightHouseReport({
      variables: {
        projectId: reportParams.projectId,
        url: reportParams.url,
        isMobile: isMobile || false,
      },
      onCompleted: data => {
        if (data.lightHouseReportHtml.report) {
          const blob = new Blob([data.lightHouseReportHtml.report], {
            type: "text/html",
          });
          const url = URL.createObjectURL(blob);
          setHtml(url);
        } else {
          console.error("No report data found.");
        }
      },
      onError: err => {
        console.log("err", err);
      },
    });
  }, [reportParams, isMobile]);
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 2 }}>
        <Button startIcon={<ArrowBack />} onClick={handleGoBack} variant="contained">
          Back
        </Button>
      </Box>
      <Box sx={{ mt: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        {html && <iframe style={{ marginTop: "20px" }} src={html} title="Lighthouse Report" width="1024px" height="768px"></iframe>}
      </Box>
    </>
  );
}

export default WebsiteUrl;
