import gql from "graphql-tag";

export const getLighthouseReportForUrl = gql`
  query Query($projectId: String!, $url: String!, $isMobile: Boolean!) {
    lightHouseReportHtml(projectId: $projectId, url: $url, isMobile: $isMobile)
  }
`;

export const GET_ALL_PROJECT_WITH_LIGHT_HOUSE_AVG = gql`
  query Query {
    getProjectWithLightHouseData
  }
`;
