import { useMemo, useState } from "react";
import { IconButton, Box, Badge, Menu, Typography, Chip } from "@mui/material";
import { IconBellRinging } from "@tabler/icons-react";
import { Stack } from "@mui/system";
import { useSelector } from "react-redux";
import { AvailNotifications } from "../../../hooks/organization";

import Invitations from "../../Modal/components/Invitations";

function Notifications() {
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [isInvisible, setInvisible] = useState(false);
  const userData = useSelector(state => state.reducer.userData);
  const { email } = userData || {};
  const { data: invitations, refresh } = AvailNotifications(email);
  const notMarkedInvitTions = useMemo(() => invitations.filter(el => el.isOpened === false), [invitations]);

  const handleClick2 = event => {
    setAnchorEl2(event.currentTarget);
    setInvisible(true);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="show 11 new notifications"
        color="inherit"
        aria-controls="msgs-menu"
        aria-haspopup="true"
        sx={{
          color: anchorEl2 ? "primary.main" : "text.secondary",
        }}
        onClick={handleClick2}
      >
        <Badge invisible={isInvisible} variant="dot" color="primary">
          <IconBellRinging size="21" stroke="1.5" />
        </Badge>
      </IconButton>
      <Menu
        id="msgs-menu"
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleClose2}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        sx={{
          "& .MuiMenu-paper": {
            width: "360px",
          },
        }}
      >
        <Stack direction="row" py={2} px={4} justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Notifications</Typography>
          <Chip label={notMarkedInvitTions.length} color="primary" size="small" />
        </Stack>
        <Box sx={{ pl: 4, pr: 4 }}>
          {invitations.length > 0 ? invitations.map(data => <Invitations data={data} key={data?.invitationsOrgData?.name} refresh={refresh} />) : <Invitations notFound />}
        </Box>
      </Menu>
    </Box>
  );
}

export default Notifications;
