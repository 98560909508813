import { memo } from "react";
import { useSelector } from "react-redux";
import { Box, Avatar, Typography, Card, CardContent, Grid, Divider, Stack, useTheme } from "@mui/material";
import welcomeImage from "../../../Images/profile/user-1.jpg";
import welcomeImage2 from "../../../Images/backgrounds/welcome-bg.svg";
import { useSubscriptionCall } from "../../../hooks/auth";
function WelcomeCard() {
  const { name, imageUrl } = useSelector(state => state.reducer.userData) || {};
  const { data } = useSubscriptionCall();
  const theme = useTheme();
  return (
    <Card elevation={0} sx={{ backgroundColor: theme.palette.primary.light, py: 0, height: "100%" }}>
      <CardContent sx={{ py: 4, px: 2 }}>
        <Grid container justifyContent="space-between">
          <Grid item sm={6} display="flex" alignItems="center">
            <Box>
              <Box
                gap="16px"
                sx={{
                  display: {
                    xs: "block",
                    sm: "flex",
                  },
                  alignItems: "center",
                }}
              >
                <Avatar src={imageUrl || welcomeImage} alt="img" sx={{ width: 60, height: 60, border: `1.5px solid ${theme.palette.primary.main}` }} />
                <Box>
                  <Typography variant="h5" sx={{ whiteSpace: { xs: "wrap", sm: "nowrap" }, fontSize: "28px", fontWeight: "700", mb: "12px" }}>
                    Welcome
                  </Typography>
                  <Typography component="span" sx={{ fontSize: "18px", fontWeight: "500" }}>
                    {data?.userCreate?.name ?? name}
                  </Typography>
                </Box>
              </Box>
              <Stack spacing={2} direction="row" divider={<Divider orientation="vertical" flexItem />}></Stack>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box mb="-51px" textAlign="right">
              <Typography component="img" src={welcomeImage2} alt="img" width="250px" />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default memo(WelcomeCard);
