import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IconBoxMultiple, IconPoint } from "@tabler/icons-react";
import { Box, List, useMediaQuery } from "@mui/material";
import { uniqueId } from "lodash";
import { toggleMobileSidebar } from "../../../redux/action";
import { MenuitemsUser, MenuitemsAdmin } from "./MenuItems";
import NavItem from "./NavItem";
import NavCollapse from "./NavCollapse";
import NavGroup from "./NavGroup/NavGroup";
import { GetScrapyData } from "../../../hooks/scrapyData";
import ProjectIcon from "../../../Images/ProjectIcon";
import { OneDotSvg } from "../../../common/utils";

const obj = {
  id: uniqueId(),
  title: "Projects",

  icon: IconBoxMultiple,
  href: "/projects",
};

const projectObj = {
  id: uniqueId(),
  title: "Projects",
  icon: ProjectIcon,
  href: "/projects",
};

// const childName = [
//   {
//     id: uniqueId(),
//     title: "Overview",
//     icon: OneDotSvg,
//     href: `/projects`,
//   },
//   {
//     id: uniqueId(),
//     title: "Site Audit",
//     icon: OneDotSvg,
//     href: `/projects`,
//   },
//   {
//     id: uniqueId(),
//     title: "Desktop Performance",
//     icon: OneDotSvg,
//     href: `/projects/report/desktop`,
//   },
//   {
//     id: uniqueId(),
//     title: "Uptime Monitoring",
//     icon: OneDotSvg,
//     href: "/uptime-monitoring",
//   },
// ];

function SidebarItems() {
  const [menuJson, setMenuJson] = useState(obj);
  const location = useLocation();
  const { pathname, search } = location;
  const pathDirect = `${pathname}${search}`;
  const { scrapyData } = GetScrapyData();
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf("/"));
  const customizer = useSelector(state => state.customizer);
  const lgUp = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const hideMenu = lgUp ? customizer.isCollapse && !customizer.isSidebarHover : "";
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.reducer);

  function getData() {
    const newArr = [];
    (scrapyData || []).map(item => {
      const { srapySessionData, id, projectName, isVerified } = item || {};
      if (srapySessionData[0] && srapySessionData[0].sessionId && isVerified) {
        newArr.push({
          id: uniqueId(),
          title: projectName,
          icon: IconPoint,
          href: "/projects",
          children: [
            {
              id: uniqueId(),
              title: "Overview",
              icon: OneDotSvg,
              href: `/projects/${id}/${srapySessionData[0].sessionId}?menu=overview`,
            },

            {
              id: uniqueId(),
              title: "Issues",
              icon: OneDotSvg,
              href: `/project-detail/${srapySessionData[0]?.sessionId}?origin=${id}&menu=siteAudit`,
            },
            {
              id: uniqueId(),
              title: "Website Performance",
              icon: OneDotSvg,
              href: `/projects/report/mobile/${srapySessionData[0]?.sessionId}/${id}?menu=performance`,
            },
            {
              id: uniqueId(),
              title: "Uptime Monitoring",
              icon: OneDotSvg,
              href: `/uptime-monitoring/${id}?menu=monitoring`,
            },
          ],
        });
        return true;
      }
      return null;
    });

    projectObj.children = [...newArr];
    setMenuJson({ ...projectObj });
  }

  useEffect(() => {
    if (Array.isArray(scrapyData) && scrapyData.length > 0) getData();
  }, [scrapyData]);
  return (
    <Box sx={{ px: 3 }}>
      <List sx={{ pt: 0 }} className="sidebarNav">
        {userData && (userData.role === "SUPERADMIN" || userData.role === "COORDINATOR") ? (
          <>
            {MenuitemsAdmin.map(item => {
              // {/********SubHeader**********/}
              if (item.subheader) {
                return <NavGroup item={item} hideMenu={hideMenu} key={item.subheader} />;

                // {/********If Sub Menu**********/}
              }
              if (item.children) {
                return (
                  <Box key={item.subheader}>
                    {item?.title === "Projects" ? (
                      <NavCollapse
                        menu={menuJson}
                        pathDirect={pathDirect}
                        hideMenu={hideMenu}
                        pathWithoutLastPart={pathWithoutLastPart}
                        level={1}
                        key={menuJson.id}
                        onClick={() => dispatch(toggleMobileSidebar())}
                      />
                    ) : (
                      <NavCollapse
                        menu={item}
                        pathDirect={pathDirect}
                        hideMenu={hideMenu}
                        pathWithoutLastPart={pathWithoutLastPart}
                        level={1}
                        key={item.id}
                        onClick={() => dispatch(toggleMobileSidebar())}
                      />
                    )}
                  </Box>
                );

                // {/********If Sub No Menu**********/}
              }
              return <NavItem item={item} key={item.id} pathDirect={pathDirect} hideMenu={hideMenu} onClick={() => dispatch(toggleMobileSidebar())} />;
            })}
          </>
        ) : (
          <>
            {MenuitemsUser.map(item => {
              if (item.subheader) {
                return <NavGroup item={item} hideMenu={hideMenu} key={item.subheader} />;
              }

              if (item.children) {
                return (
                  <Box key={menuJson.id}>
                    {item?.title === "Projects" ? (
                      <NavCollapse
                        menu={menuJson}
                        pathDirect={pathDirect}
                        hideMenu={hideMenu}
                        pathWithoutLastPart={pathWithoutLastPart}
                        level={1}
                        onClick={() => dispatch(toggleMobileSidebar())}
                      />
                    ) : (
                      <NavCollapse
                        menu={item}
                        pathDirect={pathDirect}
                        hideMenu={hideMenu}
                        pathWithoutLastPart={pathWithoutLastPart}
                        level={1}
                        key={item.id}
                        onClick={() => dispatch(toggleMobileSidebar())}
                      />
                    )}
                  </Box>
                );
              }
              return <NavItem item={item} key={item.id} pathDirect={pathDirect} hideMenu={hideMenu} onClick={() => dispatch(toggleMobileSidebar())} />;
            })}
          </>
        )}
      </List>
    </Box>
  );
}
export default memo(SidebarItems);
