import gql from "graphql-tag";

export const createNote = gql`
  mutation Mutation($data: CreateNoteInput!) {
    createNote(data: $data) {
      color
      createdAt
      note
      updatedAt
      userId
    }
  }
`;

export const updateNote = gql`
  mutation Mutation($data: UpdateNoteInput!, $updateNoteId: String!) {
    updateNote(data: $data, id: $updateNoteId) {
      id
      color
      createdAt
      note
      updatedAt
      userId
    }
  }
`;
export const deleteNote = gql`
  mutation Mutation($deleteNoteId: String!) {
    deleteNote(id: $deleteNoteId) {
      note
      userId
      createdAt
      id
      updatedAt
    }
  }
`;
