/* eslint-disable */
import { useLazyQuery } from "@apollo/client";
import { Box, Button, Card, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageNotFound from "../../Images/image-not-found-icon.png";
import { usePopup } from "../../context/commonPop";
import { GET_ALL_PROJECT_WITH_LIGHT_HOUSE_AVG } from "../../gql/queries/scrapy";
import MainLoader from "../MainLoader/MainLoader";
import SearchBar from "../common/SearchBar";
import CreateProjectLink from "../createProject/CreateProjectLink";
import BackButton from "../BackButton/BackButton";
import { useSelector } from "react-redux";
const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .SearchTopBarWrapper": {
    "& .SearchBarWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "15px",
      "@media screen and (max-width:600px)": {
        flexDirection: "column",
      },
      "& button": {
        textTransform: "none",
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        borderRadius: "5px",
        boxShadow: "none",
        padding: "6px 22px",
        "@media screen and (max-width:600px)": {
          width: "100%",
        },
        "&:focus": {
          outline: "0",
        },
      },
    },
  },
  "& .BtnTopWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    "@media screen and (max-width:600px)": {
      flexDirection: "column",
    },
  },
}));

const ProjectNotFoundWrapper = styled(Box)(() => ({
  "& .NotFoundWrapper": {
    paddingTop: "15px",
    paddingBottom: "15px",
    "& h4": {
      textAlign: "center",
      fontSize: "18px",
      fontWeight: "800",
    },
  },
  "& .LoaderWrapper": {
    textAlign: "center  ",
  },
}));

const BoxMainWrapper = styled(Box)(({ theme }) => ({
  "& .DataGridWrapper": {
    "& .datagridnopage": {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: "7px 7px 7px 7px",
      "&.PaginationHide .MuiDataGrid-footerContainer": {
        display: "none",
      },
    },
    "& .MuiDataGrid-main": {
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: theme.palette.mode === "dark" ? "#FFFFFF33" : "#2A3547",
        borderRadius: "7px",
        "& .MuiDataGrid-columnSeparator": {
          color: "#8F8F8F",
          visibility: "visible",
        },
        "& .MuiDataGrid-columnHeadersInner": {
          flex: "1",
          '& div[role="row"]': {
            flex: "1",
            width: "100%",
            "& .MuiDataGrid-columnHeader": {
              outline: "none !important",
              paddingLeft: "18px",
              paddingRight: "18px",
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                "& .MuiDataGrid-iconButtonContainer": {
                  display: "none",
                },
              },
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "14px",
            color: theme.palette.mode === "dark" ? "#ffffff" : "#ffffff",
            fontWeight: 600,
          },
        },
      },
      "& .MuiDataGrid-virtualScroller": {
        overflowX: "hidden",
        "&::-webkit-scrollbar-track": {
          borderRadius: "10px",
          backgroundColor: theme.palette.text.primary + "0d",
        },
        "&::-webkit-scrollbar": { width: "8px", backgroundColor: "transparent" },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: theme.palette.primary.main,
        },
        "& .MuiDataGrid-virtualScrollerContent": {
          // height: "400px !important",
          height: "calc(100vh - 295px) !important",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            width: "100%",
            "@media screen and (max-width:600px)": {
              width: "auto",
            },
            "& .MuiDataGrid-row": {
              boxShadow: "none",
              minHeight: "70px !important",
              width: "100%",
            },
            '& div[role="row"]': {
              cursor: "pointer",
              borderBottom: `2px solid ${theme.palette.divider}`,
              width: "100%",
              "&:last-of-type": { borderWidth: "1px" },
              "& .MuiDataGrid-cell": {
                minHeight: "70px !important",
                flex: 1,
                paddingLeft: "18px",
                paddingRight: "18px",
                "&:last-of-type": {
                  display: "none",
                  "@media screen and (max-width:600px)": {
                    display: "flex",
                  },
                },
                "&.MuiDataGrid-cell--withRenderer": {
                  "&:last-of-type": {
                    display: "flex",
                  },
                },
                "&:focus-within": { outline: "none" },
                "& .MuiDataGrid-cellContent": {
                  color: theme.palette.text.primary,
                  fontWeight: 600,
                },
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-footerContainer": {
      justifyContent: "center",
      "& .MuiTablePagination-root": {
        "& .MuiToolbar-root": {
          "& .MuiTablePagination-selectLabel": {
            marginBottom: "0",
          },
          "& .MuiTablePagination-displayedRows": {
            marginBottom: "0",
          },
        },
      },
    },
  },
}));

const numberFormate = num => {
  if (Number(num) < 10) {
    return "0" + num;
  }
  return num;
};

function PerformanceDashboard() {
  const isMblBreakpoint = useMediaQuery("(max-width: 600px)");
  const [filteredRows, setFilteredRows] = useState([]);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleOpen } = usePopup();
  const customizer = useSelector(state => state.customizer);

  const [getAllProjectlightHouse] = useLazyQuery(GET_ALL_PROJECT_WITH_LIGHT_HOUSE_AVG);

  const handleMenuItemClick = (projectId, sessionId) => {
    navigate(`/performanceDashboard/${projectId}/${sessionId}/desktop?menu=performanceDashboard`);
  };

  useEffect(() => {
    const dataArr = [];
    setLoader(true);
    getAllProjectlightHouse({
      variables: {
        search,
      },
      onCompleted: ({ getProjectWithLightHouseData }) => {
        getProjectWithLightHouseData.forEach(project => {
          const [lightHouseAvg, ProjectDetail, reportData] = project || [];
          const { screapySession, imageUrl, projectName, id, isVerified } = ProjectDetail || {};
          const [sessionObj] = screapySession || [];
          const { DesktopAvg, MobileAvg } = lightHouseAvg || {};
          const { sessionId } = sessionObj || {};
          const [deskSeo, deskbestPrac, deskAccess, deskPer] = DesktopAvg || [];
          const [mobSeo, mobbestPrac, mobAccess, mobPer] = MobileAvg || [];
          const { reportStatus } = reportData || [];
          if (isVerified) {
            dataArr.push({
              Projects: {
                name: projectName,
                img: imageUrl || ImageNotFound,
              },
              id,
              sessionId,
              performance: { desktop: Math.floor(deskPer), mobile: Math.floor(mobPer) },
              Accessibility: { desktop: Math.floor(deskAccess), mobile: Math.floor(mobAccess) },
              BestPractices: { desktop: Math.floor(deskbestPrac), mobile: Math.floor(mobbestPrac) },
              DesktopSeo: { desktop: Math.floor(deskSeo), mobile: Math.floor(mobSeo) },
              reportStatus,
            });
          }
        });
        setFilteredRows(dataArr);
        setLoader(false);
      },
      onError: err => {
        console.log("err", err);
        setLoader(false);
        handleOpen(true, "Something went wrong please try again later");
      },
      fetchPolicy: "network-only",
    });
  }, [search]);

  const columns = useMemo(
    () => [
      {
        field: "Projects",
        headerName: "Projects",
        headerAlign: "left",
        align: "left",
        width: 200,
        overflow: "hidden",
        renderCell: params => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              "& img": {
                width: "40px",
                height: "auto",
              },
              "& h2 a": {
                color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
                fontWeight: 600,
                fontSize: "16px",
              },
              "&  a": {
                color: theme.palette.mode === "dark" ? "#757575" : "#757575",
                fontWeight: 500,
                fontSize: "12px",
              },
            }}
          >
            <Typography component="img" src={params.value.img} alt={params.value.name} />
            <Box>
              <Typography component="h2" title={params.value.name}>
                <Typography component="a" href="#">
                  {params.value.name}
                </Typography>
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "performance",
        headerName: "Performance",
        flex: isMblBreakpoint ? "" : 1,
        headerAlign: "left",
        align: "left",
        width: 150,
        renderCell: params => {
          return (
            <Box
              sx={{
                width: "100%",
                "& p": {
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "12px ",
                  fontWeight: 600,
                  width: "100%",
                  "& span": {
                    ml: "-10px",
                    color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: "4px",
                    backgroundColor: theme.palette.primary.main + "33",
                    fontSize: "12px",
                    padding: "6px 4px 5px",
                    lineHeight: 1,
                    fontWeight: 600,
                  },
                  "&:first-of-type ": {
                    "& span": {
                      mb: "5px",
                    },
                  },
                },
              }}
            >
              <Typography component={"p"}>
                Desktop&nbsp;:
                <Typography component={"span"}>{numberFormate(params.value?.desktop)}</Typography>
              </Typography>
              <Typography component={"p"}>
                Mobile&nbsp;:
                <Typography component={"span"}>{numberFormate(params.value?.mobile)}</Typography>
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "Accessibility",
        headerName: "Accessibility",
        flex: isMblBreakpoint ? "" : 1,
        headerAlign: "left",
        align: "left",
        width: 150,
        renderCell: params => {
          return (
            <Box
              sx={{
                width: "100%",
                "& p": {
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "12px ",
                  fontWeight: 600,
                  width: "100%",
                  "& span": {
                    ml: "-10px",
                    color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: "4px",
                    backgroundColor: theme.palette.primary.main + "33",
                    fontSize: "12px",
                    padding: "6px 4px 5px",
                    lineHeight: 1,
                    fontWeight: 600,
                  },
                  "&:first-of-type ": {
                    "& span": {
                      mb: "5px",
                    },
                  },
                },
              }}
            >
              <Typography component={"p"}>
                Desktop&nbsp;:
                <Typography component={"span"}>{numberFormate(params.value?.desktop)}</Typography>
              </Typography>
              <Typography component={"p"}>
                Mobile&nbsp;:
                <Typography component={"span"}>{numberFormate(params.value?.mobile)}</Typography>
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "BestPractices",
        headerName: "BestPractices",
        flex: isMblBreakpoint ? "" : 1,
        headerAlign: "left",
        align: "left",
        width: 150,
        renderCell: params => {
          return (
            <Box
              sx={{
                width: "100%",
                "& p": {
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "12px ",
                  fontWeight: 600,
                  width: "100%",
                  "& span": {
                    ml: "-10px",
                    color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: "4px",
                    backgroundColor: theme.palette.primary.main + "33",
                    fontSize: "12px",
                    padding: "6px 4px 5px",
                    lineHeight: 1,
                    fontWeight: 600,
                  },
                  "&:first-of-type ": {
                    "& span": {
                      mb: "5px",
                    },
                  },
                },
              }}
            >
              <Typography component={"p"}>
                Desktop&nbsp;:
                <Typography component={"span"}>{numberFormate(params.value?.desktop)}</Typography>
              </Typography>
              <Typography component={"p"}>
                Mobile&nbsp;:
                <Typography component={"span"}>{numberFormate(params.value?.mobile)}</Typography>
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "DesktopSeo",
        headerName: "SEO",
        flex: isMblBreakpoint ? "" : 1,
        headerAlign: "left",
        align: "left",
        width: 150,
        renderCell: params => {
          return (
            <Box
              sx={{
                width: "100%",
                "& p": {
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "12px ",
                  fontWeight: 600,
                  width: "100%",
                  "& span": {
                    ml: "-10px",
                    color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: "4px",
                    backgroundColor: theme.palette.primary.main + "33",
                    fontSize: "12px",
                    padding: "6px 4px 5px",
                    lineHeight: 1,
                    fontWeight: 600,
                  },
                  "&:first-of-type ": {
                    "& span": {
                      mb: "5px",
                    },
                  },
                },
              }}
            >
              <Typography component={"p"}>
                Desktop&nbsp;:
                <Typography component={"span"}>{numberFormate(params.value?.desktop)}</Typography>
              </Typography>
              <Typography component={"p"}>
                Mobile&nbsp;:
                <Typography component={"span"}>{numberFormate(params.value?.mobile)}</Typography>
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "action",
        headerName: "Status",
        flex: isMblBreakpoint ? "" : 1,
        headerAlign: "left",
        align: "left",
        width: 150,
        overflow: "hidden",
        renderCell: params => {
          const { reportStatus } = params.row;
          let text;
          let style;
          switch (reportStatus) {
            case "Completed":
              text = "Completed";
              style = { color: "#13DEB9", border: "1px solid #13DEB9", borderRadius: "50px", backgroundColor: "#13DEB933", fontSize: "12px", padding: "5px 14px 5px" };
              break;
            case "Paused":
              text = "Paused";
              style = { color: "#13DEB9", border: "1px solid #13DEB9", borderRadius: "50px", backgroundColor: "#13DEB933", fontSize: "12px", padding: "5px 14px 5px" };
              break;
            case "Progress":
              text = "Progress";
              style = { color: "#FFAE1F", border: "1px solid #FFAE1F", borderRadius: "50px", backgroundColor: "#FFAE1F33", fontSize: "12px", padding: "5px 14px 5px" };
              break;
            case "Not Found":
              text = "Create info";
              style = { color: "#539BFF", border: "1px solid #539BFF", borderRadius: "50px", backgroundColor: "#539BFF33", fontSize: "12px", padding: "5px 14px 5px" };
              break;
            default:
              text = "Create info";
              style = { color: "#539BFF", border: "1px solid #539BFF", borderRadius: "50px", backgroundColor: "#539BFF33", fontSize: "12px", padding: "5px 14px 5px" };
              break;
          }
          return (
            <Typography component="span" sx={style}>
              {text}
            </Typography>
          );
        },
      },
    ],
    [theme, isMblBreakpoint],
  );

  const debouncedHandleChange = useCallback(
    debounce(value => {
      setSearch(value);
    }, 300),
    [],
  );

  const handleChange = e => {
    debouncedHandleChange(e.target.value);
  };

  useEffect(() => {
    return () => {
      debouncedHandleChange.cancel();
    };
  }, [debouncedHandleChange]);

  // Data Table Dynamic Width Set Start

  const wrapperRef = useRef(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  useEffect(() => {
    const handleResize = entries => {
      for (let entry of entries) {
        console.log(entry.contentRect.width);
        setLoader(true);
        setWrapperWidth(entry.contentRect.width);
        setTimeout(() => {
          setLoader(false);
        }, 300);
      }
    };
    const observer = new ResizeObserver(handleResize);
    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }
    return () => {
      if (wrapperRef.current) {
        observer.unobserve(wrapperRef.current);
      }
    };
  }, []);

  // Data Table Dynamic Width Set End

  useEffect(() => {
    setLoader(!loader);
  }, [customizer.isCollapse]);

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} md={12}>
              <Box ref={wrapperRef} sx={{ width: "100%" }}>
                <Card
                  sx={{
                    padding: "16px 16px 16px 16px",
                    border: `1px solid ${theme.palette.divider}`,
                    boxShadow: "none !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "15px",
                    "@media screen and (max-width:600px)": {
                      flexDirection: "column",
                      alignItems: "stretch",
                    },
                  }}
                >
                  <Box className="BtnTopWrapper">
                    <BackButton sx={{ "& button": { marginBottom: "0 !important" } }} />
                    <CreateProjectLink />
                  </Box>
                  <Box className="SearchTopBarWrapper">
                    <Box className="SearchBarWrapper">
                      <SearchBar handleChange={handleChange} />
                      <Button>Search</Button>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
          <ProjectNotFoundWrapper>
            {loader ? (
              <MainLoader message="Loading" />
            ) : (
              <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
                <Grid item xs={12} lg={12}>
                  <BoxMainWrapper>
                    <Box sx={{ width: "100%" }}>
                      {Array.isArray(filteredRows) && filteredRows.length > 0 ? (
                        <Box className="DataGridWrapper" style={{ width: wrapperWidth }}>
                          <DataGrid
                            rows={filteredRows}
                            columns={columns}
                            pageSize={false}
                            pagination={false}
                            className="datagridnopage"
                            onCellClick={project => {
                              handleMenuItemClick(project?.id, project?.row?.sessionId);
                            }}
                            disableColumnMenu
                          />
                        </Box>
                      ) : (
                        <Box className="NotFoundWrapper">
                          <Typography component="h4">Projects Not Found</Typography>
                        </Box>
                      )}
                    </Box>
                  </BoxMainWrapper>
                </Grid>
              </Grid>
            )}
          </ProjectNotFoundWrapper>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default PerformanceDashboard;
