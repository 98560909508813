import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LaunchIcon from "@mui/icons-material/Launch";
import { useSelector } from "react-redux";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { memo, useCallback, useEffect, useState } from "react";
import { Box, Button, Container, Grid, IconButton, Typography } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router";
import { SCRAPY_DATA, getwebSiteList } from "../../gql/queries/userWebSiteList";
import { deleteWebsite } from "../../gql/queries/deleteWebsite";
import DeleteWebsitePopup from "../Modal/components/DeleteWebsitePopup";
import BackButton from "../BackButton/BackButton";
import useQuery from "../../hooks/allQuery";
import { reportCount } from "../../gql/queries/projectDetail";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {},
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
}));

const TableWrapper = {
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "& table": {
    mt: "0",
    borderRadius: "12px",
    overflow: "hidden",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
  },
};

const BoxWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,

  borderRadius: "7px",
  padding: "30px 35px 35px",
  border: `1px solid ${theme.palette.divider}`,

  "& h3": {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "20px",
    color: theme.palette.text.primary,
  },
}));

const TableTheme = styled(Box)(({ theme }) => ({
  "& td .viewDetailBtn": {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    borderRadius: "5px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.common.hoverBtn,

      color: theme.palette.common.white,
    },
  },
  "& tbody": {
    border: `1px solid ${theme.palette.common.secondory} !important`,
  },
  "& thead": {
    "& tr th.MuiTableCell-head": {
      borderBottom: `1px solid ${theme.palette.common.secondory}`,
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: "16px",
    },
  },
  "& td": {
    borderBottom: `1px solid ${theme.palette.common.secondory}`,
    color: theme.palette.text.primary,
  },
  "& .deleteicon": {
    color: theme.palette.text.secondary,
  },
  "& tr .serielNumber": {
    color: theme.palette.text.primary,
  },
}));

function ListUserWebSite() {
  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={12}>
              <BackButton />
              <BoxWrapper>
                <Typography component="h3">Website List</Typography>
                <Box sx={TableWrapper}>
                  <TableTheme>
                    <Table sx={{ minWidth: 700, marginTop: 5 }}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">S. No</StyledTableCell>
                          <StyledTableCell align="left">WebSite</StyledTableCell>
                          {/* <StyledTableCell align="left">Token</StyledTableCell> */}
                          {/* <StyledTableCell align="center">Verify</StyledTableCell> */}

                          {/* <StyledTableCell align="center">Type</StyledTableCell> */}
                          <StyledTableCell align="center">View Detail</StyledTableCell>
                          <StyledTableCell align="center">Remove</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <MemoizedTableBodyComponent />
                    </Table>
                  </TableTheme>
                </Box>
              </BoxWrapper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}
export default ListUserWebSite;

function TableBodyComponent() {
  const [payload, setPayload] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const client = useApolloClient();
  const [userWebSite] = useLazyQuery(getwebSiteList, { fetchPolicy: "cache-first" });
  const [deleteUserWebsite, { loading }] = useLazyQuery(deleteWebsite);
  const navigate = useNavigate();
  const { allQuery } = useQuery();
  const { userData } = useSelector(state => state.reducer);

  const handleDeleteClick = useCallback(
    id => {
      setOpen(true);
      setDeleteId(id);
    },
    [setOpen, setDeleteId],
  );

  const handleVerify = useCallback(
    user => {
      if (user && user?.isVerified === false) {
        navigate(`/verify/${user.id}`);
      }
    },
    [navigate],
  );

  const handleDeleteSubmit = () => {
    allQuery({
      QUERY: deleteUserWebsite,
      variables: {
        deleteWebsiteId: deleteId,
      },
      type: "delete",
      cacheQueryData: {
        cacheQuery: SCRAPY_DATA,
        variablesCache: {
          params: userData.id,
        },
      },
      onCompleteFunc: async data => {
        const { count } = data?.deleteWebsite || {};
        const cacheData = client.readQuery({
          query: reportCount,
          variables: { reportCountId: userData.id },
        });
        const newCount = Number(cacheData?.reportCount) - Number(count || 0);
        client.writeQuery({
          query: reportCount,
          variables: { reportCountId: userData.id },
          data: { reportCount: newCount },
        });
        setPayload(prev => prev.filter(website => website?.id !== deleteId));
        setOpen(false);
      },
    });
  };
  const handleWebsiteDetail = useCallback(
    user => {
      if (user && user?.isVerified === true) {
        navigate(`/websitesetting/${user.id}`);
      }
    },
    [navigate],
  );
  useEffect(() => {
    if (userData && userData?.id) {
      userWebSite({
        variables: {
          params: userData.id,
        },
        onCompleted: data => {
          setPayload(data.userWebVisitList);
        },
      })
        .then(() => {})
        .catch(() => {});
    }
    // eslint-disable-next-line
  }, [userData?.id]);
  return (
    <TableBody>
      <DeleteWebsitePopup onSubmit={() => handleDeleteSubmit()} open={open} setOpen={setOpen} loading={loading} />
      {payload?.length > 0 ? (
        payload?.map((item, ind) => (
          <MemoizedTableRowComponent ind={ind} handleVerify={handleVerify} item={item} handleWebsiteDetail={handleWebsiteDetail} handleDeleteClick={handleDeleteClick} />
        ))
      ) : (
        <StyledTableRow>
          <StyledTableCell colSpan={9} align="center">
            No content
          </StyledTableCell>
        </StyledTableRow>
      )}
    </TableBody>
  );
}

function TableRowComponent({ ind, handleVerify, item, handleWebsiteDetail, handleDeleteClick }) {
  const { website, isVerified, id } = item || {};
  const domain = website?.replace(/^https?:\/\//i, "").split("/")[0];
  return (
    <StyledTableRow key={website}>
      <StyledTableCell className="serielNumber" align="center" component="th" scope="row">
        {ind + 1}
      </StyledTableCell>
      <StyledTableCell align="left">
        <Typography component="a" href={website} target="_blank">
          {domain}
          <Typography component="span" sx={{ "& svg": { width: "15px", ml: "2px", height: "15px" } }}>
            <LaunchIcon />
          </Typography>
        </Typography>
      </StyledTableCell>

      {/* <StyledTableCell align="left">{token}</StyledTableCell> */}
      {/* <StyledTableCell align="center">{isVerified.toString()} </StyledTableCell> */}

      {/* <StyledTableCell align="center">{verificationType}</StyledTableCell> */}
      <StyledTableCell align="center">
        {!isVerified ? (
          <Button className="viewDetailBtn" onClick={() => handleVerify(item)}>
            Verify
          </Button>
        ) : (
          <Button className="viewDetailBtn" onClick={() => handleWebsiteDetail(item)}>
            View Detail
          </Button>
        )}
      </StyledTableCell>
      <StyledTableCell align="center">
        <IconButton onClick={() => handleDeleteClick(id)} className="deleteicon">
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
}

const MemoizedTableBodyComponent = memo(TableBodyComponent);
const MemoizedTableRowComponent = memo(TableRowComponent);
