import { useState } from "react";
import { Box, useMediaQuery, Typography, Stack, Avatar, Grid, Alert, IconButton, styled, useTheme, Modal, Button } from "@mui/material";

import { uniq, flatten } from "lodash";
import { IconDownload } from "@tabler/icons-react";
import CloseIcon from "@mui/icons-material/Close";

const drawerWidth = 320;

function ChatInsideSidebar({ isInSidebar, chatDetails }) {
  const [currentImage, setCurrentImage] = useState(null);
  const theme = useTheme();

  const chatMsgWrapper = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "80%" },
    height: 600,
    bgcolor: theme.palette.background.default,
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    "& .closeIcon": {
      position: "absolute",
      top: "10px",
      right: "10px",
      background: theme.palette.primary.main,
      color: "#fff",
      fontWeight: "700",
      zIndex: 9999,
      minWidth: "30px",
      padding: "5px",
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
  };
  const handleImageClick = imageUrl => {
    setCurrentImage(imageUrl);
  };
  const handleClose = () => {
    setCurrentImage(null);
  };
  const lgUp = useMediaQuery(themes => themes.breakpoints.up("lg"));
  // eslint-disable-next-line
  const totalAttachment = uniq(flatten(chatDetails?.messages?.map(item => (item?.attachment ? item?.attachment : []))))?.length;
  // eslint-disable-next-line
  const totalMedia = uniq(flatten(chatDetails?.messages?.map(item => (item?.chatType === "image" ? item?.msg : []))))?.length;
  const StyledStack = styled(Stack)(() => ({
    ".showOnHover": {
      display: "none",
    },
    "&:hover .showOnHover": {
      display: "block",
    },
  }));
  const handleDownloadClick = pdfLink => {
    // Create a link element for downloading the file
    const link = document.createElement("a");

    link.href = pdfLink;
    link.download = "document.pdf"; // Set the desired file name and extension

    // Append the link to the document and click it to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up the link element
    document.body.removeChild(link);
  };
  return (
    <Box className="InsideWrapper">
      {isInSidebar ? (
        <Box
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            border: "0",
            borderLeft: "1px",
            borderStyle: "solid",
            right: "0",
            height: "100%",
            background: themes => themes.palette.background.paper,
            boxShadow: lgUp ? null : themes => themes.shadows[9],
            position: lgUp ? "relative" : "absolute",
            borderColor: themes => themes.palette.divider,
          }}
          p={3}
        >
          <Typography variant="h6" mb={2}>
            Media
            {/* ({totalMedia}) */}
          </Typography>
          <Grid container spacing={2}>
            {chatDetails?.messages?.map(
              val =>
                !val.isDeleted &&
                val.chatType === "image" && (
                  <Grid item xs={12} lg={4} key={val.id}>
                    <Avatar onClick={() => handleImageClick(val?.msg)} src={val?.msg} alt="media" variant="rounded" sx={{ width: "72px", height: "72px" }} />
                    <Modal open={currentImage === val?.msg} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                      <Box sx={chatMsgWrapper}>
                        {val?.chatType === "image" ? <Avatar src={val?.msg} alt="media" variant="rounded" sx={{ width: "100%", height: "100%" }} /> : null}
                        <Button className="closeIcon" onClick={handleClose}>
                          <CloseIcon />
                        </Button>
                      </Box>
                    </Modal>
                  </Grid>
                ),
            )}
            <Grid item xs={12} lg={12}>
              {totalMedia === 0 ? <Alert severity="error">No Media Found!</Alert> : null}
            </Grid>
          </Grid>

          <Typography variant="p" mt={5} mb={2} sx={{ fontSize: "12px" }}>
            You can only attach image files (such as PNG or SVG), and not files in CVG, DOC, or PDF format. Please make sure to avoid any mistakes in file selection and ensure the
            correct file format
            {/* ({totalAttachment}) */}
          </Typography>
          <Box>
            {chatDetails?.messages?.map(val => (
              <Stack spacing={2.5} direction="column">
                {val?.attachment?.map(item => (
                  <StyledStack direction="row" gap={2}>
                    <Box mr="auto">
                      <Typography variant="subtitle2" fontWeight={600} mb={1}>
                        {item.file}
                      </Typography>
                      <Typography variant="body2">{item.fileSize} MB</Typography>
                    </Box>
                    <Box className="showOnHover">
                      <IconButton aria-label="delete" onClick={() => handleDownloadClick(item?.icon)}>
                        <IconDownload stroke={1.5} size="20" />
                      </IconButton>
                    </Box>
                  </StyledStack>
                ))}
              </Stack>
            ))}
            {/* {totalAttachment === 0 ? <Alert severity="error">No Attachment Found!</Alert> : null} */}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
export default ChatInsideSidebar;
