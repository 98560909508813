import gql from "graphql-tag";

export const getwebSiteList = gql`
  query Query($params: String!) {
    userWebVisitList(params: $params) {
      id
      isVerified
      token
      userId
      verificationType
      website
      projectName
      isCrawlProgress
    }
  }
`;
export const SCRAPY_DATA = gql`
  query Query($params: String!) {
    userWebVisitList(params: $params) {
      id
      projectName
      website
      isVerified
      userId
      verificationType
      isCrawlProgress
      imageUrl
      crawlingProgressReport
      srapySessionData {
        projectId
        domain
        sessionId
        isCompleted
        basicCompleted
        createdAt
        basicCompleted
        isCanceled
        isCompleted
        reason
        isFailed
        issues
        warnings
        notices
        websiteScore
        urlCrawled
        errorUrlCount
      }
      projectSiteAudit {
        endTime
        frequency
        isActive
        startDay
        startTime
        website
        userId
        updatedAt
        sessionId
        projectId
        id
        createdAt
      }
      upTimeMonitoringData
      websitePerformanceReport
    }
  }
`;

export const GoogleAuthApi = gql`
  query Query($token: String!) {
    googleAuthApi(token: $token)
  }
`;

export const findOwnerSiteById = gql`
  query Query($userWebsiteFindByIdId: String!) {
    userWebsiteFindById(id: $userWebsiteFindByIdId) {
      id
      isVerified
      token
      userId
      verificationType
      website
      projectName
      isDns
      isGoogle
      isHtmlFile
      isHtmlTag
      isCrawlProgress
      scrapySessionDataReport {
        projectId
        domain
        sessionId
        isCompleted
        basicCompleted
        createdAt
        basicCompleted
        isCompleted
        reason
        isFailed
        issues
        warnings
        notices
        websiteScore
      }
    }
  }
`;

export const googleJson = gql`
  query Query($data: SpeedPayload!) {
    GooglePageSpeedData(data: $data)
  }
`;

export const findDomain = gql`
  query Query($domain: String!) {
    performDnsLookup(domain: $domain)
  }
`;
export const HTML_FILE_VERIFY = gql`
  query Query($htmlFileVerifyId: String!) {
    htmlFileVerify(id: $htmlFileVerifyId)
  }
`;

export const HTML_TAG_VERIFY = gql`
  query Query($htmlTagVerifyId: String!) {
    htmlTagVerify(id: $htmlTagVerifyId)
  }
`;

export const GET_ALL_PROJECT = gql`
  query Query($argumentData: Model_for_userWebsiteUpdate_Pagenation!) {
    getAllProjectByUserId(argumentData: $argumentData)
  }
`;

export const GET_ALLURL_WITH_KEYWORDS_ARRAY = gql`
  query Query($sessionId: String!, $urlCount: Int!) {
    KeywordArrayWithUrlReport(sessionId: $sessionId, urlCount: $urlCount)
  }
`;

export const GET_USER_SHOPIFI_WEBSITES = gql`
  query Query {
    GetShopifyUserWebsites
  }
`;
export const GET_WORDPRESS_WEBSITES = gql`
  query Query {
    GetWorpressUserWebsites
  }
`;
export const GET_MAGENTO_WEBSITES = gql`
  query Query {
    GetMagentoUserWebsites
  }
`;
