import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { getOrgInvitations } from "../gql/queries/organizations";
import { cancelInvitation, invitationVerifications } from "../gql/mutations/org";
import { FIND_USER_BY_ID } from "../gql/queries/users";
import { getOrgsByAdmin } from "../gql/queries/org";
import { getwebSiteList } from "../gql/queries/userWebSiteList";
export const AvailNotifications = email => {
  const [data, setData] = useState([]);

  const [GetInvitations] = useLazyQuery(getOrgInvitations, {
    fetchPolicy: "cache-first", // Doesn't check cache before making a network request
  });

  const getInvitations = async email1 => {
    if (email1) {
      const res = await GetInvitations({
        variables: {
          email: email1,
        },
      });

      if (res && res.data && res.data.getInvitations) {
        setData([...res.data.getInvitations]);
      } else {
        setData([]);
      }
    }
  };

  useEffect(() => {
    getInvitations(email);
    // eslint-disable-next-line
  }, [email]);

  const refresh = () => {
    getInvitations(email);
  };

  return { data, refresh };
};

export const AcceptInvitation = ({ token, otp, organizationId }) => {
  const [AcceptInvitation1] = useMutation(invitationVerifications);
  const handleAccept = async () => {
    const res = await AcceptInvitation1({
      variables: {
        payload: {
          token,
          otp,
          organizationId,
        },
      },
    });
    return res;
  };
  return { handleAccept };
};

export const FindUserById = id => {
  const [data, setData] = useState([]);
  const [findUserById] = useLazyQuery(FIND_USER_BY_ID, {
    fetchPolicy: "cache-first",
  });
  const getUserOrganigation = async id1 => {
    if (id1) {
      const res = await findUserById({
        variables: {
          findUserByIdId: id1,
        },
      });
      if (res && res.data && res.data.findUserById.memberOrgs) {
        setData([...res.data.findUserById.memberOrgs]);
      } else {
        setData([]);
      }
    }
  };

  const refreshFindUserById = () => {
    getUserOrganigation(id);
  };
  useEffect(() => {
    if (id) {
      getUserOrganigation(id);
    }
    // eslint-disable-next-line
  }, [id]);

  return { data, refreshFindUserById };
};

export const FindProjectName = id => {
  const [projectData, setProjectData] = useState([]);
  const [FindProject] = useLazyQuery(getwebSiteList, {
    fetchPolicy: "cache-first",
  });
  const getProjectName = async id1 => {
    if (id1) {
      const res = await FindProject({
        variables: {
          params: id1,
        },
      });
      if (res && res.data && res.data.userWebVisitList) {
        setProjectData(res.data.userWebVisitList);
      } else {
        setProjectData([]);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getProjectName(id);
    }
    // eslint-disable-next-line
  }, [id]);

  return { projectData };
};

export const GetOrganigationByAdmin = id => {
  const [orgData, setOrgData] = useState([]);
  const [getOrgs] = useLazyQuery(getOrgsByAdmin, {
    fetchPolicy: "cache-first",
  });
  const getOrganigationByAdmin = async id1 => {
    if (id1) {
      const res = await getOrgs({
        variables: {
          authorId: id1,
        },
      });
      if (res && res.data && res.data.findOrgByAdmin) {
        setOrgData([...res.data.findOrgByAdmin]);
      } else {
        setOrgData([]);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getOrganigationByAdmin(id);
    }

    // eslint-disable-next-line
  }, [id]);
  const refresh = () => {
    getOrganigationByAdmin(id);
  };

  return { orgData, refresh };
};

export const CancelInvitation = () => {
  const [CancelInvitation1] = useMutation(cancelInvitation);

  const handleCancel = async ({ email, id, organizationId }) => {
    const res = await CancelInvitation1({
      variables: {
        payload: { email, id, organizationId },
      },
    });
    return res;
  };

  return { handleCancel };
};
