import { Drawer, useMediaQuery } from "@mui/material";
import ChatListingAdmin from "./ChatListing";
const drawerWidth = 320;

function ChatSidebarAdmin({ isMobileSidebarOpen, onSidebarClose }) {
  const lgUp = useMediaQuery(theme => theme.breakpoints.up("lg"));

  return (
    <Drawer
      open={isMobileSidebarOpen}
      onClose={onSidebarClose}
      variant={lgUp ? "permanent" : "temporary"}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        zIndex: lgUp ? 0 : 1,
        [`& .MuiDrawer-paper`]: { position: "relative" },
      }}
    >
      <ChatListingAdmin />
    </Drawer>
  );
}

export default ChatSidebarAdmin;
