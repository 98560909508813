import { useTheme } from "@mui/material/styles";
import { Card, CardContent, Typography, Box, Stack } from "@mui/material";

import { IconGridDots } from "@tabler/icons-react";
import { useSelector } from "react-redux";

function DashboardWidgetCard({ title, subtitle, children, statusArr = [], ClassName }) {
  const customizer = useSelector(state => state.customizer);

  const theme = useTheme();
  const borderColor = theme.palette.grey[100];

  return (
    <Card
      sx={{ padding: 0, border: !customizer.isCardShadow ? `1px solid ${borderColor}` : "none", "&.HeightFull": { height: "100%" } }}
      elevation={customizer.isCardShadow ? 9 : 0}
      variant={!customizer.isCardShadow ? "outlined" : undefined}
      className={ClassName}
    >
      <CardContent sx={{ p: "30px" }}>
        {title ? (
          <Box>
            {title ? <Typography variant="h5">{title}</Typography> : ""}

            {subtitle ? (
              <Typography variant="subtitle2" color="textSecondary">
                {subtitle}
              </Typography>
            ) : (
              ""
            )}
          </Box>
        ) : null}

        {children}

        <Stack direction="row" spacing={2} justifyContent="space-between" mt={2}>
          {statusArr.map(item => (
            <Stack direction="row" spacing={2} alignItems="center">
              <Box width={38} height={38} bgcolor="primary.light" display="flex" alignItems="center" justifyContent="center">
                <Typography color="primary.main" display="flex" alignItems="center" justifyContent="center">
                  <IconGridDots width={22} />
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2" color="textSecondary">
                  {item.dataLabel}
                </Typography>
                <Typography variant="h6" fontWeight="600">
                  {item.dataItem}
                </Typography>
              </Box>
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default DashboardWidgetCard;
