import { memo, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";

const itemsPerPage = 5;

function LinkedImage({ cellContent, index, visibleUrls }) {
  const [newVisibleUrls, setNewVisibleUrls] = useState(visibleUrls);
  const theme = useTheme();

  const loadMoreUrls = newIndex => {
    const updatedVisibleUrls = [...newVisibleUrls];

    const indexPaylod = updatedVisibleUrls[newIndex] + itemsPerPage;

    updatedVisibleUrls[newIndex] = indexPaylod;

    setNewVisibleUrls(updatedVisibleUrls);
  };

  return (
    <Box className="ListDataWrapper">
      {Array.isArray(cellContent) &&
        cellContent.slice(0, newVisibleUrls[index])?.map(cellValue => (
          <Box
            component="span"
            key={Math.random()?.toString(16).slice(2)}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              paddingBottom: "10px",
              "& .bullet": {
                marginRight: "8px",
                // paddingTop: "8px",
              },

              // "& .LinkedImagesData": {
              //   borderBottom: "1px solid #00000012",
              //   paddingTop: "8px",
              //   paddingBottom: "8px",
              // },
              // "&:first-of-type": {
              //   "& .LinkedImagesData": {
              //     paddingTop: "0px",
              //   },
              //   "& .bullet": {
              //     paddingTop: "0px",
              //   },
              // },
              "&:last-child": {
                "& .LinkedImagesData": {
                  borderBottom: "none",
                },
              },
            }}
          >
            <span className="bullet">&#8226;</span>
            {typeof cellValue !== "object" ? (
              <Box className="indexPageUrl ">{cellValue}</Box>
            ) : (
              <Box
                className="indexPageUrl LinkedImagesData"
                sx={{
                  wordBreak: "break-all",
                }}
              >
                {cellValue?.content !== null && (
                  <Typography component="a" href={cellValue?.content} target="_blank">
                    {cellValue?.content}
                  </Typography>
                )}
                &nbsp;&nbsp;
                {cellValue?.btnContent !== null && cellValue?.btnContent && (
                  <Typography
                    component="span"
                    sx={{
                      color: "#fff",
                      backgroundColor: theme.palette.primary.main,
                      padding: "1px 6px 2px",
                      borderRadius: "5px",
                      fontSize: "12px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {cellValue?.btnContent}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        ))}

      {cellContent.length > itemsPerPage && newVisibleUrls[index] < cellContent.length && (
        <Typography
          sx={{
            color: theme.palette.primary.main,
            marginTop: "10px",
            cursor: "pointer",
            fontWeight: 600,
          }}
          onClick={() => loadMoreUrls(index)}
        >
          {cellContent.length - newVisibleUrls[index]} More Links
        </Typography>
      )}
    </Box>
  );
}

export default memo(LinkedImage);
