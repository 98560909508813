/* eslint-disable */
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { Box, Button, CircularProgress, MenuItem, Modal, TextField, styled } from "@mui/material";
import CustomTextField from "../forms/theme-elements/CustomTextField";
import CustomSelect from "../forms/theme-elements/CustomSelect";
import CustomFormLabel from "../forms/theme-elements/CustomFormLabel";
import ParentCard from "../shared/ParentCard";
import { validateDescription, validateString } from "../../utils/validations";
import { createTicket } from "../../gql/mutations/ticket";
import { convertToBase64 } from "../../utils/myUtills";
import { usePopup } from "../../context/commonPop";
import { createFetch } from "../../gql/queries/ticket";
import useQuery from "../../hooks/allQuery";

const SubmitBtn = styled(Box)(({ theme }) => ({
  marginTop: "15px",
  "& button": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
}));

const numbers = [
  {
    value: "Account",
  },
  {
    value: "Profile",
  },
  {
    value: "Payment",
  },
  {
    value: "Website audit",
  },
  {
    value: "Other",
  },
];

function TicketForm({ open, setOpen }) {
  const [data, setData] = useState({ type: "Account", description: "", image: "", submitDisable: false });
  const [errorClass, setErrorClass] = useState({
    type: false,
    description: false,
    image: false,
  });
  const { userData } = useSelector(state => state.reducer);
  const { handleOpen } = usePopup();
  const [CREATE_TICKET] = useMutation(createTicket, { fetchPolicy: "network-only" });
  const { allQuery } = useQuery();

  const handleImageChange = async file => {
    if (!file) {
      return;
    }

    if (file.type.split("/")[0] !== "image" || file.size > 1e7) {
      setErrorClass({
        ...errorClass,
        image: true,
      });
      return; // Stop further processing if the file size is too large
    }
    const base64 = await convertToBase64(file);

    setData({
      ...data,
      image: base64,
    });
  };

  const handleChange = async e => {
    const { name, value, files } = e.target;

    switch (name) {
      case "image":
        await handleImageChange(files[0]);
        break;

      default:
        setData({ ...data, [name]: value });
    }
  };

  function checkError(item) {
    const type = validateString(item.type);
    const description = validateDescription(item.description);
    setErrorClass({
      ...errorClass,
      type,
      description,
    });

    return type || description || errorClass.image;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const check = checkError(data);

    if (check) return;

    setData({ ...data, submitDisable: true });
    allQuery({
      QUERY: CREATE_TICKET,
      variables: {
        createData: {
          description: data.description,
          type: data.type,
          userId: userData.id,
          image: data.image,
          name: userData.name,
        },
      },
      type: "create",
      cacheQueryData: {
        cacheQuery: createFetch,
        variablesCache: {
          userId: userData.id,
        },
      },
      onCompleteFunc: () => {
        handleOpen(true, "Ticket raise successfully");
        setData({ description: "", image: "", type: "Account", submitDisable: false });
        setOpen(false);
      },
      onErrorFunc: ({ message }) => {
        setOpen(false);
        handleOpen(true, message);
        setData({ description: "", image: "", type: "Account", submitDisable: false });
      },
    });
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        setData({ description: "", image: "", type: "Account" });
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ParentCard title="Ticket Creation Form">
        <Box component="form" onSubmit={handleSubmit}>
          <CustomFormLabel htmlFor="standard-select-number">Type</CustomFormLabel>
          <CustomSelect
            required
            fullWidth
            id="standard-select-number"
            variant="outlined"
            name="type"
            value={data.type}
            onChange={handleChange}
            sx={{
              mb: 2,
            }}
          >
            {numbers.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </CustomSelect>
          <CustomFormLabel htmlFor="image">Image</CustomFormLabel>
          <TextField
            fullWidth
            id="image"
            type="file"
            name="image"
            inputProps={{ accept: "image/*" }}
            onChange={handleChange}
            error={errorClass.image}
            helperText={errorClass.image && "please enter a valid image type and less than 10mb"}
          />

          <CustomFormLabel htmlFor="description">Description</CustomFormLabel>

          <CustomTextField
            id="description"
            name="description"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            required
            value={data.description}
            onChange={handleChange}
            error={errorClass.description}
            helperText={errorClass.description && "please enter a description atleast 15 character long!"}
          />

          <SubmitBtn>
            <Button variant="contained" type="submit" disabled={data.submitDisable}>
              {data.submitDisable ? <CircularProgress /> : "Submit"}
            </Button>
          </SubmitBtn>
        </Box>
      </ParentCard>
    </Modal>
  );
}

export default TicketForm;
