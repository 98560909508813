/* eslint-disable */
import { Alert, Box, Button, Card, Checkbox, Container, FormControlLabel, FormGroup, Grid, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useMemo, useState, useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import {
  SessionScrapyContentCount,
  SessionScrapyDetailCount,
  SessionScrapySocialTagCount,
  SessionScrapyImageTagCount,
  SessionScrapyJavascriptCount,
  SessionScrapyCssCount,
  SessionScrapyExternalPagesCount,
  SessionScrapyInternalPageCount,
  SessionScrapyLinkCount,
  SessionScrapyDuplicateCount,
  SessionScrapyRedirectCount,
} from "../../hooks/scrapyData";
// import BackButton from "../BackButton/BackButton";
import {
  prepareInternalCountArray,
  prepareIndexableContentCount,
  prepareNonIndexableContentCount,
  prepareSocialTagCount,
  prepareImageTagCount,
  prepareJsCount,
  prepareCssCount,
  preparePagesCount,
  prepareInternalPagesCount,
  prepareCanonicalIncomingIndexable,
  prepareCanonicalIncomingNonIndexable,
  prepareDuplicateCount,
  prepearRedirectcount,
} from "../../common/countData";
import ProjectDetailData from "./ProjectDetailData";
import { GET_TURNOFF_STATUS } from "../../gql/queries/scrapy";
import useQuery from "../../hooks/allQuery";
// import { setFilteredIssues } from "../../redux/action";
import InnerHeader, { formatDate } from "./InnerHeader";
// import apolloClient from "../../apollo/config";
import { acordingTabFilterArr, indexbilityTurnOffPreFunc } from "./common/utils";
import { GetSessionList } from "../../hooks/userWebsiteData";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  // paddingBottom: "80px",
}));
const CheckBoxWrapper = styled(Box)(({ theme }) => ({
  "& .formgroupmain": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    "& .inputLabel .css-1j8wkzf-MuiTypography-root": {
      color: theme.palette.text.primary,
      fontWeight: 700,
    },
  },
}));
const InnerTabWrapper = {
  minHeight: "auto",
  borderRadius: "50px",

  "& .MuiTabs-indicator": { display: "none" },
};

const TabDataWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "@media screen and (max-width:768px)": {
    flexDirection: "column",
    justifyContent: "center",
    border: `1 px solid ${theme.palette.text.primary}`,
    paddingTop: "15px",
    "& .MuiTabs-flexContainer": {
      flexWrap: "nowrap",
    },
  },
  "@media screen and (max-width:550px)": {
    "& .MuiTabs-flexContainer": {
      flexWrap: "nowrap",
      width: "230px",
    },
  },
  "& > button": {
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",

    borderRadius: "5px",
    padding: "10px 16px",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
    },
  },
  "& .tabItem": {
    color: theme.palette.text.primary,
    fontWeight: 600,
    flexDirection: "row",
    textTransform: "none",
    background: "transparent",
    transition: "0.3s all",
    borderRadius: "0px",
    padding: "10px 18px 10px",
    minHeight: "auto",
    marginRight: "0px",
    "@media screen and (max-width:768px)": {
      fontSize: "13px",
    },

    "&:first-of-type": {
      borderTopLeftRadius: "50px",
      borderBottomLeftRadius: "50px",
    },
    "&:last-child": {
      borderTopRightRadius: "50px",
      borderBottomRightRadius: "50px",
    },
    "@media screen and (max-width:550px)": {
      "&:first-of-type": {
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
      },
      "&:last-child": {
        borderTopRightRadius: "0",
        borderBottomRightRadius: "0",
      },
    },

    "& .countTag": {
      fontSize: "14px",
      marginLeft: "5px",
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
      "& .countTag": {
        color: "#ffffff ",
      },
    },
    "&:focus": { outline: "none" },
  },
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  "& h3": {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "20px",
    color: theme.palette.text.primary,
  },
  "& .alertMessage": {
    "& .MuiAlert-message": {
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
    "& .MuiAlert-icon": {
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
  },
  "& .allIssueTable": {
    "@media screen and (max-width:768px)": {
      overflowX: "auto",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ mt: "15px" }}>{children}</Box>}
    </div>
  );
}
function ProjectDetail() {
  const [GET_LINKS] = useLazyQuery(GET_TURNOFF_STATUS, { fetchPolicy: "cache-and-network", nextFetchPolicy: "cache-first" });
  const { allQuery } = useQuery();
  const [issueBtnIndx, setIssueBtnIndx] = useSearchParams({ tab: "actual" });
  const params = useParams();
  const pdfDownload = useRef();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const customizer = useSelector(state => state.customizer);
  const theme = useTheme();
  const borderColor = theme.palette.divider;
  const { sessionId } = params;
  const projectParams = searchParams.get("origin");
  const [selectedStatus, setSelectedStatus] = useState([]);
  const listData = GetSessionList(projectParams);

  const { allSession, projectName } = (Array.isArray(listData) && listData.length && listData[0]) || {};

  const { data: detailCount, ScrapyDetailCount } = SessionScrapyDetailCount(sessionId, projectParams);
  const { data: contentCount, getContent } = SessionScrapyContentCount(sessionId, projectParams);
  const { data: socialTagCount, getScrapySocialtag } = SessionScrapySocialTagCount(sessionId, projectParams);
  const { data: imageTagCount, getScrapyImageTag } = SessionScrapyImageTagCount(sessionId, projectParams);
  const { data: jsCount, QueryJsScrapy } = SessionScrapyJavascriptCount(sessionId, projectParams);
  const { data: cssCount, QueryCssScrapy } = SessionScrapyCssCount(sessionId, projectParams);
  const { data: externalPagesCount, QueryExtrnalPageScrapy } = SessionScrapyExternalPagesCount(sessionId, projectParams);
  const { data: internalPagesCount, refresh } = SessionScrapyInternalPageCount(sessionId, projectParams);
  const { data: canonicalIncoming, getScrapyLinkRefresh } = SessionScrapyLinkCount(sessionId, projectParams);
  const { data: countDuplicate, getScrapyDuplicateRefresh } = SessionScrapyDuplicateCount(sessionId, projectParams);
  const { data: RedirectCount, getScrapyRedirectRefresh } = SessionScrapyRedirectCount(sessionId, projectParams);

  const allCount = [
    ...Object.values(detailCount),
    ...Object.values(contentCount),
    ...Object.values(socialTagCount),
    ...Object.values(imageTagCount),
    ...Object.values(jsCount),
    ...Object.values(cssCount),
    ...Object.values(externalPagesCount),
    ...Object.values(internalPagesCount),
    ...Object.values(canonicalIncoming),
    ...Object.values(countDuplicate),
    ...Object.values(RedirectCount),
  ].length;

  const internalPageCount = useMemo(() => prepareInternalCountArray({ detailCount }), [detailCount]);
  const indexableCount = useMemo(() => prepareCanonicalIncomingIndexable({ indexable: canonicalIncoming?.indexable }), [canonicalIncoming]);
  const nonIndexableCount = useMemo(() => prepareCanonicalIncomingNonIndexable({ notIndexable: canonicalIncoming?.notIndexable }), [canonicalIncoming]);
  const indexableContentCount = useMemo(() => prepareIndexableContentCount({ indexable: contentCount?.indexable }), [contentCount]);
  const nonIndexableContentCount = useMemo(() => prepareNonIndexableContentCount({ nonIndexable: contentCount?.nonIndexable }), [contentCount]);

  const socialTagCountPrepaired = useMemo(() => prepareSocialTagCount(socialTagCount), [socialTagCount]);
  const imageTagCountPrepaired = useMemo(() => prepareImageTagCount(imageTagCount), [imageTagCount]);
  const jsCountPrepaired = useMemo(() => prepareJsCount(jsCount), [jsCount]);
  const cssTagCountPrepaired = useMemo(() => prepareCssCount(cssCount), [cssCount]);
  const externalPagesCountPrepaired = useMemo(() => preparePagesCount(externalPagesCount), [externalPagesCount]);
  const internalLinkPage = useMemo(() => prepareInternalPagesCount(internalPagesCount), [internalPagesCount]);
  const duplicatePageCount = useMemo(() => prepareDuplicateCount(countDuplicate), [countDuplicate]);
  const redirectPageCount = useMemo(() => prepearRedirectcount(RedirectCount), [RedirectCount]);
  const data = useMemo(() => {
    const allIssues = [
      ...internalPageCount,
      ...indexableCount,
      ...nonIndexableCount,
      ...indexableContentCount,
      ...nonIndexableContentCount,
      ...socialTagCountPrepaired,
      ...imageTagCountPrepaired,
      ...jsCountPrepaired,
      ...cssTagCountPrepaired,
      ...externalPagesCountPrepaired,
      ...internalLinkPage,
      ...duplicatePageCount,
      ...redirectPageCount,
    ];

    const turnOffFlag = allIssues.filter(item => item.turnOff === true);
    const isNotTurnOff = allIssues.filter(item => item.turnOff !== true);
    const newIssue = allIssues.filter(item => item.turnOff !== true && item.newIssue > 0 && typeof item.newIssue === "number");
    return {
      actual: isNotTurnOff.reduce((acc, element) => (element.count > 0 ? 1 + acc : acc + 0), 0),
      all: isNotTurnOff.length,
      turnOff: turnOffFlag.length,
      new: newIssue?.length,
    };
  }, [
    internalPageCount,
    indexableCount,
    nonIndexableCount,
    indexableContentCount,
    nonIndexableContentCount,
    socialTagCountPrepaired,
    imageTagCountPrepaired,
    jsCountPrepaired,
    cssTagCountPrepaired,
    externalPagesCountPrepaired,
    internalLinkPage,
    duplicatePageCount,
    redirectPageCount,
  ]);

  function turnOff(flag, updateFlag) {
    allQuery({
      QUERY: GET_LINKS,
      variables: { payload: { session_id: sessionId, flag } },
      onCompleteFunc: async () => {
        if (flag.includes("&internalPage")) {
          refresh(updateFlag);
        }
        if (flag.includes("&jsFlags")) {
          QueryJsScrapy(updateFlag);
        }
        if (flag.includes("&cssFlags")) {
          QueryCssScrapy(updateFlag);
        }
        if (updateFlag && flag.includes("duplicate")) {
          getScrapyDuplicateRefresh(updateFlag);
        }
        if (flag.includes("&Indexability")) {
          const show = await indexbilityTurnOffPreFunc(updateFlag);
          ScrapyDetailCount(show.flag, show.key);
        }
        if (flag.includes("&Links")) {
          if (flag.includes("&indexable")) {
            getScrapyLinkRefresh(updateFlag, "indexable");
          } else {
            getScrapyLinkRefresh(updateFlag, "notIndexable");
          }
        }
        if (flag.includes("&content")) {
          if (flag.includes("&indexable")) {
            getContent(updateFlag, "indexable");
          } else {
            getContent(updateFlag, "nonIndexable");
          }
        }
        if (flag.includes("&socialTag")) {
          getScrapySocialtag(updateFlag);
        }
        if (flag.includes("&imagesFlag")) {
          getScrapyImageTag(updateFlag);
        }
        if (flag.includes("&externalPagesFlag")) {
          QueryExtrnalPageScrapy(updateFlag);
        }
        if (flag.includes("&redirectFlag")) {
          getScrapyRedirectRefresh(updateFlag);
        }
      },
    });
  }
  const handleChange = (event, newValue) => {
    setIssueBtnIndx({ ...Object.fromEntries([...issueBtnIndx]), tab: newValue });
  };

  const { checkFailed, checkCanceled, checkProgress } = useMemo(() => {
    if (allSession) {
      const matchObject = allSession.find(item => item.sessionId === sessionId);
      const { isFailed, isCanceled, isCompleted } = matchObject || {};
      return {
        checkFailed: isFailed || false,
        checkCanceled: isCanceled || false,
        checkProgress: (!isFailed && !isCanceled && !isCompleted) || false,
      };
    }
    return { checkFailed: false, checkCanceled: false, checkProgress: false };
  }, [allSession, sessionId]);

  const checkIsBasic = useMemo(() => {
    if (allSession) {
      const matchObject = allSession.find(item => item.sessionId === sessionId);
      return (matchObject?.basicCompleted && !matchObject.isCompleted) || false;
    }
    return false;
  }, [allSession, sessionId]);
  const [reportTime, setReportTime] = useState("");
  const handlePdf = useReactToPrint({
    content: () => pdfDownload.current,
    documentTitle: `${projectName}_${reportTime}_AllIssues`,
  });
  const {
    dataArrAll: duplicatePageCountAll,
    dataArrTurnOff: duplicatePageCountTurnOff,
    dataArrActual: duplicatePageCountActual,
    dataArrNew: duplicatePageCountNew,
  } = useMemo(() => {
    return acordingTabFilterArr(duplicatePageCount, selectedStatus);
  }, [duplicatePageCount, selectedStatus]);

  const {
    dataArrAll: indexbilityAll,
    dataArrTurnOff: indexbilityTurnOff,
    dataArrActual: indexbilityActual,
    dataArrNew: indexbilityNew,
  } = useMemo(() => {
    return acordingTabFilterArr(internalPageCount, selectedStatus);
  }, [internalPageCount, selectedStatus]);

  const {
    dataArrAll: indexableLinksAll,
    dataArrTurnOff: indexableLinksTurnOff,
    dataArrActual: indexableLinksActual,
    dataArrNew: indexableLinksNew,
  } = useMemo(() => {
    return acordingTabFilterArr(indexableCount, selectedStatus);
  }, [indexableCount, selectedStatus]);
  const {
    dataArrAll: nonIndexableCountAll,
    dataArrTurnOff: nonIndexableCountTurnOff,
    dataArrActual: nonIndexableCountActual,
    dataArrNew: nonIndexableCountNew,
  } = useMemo(() => {
    return acordingTabFilterArr(nonIndexableCount, selectedStatus);
  }, [nonIndexableCount, selectedStatus]);
  const {
    dataArrAll: indexableContentAll,
    dataArrTurnOff: indexableContentTurnOff,
    dataArrActual: indexableContentActual,
    dataArrNew: indexableContentNew,
  } = useMemo(() => {
    return acordingTabFilterArr(indexableContentCount, selectedStatus);
  }, [indexableContentCount, selectedStatus]);
  const {
    dataArrAll: nonIndexableContentCountAll,
    dataArrTurnOff: nonIndexableContentCountTurnOff,
    dataArrActual: nonIndexableContentCountActual,
    dataArrNew: nonIndexableContentCountNew,
  } = useMemo(() => {
    return acordingTabFilterArr(nonIndexableContentCount, selectedStatus);
  }, [nonIndexableContentCount, selectedStatus]);

  const {
    dataArrAll: socialTagCountAll,
    dataArrTurnOff: socialTagCountTurnOff,
    dataArrActual: socialTagCountActual,
    dataArrNew: socialTagCountNew,
  } = useMemo(() => {
    return acordingTabFilterArr(socialTagCountPrepaired, selectedStatus);
  }, [socialTagCountPrepaired, selectedStatus]);
  const {
    dataArrAll: imageTagCounttAll,
    dataArrTurnOff: imageTagCountTurnOff,
    dataArrActual: imageTagCountActual,
    dataArrNew: imageTagCountNew,
  } = useMemo(() => {
    return acordingTabFilterArr(imageTagCountPrepaired, selectedStatus);
  }, [imageTagCountPrepaired, selectedStatus]);

  const {
    dataArrAll: jsCountCountAll,
    dataArrTurnOff: jsCountCountTurnOff,
    dataArrActual: jsCountCountActual,
    dataArrNew: jsCountCountNew,
  } = useMemo(() => {
    return acordingTabFilterArr(jsCountPrepaired, selectedStatus);
  }, [jsCountPrepaired, selectedStatus]);

  const {
    dataArrAll: cssTagCountAll,
    dataArrTurnOff: cssTagCountTurnOff,
    dataArrActual: cssTagCountActual,
    dataArrNew: cssTagCountNew,
  } = useMemo(() => {
    return acordingTabFilterArr(cssTagCountPrepaired, selectedStatus);
  }, [cssTagCountPrepaired, selectedStatus]);

  const {
    dataArrAll: externalPagesCountAll,
    dataArrTurnOff: externalPagesCountTurnOff,
    dataArrActual: externalPagesCountActual,
    dataArrNew: externalPagesCountNew,
  } = useMemo(() => {
    return acordingTabFilterArr(externalPagesCountPrepaired, selectedStatus);
  }, [externalPagesCountPrepaired, selectedStatus]);
  const {
    dataArrAll: internalLinkPageAll,
    dataArrTurnOff: internalLinkPageTurnOff,
    dataArrActual: internalLinkPageActual,
    dataArrNew: internalLinkPageNew,
  } = useMemo(() => {
    return acordingTabFilterArr(internalLinkPage, selectedStatus);
  }, [internalLinkPage, selectedStatus]);
  const {
    dataArrAll: redirectPageCountAll,
    dataArrTurnOff: redirectPageCountTurnOff,
    dataArrActual: redirectPageCountActual,
    dataArrNew: redirectPageCountNew,
  } = useMemo(() => {
    return acordingTabFilterArr(redirectPageCount, selectedStatus);
  }, [redirectPageCount, selectedStatus]);
  useEffect(() => {
    // Update the URL query parameter based on selectedStatus
    if (selectedStatus.length > 0) {
      // If there are selected statuses, set the 'filter' query parameter
      searchParams.set("filter", selectedStatus.join(","));
    } else {
      // If no selected statuses, remove the 'filter' query parameter
      searchParams.delete("filter");
    }

    // Update the URL by assigning the search parameters back to the URL
    updateURL(searchParams?.toString(), location.pathname);
  }, [selectedStatus, searchParams, location.pathname]);

  // Function to update the URL without using history
  const updateURL = (search, pathname) => {
    const newURL = `${pathname}?${search}`;
    window.history.replaceState(null, "", newURL);
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const filterValue = urlSearchParams.get("filter");
    if (filterValue) {
      // Parse the 'filter' value from the URL and update the state
      const filterArray = filterValue.split(",");
      setSelectedStatus(filterArray);
    }
  }, [location.search]);
  const handleStatusChange = event => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedStatus(prevSelectedStatus => [...prevSelectedStatus, value]);
    } else {
      setSelectedStatus(prevSelectedStatus => prevSelectedStatus.filter(status => status !== value));
    }
  };

  useEffect(() => {
    if (!issueBtnIndx.get("tab")) {
      setIssueBtnIndx({ ...Object.fromEntries([...issueBtnIndx]), tab: "actual" });
    }
  }, [issueBtnIndx]);

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={12}>
              <InnerHeader listData={listData} setReportTime={setReportTime} />
              <BoxWrapper>
                <Card
                  sx={{ padding: "30px 45px 24px 40px", border: `1px solid ${theme.palette.divider}` }}
                  elevation={customizer.isCardShadow ? 9 : 0}
                  variant={!customizer.isCardShadow ? "outlined" : undefined}
                >
                  <Typography component="h3">All issues </Typography>

                  <Box>
                    {checkIsBasic ? (
                      <Box>
                        <Box sx={{ mb: "15px" }}>
                          <Alert className="alertMessage" severity="error">
                            This is a basic report if you want complete report so wait...
                          </Alert>
                        </Box>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>

                  {checkFailed && (
                    <Box sx={{ mb: "15px" }}>
                      <Alert className="alertMessage" severity="error">
                        {" "}
                        Report has failed...
                      </Alert>
                    </Box>
                  )}

                  {checkCanceled && (
                    <Box sx={{ mb: "15px" }}>
                      <Alert className="alertMessage" severity="error">
                        {" "}
                        Report has been canceled...
                      </Alert>
                    </Box>
                  )}

                  {checkProgress && (
                    <Box sx={{ mb: "15px" }}>
                      <Alert className="alertMessage" severity="error">
                        {" "}
                        Report in progress...
                      </Alert>
                    </Box>
                  )}

                  <TabDataWrapper>
                    <Box>
                      <Tabs
                        value={issueBtnIndx.get("tab")}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        allowScrollButtonsMobile
                        variant="scrollable"
                        sx={{ ...InnerTabWrapper, border: customizer.isCardShadow ? `1px solid ${borderColor}` : "none" }}
                      >
                        <Tab
                          className="tabItem"
                          value="actual"
                          label={
                            <>
                              Actual
                              <Typography component="span" className="countTag">
                                {data?.actual}
                              </Typography>
                            </>
                          }
                        />
                        <Tab
                          className="tabItem"
                          value="new"
                          label={
                            <>
                              New
                              <Typography component="span" className="countTag">
                                {data?.new}
                              </Typography>
                            </>
                          }
                        />{" "}
                        <Tab
                          className="tabItem"
                          value="all"
                          label={
                            <>
                              All tracked
                              <Typography component="span" className="countTag">
                                {data?.all}
                              </Typography>
                            </>
                          }
                        />{" "}
                        <Tab
                          className="tabItem"
                          value="turnedoff"
                          label={
                            <>
                              Turned off
                              <Typography component="span" className="countTag">
                                {data?.turnOff}
                              </Typography>
                            </>
                          }
                        />
                      </Tabs>
                    </Box>
                    <CheckBoxWrapper>
                      <FormGroup className="formgroupmain">
                        <FormControlLabel
                          className="inputLabel"
                          control={<Checkbox />}
                          onChange={handleStatusChange}
                          value="warning"
                          checked={selectedStatus.includes("warning")}
                          label="Warning"
                        />
                        <FormControlLabel
                          className="inputLabel"
                          control={<Checkbox />}
                          value="error"
                          checked={selectedStatus.includes("error")}
                          onChange={handleStatusChange}
                          label="Error"
                        />
                        <FormControlLabel
                          className="inputLabel"
                          control={<Checkbox />}
                          value="info"
                          checked={selectedStatus.includes("info")}
                          onChange={handleStatusChange}
                          label="Info"
                        />
                      </FormGroup>
                    </CheckBoxWrapper>

                    <Button onClick={handlePdf}>Export All Issues</Button>
                  </TabDataWrapper>
                  <TabPanel value={issueBtnIndx.get("tab")} index="actual" className="allIssueTable">
                    {issueBtnIndx.get("tab") === "actual" && (
                      <Box ref={pdfDownload}>
                        <ProjectDetailData
                          issueBtnIndx="actual"
                          duplicatePageCount={duplicatePageCountActual}
                          internalPageCount={indexbilityActual}
                          indexableCount={indexableLinksActual}
                          nonIndexableCount={nonIndexableCountActual}
                          indexableContentCount={indexableContentActual}
                          nonIndexableContentCount={nonIndexableContentCountActual}
                          socialTagCountPrepaired={socialTagCountActual}
                          imageTagCountPrepaired={imageTagCountActual}
                          jsCountPrepaired={jsCountCountActual}
                          cssTagCountPrepaired={cssTagCountActual}
                          externalPagesCountPrepaired={externalPagesCountActual}
                          internalLinkPage={internalLinkPageActual}
                          redirectCount={redirectPageCountActual}
                          refresh={turnOff}
                          totalCount={allCount}
                        />
                      </Box>
                    )}
                  </TabPanel>
                  <TabPanel value={issueBtnIndx.get("tab")} index="new" className="allIssueTable">
                    {issueBtnIndx.get("tab") === "new" && (
                      <Box ref={pdfDownload}>
                        <ProjectDetailData
                          issueBtnIndx="new"
                          duplicatePageCount={duplicatePageCountNew}
                          internalPageCount={indexbilityNew}
                          indexableCount={indexableLinksNew}
                          nonIndexableCount={nonIndexableCountNew}
                          indexableContentCount={indexableContentNew}
                          nonIndexableContentCount={nonIndexableContentCountNew}
                          socialTagCountPrepaired={socialTagCountNew}
                          imageTagCountPrepaired={imageTagCountNew}
                          jsCountPrepaired={jsCountCountNew}
                          cssTagCountPrepaired={cssTagCountNew}
                          externalPagesCountPrepaired={externalPagesCountNew}
                          internalLinkPage={internalLinkPageNew}
                          redirectCount={redirectPageCountNew}
                          refresh={turnOff}
                          totalCount={allCount}
                        />
                      </Box>
                    )}
                  </TabPanel>
                  <TabPanel value={issueBtnIndx.get("tab")} index="all" className="allIssueTable">
                    {issueBtnIndx.get("tab") === "all" && (
                      <Box ref={pdfDownload}>
                        <ProjectDetailData
                          issueBtnIndx="all"
                          duplicatePageCount={duplicatePageCountAll}
                          internalPageCount={indexbilityAll}
                          indexableCount={indexableLinksAll}
                          nonIndexableCount={nonIndexableCountAll}
                          indexableContentCount={indexableContentAll}
                          nonIndexableContentCount={nonIndexableContentCountAll}
                          socialTagCountPrepaired={socialTagCountAll}
                          imageTagCountPrepaired={imageTagCounttAll}
                          jsCountPrepaired={jsCountCountAll}
                          cssTagCountPrepaired={cssTagCountAll}
                          externalPagesCountPrepaired={externalPagesCountAll}
                          internalLinkPage={internalLinkPageAll}
                          redirectCount={redirectPageCountAll}
                          refresh={turnOff}
                          totalCount={allCount}
                        />
                      </Box>
                    )}
                  </TabPanel>
                  <TabPanel value={issueBtnIndx.get("tab")} index="turnedoff" className="allIssueTable">
                    {issueBtnIndx.get("tab") === "turnedoff" && (
                      <Box ref={pdfDownload}>
                        <ProjectDetailData
                          issueBtnIndx="turnedoff"
                          duplicatePageCount={duplicatePageCountTurnOff}
                          internalPageCount={indexbilityTurnOff}
                          indexableCount={indexableLinksTurnOff}
                          nonIndexableCount={nonIndexableCountTurnOff}
                          indexableContentCount={indexableContentTurnOff}
                          nonIndexableContentCount={nonIndexableContentCountTurnOff}
                          socialTagCountPrepaired={socialTagCountTurnOff}
                          imageTagCountPrepaired={imageTagCountTurnOff}
                          jsCountPrepaired={jsCountCountTurnOff}
                          cssTagCountPrepaired={cssTagCountTurnOff}
                          externalPagesCountPrepaired={externalPagesCountTurnOff}
                          internalLinkPage={internalLinkPageTurnOff}
                          redirectCount={redirectPageCountTurnOff}
                          refresh={turnOff}
                          totalCount={allCount}
                        />
                      </Box>
                    )}
                  </TabPanel>
                </Card>
              </BoxWrapper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default ProjectDetail;
