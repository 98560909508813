import { lazy } from "react";
import { useTheme } from "@mui/material/styles";
// import { Box, Typography } from "@mui/material";
import DashboardCard from "../shared/DashboardCard";

const Chart = lazy(() => import("react-apexcharts"), { ssr: false });

function ResponseTimeChart({ data }) {
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const secondary = theme.palette.secondary.main;
  //   const secondarylight = theme.palette.secondary.light;
  const optionslinechart = {
    chart: {
      height: 308,
      type: "line",
      fontFamily: "'Plus Jakarta Sans', sans-serif",
      foreColor: "#adb0bb",
      zoom: {
        type: "x",
        enabled: true,
      },
      toolbar: {
        show: false,
      },
      shadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
    },
    xaxis: {
      categories: data.responseMoments,
      title: {
        text: "Time",
      },
    },
    grid: {
      show: false,
    },
    colors: [primary, secondary],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "straight",
      width: "2",
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    tooltip: {
      theme: "dark",
    },
  };
  const serieslinechart = [
    // {
    //   name: "High - 2013",
    //   data: [28, 29, 33, 36, 32, 32, 33],
    // },
    {
      name: "Response Time in milliseconds",
      data: data.responseTimeToday,
    },
  ];
  return (
    <DashboardCard title="Page Response Time" subtitle="Overview Of Your Website's Response Time" ClassName="HeightFull">
      <Chart options={optionslinechart} series={serieslinechart || []} type="line" height="308px" width="100%" />
    </DashboardCard>
  );
}

export default ResponseTimeChart;
