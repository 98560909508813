import { Box, CardContent, Grid, Typography } from "@mui/material";
import { memo } from "react";
import { Link } from "react-router-dom";

function ChildCommponent({ topcard }) {
  return (
    <Box bgcolor={topcard.bgcolor} textAlign="center">
      <CardContent sx={{ p: "24px 48px 24px" }}>
        <Box
          sx={{
            width: "30px",
            height: "30px",
            backgroundColor: "transparent",
            borderRadius: "50%",
            border: "10px solid",
            ml: "auto",
            mr: "auto",
          }}
          borderColor={topcard.textcolor + " !important"}
        ></Box>
        <Typography color={topcard.textcolor} mt={1} variant="h4" fontWeight={600}>
          {topcard.title}
        </Typography>
        {topcard.title === "Current Status" ? (
          <Typography color={topcard.textcolor} variant="subtitle1" fontWeight={600} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mt: "12px" }}>
            {topcard.data}
          </Typography>
        ) : (
          <Typography color={topcard.textcolor} variant="subtitle1" fontWeight={600} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mt: "12px" }}>
            Duration{" "}
            <Typography color={topcard.textcolor} variant="subtitle1" fontWeight={600}>
              {Math.round(topcard.data / 60)} hrs
            </Typography>
          </Typography>
        )}
      </CardContent>
    </Box>
  );
}

function StatusCard({ data, projectid }) {
  const { currentStatus, uptime, downtime, totalTime } = data;
  const topCards = [
    {
      title: "Current Status",
      bgcolor: "#5D87FF26",
      textcolor: "#5D87FF",
      Link: "#",
      data: currentStatus,
    },
    {
      title: "Uptime",
      bgcolor: "#2fd42f26",
      textcolor: "#2fd42f",
      Link: "#",
      data: uptime,
    },
    {
      title: "Down Time",
      bgcolor: "#FA896B26",
      textcolor: "#FA896B",
      Link: `/downtime-monitoring/${projectid}`,
      data: downtime,
    },
    {
      title: "Total Time",
      bgcolor: "#49BEFF26",
      textcolor: "#49BEFF",
      Link: "#",
      data: totalTime,
    },
  ];

  return (
    <Grid container spacing={3} mt={0}>
      {topCards.map(topcard => (
        <Grid item xs={12} sm={6} lg={3} key={Math.random().toString(36).substr(2, 9)}>
          {topcard.title === "Down Time" ? (
            <Link to={topcard?.Link}>
              <ChildCommponent topcard={topcard} />
            </Link>
          ) : (
            <ChildCommponent topcard={topcard} />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

export default memo(StatusCard);
