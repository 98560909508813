import { Box, Card, Container, Grid, Typography, styled, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useSelector } from "react-redux";
import { lazy, useState } from "react";
import { useAllTicket } from "../../hooks/scrapyData";
import { DatePickerRangeComponent } from "./DatePickerRange";

const Chart = lazy(() => import("react-apexcharts"), { ssr: false });
const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .MainHeadingWrapper": {
    "& .MainHeading": { fontSize: "18px", fontWeight: "600" },
  },
}));

const BoxStyled = styled(Box)(() => ({
  padding: "30px",
  transition: "0.1s ease-in",
  cursor: "pointer",
  color: "inherit",
  textAlign: "center",
  borderRadius: "4px",
  "&:hover": {
    transform: "scale(1.03)",
  },
}));

const BoxMainWrapper = styled(Box)(({ theme }) => ({
  "& .datagridnopage": {
    // borderColor: "rgba(251, 252, 253, 1)",
    border: "2px solid" + theme.palette.divider,

    // "& .datagridnopage .MuiDataGrid-footerContainer": {
    //   display: "none",
    // },
    "& .MuiDataGrid-main": {
      "& .MuiDataGrid-columnHeaders": {
        borderColor: theme.palette.divider,
        borderWidth: "2px",
        "& .MuiDataGrid-columnSeparator": {
          color: theme.palette.text.primary + "26",
          visibility: "visible",
          // opacity: "0.6 !important",
        },
        "& .MuiDataGrid-columnHeadersInner": {
          width: "100%",
          '& div[role="row"]': {
            width: "100%",
            "& .MuiDataGrid-columnHeader": {
              flex: 1,
              width: "auto !important",
              maxWidth: "unset !important",
              outline: "none !important",
              "&:first-child": { maxWidth: "100px !important", "& .MuiDataGrid-columnHeaderTitleContainer": { justifyContent: "left" } },
              "& .MuiDataGrid-columnHeaderTitleContainer": { justifyContent: "start" },
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "14px",
            color: theme.palette.text.primary,
            fontWeight: 600,
          },
        },
      },
      "& .MuiDataGrid-virtualScroller": {
        // minHeight: "350px",
        "&::-webkit-scrollbar-track": {
          // WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
          borderRadius: "10px",
          backgroundColor: theme.palette.text.primary + "0d",
        },
        "&::-webkit-scrollbar": { width: "6px", backgroundColor: "transparent" },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: theme.palette.primary.main,
        },
        "& .MuiDataGrid-virtualScrollerContent": {
          height: "470px !important",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              boxShadow: "inset 0px 1px " + theme.palette.divider,
              minHeight: "70px !important",
              border: "none",
            },
            width: "100%",
            '& div[role="row"]': {
              width: "100%",
              cursor: "pointer",
              "& .MuiDataGrid-cell": {
                minHeight: "70px !important",
                flex: 1,
                width: "auto !important",
                maxWidth: "unset !important",
                justifyContent: "start",
                borderColor: theme.palette.divider,
                "&:focus-within": { outline: "none" },
                "&:first-child": { maxWidth: "100px !important", justifyContent: "left" },
                "&:last-child": {
                  display: "none",
                },
              },
              "& .MuiDataGrid-cellContent": {
                color: theme.palette.text.primary,
                fontWeight: 600,
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-footerContainer": {
      borderColor: theme.palette.divider,
      "& .MuiTablePagination-toolbar": {
        "& > p": { marginBottom: "0" },
      },
    },
  },
  "& h3": {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "20px",
    color: theme.palette.text.primary,
  },
}));
function TicketBoard() {
  const [isFetchDate, setIsFetchDate] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const { Data, ticketCounts, getAllTickes } = useAllTicket(startDate, endDate);
  const theme = useTheme();
  const customizer = useSelector(state => state.customizer);
  const borderColor = theme.palette.divider;

  const handleDateRangeChange = newValue => {
    const [newStart, newEnd] = newValue;
    setStartDate(newStart ? new Date(newStart) : null);
    setEndDate(newEnd ? new Date(newEnd) : null);
    setIsFetchDate(false);
  };

  // const handleFetchData = async () => {
  //   await fetchData();
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [startDate, endDate]);
  const handleFatch = () => {
    try {
      getAllTickes();
      setIsFetchDate(true);
    } catch (err) {
      console.log(err);
    }
  };
  function checkStatus(dataCheck) {
    const status = dataCheck.Status;
    return status;
  }
  // const ticketCounts = Data.reduce(
  //   (acc, ticket) => {
  //     const status = checkStatus(ticket);
  //     acc.total++;
  //     acc[status] = (acc[status] || 0) + 1;
  //     return acc;
  //   },
  //   { total: 0, Open: 0, Closed: 0, Pending: 0 },
  // );
  const filterDataFunc = () =>
    isFetchDate && startDate && endDate
      ? Data.filter(ticket => {
          const ticketDate = moment(ticket.createdAt, ["DD/MM/YYYY", "DD/MM/YYYY, HH:mm"]);
          return ticketDate.isSameOrAfter(startDate, "day") && ticketDate.isSameOrBefore(endDate, "day");
        })
      : Data;

  const renderChart = () => {
    const filteredData = filterDataFunc();

    const groupedData = filteredData.reduce((acc, curr) => {
      const date = moment(curr.createdAt, ["DD/MM/YYYY", "DD/MM/YYYY, HH:mm"]).format("DD/MM/YYYY");
      const status = checkStatus(curr);
      acc[date] = acc[date] || { Open: 0, Closed: 0, Pending: 0 };
      acc[date][status] = (acc[date][status] || 0) + 1;
      return acc;
    }, {});

    let categories = Object.keys(groupedData).sort((a, b) => new Date(a) - new Date(b));

    if (!startDate || !endDate) {
      categories = categories.slice(-30);
    }

    const seriesData = categories.map(date => {
      const statusCounts = groupedData[date] || { Open: 0, Closed: 0, Pending: 0 };
      return [statusCounts.Open, statusCounts.Closed, statusCounts.Pending];
    });

    return (
      <Chart
        options={{
          xaxis: { categories },
          // yaxis: {
          //   max: 21,
          //   min: 0,
          //   tickAmount: 3,
          // },
          dataLabels: { enabled: false },
          chart: { toolbar: { show: false } },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          colors: ["#77B6EA", "#FF4560", "#FFC0CB"],
        }}
        series={[
          { name: "Open", data: seriesData.map(data => data[0]) },
          { name: "Closed", data: seriesData.map(data => data[1]) },
          { name: "Pending", data: seriesData.map(data => data[2]) },
        ]}
        type="bar"
        height={360}
      />
    );
  };
  const columns = [
    { field: "id", headerName: "Index", width: 80 },
    { field: "ticketId", headerName: "Ticket Id" },
    {
      field: "name",
      headerName: "Ticket",
      renderCell: params => (
        <Box>
          <Box>{params.row.type}</Box>
          <Box>{params.row.description}</Box>
        </Box>
      ),
    },
    {
      field: "image",
      headerName: "Image",
      renderCell: params => <Typography component="img" src={params.row.image} alt={params.row.name} sx={{ width: "95px", height: "auto" }} />,
    },
    { field: "Status", headerName: "Status" },
    { field: "createdAt", headerName: "Date" },
  ];

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} lg={12}>
              <Card
                sx={{ padding: "30px 45px 24px 40px", border: customizer.isCardShadow ? `1px solid ${borderColor}` : "none", height: "100%" }}
                elevation={customizer.isCardShadow ? 9 : 0}
                variant={!customizer.isCardShadow ? "outlined" : undefined}
              >
                <Grid container spacing={2} mt={0} sx={{ justifyContent: "center", alignItems: "center" }}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box className="MainHeadingWrapper">
                      <Typography component="h5" className="MainHeading">
                        Ticket Details
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={8}>
                    <DatePickerRangeComponent handleFetchData={handleFatch} handleDateRangeChange={handleDateRangeChange} startDate={startDate} endDate={endDate} />
                  </Grid>
                </Grid>
                {/* {Data && Data.length > 0 && ( */}

                {Data?.length > 0 && (
                  <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
                    <Grid item xs={12} md={6} lg={3}>
                      <BoxStyled sx={{ backgroundColor: "primary.light", color: "primary.main" }}>
                        <Typography variant="h3">{ticketCounts.total}</Typography>
                        <Typography variant="h6">Total Tickets</Typography>
                      </BoxStyled>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <BoxStyled sx={{ backgroundColor: "success.light", color: "success.main" }}>
                        <Typography variant="h3">{ticketCounts.Pending}</Typography>
                        <Typography variant="h6">Pending Tickets</Typography>
                      </BoxStyled>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <BoxStyled sx={{ backgroundColor: "warning.light", color: "warning.main" }}>
                        <Typography variant="h3">{ticketCounts.Open}</Typography>
                        <Typography variant="h6">Open Tickets</Typography>
                      </BoxStyled>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <BoxStyled sx={{ backgroundColor: "error.light", color: "error.main" }}>
                        <Typography variant="h3">{ticketCounts.Closed}</Typography>
                        <Typography variant="h6">Closed Tickets</Typography>
                      </BoxStyled>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="HeaderWrapper">{renderChart()}</Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <BoxMainWrapper>
                        <DataGrid rows={filterDataFunc()} columns={columns} className="datagridnopage" pageSize={5} disableSelectionOnClick />
                      </BoxMainWrapper>
                    </Grid>
                  </Grid>
                )}

                {/* )} */}
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default TicketBoard;
