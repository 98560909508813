import { memo } from "react";
import { Box, Typography, Card, CardContent, Grid, Divider, Stack } from "@mui/material";
import welcomeImage2 from "../../Images/backgrounds/welcome-bg.svg";
function WelcomeCard() {
  return (
    <Card elevation={0} sx={{ backgroundColor: theme => theme.palette.primary.light, py: 0, height: "100%" }}>
      <CardContent sx={{ py: 4, px: 2 }}>
        <Grid container justifyContent="space-between">
          <Grid item sm={6} display="flex" alignItems="center">
            <Box>
              <Box
                gap="16px"
                mb={2}
                sx={{
                  display: {
                    xs: "block",
                    sm: "flex",
                  },
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    whiteSpace: { xs: "wrap", sm: "nowrap" },
                    fontSize: "24px",
                    paddingLeft: "28px",
                  }}
                >
                  Analytic Dashboard
                </Typography>
              </Box>
              <Stack spacing={2} direction="row" divider={<Divider orientation="vertical" flexItem />}></Stack>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box mb="-51px">
              <Typography component="img" src={welcomeImage2} alt="img" width="340px" />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default memo(WelcomeCard);
