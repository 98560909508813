/* eslint-disable */ import { Grid, Box, Typography, styled } from "@mui/material";
// import loginImage from "../../Images/backgrounds/login-bg.svg";
import loginImage from "../../Images/LoginBg.png";
import Logo from "../NavBar/sidebar/logo/logo";
import AuthResetPassword from "../authForms/AuthResetPassword";

const AuthResetPasswordBox = styled(Box)(({ theme }) => ({
  width: "100%",
  "& input": {
    borderRadius: "7px",
    outline: "none",
    border: "1px solid #DFE5EF",

    letterSpacing: "inherit",

    background: "none",
    margin: "0",
    WebkitTapHighlightColor: "transparent",
    display: "block",
    minWidth: "0",
    width: "100%",
    WebkitAnimationName: "mui-auto-fill-cancel",

    padding: "12px 14px",
  },
  "& .resetBtn": {
    marginTop: "24px",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  "& .resetForm": {
    mb: "0",
  },
}));
const ResetPassword = () => (
  <Grid container justifyContent="center" spacing={0} sx={{ overflowX: "hidden" }}>
    <Grid
      item
      xs={12}
      sm={12}
      lg={8}
      xl={9}
      sx={{
        position: "relative",
        "&:before": {
          content: '""',
          background: "radial-gradient(#d2f1df, #d3d7fa, #bad8f4)",
          backgroundSize: "400% 400%",
          animation: "gradient 15s ease infinite",
          position: "absolute",
          height: "100%",
          width: "100%",
          opacity: "0.3",
        },
      }}
    >
      <Box position="relative">
        <Box px={3}>
          <Logo />
        </Box>
        <Box
          alignItems="center"
          justifyContent="center"
          height={"calc(100vh - 75px)"}
          sx={{
            display: {
              xs: "none",
              lg: "flex",
            },
          }}
        >
          <img
            src={loginImage}
            alt="bg"
            style={{
              width: "100%",
              maxWidth: "500px",
            }}
          />
        </Box>
      </Box>
    </Grid>
    <Grid item xs={12} sm={12} lg={4} xl={3} display="flex" justifyContent="center" alignItems="center">
      <AuthResetPasswordBox p={4}>
        <Typography variant="h4" fontWeight="700">
          Reset your password?
        </Typography>

        <AuthResetPassword />
      </AuthResetPasswordBox>
    </Grid>
  </Grid>
);

ResetPassword.layout = "Blank";
export default ResetPassword;
