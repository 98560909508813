import gql from "graphql-tag";

export const updateImageProfile = gql`
  mutation Mutation($profileImageInput: ProfileImageInput!) {
    uploadProfileImage(profileImageInput: $profileImageInput) {
      imageUrl
      email
      id
      role
      phone
      name
      isVerified
      googlePassword
    }
  }
`;
