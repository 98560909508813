import { Typography, TextField, FormControl, Select, Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import { UpdateDataUserVerify } from "../../gql/mutations/userWebSiteVisit";
import { ownwerDomainCreate } from "../../redux/action";
import { validateString } from "../Validate/validate";
import { usePopup } from "../../context/commonPop";
import { GetUserWebsiteData } from "../../hooks/userWebsiteData";
import { extractDomainAndProtocol } from "../../common/utils";

const ButtonWrapper = {
  textAlign: "right",
  mt: "15px",
  "& button": {
    boxShadow: "none",
    textTransform: "none",

    borderRadius: "7px",
    padding: "9px 24px 9px",
    marginRight: "8px",
    "&:hover": {
      boxShadow: "none",
    },
    "&:focus": { outline: "none" },
  },
};

const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "15px",
  "& .InputStyleWrapper": {
    width: "calc(50% - 15px)",
    "& .css-px39cz-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled": {
      color: `${theme.palette.text.primary} !important`,

      "& input": {
        color: `${theme.palette.text.primary} !important`,

        "&::placeholder": {
          color: `${theme.palette.text.primary} !important`,
        },
      },
    },
  },
  "&  .menuitem": {
    color: `${theme.palette.text.primary} !important`,
  },
  "& .InputStyleWrapperFullWidth": {
    width: "calc(100% - 15px)",
  },
  "& .selectWrapper": {
    color: theme.palette.text.primary,
    "& .Mui-disabled": {
      textFillColor: theme.palette.text.primary,
      opacity: "0.5",
    },

    "& .css-1wu7ecg-MuiSvgIcon-root-MuiSelect-icon, .css-gofekn-MuiSvgIcon-root-MuiSelect-icon": {
      fill: theme.palette.text.primary,
    },
    "& .css-1b9x3a-MuiInputBase-root-MuiOutlinedInput-root": {
      border: "none",
      "&:hover": {
        border: `1px solid ${theme.palette.text.primary} !important`,
      },
    },
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.text.primary} `,
      "&:hover": {
        border: `1px solid ${theme.palette.text.primary} !important`,
      },
    },
    "& .css-9425fu-MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.text.primary} !important`,
      "&:hover": {
        border: `1px solid ${theme.palette.text.primary} !important `,
      },
    },

    "& input": {
      color: theme.palette.text.primary,
    },
  },
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  "& h3": {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "25px",
    color: theme.palette.text.primary,
  },
  "& p.Description": {
    fontSize: "16px",
    marginBottom: "20px",
    color: "#757575",
  },
  "& button": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
}));

const loderfor = {
  isLoading: false,
  htmlTag: false,
  htmlFile: false,
  dnsLoading: false,
  googleLoding: false,
};
function UpDateChild({ ProjectData }) {
  const dispatch = useDispatch();
  const [loder, setLoder] = useState(loderfor);
  const { ownerId } = useParams();
  const { handleOpen } = usePopup();

  const [UPDATE_USER_WEB_VISIT] = useMutation(UpdateDataUserVerify);
  const [formState, setFormState] = useState({
    scope: "",
    websiteUrl: "",
    projectName: "",
  });
  const [errorState, setErrorState] = useState({
    scope: false,
    projectName: false,
    websiteUrl: false,
  });
  const navigate = useNavigate();

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const errCheck = async data => {
    const scope = await validateString(data.scope);
    const projectName = await validateString(data.projectName);
    const websiteUrl = await validateString(data.websiteUrl);

    await setErrorState({
      ...errorState,
      scope,

      websiteUrl,
      projectName,
    });
    if (scope || projectName || websiteUrl) {
      return true;
    }
    return false;
  };
  const findOwnerById = async () => {
    if (ProjectData) {
      setLoder({ ...loder, isLoading: true });

      try {
        // await dispatch(ownwerDomainCreate(ProjectData));
        const { website, projectName } = ProjectData || {};
        const { domain, protocol } = extractDomainAndProtocol(website);
        const splitScope = `${protocol}//`;
        setFormState({ ...formState, scope: splitScope, websiteUrl: domain, projectName });
      } catch (error) {
        setLoder({ ...loder, isLoading: false });
        console.log("errdata", error.message);
        handleOpen(true, "Something went wrong please wait some time");
      }
    } else {
      console.log("owner id not found");
    }
  };

  const updateMutationWebSite = async payload => {
    await UPDATE_USER_WEB_VISIT({
      variables: { payload },
      onCompleted: ({ updateUserWebSiteVerify }) => {
        dispatch(ownwerDomainCreate(updateUserWebSiteVerify));
        const { website } = updateUserWebSiteVerify || {};
        const { domain, protocol } = extractDomainAndProtocol(website);
        setFormState({ ...formState, scope: protocol, websiteUrl: domain });

        navigate("/dashboard?menu=dashboard");
      },
      onError: errdata => {
        handleOpen(true, "Something went wrong please wait some time");
        console.log("UPDATE_USERVISIT", errdata.message);
      },
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const { projectName } = formState || {};
    const status = await errCheck(formState);
    if (!status && formState.scope !== null) {
      const payload = {
        id: ownerId,
        projectName,
      };
      await updateMutationWebSite(payload);
    }
  };
  useEffect(() => {
    findOwnerById();
    // eslint-disable-next-line
  }, [ProjectData]);
  return (
    <BoxWrapper>
      <Typography component="h3">Update a project</Typography>
      <Box component="form" onSubmit={event => handleSubmit(event)} sx={{ mb: "0" }}>
        <InputWrapper>
          <FormControl className="InputStyleWrapper">
            <Select className="selectWrapper" value={formState.scope ?? ""} disabled onChange={handleInputChange} displayEmpty error={errorState?.scope} name="scope">
              <MenuItem value="">None</MenuItem>
              <MenuItem value="http://">HTTP</MenuItem>
              <MenuItem value="https://">HTTPS</MenuItem>
            </Select>
            <Typography component="span" sx={{ color: "red" }}>
              {errorState?.scope && "please select this scope"}
            </Typography>
          </FormControl>

          <TextField
            name="websiteUrl"
            value={formState.websiteUrl}
            onChange={handleInputChange}
            error={errorState?.websiteUrl}
            disabled
            placeholder="Domain or path"
            className="InputStyleWrapper selectWrapper"
            helperText={errorState.websiteUrl && "Please enter valid domain or path"}
          />

          <TextField
            name="projectName"
            value={formState.projectName}
            placeholder="Project Name"
            onChange={handleInputChange}
            className="InputStyleWrapperFullWidth selectWrapper"
            error={errorState?.projectName}
            helperText={errorState.projectName && "Please enter valid project Name"}
          />
        </InputWrapper>
        <Box sx={ButtonWrapper}>
          <Button type="submit">Submit</Button>
        </Box>
      </Box>
    </BoxWrapper>
  );
}
function UpdateProject() {
  const { ownerId } = useParams();
  const { ProjectData } = GetUserWebsiteData(ownerId);
  return <UpDateChild ProjectData={ProjectData} />;
}

export default UpdateProject;
