import { useMediaQuery, Box, Drawer, Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toggleMobileSidebar } from "../../../redux/action";
import SidebarItems from "../../NavBar/sidebar/SidebarItems";
import Logo from "../../NavBar/sidebar/logo/logo";
import NavListing from "./NavListing/NavListing";

function Navigation() {
  const lgUp = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const customizer = useSelector(state => state.customizer);
  const dispatch = useDispatch();

  if (lgUp) {
    return (
      <Box sx={{ borderBottom: "1px solid rgba(0,0,0,0.05)" }} py={2}>
        {/* ------------------------------------------- */}
        {/* Sidebar for desktop */}
        {/* ------------------------------------------- */}
        <Container
          sx={{
            maxWidth: customizer.isLayout === "boxed" ? "lg" : "100%!important",
          }}
        >
          <NavListing />
        </Container>
      </Box>
    );
  }

  return (
    <Drawer
      anchor="left"
      open={customizer.isMobileSidebar}
      onClose={() => dispatch(toggleMobileSidebar())}
      variant="temporary"
      PaperProps={{
        sx: {
          width: customizer.SidebarWidth,
          border: "0 !important",
          boxShadow: theme => theme.shadows[8],
        },
      }}
    >
      {/* ------------------------------------------- */}
      {/* Logo */}
      {/* ------------------------------------------- */}
      <Box px={2}>
        <Logo path="/dashboard" />
      </Box>
      {/* ------------------------------------------- */}
      {/* Sidebar For Mobile */}
      {/* ------------------------------------------- */}
      <SidebarItems />
    </Drawer>
  );
}

export default Navigation;
