/* eslint-disable */
import { Box, Typography, Card, CardContent, Grid, Divider, Stack, useTheme } from "@mui/material";
import welcomeImage2 from "../../Images/backgrounds/welcome-bg.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
function WelcomeCard() {
  const { userData } = useSelector(state => state.reducer);
  const theme = useTheme();
  const btnGroup = {
    display: "flex",
    gap: "20px",
    mt: "20px",
    "& .purchase-plan": {
      backgroundColor: theme.palette.primary.main,
      fontWeight: 700,
      color: "#fff",
      padding: { xs: "10px 20px", sm: "10px 35px" },
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "8px",
    },
  };
  return (
    <Card elevation={0} sx={{ backgroundColor: theme.palette.primary.light, py: 0 }}>
      <CardContent sx={{ py: 4, px: 2 }}>
        <Grid container justifyContent="space-between">
          <Grid item sm={6} display="flex" alignItems="center">
            <Box
              gap="16px"
              sx={{
                display: {
                  xs: "block",
                },
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="h5" sx={{ whiteSpace: { xs: "wrap", sm: "nowrap" }, fontSize: { xs: "20px", sm: "28px" }, fontWeight: "700", mb: "8px" }}>
                  Current Plan:{" "}
                  <Typography
                    component="span"
                    sx={{ whiteSpace: { xs: "wrap", sm: "nowrap" }, fontSize: { xs: "20px", sm: "28px" }, fontWeight: "700", mb: "12px", color: theme.palette.primary.main }}
                  >
                    {userData?.subscriptionCredits?.subscriptionType || ""}
                  </Typography>
                </Typography>
                <Typography component="p" sx={{ fontSize: "14px", fontWeight: "500" }}>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </Typography>
              </Box>
              <Box sx={btnGroup}>
                <Link className="purchase-plan" to="/account-settings">
                  View Plan Details
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box mb="-24px" textAlign="right">
              <Typography component="img" src={welcomeImage2} alt="img" sx={{ width: "250px", height: "auto" }} />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default WelcomeCard;
