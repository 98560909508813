import { useEffect, useReducer } from "react";
import { useParams } from "react-router";
import { useMutation } from "@apollo/client";
import { Spinner } from "../Spinner";
import { invitationVerifications } from "../../gql/mutations/org";

const initialTodos = {
  laoding: false,
  success: false,
  error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "START":
      return { ...state, laoding: true };
    case "STOP":
      return { ...state, laoding: false };
    case "SUCCESS":
      return { ...state, laoding: false, success: true, error: "" };
    case "ERROR":
      return {
        ...state,
        laoding: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

function IsVerifying() {
  return (
    <>
      <h1>Verifying...</h1>
      <Spinner />
    </>
  );
}

function IsError({ error }) {
  return (
    <>
      <h1>Something went Wrong!</h1>
      <h3>error:{error}</h3>
    </>
  );
}

function IsSuccess() {
  return <h1>Successfully Verified</h1>;
}

function AcceptInvitation() {
  const { token, otp, organizationId } = useParams();
  const [todos, dispatch] = useReducer(reducer, initialTodos);

  const [AcceptInvitation1] = useMutation(invitationVerifications);

  useEffect(() => {
    dispatch({ type: "START", payload: "" });
    AcceptInvitation1({
      variables: {
        payload: {
          token,
          otp,
          organizationId,
        },
      },
    })
      .then(res => {
        if (res.data && res.data.varifyMember) {
          dispatch({ type: "SUCCESS", payload: "" });
        } else {
          dispatch({ type: "STOP", payload: "" });
        }
      })
      .catch(err => {
        const errString = JSON.stringify(err);
        const errJson = JSON.parse(errString);
        dispatch({ type: "ERROR", payload: errJson.message });
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {localStorage.setItem("token", token)}
      {todos.laoding && <IsVerifying />}
      {todos.success && <IsSuccess />}
      {todos.error && <IsError error={todos.error} />}
    </div>
  );
}

export default AcceptInvitation;
