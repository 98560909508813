export const LocalStorageKey = {
  ACCESS_TOKEN: "accessToken",
  REFRESH_TOKEN: "refreshToken",
};

export const popupType = {
  success: "success",
  warning: "warning",
  failed: "failed",
};
export const projectDetails = {
  STANDARD: {
    projectName: "STANDARD",
    project: 5,
    siteAudit: 10000,
    blogGeneration: 15,
    price: 1000,
  },
  PREMIUM: {
    projectName: "PREMIUM",
    project: 10,
    siteAudit: 15000,
    blogGeneration: 20,
    price: 100,
  },
  ENTERPRISES: {
    projectName: "ENTERPRISES",
    project: 15,
    siteAudit: 20000,
    blogGeneration: 6,
    price: 10,
  },
  FREEMIUM: {
    projectName: "FREEMIUM",
    project: 3,
    siteAudit: 5000,
    blogGeneration: 5,
    price: 1,
  },
};
export const defultSubscriptionType = "FREEMIUM";
