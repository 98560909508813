import { Box, Container, Grid, Typography, styled } from "@mui/material";
import { useParams } from "react-router";
import MainTitle from "./MainTitle";
import UptimeMonitoringSetting from "./UptimeMonitoringSetting";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
}));

function UptimeMonitorSetting() {
  const { projectid } = useParams();

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} lg={12}>
              <MainTitle />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Typography label="Uptime Monitoring To Switch On / Off Here !!" className="tabItem" />
            </Grid>
            <Grid item xs={12} lg={12}>
              <UptimeMonitoringSetting projectid={projectid} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default UptimeMonitorSetting;
