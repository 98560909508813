/*eslint-disable */
/**
 * Regular expression for email
 * @type {RegExp}
 * @default
 */
const regexEmail = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

/**
 * Regular expression for password
 * @type {RegExp}
 * @default
 */
const regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;

/**
 * Validate the login form data. This is useful for reduce traffic to backend
 * @param  {string} email
 * @param  {string} password
 * @return {Boolean}                - True means data is valid
 */
const validateLoginForm = (email, password) => {
  let dataIsValid = true;

  if (!email || !password) {
    dataIsValid = false;
  }

  if (!regexEmail.test(email)) {
    dataIsValid = false;
  }

  if (!regexPassword.test(password)) {
    dataIsValid = false;
  }
  return dataIsValid;
};

/**
 * Validate the registration form data
 * @param  {string} email
 * @param  {string} password
 * @param  {string} repeatPassword
 * @return {Boolean}                - True means data is valid
 */
const validateRegisterForm = (email, password, repeatPassword) => {
  let dataIsValid = true;

  if (!email || !password || !repeatPassword) {
    dataIsValid = false;
  }

  if (password !== repeatPassword) {
    dataIsValid = false;
  }

  if (!regexEmail.test(email)) {
    dataIsValid = false;
  }

  if (!regexPassword.test(password)) {
    dataIsValid = false;
  }

  return dataIsValid;
};

const validateContact = data => {
  let result;
  if (!data.match(/^\d{10}$/)) {
    result = true;
  } else {
    result = false;
  }
  return result;
};

const validateString = data => {
  let result;
  if (!/\S/.test(data)) {
    result = true;
  } else {
    result = false;
  }
  return result;
};

const validateName = name => {
  let result;
  if (/^[A-Za-z\s]+$/.test(name) && /\S/.test(name)) {
    result = false;
  } else {
    result = true;
  }
  return result;
};

const validateDescription = data => {
  let result;
  if (data.trim() !== "" && data.length >= 15) {
    result = false;
  } else {
    result = true;
  }
  return result;
};

const validpassword = data => {
  if (!regexPassword.test(data)) {
    return true;
  }
  return false;
};

/**
 * Validate API version in the format 'YYYY-MM' and ensure it's not empty
 * @param {string} apiVersion - The API version to validate
 * @returns {boolean} - True if valid, false otherwise
 */
const validateApiVersion = apiVersion => {
  if (!apiVersion.trim()) return "API version must be required";
  // const regexApiVersion = /^\d{4}-\d{2}$/;
  // return regexApiVersion.test(apiVersion) ? "" : "Invalid API version format";
};

/**
 * Validate shop name in the format 'XXXXX.myshopify.com' and ensure it's not empty
 * @param {string} shopName - The shop name to validate
 * @returns {boolean} - True if valid, false otherwise
 */
const validateShopName = shopName => {
  if (!shopName.trim()) return "Shop name must be required";
  const regexShopName = /^[a-z0-9-]+\.myshopify\.com$/;
  return regexShopName.test(shopName) ? "" : "Invalid shop name format";
};

/**
 * Validate Shopify token in the format 'shpat_<32 alphanumeric characters>' and ensure it's not empty
 * @param {string} shopifyToken - The Shopify token to validate
 * @returns {boolean} - True if valid, false otherwise
 */
const validateShopifyToken = shopifyToken => {
  if (!shopifyToken.trim()) return "Shopify token must be required";
  const regexShopifyToken = /^shpat_[a-fA-F0-9]{32}$/;
  return regexShopifyToken.test(shopifyToken) ? "" : "Invalid Shopify token format";
};

const validateUsername = shopifyToken => {
  if (!shopifyToken.trim()) return "Username must be required";
};
const validateUserPassword = shopifyToken => {
  if (!shopifyToken.trim()) return "password must be required";
};

module.exports = {
  regexEmail,
  regexPassword,
  validateLoginForm,
  validateRegisterForm,
  validpassword,
  validateContact,
  validateString,
  validateDescription,
  validateApiVersion,
  validateShopName,
  validateShopifyToken,
  validateUserPassword,
  validateUsername,
  validateName,
};
