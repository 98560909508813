/* eslint-disable */
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Card, CardHeader, CardContent, Divider, Box, Container, styled, Grid } from "@mui/material";
import { useSelector } from "react-redux";

const SectionWrapper = styled("section")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: "500px",
  overflowY: "auto",
  "&::-webkit-scrollbar":{
    display:"none"
  },
  "@media screen and (max-width:600px)": {
    width: "90%",
  },
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,

  "& .createTicket": {
    marginBottom: "20px",
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  "& .cardContent": {
    paddingTop: 0,
    paddingBottom: 0,
    "& .MuiTypography-subtitle1": {
      marginTop: "12px",
    },
  },
}));

const ParentCard = ({ title, children, footer }) => {
  const customizer = useSelector(state => state.customizer);

  const theme = useTheme();
  const borderColor = theme.palette.divider;

  return (
    <SectionWrapper>
      <Card
        sx={{ padding: 0, height: "auto", border: !customizer.isCardShadow ? `1px solid ${borderColor}` : "none" }}
        elevation={customizer.isCardShadow ? 9 : 0}
        variant={!customizer.isCardShadow ? "outlined" : undefined}
      >
        <CardHeader title={title} />
        <Divider />

        <CardContent className="cardContent">{children}</CardContent>
        {footer ? (
          <>
            <Divider />
            <Box p={3}>{footer}</Box>
          </>
        ) : (
          ""
        )}
      </Card>
    </SectionWrapper>
  );
};

export default ParentCard;
