import gql from "graphql-tag";

export const getTicketsAdmin = gql`
  query Query($ticketId: String) {
    getTickets(ticketId: $ticketId) {
      Status
      comment
      createdAt
      description
      id
      image
      isDeleted
      messages
      name
      show
      type
      updatedAt
      isDesktop
      userId
      ticketId
    }
  }
`;
export const GET_ALL_DATA_SUPERADMIN = gql`
  query GetAllTicketsBySuperAdmin($timeStamp: SuperAdmintimeStamp!) {
    getAllTicketsBySuperAdmin(timeStamp: $timeStamp) {
      tickets {
        Status
        comment
        createdAt
        description
        id
        image
        isDeleted
        messages
        name
        show
        type
        updatedAt
        userId
        ticketId
      }
      ticketCount {
        Closed
        Open
        Pending
        total
      }
    }
  }
`;
