import { Box } from "@mui/material";
import ReactApexChart from "react-apexcharts";

function ApexChart({ polarAreaValue = 400, seriesValue }) {
  const options = {
    chart: {
      width: polarAreaValue,
      type: "polarArea",
      padding: 0,
    },
    labels: ["SEO", "Best Practices", "Accessibility", "Performance "],
    fill: {
      opacity: 1,
      colors: ["#FCA58B", "#34E3C2", "#FFB93E", "#25C8D0"],
    },
    stroke: {
      width: 1,
      colors: ["#FCA58B", "#34E3C2", "#FFB93E", "#25C8D0"],
    },
    yaxis: {
      show: false,
    },
    legend: {
      position: "bottom",
      markers: {
        strokeColor: ["#FCA58B", "#34E3C2", "#FFB93E", "#25C8D0"],
        fillColors: ["#FCA58B", "#34E3C2", "#FFB93E", "#25C8D0"],
      },
      labels: {
        colors: ["#FCA58B", "#34E3C2", "#FFB93E", "#25C8D0"],
      },
    },
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 0,
        },
        spokes: {
          strokeWidth: 0,
        },
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        shadeTo: "light",
        shadeIntensity: 0.6,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#FCA58B", "#34E3C2", "#FFB93E", "#25C8D0"],
      },
    },
  };

  return (
    <Box
      sx={{
        "& .apexcharts-legend": {
          maxWidth: "275px",
          marginLeft: "auto",
          marginRight: "auto",
        },
      }}
    >
      <div id="chart">
        <ReactApexChart options={options} series={seriesValue} type="polarArea" width={330} />
      </div>
      <div id="html-dist"></div>
    </Box>
  );
}
export default ApexChart;
