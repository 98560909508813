import { Box, FormControlLabel } from "@mui/material";
import { memo } from "react";
import CustomCheckbox from "../../theme-elements/CustomCheckbox";

function ColorsCheckbox({ setNotesFilter }) {
  const handleColorChange = selectedColor => {
    setNotesFilter(notesFilter => {
      const { searchOptions } = notesFilter;
      const { selectedColors } = searchOptions;

      const updatedSelectedColors = selectedColors.includes(selectedColor) ? selectedColors.filter(color => color !== selectedColor) : [...selectedColors, selectedColor];

      return {
        ...notesFilter,
        searchOptions: {
          ...searchOptions,
          selectedColors: updatedSelectedColors.sort(),
        },
      };
    });
  };
  return (
    <Box textAlign="start">
      <FormControlLabel name="selectedColor" value="primary" control={<CustomCheckbox />} label="Primary" onChange={() => handleColorChange("primary")} />
      <FormControlLabel name="selectedColor" value="info" control={<CustomCheckbox color="info" />} label="info" onChange={() => handleColorChange("info")} />
      <FormControlLabel name="selectedColor" value="success" control={<CustomCheckbox color="success" />} label="Success" onChange={() => handleColorChange("success")} />
      <FormControlLabel name="selectedColor" value="warning" control={<CustomCheckbox color="warning" />} label="Warning" onChange={() => handleColorChange("warning")} />
      <FormControlLabel name="selectedColor" value="error" control={<CustomCheckbox color="error" />} label="Error" onChange={() => handleColorChange("error")} />
    </Box>
  );
}

export default memo(ColorsCheckbox);
