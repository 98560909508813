import { useEffect, useState } from "react";
import "./verify.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { Box, Button, CircularProgress, Container, Grid, IconButton, Link } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { useGoogleLogin } from "@react-oauth/google";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate, useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ownwerDomainCreate } from "../../redux/action";
import { UpdateDataUserVerify } from "../../gql/mutations/userWebSiteVisit";
import { GoogleAuthApi, HTML_FILE_VERIFY, HTML_TAG_VERIFY, findDomain } from "../../gql/queries/userWebSiteList";
import { usePopup } from "../../context/commonPop";
// import { CreateScrapyReport, PageSpeedCreate } from "../../hooks/scrapyData";
import { GetUserWebsiteData } from "../../hooks/userWebsiteData";

const AccordionsBoxWrapper = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.common.secondory}`,

  borderRadius: "10px",
  padding: "15px 30px 30px",

  "& h4.AccordionSubHeading": {
    marginBottom: "15px",
    marginTop: "15px",
    fontSize: "18px",
    color: theme.palette.text.primary,
  },
  "& > .MuiAccordion-root": {
    boxShadow: "none",
    border: "1px solid #dedede",
    marginBottom: "10px",
    borderRadius: "5px",
    "&::before": { content: "none" },
  },
  "& .AccordionHeaderTitle": {
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
    },
    "& h6": {
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
  },
  "& .MuiAccordionDetails-root": {
    "& p": {
      fontSize: "16px",
      color: theme.palette.text.primary,

      marginBottom: "15px",
    },
    "& .GoogleConnectBTN": {
      display: "flex",
      alignItems: "center",
      padding: "8px 12px 8px",
      fontSize: "16px",

      backgroundColor: theme.palette.primary.main,

      color: "#fff",
      "& svg": {
        marginTop: "-1px",
        marginRight: "11px",
        fontSize: "26px",
        color: "#fff",
      },
      "&:hover": {
        backgroundColor: theme.palette.common.link,

        color: theme.palette.common.white,
        "& svg": { color: theme.palette.common.white },
      },
      "&:focus": { outline: "none" },
    },
  },
  "& .DNSRecordBodyContent": {
    "& p": { "& strong": { fontWeight: "700" } },
    "& .MetaDataBlock": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.white,

      borderRadius: "5px",
      padding: "12px 18px 12px",
      marginBottom: "15px",
      "& p": {
        color: theme.palette.primary.main,

        marginBottom: "5px",
        "&:last-child": { marginBottom: "0" },
      },
    },
    "& .ErrorText": { color: "#ff0000" },
    "& .VerifyBtn": {
      boxShadow: "none",
      textTransform: "none",
      backgroundColor: theme.palette.primary.main,

      color: "#fff",
      borderRadius: "5px",
      padding: "4px 18px 4px",
      "&:hover": {
        backgroundColor: theme.palette.common.link,

        color: theme.palette.common.white,
      },
      "&:focus": { outline: "none" },
      "& span.MuiCircularProgress-root": {
        width: "25px !important",
        height: "25px !important",
        "& svg": { color: "#ffffff" },
      },
    },
  },
  "& .HtmlFileBodyContent": {
    "& p": {
      "& a": {
        color: theme.palette.primary.main,

        textDecoration: "none",
      },
    },
    "& .VerificationDownloadBtn": {
      boxShadow: "none",
      textTransform: "uppercase",
      backgroundColor: theme.palette.primary.main,

      color: "#fff",

      borderRadius: "5px",
      marginBottom: "15px",
      padding: "4px 18px 4px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,

        color: "#fff",
      },
      "&:focus": { outline: "none" },
    },

    "& .VerifyBtn": {
      boxShadow: "none",
      textTransform: "none",
      backgroundColor: theme.palette.primary.main,

      color: "#fff",
      borderRadius: "5px",
      padding: "4px 18px 4px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,

        color: "#fff",
      },
      "&:focus": { outline: "none" },
      "& span.MuiCircularProgress-root": {
        width: "25px !important",
        height: "25px !important",
        "& svg": { color: "#ffffff" },
      },
    },
  },
  "& .HtmlTagBodyContent": {
    "& .MetaDataBlock": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      borderRadius: "5px",
      padding: "12px 18px 12px",
      marginBottom: "15px",
      "& p": { color: theme.palette.primary.main, marginBottom: "5px", "&:last-child": { marginBottom: "0" } },
    },
    "& .VerifyBtn": {
      boxShadow: "none",
      textTransform: "none",
      backgroundColor: theme.palette.primary.main,

      color: "#fff",
      borderRadius: "5px",
      padding: "4px 18px 4px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,

        color: "#fff",
      },
      "&:focus": { outline: "none" },
      "& span.MuiCircularProgress-root": {
        width: "25px !important",
        height: "25px !important",
        "& svg": { color: "#ffffff" },
      },
    },
  },
  "& .accordianItem": {
    backgroundColor: theme.palette.common.accordian,
  },
}));

const HeadingWrapper = styled(Box)(({ theme }) => ({
  "& h3": {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  "& p": {
    color: theme.palette.text.primary,
  },
}));

const SectionWrapper = {
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  pt: "34px",
  pb: "50px",
};
const BoxMainWrapper = {
  backgroundColor: "#ffffff",
  borderRadius: "20px",
  padding: "54px 65px 50px",
  border: "5px solid #6edbff59",
  boxShadow: "0px 0px 50px -33px #b3b3b3",
  "& > h3": {
    fontSize: "24px",
    fontWeight: 100,
    mb: "10px",
  },
  "& > p": { mb: "30px", color: "#00000085" },
};
const BoxMainWrapper1 = {
  "& > h3": {
    fontSize: "24px",
    fontWeight: 100,
    mb: "10px",
  },
  "& > p": { mb: "30px", color: "#00000085" },
};
const loderfor = {
  isLoading: false,
  htmlTag: false,
  htmlFile: false,
  dnsLoading: false,
  googleLoding: false,
};

function VerifyCode({ ProjectData }) {
  const [errMessage, setErrMessage] = useState({});
  const [loder, setLoder] = useState(loderfor);
  const [tagStr, setTagStr] = useState("");
  const [googleMsg, setGoogleMsg] = useState("");
  const [splitValue, setSplitValue] = useState([]);
  const { handleOpen } = usePopup();
  const { userData } = useSelector(state => state.reducer);
  const { ownerId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [GOOGLE_AUTH_VARIFY] = useLazyQuery(GoogleAuthApi, { fetchPolicy: "network-only" });
  const [CALL_DOMAIN] = useLazyQuery(findDomain, { fetchPolicy: "network-only" }, { fetchPolicy: "network-only" });
  const [HTML_VERIFY] = useLazyQuery(HTML_FILE_VERIFY, { fetchPolicy: "network-only" });
  const [HTML_TAG] = useLazyQuery(HTML_TAG_VERIFY, { fetchPolicy: "network-only" });
  const [UPDATE_USER_WEB_VISIT] = useMutation(UpdateDataUserVerify);
  // const { createScrapy } = CreateScrapyReport();
  // const { CreatePageSpeed } = PageSpeedCreate();

  const { token, website, isDns, isGoogle, isHtmlFile, isHtmlTag } = ProjectData || {};

  const updateMutationWebSite = async payload => {
    await UPDATE_USER_WEB_VISIT({
      variables: { payload },
      onCompleted: async ({ updateUserWebSiteVerify }) => {
        // const { data } = await createScrapy(updateUserWebSiteVerify);
        // const { ScrapUrl } = data || {};
        // if (ScrapUrl && ScrapUrl?.sessionId) {
        //   const { sessionId } = ScrapUrl || {};
        //   await navigate("/dashboard?menu=dashboard");
        //   handleOpen(true, `Data collection is in progress. The results will be displayed on the dashboard soon.`);
        //   await CreatePageSpeed(ownerId, projectId, website, sessionId);
        // }

        dispatch(ownwerDomainCreate(updateUserWebSiteVerify));
        navigate("/dashboard?menu=dashboard");
      },
      onError: errdata => {
        handleOpen(true, "Something went wrong please wait some time");
        console.log("UPDATE_USERVISIT", errdata.message);
      },
    });
  };
  const dnsRecordVerifyBtn = async (inputDomain, id) => {
    if (inputDomain) {
      setLoder({ ...loder, dnsLoading: true });
      await CALL_DOMAIN({
        variables: {
          domain: inputDomain,
        },
        onCompleted: async ({ performDnsLookup }) => {
          if (token) {
            const arr = [];
            performDnsLookup.forEach(el => {
              const result = el[0].search(token);
              if (result > -1) {
                arr.push(result);
              }
            });
            const payload = {
              isDns: true,
              isGoogle,
              isHtmlFile,
              isHtmlTag,
              verificationType: "meta_data",
              isVerified: true,
              id,
            };
            if (arr.length > 0) {
              await updateMutationWebSite(payload);
              await setLoder({ ...loder, dnsLoding: false });
              // if (data && data?.updateUserWebSiteVerify) {
              //   navigate("/dashboard");
              // }
            } else {
              await setLoder({ ...loder, dnsLoding: false });
              await setErrMessage({
                ...errMessage,
                dnsRecord: "TXT record not found. Please check your DNS configuration and try again.",
              });
            }
          }
        },
        onError: errdata => {
          setLoder({ ...loder, dnsLoading: false });
          setErrMessage({
            ...errMessage,
            dnsRecord: "TXT record not found. Please check your DNS configuration and try again.",
          });
          console.log(errdata.message);
        },
      });
    } else {
      console.log({ dnsRecord: "Domain missing" });
    }
  };

  const findOwnerById = async () => {
    setLoder({ ...loder, isLoading: true });

    try {
      const arr = website.split("/");
      await setSplitValue(arr);
      // await dispatch(ownwerDomainCreate(ProjectData));
      const str = `<meta name="report-tool-verification" content=${token}>`;
      await setTagStr(str);
      setLoder({ ...loder, isLoading: false });
    } catch (error) {
      setLoder({ ...loder, isLoading: false });
      console.log("errdata", error.message);
      handleOpen(true, "Something went wrong please wait some time");
    }
  };
  const GoogleAuthlogin = useGoogleLogin({
    scope: "https://www.googleapis.com/auth/siteverification",
    onSuccess: async tokenResponse => {
      if (tokenResponse?.access_token) {
        setLoder({ ...loder, googleLoding: true });
        const res = await GOOGLE_AUTH_VARIFY({
          variables: { token: tokenResponse?.access_token },
          onError: errdata => {
            console.log("errdata", errdata);
          },
        });
        const result = res?.data?.googleAuthApi.search(website);

        if (result > -1) {
          console.log(userData.id, true, ownerId);
          await setGoogleMsg("Verify by Gooogle");
          const payload = {
            verificationType: "search_console",
            isVerified: true,
            id: ownerId,
            isDns,
            isGoogle: true,
            isHtmlFile,
            isHtmlTag,
          };
          await updateMutationWebSite(payload);
        } else {
          setLoder({ ...loder, googleLoding: false });
          setErrMessage({
            ...errMessage,
            googleErr: `Website Not Found! 
                     Please make sure you are a verified owner or try other methods`,
          });
        }
      }
    },
  });
  const downloadFile = () => {
    const element = document.createElement("a");
    const file = new Blob([`report-tool-verification_${token}`], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${token}.file`;
    document.body.appendChild(element);
    element.click();
  };
  const HandleHTMLfile = async id => {
    if (id) {
      setLoder({ ...loder, htmlFile: true });
      await HTML_VERIFY({
        variables: {
          htmlFileVerifyId: id,
        },
        onCompleted: async ({ htmlFileVerify }) => {
          setLoder({ ...loder, htmlFile: false });
          if (htmlFileVerify) {
            const payload = {
              verificationType: "meta_data",
              isVerified: true,
              isHtmlFile: true,
              id,
              isGoogle,
              isHtmlTag,
              isDns,
            };
            await updateMutationWebSite(payload);

            // if (resp?.data && resp?.data?.updateUserWebSiteVerify) {
            //   navigate("/dashboard");
            // }
          } else {
            setErrMessage({
              ...errMessage,
              htmlFileVerify: "website is not verify",
            });
          }
        },
        onError: errdata => {
          setLoder({ ...loder, htmlFile: false });
          console.log("errdata HandleHTMLfile", errdata);
        },
      });
    } else {
      console.log("owner id not found");
    }
  };
  const handleHTMLtag = async id => {
    setLoder({ ...loder, htmlTag: true });
    if (id) {
      await HTML_TAG({
        variables: {
          htmlTagVerifyId: id,
        },
        onCompleted: async ({ htmlTagVerify }) => {
          if (htmlTagVerify) {
            setLoder({ ...loder, htmlTag: false });
            const payload = {
              verificationType: "meta_data",
              isVerified: true,
              isHtmlTag: true,
              id,
              isDns,
              isGoogle,
              isHtmlFile,
            };
            await updateMutationWebSite(payload);
          } else {
            setLoder({ ...loder, htmlTag: false });
            setErrMessage({
              ...errMessage,
              htmlTagVerify: "website is not verify",
            });
          }
        },
        onError: errdata => {
          setLoder({ ...loder, htmlTag: false });
          console.log("errdata handleHTMLtag", errdata);
        },
      });
    } else {
      setLoder({ ...loder, htmlTag: false });
      setErrMessage({
        ...errMessage,
        htmlTagVerify: "website is not verify",
      });
      console.log("owner id not found");
    }
  };
  useEffect(() => {
    if (ProjectData) findOwnerById();
    // eslint-disable-next-line
  }, [ProjectData]);

  return (
    <>
      <HeadingWrapper>
        <Typography component="h3">Verify Ownership</Typography>
        <Typography component="p">Verify ownership To use Ahrefs and check your website for free, please prove that you own your website</Typography>
      </HeadingWrapper>
      <AccordionsBoxWrapper>
        <Typography component="h4" className="AccordionSubHeading">
          Recommended method
        </Typography>
        <Accordion className="accordianItem">
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className="AccordionHeaderTitle">
            <IconButton>{ProjectData && isGoogle ? <CheckCircleIcon sx={{ color: "#008000" }} /> : <CancelIcon sx={{ color: "#ff0000" }} />}</IconButton>
            <Typography component="h6">Google Search Console (recommended) {googleMsg}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="p">
              Connect your Google Account and allow access to your Search Console data. If your website is verified in your Console, it will be automatically verified in Ahrefs.
            </Typography>
            <Button onClick={() => GoogleAuthlogin()} disabled={isGoogle || loder.googleLoding} className="GoogleConnectBTN">
              {loder.googleLoding ? (
                <CircularProgress />
              ) : (
                <>
                  <GoogleIcon />
                  Link Google Account{" "}
                </>
              )}
            </Button>
            <Typography component="span" color="red">
              {errMessage?.googleErr}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Typography component="h4" className="AccordionSubHeading">
          Other methods
        </Typography>
        <Accordion className="accordianItem">
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className="AccordionHeaderTitle">
            <IconButton>{ProjectData && isDns ? <CheckCircleIcon sx={{ color: "#008000" }} /> : <CancelIcon sx={{ color: "#ff0000" }} />}</IconButton>
            <Typography component="h6">DNS record</Typography>
          </AccordionSummary>
          <AccordionDetails className="DNSRecordBodyContent">
            <Typography component="p">
              Sign in to your domain name provider (e.g. godaddy.com or namecheap.com) and copy the TXT record below to the DNS configuration for{" "}
              <Typography component="strong">{splitValue[2]}</Typography>
            </Typography>
            <Box className="MetaDataBlock">
              <Typography component="p">Host Record: @</Typography>
              <Typography component="p">
                Value: report-tool-verification_
                {token}
              </Typography>
            </Box>
            <Typography>Complete TXT Record Resolution may take up to 24-48 hours.</Typography>

            <Typography component="p">
              Note: <Typography component="i"> DNS changes may take some time. If Site Audit doesn’t find the record immediately, wait a day before trying again.</Typography>
            </Typography>
            <Button
              disabled={isDns || loder.dnsLoading}
              onClick={() => {
                // HandleDnsRecord(ownerId);
                if (splitValue) dnsRecordVerifyBtn(splitValue[2], ownerId);
              }}
              className="VerifyBtn"
            >
              {loder.dnsLoading ? <CircularProgress /> : "Verify"}
            </Button>
            <Typography className="ErrorText" component="p">
              {errMessage?.dnsRecord}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="accordianItem">
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header" className="AccordionHeaderTitle">
            <IconButton>{ProjectData && isHtmlFile ? <CheckCircleIcon sx={{ color: "#008000" }} /> : <CancelIcon sx={{ color: "#ff0000" }} />}</IconButton>
            <Typography component="h6">HTML file</Typography>
          </AccordionSummary>
          <AccordionDetails className="HtmlFileBodyContent">
            <Typography component="p">Upload the HTML verification file below to your domain’s root folder discoverwebtech.com/ .</Typography>

            <Button onClick={downloadFile} className="VerificationDownloadBtn">
              download verification file
            </Button>
            <Typography component="p">
              If the upload was successful, the file will be viewable at:{" "}
              <Link href={`${splitValue[0]}//${splitValue[2]}/${ProjectData?.token}.file`}>{splitValue && `${splitValue[0]}//${splitValue[2]}/${ProjectData?.token}.file`}</Link>
            </Typography>
            <Button
              onClick={() => {
                HandleHTMLfile(ownerId);
              }}
              disabled={isHtmlFile || loder.htmlFile}
              className="VerifyBtn"
            >
              {loder.htmlFile ? <CircularProgress /> : "Verify"}
            </Button>
            <Typography component="span" color="red">
              {errMessage.htmlFileVerify}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="accordianItem">
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className="AccordionHeaderTitle">
            <IconButton>{ProjectData && isHtmlTag ? <CheckCircleIcon sx={{ color: "#008000" }} /> : <CancelIcon sx={{ color: "#ff0000" }} />}</IconButton>
            <Typography component="h6">HTML tag</Typography>
          </AccordionSummary>
          <AccordionDetails className="HtmlTagBodyContent">
            <Typography component="p">
              Copy the meta tag below and paste it into the HTML code of your site’s homepage. It should go in the section, before the first section..
            </Typography>
            <Box className="MetaDataBlock">
              <Typography> {ProjectData && tagStr}</Typography>
            </Box>

            <Button
              onClick={() => {
                handleHTMLtag(ownerId);
              }}
              disabled={isHtmlTag || loder.htmlTag}
              className="VerifyBtn"
            >
              {loder.htmlTag ? <CircularProgress /> : "Verify"}
            </Button>
            <Typography component="span" color="red">
              {errMessage.htmlTagVerify}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </AccordionsBoxWrapper>
    </>
  );
}
function VerifyOwnership({ varifyBoolean = false }) {
  const { ownerId } = useParams();
  const { ProjectData } = GetUserWebsiteData(ownerId);

  return varifyBoolean ? (
    <Box sx={BoxMainWrapper1}>
      <VerifyCode ProjectData={ProjectData} />
    </Box>
  ) : (
    <Box component="section" sx={SectionWrapper}>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={10}>
              <Box sx={BoxMainWrapper}>
                <VerifyCode ProjectData={ProjectData} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default VerifyOwnership;
