export const allStorage = () => {
  const values = [];
  const keys = Object.keys(localStorage);
  let i = keys.length;

  while (i--) {
    values.push(localStorage.getItem(keys[i]));
  }

  return values;
};

export const getAuthToken = () => localStorage.getItem("accessToken");

export const graphqlErrorParse = ({ err }) => {
  const errString = JSON.stringify(err);
  return JSON.parse(errString);
};

export const preparePayloadWithoutId = (id, payload) => {
  const payloadNew = { ...payload };
  delete payloadNew[id];
  return payloadNew;
};

export function OneDotSvg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-dot" viewBox="0 0 16 16" {...props}>
      <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
    </svg>
  );
}
export function extractDomainAndProtocol(website) {
  try {
    const url = new URL(website);
    const domain = url.hostname;
    // const protocol = url.protocol.replace(/:$/, ""); // Remove the trailing colon
    const protocol = url.protocol;
    return { domain, protocol };
  } catch (e) {
    // Handle errors if the URL is invalid
    console.error("Something went wrong:", e);
    return null; // You can return an error message or handle it differently
  }
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const monthNumber = date.getMonth();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  // const formattedDate = `${day} ${month} ${year} ${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  const obj = { day, month, year, formattedHours, ampm, minute: minutes.toString().padStart(2, "0"), monthNumber };
  return obj;
}

export const dateless10 = num => {
  if (num < 10) return `0${num}`;
  return num;
};
