/* eslint-disable */
import { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  FormGroup,
  FormControlLabel,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useSubscription } from "@apollo/client";
import { CrawlingReport, GetCrawlProgessData, ScreapySessionSingleData } from "../../hooks/scrapyData";
import { SCRAPYREPORT_SUBSCRIPTION } from "../../gql/subscriptions/scrapy";
import { convertISOToDateFormat } from "../../utils/myUtills";
import DurationCalculator from "./duration";
import CrawlProgressChart from "./CrawlProgressChart";
import MainLoader from "../MainLoader/MainLoader";
import BackButton from "../BackButton/BackButton";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  // alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .refreshBtn": {
    textAlign: "right",
    paddingBottom: "20px",
    "@media screen and (max-width:600px) ": {
      textAlign: "center",
    },
    "& button": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      minWidth: "78px",
      "& svg": {
        color: "#ffffff",
      },
    },
  },
  "& .crawlProgressHead": {
    display: "flex",
    borderRadius: "7px",
    alignItems: "center",
    gap: "35px",
    backgroundColor: theme.palette.primary.main,
    padding: "30px 30px 30px 20px",
    "@media screen and (max-width:600px)": {
      flexWrap: "wrap",
      gap: "15px",
      padding: "30px 20px",
      justifyContent: "space-between",
      "& h3": {
        fontSize: "13px !important",
      },
      "& p": {
        color: "#fff",
        fontWeight: "600 !important",
        fontSize: "9px !important",
      },
    },
    "& h3": {
      color: "#fff",
      fontWeight: 600,
      fontSize: "13px",
    },
    "& p": {
      color: "#fff",

      fontWeight: 600,
      fontSize: "22px",
    },
    "& .crawlInnerBox": {
      borderRight: "2px solid #ebebee",
      paddingRight: "15px",
      "& .statusBox": {
        "& .LoaderText": {
          fontSize: "18px",
          fontWeight: 700,
          color: "#fff",
          "& span": {
            display: " inline-block",
            animation: "sk-bouncedelay 1.2s infinite ease-in-out both",
            width: "10px",
            height: "10px",
            backgroundColor: "#fff",
            borderRadius: "50%",
            "&.bounce1": {
              animationDelay: "-0.32s",
            },
            "&.bounce2": {
              animationDelay: "-0.16s",
            },

            "@keyframes sk-bouncedelay": {
              "0%, 80%, 100%": { transform: "scale(0)" },
              "40%": { transform: "scale(1)" },
            },
          },
        },
      },
      "@media screen and (max-width:600px)": {
        border: "none",
        paddingRight: "0",
      },
      "&:last-child": {
        border: "none",
      },
    },
  },
  "& .mobiletable": {
    display: "none",

    "@media screen and (max-width:600px)": {
      display: "block",
    },
  },
  "& .crawledTable": {
    marginTop: "30px",
    backgroundColor: theme.palette.primary.light,
    "& .css-qvtrhg-MuiDataGrid-virtualScroller": {
      marginLeft: "10px",
      marginRight: "10px",
      "&::-webkit-scrollbar-track": {
        WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "10px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar": { width: "8px", backgroundColor: "transparent" },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
        backgroundColor: theme.palette.primary.main,
      },
    },
    "& .datagridnopage": {
      border: "none",
      "@media screen and (max-width:600px)": {
        display: "none",
      },
      "& .MuiDataGrid-virtualScroller": {
        "& .MuiDataGrid-virtualScrollerRenderZone": {
          "& [role='row']": {
            "& .MuiDataGrid-cell": {
              justifyContent: "center",
              "&:first-of-type": { justifyContent: "left" },
            },
          },
        },
      },
      "& .MuiDataGrid-columnHeaders": {
        "& .MuiDataGrid-columnHeadersInner": {
          "& [role='row']": {
            "& .MuiDataGrid-columnHeader": {
              "& .MuiDataGrid-columnHeaderDraggableContainer": {
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                  justifyContent: "center",
                  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                    "& .MuiDataGrid-columnHeaderTitle": { marginLeft: "0", marginRight: "13px" },
                  },
                },
              },
              "&:first-of-type": {
                "& .MuiDataGrid-columnHeaderDraggableContainer": {
                  "& .MuiDataGrid-columnHeaderTitleContainer": {
                    justifyContent: "left",
                    "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                      "& .MuiDataGrid-columnHeaderTitle": { marginLeft: "0" },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    "& .css-13ede6n-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainerContent": {
      overflow: "visible",
    },
    "& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar p": {
      marginBottom: 0,
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
    "&  .MuiDataGrid-withBorderColor": {
      borderColor: " #ccc",
      whiteSpace: "wrap !important",
      "@media screen and (max-width:600px)": {
        whiteSpace: "nowrap !important",
      },
      "&:focus": {
        outline: "none ",
      },
    },
    // "& .css-13ede6n-MuiDataGrid-root .MuiDataGrid-row": {
    //   minHeight: "45px !important",
    //   maxHeight: "45px !important",
    // },
    // "& .MuiDataGrid-columnHeader": {
    //   "@media screen and (max-width:600px)": {},

    //   maxWidth: "450px !important",
    //   width: "450px !important",
    // },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: "16px",
      marginLeft: "10px",
      "@media screen and (max-width:600px)": {},
      fontSize: "13px",
    },

    "& .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone": {
      "&  .MuiDataGrid-cellContent": {
        color: `${theme.palette.text.primary} !important`,
        fontWeight: 600,
        fontSize: "13px",
      },
    },
    "& .MuiDataGrid-cellContent": {
      color: `${theme.palette.text.primary} !important`,
      fontWeight: 600,
      fontSize: "13px",
    },
  },
}));

const CheckBoxWrapper = styled(Box)(({ theme }) => ({
  "& .formgroupmain": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    "& .inputLabel .css-1j8wkzf-MuiTypography-root": {
      color: theme.palette.text.primary,
      fontWeight: 700,
    },
  },
}));

function CrawlProgressReport() {
  const { sessionId } = useParams();
  const [searchParams] = useSearchParams();
  const { crawlData, setCrawlData } = CrawlingReport(sessionId);
  const { sessionData } = ScreapySessionSingleData(sessionId);
  const {
    listData,
    pageNumber,
    setPageNumber,
    allUrlcount,
    setListData,
    setAllUrlCount,
    reFreshDetails,
    cancelCreateReport,
    isLoading,
    selectedStatus,
    setSelectedStatus,
    bannerUrlcount,
  } = GetCrawlProgessData(sessionId);
  const location = useLocation();
  const { state } = location || {};
  const { projectName, project } = state || {};
  const { createdAt, isCompleted, isFailed, reportEndTime, isCanceled } = sessionData || {};
  const theme = useTheme();
  const customizer = useSelector(state => state.customizer);
  const { data: subscriptionData, loading, error } = useSubscription(SCRAPYREPORT_SUBSCRIPTION, { variables: { sessionId } });
  const columns = [
    {
      field: "url",
      headerName: "URL",
      flex: 0.2,
      maxWidth: 450,
      overflow: "hidden",
    },
    {
      field: "response_status",
      headerName: "Status",
      renderCell: params => (
        <Typography
          component="p"
          sx={{
            backgroundColor:
              params.value >= 200 && params.value <= 299
                ? theme.palette.primary.main
                : params.value >= 300 && params.value <= 399
                ? "rgba(255, 174, 31, 0.85)"
                : params.value >= 400 && params.value <= 599
                ? "#FA896B"
                : "grey",
            color: "#fff",
            borderRadius: "3px",
            width: "45px",
            padding: "3px 5px 3px 5px",
            display: "flex",
            justifyContent: "center",
            fontSize: "12px",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "pageSize",
      headerName: "Size",
    },
    {
      field: "internalLinkCount",
      headerName: "Internal Links Count",
      width: 170,
    },
    {
      field: "externalLinkCount",
      headerName: "External Links Count",
      width: 170,
    },
  ];

  const CreateData = useMemo(() => convertISOToDateFormat(createdAt), [createdAt]).formattedDate;

  const handleChange = (event, value) => {
    setPageNumber(value);
  };
  useEffect(() => {
    if (!loading && !error && subscriptionData) {
      const { documentUpdated } = subscriptionData || {};
      const { session_id, userId, ...newData } = documentUpdated || {};
      setCrawlData(prev => [...prev, newData]);
      setAllUrlCount(prev => prev + 1);
      if (pageNumber === 1) {
        listData.length >= 20 ? setListData(prev => [newData, ...prev.slice(0, -1)]) : setListData(prev => [newData, ...prev]);
      }
    }
  }, [subscriptionData, loading, error]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const filterValue = urlSearchParams.get("filter");
    if (filterValue) {
      // Parse the 'filter' value from the URL and update the state
      const filterArray = filterValue.split(",");
      setSelectedStatus(filterArray);
    }
  }, [location.search]);
  const handleStatusChange = event => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedStatus(prevSelectedStatus => [...prevSelectedStatus, value]);
    } else {
      setSelectedStatus(prevSelectedStatus => prevSelectedStatus.filter(status => status !== value));
    }
  };
  const updateURL = (search, pathname) => {
    const newURL = `${pathname}?${search}`;
    window.history.replaceState(null, "", newURL);
  };
  useEffect(() => {
    // Update the URL query parameter based on selectedStatus
    if (selectedStatus.length > 0) {
      // If there are selected statuses, set the 'filter' query parameter
      searchParams.set("filter", selectedStatus.join(","));
    } else {
      // If no selected statuses, remove the 'filter' query parameter
      searchParams.delete("filter");
    }

    // Update the URL by assigning the search parameters back to the URL
    updateURL(searchParams.toString(), location.pathname);
  }, [selectedStatus, searchParams, location.pathname]);

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <BackButton />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12}>
              <Card
                sx={{ padding: "30px 45px 24px 40px", border: `1px solid ${theme.palette.divider}` }}
                elevation={customizer.isCardShadow ? 9 : 0}
                variant={!customizer.isCardShadow ? "outlined" : undefined}
              >
                <Box className="crawlInnerBox refreshBtn">
                  <Button
                    onClick={() => {
                      reFreshDetails();
                    }}
                  >
                    Refresh
                  </Button>
                  {!isCompleted && (
                    <Button
                      disabled={isLoading}
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        cancelCreateReport();
                      }}
                    >
                      {isLoading ? <CircularProgress size={24.5} /> : "Cancel"}
                    </Button>
                  )}
                </Box>

                <Box className="crawlProgressHead">
                  <Box className="crawlInnerBox">
                    <Typography component="h3">Project</Typography>
                    <Typography component="p">{projectName || project || "--"}</Typography>
                  </Box>

                  <Box className="crawlInnerBox">
                    <Typography component="h3">Report Start At</Typography>
                    {createdAt && <Typography component="p">{CreateData || "12:00 PM 01/01/2024 IST"}</Typography>}
                  </Box>
                  <Box className="crawlInnerBox">{createdAt && <DurationCalculator createdAt={createdAt} isCompleted={isCompleted} reportEndTime={reportEndTime} />}</Box>
                  <Box className="crawlInnerBox">
                    <Typography component="h3">Urls Crawled</Typography>
                    <Typography component="p">{bannerUrlcount}</Typography>
                  </Box>
                  <Box className="crawlInnerBox">
                    <Typography component="h3">Report Status</Typography>
                    <Typography component="p" className="statusBox">
                      {isFailed && !isCanceled ? (
                        "Failed"
                      ) : isCompleted && !isCanceled ? (
                        "Completed"
                      ) : isCanceled ? (
                        "Canceled"
                      ) : (
                        <Typography component="h5" color="textSecondary" className="LoaderText">
                          In Progress
                          <Box component="span" className="bounce1"></Box>
                          <Box component="span" className="bounce2"></Box>
                          <Box component="span" className="bounce3"></Box>
                        </Typography>
                      )}
                    </Typography>
                  </Box>
                </Box>
                {<CrawlProgressChart createdAt={createdAt} crawlData={crawlData} />}
                <CheckBoxWrapper>
                  <FormGroup className="formgroupmain">
                    <FormControlLabel
                      className="inputLabel"
                      control={<Checkbox />}
                      onChange={handleStatusChange}
                      value="warning"
                      checked={selectedStatus.includes("warning")}
                      label="Warning"
                    />
                    <FormControlLabel
                      className="inputLabel"
                      control={<Checkbox />}
                      value="error"
                      checked={selectedStatus.includes("error")}
                      onChange={handleStatusChange}
                      label="Error"
                    />
                    <FormControlLabel
                      className="inputLabel"
                      control={<Checkbox />}
                      value="info"
                      checked={selectedStatus.includes("info")}
                      onChange={handleStatusChange}
                      label="Success"
                    />
                  </FormGroup>
                </CheckBoxWrapper>
                <Box className="crawledTable">
                  {listData && listData.length > 0 ? (
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow
                            sx={{
                              backgroundColor: { xs: theme.palette.primary.main, sm: "unset" },
                              "& th": {
                                fontSize: { xs: "12px !important", sm: "14px !important" },
                                whiteSpace: "nowrap",
                                color: { xs: "#fff !important", sm: `${theme.palette.text.primary}!important` },
                              },
                            }}
                          >
                            {columns.map(column => (
                              <TableCell key={column.field} className="MuiDataGrid-columnHeaderTitle">
                                {column.headerName}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {listData.map(row => (
                            <TableRow key={row.id}>
                              {columns.map(column => (
                                <TableCell
                                  key={column.field}
                                  className="MuiDataGrid-columnHeaderTitle"
                                  sx={{
                                    "&:first-of-type div": {
                                      maxWidth: { xs: "auto", sm: "400px" },
                                      wordBreak: { xs: "normal", sm: "break-word" },
                                    },
                                  }}
                                >
                                  <Box>{column.renderCell ? column.renderCell({ value: row[column.field] }) : row[column.field]}</Box>
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : !isCompleted && !isFailed ? (
                    <MainLoader message="In Progress" />
                  ) : null}
                  {allUrlcount > 0 && <Pagination count={Math.ceil(allUrlcount / 20)} page={pageNumber} onChange={handleChange} />}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default CrawlProgressReport;
