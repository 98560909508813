/* eslint-disable */
import { Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";
import { memo } from "react";

const CreatProjectWrapper = styled(Box)(({ theme }) => ({
  "@media screen and (max-width:550px)": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    width: "100%",
  },
  "& a": {
    width: "100%",
  },
  "& button": {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    "@media screen and (max-width:600px)": {
      width: "100%",
    },
    "& a": {
      color: theme.palette.common.textColor,
      width: "100%",
      "&:hover": { textDecoration: "none" },
    },
    "&:hover": {
      backgroundColor: theme.palette.common.hoverBtn,
      color: theme.palette.common.white,
      "& a": {
        color: theme.palette.common.white,
      },
    },
  },
  "& .OverviewBtn": {
    "@media screen and (min-width:550px)": { marginRight: "15px" },
  },
  "& .reportBtn": {
    width: "124px",
    "& span": {
      height: "25px !important",
      width: "25px !important",
      display: "flex",
    },
    "& svg": {
      color: "#fff",
      height: "25px",
      width: "25px",
    },
  },
  "& .Mui-disabled": {
    backgroundColor: theme.palette.common.orange,
    color: `${theme.palette.common.textColor} !important`,
    opacity: "0.5",
  },
}));

const CreateProjectButton = {
  textTransform: "none",
  backgroundColor: "#ff642d",
  color: "#fff",
  borderRadius: "5px",
  boxShadow: "none",
  "& a": {
    color: "#ffffff",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Plus Jakarta Sans', sans-serif !important",
    "& svg": { mr: "6px", fontSize: "22px", transition: "0.9s ease-out" },
  },
  "&:hover": { backgroundColor: "transparent", boxShadow: "none", "& a": { color: "#ff642d" }, "& svg": { transform: "rotateZ(720deg)" } },
  "&:focus": { outline: "none" },
};

function CreateProjectLink() {
  return (
    <CreatProjectWrapper>
      <Link to="/website">
        <Button variant="contained" sx={CreateProjectButton}>
          <AddIcon />
          Create Project
        </Button>
      </Link>
    </CreatProjectWrapper>
  );
}

export default memo(CreateProjectLink);
