import gql from "graphql-tag";

export const createUserVisit = gql`
  mutation Mutation($payload: Model_for_userWebsite!) {
    userWebSite(payload: $payload) {
      id
      isVerified
      token
      userId
      website
      verificationType
      projectName
      isCrawlProgress
      imageUrl
      srapySessionData {
        projectId
        domain
        sessionId
        isCompleted
        basicCompleted
        createdAt
        basicCompleted
        isCompleted
        reason
        isFailed
        urlCrawled
        errorUrlCount
      }
    }
  }
`;

export const getWebSiteList = gql`
  mutation Mutation($payload: model_UserResponse!) {
    userGetResponse(payload: $payload) {
      isVerified
      isCrawlProgress
    }
  }
`;

export const STORE_GOOGLEAUTH = gql`
  mutation Mutation($payload: Model_for_userWebsite!) {
    userWebSite(payload: $payload) {
      isVerified
      userId
      verificationType
      website
      id
      projectName
      isCrawlProgress
    }
  }
`;

export const CREATE_SHOPIFY_USERDATA = gql`
  mutation CreateShopifyUserData($userInput: CreateArgShopifyBlog!) {
    CreateShopifyUserData(userInput: $userInput)
  }
`;
export const CREATE_WORDPRESS_USERDATA = gql`
  mutation CreateWordpressUserData($userInput: CreateArgWordpressBlog!) {
    CreateWordpressUserData(userInput: $userInput)
  }
`;

export const CREATE_MAGENTO_USERDATA = gql`
  mutation CreateMagentoUserData($userInput: CreateArgWordpressBlog!) {
    CreateMagentoUserData(userInput: $userInput)
  }
`;

export const UpdateDataUserVerify = gql`
  mutation Mutation($payload: Model_for_userWebsiteUpdate!) {
    updateUserWebSiteVerify(payload: $payload) {
      id
      isVerified
      token
      userId
      verificationType
      website
      projectName
      isDns
      isGoogle
      isHtmlFile
      isHtmlTag
      isCrawlProgress
    }
  }
`;

export const PUBLISH_BLOG_SHOPIFY = gql`
  mutation CreateBlogForShopify($inputdata: CreateArgShopifyBlog!) {
    CreateBlogForShopify(inputdata: $inputdata)
  }
`;

export const PUBLISH_BLOG_WORDPRESS = gql`
  mutation CreateBlogForWordPress($inputdata: CreateArgWordpressBlog!) {
    CreateBlogForWordPress(inputdata: $inputdata)
  }
`;

// export const DataGetResponce = gql`
//   mutation Mutation($payload: model_UserResponse!) {
//     userGetResponse(payload: $payload) {
//       code
//       isVerified
//     }
//   }
// `;
