import { Box, Typography, useMediaQuery } from "@mui/material";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { IconTicket } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export function SidebarSupportComponent() {
  const navigate = useNavigate();
  const lgUp = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const customizer = useSelector(state => state.customizer);
  const { role } = useSelector(state => state.reducer.userData);
  const hideMenu = lgUp ? customizer.isCollapse && !customizer.isSidebarHover : "";

  const onClickNavigate = () => {
    if (role === "SUPERADMIN" || role === "COORDINATOR") {
      return navigate("/tickets");
    }
    return navigate("/support");
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={2}
      sx={{ cursor: "pointer", m: 3, p: 2, bgcolor: `${"secondary.light"}`, "&.ProfileAvatarWrapper": { p: 0, pt: 2, pb: 2, bgcolor: "transparent" } }}
      className={!hideMenu ? "" : "ProfileAvatarWrapper"}
      onClick={() => onClickNavigate()}
    >
      {!hideMenu ? (
        <>
          {" "}
          {role === "SUPERADMIN" || role === "COORDINATOR" ? <SupervisorAccountIcon /> : <IconTicket />}
          <Box sx={{ width: "110px", overflow: "hidden" }}>
            <Typography sx={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }} variant="h6">
              Support
            </Typography>
          </Box>
        </>
      ) : role === "SUPERADMIN" || role === "COORDINATOR" ? (
        <SupervisorAccountIcon />
      ) : (
        <IconTicket />
      )}
    </Box>
  );
}
