/*eslint-disable */
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { ADVANCE_BLOG_GEN, ADVANCE_SUBTOPIC_BLOG_GEN, FIND_MANY_TITLE_META_DECS } from "../gql/utils";
import { useLocation } from "react-router";
import { usePopup } from "../context/commonPop";

export const getItems = (item, index) => {
  return {
    id: `item-${index + 1}`,
    Topic: `${item?.title || "Default Title"}`,
    Content: `${
      item?.topic || "What's even more challenging is coming up with a great content idea - one that will bring you the right traffic and resonate across your audience."
    }`,
    Count: `${item?.wordCount || 0}`,
  };
};

export const GetMetaDataDescTitle = () => {
  const [tableData, setTableData] = useState(null);
  const [DataGridData, setDataGridData] = useState(null);
  const [getTableData] = useLazyQuery(FIND_MANY_TITLE_META_DECS, {
    fetchPolicy: "network-and-cache",
  });
  let arrayData = [];

  const callTableData = async inputArg => {
    const { flag } = inputArg || {};
    const mainFlag = flag.split("&")[0];
    if (inputArg) {
      const res = await getTableData({
        variables: {
          inputArg,
        },
      });
      if (res && res.data && res.data.FindTitleMetaData) {
        setTableData([...res.data.FindTitleMetaData]);
        if (mainFlag === "titleSort" || flag === "titleTagMissing") {
          arrayData = res.data.FindTitleMetaData.map((item, ind) => ({ id: ind + 1, title: item?.title }));
        } else {
          arrayData = res.data.FindTitleMetaData.map((item, ind) => ({ id: ind + 1, meta_description: item?.meta_description }));
        }
        setDataGridData(arrayData);
      } else {
        setTableData([]);
        setDataGridData([]);
      }
    }
  };

  return { tableData, callTableData, DataGridData, setDataGridData };
};

export const useAdvanceBlogContent = () => {
  const {
    state: { title, focusKeyword, selectedFocusKeywords,projectId },
  } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState(null);
  const { handleOpen } = usePopup();
  const [getAdvanceBlogContentMutation] = useMutation(ADVANCE_BLOG_GEN);

  useEffect(() => {
    if (title && (focusKeyword || selectedFocusKeywords)) {
      getAdvanceBlogContentMutation({
        variables: {
          blogInput: {
            title: title,
            focusKeyword: focusKeyword.length > 0 ? focusKeyword.join(", ") : selectedFocusKeywords.length > 0 ? selectedFocusKeywords.join(", ") : "",
            blogCount: 150,
          },
        },
        onCompleted: data => {
          const { advanceBlogContent } = data || {};
          const modifiedContentArray = advanceBlogContent.map(item => item.replace(/^\d+\.\s*/, ""));
          setContent(modifiedContentArray);
          setIsLoading(false);
        },
        onError: err => {
          console.log(err);
          handleOpen(true, err.message || "Data not fetch");
          setIsLoading(false);
        },
      });
    }
  }, [title, focusKeyword, selectedFocusKeywords]);

  return { isLoading, content, projectId };
};

export const useAdvanceSubtopicBlogContent = () => {
  const {
    state: { title, focusKeyword, selectedFocusKeywords, projectId },
  } = useLocation();
  const { handleOpen } = usePopup();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [isDisable, setIsDisable] = useState(true);
  const [generatedItems, setGeneratedItems] = useState([]);
  const [titleAndMetaDescription, setTitleAndMetaDescription] = useState({});

  const [getAdvanceSubtopicBlogContentMutation] = useMutation(ADVANCE_SUBTOPIC_BLOG_GEN);

  const advanceSubtopicData = async (item, complete, count, index) => {
    // setLoading(true);
    setIsDisable(true);
    try {
      const { data } = await getAdvanceSubtopicBlogContentMutation({
        variables: {
          blogInput: {
            title,
            subTopic: item,
            complete: complete ? complete : false,
            focusKeyword: focusKeyword.length > 0 ? focusKeyword.join(", ") : selectedFocusKeywords.length > 0 ? selectedFocusKeywords.join(", ") : "",
            blogCount: count || 150,
          },
        },
      });
      if (complete) {
        const { generated_subtopics } = data.advanceSubtopicBlogContent || {};

        const [metaData] = generated_subtopics.map(subtopic => {
          const match = subtopic.match(/Meta Title: (.+?)\n\nMeta Description: (.+)/);
          return {
            title: match ? match[1] : "",
            description: match ? match[2] : "",
          };
        });
        setTitleAndMetaDescription(metaData);
      } else {
        const { generated_subtopics, word_count } = data.advanceSubtopicBlogContent || {};
        const modifiedSubtopics = generated_subtopics.map(subtopic => subtopic.replace(/\n\n/g, "").replace(/\n/g, ""));
        const newObject = { topic: modifiedSubtopics[0], title: item, wordCount: word_count };
        const newItem = getItems(newObject, items.length);

        if (index === undefined) {
          setItems(prev => [...prev, newItem]);
        } else {
          setItems(prev => {
            prev[index] = newItem;
            return prev;
          });
        }
      }

      if (!generatedItems.includes(item)) {
        setGeneratedItems(prev => [...prev, item]);
      }

      setIsDisable(false);
      setLoading(false);
    } catch (error) {
      console.error("Error occurred:", error);
      handleOpen(true, error.message || "Something went wrong , Data not fetch");
      setLoading(false);
    }
  };

  return { loading, setLoading, advanceSubtopicData, items,title,setLoading, setItems, projectId ,isDisable, setIsDisable, titleAndMetaDescription, generatedItems, setGeneratedItems };
};
