/* eslint-disable */

import { memo, useEffect, useState, useMemo } from "react";
import { Box, Button, styled, Typography, Tooltip, Menu, MenuItem, useTheme } from "@mui/material";
import { useLocation, Link, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetScrapyData } from "../../../hooks/scrapyData";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const NavbarMenuWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  "@media screen and (max-width:1025px)": {
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "24px",
    gap: "10px",
  },
  "& > a": {
    "@media screen and (min-width:1200px) and (max-width:1280px)": {
      fontSize: "8px",
    },
    "@media screen and (min-width:1280px) and (max-width:1396px)": {
      fontSize: "10px",
    },
    "@media screen and (min-width:1396px) and (max-width:1510px)": {
      fontSize: "12px",
    },
    "&.MuiButton-colorInherit:hover": {
      color: theme.palette.primary.main,
    },
  },
  "& button": {
    backgroundColor: "transparent",
    border: "none",
    color: theme.palette.text.primary,
    "&:hover": { backgroundColor: "transparent" },
    "&:focus": { outline: "none" },
    "&.Mui-selected": { backgroundColor: "transparent", color: theme.palette.primary.main, fontWeight: "600" },
    "& span.MuiTouchRipple-root": { display: "none" },
  },
  "& h4": {
    backgroundColor: theme.palette.primary.main,
    padding: "0px 10px 1px",
    borderRadius: "5px",
    color: "#ffffff",
    fontWeight: 600,
    whiteSpace: "nowrap",
    maxWidth: "260px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "default",
  },
}));

const customPatterns = [
  /\/projects\/[^/]+\/[^/]+$/,
  /\/project-detail\/[^/]+$/,
  /\/uptime-monitoring\/[^/]+$/,
  /\/projects\/report\/desktop\/([^/]+)\/([^/]+)$/,
  /\/projects\/report\/mobile\/([^/]+)\/([^/]+)$/,
];
const allowedRoles = ["SUPERADMIN", "COORDINATOR"];

function AppDD({ setAlignment, setShowDrawer }) {
  const { scrapyData } = GetScrapyData();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const menu = searchParams.get("menu");
  const { userData } = useSelector(state => state.reducer);
  const theme = useTheme();
  const [anchorElAd, setAnchorElAd] = useState(null);
  const openAd = anchorElAd;
  const handleAnalyticDashboardClick = event => {
    setAnchorElAd(event.currentTarget);
  };
  const handleCloseAd = () => {
    setAnchorElAd(null);
    setShowDrawer && setShowDrawer(false);
  };

  const [urlParameters, setUrlParameters] = useState({
    srapySessionData: [],
    projectName: "",
    projectId: "",
  });
  const ButtonTabHover = pathname => {
    try {
      if (pathname.includes("uptime-monitoring/")) {
        setAlignment("Uptime");
      }
      if (pathname.includes("projects/")) {
        setAlignment("Overview");
      }
      if (pathname.includes("project-detail/")) {
        setAlignment("Site");
      }
      if (pathname.includes("/projects/report/desktop/")) {
        setAlignment("Website");
      }
      if (pathname.includes("/keywordsDashboard")) {
        setAlignment("Keyword");
      }
      // if (pathname.includes("/google-trends")) {
      //   setAlignment("Google Trends");
      // }
    } catch (e) {
      console.log(e);
    }
  };
  const extractProjectIDFromURL = (pathname, search) => {
    const urlSearchParams = new URLSearchParams(search);
    const originValue = urlSearchParams.get("origin");

    if (originValue) {
      return originValue;
    }
    const customPattern = /\/projects\/report\/desktop\/([^/]+)\/([^/]+)$/ || /\/projects\/report\/mobile\/([^/]+)\/([^/]+)$/;

    const customPathMatch = pathname.match(customPattern);

    if (customPathMatch) {
      const secondPart = customPathMatch[2];
      return secondPart;
    }

    const pathMatch = pathname.match(/\/(?:|projects|uptime-monitoring)\/([^/]+)/);

    if (pathMatch && pathMatch[1]) {
      return pathMatch[1];
    }

    return null;
  };

  const showButtons = useMemo(() => customPatterns.some(pattern => pattern.test(location.pathname)), [location.pathname]);

  useEffect(() => {
    ButtonTabHover(location.pathname, location.search);
    if (showButtons) {
      const projectID = extractProjectIDFromURL(location.pathname, location.search);
      const matchingProject = scrapyData.find(project => project.id === projectID);
      const { srapySessionData, projectName, id } = matchingProject || {};

      setUrlParameters({
        srapySessionData: srapySessionData || [],
        projectName: projectName || "",
        projectId: id || "",
      });
    }
    // eslint-disable-next-line
  }, [scrapyData, location.search, location.pathname, showButtons]);

  return (
    <NavbarMenuWrapper>
      <>
        <Button
          color={menu === "dashboard" ? "primary" : "inherit"}
          component={Link}
          to={`/dashboard?menu=dashboard`}
          onClick={() => {
            setShowDrawer && setShowDrawer(false);
          }}
        >
          Dashboard
        </Button>
        <Button
          color={menu === "headerSiteAudit" ? "primary" : "inherit"}
          component={Link}
          to={"/siteAudit?menu=headerSiteAudit"}
          onClick={() => {
            setShowDrawer && setShowDrawer(false);
          }}
        >
          Site Audit
        </Button>
        <Button
          color={menu === "headerMonitoring" ? "primary" : "inherit"}
          component={Link}
          to={`/uptimeMonitoring?menu=headerMonitoring`}
          onClick={() => {
            setShowDrawer && setShowDrawer(false);
          }}
        >
          Uptime Monitoring
        </Button>
        {userData && userData.role && allowedRoles.includes(userData.role) && (
          <Button
            color={menu === "headerAnalyticDashboard" ? "primary" : "inherit"}
            component={Link}
            to={`/analyticDashboard?menu=headerAnalyticDashboard`}
            onClick={() => {
              setShowDrawer && setShowDrawer(false);
            }}
          >
            Analytic Dashboard
          </Button>
        )}

        {/* <Button
          color={menu === "performanceDashboard" ? "primary" : "inherit"}
          component={Link}
          to={`/performanceDashboard?menu=performanceDashboard`}
          onClick={() => {
            setShowDrawer && setShowDrawer(false);
          }}
        >
          Performance Dashboard
        </Button>
        <Button
          color={menu === "headerKeywords" ? "primary" : "inherit"}
          component={Link}
          to={`/keywordsDashboard?menu=headerKeywords`}
          onClick={() => {
            setShowDrawer && setShowDrawer(false);
          }}
        >
          Blog Generator
        </Button>
        {userData && userData.role && allowedRoles.includes(userData.role) && (
          <Button
            color={menu === "headerAnalyticDashboard" ? "primary" : "inherit"}
            component={Link}
            to={`/analyticDashboard?menu=headerAnalyticDashboard`}
            onClick={() => {
              setShowDrawer && setShowDrawer(false);
            }}
          >
            Analytic Dashboard
          </Button>
        )}
        {/* <Button
          color={menu === "headerGoogletrends" ? "primary" : "inherit"}
          component={Link}
          to={`/googleTrends?menu=headerGoogletrends`}
          onClick={() => {
            setShowDrawer && setShowDrawer(false);
          }}
        >
          Google Trends
        </Button> */}

        {/* {userData && userData.role && allowedRoles.includes(userData.role) && (
          <>
            <Button
              color={anchorElAd ? "primary" : "inherit"}
              endIcon={!anchorElAd ? <ArrowRightIcon /> : <ArrowDropDownIcon />}
              onClick={handleAnalyticDashboardClick}
              component={Link}
              to={`#`}
              sx={{ "& .MuiButton-endIcon": { marginLeft: "0" } }}
            >
              Analytic Dashboard
            </Button>
            <Menu
              id="analytic-dashboard-menu"
              anchorEl={anchorElAd}
              open={openAd}
              onClose={handleCloseAd}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                "& ul": {
                  "& a": {
                    "@media screen and (min-width:1200px) and (max-width:1280px)": {
                      fontSize: "8px",
                    },
                    "@media screen and (min-width:1280px) and (max-width:1396px)": {
                      fontSize: "10px",
                    },
                    "@media screen and (min-width:1396px) and (max-width:1510px)": {
                      fontSize: "12px",
                    },
                    "&:hover": { color: theme.palette.primary.main },
                  },
                },
              }}
            >
              <MenuItem component={Link} to={`/users`} onClick={handleCloseAd} color="primary">
                Signup User
              </MenuItem>
              <MenuItem component={Link} to={`/reports?menu=headerAnalyticDashboard`} onClick={handleCloseAd}>
                Report Details
              </MenuItem>
              <MenuItem component={Link} to={`/all`} onClick={handleCloseAd}>
                All Details
              </MenuItem>
              <MenuItem component={Link} to={`#`} onClick={handleCloseAd}>
                Uptime Monitoring
              </MenuItem>
              <MenuItem component={Link} to={`/ticketList?menu=headerAnalyticDashboard`} onClick={handleCloseAd}>
                Tickets
              </MenuItem>
            </Menu>
          </>
        )} */}
      </>

      {/* {showButtons && urlParameters?.projectName && (
        <Tooltip title={urlParameters?.projectName} arrow>
          <Typography component="h4" color="inherit">
            {urlParameters?.projectName}
          </Typography>
        </Tooltip>
      )} */}
    </NavbarMenuWrapper>
  );
}

export default memo(AppDD);
