import { useContext, useState } from "react";
import { Box, Menu, Avatar, Typography, Divider, Button, IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import profileImage from "../../../Images/profile/user-1.jpg";
// import ultimateImage from "../../../Images/backgrounds/unlimited-bg.png";
import * as dropdownData from "./data";
import { logoutUser } from "../../../redux/action";
import { AuthContext } from "../../../AuthContext";
import LogoutPopUp from "../../Modal/LogoutPopup";
import { useSubscriptionCall } from "../../../hooks/auth";

function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { removeAuth } = useContext(AuthContext);
  const userData = useSelector(state => state.reducer.userData);
  const { data } = useSubscriptionCall();
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [open, setOpen] = useState(false); // State variable for logout popup

  const handleClick2 = event => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleLogout = () => {
    setOpen(true); // Open logout popup
  };

  const handleLogoutConfirm = () => {
    removeAuth();
    localStorage.removeItem("token");
    window.dispatchEvent(new Event("storage"));
    dispatch(logoutUser(true));
    setOpen(false);
    navigate("/");
  };

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="show 11 new notifications"
        color="inherit"
        aria-controls="msgs-menu"
        aria-haspopup="true"
        sx={{
          ...(typeof anchorEl2 === "object" && {
            color: "primary.main",
          }),
        }}
        onClick={handleClick2}
      >
        <Avatar
          src={userData?.imageUrl || profileImage}
          alt="ProfileImg"
          sx={{
            width: 35,
            height: 35,
          }}
        />
      </IconButton>
      {/* ------------------------------------------- */}
      {/* Message Dropdown */}
      {/* ------------------------------------------- */}
      <Menu
        id="msgs-menu"
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleClose2}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        sx={{
          "& .MuiMenu-paper": {
            width: "360px",
            p: 4,
          },
        }}
      >
        <Typography variant="h5">User Profile</Typography>
        <Stack direction="row" py={3} spacing={2} alignItems="center">
          <Avatar src={userData?.imageUrl || profileImage} alt="ProfileImg" sx={{ width: 50, height: 50 }} />
          <Box>
            <Typography variant="subtitle2" color="textPrimary" fontWeight={600}>
              {/* Mathew Anderson: */}
              {data?.userCreate?.name ?? userData?.name}
            </Typography>
            {/* <Typography variant="subtitle2" color="textSecondary">
              Designer
            </Typography> */}
            <Typography variant="subtitle2" color="textSecondary" display="flex" alignItems="center" gap={1}>
              {/* <IconMail width={15} height={15} /> */}
              {data?.userCreate?.email ?? userData?.email}
            </Typography>
          </Box>
        </Stack>
        <Divider />
        {dropdownData.profile.map(profile => (
          <Box key={profile.title}>
            <Box sx={{ py: 2, px: 0 }} className="hover-text-primary" onClick={handleClose2}>
              <Link to={profile.href}>
                <Stack direction="row" spacing={2}>
                  <Box width="45px" height="45px" display="flex" alignItems="center" justifyContent="center">
                    <Avatar
                      src={profile.icon}
                      alt={profile.subtitle}
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: 0,
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      color="textPrimary"
                      className="text-hover"
                      noWrap
                      sx={{
                        width: "240px",
                      }}
                    >
                      {profile.title}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle2"
                      sx={{
                        width: "240px",
                      }}
                      noWrap
                    >
                      {profile.subtitle}
                    </Typography>
                  </Box>
                </Stack>
              </Link>
            </Box>
          </Box>
        ))}
        <Box mt={2}>
          {/* <Box bgcolor="primary.light" p={3} mb={3} overflow="hidden" position="relative">
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="h5" mb={2}>
                  Unlimited <br />
                  Access
                </Typography>
                <Button variant="contained" color="primary">
                  Upgrade
                </Button>
              </Box>
              <img src={ultimateImage} alt="unlimited" className="signup-bg"></img>
            </Box>
          </Box> */}
          <Button variant="outlined" color="primary" fullWidth onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </Menu>
      <LogoutPopUp open={open} setOpen={setOpen} onLogout={handleLogoutConfirm} />
    </Box>
  );
}

export default Profile;
