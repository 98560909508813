import { Box, Container, Grid, styled, Typography, useTheme } from "@mui/material";
import WelcomeCard from "./WelcomeCard";
import BackButton from "../BackButton/BackButton";
import BlurSection from "./BlurSection";
import PurchasePlan from "./PurchasePlan";
const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
}));
function BlurPage() {
  const theme = useTheme();
  const MainHeadWrapper = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h3": { fontSize: "22px", fontWeight: 600, lineHeight: "35.28px", color: theme.palette.mode === "dark" ? "#ffffff" : "#000000" },
  };
  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} lg={12}>
              <WelcomeCard />
            </Grid>
            <Grid item xs={12}>
              <Box sx={MainHeadWrapper}>
                <Typography component="h3">working process all device</Typography>
                <BackButton sx={{ "& button": { marginBottom: "0 !important" } }} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <BlurSection />
            </Grid>
          </Grid>
          <Box sx={{ position: "sticky", bottom: "20px", width: { xs: "100%", sm: "80%" }, m: "auto" }}>
            <PurchasePlan />
          </Box>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default BlurPage;
