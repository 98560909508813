/* eslint-disable */
import { lazy, useState } from "react";
import { Avatar, Box, Card, Grid, MenuItem, Stack, Typography, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

import { IconGridDots } from "@tabler/icons-react";
import CustomSelect from "../forms/theme-elements/CustomSelect";

const Chart = lazy(() => import("react-apexcharts"), { ssr: false });

const BoxWrapper = styled(Box)(() => ({
  borderRadius: "7px",
  // marginBottom: "20px",
  height: "100%",
  "& .MainHeading": { fontSize: "18px", fontWeight: "600" },
  "& .SubHeading": { fontSize: "14px", fontWeight: "400" },
  "& .HeaderWrapper": { display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px" },
}));

function RevenueUpdates({ data }) {
  // chart color
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const secondary = theme.palette.secondary.main;
  const optionscolumnchart = {
    chart: {
      type: "bar",
      fontFamily: "'Plus Jakarta Sans', sans-serif;",
      foreColor: "#adb0bb",
      toolbar: {
        show: true,
      },
      height: 360,
      stacked: true,
    },
    colors: [primary, secondary],
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: "60%",
        columnWidth: "20%",
        borderRadius: [6],
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
      },
    },

    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      borderColor: "rgba(0,0,0,0.1)",
      strokeDashArray: 3,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      min: -24,
      max: 24,
      tickAmount: 10,
    },
    xaxis: {
      categories: data.dates,
      axisBorder: {
        show: false,
      },
    },
    tooltip: {
      theme: theme.palette.mode === "dark" ? "dark" : "light",
      fillSeriesColor: false,
    },
  };
  const seriescolumnchart = [
    {
      name: "UpTime",
      data: data.success ? data.uptimePerDay.map(item => Math.round(item / 60)) : 0,
    },
    {
      name: "Down Time",
      data: data.success ? data.downtimePerDay.map(item => Math.round(item / 60)) : 0,
    },
  ];
  const customizer = useSelector(state => state.customizer);
  const borderColor = theme.palette.divider;
  const [month, setMonth] = useState("1");

  const handleChange = event => {
    setMonth(event.target.value);
  };

  function calculateUptime(uptime, totalMinutes) {
    return Math.min(uptime, totalMinutes);
  }

  function calculateDowntime(downtime, totalMinutes) {
    return Math.min(downtime, totalMinutes);
  }

  function calculateAverageUptimePercentage(uptime, downtime, durationInDays) {
    const lastXDayUptime = calculateUptime(uptime, durationInDays * 24 * 60);
    const lastXDayDowntime = calculateDowntime(downtime, durationInDays * 24 * 60);
    const totalUptime = lastXDayUptime + lastXDayDowntime;

    if (totalUptime === 0) {
      return "0.00%";
    }

    const averageUptimePercentage = (lastXDayUptime / totalUptime) * 100;
    return averageUptimePercentage.toFixed(2);
  }

  return (
    <BoxWrapper>
      <Card
        sx={{ padding: "30px 45px 24px 40px", border: customizer.isCardShadow ? `1px solid ${borderColor}` : "none", height: "100%" }}
        elevation={customizer.isCardShadow ? 9 : 0}
        variant={!customizer.isCardShadow ? "outlined" : undefined}
      >
        <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
          <Grid item xs={12} lg={8}>
            <Box className="GraphMainBoxWrapper">
              <Box className="HeaderWrapper">
                <Box>
                  <Typography component="h5" className="MainHeading">
                    Website Uptime Status Checker
                  </Typography>
                  <Typography component="h6" className="SubHeading">
                    Monitor Your Webpage&rsquo;s Uptime Status
                  </Typography>
                </Box>
                {/* <CustomSelect labelId="month-dd" id="month-dd" size="small" value={month} onChange={handleChange}>
                  <MenuItem value={1}>March 2023</MenuItem>
                  <MenuItem value={2}>April 2023</MenuItem>
                  <MenuItem value={3}>May 2023</MenuItem>
                </CustomSelect> */}
              </Box>
              <Chart options={optionscolumnchart} series={seriescolumnchart || []} type="bar" height={360} width="100%" />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ pl: "90px !important" }}>
            <Box className="GraphMainBoxWrapper">
              <Typography component="h5" className="MainHeading">
                Total Uptime
              </Typography>
              <Stack spacing={3} mt={3}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box width={40} height={40} bgcolor="primary.light" display="flex" alignItems="center" justifyContent="center">
                    <Typography color="primary" variant="h6" display="flex">
                      <IconGridDots size={24} />
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h3" fontWeight="700">
                      {data ? `${Math.round(data.uptime / 60)} hours` : 0}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      Total Uptime
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
              <Stack spacing={3} my={5}>
                <Stack direction="row" spacing={2}>
                  <Avatar
                    sx={{
                      width: 9,
                      mt: 1,
                      height: 9,
                      bgcolor: "#5D87FF",
                      svg: { display: "none" },
                    }}
                  ></Avatar>
                  <Box>
                    <Typography variant="subtitle1" color="textSecondary">
                      24hr
                    </Typography>
                    <Typography variant="h5">{`${calculateAverageUptimePercentage(data.uptime, data.downtime, 1)}%`}</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Avatar
                    sx={{
                      width: 9,
                      mt: 1,
                      height: 9,
                      bgcolor: "#49BEFF",
                      svg: { display: "none" },
                    }}
                  ></Avatar>
                  <Box>
                    <Typography variant="subtitle1" color="textSecondary">
                      7 days
                    </Typography>
                    <Typography variant="h5">{`${calculateAverageUptimePercentage(data.uptime, data.downtime, 7)}%`}</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Avatar
                    sx={{
                      width: 9,
                      mt: 1,
                      height: 9,
                      bgcolor: "#05E47B",
                      svg: { display: "none" },
                    }}
                  ></Avatar>
                  <Box>
                    <Typography variant="subtitle1" color="textSecondary">
                      30 days
                    </Typography>
                    <Typography variant="h5">{`${calculateAverageUptimePercentage(data.uptime, data.downtime, 30)}%`}</Typography>
                  </Box>
                </Stack>
              </Stack>

              {/* <Button color="primary" variant="contained" fullWidth>
                View Full Report
              </Button> */}
            </Box>
          </Grid>
        </Grid>
      </Card>
    </BoxWrapper>
  );
}

export default RevenueUpdates;
