import { Box, Button, Container, Grid, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import FailedIcon from "../SvgIcons/FailedIcon";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .BoxWrapper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .FailedIcon": {
      width: "181px",
      height: "122px",
      marginBottom: "30px",
    },
    "& h3": {
      color: "#FF6062",
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: 1,
      marginBottom: "20px",
    },
    "& p": {
      textAlign: "center",
      color: "#757575",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: 1.5,
      marginBottom: "20px",
    },
    "& button": {
      textTransform: "none",
      backgroundColor: "transparent",
      color: "#FF6062",
      border: "2px solid #FF6062",
      borderRadius: "5px",
      boxShadow: "none",
      padding: "8px 22px",
      width: "280px",
      "@media screen and (max-width:600px)": {
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#FF6062",
        color: "#ffffff",
      },
    },
  },
}));

function PaymentFailed() {
  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} lg={12}>
              <Box className="BoxWrapper">
                <FailedIcon />
                <Typography component="h3">Failed!</Typography>
                <Typography component="p">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                  <br />
                  has been the industry standard dummy text ever since the 1500s,
                </Typography>
                <Link to="/payments">
                  <Button>Back to payment page</Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default PaymentFailed;
