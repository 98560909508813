function MobileIcon() {
  return (
    <svg width="30" height="48" viewBox="0 0 30 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="MobileIcon">
      <path
        d="M24.4218 46.5938H5.57808C3.37759 46.5938 1.59372 44.8099 1.59372 42.6094V5.39066C1.59372 3.19016 3.37759 1.40629 5.57808 1.40629H24.4218C26.6223 1.40629 28.4061 3.19016 28.4061 5.39066V42.6094C28.4061 44.8099 26.6223 46.5938 24.4218 46.5938Z"
        stroke="white"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1.59372 38.4375H28.4061" stroke="white" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.9999 42.375V42.5625" stroke="white" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default MobileIcon;
