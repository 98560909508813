import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Menu, MenuItem, Typography, useTheme } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  borderRadius: "8px",

  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function Popup({ flag, refresh, issueBtnIndx, updateFlag }) {
  const [open1, setOpen1] = useState(false);
  const [openChild, setOpenChild] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isTabOn = issueBtnIndx === "turnedoff";
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
    setOpen1(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  function HandleClick() {
    setOpenChild(!openChild);
    setOpen1(false);
  }
  const handleOpenPopup = () => {
    refresh(flag, updateFlag);

    setOpenChild(!openChild);
  };
  return (
    <>
      <Box>
        <Button
          sx={{
            backgroundColor: "transparent",
            minWidth: "auto",
            pl: "6px",
            pr: "6px",
            "&:hover": {
              backgroundColor: "transparent",
              color: theme.palette.primary.main,
            },
            ":focus": { outline: "none" },
          }}
          width="1px"
          onClick={handleMenu}
        >
          <MoreVertIcon />
        </Button>
        {open1 && (
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            sx={{
              "& .MuiPopover-paper": {
                overflow: "visible",
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: theme.palette.primary.main,
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
                "& ul": {
                  p: "0",
                  borderRadius: "6px",
                  overflow: "hidden",
                  "& .menuitem": {
                    color: "#ffffff",
                    backgroundColor: theme.palette.primary.main,
                    p: "12px 18px",
                  },
                },
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem className="menuitem asdf" onClick={() => HandleClick()}>
              {isTabOn ? "Turned on for this issue" : "Turned off for this issue"}
            </MenuItem>
          </Menu>
        )}
      </Box>

      {!open1 && (
        <Modal open={openChild} onClose={() => setOpenChild(!openChild)} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
          <Box sx={{ ...style, backgroundColor: theme.palette.background.default }}>
            <Typography component="p" sx={{ color: theme.palette.text.primary, fontWeight: 600 }} id="child-modal-description">
              {isTabOn ? "Are You Sure You Want to Turned on this Issue" : "Are You Sure You Want to Turned off this Issue"}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: "15px" }}>
              <Button
                sx={{ backgroundColor: theme.palette.primary.main, color: "#fff", fontWeight: 600, display: "inline-block", ":focus": { outline: "none" } }}
                onClick={handleOpenPopup}
              >
                Ok
              </Button>
              <Button
                sx={{ backgroundColor: theme.palette.primary.main, color: "#fff", fontWeight: 600, display: "inline-block", ":focus": { outline: "none" } }}
                onClick={() => setOpenChild(!openChild)}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}
