/* eslint-disable */
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import projectJson from "./projectdata.json";
const HelperWrapper = styled(Box)(({ theme }) => ({
  maxHeight: "500px",
  overflowX: "hidden",
  paddingRight: "15px",
  "::-webkit-scrollbar-track": {
    WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    backgroundColor: "#F5F5F5",
    borderRadius: "50px",
    "@media screen and (max-width:600px)": { backgroundColor: "transparent", WebkitBoxShadow: "none" },
  },
  "::-webkit-scrollbar": {
    width: "6px",
    backgroundColor: "#F5F5F5",
    "@media screen and (max-width:600px)": { width: "1px", backgroundColor: "transparent" },
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.common.hoverBtn,
    borderRadius: "50px",
    "@media screen and (max-width:600px)": { backgroundColor: "#000000" },
  },
  "& button": {
    fontSize: "14px",
    fontWeight: "400",
    // marginBottom: "16px",
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    padding: "6px 16px",
    "&:focus": {
      outline: "none",
    },
    "& a": {
      color: "#fff",
      fontWeight: "400",
    },
  },
  "& h5": {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: "20px",
    marginBottom: "10px",
  },
  "& p": {
    fontSize: "14px",
    marginBottom: "20px",
    fontWeight: "400",

    color: theme.palette.text.primary,
  },
  "& li": { fontSize: "14px", marginBottom: "8px", color: theme.palette.text.primary },
  "& .IssueDetailsData, .HowToFix": { marginBottom: "22px" },
  "& .HowToFix": {
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));

function ProjectHelperDetail({ flag, viewLink, val }) {
  return (
    <HelperWrapper>
      <Typography component="h5">Issue details</Typography>
      <Typography component="div" className="IssueDetailsData" dangerouslySetInnerHTML={{ __html: projectJson[flag]?.issue_Details }} />
      <Typography component="h5">To resolve this issue:</Typography>
      <Typography component="div" className="HowToFix" dangerouslySetInnerHTML={{ __html: projectJson[flag]?.how_to_fix }} />
      <Button disabled={!val}>
        <Link to={viewLink}>View Affected URLs</Link>
      </Button>
    </HelperWrapper>
  );
}

export default ProjectHelperDetail;
