import gql from "graphql-tag";

export const createTicket = gql`
  mutation Mutation($createData: TicketInput!) {
    ticketCreate(createData: $createData)
  }
`;

export const createChat = gql`
  mutation Mutation($createData: ChatInput!) {
    ChatCreate(createData: $createData)
  }
`;

export const newMessage = gql`
  mutation Mutation($updateData: TicketInput!) {
    newmsg(updateData: $updateData)
  }
`;

export const updateTicket = gql`
  mutation Mutation($updateData: TicketInput!) {
    ticketUpdate(updateData: $updateData)
  }
`;
