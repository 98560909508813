import { useTheme } from "@mui/material";

function ClockIcon() {
  const theme = useTheme();
  return (
    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="ClockIcon">
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
          <path d="m0 0h24v24h-24z" />
        </clipPath>
      </defs>
      <g clipPath="url(#cp1)">
        <path fill={theme.palette.primary.main} d="m12.3 5.6c0-0.5-0.5-1-1.1-1-0.6 0-1 0.5-1 1v7.3l4.6 4.3c0.4 0.4 1.1 0.4 1.5 0 0.4-0.4 0.4-1 0-1.4l-4-3.8z" />
        <path
          fillRule="evenodd"
          fill={theme.palette.primary.main}
          d="m24 12c0 6.6-5.4 12-12 12-6.6 0-12-5.4-12-12 0-6.6 5.4-12 12-12 6.6 0 12 5.4 12 12zm-12-10c-5.5 0-10 4.5-10 10 0 5.5 4.5 10 10 10 5.5 0 10-4.5 10-10 0-5.5-4.5-10-10-10z"
        />
      </g>
    </svg>
  );
}

export default ClockIcon;
