import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconBoxMultiple, IconPoint } from "@tabler/icons-react";
import { Box, List, useMediaQuery } from "@mui/material";
import { uniqueId } from "lodash";
import NavItem from "../NavItem/NavItem";
import NavCollapse from "../NavCollapse/NavCollapse";
import { GetScrapyData } from "../../../../hooks/scrapyData";
import ProjectIcon from "../../../../Images/ProjectIcon";
import NavGroup from "../../../NavBar/sidebar/NavGroup/NavGroup";
import { toggleMobileSidebar } from "../../../../redux/action";
import { MenudataAdmin, MenudataUser } from "../MenuData";
import { OneDotSvg } from "../../../../common/utils";

function NavListing() {
  const location = useLocation();
  const { pathname, search } = location || {};
  const pathDirect = `${pathname}${search}`;
  const { scrapyData } = GetScrapyData();
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf("/"));
  const customizer = useSelector(state => state.customizer);
  const lgUp = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const hideMenu = lgUp ? customizer.isCollapse && !customizer.isSidebarHover : "";
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.reducer);

  const childobject = {
    id: uniqueId(),
    title: "Discover",
    icon: IconPoint,
    href: "/",
    children: [
      {
        id: uniqueId(),
        title: "Overview",
        icon: OneDotSvg,
        href: "/",
      },
      {
        id: uniqueId(),
        title: "Report",
        icon: OneDotSvg,
        href: "/okokokp",
      },
    ],
  };
  const obj = {
    id: uniqueId(),
    title: "Projects",
    icon: IconBoxMultiple,
    href: "/",
    children: [childobject],
  };

  const [menuJson, setMenuJson] = useState(obj);

  useEffect(() => {
    const childobj = {
      id: uniqueId(),
      title: "Discover",
      icon: IconPoint,
      href: "/",
      children: [
        {
          id: uniqueId(),
          title: "Overview",
          icon: OneDotSvg,
          href: "/",
        },
        {
          id: uniqueId(),
          title: "Report",
          icon: OneDotSvg,
          href: "/okokokp",
        },
      ],
    };

    const projectObj = {
      id: uniqueId(),
      title: "Projects",
      icon: ProjectIcon,
      href: "/",
      children: [childobj],
    };

    if (Array.isArray(scrapyData) && scrapyData.length > 0) {
      const childArrayResp = (scrapyData || []).map(item => {
        const { srapySessionData, id, projectName } = item || {};
        if (srapySessionData[0] && srapySessionData[0].sessionId) {
          return {
            id: uniqueId(),
            title: projectName,
            icon: IconPoint,
            href: "/",
            children: [
              {
                id: uniqueId(),
                title: "Overview",
                icon: OneDotSvg,
                href: `/projects/${id}/${srapySessionData[0].sessionId}`,
              },
              {
                id: uniqueId(),
                title: "Site Audit",
                icon: OneDotSvg,
                href: `/project-detail/${srapySessionData[0]?.sessionId}?origin=${id}`,
              },
              {
                id: uniqueId(),
                title: "Website Performance",
                icon: OneDotSvg,
                href: `projects/report/desktop/${srapySessionData[0]?.sessionId}/${id}`,
              },
              {
                id: uniqueId(),
                title: "Uptime Monitoring",
                icon: OneDotSvg,
                href: `/uptime-monitoring/${id}?menu=monitoring`,
              },
            ],
          };
        }
        return {
          id: uniqueId(),
          title: projectName,
          icon: IconPoint,
          href: "/projects",
          children: [
            {
              id: uniqueId(),
              title: "Overview",
              icon: OneDotSvg,
              href: `/`,
            },
            {
              id: uniqueId(),
              title: "Site-Audit",
              icon: OneDotSvg,
              href: `/`,
            },
            {
              id: uniqueId(),
              title: "Desktop-Performance",
              icon: OneDotSvg,
              href: `/report/desktop`,
            },
            {
              id: uniqueId(),
              title: "uptime-monitoring",
              icon: OneDotSvg,
              href: "/uptime-monitoring",
            },
          ],
        };
      });
      projectObj.children = [...childArrayResp];
      setMenuJson({ ...projectObj });
    }
  }, [scrapyData]);
  return (
    <Box>
      <List sx={{ p: 0, display: "flex", gap: "3px", zIndex: "100" }}>
        {userData && userData.role && (userData.role === "SUPERADMIN" || userData.role === "COORDINATOR") ? (
          <>
            {MenudataAdmin.map(item => {
              if (item.subheader) {
                return <NavGroup item={item} hideMenu={hideMenu} key={item.subheader} />;

                // {/********If Sub Menu**********/}
              }
              if (item.children) {
                return (
                  <Box>
                    {item?.title === "Projects" ? (
                      <NavCollapse
                        menu={menuJson}
                        pathDirect={pathDirect}
                        hideMenu={hideMenu}
                        pathWithoutLastPart={pathWithoutLastPart}
                        level={1}
                        key={menuJson.id}
                        onClick={() => dispatch(toggleMobileSidebar())}
                      />
                    ) : (
                      <NavCollapse
                        menu={item}
                        pathDirect={pathDirect}
                        hideMenu={hideMenu}
                        pathWithoutLastPart={pathWithoutLastPart}
                        level={1}
                        key={item.id}
                        onClick={() => dispatch(toggleMobileSidebar())}
                      />
                    )}
                  </Box>
                );

                // {/********If Sub No Menu**********/}
              }
              return <NavItem item={item} key={item.id} pathDirect={pathDirect} hideMenu={hideMenu} onClick={() => dispatch(toggleMobileSidebar())} />;
            })}
          </>
        ) : (
          <>
            {MenudataUser.map(item => {
              if (item.subheader) {
                return <NavGroup item={item} hideMenu={hideMenu} key={item.subheader} />;

                // {/********If Sub Menu**********/}
              }
              if (item.children) {
                return (
                  <Box>
                    {item?.title === "Projects" ? (
                      <NavCollapse
                        menu={menuJson}
                        pathDirect={pathDirect}
                        hideMenu={hideMenu}
                        pathWithoutLastPart={pathWithoutLastPart}
                        level={1}
                        key={menuJson.id}
                        onClick={() => dispatch(toggleMobileSidebar())}
                      />
                    ) : (
                      <NavCollapse
                        menu={item}
                        pathDirect={pathDirect}
                        hideMenu={hideMenu}
                        pathWithoutLastPart={pathWithoutLastPart}
                        level={1}
                        key={item.id}
                        onClick={() => dispatch(toggleMobileSidebar())}
                      />
                    )}
                  </Box>
                );

                // {/********If Sub No Menu**********/}
              }
              return <NavItem item={item} key={item.id} pathDirect={pathDirect} hideMenu={hideMenu} onClick={() => dispatch(toggleMobileSidebar())} />;
            })}
          </>
        )}
      </List>
    </Box>
  );
}
export default NavListing;
