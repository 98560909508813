import { useNavigate } from "react-router";
import { Stack, Typography, Avatar, Box } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

function AllDetails({ stat }) {
  const navigate = useNavigate();
  return (
    <Stack direction="row" spacing={2} justifyContent={{ xs: "center", sm: "space-between" }} alignItems="center">
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        onClick={() => {
          if (stat.percent > 0) navigate(`${stat.url}`);
        }}
      >
        <Avatar
          sx={{
            bgcolor: stat.lightcolor,
            color: stat.color,
            width: 42,
            height: 24,
            borderRadius: "4px",
          }}
        >
          <Typography variant="subtitle2" fontWeight="600">
            {stat.percent}
          </Typography>
        </Avatar>
        <Box>
          <Typography variant="h6" mb="4px" fontSize="12px" sx={{ cursor: "pointer" }}>
            {stat.title} <LaunchIcon fontSize="12px" />
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
}

export default AllDetails;
