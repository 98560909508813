import { useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { forgotPassword } from "../../gql/queries/users";
import CustomTextField from "../forms/theme-elements/CustomTextField";
import CustomFormLabel from "../forms/theme-elements/CustomFormLabel";
import { usePopup } from "../../context/commonPop";

const LoaderStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
function AuthForgotPassword() {
  const [preForgetPassword] = useLazyQuery(forgotPassword);
  const [isLoading, setIsLoading] = useState(false);
  const [errormsg, setErrorMsg] = useState("");
  const { handleOpen } = usePopup();
  const [values, setValues] = useState({
    email: "",
    message: "",
  });
  const { email, message } = values;

  const handleChange = name => e => {
    setValues({ ...values, message: "", error: "", [name]: e.target.value });
  };
  const handleSubmit = async e => {
    e.preventDefault();

    if (email === "") {
      setErrorMsg("Email field cannot be empty");
      return;
    }
    setIsLoading(true);
    await preForgetPassword({
      variables: {
        email: values.email,
      },
      onError: err => {
        console.log(err.message);
        setErrorMsg(err.message);
      },
    })
      .then(data => {
        const links = data.data.preForgetPassword.message;
        setValues({ ...values, error: "" });
        setErrorMsg("");
        handleOpen(true, links);
      })
      .catch(err => {
        console.log(err);
      });
    setIsLoading(false);
  };

  const showError = () =>
    errormsg ? (
      <Typography component="p" sx={{ marginTop: 0, color: "red", fontWeight: "600", marginBottom: 0 }}>
        {errormsg}
      </Typography>
    ) : (
      ""
    );

  const showMessage = () =>
    message && (
      <Box component="p" sx={{ marginTop: 0, color: "red", fontWeight: "600", marginBottom: 0 }}>
        {/* <Typography> */}
        {values.message}
        {/* </Typography> */}
      </Box>
    );
  return (
    <Stack mt={4} spacing={2}>
      <CustomFormLabel htmlFor="reset-email">Email Adddress</CustomFormLabel>
      <CustomTextField id="reset-email" variant="outlined" fullWidth type="email" value={email} error={errormsg} required onChange={handleChange("email")} />

      <Button color="primary" variant="contained" size="large" fullWidth disabled={isLoading} onClick={handleSubmit}>
        {isLoading ? <CircularProgress size={30} style={LoaderStyle} /> : "Send password reset link"}
      </Button>

      <Box>
        {showError()}
        {showMessage()}
      </Box>

      <Button color="primary" size="large" fullWidth component={Link} to="/">
        Back to Login
      </Button>
    </Stack>
  );
}

export default AuthForgotPassword;
