import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import InfoIcon from "@mui/icons-material/Info";

const ReportProblemIconCss = {
  color: "#f13333",
  fontSize: "18px",
  mt: "-2px",
  mr: "5px",
};

const InfoErrorWrapper = {
  color: "#3288d7 !important",
  fontSize: "18px",
  mt: "-2px",
  mr: "5px",
};
const WarningErrorWrapper = {
  color: "#face0d !important",
  fontSize: "18px",
  mt: "-2px",
  mr: "5px",
};
export const prepareInternalCountArray = ({ detailCount }) => {
  const { robotsContent, canonicalData } = detailCount || {};

  const tableData = [
    {
      content: "Canonical points to 200",
      count: canonicalData?.status200?.count ?? "—",
      flag: "status200",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: canonicalData?.status200?.turn ?? false,
      newIssue: canonicalData?.status200?.newIssue ?? "—",
      removeIssue: canonicalData?.status200?.removeIssue ?? "—",
      changeCount: canonicalData?.status200?.changeCount ?? "—",
      addIssue: canonicalData?.status200?.addIssue ?? "—",
    },
    {
      content: "Canonical points to 2XX",
      count: canonicalData?.status2xx?.count ?? "—",
      flag: "status2xx",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: canonicalData?.status2xx?.turn ?? false,
      newIssue: canonicalData?.status2xx?.newIssue ?? "—",
      removeIssue: canonicalData?.status2xx?.removeIssue ?? "—",
      changeCount: canonicalData?.status2xx?.changeCount ?? "—",
      addIssue: canonicalData?.status2xx?.addIssue ?? "—",
    },
    {
      content: "Canonical points to 300",
      count: canonicalData?.status300?.count ?? "—",
      flag: "status300",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: canonicalData?.status300?.turn ?? false,
      newIssue: canonicalData?.status300?.newIssue ?? "—",
      removeIssue: canonicalData?.status300?.removeIssue ?? "—",
      changeCount: canonicalData?.status300?.changeCount ?? "—",
      addIssue: canonicalData?.status300?.addIssue ?? "—",
    },
    {
      content: "Canonical points to 4XX",
      count: canonicalData?.status4xx?.count ?? "—",
      flag: "status4xx",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: canonicalData?.status4xx?.turn ?? false,
      newIssue: canonicalData?.status4xx?.newIssue ?? "—",
      removeIssue: canonicalData?.status4xx?.removeIssue ?? "—",
      changeCount: canonicalData?.status4xx?.changeCount ?? "—",
      addIssue: canonicalData?.status4xx?.addIssue ?? "—",
    },
    {
      content: "Canonical points to 400",
      count: canonicalData?.status400?.count ?? "—",
      flag: "status400",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: canonicalData?.status400?.turn ?? false,
      newIssue: canonicalData?.status400?.newIssue ?? "—",
      removeIssue: canonicalData?.status400?.removeIssue ?? "—",
      changeCount: canonicalData?.status400?.changeCount ?? "—",
      addIssue: canonicalData?.status400?.addIssue ?? "—",
    },
    {
      content: "Canonical points to 404",
      count: canonicalData?.status404?.count ?? "—",
      flag: "status404",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: canonicalData?.status404?.turn ?? false,
      newIssue: canonicalData?.status404?.newIssue ?? "—",
      removeIssue: canonicalData?.status404?.removeIssue ?? "—",
      changeCount: canonicalData?.status404?.changeCount ?? "—",
      addIssue: canonicalData?.status404?.addIssue ?? "—",
    },
    {
      content: "Canonical points to 5XX",
      count: canonicalData?.status5xx?.count ?? "—",
      flag: "status5xx",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: canonicalData?.status5xx?.turn ?? false,
      newIssue: canonicalData?.status5xx?.newIssue ?? "—",
      removeIssue: canonicalData?.status5xx?.removeIssue ?? "—",
      changeCount: canonicalData?.status5xx?.changeCount ?? "—",
      addIssue: canonicalData?.status5xx?.addIssue ?? "—",
    },
    {
      content: "Canonical points to 500",
      count: canonicalData?.status500?.count ?? "—",
      flag: "status500",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: canonicalData?.status500?.turn ?? false,
      newIssue: canonicalData?.status500?.newIssue ?? "—",
      removeIssue: canonicalData?.status500?.removeIssue ?? "—",
      changeCount: canonicalData?.status500?.changeCount ?? "—",
      addIssue: canonicalData?.status500?.addIssue ?? "—",
    },
    {
      content: "Canonical points to redirect",
      count: canonicalData?.pointToRedirect?.count ?? "—",
      flag: "pointToRedirect",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      turnOff: canonicalData?.pointToRedirect?.turn ?? false,
      status: "error",
      newIssue: canonicalData?.pointToRedirect?.newIssue ?? "—",
      removeIssue: canonicalData?.pointToRedirect?.removeIssue ?? "—",
      changeCount: canonicalData?.pointToRedirect?.changeCount ?? "—",
      addIssue: canonicalData?.pointToRedirect?.addIssue ?? "—",
    },
    {
      content: "Nofollow Page",
      count: robotsContent?.noFollow?.count ?? "—",
      flag: "noFollow",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: robotsContent?.noFollow?.turn ?? false,
      newIssue: robotsContent?.noFollow?.newIssue ?? "—",
      removeIssue: robotsContent?.noFollow?.removeIssue ?? "—",
      changeCount: robotsContent?.noFollow?.changeCount ?? "—",
      addIssue: robotsContent?.noFollow?.addIssue ?? "—",
    },
    {
      content: "Noindex Page",
      count: robotsContent?.noIndex?.count ?? "—",
      flag: "noIndex",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: robotsContent?.noIndex?.turn ?? false,
      newIssue: robotsContent?.noIndex?.newIssue ?? "—",
      removeIssue: robotsContent?.noIndex?.removeIssue ?? "—",
      changeCount: robotsContent?.noIndex?.changeCount ?? "—",
      addIssue: robotsContent?.noIndex?.addIssue ?? "—",
    },
    {
      content: "NoFollow Noindex Page",
      count: robotsContent?.noFollowIndex?.count ?? "—",
      flag: "noFollowIndex",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: robotsContent?.noFollowIndex?.turn ?? false,
      newIssue: robotsContent?.noFollowIndex?.newIssue ?? "—",
      removeIssue: robotsContent?.noFollowIndex?.removeIssue ?? "—",
      changeCount: robotsContent?.noFollowIndex?.changeCount ?? "—",
      addIssue: robotsContent?.noFollowIndex?.addIssue ?? "—",
    },
    {
      content: "Nofollow in HTML and HTTP header",
      count: robotsContent?.noFollowInHttpHeader?.count ?? "—",
      flag: "noFollowInHttpHeader",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: robotsContent?.noFollowInHttpHeader?.turn ?? false,
      newIssue: robotsContent?.noFollowInHttpHeader?.newIssue ?? "—",
      removeIssue: robotsContent?.noFollowInHttpHeader?.removeIssue ?? "—",
      changeCount: robotsContent?.noFollowInHttpHeader?.changeCount ?? "—",
      addIssue: robotsContent?.noFollowInHttpHeader?.addIssue ?? "—",
    },
    {
      content: "Noindex in HTML and HTTP header",
      count: robotsContent?.noIndexInHttpHeader?.count ?? "—",
      flag: "noIndexInHttpHeader",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: robotsContent?.noIndexInHttpHeader?.turn ?? false,
      newIssue: robotsContent?.noIndexInHttpHeader?.newIssue ?? "—",
      removeIssue: robotsContent?.noIndexInHttpHeader?.removeIssue ?? "—",
      changeCount: robotsContent?.noIndexInHttpHeader?.changeCount ?? "—",
      addIssue: robotsContent?.noIndexInHttpHeader?.addIssue ?? "—",
    },
    {
      content: "Canonical from HTTP to HTTPS",
      count: robotsContent?.httpToHttps?.count ?? "—",
      flag: "httpToHttps",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: robotsContent?.httpToHttps?.turn ?? false,
      newIssue: robotsContent?.httpToHttps?.newIssue ?? "—",
      removeIssue: robotsContent?.httpToHttps?.removeIssue ?? "—",
      changeCount: robotsContent?.httpToHttps?.changeCount ?? "—",
      addIssue: robotsContent?.httpToHttps?.addIssue ?? "—",
    },
    {
      content: "Canonical from HTTPS to HTTP",
      count: robotsContent?.httpsToHttp?.count ?? "—",
      flag: "httpsToHttp",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: robotsContent?.httpsToHttp?.turn ?? false,
      newIssue: robotsContent?.httpsToHttp?.newIssue ?? "—",
      removeIssue: robotsContent?.httpsToHttp?.removeIssue ?? "—",
      changeCount: robotsContent?.httpsToHttp?.changeCount ?? "—",
      addIssue: robotsContent?.httpsToHttp?.addIssue ?? "—",
    },
  ];

  return tableData;
};

export const prepareSocialTagCount = socialTagsCount => {
  const tableData = [
    {
      content: "Open Graph tags incomplete",
      count: socialTagsCount?.graphTagsIncomplete?.count ?? "—",
      flag: "graphTagsIncomplete",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: socialTagsCount?.graphTagsIncomplete?.turn ?? false,
      newIssue: socialTagsCount?.graphTagsIncomplete?.newIssue ?? "—",
      removeIssue: socialTagsCount?.graphTagsIncomplete?.removeIssue ?? "—",
      changeCount: socialTagsCount?.graphTagsIncomplete?.changeCount ?? "—",
      addIssue: socialTagsCount?.graphTagsIncomplete?.addIssue ?? "—",
    },
    {
      content: "Open Graph URL not matching canonical",
      count: socialTagsCount?.graphUrlNotMatchingCanonical?.count ?? "—",
      flag: "graphUrlNotMatchingCanonical",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: socialTagsCount?.graphUrlNotMatchingCanonical?.turn ?? false,
      newIssue: socialTagsCount?.graphUrlNotMatchingCanonical?.newIssue ?? "—",
      removeIssue: socialTagsCount?.graphUrlNotMatchingCanonical?.removeIssue ?? "—",
      changeCount: socialTagsCount?.graphUrlNotMatchingCanonical?.changeCount ?? "—",
      addIssue: socialTagsCount?.graphUrlNotMatchingCanonical?.addIssue ?? "—",
    },
    {
      content: "Open Graph tags missing",
      count: socialTagsCount?.openGraphTagsMissing?.count ?? "—",
      flag: "openGraphTagsMissing",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: socialTagsCount?.openGraphTagsMissing?.turn ?? false,
      newIssue: socialTagsCount?.openGraphTagsMissing?.newIssue ?? "—",
      removeIssue: socialTagsCount?.openGraphTagsMissing?.removeIssue ?? "—",
      changeCount: socialTagsCount?.openGraphTagsMissing?.changeCount ?? "—",
      addIssue: socialTagsCount?.openGraphTagsMissing?.addIssue ?? "—",
    },
    {
      content: "Twitter card incomplete",
      count: socialTagsCount?.twitterCardIncomplete?.count ?? "—",
      flag: "twitterCardIncomplete",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: socialTagsCount?.twitterCardIncomplete?.turn ?? false,
      newIssue: socialTagsCount?.twitterCardIncomplete?.newIssue ?? "—",
      removeIssue: socialTagsCount?.twitterCardIncomplete?.removeIssue ?? "—",
      changeCount: socialTagsCount?.twitterCardIncomplete?.changeCount ?? "—",
      addIssue: socialTagsCount?.twitterCardIncomplete?.addIssue ?? "—",
    },
    {
      content: "Twitter card missing",
      count: socialTagsCount?.twitterCardMissing?.count ?? "—",
      flag: "twitterCardMissing",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: socialTagsCount?.twitterCardIncomplete?.turn ?? false,
      newIssue: socialTagsCount?.twitterCardIncomplete?.newIssue ?? "—",
      removeIssue: socialTagsCount?.twitterCardIncomplete?.removeIssue ?? "—",
      changeCount: socialTagsCount?.twitterCardIncomplete?.changeCount ?? "—",
      addIssue: socialTagsCount?.twitterCardIncomplete?.addIssue ?? "—",
    },
  ];
  return tableData;
};

export const prepareImageTagCount = GetImagesCounts => {
  const tableData = [
    {
      content: "Image broken",
      count: GetImagesCounts?.imageBroken?.count ?? "—",
      flag: "imageBroken",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: GetImagesCounts?.imageBroken?.turn ?? false,
      newIssue: GetImagesCounts?.imageBroken?.newIssue ?? "—",
      removeIssue: GetImagesCounts?.imageBroken?.removeIssue ?? "—",
      changeCount: GetImagesCounts?.imageBroken?.changeCount ?? "—",
      addIssue: GetImagesCounts?.imageBroken?.addIssue ?? "—",
    },
    {
      content: "Page has broken image",
      count: GetImagesCounts?.pageHasBrokenImage?.count ?? "—",
      flag: "pageHasBrokenImage",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: GetImagesCounts?.pageHasBrokenImage?.turn ?? false,
      newIssue: GetImagesCounts?.pageHasBrokenImage?.newIssue ?? "—",
      removeIssue: GetImagesCounts?.pageHasBrokenImage?.removeIssue ?? "—",
      changeCount: GetImagesCounts?.pageHasBrokenImage?.changeCount ?? "—",
      addIssue: GetImagesCounts?.pageHasBrokenImage?.addIssue ?? "—",
    },
    {
      content: "Missing alt text",
      count: GetImagesCounts?.missingAltText?.count ?? "—",
      flag: "missingAltText",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: GetImagesCounts?.missingAltText?.turn ?? false,
      newIssue: GetImagesCounts?.missingAltText?.newIssue ?? "—",
      removeIssue: GetImagesCounts?.missingAltText?.removeIssue ?? "—",
      changeCount: GetImagesCounts?.missingAltText?.changeCount ?? "—",
      addIssue: GetImagesCounts?.missingAltText?.addIssue ?? "—",
    },
    {
      content: "HTTPS page links to HTTP image",
      count: GetImagesCounts?.httpsPageLinksToHTTPImage?.count ?? "—",
      flag: "httpsPageLinksToHTTPImage",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: GetImagesCounts?.httpsPageLinksToHTTPImage?.turn ?? false,
      newIssue: GetImagesCounts?.httpsPageLinksToHTTPImage?.newIssue ?? "—",
      removeIssue: GetImagesCounts?.httpsPageLinksToHTTPImage?.removeIssue ?? "—",
      changeCount: GetImagesCounts?.httpsPageLinksToHTTPImage?.changeCount ?? "—",
      addIssue: GetImagesCounts?.httpsPageLinksToHTTPImage?.addIssue ?? "—",
    },
    {
      content: "Image redirects",
      count: GetImagesCounts?.imageRedirects?.count ?? "—",
      flag: "imageRedirects",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: GetImagesCounts?.imageRedirects?.turn ?? false,
      newIssue: GetImagesCounts?.imageRedirects?.newIssue ?? "—",
      removeIssue: GetImagesCounts?.imageRedirects?.removeIssue ?? "—",
      changeCount: GetImagesCounts?.imageRedirects?.changeCount ?? "—",
      addIssue: GetImagesCounts?.imageRedirects?.addIssue ?? "—",
    },
    {
      content: "Page has redirected image",
      count: GetImagesCounts?.PageHasRedirectedImage?.count ?? "—",
      flag: "PageHasRedirectedImage",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: GetImagesCounts?.PageHasRedirectedImage?.turn ?? false,
      newIssue: GetImagesCounts?.PageHasRedirectedImage?.newIssue ?? "—",
      removeIssue: GetImagesCounts?.PageHasRedirectedImage?.removeIssue ?? "—",
      changeCount: GetImagesCounts?.PageHasRedirectedImage?.changeCount ?? "—",
      addIssue: GetImagesCounts?.PageHasRedirectedImage?.addIssue ?? "—",
    },
  ];
  return tableData;
};

export const prepareJsCount = GetJsCount => {
  const { jsBroken, pageHasBrokenJs, httpsPageLinksToHttpJs, jsRedirects, PageHasRedirectedJs } = GetJsCount || {};
  const tableData = [
    {
      content: "JavaScript broken",
      count: jsBroken?.count ?? "—",
      flag: "jsBroken",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: jsBroken?.turn ?? false,
      newIssue: jsBroken?.newIssue ?? "—",
      removeIssue: jsBroken?.removeIssue ?? "—",
      changeCount: jsBroken?.changeCount ?? "—",
      addIssue: jsBroken?.addIssue ?? "—",
    },
    {
      content: "Page has broken JavaScript",
      count: pageHasBrokenJs?.count ?? "—",
      flag: "pageHasBrokenJs",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: pageHasBrokenJs?.turn ?? false,
      newIssue: pageHasBrokenJs?.newIssue ?? "—",
      removeIssue: pageHasBrokenJs?.removeIssue ?? "—",
      changeCount: pageHasBrokenJs?.changeCount ?? "—",
      addIssue: pageHasBrokenJs?.addIssue ?? "—",
    },
    {
      content: "HTTPS page links to HTTP JavaScript",
      count: httpsPageLinksToHttpJs?.count ?? "—",
      flag: "httpsPageLinksToHttpJs",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: httpsPageLinksToHttpJs?.turn ?? false,
      newIssue: httpsPageLinksToHttpJs?.newIssue ?? "—",
      removeIssue: httpsPageLinksToHttpJs?.removeIssue ?? "—",
      changeCount: httpsPageLinksToHttpJs?.changeCount ?? "—",
      addIssue: httpsPageLinksToHttpJs?.addIssue ?? "—",
    },
    {
      content: "JavaScript redirects",
      count: jsRedirects?.count ?? "—",
      flag: "jsRedirects",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: jsRedirects?.turn ?? false,
      newIssue: jsRedirects?.newIssue ?? "—",
      removeIssue: jsRedirects?.removeIssue ?? "—",
      changeCount: jsRedirects?.changeCount ?? "—",
      addIssue: jsRedirects?.addIssue ?? "—",
    },
    {
      content: "Page has redirected JavaScript",
      count: PageHasRedirectedJs?.count ?? "—",
      flag: "PageHasRedirectedJs",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: PageHasRedirectedJs?.turn ?? false,
      newIssue: PageHasRedirectedJs?.newIssue ?? "—",
      removeIssue: PageHasRedirectedJs?.removeIssue ?? "—",
      changeCount: PageHasRedirectedJs?.changeCount ?? "—",
      addIssue: PageHasRedirectedJs?.addIssue ?? "—",
    },
  ];
  return tableData;
};

export const prepareDuplicateCount = ({ duplicate }) => [
  {
    content: "Duplicate pages without canonical",
    count: duplicate?.count ?? "—",
    flag: "duplicate",
    icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
    status: "error",
    turnOff: duplicate?.turn ?? false,
    newIssue: duplicate?.newIssue ?? "—",
    removeIssue: duplicate?.removeIssue ?? "—",
    changeCount: duplicate?.changeCount ?? "—",
    addIssue: duplicate?.addIssue ?? "—",
  },
];

export const prepareCssCount = GetCssCounts => {
  const { PageHasRedirectedCss, cssBroken, cssRedirects, httpsPageLinksToHttpCss, pageHasBrokenCss } = GetCssCounts || {};
  const tableData = [
    {
      content: "CSS broken",
      count: cssBroken?.count ?? "—",
      flag: "cssBroken",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: cssBroken?.turn ?? false,
      newIssue: cssBroken?.newIssue ?? "—",
      removeIssue: cssBroken?.removeIssue ?? "—",
      changeCount: cssBroken?.changeCount ?? "—",
      addIssue: cssBroken?.addIssue ?? "—",
    },
    {
      content: "Page has broken CSS",
      count: pageHasBrokenCss?.count ?? "—",
      flag: "pageHasBrokenCss",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: pageHasBrokenCss?.turn ?? false,
      newIssue: pageHasBrokenCss?.newIssue ?? "—",
      removeIssue: pageHasBrokenCss?.removeIssue ?? "—",
      changeCount: pageHasBrokenCss?.changeCount ?? "—",
      addIssue: pageHasBrokenCss?.addIssue ?? "—",
    },
    {
      content: "HTTPS page links to HTTP CSS",
      count: httpsPageLinksToHttpCss?.count ?? "—",
      flag: "httpsPageLinksToHttpCss",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: httpsPageLinksToHttpCss?.turn ?? false,
      newIssue: httpsPageLinksToHttpCss?.newIssue ?? "—",
      removeIssue: httpsPageLinksToHttpCss?.removeIssue ?? "—",
      changeCount: httpsPageLinksToHttpCss?.changeCount ?? "—",
      addIssue: httpsPageLinksToHttpCss?.addIssue ?? "—",
    },
    {
      content: "CSS redirects",
      count: cssRedirects?.count,
      flag: "cssRedirects",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: cssRedirects?.turn ?? false,
      newIssue: cssRedirects?.newIssue ?? "—",
      removeIssue: cssRedirects?.removeIssue ?? "—",
      changeCount: cssRedirects?.changeCount ?? "—",
      addIssue: cssRedirects?.addIssue ?? "—",
    },
    {
      content: "Page has redirected CSS",
      count: PageHasRedirectedCss?.count ?? "—",
      flag: "PageHasRedirectedCss",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: PageHasRedirectedCss?.turn ?? false,
      newIssue: PageHasRedirectedCss?.newIssue ?? "—",
      removeIssue: PageHasRedirectedCss?.removeIssue ?? "—",
      changeCount: PageHasRedirectedCss?.changeCount ?? "—",
      addIssue: PageHasRedirectedCss?.addIssue ?? "—",
    },
  ];
  return tableData;
};

export const preparePagesCount = PagesCount => {
  const { external3xxRedirect, external4xx, external5xx } = PagesCount || {};
  const tableData = [
    {
      content: "External 3XX redirect",
      count: external3xxRedirect?.count ?? "—",
      flag: "external3xxRedirect",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: external3xxRedirect?.turn ?? false,
      newIssue: external3xxRedirect?.newIssue ?? "—",
      removeIssue: external3xxRedirect?.removeIssue ?? "—",
      changeCount: external3xxRedirect?.changeCount ?? "—",
      addIssue: external3xxRedirect?.addIssue ?? "—",
    },
    {
      content: "External 4XX",
      count: external4xx?.count ?? "—",
      flag: "external4xx",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: external4xx?.turn ?? false,
      newIssue: external4xx?.newIssue ?? "—",
      removeIssue: external4xx?.removeIssue ?? "—",
      changeCount: external4xx?.changeCount ?? "—",
      addIssue: external4xx?.addIssue ?? "—",
    },
    {
      content: "External 5XX",
      count: external5xx?.count ?? "—",
      flag: "external5xx",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: external5xx?.turn ?? false,
      newIssue: external5xx?.newIssue ?? "—",
      removeIssue: external5xx?.removeIssue ?? "—",
      changeCount: external5xx?.changeCount ?? "—",
      addIssue: external5xx?.addIssue ?? "—",
    },
  ];
  return tableData;
};

export const prepareInternalPagesCount = PagesCount => {
  const tableData = [
    // {
    //   content: "3XX page",
    //   count: PagesCount.internal3xx?.count ?? "—",
    //   flag: "internal3xx",
    //   icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
    //   status: "error",
    //   turnOff: PagesCount.internal3xx?.turn ?? false,
    //   newIssue: PagesCount.internal3xx?.newIssue ?? "—",
    //   removeIssue: PagesCount.internal3xx?.removeIssue ?? "—",
    //   changeCount: PagesCount.internal3xx?.changeCount ?? "—",
    //   addIssue: PagesCount.internal3xx?.addIssue ?? "—",
    // },
    {
      content: "404 page",
      count: PagesCount.internal404?.count ?? "—",
      flag: "internal404",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: PagesCount.internal404?.turn ?? false,
      newIssue: PagesCount.internal404?.newIssue ?? "—",
      removeIssue: PagesCount.internal404?.removeIssue ?? "—",
      changeCount: PagesCount.internal404?.changeCount ?? "—",
      addIssue: PagesCount.internal404?.addIssue ?? "—",
    },
    {
      content: "4XX page",
      count: PagesCount.internal4xx?.count ?? "—",
      flag: "internal4xx",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: PagesCount.internal4xx?.turn ?? false,
      newIssue: PagesCount.internal4xx?.newIssue ?? "—",
      removeIssue: PagesCount.internal4xx?.removeIssue ?? "—",
      changeCount: PagesCount.internal4xx?.changeCount ?? "—",
      addIssue: PagesCount.internal4xx?.addIssue ?? "—",
    },
    {
      content: "500 page",
      count: PagesCount.internal500?.count ?? "—",
      flag: "internal500",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      turnOff: PagesCount.internal500?.turn ?? false,
      status: "error",
      newIssue: PagesCount.internal500?.newIssue ?? "—",
      removeIssue: PagesCount.internal500?.removeIssue ?? "—",
      changeCount: PagesCount.internal500?.changeCount ?? "—",
      addIssue: PagesCount.internal500?.addIssue ?? "—",
    },
    {
      content: "5XX page",
      count: PagesCount.internal5xx?.count ?? "—",
      flag: "internal5xx",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      turnOff: PagesCount.internal5xx?.turn ?? false,
      status: "error",
      newIssue: PagesCount.internal5xx?.newIssue ?? "—",
      removeIssue: PagesCount.internal5xx?.removeIssue ?? "—",
      changeCount: PagesCount.internal5xx?.changeCount ?? "—",
      addIssue: PagesCount.internal5xx?.addIssue ?? "—",
    },
    {
      content: "HTTPS/HTTP mixed content",
      count: PagesCount.mixedContent?.count ?? "—",
      flag: "mixedContent",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      turnOff: PagesCount.mixedContent?.turn ?? false,
      status: "warning",
      newIssue: PagesCount.mixedContent?.newIssue ?? "—",
      removeIssue: PagesCount.mixedContent?.removeIssue ?? "—",
      changeCount: PagesCount.mixedContent?.changeCount ?? "—",
      addIssue: PagesCount.mixedContent?.addIssue ?? "—",
    },
  ];
  return tableData;
};

export const prepareCanonicalIncomingIndexable = ({ indexable }) => {
  const tableData = [
    {
      content: "Orphan page (has no incoming internal links) ",
      count: indexable?.orphanePage?.count ?? "—",
      flag: "orphanePage",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: indexable?.orphanePage?.turn ?? false,
      newIssue: indexable?.orphanePage?.newIssue ?? "—",
      removeIssue: indexable?.orphanePage?.removeIssue ?? "—",
      changeCount: indexable?.orphanePage?.changeCount ?? "—",
      addIssue: indexable?.orphanePage?.addIssue ?? "—",
    },
    {
      content: "Page has links to broken page",
      count: indexable?.linkToBrokenPage?.count ?? "—",
      flag: "linkToBrokenPage",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: indexable?.linkToBrokenPage?.turn ?? false,
      newIssue: indexable?.linkToBrokenPage?.newIssue ?? "—",
      removeIssue: indexable?.linkToBrokenPage?.removeIssue ?? "—",
      changeCount: indexable?.linkToBrokenPage?.changeCount ?? "—",
      addIssue: indexable?.linkToBrokenPage?.addIssue ?? "—",
    },
    {
      content: "Canonical URL has no incoming internal links",
      count: indexable?.canonicalNotIncoming?.count ?? "—",
      flag: "canonicalNotIncoming",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: indexable?.canonicalNotIncoming?.turn ?? false,
      newIssue: indexable?.canonicalNotIncoming?.newIssue ?? "—",
      removeIssue: indexable?.canonicalNotIncoming?.removeIssue ?? "—",
      changeCount: indexable?.canonicalNotIncoming?.changeCount ?? "—",
      addIssue: indexable?.canonicalNotIncoming?.addIssue ?? "—",
    },
    {
      content: "HTTPS page has internal links to HTTP ",
      count: indexable?.httpsTohttpInternal?.count ?? "—",
      flag: "httpsTohttpInternal",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: indexable?.httpsTohttpInternal?.turn ?? false,
      newIssue: indexable?.httpsTohttpInternal?.newIssue ?? "—",
      removeIssue: indexable?.httpsTohttpInternal?.removeIssue ?? "—",
      changeCount: indexable?.httpsTohttpInternal?.changeCount ?? "—",
      addIssue: indexable?.httpsTohttpInternal?.addIssue ?? "—",
    },
    {
      content: "Page has no outgoing internal links ",
      count: indexable?.noOutgoingInternalLinks?.count ?? "—",
      flag: "noOutgoingInternalLinks",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: indexable?.noOutgoingInternalLinks?.turn ?? false,
      newIssue: indexable?.noOutgoingInternalLinks?.newIssue ?? "—",
      removeIssue: indexable?.noOutgoingInternalLinks?.removeIssue ?? "—",
      changeCount: indexable?.noOutgoingInternalLinks?.changeCount ?? "—",
      addIssue: indexable?.noOutgoingInternalLinks?.addIssue ?? "—",
    },
    {
      content: "Page has no outgoing links ",
      count: indexable?.noOutgoingLink?.count ?? "—",
      flag: "noOutgoingLink",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: indexable?.noOutgoingLink?.turn ?? false,
      newIssue: indexable?.noOutgoingLink?.newIssue ?? "—",
      removeIssue: indexable?.noOutgoingLink?.removeIssue ?? "—",
      changeCount: indexable?.noOutgoingLink?.changeCount ?? "—",
      addIssue: indexable?.noOutgoingLink?.addIssue ?? "—",
    },
    {
      content: "Page has links to redirect ",
      count: indexable?.linkToRedirect?.count ?? "—",
      flag: "linkToRedirect",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: indexable?.linkToRedirect?.turn ?? false,
      newIssue: indexable?.linkToRedirect?.newIssue ?? "—",
      removeIssue: indexable?.linkToRedirect?.removeIssue ?? "—",
      changeCount: indexable?.linkToRedirect?.changeCount ?? "—",
      addIssue: indexable?.linkToRedirect?.addIssue ?? "—",
    },
    {
      content: "Page has nofollow incoming internal links only ",
      count: indexable?.nofollowIncomingOnly?.count ?? "—",
      flag: "nofollowIncomingOnly",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: indexable?.nofollowIncomingOnly?.turn ?? false,
      newIssue: indexable?.nofollowIncomingOnly?.newIssue ?? "—",
      removeIssue: indexable?.nofollowIncomingOnly?.removeIssue ?? "—",
      changeCount: indexable?.nofollowIncomingOnly?.changeCount ?? "—",
      addIssue: indexable?.nofollowIncomingOnly?.addIssue ?? "—",
    },
    {
      content: "Redirected page has no incoming internal links ",
      count: indexable?.redirectPageHasNoIncoming?.count ?? "—",
      flag: "redirectPageHasNoIncoming",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: indexable?.redirectPageHasNoIncoming?.turn ?? false,
      newIssue: indexable?.redirectPageHasNoIncoming?.newIssue ?? "—",
      removeIssue: indexable?.redirectPageHasNoIncoming?.removeIssue ?? "—",
      changeCount: indexable?.redirectPageHasNoIncoming?.changeCount ?? "—",
      addIssue: indexable?.redirectPageHasNoIncoming?.addIssue ?? "—",
    },
    {
      content: "Page has only one dofollow incoming internal link ",
      count: indexable?.onlyOneDofollow?.count ?? "—",
      flag: "onlyOneDofollow",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: indexable?.onlyOneDofollow?.turn ?? false,
      newIssue: indexable?.onlyOneDofollow?.newIssue ?? "—",
      removeIssue: indexable?.onlyOneDofollow?.removeIssue ?? "—",
      changeCount: indexable?.onlyOneDofollow?.changeCount ?? "—",
      addIssue: indexable?.onlyOneDofollow?.addIssue ?? "—",
    },
    {
      content: "HTTP page has internal links to HTTPS ",
      count: indexable?.httpTohttpsInternal?.count ?? "—",
      flag: "httpTohttpsInternal",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: indexable?.httpTohttpsInternal?.turn ?? false,
      newIssue: indexable?.httpTohttpsInternal?.newIssue ?? "—",
      removeIssue: indexable?.httpTohttpsInternal?.removeIssue ?? "—",
      changeCount: indexable?.httpTohttpsInternal?.changeCount ?? "—",
      addIssue: indexable?.httpTohttpsInternal?.addIssue ?? "—",
    },
    {
      content: "Page has nofollow and dofollow incoming internal links ",
      count: indexable?.dofollowAndNofollow?.count ?? "—",
      flag: "dofollowAndNofollow",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: indexable?.dofollowAndNofollow?.turn ?? false,
      newIssue: indexable?.dofollowAndNofollow?.newIssue ?? "—",
      removeIssue: indexable?.dofollowAndNofollow?.removeIssue ?? "—",
      changeCount: indexable?.dofollowAndNofollow?.changeCount ?? "—",
      addIssue: indexable?.dofollowAndNofollow?.addIssue ?? "—",
    },
    {
      content: "Page has nofollow outgoing internal links ",
      count: indexable?.noFollowOutgoingInternalLinks?.count ?? "—",
      flag: "noFollowOutgoingInternalLinks",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: indexable?.noFollowOutgoingInternalLinks?.turn ?? false,
      newIssue: indexable?.noFollowOutgoingInternalLinks?.newIssue ?? "—",
      removeIssue: indexable?.noFollowOutgoingInternalLinks?.removeIssue ?? "—",
      changeCount: indexable?.noFollowOutgoingInternalLinks?.changeCount ?? "—",
      addIssue: indexable?.noFollowOutgoingInternalLinks?.addIssue ?? "—",
    },
  ];
  return tableData;
};
export const prepareCanonicalIncomingNonIndexable = ({ notIndexable }) => {
  const tableData = [
    {
      content: "Orphan page (has no incoming internal links) ",
      count: notIndexable?.orphanePage?.count ?? "—",
      flag: "orphanePage",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: notIndexable?.orphanePage?.turn ?? false,
      newIssue: notIndexable?.orphanePage?.newIssue ?? "—",
      removeIssue: notIndexable?.orphanePage?.removeIssue ?? "—",
      changeCount: notIndexable?.orphanePage?.changeCount ?? "—",
      addIssue: notIndexable?.orphanePage?.addIssue ?? "—",
    },
    {
      content: "Page has links to broken page",
      count: notIndexable?.linkToBrokenPage?.count ?? "—",
      flag: "linkToBrokenPage",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: notIndexable?.linkToBrokenPage?.turn ?? false,
      newIssue: notIndexable?.linkToBrokenPage?.newIssue ?? "—",
      removeIssue: notIndexable?.linkToBrokenPage?.removeIssue ?? "—",
      changeCount: notIndexable?.linkToBrokenPage?.changeCount ?? "—",
      addIssue: notIndexable?.linkToBrokenPage?.addIssue ?? "—",
    },
    // { content: "Canonical URL has no incoming internal links", count: notIndexable?.canonicalNotIncoming, flag: "cononicalNoIncomingIntrnLinks" },
    {
      content: "HTTPS page has internal links to HTTP ",
      count: notIndexable?.httpsTohttpInternal?.count ?? "—",
      flag: "httpsTohttpInternal",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: notIndexable?.httpsTohttpInternal?.turn ?? false,
      newIssue: notIndexable?.httpsTohttpInternal?.newIssue ?? "—",
      removeIssue: notIndexable?.httpsTohttpInternal?.removeIssue ?? "—",
      changeCount: notIndexable?.httpsTohttpInternal?.changeCount ?? "—",
      addIssue: notIndexable?.httpsTohttpInternal?.addIssue ?? "—",
    },
    {
      content: "Page has no outgoing internal links ",
      count: notIndexable?.noOutgoingInternalLinks?.count ?? "—",
      flag: "noOutgoingInternalLinks",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: notIndexable?.noOutgoingInternalLinks?.turn ?? false,
      newIssue: notIndexable?.noOutgoingInternalLinks?.newIssue ?? "—",
      removeIssue: notIndexable?.noOutgoingInternalLinks?.removeIssue ?? "—",
      changeCount: notIndexable?.noOutgoingInternalLinks?.changeCount ?? "—",
      addIssue: notIndexable?.noOutgoingInternalLinks?.addIssue ?? "—",
    },
    {
      content: "Page has no outgoing links ",
      count: notIndexable?.noOutgoingLink?.count ?? "—",
      flag: "noOutgoingLink",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: notIndexable?.noOutgoingLink?.turn ?? false,
      newIssue: notIndexable?.noOutgoingLink?.newIssue ?? "—",
      removeIssue: notIndexable?.noOutgoingLink?.removeIssue ?? "—",
      changeCount: notIndexable?.noOutgoingLink?.changeCount ?? "—",
      addIssue: notIndexable?.noOutgoingLink?.addIssue ?? "—",
    },
    {
      content: "Page has links to redirect ",
      count: notIndexable?.linkToRedirect?.count ?? "—",
      flag: "linkToRedirect",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: notIndexable?.linkToRedirect?.turn ?? false,
      newIssue: notIndexable?.linkToRedirect?.newIssue ?? "—",
      removeIssue: notIndexable?.linkToRedirect?.removeIssue ?? "—",
      changeCount: notIndexable?.linkToRedirect?.changeCount ?? "—",
      addIssue: notIndexable?.linkToRedirect?.addIssue ?? "—",
    },
    {
      content: "Page has nofollow incoming internal links only ",
      count: notIndexable?.nofollowIncomingOnly?.count ?? "—",
      flag: "nofollowIncomingOnly",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: notIndexable?.nofollowIncomingOnly?.turn ?? false,
      newIssue: notIndexable?.nofollowIncomingOnly?.newIssue ?? "—",
      removeIssue: notIndexable?.nofollowIncomingOnly?.removeIssue ?? "—",
      changeCount: notIndexable?.nofollowIncomingOnly?.changeCount ?? "—",
      addIssue: notIndexable?.nofollowIncomingOnly?.addIssue ?? "—",
    },
    {
      content: "Redirected page has no incoming internal links ",
      count: notIndexable?.redirectPageHasNoIncoming?.count ?? "—",
      flag: "redirectPageHasNoIncoming",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: notIndexable?.redirectPageHasNoIncoming?.turn ?? false,
      newIssue: notIndexable?.redirectPageHasNoIncoming?.newIssue ?? "—",
      removeIssue: notIndexable?.redirectPageHasNoIncoming?.removeIssue ?? "—",
      changeCount: notIndexable?.redirectPageHasNoIncoming?.changeCount ?? "—",
      addIssue: notIndexable?.redirectPageHasNoIncoming?.addIssue ?? "—",
    },
    {
      content: "Page has only one dofollow incoming internal link ",
      count: notIndexable?.onlyOneDofollow?.count ?? "—",
      flag: "onlyOneDofollow",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: notIndexable?.onlyOneDofollow?.turn ?? false,
      newIssue: notIndexable?.onlyOneDofollow?.newIssue ?? "—",
      removeIssue: notIndexable?.onlyOneDofollow?.removeIssue ?? "—",
      changeCount: notIndexable?.onlyOneDofollow?.changeCount ?? "—",
      addIssue: notIndexable?.onlyOneDofollow?.addIssue ?? "—",
    },
    {
      content: "HTTP page has internal links to HTTPS ",
      count: notIndexable?.httpTohttpsInternal?.count ?? "—",
      flag: "httpTohttpsInternal",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: notIndexable?.httpTohttpsInternal?.turn ?? false,
      newIssue: notIndexable?.httpTohttpsInternal?.newIssue ?? "—",
      removeIssue: notIndexable?.httpTohttpsInternal?.removeIssue ?? "—",
      changeCount: notIndexable?.httpTohttpsInternal?.changeCount ?? "—",
      addIssue: notIndexable?.httpTohttpsInternal?.addIssue ?? "—",
    },
    {
      content: "Page has nofollow and dofollow incoming internal links ",
      count: notIndexable?.dofollowAndNofollow?.count ?? "—",
      flag: "dofollowAndNofollow",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: notIndexable?.dofollowAndNofollow?.turn ?? false,
      newIssue: notIndexable?.dofollowAndNofollow?.newIssue ?? "—",
      removeIssue: notIndexable?.dofollowAndNofollow?.removeIssue ?? "—",
      changeCount: notIndexable?.dofollowAndNofollow?.changeCount ?? "—",
      addIssue: notIndexable?.dofollowAndNofollow?.addIssue ?? "—",
    },
    {
      content: "Page has nofollow outgoing internal links ",
      count: notIndexable?.noFollowOutgoingInternalLinks?.count ?? "—",
      flag: "noFollowOutgoingInternalLinks",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: notIndexable?.noFollowOutgoingInternalLinks?.turn ?? false,
      newIssue: notIndexable?.noFollowOutgoingInternalLinks?.newIssue ?? "—",
      removeIssue: notIndexable?.noFollowOutgoingInternalLinks?.removeIssue ?? "—",
      changeCount: notIndexable?.noFollowOutgoingInternalLinks?.changeCount ?? "—",
      addIssue: notIndexable?.noFollowOutgoingInternalLinks?.addIssue ?? "—",
    },
  ];
  return tableData;
};
export const prepareIndexableContentCount = ({ indexable }) => {
  const tableData = [
    {
      content: "Multiple meta description tags",
      count: indexable?.multipleMetaDesc?.count ?? "—",
      flag: "multipleMetaDesc",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: indexable?.multipleMetaDesc?.turn ?? false,
      newIssue: indexable?.multipleMetaDesc?.newIssue ?? "—",
      removeIssue: indexable?.multipleMetaDesc?.removeIssue ?? "—",
      changeCount: indexable?.multipleMetaDesc?.changeCount ?? "—",
      addIssue: indexable?.multipleMetaDesc?.addIssue ?? "—",
    },
    {
      content: "Multiple title tags",
      count: indexable?.multipleTitleTag?.count ?? "—",
      flag: "multipleTitleTag",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: indexable?.multipleTitleTag?.turn ?? false,
      newIssue: indexable?.multipleTitleTag?.newIssue ?? "—",
      removeIssue: indexable?.multipleTitleTag?.removeIssue ?? "—",
      changeCount: indexable?.multipleTitleTag?.changeCount ?? "—",
      addIssue: indexable?.multipleTitleTag?.addIssue ?? "—",
    },
    {
      content: "Title tag missing or empty",
      count: indexable?.titleTagMissing?.count ?? "—",
      flag: "titleTagMissing",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: indexable?.titleTagMissing?.turn ?? false,
      newIssue: indexable?.titleTagMissing?.newIssue ?? "—",
      removeIssue: indexable?.titleTagMissing?.removeIssue ?? "—",
      changeCount: indexable?.titleTagMissing?.changeCount ?? "—",
      addIssue: indexable?.titleTagMissing?.addIssue ?? "—",
    },
    {
      content: "Meta description tag missing or empty",
      count: indexable?.metaDescMissing?.count ?? "—",
      flag: "metaDescMissing",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: indexable?.metaDescMissing?.turn ?? false,
      newIssue: indexable?.metaDescMissing?.newIssue ?? "—",
      removeIssue: indexable?.metaDescMissing?.removeIssue ?? "—",
      changeCount: indexable?.metaDescMissing?.changeCount ?? "—",
      addIssue: indexable?.metaDescMissing?.addIssue ?? "—",
    },
    {
      content: "Title too short",
      count: indexable?.titleSort?.count ?? "—",
      flag: "titleSort",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: indexable?.titleSort?.turn ?? false,
      newIssue: indexable?.titleSort?.newIssue ?? "—",
      removeIssue: indexable?.titleSort?.removeIssue ?? "—",
      changeCount: indexable?.titleSort?.changeCount ?? "—",
      addIssue: indexable?.titleSort?.addIssue ?? "—",
    },
    {
      content: "Low word count",
      count: indexable?.lowWordCount?.count ?? "—",
      flag: "lowWordCount",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: indexable?.lowWordCount?.turn ?? false,
      newIssue: indexable?.lowWordCount?.newIssue ?? "—",
      removeIssue: indexable?.lowWordCount?.removeIssue ?? "—",
      changeCount: indexable?.lowWordCount?.changeCount ?? "—",
      addIssue: indexable?.lowWordCount?.addIssue ?? "—",
    },
    {
      content: "H1 tag missing or empty",
      count: indexable?.h1TagMissing?.count ?? "—",
      flag: "h1TagMissing",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: indexable?.h1TagMissing?.turn ?? false,
      newIssue: indexable?.h1TagMissing?.newIssue ?? "—",
      removeIssue: indexable?.h1TagMissing?.removeIssue ?? "—",
      changeCount: indexable?.h1TagMissing?.changeCount ?? "—",
      addIssue: indexable?.h1TagMissing?.addIssue ?? "—",
    },
    {
      content: "Meta description too long",
      count: indexable?.metaDescLong?.count ?? "—",
      flag: "metaDescLong",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: indexable?.metaDescLong?.turn ?? false,
      newIssue: indexable?.metaDescLong?.newIssue ?? "—",
      removeIssue: indexable?.metaDescLong?.removeIssue ?? "—",
      changeCount: indexable?.metaDescLong?.changeCount ?? "—",
      addIssue: indexable?.metaDescLong?.addIssue ?? "—",
    },
    {
      content: "Meta description too short",
      count: indexable?.metaDescSort?.count ?? "—",
      flag: "metaDescSort",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: indexable?.metaDescSort?.turn ?? false,
      newIssue: indexable?.metaDescSort?.newIssue ?? "—",
      removeIssue: indexable?.metaDescSort?.removeIssue ?? "—",
      changeCount: indexable?.metaDescSort?.changeCount ?? "—",
      addIssue: indexable?.metaDescSort?.addIssue ?? "—",
    },
    {
      content: "Title too long",
      count: indexable?.titleLong?.count ?? "—",
      flag: "titleLong",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: indexable?.titleLong?.turn ?? false,
      newIssue: indexable?.titleLong?.newIssue ?? "—",
      removeIssue: indexable?.titleLong?.removeIssue ?? "—",
      changeCount: indexable?.titleLong?.changeCount ?? "—",
      addIssue: indexable?.titleLong?.addIssue ?? "—",
    },
    {
      content: "Multiple H1 tags",
      count: indexable?.multipleH1Tags?.count ?? "—",
      flag: "multipleH1Tags",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: indexable?.multipleH1Tags?.turn ?? false,
      newIssue: indexable?.multipleH1Tags?.newIssue ?? "—",
      removeIssue: indexable?.multipleH1Tags?.removeIssue ?? "—",
      changeCount: indexable?.multipleH1Tags?.changeCount ?? "—",
      addIssue: indexable?.multipleH1Tags?.addIssue ?? "—",
    },
  ];
  return tableData;
};

export const prepareNonIndexableContentCount = ({ nonIndexable }) => {
  const tableData = [
    {
      content: "Multiple meta description tags",
      count: nonIndexable?.multipleMetaDesc?.count ?? "—",
      flag: "multipleMetaDesc",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: nonIndexable?.multipleMetaDesc?.turn ?? false,
      newIssue: nonIndexable?.multipleMetaDesc?.newIssue ?? "—",
      removeIssue: nonIndexable?.multipleMetaDesc?.removeIssue ?? "—",
      changeCount: nonIndexable?.multipleMetaDesc?.changeCount ?? "—",
      addIssue: nonIndexable?.multipleMetaDesc?.addIssue ?? "—",
    },
    {
      content: "Multiple title tags",
      count: nonIndexable?.multipleTitleTag?.count ?? "—",
      flag: "multipleTitleTag",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: nonIndexable?.multipleTitleTag?.turn ?? false,
      newIssue: nonIndexable?.multipleTitleTag?.newIssue ?? "—",
      removeIssue: nonIndexable?.multipleTitleTag?.removeIssue ?? "—",
      changeCount: nonIndexable?.multipleTitleTag?.changeCount ?? "—",
      addIssue: nonIndexable?.multipleTitleTag?.addIssue ?? "—",
    },
    {
      content: "Title tag missing or empty",
      count: nonIndexable?.titleTagMissing?.count ?? "—",
      flag: "titleTagMissing",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: nonIndexable?.titleTagMissing?.turn ?? false,
      newIssue: nonIndexable?.titleTagMissing?.newIssue ?? "—",
      removeIssue: nonIndexable?.titleTagMissing?.removeIssue ?? "—",
      changeCount: nonIndexable?.titleTagMissing?.changeCount ?? "—",
      addIssue: nonIndexable?.titleTagMissing?.addIssue ?? "—",
    },
    {
      content: "Meta description tag missing or empty",
      count: nonIndexable?.metaDescMissing?.count ?? "—",
      flag: "metaDescMissing",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: nonIndexable?.metaDescMissing?.turn ?? false,
      newIssue: nonIndexable?.metaDescMissing?.newIssue ?? "—",
      removeIssue: nonIndexable?.metaDescMissing?.removeIssue ?? "—",
      changeCount: nonIndexable?.metaDescMissing?.changeCount ?? "—",
      addIssue: nonIndexable?.metaDescMissing?.addIssue ?? "—",
    },
    {
      content: "Title too short",
      count: nonIndexable?.titleSort?.count ?? "—",
      flag: "titleSort",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: nonIndexable?.titleSort?.turn ?? false,
      newIssue: nonIndexable?.titleSort?.newIssue ?? "—",
      removeIssue: nonIndexable?.titleSort?.removeIssue ?? "—",
      changeCount: nonIndexable?.titleSort?.changeCount ?? "—",
      addIssue: nonIndexable?.titleSort?.addIssue ?? "—",
    },
    {
      content: "H1 tag missing or empty",
      count: nonIndexable?.h1TagMissing?.count ?? "—",
      flag: "h1TagMissing",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: nonIndexable?.h1TagMissing?.turn ?? false,
      newIssue: nonIndexable?.h1TagMissing?.newIssue ?? "—",
      removeIssue: nonIndexable?.h1TagMissing?.removeIssue ?? "—",
      changeCount: nonIndexable?.h1TagMissing?.changeCount ?? "—",
      addIssue: nonIndexable?.h1TagMissing?.addIssue ?? "—",
    },
    {
      content: "Low word count",
      count: nonIndexable?.lowWordCount?.count ?? "—",
      flag: "lowWordCount",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: nonIndexable?.lowWordCount?.turn ?? false,
      newIssue: nonIndexable?.lowWordCount?.newIssue ?? "—",
      removeIssue: nonIndexable?.lowWordCount?.removeIssue ?? "—",
      changeCount: nonIndexable?.lowWordCount?.changeCount ?? "—",
      addIssue: nonIndexable?.lowWordCount?.addIssue ?? "—",
    },
    {
      content: "Meta description too long",
      count: nonIndexable?.metaDescLong?.count ?? "—",
      flag: "metaDescLong",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: nonIndexable?.metaDescLong?.turn ?? false,
      newIssue: nonIndexable?.metaDescLong?.newIssue ?? "—",
      removeIssue: nonIndexable?.metaDescLong?.removeIssue ?? "—",
      changeCount: nonIndexable?.metaDescLong?.changeCount ?? "—",
      addIssue: nonIndexable?.metaDescLong?.addIssue ?? "—",
    },
    {
      content: "Meta description too short",
      count: nonIndexable?.metaDescSort?.count ?? "—",
      flag: "metaDescSort",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: nonIndexable?.metaDescSort?.turn ?? false,
      newIssue: nonIndexable?.metaDescSort?.newIssue ?? "—",
      removeIssue: nonIndexable?.metaDescSort?.removeIssue ?? "—",
      changeCount: nonIndexable?.metaDescSort?.changeCount ?? "—",
      addIssue: nonIndexable?.metaDescSort?.addIssue ?? "—",
    },
    {
      content: "Title too long",
      count: nonIndexable?.titleLong?.count ?? "—",
      flag: "titleLong",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: nonIndexable?.titleLong?.turn ?? false,
      newIssue: nonIndexable?.titleLong?.newIssue ?? "—",
      removeIssue: nonIndexable?.titleLong?.removeIssue ?? "—",
      changeCount: nonIndexable?.titleLong?.changeCount ?? "—",
      addIssue: nonIndexable?.titleLong?.addIssue ?? "—",
    },
    {
      content: "Multiple H1 tags",
      count: nonIndexable?.multipleH1Tags?.count ?? "—",
      flag: "multipleH1Tags",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: nonIndexable?.multipleH1Tags?.turn,
      newIssue: nonIndexable?.multipleH1Tags?.newIssue ?? "—",
      removeIssue: nonIndexable?.multipleH1Tags?.removeIssue ?? "—",
      changeCount: nonIndexable?.multipleH1Tags?.changeCount ?? "—",
      addIssue: nonIndexable?.multipleH1Tags?.addIssue ?? "—",
    },
  ];
  return tableData;
};

export const prepearRedirectcount = redirectData => {
  const tableData = [
    {
      content: "Broken Redirect",
      count: redirectData?.brokenRedirect?.count ?? "—",
      flag: "brokenRedirect",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: redirectData?.brokenRedirect?.turn,
      newIssue: redirectData?.brokenRedirect?.newIssue ?? "—",
      removeIssue: redirectData?.brokenRedirect?.removeIssue ?? "—",
      changeCount: redirectData?.brokenRedirect?.changeCount ?? "—",
      addIssue: redirectData?.brokenRedirect?.addIssue ?? "—",
    },
    {
      content: "Redirect chain too long",
      count: redirectData?.redirectChainTooLong?.count ?? "—",
      flag: "redirectChainTooLong",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: redirectData?.redirectChainTooLong?.turn,
      newIssue: redirectData?.redirectChainTooLong?.newIssue ?? "—",
      removeIssue: redirectData?.redirectChainTooLong?.removeIssue ?? "—",
      changeCount: redirectData?.redirectChainTooLong?.changeCount ?? "—",
      addIssue: redirectData?.redirectChainTooLong?.addIssue ?? "—",
    },
    {
      content: "Redirect loop",
      count: redirectData?.redirectLoop?.count ?? "—",
      flag: "redirectLoop",
      icon: <ReportProblemIcon sx={ReportProblemIconCss} />,
      status: "error",
      turnOff: redirectData?.redirectLoop?.turn,
      newIssue: redirectData?.redirectLoop?.newIssue ?? "—",
      removeIssue: redirectData?.redirectLoop?.removeIssue ?? "—",
      changeCount: redirectData?.redirectLoop?.changeCount ?? "—",
      addIssue: redirectData?.redirectLoop?.addIssue ?? "—",
    },
    {
      content: "3XX redirect",
      count: redirectData?.redirect3xx?.count ?? "—",
      flag: "redirect3xx",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: redirectData?.redirect3xx?.turn,
      newIssue: redirectData?.redirect3xx?.newIssue ?? "—",
      removeIssue: redirectData?.redirect3xx?.removeIssue ?? "—",
      changeCount: redirectData?.redirect3xx?.changeCount ?? "—",
      addIssue: redirectData?.redirect3xx?.addIssue ?? "—",
    },
    {
      content: "302 redirect",
      count: redirectData?.redirect302?.count ?? "—",
      flag: "redirect302",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: redirectData?.redirect302?.turn,
      newIssue: redirectData?.redirect302?.newIssue ?? "—",
      removeIssue: redirectData?.redirect302?.removeIssue ?? "—",
      changeCount: redirectData?.redirect302?.changeCount ?? "—",
      addIssue: redirectData?.redirect302?.addIssue ?? "—",
    },
    {
      content: "HTTPS to HTTP redirect",
      count: redirectData?.httpsToHttpRedirect?.count ?? "—",
      flag: "httpsToHttpRedirect",
      icon: <InfoIcon sx={WarningErrorWrapper} />,
      status: "warning",
      turnOff: redirectData?.httpsToHttpRedirect?.turn,
      newIssue: redirectData?.httpsToHttpRedirect?.newIssue ?? "—",
      removeIssue: redirectData?.httpsToHttpRedirect?.removeIssue ?? "—",
      changeCount: redirectData?.httpsToHttpRedirect?.changeCount ?? "—",
      addIssue: redirectData?.httpsToHttpRedirect?.addIssue ?? "—",
    },
    {
      content: "HTTP to HTTPS redirect",
      count: redirectData?.httpToHttpsRedirect?.count ?? "—",
      flag: "httpToHttpsRedirect",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: redirectData?.httpToHttpsRedirect?.turn,
      newIssue: redirectData?.httpToHttpsRedirect?.newIssue ?? "—",
      removeIssue: redirectData?.httpToHttpsRedirect?.removeIssue ?? "—",
      changeCount: redirectData?.httpToHttpsRedirect?.changeCount ?? "—",
      addIssue: redirectData?.httpToHttpsRedirect?.addIssue ?? "—",
    },
    {
      content: "Redirect chain",
      count: redirectData?.redirectChain?.count ?? "—",
      flag: "redirectChain",
      icon: <InfoIcon sx={InfoErrorWrapper} />,
      status: "info",
      turnOff: redirectData?.redirectChain?.turn,
      newIssue: redirectData?.redirectChain?.newIssue ?? "—",
      removeIssue: redirectData?.redirectChain?.removeIssue ?? "—",
      changeCount: redirectData?.redirectChain?.changeCount ?? "—",
      addIssue: redirectData?.redirectChain?.addIssue ?? "—",
    },
  ];
  return tableData;
};
