import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import BackButton from "../BackButton/BackButton";
import CustomSelect from "../forms/theme-elements/CustomSelect";
import { CreateScrapyReport, PageSpeedCreate } from "../../hooks/scrapyData";
import { usePopup } from "../../context/commonPop";
import { popupType } from "../../common/constant";
import ComfirmationPopup from "../../common/comfirmationPopup";

export function formatDate(inputDate) {
  const date = new Date(inputDate);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  const formattedDate = `${day} ${month} ${year} ${formattedHours}:${minutes?.toString().padStart(2, "0")} ${ampm}`;

  return formattedDate;
}

function InnerHeader({ listData, setReportTime }) {
  const { allSession, website, isCrawlProgress, projectName } = (Array.isArray(listData) && listData.length && listData[0]) || {};
  const domain = website?.replace(/^https?:\/\//i, "").split("/")[0];
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [loader, setLoader] = useState(false);
  const { createScrapy } = CreateScrapyReport();
  const { CreatePageSpeed } = PageSpeedCreate();
  const { customPopupModel } = usePopup();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const handleChange = event => {
    setValue(event.target.value);
    const selectedItem = allSession.find(item => item.sessionId === event.target.value) || { sessionId: "", createAt: "" };
    // Format and store the date in another state
    const newFormattedDate = formatDate(selectedItem.createAt);
    setReportTime(newFormattedDate);
    const newUrl = `/project-detail/${event.target.value}?show=${searchParams.get("show")}&origin=${searchParams.get("origin")}`;

    navigate(newUrl);
  };

  useEffect(() => {
    if (params.sessionId) {
      setValue(params.sessionId);
    }
  }, [params.sessionId]);

  useEffect(() => {
    if (Array.isArray(allSession) && allSession.length) {
      const firstReportTime = allSession?.[0]?.createAt;
      const firstReportTimeInFormat = formatDate(firstReportTime);
      setReportTime(firstReportTimeInFormat);
    }
  }, [allSession]);

  const handleClick = async () => {
    setLoader(true);
    const item = {
      website,
      id: searchParams.get("origin"),
    };
    try {
      const scrapydata = await createScrapy(item);
      if (scrapydata && scrapydata.data && scrapydata.data.ScrapUrl && scrapydata.data.ScrapUrl.sessionId) {
        const { sessionId, authorId } = scrapydata.data.ScrapUrl;
        customPopupModel({
          type: popupType.success,
          contentFunc: <Typography component="p">Data collection is in progress. The results will be displayed on the dashboard soon.</Typography>,
        });
        await CreatePageSpeed(authorId, searchParams.get("origin"), website, sessionId);
        setLoader(false);
      } else if (scrapydata && scrapydata.errors) {
        customPopupModel({
          type: popupType.failed,
          contentFunc: <Typography component="p">{scrapydata.errors || "something went wrong"}</Typography>,
        });
        setLoader(false);
      } else {
        customPopupModel({
          type: popupType.failed,
          contentFunc: <Typography component="p">Your crawl limit is reached.</Typography>,
        });
        setLoader(false);
      }
    } catch (error) {
      console.error("Error during scraping:", error);
      customPopupModel({
        type: popupType.failed,
        contentFunc: <Typography component="p">An error occurred during scraping.</Typography>,
      });
      setLoader(false);
    }
  };
  const handleNewCrawlBtn = () => {
    setLoader(true);
    setOpen(true);
  };
  const handleClosePopup = () => {
    setOpen(false);
    setLoader(false);
  };
  const websiteProject = {
    mb: "15px",
    "& a": {
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: "13px",
    },
    "& span svg": {
      height: "12px",
      color: theme.palette.primary.main,
      fontWeight: 600,
      width: "15px",
    },
  };
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: "center", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "15px" }}>
          <BackButton />
          {website && domain && projectName && (
            <Box sx={websiteProject}>
              <Typography component="p" sx={{ color: theme.palette.text.primary, fontWeight: 600, fontSize: "15px" }}>
                {projectName}
              </Typography>
              <Typography sx={{ mt: "-5px" }} component="p">
                <Typography component="a" href={website} target="_blank">
                  {domain}
                  <Typography component="span">
                    <OpenInNewIcon />
                  </Typography>
                </Typography>
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            "& button": {
              padding: "3px 8px",
            },
          }}
        >
          <Box sx={{ m: 1, marginBottom: "15px", "& button": { backgroundColor: theme.palette.primary.main, color: "#fff" } }}>
            <Button onClick={() => navigate(`/crawl-progress/${params.sessionId}`, { state: { projectName } })}>Crawl Report</Button>
          </Box>
          <Box sx={{ "& button": { backgroundColor: theme.palette.primary.main, color: "#fff" } }}>
            <Button
              disabled={!website || isCrawlProgress || loader}
              onClick={handleNewCrawlBtn}
              sx={{ m: 1, marginBottom: "15px", minWidth: "86.5px", "&.Mui-disabled": { opacity: "0.2", color: "#ffffff" }, "& svg": { color: "#ffffff" } }}
            >
              {loader ? <CircularProgress size={24.5} /> : "New Crawl"}
            </Button>
          </Box>
          <FormControl sx={{ m: 1, minWidth: 120, marginBottom: "15px" }}>
            <InputLabel id="demo-simple-select-label">Select Report Date</InputLabel>
            <CustomSelect labelId="demo-simple-select-label" label="Select Report Date" id="month-dd" size="small" onChange={handleChange} value={value}>
              {Array.isArray(allSession) &&
                allSession.length > 0 &&
                allSession.map(item => (
                  <MenuItem className="menuitemlist" value={item?.sessionId} key={item.sessionId}>
                    {formatDate(item?.createAt)}
                  </MenuItem>
                ))}
            </CustomSelect>
          </FormControl>
        </Box>
      </Box>
      <ComfirmationPopup open={open} handleClosePopup={handleClosePopup} msg="Are you sure you want to generate a new report?" handleFunc={handleClick} sucessBtnName="Genrate" />
    </>
  );
}

export default InnerHeader;
