import { Box, Container, Grid, styled } from "@mui/material";
import { ErrorAlert } from "../components/ErrorAlert";
import BackButton from "../components/BackButton/BackButton";
const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  padding: "30px 0",
}));
export function Page404() {
  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={12}>
              <BackButton />
              <ErrorAlert errorMessage="404" />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}
