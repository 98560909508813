/* eslint-disable */
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { Box, Card, Container, Grid, ToggleButton, ToggleButtonGroup, Typography, styled, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { lazy, useMemo, useRef, useState } from "react";
import { useAllUserGraphValue, useAllUserTableDetails } from "../../hooks/user";
import MainLoader from "../MainLoader/MainLoader";
import { DatePickerRangeComponent } from "./DatePickerRange";

const Chart = lazy(() => import("react-apexcharts"), { ssr: false });
const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .MainHeadingWrapper": {
    "& .MainHeading": { fontSize: "18px", fontWeight: "600" },
  },
}));
const BoxStyled = styled(Box)(() => ({
  padding: "30px",
  transition: "0.1s ease-in",
  cursor: "pointer",
  color: "inherit",
  textAlign: "center",
  borderRadius: "4px",
  "&:hover": {
    transform: "scale(1.03)",
  },
}));
const BoxMainWrapper = styled(Box)(({ theme }) => ({
  "& .datagridnopage": {
    // borderColor: "rgba(251, 252, 253, 1)",
    border: "2px solid" + theme.palette.divider,

    // "& .datagridnopage .MuiDataGrid-footerContainer": {
    //   display: "none",
    // },
    "& .MuiDataGrid-main": {
      "& .MuiDataGrid-columnHeaders": {
        borderColor: theme.palette.divider,
        borderWidth: "2px",
        "& .MuiDataGrid-columnSeparator": {
          color: theme.palette.text.primary + "26",
          visibility: "visible",
          // opacity: "0.6 !important",
        },
        "& .MuiDataGrid-columnHeadersInner": {
          width: "100%",
          '& div[role="row"]': {
            width: "100%",
            "& .MuiDataGrid-columnHeader": {
              flex: 1,
              width: "auto !important",
              maxWidth: "unset !important",
              outline: "none !important",
              "&:first-child": { maxWidth: "100px !important", "& .MuiDataGrid-columnHeaderTitleContainer": { justifyContent: "left" } },
              "& .MuiDataGrid-columnHeaderTitleContainer": { justifyContent: "start" },
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "14px",
            color: theme.palette.text.primary,
            fontWeight: 600,
          },
        },
      },
      "& .MuiDataGrid-virtualScroller": {
        // minHeight: "350px",
        "&::-webkit-scrollbar-track": {
          // WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
          borderRadius: "10px",
          backgroundColor: theme.palette.text.primary + "0d",
        },
        "&::-webkit-scrollbar": { width: "6px", backgroundColor: "transparent" },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: theme.palette.primary.main,
        },
        "& .MuiDataGrid-virtualScrollerContent": {
          height: "470px !important",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              boxShadow: "inset 0px 1px " + theme.palette.divider,
              minHeight: "70px !important",
              border: "none",
            },
            width: "100%",
            '& div[role="row"]': {
              width: "100%",
              cursor: "pointer",
              "& .MuiDataGrid-cell": {
                minHeight: "70px !important",
                flex: 1,
                width: "auto !important",
                maxWidth: "unset !important",
                justifyContent: "start",
                borderColor: theme.palette.divider,
                "&:focus-within": { outline: "none" },
                "&:first-child": { maxWidth: "100px !important", justifyContent: "left" },
                "&:last-child": {
                  display: "none",
                },
              },
              "& .MuiDataGrid-cellContent": {
                color: theme.palette.text.primary,
                fontWeight: 600,
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-footerContainer": {
      borderColor: theme.palette.divider,
      "& .MuiTablePagination-toolbar": {
        "& > p": { marginBottom: "0" },
      },
    },
  },
  "& h3": {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "20px",
    color: theme.palette.text.primary,
  },
}));
// const DatePickerStyleWrapper = {
//   justifyContent: "flex-end",
//   flexDirection: "row",
//   gap: "15px",
//   paddingTop: "0",
//   "@media screen and (max-width: 500px)": {
//     flexDirection: "column",
//   },
//   "& .MuiFormControl-root.MuiTextField-root": {
//     minWidth: "500px",
//     "@media screen and (max-width: 821px)": {
//       minWidth: "auto",
//       flex: 1,
//     },
//   },
//   "& .FetchBtn": { marginTop: "0" },
// };

function SignupUser() {
  const [isFetchDate, setIsFetchDate] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [toggleValue, setToggeleValue] = useState("signup");
  const { allUserData, loading, userCounts, getUser, setIsToggle, isToggle, loginCounts, updateAtPage, createAtPage, paginationDetail, setCurrentLoginPage } =
    useAllUserTableDetails(startDate, endDate);
  const theme = useTheme();
  const { usersData, getGraphData } = useAllUserGraphValue(startDate, endDate);
  const customizer = useSelector(state => state.customizer);
  const borderColor = theme.palette.divider;

  const apiRef = useGridApiRef();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 30,
  });

  // Memoize the data for pagination metadata
  const paginationMetaRef = useRef();
  const paginationMeta = useMemo(() => {
    if (paginationDetail?.totalPage !== undefined && paginationMetaRef.current?.totalPage !== paginationDetail?.totalPage) {
      paginationMetaRef.current = { ...paginationDetail, totalPage: paginationDetail?.totalPage };
    }
    return paginationMetaRef.current;
  }, [paginationDetail]);

  // Function to handle pagination change
  const handlePageChange = newPage => {
    if (isToggle) {
      setCurrentLoginPage(newPage + 1); // Adjust to 1-based page number for the API
    } else {
      setCurrentCreatePage(newPage + 1); // Adjust to 1-based page number for the API
    }

    getUser(); // Fetch data for the new page
  };

  const handleToggleValueChange = e => {
    setToggeleValue(e.target.value);
    setIsToggle(!isToggle);
  };
  const handleDateRangeChange = newValue => {
    const [newStart, newEnd] = newValue;
    setStartDate(newStart ? new Date(newStart) : null);
    setEndDate(newEnd ? new Date(newEnd) : null);
    setIsFetchDate(false);
  };
  const handleFatch = () => {
    try {
      getUser();
      getGraphData();
      setIsFetchDate(true);
    } catch (err) {
      console.log(err);
    }
  };

  const filterDataFunc = () =>
    isFetchDate && startDate && endDate
      ? allUserData.filter(ticket => {
          const createdAtDate = new Date(ticket.createdAt);
          return createdAtDate >= startDate && createdAtDate <= endDate;
        })
      : allUserData;

  const renderChart = () => {
    const concatenatedArray = [...usersData.updatedSeriesData, ...usersData.seriesData];
    return (
      <Chart
        height={360}
        options={{
          chart: {
            type: "line",
            zoom: { enabled: true },
            toolbar: { show: false },
          },
          xaxis: {
            type: "category",
            categories: usersData.uniqueDateArray.map(data => data.x), // Set the parsed dates as categories
            tickPlacement: "between",
          },
          yaxis: {
            min: 0,
            max: 24,
            tickAmount: 12,
            labels: {
              formatter: val => `${Math.floor(val)}`,
            },
          },
          tooltip: {
            x: {
              formatter: val => `Date & Time: ${val}`,
            },
            y: {
              formatter: (val, { dataPointIndex }) => {
                const name = concatenatedArray[dataPointIndex].x;
                // return "";
                return `User: ${name}`;
              },
            },
          },
          markers: {
            size: 5,
          },
          dataLabels: {
            enabled: false,
          },
        }}
        series={[
          {
            name: "Created Users",
            data: usersData.seriesData.map(data => data.y), // Make sure data.y exists
          },
          {
            name: "Logged In Users",
            data: usersData.updatedSeriesData.map(data => data.y), // Make sure data.y exists
          },
        ]}
      />
    );
  };

  const columns = [
    { field: "id", headerName: "No." },
    { field: "name", headerName: "Name" },
    { field: "email", headerName: "Email" },
    { field: "role", headerName: "Role" },
    { field: "time", headerName: "Time" },
  ];

  const rows = filterDataFunc()?.map((user, indx) => ({
    id: indx + 1,
    name: user.name,
    email: user.email,
    role: user.role,
    time: isToggle ? new Date(user.updatedAt).toLocaleString() : new Date(user.createdAt).toLocaleString(),
  }));
  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} lg={12}>
              <Card
                sx={{ padding: "30px 45px 24px 40px", border: customizer.isCardShadow ? `1px solid ${borderColor}` : "none", height: "100%" }}
                elevation={customizer.isCardShadow ? 9 : 0}
                variant={!customizer.isCardShadow ? "outlined" : undefined}
              >
                <Grid container spacing={2} mt={0} sx={{ justifyContent: "center", alignItems: "center" }}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box className="MainHeadingWrapper">
                      <Typography component="h5" className="MainHeading">
                        Users Details
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={8}>
                    <DatePickerRangeComponent handleFetchData={handleFatch} handleDateRangeChange={handleDateRangeChange} startDate={startDate} endDate={endDate} />
                  </Grid>
                  {/* <Grid item xs={12} md={6} lg={8}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer sx={DatePickerStyleWrapper} components={["SingleInputDateRangeField"]}>
                        <DateRangePicker
                          slots={{ field: SingleInputDateRangeField }}
                          name="allowedRange"
                          value={[startDate, endDate]}
                          onChange={handleDateRangeChange}
                          renderInput={params => <SingleInputDateRangeField {...params} />}
                          onOpen={() => {
                            setTimeout(() => {
                              textHideFunction();
                            }, 200);
                          }}
                          disableFuture
                        />
                        <Button className="FetchBtn" onClick={() => handleFatch()}>
                          {" "}
                          Fetch
                        </Button>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid> */}
                </Grid>
                {loading ? (
                  <MainLoader message="Loading..." />
                ) : (
                  <Grid container spacing={2} mt={0} sx={{}}>
                    <Grid item xs={12} md={6} lg={3}>
                      <BoxStyled sx={{ backgroundColor: "primary.light", color: "primary.main" }}>
                        <Typography variant="h3">{userCounts}</Typography>
                        <Typography variant="h6">Total Signup Users</Typography>
                      </BoxStyled>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <BoxStyled sx={{ backgroundColor: "secondary.light", color: "secondary.main" }}>
                        <Typography variant="h3">{loginCounts}</Typography>
                        <Typography variant="h6">Total Login Users</Typography>
                      </BoxStyled>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Box className="HeaderWrapper">{renderChart()}</Box>
                    </Grid>
                    <Grid>
                      <ToggleButtonGroup color="primary" value={toggleValue} exclusive onChange={e => handleToggleValueChange(e)} aria-label="Platform">
                        <ToggleButton value="signup">Signup</ToggleButton>
                        <ToggleButton value="login">Login</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <BoxMainWrapper>
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          className="datagridnopage"
                          disableSelectionOnClick
                          hideFooterPagination={paginationDetail?.UsersCount <= 30}
                          apiRef={apiRef}
                          rowCount={paginationDetail?.UsersCount} // Total user count from the hook
                          loading={loading} // Loading state from the hook
                          paginationMode="server"
                          paginationModel={paginationModel}
                          pageSizeOptions={30}
                          onPaginationModelChange={model => {
                            setPaginationModel(model);
                            handlePageChange(model.page); // Handle pagination change
                          }}
                          initialState={{
                            pagination: { paginationModel },
                          }}
                        />
                      </BoxMainWrapper>
                    </Grid>
                  </Grid>
                )}
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default SignupUser;
