import { Box, Card, Grid, styled, Typography, useTheme } from "@mui/material";
import OtherPerformanceChartBox from "./OtherPerformanceChartBox";

const BoxWrapper = styled(Box)(({ theme }) => ({
  "& .AverageMainWrapper": {
    marginBottom: "40px",
    "& h4": {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "30px",
      color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
      marginBottom: "6px",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      color: theme.palette.mode === "dark" ? "#B3B3B3" : "#B3B3B3",
      "& span": {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
        color: theme.palette.primary.main,
      },
    },
  },
}));

function OtherPerformance({ label, description, desktopData, mobileData, heading, projectId }) {
  const theme = useTheme();
  return (
    <BoxWrapper>
      <Card sx={{ padding: "16px 20px 20px 20px", border: `1px solid ${theme.palette.divider}`, boxShadow: "none !important" }}>
        <Grid container spacing={0} mt={0} sx={{ justifyContent: "center" }}>
          <Grid item xs={12} lg={12}>
            <Box className="AverageMainWrapper">
              <Typography component="h4">{label}</Typography>
              {description && <Typography component="p" dangerouslySetInnerHTML={{ __html: description }} />}
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ borderRight: `1px solid ${theme.palette.divider}`, mb: { xs: "30px", lg: "0" } }}>
            <OtherPerformanceChartBox label="Desktop Performance" data={desktopData} title="Desktop" heading={heading} projectId={projectId} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <OtherPerformanceChartBox label="Mobile Performance" data={mobileData} title="Mobile" heading={heading} projectId={projectId} />
          </Grid>
        </Grid>
      </Card>
    </BoxWrapper>
  );
}
export default OtherPerformance;
