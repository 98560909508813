/* eslint-disable */
import { Box, Button, Card, Container, Grid, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useState, useRef } from "react";
import { debounce } from "lodash";
import ImageNotFound from "../Images/image-not-found-icon.png";
import { GetScrapyData } from "../hooks/scrapyData";
import CreateProjectLink from "../components/createProject/CreateProjectLink";
import MainLoader from "../components/MainLoader/MainLoader";
import BackButton from "../components/BackButton/BackButton";
import SearchBar from "../components/common/SearchBar";
import ClockIcon from "../components/SvgIcons/ClockIcon";
import { useSelector } from "react-redux";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .SearchTopBarWrapper": {
    "& .SearchBarWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "15px",
      "@media screen and (max-width:600px)": {
        flexDirection: "column",
      },
      "& button": {
        textTransform: "none",
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        borderRadius: "5px",
        boxShadow: "none",
        padding: "6px 22px",
        "@media screen and (max-width:600px)": {
          width: "100%",
        },
        "&:focus": {
          outline: "0",
        },
      },
    },
  },
  "& .BtnTopWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    "@media screen and (max-width:600px)": {
      flexDirection: "column",
    },
  },
}));

const BoxMainWrapper = styled(Box)(({ theme }) => ({
  "& .DataGridWrapper": {
    "& .datagridnopage": {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: "7px 7px 7px 7px",
      "&.PaginationHide .MuiDataGrid-footerContainer": {
        display: "none",
      },
    },
    "& .MuiDataGrid-main": {
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: theme.palette.mode === "dark" ? "#FFFFFF33" : "#2A3547",
        borderRadius: "7px",
        "& .MuiDataGrid-columnSeparator": {
          color: "#8F8F8F",
          visibility: "visible",
        },
        "& .MuiDataGrid-columnHeadersInner": {
          flex: "1",
          '& div[role="row"]': {
            flex: "1",
            width: "100%",
            "& .MuiDataGrid-columnHeader": {
              outline: "none !important",
              paddingLeft: "18px",
              paddingRight: "18px",
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                "& .MuiDataGrid-iconButtonContainer": {
                  display: "none",
                },
              },
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "14px",
            color: theme.palette.mode === "dark" ? "#ffffff" : "#ffffff",
            fontWeight: 600,
          },
        },
      },
      "& .MuiDataGrid-virtualScroller": {
        overflowX: "hidden",
        "&::-webkit-scrollbar-track": {
          borderRadius: "10px",
          backgroundColor: theme.palette.text.primary + "0d",
        },
        "&::-webkit-scrollbar": { width: "8px", backgroundColor: "transparent" },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: theme.palette.primary.main,
        },
        "& .MuiDataGrid-virtualScrollerContent": {
          // height: "400px !important",
          height: "calc(100vh - 295px) !important",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            width: "100%",
            "@media screen and (max-width:600px)": {
              width: "auto",
            },
            "& .MuiDataGrid-row": {
              boxShadow: "none",
              minHeight: "70px !important",
              width: "100%",
            },
            '& div[role="row"]': {
              cursor: "pointer",
              borderBottom: `2px solid ${theme.palette.divider}`,
              width: "100%",
              "&:last-of-type": { borderWidth: "1px" },
              "& .MuiDataGrid-cell": {
                minHeight: "70px !important",
                flex: 1,
                paddingLeft: "18px",
                paddingRight: "18px",
                "&:last-of-type": {
                  display: "none",
                  "@media screen and (max-width:600px)": {
                    display: "flex",
                  },
                },
                "&.MuiDataGrid-cell--withRenderer": {
                  "&:last-of-type": {
                    display: "flex",
                  },
                },
                "&:focus-within": { outline: "none" },
                "& .MuiDataGrid-cellContent": {
                  color: theme.palette.text.primary,
                  fontWeight: 600,
                },
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-footerContainer": {
      justifyContent: "center",
      "& .MuiTablePagination-root": {
        "& .MuiToolbar-root": {
          "& .MuiTablePagination-selectLabel": {
            marginBottom: "0",
          },
          "& .MuiTablePagination-displayedRows": {
            marginBottom: "0",
          },
        },
      },
    },
  },
}));

const rows = [
  // { avatar: { screenshot }, id: "0" },
  {
    Projects: {
      name: "Jon",
      LastCrawl: "25OCT",
      img: "https://images.unsplash.com/photo-1682685797660-3d847763208e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    Status: "Snow",
    HealthScore: "100%",
    URLsCrawled: "0",
    InternalURLsErrors: "0",
    Scheduled: "-",
    id: "1",
  },
  {
    Projects: {
      name: "Cersei",
      img: "https://images.unsplash.com/photo-1682685797660-3d847763208e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    LastCrawl: "25OCT",
    Status: "Lannister",
    HealthScore: "100%",
    URLsCrawled: "3",
    InternalURLsErrors: "5",
    Scheduled: "-",
    id: "2",
  },
  {
    Projects: {
      name: "Jaime",
      img: "https://images.unsplash.com/photo-1682685797660-3d847763208e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    LastCrawl: "25OCT",
    Status: "Lannister",
    HealthScore: "100%",
    URLsCrawled: "2",
    InternalURLsErrors: "9",
    Scheduled: "-",
    id: "3",
  },
  {
    Projects: {
      name: "Arya",
      LastCrawl: "25OCT",
      img: "https://images.unsplash.com/photo-1682685797660-3d847763208e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    Status: "Stark",
    HealthScore: "100%",
    URLsCrawled: "4",
    InternalURLsErrors: "6",
    Scheduled: "-",
    id: "4",
  },
];

function UptimeMonitoring() {
  const isMblBreakpoint = useMediaQuery("(max-width: 600px)");
  const navigate = useNavigate();
  const { scrapyData, loading } = GetScrapyData();
  const theme = useTheme();
  const [search, setSearch] = useState(null);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [loader, setLoader] = useState(false);
  const customizer = useSelector(state => state.customizer);

  const findSearchValue = () => {
    const subString = search && search.trim().length > 0 ? search.trim() : "";
    const filterArr = scrapyData.filter(project => project.projectName.toLowerCase().includes(subString));

    if (filterArr.length > 0) {
      const dataArr = [];

      // eslint-disable-next-line
      filterArr.forEach(project => {
        if (project.isVerified) {
          dataArr.push({
            Projects: {
              name: project.projectName,
              img: project?.imageUrl && project?.imageUrl ? project?.imageUrl : ImageNotFound,
            },
            Status: project && project?.upTimeMonitoringData?.isMonitoring ? "ON" : "OFF",
            Scheduled: project && project?.upTimeMonitoringData?.totalTime ? Math.round(project.upTimeMonitoringData.totalTime / 60) + " hrs" : "0 hrs",

            id: project?.id,
            sessionId: project?.srapySessionData.length && project.srapySessionData[0].sessionId ? project.srapySessionData[0].sessionId : 0,
          });
        }
      });
      setFilteredRows(dataArr);
    }
  };
  useEffect(() => {
    findSearchValue();
  }, [search]);
  useEffect(() => {
    const dataArr = [];
    // eslint-disable-next-line
    scrapyData.forEach(project => {
      if (project.isVerified) {
        dataArr.push({
          Projects: {
            name: project.projectName,
            img: project?.imageUrl && project?.imageUrl ? project?.imageUrl : ImageNotFound,
          },
          Status: project && project?.upTimeMonitoringData?.isMonitoring ? "ON" : "OFF",
          Scheduled: project && project?.upTimeMonitoringData?.totalTime ? Math.round(project.upTimeMonitoringData.totalTime / 60) + " hrs" : "0 hrs",

          id: project?.id,
          sessionId: project?.srapySessionData.length && project.srapySessionData[0].sessionId ? project.srapySessionData[0].sessionId : 0,
        });
      }
    });
    setFilteredRows(dataArr);
    // eslint-disable-next-line
  }, [scrapyData]);

  const columns = [
    {
      field: "Projects",
      headerName: "Projects",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "left",
      align: "left",
      width: 250,
      overflow: "hidden",
      renderCell: params => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "10px",
            "& img": { width: "40px", height: "auto" },
            "& h4": { fontSize: "16px", fontWeight: 600, color: theme.palette.mode === "dark" ? "#ffffff" : "#000000" },
          }}
        >
          <Typography component="img" src={params.value.img} alt={params.value.name} />
          <Typography component="h4">{params.value.name}</Typography>
        </Box>
      ),
    },
    {
      field: "Scheduled",
      headerName: "Total Monitoring Time (Hours)",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "left",
      align: "left",
      width: 250,
      renderCell: params => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            "& p": {
              fontSize: "14px",
              color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
              fontWeight: 600,
            },
          }}
        >
          <ClockIcon />
          <Typography component="p">{params.row.Scheduled}</Typography>
        </Box>
      ),
    },
    {
      field: "Status",
      headerName: "Status",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "left",
      align: "left",
      width: 150,
      renderCell: params => (
        <Box
          sx={{
            "& span": {
              color: params.row.Status === "ON" ? "#13DEB9" : "#FA896B",
              border: `1px solid ${params.row.Status === "ON" ? "#13DEB9" : "#FA896B"}`,
              borderRadius: "50px",
              backgroundColor: params.row.Status === "ON" ? "#13DEB933" : "#FA896B33",
              fontSize: "12px",
              padding: "5px 14px 5px",
            },
          }}
        >
          <Typography component="span">{params.row.Status}</Typography>
        </Box>
      ),
    },
  ];
  const debouncedHandleChange = useCallback(
    debounce(value => {
      setSearch(value);
    }, 300),
    [],
  );

  const handleChange = e => {
    debouncedHandleChange(e.target.value);
  };

  useEffect(() => () => debouncedHandleChange.cancel(), [debouncedHandleChange]); // eslint-disable-line

  // Data Table Dynamic Width Set Start

  const wrapperRef = useRef(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  useEffect(() => {
    const handleResize = entries => {
      for (let entry of entries) {
        setLoader(true);
        setWrapperWidth(entry.contentRect.width);
        setTimeout(() => {
          setLoader(false);
        }, 300);
      }
    };
    const observer = new ResizeObserver(handleResize);
    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }
    return () => {
      if (wrapperRef.current) {
        observer.unobserve(wrapperRef.current);
      }
    };
  }, []);

  // Data Table Dynamic Width Set End

  useEffect(() => {
    setLoader(!loader);
  }, [customizer.isCollapse]);

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} md={12}>
              <Box ref={wrapperRef} sx={{ width: "100%" }}>
                <Card
                  sx={{
                    padding: "16px 16px 16px 16px",
                    border: `1px solid ${theme.palette.divider}`,
                    boxShadow: "none !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "15px",
                    "@media screen and (max-width:600px)": {
                      flexDirection: "column",
                      alignItems: "stretch",
                    },
                  }}
                >
                  <Box className="BtnTopWrapper">
                    <BackButton sx={{ "& button": { marginBottom: "0 !important" } }} />
                    <CreateProjectLink />
                  </Box>
                  <Box className="SearchTopBarWrapper">
                    <Box className="SearchBarWrapper">
                      <SearchBar handleChange={handleChange} />
                      <Button>Search</Button>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
          {loading || loader ? (
            <MainLoader message="Loading" />
          ) : (
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} md={12}>
                <BoxMainWrapper>
                  {scrapyData.length ? (
                    <Box className="DataGridWrapper" style={{ width: wrapperWidth }}>
                      <DataGrid
                        rows={filteredRows}
                        columns={columns}
                        pageSize={false}
                        pagination={false}
                        className="datagridnopage"
                        onCellClick={params => navigate(`/uptime-monitoring/${params.id}?menu=monitoring`)}
                        disableColumnMenu
                      />
                    </Box>
                  ) : (
                    <Typography variant="h6">NO Projects Found!</Typography>
                  )}
                </BoxMainWrapper>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default UptimeMonitoring;
