import { useState } from "react";
import { Box, Modal, Table, TableBody, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import HelpIcon from "@mui/icons-material/Help";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link, useParams, useSearchParams } from "react-router-dom";
import ProjectHelperDetail from "./ProjectHelperDetail";
import Popup from "./TurnedOffPopUp";
import MainLoader from "../MainLoader/MainLoader";
const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: "15px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const Modelstyle = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 630,
  backgroundColor: theme.palette.background.default,
  // border: "5px solid #6edbff",
  padding: "40px 50px 45px",
  borderRadius: "20px",
  boxShadow: "0 0 1px 0 rgba(0,0,0,0.31), 0 24px 36px -8px rgba(0,0,0,0.25)",
  "@media screen and (max-width:600px)": {
    width: "90%",
    padding: "40px 24px 45px",
  },
  "& > svg": {
    position: "absolute",
    top: "22px",
    right: "22px",
    color: theme.palette.primary.main,

    cursor: "pointer",
  },
}));
const TableMainWrapper = styled(Box)(({ theme }) => ({
  "& table": {
    marginTop: "0",
    borderRadius: "12px",
    // overflow: "hidden",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    "& thead": {
      "& tr th.MuiTableCell-head": {
        backgroundColor: theme.palette.common.hoverBtn,
        fontSize: "14px",
      },
    },
    "& tbody": {
      "& tr td": {
        color: theme.palette.text.primary,
        padding: "0px 5px",
        "& a": {
          color: theme.palette.text.primary,
          fontWeight: 600,
          "& svg": {
            color: "red",
          },
        },
        "& .HelperIconStyle": {
          color: `${theme.palette.primary.main} !important`,
          transition: "0.3s all",
          cursor: "pointer",
          "&:hover": { color: "#ff642d" },
        },
        "& .MoreIconStyle": {
          color: theme.palette.primary.main,

          cursor: "pointer",
        },
      },

      "& > h5": {
        fontSize: "16px",
        fontWeight: 600,
        padding: "12px 14px 12px",
        color: theme.palette.text.primary,
      },
      "& .indexable": {
        color: theme.palette.text.primary,
        fontWeight: 600,

        fontSize: "12px",
        paddingLeft: "14px",
        fontFamily: "Plus Jakarta Sans', sans-serif !important",
        display: "inline-block",
      },
      "& .Indexability": {
        color: theme.palette.common.black,
        fontSize: "15px",
      },
      "& .Indexabilitylink": {
        fontSize: "15px",
        color: theme.palette.primary.main,
      },
    },
  },
}));

const BoxCss = { opacity: 0.5, pointerEvents: "none" };
function ProjectDetailData({
  internalPageCount,
  indexableCount,
  nonIndexableCount,
  indexableContentCount,
  nonIndexableContentCount,
  socialTagCountPrepaired,
  imageTagCountPrepaired,
  jsCountPrepaired,
  cssTagCountPrepaired,
  externalPagesCountPrepaired,
  internalLinkPage,
  refresh,
  issueBtnIndx,
  duplicatePageCount,
  totalCount = 0,
  redirectCount,
}) {
  const [newState, setNewState] = useState({ open: false, flag: "", viewLink: "", val: 0 });
  const handleOpen = ({ flag, viewLink, val }) => {
    setNewState({ ...newState, open: true, flag, viewLink, val });
  };
  const handleClose = () => setNewState({ ...newState, open: false });
  const params = useParams();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const projectdetailtable = {
    maxHeight: "calc(100vh - 100px)",
    "&::-webkit-scrollbar-track": {
      // WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      borderRadius: "10px",
      backgroundColor: theme.palette.text.primary + "0d",
    },
    "&::-webkit-scrollbar": { width: "8px", backgroundColor: "transparent" },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
      backgroundColor: theme.palette.primary.main,
    },
  };
  return (
    <TableMainWrapper sx={{ maxHeight: 440, overflow: "auto", height: 400 }}>
      {totalCount < 1 ? (
        <MainLoader message="Loading" />
      ) : (
        <TableContainer sx={projectdetailtable}>
          <Table sx={{ minWidth: 700, marginTop: 5 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Issue</StyledTableCell>
                <StyledTableCell align="center">Crawled</StyledTableCell>
                <StyledTableCell align="center">Change</StyledTableCell>
                <StyledTableCell align="center">Added</StyledTableCell>
                <StyledTableCell align="center">New</StyledTableCell>
                <StyledTableCell align="center">Removed</StyledTableCell>
                <StyledTableCell align="center">Missing</StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {internalLinkPage?.length > 0 && <Typography component="h5">Internal pages</Typography>}
                {internalLinkPage &&
                  internalLinkPage.map(linkData => (
                    <StyledTableRow key={Math.random().toString(36).substr(2, 9)}>
                      <StyledTableCell align="left">
                        {linkData?.count > 0 ? (
                          <Link to={`/project-inner-detail/${params?.sessionId}/${linkData?.flag}&internalPage?name=${linkData?.content}&origin=${searchParams.get("origin")}`}>
                            {linkData?.icon}
                            <Typography component="span" className="Indexabilitylink">
                              {" "}
                              {linkData?.content}
                            </Typography>
                          </Link>
                        ) : (
                          <Box component="span" sx={BoxCss}>
                            {linkData?.icon}
                            <Typography component="span" className="Indexability">
                              {" "}
                              {linkData?.content}
                            </Typography>
                          </Box>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">{linkData?.count}</StyledTableCell>
                      <StyledTableCell align="center">{linkData?.changeCount}</StyledTableCell>
                      <StyledTableCell align="center">{linkData?.addIssue}</StyledTableCell>
                      <StyledTableCell align="center">{linkData?.newIssue}</StyledTableCell>
                      <StyledTableCell align="center">{linkData?.removeIssue}</StyledTableCell>
                      <StyledTableCell align="center">—</StyledTableCell>
                      <StyledTableCell align="center">
                        <HelpIcon
                          onClick={() =>
                            handleOpen({
                              flag: linkData?.flag,
                              viewLink: `/project-inner-detail/${params?.sessionId}/${linkData?.flag}&internalPage?name=${linkData?.content}&origin=${searchParams.get("origin")}`,
                              val: linkData?.count,
                            })
                          }
                          className="HelperIconStyle"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Popup flag={`${linkData?.flag}&internalPage`} refresh={refresh} issueBtnIndx={issueBtnIndx} updateFlag={linkData?.flag} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </>
              {internalPageCount?.length > 0 && <Typography component="h5">Indexability</Typography>}
              {internalPageCount &&
                internalPageCount.map(internalPages => (
                  <StyledTableRow key={Math.random().toString(36).substr(2, 9)}>
                    <StyledTableCell align="left">
                      {internalPages?.count > 0 ? (
                        <Link
                          to={`/project-inner-detail/${params?.sessionId}/${internalPages?.flag}&Indexability?name=${internalPages?.content}&origin=${searchParams.get("origin")}`}
                        >
                          {internalPages?.icon}
                          <Typography component="span" className="Indexabilitylink">
                            {" "}
                            {internalPages?.content}
                          </Typography>
                        </Link>
                      ) : (
                        <Box component="span" sx={BoxCss}>
                          {internalPages?.icon}
                          <Typography component="span" className="Indexability">
                            {" "}
                            {internalPages?.content}
                          </Typography>
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">{internalPages?.count}</StyledTableCell>
                    <StyledTableCell align="center">{internalPages?.changeCount}</StyledTableCell>
                    <StyledTableCell align="center">{internalPages?.addIssue}</StyledTableCell>
                    <StyledTableCell align="center">{internalPages?.newIssue}</StyledTableCell>
                    <StyledTableCell align="center">{internalPages?.removeIssue}</StyledTableCell>
                    <StyledTableCell align="center">—</StyledTableCell>
                    <StyledTableCell align="center">
                      <HelpIcon
                        onClick={() =>
                          handleOpen({
                            flag: internalPages?.flag,
                            viewLink: `/project-inner-detail/${params?.sessionId}/${internalPages?.flag}&Indexability?name=${internalPages?.content}&origin=${searchParams.get(
                              "origin",
                            )}`,
                            val: internalPages?.count,
                          })
                        }
                        className="HelperIconStyle"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Popup flag={`${internalPages?.flag}&Indexability`} refresh={refresh} issueBtnIndx={issueBtnIndx} updateFlag={internalPages?.flag} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              {(indexableCount?.length > 0 || nonIndexableCount?.length > 0) && <Typography component="h5">Links</Typography>}
              {indexableCount?.length > 0 && (
                <Typography component="span" className="indexable">
                  INDEXABLE
                </Typography>
              )}
              {indexableCount &&
                indexableCount.map(linksIndexData => (
                  <StyledTableRow key={Math.random().toString(36).substr(2, 9)}>
                    <StyledTableCell align="left">
                      {linksIndexData?.count > 0 ? (
                        <Link
                          to={`/project-inner-detail/${params?.sessionId}/${linksIndexData?.flag}&Links&indexable?name=${linksIndexData?.content}&origin=${searchParams.get(
                            "origin",
                          )}`}
                        >
                          {linksIndexData?.icon}
                          <Typography component="span" className="Indexabilitylink">
                            {" "}
                            {linksIndexData?.content}
                          </Typography>
                        </Link>
                      ) : (
                        <Box component="span" sx={BoxCss}>
                          {linksIndexData?.icon}
                          <Typography component="span" className="Indexability">
                            {" "}
                            {linksIndexData?.content}
                          </Typography>
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">{linksIndexData?.count}</StyledTableCell>
                    <StyledTableCell align="center">{linksIndexData?.changeCount}</StyledTableCell>
                    <StyledTableCell align="center">{linksIndexData?.addIssue}</StyledTableCell>
                    <StyledTableCell align="center">{linksIndexData?.newIssue}</StyledTableCell>
                    <StyledTableCell align="center">{linksIndexData?.removeIssue}</StyledTableCell>
                    <StyledTableCell align="center">—</StyledTableCell>
                    <StyledTableCell align="center">
                      <HelpIcon
                        onClick={() =>
                          handleOpen({
                            flag: linksIndexData?.flag,
                            viewLink: `/project-inner-detail/${params?.sessionId}/${linksIndexData?.flag}&Links&indexable?name=${linksIndexData?.content}&origin=${searchParams.get(
                              "origin",
                            )}`,
                            val: linksIndexData?.count,
                          })
                        }
                        className="HelperIconStyle"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {" "}
                      <Popup flag={`${linksIndexData?.flag}&Links&indexable`} refresh={refresh} issueBtnIndx={issueBtnIndx} updateFlag={linksIndexData?.flag} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              {nonIndexableCount?.length > 0 && (
                <Typography component="span" className="indexable">
                  NOT INDEXABLE
                </Typography>
              )}
              {nonIndexableCount &&
                nonIndexableCount.map(linksNonIndexData => (
                  <StyledTableRow key={Math.random().toString(36).substr(2, 9)}>
                    <StyledTableCell align="left">
                      {linksNonIndexData?.count > 0 ? (
                        <Link
                          to={`/project-inner-detail/${params?.sessionId}/${linksNonIndexData?.flag}&Links&notIndexable?name=${
                            linksNonIndexData?.content
                          }&origin=${searchParams.get("origin")}`}
                        >
                          {linksNonIndexData?.icon}
                          <Typography component="span" className="Indexabilitylink">
                            {" "}
                            {linksNonIndexData?.content}
                          </Typography>
                        </Link>
                      ) : (
                        <Box component="span" sx={BoxCss}>
                          {linksNonIndexData?.icon}
                          <Typography component="span" className="Indexability">
                            {" "}
                            {linksNonIndexData?.content}
                          </Typography>
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">{linksNonIndexData?.count}</StyledTableCell>
                    <StyledTableCell align="center">{linksNonIndexData?.changeCount}</StyledTableCell>
                    <StyledTableCell align="center">{linksNonIndexData?.addIssue}</StyledTableCell>
                    <StyledTableCell align="center">{linksNonIndexData?.newIssue}</StyledTableCell>
                    <StyledTableCell align="center">{linksNonIndexData?.removeIssue}</StyledTableCell>
                    <StyledTableCell align="center">—</StyledTableCell>
                    <StyledTableCell align="center">
                      <HelpIcon
                        onClick={() =>
                          handleOpen({
                            flag: linksNonIndexData?.flag,
                            viewLink: `/project-inner-detail/${params?.sessionId}/${linksNonIndexData?.flag}&Links&notIndexable?name=${
                              linksNonIndexData?.content
                            }&origin=${searchParams.get("origin")}`,
                            val: linksNonIndexData?.count,
                          })
                        }
                        className="HelperIconStyle"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {" "}
                      <Popup flag={`${linksNonIndexData?.flag}&Links&notIndexable`} refresh={refresh} issueBtnIndx={issueBtnIndx} updateFlag={linksNonIndexData?.flag} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}

              {redirectCount?.length > 0 && <Typography component="h5">Redirect</Typography>}
              {redirectCount &&
                redirectCount.map(redirectData => (
                  <StyledTableRow key={Math.random().toString(36).substr(2, 9)}>
                    <StyledTableCell align="left">
                      {redirectData?.count > 0 ? (
                        <Link
                          to={`/project-inner-detail/${params?.sessionId}/${redirectData?.flag}&redirectFlag?name=${redirectData?.content}&origin=${searchParams.get("origin")}`}
                        >
                          {redirectData?.icon}
                          <Typography component="span" className="Indexabilitylink">
                            {" "}
                            {redirectData?.content}
                          </Typography>
                        </Link>
                      ) : (
                        <Box component="span" sx={BoxCss}>
                          {redirectData?.icon}
                          <Typography component="span" className="Indexability">
                            {" "}
                            {redirectData?.content}
                          </Typography>
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">{redirectData?.count}</StyledTableCell>
                    <StyledTableCell align="center">{redirectData?.changeCount}</StyledTableCell>
                    <StyledTableCell align="center">{redirectData?.addIssue}</StyledTableCell>
                    <StyledTableCell align="center">{redirectData?.newIssue}</StyledTableCell>
                    <StyledTableCell align="center">{redirectData?.removeIssue}</StyledTableCell>
                    <StyledTableCell align="center">—</StyledTableCell>
                    <StyledTableCell align="center">
                      <HelpIcon
                        onClick={() =>
                          handleOpen({
                            flag: redirectData?.flag,
                            viewLink: `/project-inner-detail/${params?.sessionId}/${redirectData?.flag}&redirectFlag?name=${redirectData?.content}&origin=${searchParams.get(
                              "origin",
                            )}`,
                            val: redirectData?.count,
                          })
                        }
                        className="HelperIconStyle"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Popup flag={`${redirectData?.flag}&redirectFlag`} refresh={refresh} issueBtnIndx={issueBtnIndx} updateFlag={redirectData?.flag} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}

              {(indexableContentCount?.length > 0 || nonIndexableContentCount?.length > 0) && <Typography component="h5">Content</Typography>}
              {indexableContentCount?.length > 0 && (
                <Typography component="span" className="indexable">
                  INDEXABLE
                </Typography>
              )}
              {indexableContentCount &&
                indexableContentCount.map(contentIndexData => (
                  <StyledTableRow key={Math.random().toString(36).substr(2, 9)}>
                    <StyledTableCell align="left">
                      {contentIndexData?.count > 0 ? (
                        <Link
                          to={`/project-inner-detail/${params?.sessionId}/${contentIndexData?.flag}&content&indexable?name=${contentIndexData?.content}&origin=${searchParams.get(
                            "origin",
                          )}`}
                        >
                          {contentIndexData?.icon}
                          <Typography component="span" className="Indexabilitylink">
                            {" "}
                            {contentIndexData?.content}
                          </Typography>
                        </Link>
                      ) : (
                        <Box component="span" sx={BoxCss}>
                          {contentIndexData?.icon}
                          <Typography component="span" className="Indexability">
                            {" "}
                            {contentIndexData?.content}
                          </Typography>
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">{contentIndexData?.count}</StyledTableCell>
                    <StyledTableCell align="center">{contentIndexData?.changeCount}</StyledTableCell>
                    <StyledTableCell align="center">{contentIndexData?.addIssue}</StyledTableCell>
                    <StyledTableCell align="center">{contentIndexData?.newIssue}</StyledTableCell>
                    <StyledTableCell align="center">{contentIndexData?.removeIssue}</StyledTableCell>
                    <StyledTableCell align="center">—</StyledTableCell>
                    <StyledTableCell align="center">
                      <HelpIcon
                        onClick={() =>
                          handleOpen({
                            flag: contentIndexData?.flag,
                            viewLink: `/project-inner-detail/${params?.sessionId}/${contentIndexData?.flag}&content&indexable?name=${
                              contentIndexData?.content
                            }&origin=${searchParams.get("origin")}`,
                            val: contentIndexData?.count,
                          })
                        }
                        className="HelperIconStyle"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {" "}
                      <Popup flag={`${contentIndexData?.flag}&content&indexable`} refresh={refresh} issueBtnIndx={issueBtnIndx} updateFlag={contentIndexData?.flag} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              {nonIndexableContentCount?.length > 0 && (
                <Typography component="span" className="indexable">
                  NOT INDEXABLE
                </Typography>
              )}
              {nonIndexableContentCount &&
                nonIndexableContentCount.map(contentNonIndex => (
                  <StyledTableRow key={Math.random().toString(36).substr(2, 9)}>
                    <StyledTableCell align="left">
                      {contentNonIndex?.count > 0 ? (
                        <Link
                          to={`/project-inner-detail/${params.sessionId}/${contentNonIndex?.flag}&content&nonIndexable?name=${contentNonIndex?.content}&origin=${searchParams.get(
                            "origin",
                          )}`}
                        >
                          {contentNonIndex?.icon}
                          <Typography component="span" className="Indexabilitylink">
                            {" "}
                            {contentNonIndex?.content}
                          </Typography>
                        </Link>
                      ) : (
                        <Box component="span" sx={BoxCss}>
                          {contentNonIndex?.icon}
                          <Typography component="span" className="Indexability">
                            {" "}
                            {contentNonIndex?.content}
                          </Typography>
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">{contentNonIndex?.count}</StyledTableCell>
                    <StyledTableCell align="center">{contentNonIndex?.changeCount}</StyledTableCell>
                    <StyledTableCell align="center">{contentNonIndex?.addIssue}</StyledTableCell>
                    <StyledTableCell align="center">{contentNonIndex?.newIssue}</StyledTableCell>
                    <StyledTableCell align="center">{contentNonIndex?.removeIssue}</StyledTableCell>
                    <StyledTableCell align="center">—</StyledTableCell>
                    <StyledTableCell align="center">
                      <HelpIcon
                        onClick={() =>
                          handleOpen({
                            flag: contentNonIndex?.flag,
                            viewLink: `/project-inner-detail/${params.sessionId}/${contentNonIndex?.flag}&content&nonIndexable?name=${
                              contentNonIndex?.content
                            }&origin=${searchParams.get("origin")}`,
                            val: contentNonIndex?.count,
                          })
                        }
                        className="HelperIconStyle"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Popup flag={`${contentNonIndex?.flag}&content&nonIndexable`} refresh={refresh} issueBtnIndx={issueBtnIndx} updateFlag={contentNonIndex?.flag} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              {socialTagCountPrepaired?.length > 0 && <Typography component="h5">Social tags</Typography>}
              {socialTagCountPrepaired &&
                socialTagCountPrepaired.map(tagsData => (
                  <StyledTableRow key={Math.random().toString(36).substr(2, 9)}>
                    <StyledTableCell align="left">
                      {tagsData?.count > 0 ? (
                        <Link to={`/project-inner-detail/${params?.sessionId}/${tagsData?.flag}&socialTag?name=${tagsData?.content}&origin=${searchParams.get("origin")}`}>
                          {tagsData?.icon}
                          <Typography component="span" className="Indexabilitylink">
                            {" "}
                            {tagsData?.content}
                          </Typography>
                        </Link>
                      ) : (
                        <Box component="span" sx={BoxCss}>
                          {tagsData?.icon}
                          <Typography component="span" className="Indexability">
                            {" "}
                            {tagsData?.content}
                          </Typography>
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">{tagsData?.count}</StyledTableCell>
                    <StyledTableCell align="center">{tagsData?.changeCount}</StyledTableCell>
                    <StyledTableCell align="center">{tagsData?.addIssue}</StyledTableCell>
                    <StyledTableCell align="center">{tagsData?.newIssue}</StyledTableCell>
                    <StyledTableCell align="center">{tagsData?.removeIssue}</StyledTableCell>
                    <StyledTableCell align="center">—</StyledTableCell>
                    <StyledTableCell align="center">
                      <HelpIcon
                        onClick={() =>
                          handleOpen({
                            flag: tagsData?.flag,
                            viewLink: `/project-inner-detail/${params.sessionId}/${tagsData?.flag}&socialTag?name=${tagsData?.content}&origin=${searchParams.get("origin")}`,
                            val: tagsData?.count,
                          })
                        }
                        className="HelperIconStyle"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Popup flag={`${tagsData?.flag}&socialTag`} refresh={refresh} issueBtnIndx={issueBtnIndx} updateFlag={tagsData?.flag} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              {duplicatePageCount?.length > 0 && <Typography component="h5">Duplicates</Typography>}
              {duplicatePageCount &&
                duplicatePageCount.map(tagsData => (
                  <StyledTableRow key={Math.random().toString(36).substr(2, 9)}>
                    <StyledTableCell align="left">
                      {tagsData?.count > 0 ? (
                        <Link to={`/project-inner-detail/${params?.sessionId}/${tagsData?.flag}?name=${tagsData?.content}&origin=${searchParams.get("origin")}`}>
                          {tagsData?.icon}
                          <Typography component="span" className="Indexabilitylink">
                            {" "}
                            {tagsData?.content}
                          </Typography>
                        </Link>
                      ) : (
                        <Box component="span" sx={BoxCss}>
                          {tagsData?.icon}
                          <Typography component="span" className="Indexability">
                            {" "}
                            {tagsData?.content}
                          </Typography>
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">{tagsData?.count}</StyledTableCell>
                    <StyledTableCell align="center">{tagsData?.changeCount}</StyledTableCell>
                    <StyledTableCell align="center">{tagsData?.addIssue}</StyledTableCell>
                    <StyledTableCell align="center">{tagsData?.newIssue}</StyledTableCell>
                    <StyledTableCell align="center">{tagsData?.removeIssue}</StyledTableCell>
                    <StyledTableCell align="center">—</StyledTableCell>
                    <StyledTableCell align="center">
                      <HelpIcon
                        onClick={() =>
                          handleOpen({
                            flag: tagsData?.flag,
                            viewLink: `/project-inner-detail/${params.sessionId}/${tagsData?.flag}&socialTag?name=${tagsData?.content}&origin=${searchParams.get("origin")}`,
                            val: tagsData?.count,
                          })
                        }
                        className="HelperIconStyle"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Popup flag={tagsData?.flag} refresh={refresh} issueBtnIndx={issueBtnIndx} updateFlag={tagsData?.flag} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              {imageTagCountPrepaired?.length > 0 && <Typography component="h5">Images</Typography>}
              {imageTagCountPrepaired &&
                imageTagCountPrepaired.map(imgData => (
                  <StyledTableRow key={Math.random().toString(36).substr(2, 9)}>
                    <StyledTableCell align="left">
                      {imgData?.count > 0 ? (
                        <Link to={`/project-inner-detail/${params.sessionId}/${imgData?.flag}&imagesFlag?name=${imgData?.content}&origin=${searchParams.get("origin")}`}>
                          {imgData?.icon}
                          <Typography component="span" className="Indexabilitylink">
                            {" "}
                            {imgData?.content}
                          </Typography>
                        </Link>
                      ) : (
                        <Box component="span" sx={BoxCss}>
                          {imgData?.icon}
                          <Typography component="span" className="Indexability">
                            {" "}
                            {imgData?.content}
                          </Typography>
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">{imgData?.count}</StyledTableCell>
                    <StyledTableCell align="center">{imgData?.changeCount}</StyledTableCell>
                    <StyledTableCell align="center">{imgData?.addIssue}</StyledTableCell>
                    <StyledTableCell align="center">{imgData?.newIssue}</StyledTableCell>
                    <StyledTableCell align="center">{imgData?.removeIssue}</StyledTableCell>
                    <StyledTableCell align="center">—</StyledTableCell>
                    <StyledTableCell align="center">
                      <HelpIcon
                        onClick={() =>
                          handleOpen({
                            flag: imgData?.flag,
                            viewLink: `/project-inner-detail/${params.sessionId}/${imgData?.flag}&imagesFlag?name=${imgData?.content}`,
                            val: imgData?.count,
                          })
                        }
                        className="HelperIconStyle"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Popup flag={`${imgData?.flag}&imagesFlag`} refresh={refresh} issueBtnIndx={issueBtnIndx} updateFlag={imgData?.flag} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              {jsCountPrepaired?.length > 0 && <Typography component="h5">JavaScript</Typography>}
              {jsCountPrepaired &&
                jsCountPrepaired.map(jsData => (
                  <StyledTableRow key={Math.random().toString(36).substr(2, 9)}>
                    <StyledTableCell align="left">
                      {jsData?.count > 0 ? (
                        <Link to={`/project-inner-detail/${params.sessionId}/${jsData?.flag}&jsFlags?name=${jsData?.content}&origin=${searchParams.get("origin")}`}>
                          {jsData?.icon}
                          <Typography component="span" className="Indexabilitylink">
                            {" "}
                            {jsData?.content}
                          </Typography>
                        </Link>
                      ) : (
                        <Box component="span" sx={BoxCss}>
                          {jsData?.icon}
                          <Typography component="span" className="Indexability">
                            {" "}
                            {jsData?.content}
                          </Typography>
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">{jsData?.count}</StyledTableCell>
                    <StyledTableCell align="center">{jsData?.changeCount}</StyledTableCell>
                    <StyledTableCell align="center">{jsData?.addIssue}</StyledTableCell>
                    <StyledTableCell align="center">{jsData?.newIssue}</StyledTableCell>
                    <StyledTableCell align="center">{jsData?.removeIssue}</StyledTableCell>
                    <StyledTableCell align="center">—</StyledTableCell>
                    <StyledTableCell align="center">
                      <HelpIcon
                        onClick={() =>
                          handleOpen({
                            flag: jsData?.flag,
                            viewLink: `/project-inner-detail/${params.sessionId}/${jsData?.flag}&jsFlags?name=${jsData?.content}`,
                            val: jsData?.count,
                          })
                        }
                        className="HelperIconStyle"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Popup flag={`${jsData?.flag}&jsFlags`} refresh={refresh} issueBtnIndx={issueBtnIndx} updateFlag={jsData?.flag} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              {cssTagCountPrepaired?.length > 0 && <Typography component="h5">CSS</Typography>}
              {cssTagCountPrepaired &&
                cssTagCountPrepaired.map(cssData => (
                  <StyledTableRow key={Math.random().toString(36).substr(2, 9)}>
                    <StyledTableCell align="left">
                      {cssData?.count > 0 ? (
                        <Link to={`/project-inner-detail/${params?.sessionId}/${cssData?.flag}&cssFlags?name=${cssData?.content}&origin=${searchParams.get("origin")}`}>
                          {cssData?.icon}
                          <Typography component="span" className="Indexabilitylink">
                            {" "}
                            {cssData?.content}
                          </Typography>
                        </Link>
                      ) : (
                        <Box component="span" sx={BoxCss}>
                          {cssData?.icon}
                          <Typography component="span" className="Indexability">
                            {" "}
                            {cssData?.content}
                          </Typography>
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">{cssData?.count}</StyledTableCell>
                    <StyledTableCell align="center">{cssData?.changeCount}</StyledTableCell>
                    <StyledTableCell align="center">{cssData?.addIssue}</StyledTableCell>
                    <StyledTableCell align="center">{cssData?.newIssue}</StyledTableCell>
                    <StyledTableCell align="center">{cssData?.removeIssue}</StyledTableCell>
                    <StyledTableCell align="center">—</StyledTableCell>
                    <StyledTableCell align="center">
                      <HelpIcon
                        onClick={() =>
                          handleOpen({
                            flag: cssData?.flag,
                            viewLink: `/project-inner-detail/${params?.sessionId}/${cssData?.flag}&cssFlags?name=${cssData?.content}`,
                            val: cssData?.count,
                          })
                        }
                        className="HelperIconStyle"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Popup flag={`${cssData?.flag}&cssFlags`} refresh={refresh} issueBtnIndx={issueBtnIndx} updateFlag={cssData?.flag} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              {externalPagesCountPrepaired?.length > 0 && <Typography component="h5">External pages</Typography>}
              {externalPagesCountPrepaired &&
                externalPagesCountPrepaired.map(externalPage => (
                  <StyledTableRow key={Math.random().toString(36).substr(2, 9)}>
                    <StyledTableCell align="left">
                      {externalPage?.count > 0 ? (
                        <Link
                          to={`/project-inner-detail/${params?.sessionId}/${externalPage?.flag}&externalPagesFlag?name=${externalPage?.content}&origin=${searchParams.get(
                            "origin",
                          )}`}
                        >
                          {externalPage?.icon}
                          <Typography component="span" className="Indexabilitylink">
                            {" "}
                            {externalPage?.content}
                          </Typography>
                        </Link>
                      ) : (
                        <Box component="span" sx={BoxCss}>
                          {externalPage?.icon}
                          <Typography component="span" className="Indexability">
                            {" "}
                            {externalPage?.content}
                          </Typography>
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">{externalPage?.count}</StyledTableCell>
                    <StyledTableCell align="center">{externalPage?.changeCount}</StyledTableCell>
                    <StyledTableCell align="center">{externalPage?.addIssue}</StyledTableCell>
                    <StyledTableCell align="center">{externalPage?.newIssue}</StyledTableCell>
                    <StyledTableCell align="center">{externalPage?.removeIssue}</StyledTableCell>
                    <StyledTableCell align="center">—</StyledTableCell>
                    <StyledTableCell align="center">
                      <HelpIcon
                        onClick={() =>
                          handleOpen({
                            flag: externalPage?.flag,
                            viewLink: `/project-inner-detail/${params?.sessionId}/${externalPage?.flag}&externalPagesFlag?name=${externalPage?.content}&origin=${searchParams.get(
                              "origin",
                            )}`,
                            val: externalPage?.count,
                          })
                        }
                        className="HelperIconStyle"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Popup flag={`${externalPage?.flag}&externalPagesFlag`} refresh={refresh} issueBtnIndx={issueBtnIndx} updateFlag={externalPage?.flag} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              <Modal open={newState.open} onClose={handleClose}>
                <Modelstyle>
                  <CancelIcon onClick={handleClose} />
                  <ProjectHelperDetail flag={newState?.flag} viewLink={newState?.viewLink} val={newState?.val} />
                </Modelstyle>
              </Modal>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </TableMainWrapper>
  );
}
export default ProjectDetailData;
