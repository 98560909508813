import { Box } from "@mui/material";

const SvgAnimationWrapper = {
  "& .RightMark": {
    transformOrigin: "center",
    animation: "zoom-in 1s cubic-bezier(0.4, 0, 1, 1)",
    "@keyframes zoom-in": {
      "0%": { transform: "scale(0)" },
      "100%": { transform: "scale(1)" },
    },
  },
  "& .MarkCircle": {
    transformOrigin: "center",
    animation: "opacity-up 2s cubic-bezier(0.4, 0, 1, 1)",
    "@keyframes opacity-up": {
      "0%": { opacity: "0" },
      "100%": { opacity: "1" },
    },
  },
  "& .Flag": {
    transformOrigin: "center",
    animation: "fade-flag1 2.5s infinite ease 1.5s",
    opacity: "0",
    "@keyframes fade-flag1": {
      "0%, 100%": { opacity: "0" },
      "50%": { opacity: "1" },
    },
  },
};

function ErrorIcon() {
  return (
    <Box sx={SvgAnimationWrapper} component="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156 106" fill="none" className="ErrorIcon">
      <path d="M72.5414 75.8658L72 48H80L79.4586 75.8658H72.5414ZM72 88V80.4832H80V88H72Z" fill="#FF6062" className="RightMark" />
      <path
        d="M78.5696 18.625L76.188 14.5L73.8065 18.625L27.6184 98.625L25.2369 102.75H30H122.376H127.139L124.758 98.625L78.5696 18.625Z"
        stroke="#FF6062"
        strokeWidth="5.5"
        className="MarkCircle"
      />
      <path d="M0.270677 68.6295L0 54H4L3.72932 68.6295H0.270677ZM0 75V71.0537H4V75H0Z" fill="#FF6062" className="Flag" />
      <path d="M130.203 30.4497L130 20H133L132.797 30.4497H130.203ZM130 35V32.1812H133V35H130Z" fill="#FF6062" className="Flag" />
      <path d="M39.203 10.4497L39 0H42L41.797 10.4497H39.203ZM39 15V12.1812H42V15H39Z" fill="#FF6062" className="Flag" />
      <path d="M153.203 79.1463L153 68H156L155.797 79.1463H153.203ZM153 84V80.9933H156V84H153Z" fill="#FF6062" className="Flag" />
    </Box>
  );
}

export default ErrorIcon;
