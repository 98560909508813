function ProjectIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1298.9 1067" xmlSpace="preserve" width="25px" className="SvgIcons">
      <path d="M1298.9 130.7v935.4H154.5c-72.6 0-137.4-53.4-151.1-124.7C1.3 930.6.2 919.5.1 908.6 0 658.6 0 408.8 0 158.9 0 86.3 46.6 25.2 116.3 6.2c58.8-16 121.4 5.3 158.7 53.7 15.9 20.7 26.3 43.7 31.1 69.3.1.4.3.7.6 1.5h992.2zm-987 779.6c-17.1 0-33.4 0-49.8-.1-.8 0-2.2-1.7-2.3-2.8-.6-4.6-.6-9.3-1.3-13.8-9.3-60-69.5-99.3-128.5-84-44.8 11.6-76.3 49.9-77.7 96.2-1.3 42.3 17 74.3 53.6 95.6 16.3 9.5 34.2 12.9 52.9 12.9 51-.1 102 0 153 0H1247V183.2H311.8c.1 242.3.1 484.5.1 727.1zm-52.1-117v-5.1V517 160.3c0-18.7-3.4-36.6-12.6-53-18-31.7-45.2-49.9-81.5-53.6-28.9-2.9-54.9 5.2-77 24-25.3 21.4-36.8 49.3-36.8 82.4.1 209.7 0 419.4 0 629.2v4c69.5-52.2 138.3-52.3 207.9 0z" />
      <path d="M981.2 410.9l-71.8-71.8H1091v180.3c-24-24-47.8-47.8-72-71.9-58.5 58.5-116.5 116.4-174.7 174.6-48-48.1-95.8-96-143.7-144.1-63.5 55.5-126.5 110.6-189.9 166-11.3-12.9-22.5-25.7-34.1-38.9 75.4-66 150.6-131.8 225.9-197.8 47.3 47.2 94.4 94.3 141.3 141l137.4-137.4zM442 832v-51.2h648.9V832H442z" />
    </svg>
  );
}

export default ProjectIcon;
