/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_SUBSCRIPTION } from "../../gql/mutations/payment";
import { Box, Button, Card, Container, Grid, List, ListItem, ListItemIcon, ListItemText, styled, Switch, Typography, useTheme } from "@mui/material";
import { GET_ALL_PRODUCTS } from "../../gql/queries/payments";
import MainLoader from "../MainLoader/MainLoader";
import { useSelector } from "react-redux";
import { usePopup } from "../../context/commonPop";
import { IconCheck, IconX } from "@tabler/icons-react";
import BrushShape from "../SvgIcons/BrushShape";
import pricingImageOne from "../../Images/pricing-image/PricingPlanImgOne.png";
import pricingImageTwo from "../../Images/pricing-image/PricingPlanImgTwo.png";
import pricingImageThree from "../../Images/pricing-image/PricingPlanImgThree.png";
import pricingDarkImageOne from "../../Images/pricing-image/PricingPlanDarkImgOne.png";
import pricingDarkImageTwo from "../../Images/pricing-image/PricingPlanDarkImgTwo.png";
import pricingDarkImageThree from "../../Images/pricing-image/PricingPlanDarkImgThree.png";
import WelcomeCard from "./WelcomeCard";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .PaymentBoxWrapper": {
    "& h6": { fontSize: "18px", fontWeight: 700, marginBottom: "15px" },
    "& h2": { fontSize: "24px", fontWeight: 700, marginBottom: "15px" },
  },
  "& .HeadMainWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .HeadMainHeading": {
      fontSize: "24px",
      fontWeight: 700,
      color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
    },
    "& .HeadSwitchWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& p": {
        fontSize: "18px",
        fontWeight: 500,
        color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
        "& strong": {
          fontSize: "18px",
          fontWeight: 700,
          color: theme.palette.primary.main,
          position: "relative",
          "& .BrushShape": {
            position: "absolute",
            right: "0",
            bottom: "-14px",
            width: "130px",
            height: "12px",
          },
        },
      },
    },
  },
  "& .PlanBoxWrapper": {},
}));

const pricing = [
  {
    id: 1,
    image: pricingImageOne,
    imagedark: pricingDarkImageOne,
    package: "STANDARD",
    monthlyplan: 1000,
    yearlyplan: 1000,
    plan: "plan_PAS1x8k6wqmWmF",
    amount: 100000,
    btntext: "Subscribe",
    rules: [
      {
        limit: true,
        title: "5 Project",
      },
      {
        limit: true,
        title: "10000 Site Audit",
      },
      {
        limit: true,
        title: "15 Blog Generation",
      },
    ],
  },
  {
    id: 2,
    image: pricingImageTwo,
    imagedark: pricingDarkImageTwo,
    package: "PREMIUM",
    monthlyplan: 100,
    yearlyplan: 100,
    plan: "plan_PC5PVSVka1vIB2",
    amount: 10000,
    btntext: "Subscribe",
    rules: [
      {
        limit: true,
        title: "10 Project",
      },
      {
        limit: true,
        title: "15000 Site Audit",
      },
      {
        limit: true,
        title: "20 Blog Generation",
      },
    ],
  },
  {
    id: 3,
    image: pricingImageThree,
    imagedark: pricingDarkImageThree,
    package: "ENTERPRISES",
    monthlyplan: 10,
    plan: "plan_PJAEdJfoMRllB7",
    amount: 1000,
    yearlyplan: 10,
    btntext: "Subscribe",
    rules: [
      {
        limit: true,
        title: "15 Project",
      },
      {
        limit: true,
        title: "20000 Site Audit",
      },
      {
        limit: true,
        title: "6 Blog Generation",
      },
    ],
  },
];

const Payment = () => {
  const [show, setShow] = React.useState(false);
  const [getAllProduct, setGetAllProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const { userData } = useSelector(state => state.reducer);
  const { handleOpen } = usePopup();
  const [createSubscriptionCall] = useMutation(CREATE_SUBSCRIPTION);
  const [getProduct] = useLazyQuery(GET_ALL_PRODUCTS, { fetchPolicy: "network-only" });
  const theme = useTheme();
  const userSubscription = userData?.subscriptionCredits?.subscriptionType;
  const handleSubscribe = async (planId, amount) => {
    try {
      const { data } = await createSubscriptionCall({
        variables: { plan: planId },
      });
      const { createSubscription } = data;
      if (!createSubscription) {
        console.error("SessionId is not available!");
        return;
      }

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: amount,
        currency: "INR",
        name: "HotSpotseo",
        subscription_id: createSubscription,

        redirect: true,
        notes: {
          id: userData?.id,
          email: userData?.email,
          razorpayCustomerId: userData.razorpayCustomerId,
          planId,
        },
        callback_url: `${process.env.REACT_APP_BACKEND_DOMAIN}razorpay/verify`,

        prefill: {
          name: userData.name,
          email: userData.email,
          contact: null,
        },
        theme: {
          color: "#F37254",
        },
        recurring: true,
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      handleOpen(true, `${error.message}`);
      console.log("Error creating subscription:", error);
    }
  };
  const handleFetchProduct = () => {
    getProduct({
      onCompleted: ({ getAllProducts }) => {
        setGetAllProduct(getAllProducts);
        setLoading(false);
      },
      onError: err => {
        console.error("Error fetching plan:", err);
        setLoading(false);
      },
    });
  };
  useEffect(() => {
    handleFetchProduct();
  }, []);
  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          {loading ? (
            <MainLoader />
          ) : (
            <>
              {/* <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
                {getAllProduct.map(product => {
                  const isSubscribed = userSubscription === product.name;
                  return (
                    <Grid item xs={12} lg={4} key={product.id}>
                      <Card sx={{ padding: "30px 45px 30px 40px", border: `1px solid ${theme.palette.divider}`, boxShadow: "none" }}>
                        <Box className="PaymentBoxWrapper">
                          <Typography component={"h6"}>{product.name}</Typography>
                          <Typography component={"h2"}>${product.amount / 100} per Month</Typography>
                          <Button onClick={() => handleSubscribe(product.id, product.amount)} disabled={isSubscribed}>
                            {isSubscribed ? "Subscribed" : "Subscribe"}
                          </Button>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid> */}
              <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
                <Grid item xs={12} lg={12}>
                  <WelcomeCard />
                </Grid>
              </Grid>
              <Grid container spacing={2} justifyContent="center" mt={0}>
                <Grid item xs={12}>
                  <Box className="HeadMainWrapper">
                    <Typography component="h2" className="HeadMainHeading">
                      Plans & Pricing
                    </Typography>
                    <Box className="HeadSwitchWrapper">
                      <Switch onChange={() => setShow(!show)} />
                      <Typography component="p">
                        Pay annually and{" "}
                        <Typography component="strong">
                          save up to 12% <BrushShape />
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={0}>
                {pricing.map((price, i) => {
                  const isSubscribed = userSubscription === price.package;
                  return (
                    <Grid item xs={12} sm={6} lg={4} key={i}>
                      <Box className="PlanBoxWrapper">
                        <Card sx={{ padding: "32px 32px 32px 32px", border: `1px solid ${theme.palette.divider}`, boxShadow: "none !important" }}>
                          <Box sx={{ textAlign: "center", marginTop: "-32px", "& img": { width: "180px", height: "auto" } }}>
                            {theme.palette.mode === "dark" ? <Typography component="img" src={price.imagedark} alt="" /> : <Typography component="img" src={price.image} alt="" />}
                          </Box>
                          <Typography variant="subtitle1" fontSize="16px" mb={3} fontWeight={700} color="textSecondary" textTransform="uppercase">
                            {price.package}
                          </Typography>
                          <Box mt={4}>
                            <Box display="flex">
                              <Typography variant="h4" mr="8px" mt="-12px">
                                ₹
                              </Typography>
                              <Typography fontSize="40px" fontWeight="700">
                                {show ? price.yearlyplan : price.monthlyplan}
                              </Typography>
                              <Typography fontSize="15px" fontWeight={400} ml={1} color="textSecondary" mt={1}>
                                /monthly
                              </Typography>
                            </Box>
                          </Box>
                          <Box mt={2}>
                            <List>
                              {price.rules.map((rule, i) => (
                                <Box key={i}>
                                  {rule.limit ? (
                                    <>
                                      <ListItem disableGutters>
                                        <ListItemIcon sx={{ color: "primary.main", minWidth: "32px" }}>
                                          <IconCheck width={18} />
                                        </ListItemIcon>
                                        <ListItemText>{rule.title}</ListItemText>
                                      </ListItem>
                                    </>
                                  ) : (
                                    <ListItem disableGutters sx={{ color: "grey.400" }}>
                                      <ListItemIcon sx={{ color: "grey.400", minWidth: "32px" }}>
                                        <IconX width={18} />
                                      </ListItemIcon>
                                      <ListItemText>{rule.title}</ListItemText>
                                    </ListItem>
                                  )}
                                </Box>
                              ))}
                            </List>
                          </Box>
                          <Button
                            sx={{ width: "100%", mt: 3 }}
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={() => handleSubscribe(price.plan, price.amount)}
                            disabled={isSubscribed}
                          >
                            {isSubscribed ? "Subscribed" : price.btntext}
                          </Button>
                        </Card>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}
        </Box>
      </Container>
    </SectionWrapper>
  );
};

export default Payment;
