/*eslint-disable*/
import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";

import { AuthContext } from "../../AuthContext";
import { recoverSession } from "../../utils/session";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

export function RequireAuthenticated({ children }) {
  const { isAuth, activateAuth, removeAuth } = useContext(AuthContext);

  function checkLogIn() {
    if (recoverSession("accessToken")) {
      activateAuth(recoverSession("accessToken"));
    } else {
      removeAuth();
    }
  }

  useEffect(() => {
    checkLogIn();
    window.addEventListener("storage", events => {
      setTimeout(() => {
        checkLogIn();
      }, 1000);
    });
    return () => {
      window.removeEventListener("storage", () => {});
    };
    // eslint-disable-next-line
  }, []);
  if (isAuth === null) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "calc(100vh - 100px)" }}>
        <CircularProgress />
      </Box>
    );
  }
  if (isAuth === false) {
    return <Navigate to="/" />;
  }
  return children;
}

export function RequireAuthenticatedNav({ children }) {
  //   const isAuth = recoverSession("token");

  const { isAuth } = useContext(AuthContext);
  // useEffect(() => {
  //   if (
  //     recoverSession("token") != undefined ||
  //     recoverSession("token") != null
  //   ) {
  //     activateAuth(recoverSession("token"));
  //   }
  // }, []);

  if (isAuth) {
    return children;
  }
  return null;
  // else {
  //   return <Navigate to="/" />;
  // }
}
