import gql from "graphql-tag";

export const SCRAP_WEBSITE = gql`
  mutation Mutation($payload: CreateScrapyInput!) {
    ScrapUrl(payload: $payload) {
      id
      sessionId
      authorId
    }
  }
`;

export const pageSpeed = gql`
  mutation Mutation($data: ScrapyReportInputs!) {
    PageSpeed(data: $data) {
      projectId
      sessionId
      userId
      website
      mobileSpeed
      mobilejson
      desktopSpeed
      desktopjson
      id
      issues
      notices
      updatedAt
      warnings
      createdAt
    }
  }
`;

export const ligtHouseSave = gql`
  mutation Mutation($projectId: String!, $sessionId: String!) {
    lightHouseReport(projectId: $projectId, sessionId: $sessionId)
  }
`;
export const pauseLighthouseReport = gql`
  mutation Mutation($projectId: String!, $sessionId: String!) {
    pauseLightHouseReport(projectId: $projectId, sessionId: $sessionId)
  }
`;
export const resumeLighthouseReport = gql`
  mutation Mutation($projectId: String!, $sessionId: String!) {
    resumeLightHouseReport(projectId: $projectId, sessionId: $sessionId)
  }
`;
export const CHECK_METADATA_N_TITLE = gql`
  mutation CheckMetaDataAndTitle($inputArg: TitleMetaDTO!) {
    checkMetaDataAndTitle(inputArg: $inputArg) {
      meta_description
      title
    }
  }
`;
export const GENERATE_METADATA_N_TITLE = gql`
  mutation Mutation($payload: GenerateInputs!) {
    generateMetaDataAndTitle(payload: $payload) {
      meta_description
      title
    }
  }
`;
