import { useEffect, useState } from "react";
import { Typography, Box, Container, Button, Grid, styled, Card } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import placeholder from "../Images/placeholder.png";
import { useSubscriptionCall } from "../hooks/auth";
import MainLoader from "../components/MainLoader/MainLoader";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
}));

const BoxMainWrapperTwo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media screen and (max-width:600px)": {
    display: "block",
  },
  "& .profiledata": {
    "@media screen and (max-width:600px)": {
      marginTop: "20px",
    },
    "& h6": {
      color: theme.palette.text.primary,
      marginBottom: "15px",
      "&:last-child": { marginBottom: "0" },
    },
  },
}));

const ProfileAvatarWrapper = styled(Box)(({ theme }) => ({
  width: "270px",
  textAlign: "center",
  marginRight: "80px",
  "@media screen and (max-width:600px)": {
    marginRight: "0",
    width: "100%",
  },
  "& img": {
    borderRadius: "50%",
    border: "3px solid #6edbff",
    width: "100%",
  },
  "& button": {
    boxShadow: "none",
    textTransform: "none",
    background: theme.palette.primary.main,
    color: "#fff",
    marginTop: "25px",
    borderRadius: "50px",
    padding: "4px 18px 4px",
    // border: "1px solid #ff642d",
    "&:hover": {
      borderColor: "#6EDBFF",
      background: theme.palette.primary.main,
      color: "#fff",
      boxShadow: "none",
    },
    "&:focus": { outline: "none" },
  },
}));

function Profile() {
  const [loading, setLoading] = useState(true);
  const userData = useSelector(state => state.reducer.userData);
  const navigate = useNavigate();
  const customizer = useSelector(state => state.customizer);
  const { data } = useSubscriptionCall();
  const theme = useTheme();
  const borderColor = theme.palette.divider;
  const handleEditClick = async () => {
    navigate("/account-settings");
  };

  useEffect(() => {
    if (userData?.email) {
      setLoading(false);
    }
  }, [userData]);
  return (
    <ThemeProvider theme={theme}>
      <SectionWrapper>
        <Container maxWidth="">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
              <Grid item xs={12}>
                <Card
                  sx={{ padding: { xs: "30px 0px 24px 0px", sm: "30px 45px 24px 40px" }, border: customizer.isCardShadow ? `1px solid ${borderColor}` : "none" }}
                  elevation={customizer.isCardShadow ? 9 : 0}
                >
                  {loading ? (
                    <MainLoader SX={{ height: "40vh !important" }} message="Loading" />
                  ) : (
                    <BoxMainWrapperTwo>
                      <ProfileAvatarWrapper>
                        <Typography component="img" src={userData?.imageUrl || placeholder} alt="" />
                        <Button className="editProfile" variant="contained" color="primary" onClick={handleEditClick}>
                          Edit Profile
                        </Button>
                      </ProfileAvatarWrapper>
                      <Box className="profiledata">
                        <Typography variant="h6">Profile : {userData.role}</Typography>
                        <Typography variant="h6">Name : {data?.userCreate?.name ?? userData?.name}</Typography>
                        <Typography variant="h6">Email : {data?.userCreate?.email ?? userData?.email}</Typography>
                        <Typography variant="h6">Address : {data?.userCreate?.address ?? userData?.address}</Typography>
                        <Typography variant="h6">Phone : {data?.userCreate?.phone ?? userData?.phone}</Typography>
                        <Typography variant="h6">Bio : {data ? data?.userCreate?.bio : userData?.bio}</Typography>
                      </Box>
                    </BoxMainWrapperTwo>
                  )}
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </SectionWrapper>
    </ThemeProvider>
  );
}

export default Profile;
