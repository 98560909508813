/* eslint-disable */
import { useEffect, useState } from "react";
import { Box, Button, IconButton, Stack, styled, Switch, TextField, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { uptimeGmailDelete, uptimeMonitoring } from "../../gql/mutations/uptimeMonitoring";
import { usePopup } from "../../context/commonPop";
import { getUptimeProject } from "../../gql/queries/uptimeStatus";
import { GetUserWebsiteData } from "../../hooks/userWebsiteData";
import useQuery from "../../hooks/allQuery";
import { SCRAPY_DATA } from "../../gql/queries/userWebSiteList";
import DashboardCard from "../shared/DashboardCard";

const BoxWrapper = styled(Box)(({ theme }) => ({
  "& .InputWrapper": {
    marginTop: "10px",
    "& p": {
      marginBottom: "6px",
    },
    "& .MuiFormControl-root": {
      "& label": {
        "&[data-shrink='false']": {
          top: "-4px",
          color: "#7e7e7e",
        },
      },
    },
  },
}));

function UptimeMonitoringSetting({ projectid }) {
  const [isMonitoringOn, setIsMonitoringOn] = useState(false);
  const [gmailInput, setGmailInput] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [gmails, setGmails] = useState([]);
  const [editIndex, setEditIndex] = useState(null);

  const { handleOpen } = usePopup();
  const { allQueryObject } = useQuery();
  const client = useApolloClient();
  const [updateUptime] = useMutation(uptimeMonitoring, { fetchPolicy: "network-only" });
  const [GET_UPTIME_PROJECT] = useLazyQuery(getUptimeProject, { fetchPolicy: "network-and-cache" });
  const [deleteEmailMutation] = useMutation(uptimeGmailDelete);
  const { ProjectData } = GetUserWebsiteData(projectid);
  const { userData } = useSelector(state => state.reducer);
  const { website } = ProjectData || {};

  const validateEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email).toLowerCase());

  const getData = () => {
    allQueryObject({
      QUERY: GET_UPTIME_PROJECT,
      variables: { projectId: projectid },
      onCompleteFunc: ({ GetUptime }) => {
        setIsMonitoringOn(GetUptime?.isMonitoring || false);
        setGmails(GetUptime?.gmails || []);
      },
      onErrorFunc: error => {
        console.error("Error fetching uptime data:", error);
        handleOpen(true, error.message || "Something went wrong");
      },
    });
  };

  const handleSwitchChange = async () => {
    if (!website) return;

    const gmailsArray = gmailInput
      .split(",")
      .map(email => email.trim())
      .filter(email => email);
    if (!gmailsArray.every(validateEmail)) {
      setEmailError(true);
      return;
    }

    try {
      await updateUptime({
        variables: {
          data: {
            userId: userData.id,
            projectId: projectid,
            isMonitoring: !isMonitoringOn,
            domain: website.split("/")[2],
            gmails: !isMonitoringOn ? gmailsArray : [],
          },
        },
      });
      setIsMonitoringOn(prev => !prev);
      if (!isMonitoringOn) {
        setGmails(gmailsArray);
      } else {
        setGmails([]);
      }

      getData();
      setGmailInput("");
      updateCacheAfterSwitch();
      handleOpen(true, isMonitoringOn ? "Successfully ended uptime monitoring" : "Successfully started uptime monitoring");
    } catch (error) {
      console.error("Error updating uptime:", error);
      handleOpen(true, "Failed to update isMonitoring state!");
    }
  };

  const updateCacheAfterSwitch = (newMonitoringState, newGmails) => {
    const updateCache = (query, variables, getUpdatedData) => {
      const cacheData = client.readQuery({ query, variables });
      if (cacheData) {
        client.writeQuery({
          query,
          variables,
          data: getUpdatedData(cacheData),
        });
      }
    };

    updateCache(SCRAPY_DATA, { params: userData.id }, cacheData => ({
      ...cacheData,
      userWebVisitList: cacheData.userWebVisitList.map(project =>
        project.id === projectid
          ? {
              ...project,
              upTimeMonitoringData: {
                ...project.upTimeMonitoringData,
                isMonitoring: newMonitoringState,
                gmails: newMonitoringState ? newGmails : [],
              },
            }
          : project,
      ),
    }));

    updateCache(getUptimeProject, { projectId: projectid }, cacheData => ({
      ...cacheData,
      GetUptime: {
        ...cacheData.GetUptime,
        isMonitoring: newMonitoringState,
        gmails: newMonitoringState ? newGmails : [],
      },
    }));
  };

  const handleAddOrUpdateGmail = async () => {
    if (!validateEmail(gmailInput)) {
      setEmailError("Invalid email format.");
      return;
    }

    if (gmails.includes(gmailInput) && editIndex === null) {
      handleOpen(true, "Gmail already exists");
      return;
    }

    const updatedGmails = [...gmails];
    if (editIndex !== null) {
      updatedGmails[editIndex] = gmailInput;
    } else {
      updatedGmails.push(gmailInput);
    }

    setGmails(updatedGmails);
    setEditIndex(null);
    setGmailInput("");
    setEmailError("");

    try {
      await updateUptime({
        variables: {
          data: {
            userId: userData.id,
            projectId: projectid,
            isMonitoring: isMonitoringOn,
            domain: website.split("/")[2],
            gmails: updatedGmails,
            updateIndex: editIndex,
            newGmail: gmailInput,
          },
        },
      });
    } catch (error) {
      console.error("Error updating uptime email:", error);
      handleOpen(true, error.message || "Something went wrong");
    }
  };

  const handleEditGmail = index => {
    setEditIndex(index);
    setGmailInput(gmails[index]);
  };

  const handleDeleteGmail = async index => {
    const updatedGmails = gmails.filter((_, i) => i !== index);
    setGmails(updatedGmails);
    if (editIndex === index) {
      setEditIndex(null);
      setGmailInput("");
    }

    try {
      await deleteEmailMutation({
        variables: { projectId: projectid, index },
      });
    } catch (error) {
      console.error("Error deleting email:", error);
      handleOpen(true, error.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (projectid) getData();
  }, [projectid]);

  return (
    <DashboardCard className="HeightFull">
      <BoxWrapper>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Uptime Monitoring {isMonitoringOn ? "On" : "Off"}</Typography>
          <Switch checked={isMonitoringOn} onChange={handleSwitchChange} inputProps={{ "aria-label": "ant design" }} />
        </Stack>
        <Box className="InputWrapper" style={{ marginTop: "16px" }}>
          <Typography component="p">Notification Gmails for uptime monitoring</Typography>
          <TextField
            label="Enter a Gmail address"
            value={gmailInput}
            onChange={e => {
              setGmailInput(e.target.value);
              setEmailError(!validateEmail(e.target.value) && e.target.value !== "");
            }}
            fullWidth
            error={!!emailError}
            helperText={emailError || ""}
            variant="outlined"
          />
          <Button variant="contained" color="primary" onClick={handleAddOrUpdateGmail} disabled={emailError || gmailInput.trim() === ""} style={{ marginTop: "16px" }}>
            {editIndex !== null ? "Update Gmail" : "Add Gmail"}
          </Button>
          {gmails.length > 0 && (
            <Box style={{ marginTop: "10px" }}>
              {gmails.map((email, index) => (
                <Stack direction="row" spacing={1} alignItems="center" key={index} style={{ marginBottom: "10px" }}>
                  <Typography>{email}</Typography>
                  <IconButton onClick={() => handleEditGmail(index)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteGmail(index)} color="error">
                    <CancelIcon />
                  </IconButton>
                </Stack>
              ))}
            </Box>
          )}
        </Box>
      </BoxWrapper>
    </DashboardCard>
  );
}

export default UptimeMonitoringSetting;
