import { memo } from "react";
import { Box, Grid, Container, Typography, Card, CardContent, Avatar, Divider, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";

import Expence from "../components/dashboards/ecommerce/Expence";
import RecentReports from "../components/dashboards/ecommerce/RecentReports";
import { GetGraphCountData, GetSpeedData } from "../hooks/scrapyData";
import HealthScoreOverview from "../components/dashboards/ecommerce/HealthScoreOverview";
import DummyChart from "../components/dashboards/ecommerce/DummyChart";
import ResponseTimeChart from "../components/uptime-monitoring/ResponseTimeChart";
import DummyTimeChart from "../components/uptime-monitoring/DummyTimeResponseChart";
import RevenueUpdates from "../components/uptime-monitoring/RevenueUpdate";
import { GetUptimStatus } from "../hooks/getUptimeRecords";
import welcomeImage from "../Images/profile/user-1.jpg";
import welcomeImage2 from "../Images/backgrounds/welcome-bg.svg";
import WeeklyStats from "../components/dashboards/modern/WeeklyStats";
import { getDomain } from "../utils/myUtills";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "80px",
  "& .ProjectWrapper": {
    display: "flex",
    flexDirection: "column",
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));

function Ecommerce() {
  const { sessionId, projectid } = useParams();
  const navigate = useNavigate();
  const { uptimeRecordsData } = GetUptimStatus(projectid);
  const { PageSpeedData } = GetSpeedData(projectid);
  const { countData } = GetGraphCountData(projectid, 10);
  const { GetPageSpeed } = PageSpeedData || {};
  return (
    <Box>
      <SectionWrapper>
        <Container maxWidth="">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Card elevation={0} sx={{ backgroundColor: theme => theme.palette.primary.light, py: 0, height: "100%" }}>
                  <CardContent sx={{ py: 4, px: 2 }}>
                    <Grid container justifyContent="space-between">
                      <Grid item sm={6} display="flex" alignItems="center">
                        <Box>
                          <Box
                            gap="16px"
                            mb={5}
                            sx={{
                              display: {
                                xs: "block",
                                sm: "flex",
                              },
                              alignItems: "center",
                            }}
                          >
                            <Avatar src={welcomeImage} alt="img" sx={{ width: 40, height: 40 }} />
                            <Box className="ProjectWrapper">
                              <Typography variant="h5" sx={{ whiteSpace: { xs: "wrap", sm: "nowrap" } }}>
                                Project Overview
                              </Typography>

                              <Typography component="a" target="_blank" href={countData && countData?.GetGraphIssueCount && countData?.GetGraphIssueCount[0]?.website}>
                                {countData && countData?.GetGraphIssueCount && getDomain(countData?.GetGraphIssueCount[0]?.website)}
                                <LaunchIcon fontSize="16px" />
                              </Typography>
                            </Box>
                          </Box>
                          <Stack spacing={2} direction="row" divider={<Divider orientation="vertical" flexItem />}></Stack>
                        </Box>
                      </Grid>
                      <Grid item sm={6}>
                        <Box mb="-51px">
                          <img src={welcomeImage2} alt="img" width="340px" />
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} lg={8}>
                <Grid container spacing={3} sx={{ height: "calc(100% + 24px)" }}>
                  <Grid item xs={12} sm={6} lg={6}>
                    <WeeklyStats countData={countData} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={6}
                    onClick={() => {
                      navigate(`/project-detail/${sessionId}?show=true&origin=${projectid}`);
                    }}
                  >
                    <HealthScoreOverview ProjectData={countData} />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={12}>
                    {uptimeRecordsData && uptimeRecordsData.success ? <RevenueUpdates data={uptimeRecordsData} /> : <DummyChart data={uptimeRecordsData} projectid={projectid} />}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={4}>
                <Grid container spacing={3} sx={{ height: "calc(100% + 24px)" }} alignItems="stretch">
                  {GetPageSpeed && <MemorizedExpensesCard sessionId={sessionId} GetPageSpeed={GetPageSpeed} projectid={projectid} />}
                  <Grid item xs={12} lg={12} sx={{ height: "calc(100% - 278px)" }}>
                    <RecentReports ProjectData={countData} />
                  </Grid>
                </Grid>
              </Grid>
              {/* column */}
              <Grid item xs={12} lg={12}>
                {uptimeRecordsData && uptimeRecordsData.success && uptimeRecordsData.responseMoments ? <ResponseTimeChart data={uptimeRecordsData} /> : <DummyTimeChart />}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </SectionWrapper>
    </Box>
  );
}

export default Ecommerce;

function ExpensesCard({ sessionId, GetPageSpeed, projectid }) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Link to={`/projects/report/mobile/${sessionId}/${projectid}`}>
          <Expence heading="Mobile performance" val={GetPageSpeed?.mobileSpeed?.performance?.score} />
        </Link>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Link to={`/projects/report/desktop/${sessionId}/${projectid}`}>
          <Expence heading="Desktop performance" val={GetPageSpeed?.desktopSpeed?.performance?.score} />
        </Link>
      </Grid>
    </>
  );
}

const MemorizedExpensesCard = memo(ExpensesCard);
