function DesktopIcon() {
  return (
    <svg width="48" height="42" viewBox="0 0 48 42" fill="none" xmlns="http://www.w3.org/2000/svg" className="DesktopIcon">
      <path
        d="M0 4.1701V32.7639C0.00114143 33.7829 0.406473 34.7599 1.12707 35.4805C1.84766 36.2011 2.82467 36.6065 3.84375 36.6076H15.4452L13.8194 39.4237C13.6878 39.6517 13.6184 39.9104 13.6184 40.1737C13.6184 40.437 13.6877 40.6956 13.8194 40.9236C13.951 41.1517 14.1404 41.341 14.3684 41.4727C14.5964 41.6043 14.8551 41.6737 15.1184 41.6737H32.8815C33.1448 41.6737 33.4035 41.6044 33.6315 41.4727C33.8596 41.3411 34.0489 41.1517 34.1806 40.9237C34.3122 40.6957 34.3816 40.437 34.3816 40.1737C34.3816 39.9104 34.3122 39.6517 34.1806 39.4237L32.5548 36.6076H44.1562C45.1753 36.6065 46.1523 36.2011 46.8729 35.4805C47.5935 34.7599 47.9989 33.7829 48 32.7639V4.1701C47.9988 3.15104 47.5935 2.17404 46.8729 1.45345C46.1523 0.732861 45.1753 0.327521 44.1562 0.326355H3.84375C2.82468 0.327521 1.84769 0.732861 1.1271 1.45345C0.406506 2.17404 0.0011662 3.15104 0 4.1701ZM30.2835 38.6737H17.7165L18.9094 36.6076H29.0906L30.2835 38.6737ZM44.1562 33.6076H3.84375C3.62005 33.6074 3.40558 33.5184 3.2474 33.3602C3.08922 33.202 3.00025 32.9876 3 32.7639V30.6656H45V32.764C44.9997 32.9877 44.9107 33.2021 44.7525 33.3603C44.5944 33.5184 44.3799 33.6074 44.1562 33.6076ZM45 4.1701V27.6656H3V4.1701C3.00027 3.94641 3.08926 3.73196 3.24743 3.57378C3.4056 3.41561 3.62006 3.32663 3.84375 3.32635H44.1562C44.3799 3.32663 44.5944 3.41561 44.7526 3.57378C44.9107 3.73196 44.9997 3.94641 45 4.1701Z"
        fill="white"
      />
    </svg>
  );
}

export default DesktopIcon;
