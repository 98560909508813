/* eslint-disable */
import { useEffect, useState } from "react";
import { Box, Typography, styled, Card, useTheme, Button } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined";
import { subscriptionCancelQuery } from "../../gql/queries/subcriptions";
import useQuery from "../../hooks/allQuery";
import { Link } from "react-router-dom";
import { GET_USER_SUBSCRIPTION_PLAN } from "../../gql/queries/payments";
import MainLoader from "../MainLoader/MainLoader";
import { usePopup } from "../../context/commonPop";
import { useSelector } from "react-redux";
import { updateUser } from "../../redux/action";
import { useDispatch } from "react-redux";
import { defultSubscriptionType } from "../../common/constant";
import PlanDetails from "./modal/PlanDetails";
import ConfirmationModal from "./modal/ConfirmationModal";

const BoxWrapper = styled(Box)(({ theme }) => ({
  padding: "16px",
  "& .PlanStatusBoxWrapper": {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    gap: "15px",
    "@media screen and (max-width:820px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "24px",
    },
    "& .PlanStatus": {
      "& .MainHeading": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: 1,
        color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
        marginBottom: "12px",
        "& > span": {
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: 1,
          color: theme.palette.primary.main,
        },
      },
      "& .Label": {
        fontSize: "14px",
        color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
        fontWeight: 500,
        lineHeight: 1,
        marginBottom: "16px",
      },
      "& .PaymentMethod": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "6px",
        "& .CardIconWrapper": {
          backgroundColor: "#F2F6FA",
          padding: "8px 8px 8px",
          borderRadius: "6px",
          "& svg": {
            color: "#2A3547",
            fontSize: "24px",
          },
        },
        "& .CardDetails": {
          "& .CardTitle": {
            fontSize: "14px",
            color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
            fontWeight: 700,
            lineHeight: 1,
            marginBottom: "6px",
          },
          "& .Value": {
            fontSize: "14px",
            color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
            fontWeight: 700,
            lineHeight: 1,
          },
        },
      },
    },
    "& .BtnWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "14px",
      "@media screen and (max-width:820px)": {
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
      },
      "& button": {
        textTransform: "none",
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        borderRadius: "5px",
        boxShadow: "none",
        padding: "7px 22px",
        whiteSpace: "nowrap",
        "@media screen and (max-width:600px)": {
          width: "100%",
        },
        "&:focus": {
          outline: "none",
        },
        "&.OutlineBtn": {
          color: theme.palette.primary.main,
          backgroundColor: "transparent",
          border: `1px solid ${theme.palette.primary.main}`,
          padding: "6px 22px",
        },
      },
      "& a": {
        "@media screen and (max-width:600px)": {
          width: "100%",
        },
      },
    },
  },
  "& .PaymentMethodBoxWrapper": {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    gap: "15px",
    "@media screen and (max-width:820px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "24px",
    },
    "& .PaymentMethod": {
      "& .MainHeading": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: 1,
        color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
        marginBottom: "12px",
        "& > span": {
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: 1,
          color: theme.palette.primary.main,
        },
      },
      "& .Label": {
        fontSize: "14px",
        color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
        fontWeight: 500,
        lineHeight: 1,
        marginBottom: "16px",
      },
      "& .PaymentMethod": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "6px",
        marginBottom: "14px",
        "& .CardIconWrapper": {
          backgroundColor: "#F2F6FA",
          padding: "8px 8px 8px",
          borderRadius: "6px",
          "& svg": {
            color: "#2A3547",
            fontSize: "24px",
          },
        },
        "& .CardDetails": {
          "& .CardTitle": {
            fontSize: "14px",
            color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
            fontWeight: 700,
            lineHeight: 1,
            marginBottom: "6px",
          },
          "& .Value": {
            fontSize: "14px",
            color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
            fontWeight: 700,
            lineHeight: 1,
          },
        },
      },
      "& .SubLabel": {
        fontSize: "12px",
        color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
        fontWeight: 400,
        lineHeight: 1,
      },
    },
    "& .BtnWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "14px",
      "@media screen and (max-width:820px)": {
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
      },
      "& button": {
        textTransform: "none",
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        borderRadius: "5px",
        boxShadow: "none",
        padding: "7px 22px",
        whiteSpace: "nowrap",
        "@media screen and (max-width:600px)": {
          width: "100%",
        },
        "&:focus": {
          outline: "none",
        },
        "&.OutlineBtn": {
          color: "#FA896B",
          backgroundColor: "transparent",
          border: "1px solid #FA896B",
          padding: "6px 22px",
        },
      },
    },
  },
  "& .FreePlanStatusBoxWrapper": {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    gap: "15px",
    "@media screen and (max-width:820px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "24px",
    },
    "& .FreePlanStatus": {
      "& .MainHeading": {
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: 1,
        color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
        marginBottom: "12px",
        "& > span": {
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: 1,
          color: theme.palette.primary.main,
        },
      },
      "& .Label": {
        fontSize: "16px",
        color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547",
        fontWeight: 500,
        lineHeight: 1,
        marginBottom: "16px",
        "& strong": {
          color: theme.palette.primary.main,
        },
      },
    },
    "& .BtnWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "14px",
      "@media screen and (max-width:820px)": {
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
      },
      "& button": {
        textTransform: "none",
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        borderRadius: "5px",
        boxShadow: "none",
        padding: "7px 22px",
        whiteSpace: "nowrap",
        "@media screen and (max-width:600px)": {
          width: "100%",
        },
        "&:focus": {
          outline: "none",
        },
        "&.OutlineBtn": {
          color: theme.palette.primary.main,
          backgroundColor: "transparent",
          border: `1px solid ${theme.palette.primary.main}`,
          padding: "6px 22px",
        },
      },
    },
  },
}));

const formatTimestampToDate = timestamp => {
  const date = new Date(timestamp * 1000);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return `On ${date.toLocaleDateString("en-US", options)}`;
};

const BillsTab = ({ setValue }) => {
  const [openPlan, setPlanOpen] = useState(false);
  const handlePlanOpen = () => setPlanOpen(true);
  const handlePlanClose = () => setPlanOpen(false);
  const [openCancelSubscription, setCancelSubscriptionOpen] = useState(false);
  const handleCancelSubscriptionOpen = () => setCancelSubscriptionOpen(true);
  const handleCancelSubscriptionClose = () => setCancelSubscriptionOpen(false);

  const [loading, setLoading] = useState(false);
  const [userPlan, setUserPlan] = useState(null);
  const { userData } = useSelector(state => state.reducer);
  const { handleOpen } = usePopup();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { allQuery } = useQuery;
  const [getPlan] = useLazyQuery(GET_USER_SUBSCRIPTION_PLAN, {
    fetchPolicy: "network-only",
  });
  const [CANCEL_QUERY] = useLazyQuery(subscriptionCancelQuery, {
    fetchPolicy: "cache-first",
  });
  const handleSubscriptionHistoryTab = () => {
    setValue(2);
  };
  const handleCancelFunc = () => {
    CANCEL_QUERY({
      onCompleted: () => {
        handleOpen(true, "Successfully deleted your subscription");
        dispatch(updateUser({ ...userData, razorpaySubscriptionId: null }));
        setLoading(false);
        // fetchPlan();
      },
      onError: err => {
        console.error("Error fetching plan:", err);
        setLoading(false);
      },
    });
  };

  const fetchPlan = () => {
    setLoading(true);
    getPlan({
      onCompleted: data => {
        if (data && data.getUserPlan) {
          const { getUserPlan } = data;
          setUserPlan(getUserPlan);
        } else {
          console.error("Invalid data structure:", data);
        }
        setLoading(false);
      },
      onError: err => {
        console.error("Error fetching plan:", err);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    fetchPlan();
  }, []);

  return (
    <>
      {loading ? (
        <MainLoader />
      ) : (
        <BoxWrapper>
          {userData?.razorpaySubscriptionId === null || !userData?.razorpaySubscriptionId || userData?.subscriptionCredits?.subscriptionType == defultSubscriptionType ? (
            <Card sx={{ padding: "22px 24px 26px 24px", border: `1px solid ${theme.palette.divider}`, boxShadow: "none !important" }} className="FreePlanStatusBoxWrapper">
              <Box className="FreePlanStatus">
                <Typography component="h3" className="MainHeading">
                  Current Plan : &nbsp;<Typography component="span">FREEMIUM</Typography>
                </Typography>
                <Typography component="i" className="Label">
                  Note:- You currently do not have an active subscription. Please consider subscribing to enjoy premium benefits.
                  <br />
                  Please click <strong>VIEW PLAN</strong> button to add subscription
                </Typography>
              </Box>
              <Box className="BtnWrapper">
                <Link to="/payments">
                  <Button>View Plan</Button>
                </Link>
              </Box>
            </Card>
          ) : (
            <Card sx={{ padding: "22px 24px 26px 24px", border: `1px solid ${theme.palette.divider}`, boxShadow: "none !important", mb: "16px" }} className="PlanStatusBoxWrapper">
              <Box className="PlanStatus">
                <Typography component="h3" className="MainHeading">
                  Current Plan : &nbsp;<Typography component="span">{userPlan?.planName || defultSubscriptionType}</Typography>
                </Typography>
                <Typography component="p" className="Label">
                  Thanks for being a premium member and supporting our development.
                </Typography>
                <Box className="PaymentMethod">
                  <Typography component="span" className="CardIconWrapper">
                    <TokenOutlinedIcon />
                  </Typography>
                  <Box className="CardDetails">
                    <Typography component="p" className="CardTitle">
                      Current Plan
                    </Typography>
                    <Typography component="p" className="Value">
                      {`${userPlan?.amount / 100} ${userPlan?.planType} Visits`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="BtnWrapper">
                <Button className="OutlineBtn" onClick={handlePlanOpen}>
                  View Details
                </Button>
                <PlanDetails handleClose={handlePlanClose} open={openPlan} viewDetails={null} />
                <Button className="OutlineBtn" onClick={handleSubscriptionHistoryTab}>
                  All Plans History
                </Button>
                <Link to="/payments">
                  <Button>Change Plan</Button>
                </Link>
              </Box>
            </Card>
          )}
          {userData?.razorpaySubscriptionId !== null && userData?.razorpaySubscriptionId && (
            <Card sx={{ padding: "22px 24px 26px 24px", border: `1px solid ${theme.palette.divider}`, boxShadow: "none !important" }} className="PaymentMethodBoxWrapper">
              <Box className="PaymentMethod">
                <Typography component="h3" className="MainHeading">
                  Payment Method
                </Typography>
                <Typography component="p" className="Label">
                  {userPlan?.card?.created_at && formatTimestampToDate(userPlan.card?.created_at)}
                </Typography>
                <Box className="PaymentMethod">
                  <Typography component="span" className="CardIconWrapper">
                    <CreditCardOutlinedIcon />
                  </Typography>
                  <Box className="CardDetails">
                    <Typography component="p" className="CardTitle">
                      {userPlan?.card?.network}
                    </Typography>
                    <Typography component="p" className="Value">
                      *****{userPlan?.card?.last4}
                    </Typography>
                  </Box>
                </Box>
                <Typography component="p" className="SubLabel">
                  If you updated your payment method, it will only be displayed here after your next billing cycle.
                </Typography>
              </Box>
              <Box className="BtnWrapper">
                <Button className="OutlineBtn" onClick={handleCancelSubscriptionOpen}>
                  Cancel Subscription
                </Button>
                <ConfirmationModal
                  label="Upon cancellation, your account will automatically revert to the default FREEMIUM plan."
                  handleClose={handleCancelSubscriptionClose}
                  open={openCancelSubscription}
                  handleCancelFunc={handleCancelFunc}
                />
              </Box>
            </Card>
          )}
        </BoxWrapper>
      )}
    </>
  );
};

export default BillsTab;
