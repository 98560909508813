/* eslint-disable */
import { Box, Card, Container, Grid, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { lazy, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useLazyQuery } from "@apollo/client";
import dayjs from "dayjs";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { ALL_REPORT } from "../../gql/queries/scrapy";
import { useAllSiteAuditCount } from "../../hooks/scrapyData";
import { DatePickerRangeComponent } from "./DatePickerRange";
import MainLoader from "../MainLoader/MainLoader";
import { useSelector } from "react-redux";
const Chart = lazy(() => import("react-apexcharts"), { ssr: false });

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .MainHeadingWrapper": {
    "& .MainHeading": { fontSize: "18px", fontWeight: "600" },
  },
}));
const BoxMainWrapper = styled(Box)(({ theme }) => ({
  "& .DataGridWrapper": {
    "& .datagridnopage": {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: "7px 7px 7px 7px",
    },
    "& .datagridnopage.PaginationHide .MuiDataGrid-footerContainer": {
      display: "none",
    },
    "& .MuiDataGrid-main": {
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: theme.palette.mode === "dark" ? "#FFFFFF33" : "#2A3547",
        borderRadius: "7px",
        "& .MuiDataGrid-columnSeparator": {
          color: "#8F8F8F",
          visibility: "visible",
        },
        "& .MuiDataGrid-columnHeadersInner": {
          flex: "1",
          '& div[role="row"]': {
            flex: "1",
            width: "100%",
            "& .MuiDataGrid-columnHeader": {
              outline: "none !important",
              paddingLeft: "18px",
              paddingRight: "18px",
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                "& .MuiDataGrid-iconButtonContainer": {
                  display: "none",
                },
              },
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "14px",
            color: theme.palette.mode === "dark" ? "#ffffff" : "#ffffff",
            fontWeight: 600,
          },
        },
      },
      "& .MuiDataGrid-virtualScroller": {
        overflowX: "hidden",
        "&::-webkit-scrollbar-track": {
          borderRadius: "10px",
          backgroundColor: theme.palette.text.primary + "0d",
        },
        "&::-webkit-scrollbar": { width: "8px", backgroundColor: "transparent" },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: theme.palette.primary.main,
        },
        "& .MuiDataGrid-virtualScrollerContent": {
          // height: "400px !important",
          height: "calc(100vh - 295px) !important",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            width: "100%",
            "@media screen and (max-width:600px)": {
              width: "auto",
            },
            "& .MuiDataGrid-row": {
              boxShadow: "none",
              minHeight: "70px !important",
              width: "100%",
            },
            '& div[role="row"]': {
              cursor: "pointer",
              borderBottom: `2px solid ${theme.palette.divider}`,
              width: "100%",
              "&:last-of-type": { borderWidth: "1px" },
              "& .MuiDataGrid-cell": {
                minHeight: "70px !important",
                flex: 1,
                paddingLeft: "18px",
                paddingRight: "18px",
                "&:last-of-type": {
                  display: "none",
                  "@media screen and (max-width:600px)": {
                    display: "flex",
                  },
                },
                "&.MuiDataGrid-cell--withRenderer": {
                  "&:last-of-type": {
                    display: "flex",
                  },
                },
                "&:focus-within": { outline: "none" },
                "& .MuiDataGrid-cellContent": {
                  color: theme.palette.text.primary,
                  fontWeight: 600,
                },
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-footerContainer": {
      justifyContent: "center",
      "& .MuiTablePagination-root": {
        "& .MuiToolbar-root": {
          "& .MuiTablePagination-selectLabel": {
            marginBottom: "0",
          },
          "& .MuiTablePagination-displayedRows": {
            marginBottom: "0",
          },
        },
      },
    },
  },
}));

const BoxStyled = styled(Box)(() => ({
  padding: "30px",
  transition: "0.1s ease-in",
  cursor: "pointer",
  color: "inherit",
  textAlign: "center",
  borderRadius: "4px",
  "&:hover": {
    transform: "scale(1.03)",
  },
}));
// const DatePickerStyleWrapper = {
//   justifyContent: "flex-end",
//   flexDirection: "row",
//   gap: "15px",
//   paddingTop: "0",
//   "@media screen and (max-width: 500px)": {
//     flexDirection: "column",
//   },
//   "& .MuiFormControl-root.MuiTextField-root": {
//     minWidth: "500px",
//     "@media screen and (max-width: 821px)": {
//       minWidth: "auto",
//       flex: 1,
//     },
//   },
//   "& .FetchBtn": { marginTop: "0" },
// };
function Reports() {
  const isMblBreakpoint = useMediaQuery("(max-width: 600px)");
  const [loader, setLoader] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [reportCounts, setReportCounts] = useState({ Completed: 0, Failed: 0, Deleted: 0, Progress: 0, totalCount: 0 });
  const [reportsData, setReportsData] = useState([]);
  const [allReports] = useLazyQuery(ALL_REPORT, { fetchPolicy: "network-and-cache" });
  const theme = useTheme();
  const navigate = useNavigate();
  const { siteAuditData, getAllSiteAuditData } = useAllSiteAuditCount();
  const customizer = useSelector(state => state.customizer);
  const handleDateRangeChange = newValue => {
    const [newStart, newEnd] = newValue;
    setStartDate(newStart ? new Date(newStart) : null);
    setEndDate(newEnd ? new Date(newEnd) : null);
  };
  function checkStatus(dataCheck) {
    let status = "Completed";
    const { isCompleted, isDeleted, isFailed } = dataCheck || {};
    if (isDeleted) {
      status = "Deleted";
    } else if (isFailed) {
      status = "Failed";
    } else if (!isCompleted && !isFailed) {
      status = "Progress";
    }

    return status;
  }

  async function fetchData() {
    await allReports({
      variables: {
        startDate,
        endDate,
      },
      onCompleted: ({ getAllReports }) => {
        const { filterReport, reportCount } = getAllReports || {};
        const newData = filterReport.map((item, ind) => ({ ...item, id: ind + 1, status: checkStatus(item), createdAt: moment(item?.createdAt).format("DD/MM/YYYY, HH:mm") }));
        setReportsData(newData);
        setReportCounts(reportCount);
      },
      onError: err => {
        console.log(err);
      },
    });
  }
  console.log({ reportCounts });
  const handleFetchData = async () => {
    await fetchData();
    if (startDate && endDate) {
      const formattedStartDate = startDate ? dayjs(startDate).format("DD-MM-YYYY") : null;
      const formattedEndDate = endDate ? dayjs(endDate).format("DD-MM-YYYY") : null;
      await getAllSiteAuditData(formattedStartDate, formattedEndDate);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // function textHideFunction() {
  //   const specificText = "MUI X Missing license key";
  //   const xpath = `//div[text()[contains(., '${specificText}')]]`;
  //   const matchingElement = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

  //   if (matchingElement) {
  //     matchingElement.style.display = "none";
  //   }
  // }
  const columns = [
    {
      field: "project",
      headerName: "Project",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "left",
      align: "left",
      width: 280,
      renderCell: params => (
        <Box
          sx={{
            "& h4": { fontSize: "16px", fontWeight: 600, color: theme.palette.mode === "dark" ? "#ffffff" : "#000000" },
            "& h6": { fontSize: "12px", fontWeight: 600, fontWeight: 600, color: theme.palette.mode === "dark" ? "#757575" : "#757575" },
          }}
        >
          <Typography component="h4">{params.row.project}</Typography>
          <Typography component="h6">{params.row.domain}</Typography>
        </Box>
      ),
    },
    {
      field: "time",
      headerName: "Date and Time",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "left",
      align: "left",
      width: 280,
      renderCell: params => (
        <Box
          sx={{
            "& p": { fontSize: "12px", fontWeight: 600, color: theme.palette.mode === "dark" ? "#ffffff" : "#2A3547" },
          }}
        >
          <Typography component="p" title={params.row.time}>
            {params.row.time}
          </Typography>
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "left",
      align: "left",
      width: 280,
      renderCell: params => {
        const { status } = params.row;
        let text;
        let style;
        switch (status) {
          case "Completed":
            text = "Completed";
            style = { color: "#13DEB9", border: "1px solid #13DEB9", borderRadius: "50px", backgroundColor: "#13DEB933", fontSize: "12px", padding: "5px 14px 5px" };
            break;
          case "Progress":
            text = "Progress";
            style = { color: "#FFAE1F", border: "1px solid #FFAE1F", borderRadius: "50px", backgroundColor: "#FFAE1F33", fontSize: "12px", padding: "5px 14px 5px" };
            break;
          case "Failed":
            text = "Failed";
            style = { color: "#FA896B", border: "1px solid #FA896B", borderRadius: "50px", backgroundColor: "#FA896B33", fontSize: "12px", padding: "5px 14px 5px" };
            break;
          default:
            text = "Progress";
            style = { color: "#FFAE1F", border: "1px solid #FFAE1F", borderRadius: "50px", backgroundColor: "#FFAE1F33", fontSize: "12px", padding: "5px 14px 5px" };
            break;
        }
        return (
          <Typography component="span" sx={style}>
            {text}
          </Typography>
        );
      },
    },
  ];

  function parseDate(dateStr) {
    const parts = dateStr.split(", ");
    const dateParts = parts[0].split("/");
    const timeParts = parts[1].split(":");
    return new Date(dateParts[2], dateParts[1] - 1, dateParts[0], timeParts[0], timeParts[1]);
  }
  const rows = reportsData?.map(item => ({
    id: item.id,
    domain: item.domain,
    project: item.project,
    status: item.status,
    time: parseDate(item.createdAt).toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
  }));

  const renderChart = () => {
    const groupedData = reportsData.reduce((acc, curr) => {
      const date = moment(curr.createdAt, ["DD/MM/YYYY", "DD/MM/YYYY, HH:mm"]).format("DD/MM/YYYY");
      const status = checkStatus(curr);
      acc[date] = acc[date] || { Completed: 0, Failed: 0, Progress: 0 };
      acc[date][status] = (acc[date][status] || 0) + 1;
      return acc;
    }, {});

    let categories = Object.keys(groupedData).sort((a, b) => new Date(a) - new Date(b));

    if (!startDate || !endDate) {
      categories = categories.slice(-7);
    }

    const seriesData = categories.map(date => {
      const statusCounts = groupedData[date] || { Completed: 0, Failed: 0, Progress: 0 };
      return [statusCounts.Completed, statusCounts.Failed, statusCounts.Progress];
    });

    return (
      <Chart
        options={{
          xaxis: { categories },
          // yaxis: {
          //   max: 21,
          //   min: 0,
          //   tickAmount: 3,
          // },
          dataLabels: { enabled: false },
          chart: { toolbar: { show: false } },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          colors: ["#77B6EA", "#FF4560", "#40d338"],
        }}
        series={[
          { name: "Completed", data: seriesData.map(data => data[0]) },
          { name: "Failed", data: seriesData.map(data => data[1]) },
          { name: "Progress", data: seriesData.map(data => data[2]) },
        ]}
        type="bar"
        height={360}
      />
    );
  };

  const handleClick = path => {
    navigate(`/scheduled-report-details/${path}?menu=headerAnalyticDashboard`, {
      state: { startDate: startDate ? dayjs(startDate).format("DD-MM-YYYY") : "", endDate: endDate ? dayjs(endDate).format("DD-MM-YYYY") : "" },
    });
  };

  const wrapperRef = useRef(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  useEffect(() => {
    const handleResize = entries => {
      for (let entry of entries) {
        setLoader(true);
        setWrapperWidth(entry.contentRect.width);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    };
    const observer = new ResizeObserver(handleResize);
    if (wrapperRef.current) {
      observer.observe(wrapperRef?.current);
    }
    () => {
      if (wrapperRef.current) {
        observer.unobserve(wrapperRef?.current);
      }
      return;
    };
  }, [wrapperRef.current]);

  // Data Table Dynamic Width Set End
  useEffect(() => {
    setLoader(!loader);
  }, [customizer.isCollapse]);

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} lg={12} ref={wrapperRef}>
              <Card sx={{ padding: "30px 45px 24px 40px", border: `1px solid ${theme.palette.divider}`, boxShadow: "none !important" }}>
                <Grid container spacing={2} mt={0} sx={{ justifyContent: "center", alignItems: "center" }}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Box className="MainHeadingWrapper">
                      <Typography component="h5" className="MainHeading">
                        Report Details
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <DatePickerRangeComponent handleFetchData={handleFetchData} handleDateRangeChange={handleDateRangeChange} startDate={startDate} endDate={endDate} />
                  </Grid>
                </Grid>
                {reportsData && reportsData?.length > 0 && (
                  <Grid>
                    <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
                      <Grid item xs={12} md={6} lg={3}>
                        <BoxStyled sx={{ backgroundColor: "primary.light", color: "primary.main" }}>
                          <Typography variant="h3">{reportCounts && reportCounts.totalCount}</Typography>
                          <Typography variant="h6">Total Reports</Typography>
                        </BoxStyled>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <BoxStyled sx={{ backgroundColor: "success.light", color: "success.main" }}>
                          <Typography variant="h3">{reportCounts && reportCounts.Completed}</Typography>
                          <Typography variant="h6">Completed Reports</Typography>
                        </BoxStyled>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <BoxStyled sx={{ backgroundColor: "warning.light", color: "warning.main" }}>
                          <Typography variant="h3">{reportCounts && reportCounts.Progress}</Typography>
                          <Typography variant="h6">Progress Report</Typography>
                        </BoxStyled>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <BoxStyled sx={{ backgroundColor: "error.light", color: "error.main" }}>
                          <Typography variant="h3">{reportCounts && reportCounts.Failed}</Typography>
                          <Typography variant="h6">Failed Reports</Typography>
                        </BoxStyled>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <BoxStyled
                          onClick={() => {
                            if (siteAuditData?.allCount > 0) handleClick("all");
                          }}
                          sx={{ backgroundColor: "primary.light", color: "primary.main" }}
                        >
                          <Typography variant="h3">{siteAuditData && (siteAuditData?.allCount || 0)}</Typography>
                          <Typography variant="h6">All schedule</Typography>
                        </BoxStyled>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <BoxStyled
                          onClick={() => {
                            if (siteAuditData?.dailyCount > 0) handleClick("daily");
                          }}
                          sx={{ backgroundColor: "success.light", color: "success.main" }}
                        >
                          <Typography variant="h3">{siteAuditData && (siteAuditData?.dailyCount || 0)}</Typography>
                          <Typography variant="h6">Daily schedule</Typography>
                        </BoxStyled>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <BoxStyled
                          onClick={() => {
                            if (siteAuditData?.weeklyCount > 0) handleClick("weekly");
                          }}
                          sx={{ backgroundColor: "warning.light", color: "warning.main" }}
                        >
                          <Typography variant="h3">{siteAuditData && (siteAuditData?.weeklyCount || 0)}</Typography>
                          <Typography variant="h6">Weekly schedule</Typography>
                        </BoxStyled>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <BoxStyled
                          onClick={() => {
                            if (siteAuditData?.monthlyCount > 0) handleClick("monthly");
                          }}
                          sx={{ backgroundColor: "error.light", color: "error.main" }}
                        >
                          <Typography variant="h3">{siteAuditData && (siteAuditData?.monthlyCount || 0)}</Typography>
                          <Typography variant="h6">Monthly schedule</Typography>
                        </BoxStyled>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        {loader ? <MainLoader message="Loading" /> : <Box className="HeaderWrapper">{renderChart()}</Box>}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} lg={12}>
              <Box ref={wrapperRef} sx={{ width: "100%" }}></Box>
            </Grid>
          </Grid>
          {loader ? (
            <MainLoader message="Loading" />
          ) : (
            <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
              <Grid item xs={12} md={12} lg={12}>
                <BoxMainWrapper>
                  <Box className="DataGridWrapper" style={{ width: wrapperWidth }}>
                    <DataGrid rows={rows} columns={columns} className="datagridnopage" pageSize={5} disableSelectionOnClick disableColumnMenu />
                  </Box>
                </BoxMainWrapper>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default Reports;
