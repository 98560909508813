import { useState, useEffect } from "react";
import { w3cwebsocket as W3cwebsocket } from "websocket";

function MyComponent() {
  const [message, setMessage] = useState("");
  const client = new W3cwebsocket("ws://localhost:4000/graphql");

  useEffect(() => {
    client.onopen = () => {
      console.log("WebSocket connected");
    };

    client.onmessage = event => {
      const data = JSON.parse(event.data);
      setMessage(data);
    };
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    const a = client.send(JSON.stringify({ type: "message", content: "Hello, world!" }));
    return a;
  };

  return (
    <div>
      <p>{message}</p>
      <button onClick={handleClick} type="button">
        Send message
      </button>
    </div>
  );
}
export default MyComponent;
