/* eslint-disable */
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Box, Container, Grid, Card, styled, useTheme, Paper, Button, Typography, useMediaQuery } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { debounce } from "lodash";
import { GetProjectByUserId } from "../../hooks/userWebsiteData";
import BackButton from "../BackButton/BackButton";
import MainLoader from "../MainLoader/MainLoader";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SearchBar from "../common/SearchBar";
import CreateProjectLink from "../createProject/CreateProjectLink";
import { usePopup } from "../../context/commonPop";

const SectionMainWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .SearchTopBarWrapper": {
    "& .SearchBarWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "15px",
      "@media screen and (max-width:600px)": {
        flexDirection: "column",
      },
      "& button": {
        textTransform: "none",
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
        borderRadius: "5px",
        boxShadow: "none",
        padding: "6px 22px",
        "@media screen and (max-width:600px)": {
          width: "100%",
        },
        "&:focus": {
          outline: "0",
        },
      },
    },
  },
  "& .BtnTopWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    "@media screen and (max-width:600px)": {
      flexDirection: "column",
    },
  },
  "& .DataGridWrapper": {
    "& .datagridnopage": {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: "7px 7px 7px 7px",
    },
    "& .datagridnopage.PaginationHide .MuiDataGrid-footerContainer": {
      display: "none",
    },
    "& .MuiDataGrid-main": {
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: theme.palette.mode === "dark" ? "#FFFFFF33" : "#2A3547",
        borderRadius: "7px",
        "& .MuiDataGrid-columnSeparator": {
          color: "#8F8F8F",
          visibility: "visible",
        },
        "& .MuiDataGrid-columnHeadersInner": {
          flex: "1",
          '& div[role="row"]': {
            flex: "1",
            width: "100%",
            "& .MuiDataGrid-columnHeader": {
              outline: "none !important",
              paddingLeft: "18px",
              paddingRight: "18px",
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                "& .MuiDataGrid-iconButtonContainer": {
                  display: "none",
                },
              },
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "14px",
            color: theme.palette.mode === "dark" ? "#ffffff" : "#ffffff",
            fontWeight: 600,
          },
        },
      },
      "& .MuiDataGrid-virtualScroller": {
        overflowX: "hidden",
        "&::-webkit-scrollbar-track": {
          borderRadius: "10px",
          backgroundColor: theme.palette.text.primary + "0d",
        },
        "&::-webkit-scrollbar": { width: "8px", backgroundColor: "transparent" },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: theme.palette.primary.main,
        },
        "& .MuiDataGrid-virtualScrollerContent": {
          // height: "400px !important",
          height: "calc(100vh - 295px) !important",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            width: "100%",
            "@media screen and (max-width:600px)": {
              width: "auto",
            },
            "& .MuiDataGrid-row": {
              boxShadow: "none",
              minHeight: "70px !important",
              width: "100%",
            },
            '& div[role="row"]': {
              cursor: "pointer",
              borderBottom: `2px solid ${theme.palette.divider}`,
              width: "100%",
              "&:last-of-type": { borderWidth: "1px" },
              "& .MuiDataGrid-cell": {
                minHeight: "70px !important",
                flex: 1,
                paddingLeft: "18px",
                paddingRight: "18px",
                "&:last-of-type": {
                  display: "none",
                  "@media screen and (max-width:600px)": {
                    display: "flex",
                  },
                },
                "&.MuiDataGrid-cell--withRenderer": {
                  "&:last-of-type": {
                    display: "flex",
                  },
                },
                "&:focus-within": { outline: "none" },
                "& .MuiDataGrid-cellContent": {
                  color: theme.palette.text.primary,
                  fontWeight: 600,
                },
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-footerContainer": {
      justifyContent: "center",
      "& .MuiTablePagination-root": {
        "& .MuiToolbar-root": {
          "& .MuiTablePagination-selectLabel": {
            marginBottom: "0",
          },
          "& .MuiTablePagination-displayedRows": {
            marginBottom: "0",
          },
        },
      },
    },
  },
}));

function KeywordsDashboard() {
  const navigate = useNavigate();
  const { handleOpen } = usePopup();
  const userData = useSelector(state => state.reducer.userData);
  const { allProjectWithSessionId, loading, paginationDetail, setPaginationDetail, totalPage, getData, search, setSearch, setSearchClick } = GetProjectByUserId({
    RequireSessionId: 1,
    isComplete: true,
  });
  const isMblBreakpoint = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const wrapperRef = useRef(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const customizer = useSelector(state => state.customizer);
  const apiRef = useGridApiRef();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 30,
  });
  const [loader, setLoader] = useState(false);
  // Memoize the data for pagination metadata
  const paginationMetaRef = useRef();
  const paginationMeta = useMemo(() => {
    if (totalPage !== undefined && paginationMetaRef.current?.totalPage !== totalPage) {
      paginationMetaRef.current = totalPage;
    }
    return paginationMetaRef.current;
  }, [paginationDetail]);

  // Function to handle pagination change
  const handlePageChange = newPage => {
    const { page, pageSize } = newPage || {};
    setPaginationDetail({ page, projectPerBundle: pageSize });
    getData(); // Fetch data for the new page
  };
  console.log(userData);
  const handleClick = item => {
    if (userData.subscriptionCredits.blogGeneration < 1) {
      return handleOpen(true, "You have used your blog genration limit. For generate more blog, please purchase or upgrade your plan!");
    }
    const { row } = item;
    if (row?.sessionId) {
      const pathname = `/urlKeywords/${row?.sessionId}?menu=headerKeywords`;
      const state = {
        projectId: row.id,
      };
      navigate(pathname, { state });
    }
  };

  const columnsData = [
    {
      field: "projectName",
      headerName: "Project",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "left",
      align: "left",
      width: 280,
      renderCell: params => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "10px",
            "& img": { width: "40px", height: "auto" },
            "& h4": { fontSize: "16px", fontWeight: 600, color: theme.palette.mode === "dark" ? "#ffffff" : "#000000" },
          }}
        >
          <Typography component="img" src={params.row.image} alt={params.value} />
          <Typography component="h4">{params.value}</Typography>
        </Box>
      ),
    },
    {
      field: "website-type",
      headerName: "Website Type",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: params => (
        <Box
          sx={{
            "& p": { fontSize: params?.row?.websiteType ? "12px" : "20px", fontWeight: 400, color: theme.palette.mode === "dark" ? "#ffffff" : "#000000" },
          }}
        >
          <Typography component="p">{params?.row?.websiteType || "--"}</Typography>
        </Box>
      ),
    },
    {
      field: "domain",
      headerName: "Domain",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "center",
      align: "center",
      width: 300,
      renderCell: params => (
        <Box
          sx={{
            "& a": { fontSize: "12px", fontWeight: 400 },
          }}
        >
          <Typography component="a" href={params.value} target="_blank">
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "center",
      align: "center",
      width: 180,
      renderCell: params => {
        return (
          <Box
            sx={{
              "& span": {
                color: params.row.status === true ? "#13DEB9" : "#FA896B",
                border: `1px solid ${params.row.status === true ? "#13DEB9" : "#FA896B"}`,
                borderRadius: "50px",
                backgroundColor: params.row.status === true ? "#13DEB933" : "#FA896B33",
                fontSize: "12px",
                padding: "5px 14px 5px",
              },
              "& p": { fontSize: "20px", fontWeight: 400, color: theme.palette.mode === "dark" ? "#ffffff" : "#000000" },
            }}
          >
            {params.row.status === true || params.row.status === false ? (
              <Typography component="span">{params.row.status === true ? "Connected" : params.row.status === false ? "Disconnected" : null}</Typography>
            ) : (
              <Typography component="p">--</Typography>
            )}
          </Box>
        );
      },
    },
    {
      field: "keywordsGenerate",
      headerName: "Keywords Generate",
      flex: isMblBreakpoint ? "" : 1,
      headerAlign: "right",
      align: "right",
      width: 200,
      renderCell: params => (
        <Button
          sx={{
            textTransform: "none",
            backgroundColor: theme.palette.primary.main,
            color: "#ffffff",
            borderRadius: "5px",
            boxShadow: "none",
            padding: "6px 22px",
            "&.Mui-disabled": {
              opacity: 0.3,
              color: "#fff",
            },
            "&:focus": {
              outline: "0",
            },
          }}
          disabled={params.value}
        >
          Generate
        </Button>
      ),
    },
  ];
  const rows = allProjectWithSessionId.map((row, indx) => {
    const sessionId = row.screapySession[0]?.sessionId;

    const disabled = !sessionId;
    return {
      id: row.id,
      projectName: row.projectName,
      websiteType: row.appType,
      status: row.isConnected,
      image: row.imageUrl,
      domain: row.website,
      keywordsGenerate: disabled,
      sessionId,
    };
  });

  const debouncedHandleChange = useCallback(
    debounce(value => {
      setSearch(value);
    }, 300),
    [],
  );

  const handleSearchChange = e => {
    debouncedHandleChange(e.target.value);
  };

  useEffect(() => () => debouncedHandleChange.cancel(), [debouncedHandleChange]); // eslint-disable-line
  useEffect(() => {
    getData();
  }, [search]);
  // Data Table Dynamic Width Set Start

  useEffect(() => {
    const handleResize = entries => {
      for (let entry of entries) {
        setLoader(true);
        setWrapperWidth(entry.contentRect.width);
        setTimeout(() => {
          setLoader(false);
        }, 300);
      }
    };
    const observer = new ResizeObserver(handleResize);
    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }
    return () => {
      if (wrapperRef.current) {
        observer.unobserve(wrapperRef.current);
      }
    };
  }, [wrapperRef]);

  // Data Table Dynamic Width Set End
  useEffect(() => {
    setLoader(!loader);
  }, [customizer.isCollapse]);

  return (
    <>
      <SectionMainWrapper>
        <Container maxWidth="">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} md={12}>
                <Box ref={wrapperRef} sx={{ width: "100%" }}>
                  <Card
                    sx={{
                      padding: "16px 16px 16px 16px",
                      border: `1px solid ${theme.palette.divider}`,
                      boxShadow: "none !important",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "15px",
                      "@media screen and (max-width:600px)": {
                        flexDirection: "column",
                        alignItems: "stretch",
                      },
                    }}
                  >
                    <Box className="BtnTopWrapper">
                      <BackButton sx={{ "& button": { marginBottom: "0 !important" } }} />
                      <CreateProjectLink />
                    </Box>
                    <Box className="SearchTopBarWrapper">
                      <Box className="SearchBarWrapper">
                        <SearchBar handleChange={handleSearchChange} />
                        <Button>Search</Button>
                      </Box>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            </Grid>
            {loading || loader ? (
              <MainLoader message="Loading..." />
            ) : (
              <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
                <Grid item xs={12} lg={12}>
                  <Box className="DataGridWrapper" style={{ width: wrapperWidth }}>
                    <DataGrid
                      rows={rows}
                      columns={columnsData}
                      pageSize={false}
                      pagination={false}
                      className="datagridnopage"
                      onCellClick={params => handleClick(params)}
                      disableColumnMenu
                      apiRef={apiRef}
                      rowCount={totalPage}
                      loading={loading}
                      paginationMode="server"
                      paginationModel={paginationModel}
                      pageSizeOptions={30}
                      onPaginationModelChange={model => {
                        setPaginationModel(model);
                        handlePageChange(page);
                      }}
                      initialState={{
                        pagination: { paginationModel },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Container>
      </SectionMainWrapper>
    </>
  );
}
export default KeywordsDashboard;
