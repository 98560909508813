function RightCheckMark() {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="RightCheckMark">
      <path
        d="M5.17403 7.70522L11.9646 0.73234C11.9647 0.732284 11.9647 0.732227 11.9648 0.732171C12.0315 0.663507 12.1111 0.608738 12.1991 0.571257C12.2871 0.53374 12.3817 0.514355 12.4774 0.514355C12.5731 0.514355 12.6677 0.53374 12.7557 0.571257C12.8437 0.608735 12.9233 0.663501 12.99 0.73216L5.17403 7.70522ZM5.17403 7.70522L2.03497 4.48134L2.03494 4.48132C1.89991 4.34268 1.71563 4.2638 1.52233 4.2638C1.32903 4.2638 1.14475 4.34268 1.00971 4.48132C0.874857 4.61978 0.8 4.80647 0.8 5.00007C0.8 5.19367 0.874857 5.38036 1.00971 5.51882L4.66123 9.2678L4.80468 9.12843L4.6614 9.26798L4.66125 9.26782C4.72803 9.33655 4.80765 9.39138 4.89569 9.42888C4.98375 9.4664 5.07835 9.48578 5.17402 9.48578C5.26969 9.48578 5.36429 9.4664 5.45235 9.42888C5.5404 9.39137 5.62002 9.33654 5.68681 9.2678L12.9898 1.76984L5.17403 7.70522ZM13.1456 0.970687C13.1097 0.881861 13.057 0.800827 12.9902 0.732342L12.99 1.76966C13.0569 1.70113 13.1097 1.62003 13.1456 1.53113C13.1816 1.44215 13.2 1.34694 13.2 1.25091C13.2 1.15487 13.1816 1.05967 13.1456 0.970689L12.9602 1.04563L13.1456 0.970687Z"
        fill="#45C0CB"
        stroke="#45C0CB"
        strokeWidth="0.4"
      />
    </svg>
  );
}

export default RightCheckMark;
