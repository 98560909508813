/* eslint-disable */
import { Box, Card, Container, Grid, Typography, styled, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { lazy, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
import { GET_REPORT_DATE_WISE_FOR_ADMIN } from "../../gql/queries/scrapy";
import WelcomeCard from "./WelcomeCard";
import { GET_ALL_TICKET_GRAPH_VALUE } from "../../gql/queries/ticket";
import { GET_ALL_USER_GRAPH_VALUE } from "../../gql/queries/users";
import { DatePickerRangeComponent } from "./DatePickerRange";
import { useAllUserGraphValue } from "../../hooks/user";
const Chart = lazy(() => import("react-apexcharts"), { ssr: false });

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "60px",
  "& .MainHeadingWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
    "& .MainHeading": { fontSize: "18px", fontWeight: "600" },
    "& a": {
      "& .ViewMore": {
        color: theme.palette.primary.main,
        fontSize: "13px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "4px",
        "& svg.MuiSvgIcon-root": { fontSize: "14px" },
      },
    },
  },
}));

function AllPage() {
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [reportsData, setReportsData] = useState({ seriesData: [], allDates: [] });
  const [ticketsData, setTicketsData] = useState({ seriesData: [], allDates: [] });
  // const [usersData, setUsersData] = useState({ updatedSeriesData: [], seriesData: [], uniqueDateArray: [] });
  // const [allUsersQuery] = useLazyQuery(GET_ALL_USER_GRAPH_VALUE, { fetchPolicy: "network-and-cache" });
  const [allTicketsQuery] = useLazyQuery(GET_ALL_TICKET_GRAPH_VALUE, { fetchPolicy: "network-and-cache" });
  const [allReports] = useLazyQuery(GET_REPORT_DATE_WISE_FOR_ADMIN, { fetchPolicy: "network-and-cache" });
  const theme = useTheme();
  const { usersData, getGraphData } = useAllUserGraphValue(startDate, endDate);
  const customizer = useSelector(state => state.customizer);
  const borderColor = theme.palette.divider;

  const handleDateRangeChange = newValue => {
    const [newStart, newEnd] = newValue;
    setStartDate(newStart ? new Date(newStart) : null);
    setEndDate(newEnd ? new Date(newEnd) : null);
  };

  async function fetchData() {
    await allReports({
      variables: {
        timeStamp: {
          startDate,
          endDate,
        },
      },
      onCompleted: ({ getSuperAdminForAllReportsGraphValue }) => {
        setReportsData(getSuperAdminForAllReportsGraphValue);
      },
      onError: err => {
        console.log(err);
        // setLoading(false);
      },
    });
    await allTicketsQuery({
      variables: {
        timeStamp: {
          startDate,
          endDate,
        },
      },
      onCompleted: ({ getGraphAllTicketsForSuperAdmin }) => {
        setTicketsData(getGraphAllTicketsForSuperAdmin);
      },
      onError: err => {
        console.log(err);
        // setLoading(false);
      },
    });
    await getGraphData();
  }

  const renderChart = () => (
    <Chart
      options={{
        xaxis: { categories: reportsData.allDates || [] },
        dataLabels: { enabled: false },
        chart: { toolbar: { show: false } },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        colors: ["#77B6EA", "#FF4560", "#40d338"],
      }}
      series={[
        { name: "Completed", data: reportsData?.seriesData.map(data => data[0]) },
        { name: "Failed", data: reportsData?.seriesData.map(data => data[1]) },
        { name: "Progress", data: reportsData?.seriesData.map(data => data[2]) },
      ]}
      type="bar"
      height={360}
    />
  );

  const fetchSignupData = () => {
    const concatenatedArray = [...usersData.seriesData, ...usersData.updatedSeriesData];
    
    return (
      <Chart
        height={360}
        options={{
          chart: {
            type: "line",
            zoom: { enabled: true },
            toolbar: { show: false },
          },
          xaxis: {
            type: "category",
            categories: usersData.uniqueDateArray.map(data => data.x), // Set the parsed dates as categories
            tickPlacement: "between",
          },
          yaxis: {
            min: 0,
            max: 24,
            tickAmount: 12,
            labels: {
              formatter: val => `${Math.floor(val)}`,
            },
          },
          tooltip: {
            x: {
              formatter: val => `Date & Time: ${val}`,
            },
            y: {
              formatter: (val, { dataPointIndex }) => {
                const dataPoint = concatenatedArray[dataPointIndex];
                const name = dataPoint ? dataPoint.x : "Unknown";

                // return "";
                return `User: ${name}`;
              },
            },
          },
          markers: {
            size: 5,
          },
          dataLabels: {
            enabled: false,
          },
        }}
        series={[
          {
            name: "Created Users",
            data: usersData.seriesData.map(data => data.y), // Make sure data.y exists
          },
          {
            name: "Logged In Users",
            data: usersData.updatedSeriesData.map(data => data.y), // Make sure data.y exists
          },
        ]}
      />
    );
  };

  const renderTicketChart = () => (
    <Chart
      options={{
        xaxis: { categories: ticketsData?.allDates },
        // yaxis: {
        //   max: 21,
        //   min: 0,
        //   tickAmount: 3,
        // },
        dataLabels: { enabled: false },
        chart: { toolbar: { show: false } },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        colors: ["#77B6EA", "#FF4560", "#FFC0CB"],
      }}
      series={[
        { name: "Open", data: ticketsData?.seriesData.map(data => data[0]) },
        { name: "Closed", data: ticketsData?.seriesData.map(data => data[1]) },
        { name: "Pending", data: ticketsData?.seriesData.map(data => data[2]) },
      ]}
      type="bar"
      height={360}
    />
  );

  const handleFetchData = () => {
    fetchData();
  };
  useEffect(() => {
    fetchData();
  }, [theme]);

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12} md={12} lg={12}>
              <WelcomeCard />
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <DatePickerRangeComponent handleFetchData={handleFetchData} handleDateRangeChange={handleDateRangeChange} startDate={startDate} endDate={endDate} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card
                sx={{ padding: "30px 45px 24px 40px", border: customizer.isCardShadow ? `1px solid ${borderColor}` : "none", height: "100%" }}
                elevation={customizer.isCardShadow ? 9 : 0}
                variant={!customizer.isCardShadow ? "outlined" : undefined}
              >
                <Box className="MainHeadingWrapper">
                  <Typography component="h5" className="MainHeading">
                    Report Details
                  </Typography>
                  <Link to="/reports?menu=headerAnalyticDashboard">
                    <Typography component="span" className="ViewMore">
                      View More Details <OpenInNewIcon />
                    </Typography>
                  </Link>
                </Box>

                <Box className="HeaderWrapper">{renderChart()}</Box>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card
                sx={{ padding: "30px 45px 24px 40px", border: customizer.isCardShadow ? `1px solid ${borderColor}` : "none", height: "100%" }}
                elevation={customizer.isCardShadow ? 9 : 0}
                variant={!customizer.isCardShadow ? "outlined" : undefined}
              >
                <Box className="MainHeadingWrapper">
                  <Typography component="h5" className="MainHeading">
                    User Details
                  </Typography>
                  <Link to="/users?menu=headerAnalyticDashboard">
                    <Typography component="span" className="ViewMore">
                      View More Details <OpenInNewIcon />
                    </Typography>
                  </Link>
                </Box>
                <Box className="HeaderWrapper">{fetchSignupData()}</Box>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card
                sx={{ padding: "30px 45px 24px 40px", border: customizer.isCardShadow ? `1px solid ${borderColor}` : "none", height: "100%" }}
                elevation={customizer.isCardShadow ? 9 : 0}
                variant={!customizer.isCardShadow ? "outlined" : undefined}
              >
                <Box className="MainHeadingWrapper">
                  <Typography component="h5" className="MainHeading">
                    Ticket Details
                  </Typography>
                  <Link to="/ticketList?menu=headerAnalyticDashboard">
                    <Typography component="span" className="ViewMore">
                      View More Details <OpenInNewIcon />
                    </Typography>
                  </Link>
                </Box>
                <Box className="HeaderWrapper">{renderTicketChart()}</Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default AllPage;
