import gql from "graphql-tag";

export const LIST_ALL_USERS = gql`
  query listAllUsers {
    listAllUsers {
      email
      isAdmin
      isActive
      registrationDate
      lastLogin
      uuid
    }
  }
`;

export const SCRAPY_SUBSCRIPTION = gql`
  subscription Subscription($sessionId: String!) {
    scrapyResponse(sessionId: $sessionId) {
      sessionId
      isCompleted
      authorId
      createdAt
      updatedAt
    }
  }
`;

export const GET_KEYWORD = gql`
  mutation Mutation($keywordInput: KeywordArg!) {
    getKeywordTag(keywordInput: $keywordInput)
  }
`;
export const GET_TITLE = gql`
  mutation GetKeywordTitle($keywords: [String!]!) {
    getKeywordTitle(keywords: $keywords)
  }
`;
export const GET_GEMINI_TITLE = gql`
  mutation Mutation($keywords: [String!]!) {
    getGeminiTitle(keywords: $keywords)
  }
`;

export const GET_BLOG = gql`
  mutation Mutation($blogInput: BlogInputs!) {
    generateBlog(blogInput: $blogInput)
  }
`;
export const GET_GEMINI_BLOG = gql`
  mutation GenerateGeminiBlog($blogInput: BlogInputs!) {
    generateGeminiBlog(blogInput: $blogInput)
  }
`;
export const FIND_MANY_TITLE_META_DECS = gql`
  query FindTitleMetaData($inputArg: TitleMetaDTO!) {
    FindTitleMetaData(inputArg: $inputArg) {
      flag
      id
      isManual
      meta_description
      sessionId
      title
      url
      userId
    }
  }
`;
export const ADVANCE_BLOG_GEN = gql`
  mutation Mutation($blogInput: AdvanceBlogInputs!) {
    advanceBlogContent(blogInput: $blogInput)
  }
`;
export const ADVANCE_SUBTOPIC_BLOG_GEN = gql`
  mutation AdvanceSubtopicBlogContent($blogInput: SubTopicBlogInputs!) {
    advanceSubtopicBlogContent(blogInput: $blogInput)
  }
`;
