import { memo } from "react";
import { useMutation } from "@apollo/client";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { authSignupByGoogle } from "../gql/mutations/auth";
import useQuery from "../hooks/allQuery";
import { usePopup } from "../context/commonPop";
import { SignupUser, setThemeConfig } from "../redux/action";
import { saveLocalTokens } from "../utils/session";

const googleConnectStyle = { textAlign: "center" };

function LoginGoogle() {
  const [SIGN_IN_BY_GOOGLE] = useMutation(authSignupByGoogle);
  const { allQuery } = useQuery();
  const { handleOpen } = usePopup();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const responseGoogle = credentialResponse => {
    const tokenId = credentialResponse.credential;
    allQuery({
      QUERY: SIGN_IN_BY_GOOGLE,
      variables: {
        tokenId,
      },
      onCompleteFunc: async data => {
        const { accessToken, refreshToken } = data.signInByGoogleAuth;

        if (accessToken && accessToken.length > 0) {
          localStorage.setItem("token", accessToken);
          saveLocalTokens({
            accessToken,
            refreshToken,
          });
          dispatch(
            SignupUser({
              ...data.signInByGoogleAuth.user,
              subscription: data?.signInByGoogleAuth?.subscription,
              subscriptionCredits: data?.signInByGoogleAuth?.subscriptionCredits,
            }),
          );
          if (data.signInByGoogleAuth.user?.userTheme) {
            dispatch(setThemeConfig(data.signInByGoogleAuth.user?.userTheme));
          }

          window.dispatchEvent(new Event("storage"));
          navigate("/dashboard?menu=dashboard");
        }
      },
      onErrorFunc: errdata => {
        console.log(errdata);
        handleOpen(true, errdata?.message || "Something went wrong");
      },
    });
  };
  return (
    <Box sx={googleConnectStyle}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          onSuccess={credentialResponse => {
            responseGoogle(credentialResponse);
          }}
          onError={() => {
            console.log("Login-Failed");
            handleOpen(true, "Something went wrong");
          }}
          shape="square"
          theme="filled_blue"
          size="medium"
          width="300px"
        />
      </GoogleOAuthProvider>
    </Box>
  );
}

export default memo(LoginGoogle);
