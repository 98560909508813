/* eslint-disable */
import React from "react";
import { useTheme } from "@mui/material/styles";

// mui imports
import { ListItemIcon, styled, ListItemText, Box, ListItemButton } from "@mui/material";

// custom imports
import NavItem from "../NavItem/NavItem";

// plugins
import { IconChevronDown } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// FC Component For Dropdown Menu
const NavCollapse = ({ menu, level, pathWithoutLastPart, pathDirect, hideMenu, onClick }) => {
  const Icon = menu.icon;
  const theme = useTheme();
  const location = useLocation();
  const { pathname } = location;
  const [open, setOpen] = React.useState(false);
  const customizer = useSelector(state => state.customizer);
  const menuIcon = level > 1 ? <Icon stroke={1.5} size="1rem" /> : <Icon stroke={1.5} size="1.1rem" />;

  React.useEffect(() => {
    setOpen(false);
    menu.children.forEach(item => {
      if (item.href === pathname) {
        setOpen(true);
      }
    });
  }, [pathname, menu.children]);

  const ListItemStyled = styled(ListItemButton)(() => ({
    width: "auto",
    padding: "5px 10px",
    position: "relative",
    flexGrow: "unset",
    gap: "10px",
    borderRadius: `${customizer.borderRadius}px`,
    whiteSpace: "nowrap",

    color: open || pathname.includes(menu.href) || level < 1 ? theme.palette.text.primary : theme.palette.text.secondary,
    // backgroundColor: open || pathname.includes(menu.href) ? theme.palette.primary.main : "",

    "&:hover": {
      backgroundColor: open || pathname.includes(menu.href) ? theme.palette.primary.light : theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    "&:hover > .SubNav": { display: "block", paddingTop: "15px" },
  }));

  const ListSubMenu = styled(Box)(() => ({
    display: "none",
    position: "absolute",
    top: level > 1 ? `0px` : "35px",
    left: level > 1 ? `${level + 228}px` : "0px",
    padding: "10px",
    width: "250px",
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[8],
    backgroundColor: theme.palette.background.paper,
    marginBottom: "15px",
  }));

  const listItemProps = {
    component: "li",
  };

  // If Menu has Children
  const submenus = menu.children?.map(item => {
    if (item.children) {
      return <NavCollapse key={item.id} menu={item} level={level + 1} pathWithoutLastPart={pathWithoutLastPart} pathDirect={pathDirect} hideMenu={hideMenu} onClick={onClick} />;
    } else {
      return <NavItem key={item.id} item={item} level={level + 1} pathDirect={pathDirect} hideMenu={hideMenu} onClick={onClick} />;
    }
  });

  return (
    <React.Fragment key={menu.id}>
      <ListItemStyled {...listItemProps} selected={pathWithoutLastPart === menu.href} className={open ? "selected" : ""}>
        <ListItemIcon
          sx={{
            minWidth: "auto",
            p: "3px 0",
            color: "inherit",
          }}
        >
          {menuIcon}
        </ListItemIcon>
        <ListItemText color="inherit" sx={{ mr: "auto" }}>
          {menu.title}
        </ListItemText>
        <IconChevronDown size="1rem" />
        <ListSubMenu component={"ul"} className="SubNav">
          {submenus}
        </ListSubMenu>
      </ListItemStyled>
    </React.Fragment>
  );
};

export default NavCollapse;
