/* eslint-disable */
import { Typography, TextField, FormControl, Select, Box, CircularProgress, Button, Grid, InputLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import { UpdateDataUserVerify } from "../../gql/mutations/userWebSiteVisit";
import { ownwerDomainCreate } from "../../redux/action";
import { validateString } from "../Validate/validate";
import { usePopup } from "../../context/commonPop";
import { GetUserWebsiteData } from "../../hooks/userWebsiteData";
import { extractDomainAndProtocol } from "../../common/utils";
import { Container } from "@mui/system";
import MessagePopUp from "../Modal/messagePopUp";
import darkLogoImage from "../../Images/hotSpotLogoBlue.svg";
import MannualSteps from "./MannualSteps";
import BackButton from "../BackButton/BackButton";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "100vh",
  backgroundColor: "#EFF3FB",
  paddingTop: "30px",
  paddingBottom: "30px",
  "& .LogoWrapper": {
    textAlign: "center",
    "& img": { width: "105px", height: "auto" },
  },
  "& .importProjectbox": {
    backgroundColor: "#fff",
    padding: "20px",
    border: "1px solid #E3E3E3",
    borderRadius: "8px",
    "& .InputWrapper": {
      "& .selectInput": {
        width: "40%",
      },
      "& .MuiFormControl-root": {
        marginTop: "7px",
        "& fieldset": {
          borderColor: " #E3E3E3 !important",
        },
        "&.FullWidthInput": {
          flex: "100%",
        },
        "& label": { lineHeight: 1 },
      },
      "& label": { color: theme.palette.text.primary, fontSize: "12px", fontWeight: 600, lineHeight: "1", textAlign: "left" },
    },
  },
  "& .ImportMainHead": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "@media screen and (max-width:600px)": {
      flexDirection: "column",
      marginBottom: "20px",
      gap: "20px",
    },
    "& .MainHeading": { color: "#2A3547", textAlign: "center", lineHeight: 1, fontSize: "24px", fontWeight: 700, marginBottom: "10px" },
    "& .SubHeading": {
      color: "#757575",
      textAlign: "center",
      lineHeight: 1,
      fontSize: "14px",
      fontWeight: 500,
    },
  },
  "& .continueBtn": {
    textTransform: "none",
    backgroundColor: "#45C0CB",
    color: "#ffffff",
    borderRadius: "5px",
    boxShadow: "none",
    padding: "12px 22px 10px",
    width: "100%",
    "&:hover": { backgroundColor: "#45C0CB" },
    "&:focus": {
      outline: "0",
      backgroundColor: "#45C0CB",
    },
    "& .MuiCircularProgress-circle": {
      color: "#ffffff",
    },
  },
  "& .backBtnBox": {
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  "& .MainHeading h2": {
    color: "#2A3547",
    textAlign: "center",
    lineHeight: 1,
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "10px",
  },
  "& .SubHeading": {
    color: "#757575",
    textAlign: "center",
    lineHeight: 1,
    fontSize: "14px",
    fontWeight: 500,
  },
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "15px",
  "& .InputStyleWrapper": {
    width: "calc(50% - 15px)",
    "& .css-px39cz-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled": {
      color: `${theme.palette.text.primary} !important`,

      "& input": {
        color: `${theme.palette.text.primary} !important`,

        "&::placeholder": {
          color: `${theme.palette.text.primary} !important`,
        },
      },
    },
  },
  "&  .menuitem": {
    color: `${theme.palette.text.primary} !important`,
  },
  "& .InputStyleWrapperFullWidth": {
    width: "calc(100% - 15px)",
  },
  "& .selectWrapper": {
    color: theme.palette.text.primary,
    "& .Mui-disabled": {
      textFillColor: theme.palette.text.primary,
      opacity: "0.5",
    },

    "& .css-1wu7ecg-MuiSvgIcon-root-MuiSelect-icon, .css-gofekn-MuiSvgIcon-root-MuiSelect-icon": {
      fill: theme.palette.text.primary,
    },
    "& .css-1b9x3a-MuiInputBase-root-MuiOutlinedInput-root": {
      border: "none",
      "&:hover": {
        border: `1px solid ${theme.palette.text.primary} !important`,
      },
    },
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.text.primary} `,
      "&:hover": {
        border: `1px solid ${theme.palette.text.primary} !important`,
      },
    },
    "& .css-9425fu-MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.text.primary} !important`,
      "&:hover": {
        border: `1px solid ${theme.palette.text.primary} !important `,
      },
    },

    "& input": {
      color: theme.palette.text.primary,
    },
  },
}));

const BoxWrapper = styled(Box)(({ theme }) => ({
  "& h3": {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "25px",
    color: theme.palette.text.primary,
  },
  "& p.Description": {
    fontSize: "16px",
    marginBottom: "20px",
    color: "#757575",
  },
  "& button": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
}));

const loderfor = {
  isLoading: false,
  htmlTag: false,
  htmlFile: false,
  dnsLoading: false,
  googleLoding: false,
};
function UpDateChild({ ProjectData }) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(loderfor);
  const { ownerId } = useParams();
  const { handleOpen } = usePopup();
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
   console.log("ownerId", ownerId);
  const [UPDATE_USER_WEB_VISIT] = useMutation(UpdateDataUserVerify);
  const [formState, setFormState] = useState({
    scope: "",
    websiteUrl: "",
    projectName: "",
  });
  const [errorState, setErrorState] = useState({
    scope: false,
    projectName: false,
    websiteUrl: false,
  });
  const navigate = useNavigate();

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const errCheck = async data => {
    const scope = await validateString(data.scope);
    const projectName = await validateString(data.projectName);
    const websiteUrl = await validateString(data.websiteUrl);

    await setErrorState({
      ...errorState,
      scope,

      websiteUrl,
      projectName,
    });
    if (scope || projectName || websiteUrl) {
      return true;
    }
    return false;
  };
  const findOwnerById = async () => {
    if (ProjectData) {
      setLoader({ ...loader, isLoading: true });

      try {
        // await dispatch(ownwerDomainCreate(ProjectData));
        const { website, projectName } = ProjectData || {};
        const { domain, protocol } = extractDomainAndProtocol(website);
        const splitScope = `${protocol}//`;
        setFormState({ ...formState, scope: splitScope, websiteUrl: domain, projectName });
      } catch (error) {
        setLoader({ ...loader, isLoading: false });
        console.log("errdata", error.message);
        handleOpen(true, "Something went wrong please wait some time");
      }
    } else {
      console.log("owner id not found");
    }
  };

  const updateMutationWebSite = async payload => {
    await UPDATE_USER_WEB_VISIT({
      variables: { payload },
      onCompleted: ({ updateUserWebSiteVerify }) => {
        dispatch(ownwerDomainCreate(updateUserWebSiteVerify));
        const { website } = updateUserWebSiteVerify || {};
        const { domain, protocol } = extractDomainAndProtocol(website);
        setFormState({ ...formState, scope: protocol, websiteUrl: domain });
       navigate(`/first-project-config/${ownerId}`);
      },
      onError: errdata => {
        handleOpen(true, "Something went wrong please wait some time");
        console.log("UPDATE_USERVISIT", errdata.message);
      },
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const { projectName } = formState || {};
    const status = await errCheck(formState);
    if (!status && formState.scope !== null) {
      const payload = {
        id: ownerId,
        projectName,
      };
      await updateMutationWebSite(payload);
    }
  };
  useEffect(() => {
    findOwnerById();
    // eslint-disable-next-line
  }, [ProjectData]);
  return (
    <SectionWrapper>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          {open && <MessagePopUp message={message} open={open} setOpen={setOpen} />}
          <Grid container spacing={2} mt={0} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Box className="LogoWrapper">
                <Typography component="img" src={darkLogoImage} alt="Logo" />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={0} justifyContent="center">
            <Grid item xs={12} md={12}>
              <Box className="importProjectbox">
                <Grid container spacing={2} mt={0}>
                  <Grid item xs={12}>
                    <Box className="ImportMainHead">
                      <Box sx={{ flex: 1, width: { xs: "100%", sm: "unset" } }}>
                        <Box sx={{ width: { xs: "100%", sm: "50%" }, m: "auto", mb: { xs: "15px", sm: "30px" } }}>
                          <MannualSteps activeStep={0} />
                        </Box>
                      </Box>
                      <BackButton
                        sx={{
                          marginLeft: "-80px",
                          zIndex: 99999999,
                          "@media screen and (max-width:600px)": { marginLeft: "0" },
                          "& button": { marginBottom: "0 !important", backgroundColor: "#45C0CB !important" },
                        }}
                      />
                    </Box>
                    <Box className="MainHeading">
                      <Typography component={"h2"}>Update your first project</Typography>
                      <Typography className="SubHeading">Set up your website to start analyzing it</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box width="100%" className="InputWrapper" component="form" mb={0} onSubmit={event => handleSubmit(event)}>
                      <Box display="flex" justifyContent="space-between" width="100%" flexDirection={{ xs: "column", sm: "row" }} mb={2} gap={2}>
                        <Box flex={1}>
                          <Typography component={"label"}>Protocols</Typography>
                          <FormControl fullWidth variant="outlined" margin="normal" className="InputStyleWrapper">
                            <InputLabel id="protocol-select-label">Select http or https</InputLabel>
                            <Select
                              labelId="protocol-select-label"
                              label="Select http or https"
                              className="selectWrapper"
                              value={formState.scope ?? ""}
                              disabled
                              onChange={handleInputChange}
                              displayEmpty
                              error={errorState?.scope}
                              name="scope"
                            >
                              <MenuItem value="">None</MenuItem>
                              <MenuItem value="http://">HTTP</MenuItem>
                              <MenuItem value="https://">HTTPS</MenuItem>
                            </Select>
                            <Typography component="span" sx={{ color: "red" }}>
                              {errorState?.scope && "please select this scope"}
                            </Typography>
                          </FormControl>
                        </Box>
                        <Box flex={1}>
                          <Typography component={"label"}>Domain Name</Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            label="Enter your domain name"
                            name="websiteUrl"
                            value={formState.websiteUrl}
                            onChange={handleInputChange}
                            error={errorState?.websiteUrl}
                            disabled
                            placeholder="Domain or path"
                            className="InputStyleWrapper selectWrapper"
                            helperText={errorState.websiteUrl && "Please enter valid domain or path"}
                          />
                        </Box>
                      </Box>
                      <Typography component={"p"} mb={2}>
                        We recommend using the "http + https" protocol along with the non-www version of your domain. You'll get the most complete backlink profile and accurate
                        tracking data this way.
                      </Typography>
                      <Box mb={2}>
                        <Typography component={"label"}>Project Name</Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          label="Enter your project name"
                          name="projectName"
                          value={formState.projectName}
                          placeholder="Project Name"
                          onChange={handleInputChange}
                          className="InputStyleWrapperFullWidth selectWrapper"
                          error={errorState?.projectName}
                          helperText={errorState.projectName && "Please enter valid project Name"}
                        />
                      </Box>
                      <Button className="continueBtn" type="submit">
                        {"Update"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}
function UpdateProject() {
  const { ownerId } = useParams();
  const { ProjectData } = GetUserWebsiteData(ownerId);
  return <UpDateChild ProjectData={ProjectData} />;
}

export default UpdateProject;
