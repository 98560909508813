/* eslint-disable */
import { useEffect, useState } from "react";
import Report2Viewer from "react2-lighthouse-viewer";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import moment from "moment/moment";
import { Box, FormControl, MenuItem, Select, Button, Typography, styled, Container, Tabs, Tab, Grid, useTheme, CircularProgress } from "@mui/material";
import { googleJson } from "../../gql/queries/userWebSiteList";
import { usePopup } from "../../context/commonPop";
import { getLinks200 } from "../../gql/queries/projectDetail";
import { LIGHT_HOUSE, speedDataReport } from "../../gql/queries/scrapy";
import useQuery from "../../hooks/allQuery";
import { ligtHouseSave, pageSpeed, pauseLighthouseReport, resumeLighthouseReport } from "../../gql/mutations/scrapy";
import MainLoader from "../MainLoader/MainLoader";
import BackButton from "../BackButton/BackButton";
import WelcomeCardReport from "./WelcomeCardReport";
import WelcomeCard from "../dashboards/ecommerce/WelcomeCard";
import GenerateReport from "./GenerateReport";
import { useSelector } from "react-redux";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 70px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  paddingBottom: "20px",
  paddingTop: "20px",
  "& h3.MainHeading": { textAlign: "center", marginBottom: "20px" },
}));
const TabDataWrapper = styled(Box)(({ theme }) => ({
  minHeight: 224,
  "& button.Mui-selected": {
    color: theme.palette.common.white,
  },
  "& article": {
    backgroundColor: theme.palette.background.default,
    "& .lh-topbar": {
      backgroundColor: theme.palette.background.default,
      "& a.lh-topbar__url": { color: theme.palette.text.primary },
    },
    "& .lh-container": {
      "& .lh-header-container": {
        "& .lh-scores-wrapper": {
          "& .lh-scores-header": {
            "& a .lh-gauge__label": { color: theme.palette.text.primary },
          },
        },
      },
    },
    "& .lh-report": {
      "& .lh-categories": {
        "& .lh-category-wrapper": {
          "& .lh-category-header": {
            "& .lh-score__gauge": {
              "& .lh-gauge__percentage": { color: theme.palette.text.primary },
              "& .lh-gauge__label": { color: theme.palette.text.primary },
            },
            "& .lh-category-header__description": {
              "& .lh-metrics__disclaimer": {
                "& span": {
                  color: theme.palette.text.primary,
                  "& a": { color: theme.palette.primary.main },
                },
                "& a": { color: theme.palette.primary.main },
              },
              "& span": {
                color: theme.palette.text.primary,
                "& a": { color: theme.palette.primary.main },
              },
            },
            "& .lh-scorescale-wrap": {
              "& .lh-scorescale": {
                "& .lh-scorescale-range": { color: theme.palette.text.primary },
              },
            },
          },
          "& .lh-audit-group": {
            "& .lh-audit-group__header": {
              "& .lh-audit-group__title": { color: theme.palette.text.primary },
              "& .lh-metrics-toggle__label": { color: theme.palette.text.primary },
            },
            "& .lh-metrics-container": {
              "& .lh-metric": {
                "& .lh-metric__innerwrap": {
                  "& .lh-metric__title": { color: theme.palette.text.primary },
                  "& .lh-metric__description": {
                    "& span": {
                      color: theme.palette.text.primary,
                      "& a": { color: theme.palette.primary.main },
                    },
                  },
                },
              },
            },
            "& .lh-buttons": {
              "& button": {
                backgroundColor: theme.palette.primary.main,
                color: "#ffffff",
                border: "none",
                height: "40px",
                "&::before": { filter: "brightness(0) invert(1)" },
              },
            },
            "& details": {
              "&[open]": { outlineColor: theme.palette.text.primary + "2e!important" },
              "& span": { color: theme.palette.text.primary, "& code": { color: theme.palette.primary.main } },
              "& a": { color: theme.palette.primary.main },
              "& .lh-table.lh-details, .lh-list.lh-details": {
                "& thead": {
                  "& tr": {
                    "& th": { backgroundColor: theme.palette.background.default, color: theme.palette.text.primary, position: "unset" },
                  },
                },
                "& tbody": {
                  "& tr": {
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    "& td": {
                      color: theme.palette.text.primary,
                      "& pre": { color: theme.palette.text.primary },
                      "& .lh-node__snippet": { color: theme.palette.primary.main },
                    },
                  },
                },
              },
            },
          },
          "& .lh-metricfilter": {
            "& span.lh-metricfilter__text": { color: theme.palette.text.primary },
            "& label.lh-metricfilter__label": { color: theme.palette.text.primary },
          },
          "& .lh-audit-group.lh-audit-group--load-opportunities": {
            "& .lh-audit-group__header": { "& span": { color: theme.palette.text.primary } },
            "& .lh-load-opportunity__header": { "& .lh-load-opportunity__col": { color: theme.palette.text.primary } },
            "& .lh-audit.lh-audit--load-opportunity": {
              "& details": {
                "&[open]": { outlineColor: theme.palette.text.primary + "!important" },
                "& span": { color: theme.palette.text.primary },
                "& a": { color: theme.palette.primary.main },
                "& .lh-table.lh-details": {
                  "& thead": {
                    "& tr": {
                      "& th": { backgroundColor: theme.palette.background.default, color: theme.palette.text.primary, position: "unset" },
                    },
                  },
                  "& tbody": {
                    "& tr": {
                      backgroundColor: theme.palette.background.default,
                      color: theme.palette.text.primary,
                      "& td": {
                        color: theme.palette.text.primary,
                        "& .lh-node__snippet": { color: theme.palette.primary.main },
                      },
                    },
                  },
                },
              },
            },
          },
          "& .lh-audit-group.lh-audit-group--diagnostics": {
            "& .lh-audit-group__header": { "& span": { color: theme.palette.text.primary } },
            "& .lh-load-opportunity__header": { "& .lh-load-opportunity__col": { color: theme.palette.text.primary } },
            "& .lh-audit": {
              "& details": {
                "&[open]": { outlineColor: theme.palette.text.primary + "!important" },
                "& span": { color: theme.palette.text.primary },
                "& a": { color: theme.palette.primary.main },
                "& .lh-table.lh-details, .lh-list.lh-details": {
                  "& thead": {
                    "& tr": {
                      "& th": { backgroundColor: theme.palette.background.default, color: theme.palette.text.primary, position: "unset" },
                    },
                  },
                  "& tbody": {
                    "& tr": {
                      backgroundColor: theme.palette.background.default,
                      color: theme.palette.text.primary,
                      "& td": {
                        color: theme.palette.text.primary,
                        "& pre": { color: theme.palette.text.primary },
                        "& .lh-node__snippet": { color: theme.palette.primary.main },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
      "& footer": {
        "& ul.lh-meta__items": {
          backgroundColor: theme.palette.text.primary + "0d",
          padding: "28px 25px 24px 30px",
          "& li.lh-meta__item": { color: theme.palette.text.primary },
        },
        "& .lh-generated": {
          color: theme.palette.text.primary,
          "& a": { color: theme.palette.primary.main },
        },
      },
    },
  },
}));
const TabBtnWrapper = styled(Tabs)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "20px",
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
    gap: "20px",
    paddingTop: "15px",
    paddingBottom: "15px",
    "& .tabItem": {
      color: theme.palette.text.primary,
      width: "50%",
      borderRadius: "8px",
      boxShadow: "0px 0px 11px -6px #9f9f9f",
      "&.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: "#ffffff",
      },
      "&:focus": { outline: "none", border: "none" },
    },
  },
  "& .MuiTabs-indicator": { display: "none" },
}));

const SiteSelectWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-between",
  gap: "15px",
  "& .regenerate": {
    backgroundColor: theme.palette.primary.main,
    fontWeight: 700,
    color: "#fff",
    padding: "10px 35px",
    marginBottom: "15px",
  },
  "& .reportGenerate": {
    color: theme.palette.text.primary,
    fontWeight: 700,
    marginBottom: "8px",
  },
  "& .SiteSelectFormWrapper": { paddingBottom: "15px", "& #SiteSelectWrapper": { boxShadow: "0px 0px 11px -6px #9f9f9f" }, "& fieldset": { display: "none" } },
}));

function Report() {
  const [newstate, setNewstate] = useState({ createDate: null, loading: true, page: "", jsondata: null, reportDetail: null });
  const [pageList, setPagelist] = useState([]);
  const [value, setValue] = useState(0);
  const [reportData, setReportData] = useState(null);
  const { allQuery } = useQuery();
  const userData = useSelector(state => state.reducer.userData);
  const [GET_PAGE_SPEED_REPORT] = useLazyQuery(speedDataReport, { fetchPolicy: "cache-first" });
  const [GOOGLE_JSON] = useLazyQuery(googleJson, { fetchPolicy: "cache-and-network" });
  const [GET_LINKS] = useLazyQuery(getLinks200, { fetchPolicy: "cache-first" });
  const { handleOpen } = usePopup();
  const [generateReportLoading, setGenerateReportLoading] = useState(false);
  const { sessionId, type, projectid } = useParams();
  const navigate = useNavigate();
  const [PAGE_SPEED] = useMutation(pageSpeed, { fetchPolicy: "network-only" });
  const [LIGHT_HOUSE_SAVE] = useMutation(ligtHouseSave);
  const [PAUSE_REPORT] = useMutation(pauseLighthouseReport);
  const [RESUME_REPORT] = useMutation(resumeLighthouseReport);
  function reloadFunc() {
    const { mobilejson, desktopjson } = reportData;
    if (type === "mobile") {
      const { lighthouseResult } = mobilejson || {};
      setNewstate(prevState => ({ ...prevState, jsondata: lighthouseResult, reportDetail: reportData }));
    } else {
      const { lighthouseResult } = desktopjson || {};
      setNewstate(prevState => ({ ...prevState, jsondata: lighthouseResult, reportDetail: reportData }));
    }
  }

  const SpeedResponse = async () => {
    setGenerateReportLoading(true);

    allQuery({
      QUERY: GET_PAGE_SPEED_REPORT,
      variables: {
        projectId: projectid,
      },
      onCompleteFunc: ({ GetPageSpeedreport }) => {
        setReportData(GetPageSpeedreport);

        setGenerateReportLoading(false);
        setNewstate(prevState => ({ ...prevState, loading: false }));
      },
      onErrorFunc: errdata => {
        handleOpen(true, errdata.message);

        setGenerateReportLoading(false);
        setNewstate(prevState => ({ ...prevState, loading: false }));
        console.log("errdata", errdata);
      },
    });
  };

  const loadPage = () => {
    allQuery({
      QUERY: GET_LINKS,
      variables: {
        sessionId,
      },
      onCompleteFunc: ({ GetLinks200 }) => {
        setPagelist(GetLinks200);
      },
      onErrorFunc: errdata => {
        console.log("errdata", errdata);
      },
    });
  };

  const handleChange = event => {
    setNewstate({ ...newstate, page: event.target.value });
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const LoadData = async () => {
    setNewstate(prevState => ({ ...prevState, loading: true }));
    allQuery({
      QUERY: GOOGLE_JSON,
      variables: {
        data: {
          mobtype: type,
          website: newstate.page,
        },
      },
      onCompleteFunc: ({ GooglePageSpeedData }) => {
        if (GooglePageSpeedData) {
          const { lighthouseResult } = GooglePageSpeedData;
          setNewstate(prevState => ({
            ...prevState,
            loading: false,
            jsondata: lighthouseResult,
          }));
        }
      },
      onErrorFunc: errdata => {
        setNewstate(prevState => ({ ...prevState, loading: false }));
        console.log("errdata", errdata.message);
        handleOpen(true, "Something went wrong please wait some time");
      },
    });
  };

  const handleCreateReport = async () => {
    if (!sessionId || !projectid) {
      handleOpen(true, "First Create Screapy Report");
      return;
    }
    if (projectid && sessionId) {
      setGenerateReportLoading(true);
      allQuery({
        QUERY: LIGHT_HOUSE_SAVE,
        variables: { projectId: projectid, sessionId },
        onCompleteFunc: () => {
          setGenerateReportLoading(false);
        },
        onErrorFunc: err => {
          setGenerateReportLoading(false);
          console.log("err", err);
        },
        type: "create",
        cacheQueryData: {
          cacheQuery: LIGHT_HOUSE,
          variablesCache: {
            projectId: projectid,
          },
        },
      });
    }
  };

  const handlePauseReport = async () => {
    if (!sessionId || !projectid) {
      handleOpen(true, "Please provide both Project ID and Session ID to pause the report.");
      return;
    }

    if (projectid && sessionId) {
      setGenerateReportLoading(true);

      allQuery({
        QUERY: PAUSE_REPORT,
        variables: { projectId: projectid, sessionId },
        onCompleteFunc: () => {
          setGenerateReportLoading(false);
        },
        onErrorFunc: err => {
          setGenerateReportLoading(false);
          console.log("Error pausing report", err);
        },
        type: "pause",
        cacheQueryData: {
          cacheQuery: LIGHT_HOUSE,
          variablesCache: {
            projectId: projectid,
          },
        },
      });
    }
  };

  const handleResumeReport = async () => {
    if (!sessionId || !projectid) {
      handleOpen(true, "Please provide both Project ID and Session ID to resume the report.");
      return;
    }

    if (projectid && sessionId) {
      setGenerateReportLoading(true);

      allQuery({
        QUERY: RESUME_REPORT,
        variables: { projectId: projectid, sessionId },
        onCompleteFunc: () => {
          setGenerateReportLoading(false);
        },
        onErrorFunc: err => {
          setGenerateReportLoading(false);
          console.log("Error resuming report", err);
        },
        type: "resume",
        cacheQueryData: {
          cacheQuery: LIGHT_HOUSE,
          variablesCache: {
            projectId: projectid,
          },
        },
      });
    }
  };

  async function handleRegenerate() {
    setNewstate(prevState => ({ ...prevState, loading: true }));
    const { userId, projectId, website } = newstate.reportDetail || {};
    allQuery({
      QUERY: PAGE_SPEED,
      variables: {
        data: {
          userId,
          projectId,
          website,
          sessionId,
        },
      },
      onCompleteFunc: ({ PageSpeed }) => {
        const { mobilejson, desktopjson } = PageSpeed || {};
        if (type === "mobile") {
          const { lighthouseResult } = mobilejson || {};
          setNewstate(prevState => ({ ...prevState, jsondata: lighthouseResult, reportDetail: PageSpeed, loading: false }));
        } else {
          const { lighthouseResult } = desktopjson || {};
          setNewstate(prevState => ({
            ...prevState,
            jsondata: lighthouseResult,
            reportDetail: PageSpeed,
            loading: false,
          }));
        }
      },
      onErrorFunc: errdata => {
        console.log("error", errdata.message);
        setNewstate(prevState => ({ ...prevState, loading: false }));
        handleOpen(true, errdata.message);
      },
    });
  }

  useEffect(() => {
    if (newstate.page) {
      LoadData();
    }
  }, [newstate.page, value]);

  useEffect(() => {
    if (type === "mobile") {
      setValue(0);
    } else {
      setValue(1);
    }

    if (sessionId) {
      loadPage();
    }

    if (projectid) {
      SpeedResponse();
    }
  }, [projectid, sessionId]);

  useEffect(() => {
    if (reportData) reloadFunc();
  }, [reportData, type]);
  const theme = useTheme();
  const { reportStatus, isActiveReport } = reportData || {};
  const MainHeadWrapper = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h3": { fontSize: "22px", fontWeight: 600, lineHeight: "35.28px", color: theme.palette.mode === "dark" ? "#ffffff" : "#000000" },
  };

  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {!userData?.subscription?.active ? (
                <Grid item xs={12}>
                  <WelcomeCardReport projectid={projectid} sessionId={sessionId} />
                </Grid>
              ) : (
                <>
                  {reportData?.reportStatus === "Completed" || reportData?.reportStatus === "Progress" || reportData?.reportStatus === "Paused" ? (
                    <>
                      <Grid item xs={12}>
                        <WelcomeCardReport projectid={projectid} sessionId={sessionId} />
                      </Grid>
                      {generateReportLoading ? (
                        <CircularProgress />
                      ) : (
                        reportData?.reportStatus !== "Completed" && (
                          <GenerateReport
                            handleCreateReport={handleCreateReport}
                            handlePauseReport={handlePauseReport}
                            reportStatus={reportStatus}
                            handleResumeReport={handleResumeReport}
                            isActiveReport={isActiveReport}
                            generateReportLoading={generateReportLoading}
                          />
                        )
                      )}
                    </>
                  ) : (
                    <Box>
                      <WelcomeCard />
                      {generateReportLoading ? (
                        <CircularProgress />
                      ) : (
                        <GenerateReport
                          handleCreateReport={handleCreateReport}
                          handlePauseReport={handlePauseReport}
                          reportStatus={reportStatus}
                          handleResumeReport={handleResumeReport}
                          isActiveReport={isActiveReport}
                          generateReportLoading={generateReportLoading}
                        />
                      )}
                    </Box>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box sx={MainHeadWrapper}>
                <Typography component="h3">Report View</Typography>
                <BackButton sx={{ "& button": { marginBottom: "0 !important" } }} />
              </Box>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box sx={{ border: "1px solid #E4E4E4", p: "10px", borderRadius: "6px" }}>
                <Typography variant="h3" className="MainHeading">
                  Light House
                </Typography>
                <TabBtnWrapper value={value} scrollButtons="auto" aria-label="basic tabs example" onChange={handleTabChange}>
                  <Tab
                    label="Mobile"
                    className="tabItem"
                    onClick={() => {
                      navigate(`/performanceDashboard/${projectid}/${sessionId}/mobile?menu=performanceDashboard`);
                    }}
                  />
                  <Tab
                    label="Desktop"
                    className="tabItem"
                    onClick={() => {
                      navigate(`/performanceDashboard/${projectid}/${sessionId}/desktop?menu=performanceDashboard`);
                    }}
                  />
                </TabBtnWrapper>

                {/* {newstate.page ? (
                ""
              ) : (
                <>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button className="regenerate" onClick={() => handleRegenerate()} disabled={newstate.loading}>
                      Regenerate
                    </Button>
                  </Box>
                  {newstate.reportDetail && (
                    <Typography className="reportGenerate" component="h5">
                      Report Generated on Date:-{moment(newstate.reportDetail?.createdAt).format("DD/MM/YYYY, hh:mm a")}
                    </Typography>
                  )}
                </>
              )} */}

                {newstate.loading ? (
                  <MainLoader message="Loading" />
                ) : newstate.jsondata ? (
                  <>
                    {pageList.length > 0 && (
                      <SiteSelectWrapper>
                        <FormControl fullWidth className="SiteSelectFormWrapper">
                          {newstate.reportDetail && (
                            <Typography className="reportGenerate" component="h5">
                              Report Generated on Date:-{moment(newstate.reportDetail?.createdAt).format("DD/MM/YYYY, hh:mm a")}
                            </Typography>
                          )}
                          <Select labelId="demo-simple-select-label" id="SiteSelectWrapper" value={newstate.page || pageList?.[0].url} label="Age" onChange={handleChange}>
                            {pageList.map(pageArr => (
                              <MenuItem value={pageArr.url} key={pageArr.url}>
                                {pageArr.url}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {newstate.page ? (
                          ""
                        ) : (
                          <Button className="regenerate" onClick={() => handleRegenerate()} disabled={newstate.loading}>
                            Regenerate
                          </Button>
                        )}
                      </SiteSelectWrapper>
                    )}

                    <TabDataWrapper>
                      <Report2Viewer json={newstate.jsondata} />
                    </TabDataWrapper>
                  </>
                ) : (
                  <Box sx={{ height: "calc(100vh - 260px)", display: "flex", alignItems: "center", justifyContent: "center", "& h3": { fontSize: "18px", fontWeight: "700" } }}>
                    <Typography component="h3">Report not available for this project!</Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default Report;
