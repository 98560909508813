import Backdrop from "@mui/material/Backdrop";
import { Box, CircularProgress } from "@mui/material";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { memo } from "react";

const ModalWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,

  borderRadius: "20px",
  border: `5px solid ${theme.palette.common.secondory}`,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  padding: "35px 35px 34px",
  "@media screen and (max-width:600px)": {
    width: "90%",
  },
  "& h4": { fontSize: "22px", marginBottom: "8px", color: theme.palette.text.primary, fontWeight: 600 },
  "& p": { fontSize: "16px", marginBottom: "8px", color: theme.palette.text.primary },
  "& .BtnWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px",
    "& .deleteLoader": {
      width: "24.5px !important",
      height: "24.5px !important",
      "& svg": {
        color: "#fff",
      },
    },
    "& button": {
      width: "calc(50% - 8px)",
      backgroundColor: theme.palette.primary.main,
      color: "#fff",

      // border: "1px solid #ff642d !important",
      marginTop: "15px",
      "&:focus": { outline: "none" },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
      },
    },
  },
}));

function DeleteWebsitePopup({ open, setOpen, onSubmit, handleListItemClick, loading, onClose }) {
  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const handleSubmit = () => {
    if (onSubmit) onSubmit();
    if (handleListItemClick) handleListItemClick("Delete Project");
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalWrapper>
          <Typography component="h4">Important</Typography>
          <Typography component="p">Are you sure you want to delete, </Typography>
          <Box className="BtnWrapper">
            <Button disabled={loading} onClick={handleSubmit}>
              {" "}
              {loading ? <CircularProgress className="deleteLoader" /> : "Delete"}
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </Box>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
}

export default memo(DeleteWebsitePopup);
