import { Typography, TextField, FormControl, Select, Box, Button, Container, Grid, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { memo, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { createUserVisit } from "../../gql/mutations/userWebSiteVisit";
import { ownwerDomainCreate } from "../../redux/action";
import { validateString } from "../Validate/validate";
import { ValidationHttp, ValidationUri } from "../../common/validations";
import { usePopup } from "../../context/commonPop";
import MessagePopUp from "../Modal/messagePopUp";
import BackButton from "../BackButton/BackButton";
import useQuery from "../../hooks/allQuery";
import { SCRAPY_DATA } from "../../gql/queries/userWebSiteList";
import { GetScrapyData } from "../../hooks/scrapyData";

const SectionWrapper = styled("section")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  display: "flex",
  alignItems: "start",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
}));

const ButtonWrapper = {
  textAlign: "right",
  mt: "15px",
  "& button": {
    boxShadow: "none",
    textTransform: "none",
    background: "#ff642d",
    color: "#fff",
    borderRadius: "7px",
    padding: "9px 24px 9px",
    marginRight: "8px",

    "&:focus": { outline: "none" },
  },
};

const BoxWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "7px",
  padding: "54px 65px 50px",
  "@media screen and (max-width:600px)": {
    padding: "25px 20px 25px",
  },
  "& h3": {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "5px",
    color: theme.palette.text.primary,
  },
  "& p.Description": {
    fontSize: "16px",
    marginBottom: "20px",
    color: theme.palette.text.primary,
  },
  "& .submitBtn": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    width: "95px",
    "& .submitLoader": {
      width: "24px !important",
      height: "24px !important",

      "& svg": {
        color: "#fff",
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,

      color: "fff",
    },
  },
}));
const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "15px",
  "& .InputStyleWrapper": {
    width: "calc(50% - 15px)",
  },
  "&  .menuitem": {
    color: `${theme.palette.text.primary} !important`,
  },
  "& .InputStyleWrapperFullWidth": {
    width: "calc(100% - 15px)",
  },
  "& .selectWrapper": {
    color: theme.palette.text.primary,

    "& .css-1wu7ecg-MuiSvgIcon-root-MuiSelect-icon, .css-gofekn-MuiSvgIcon-root-MuiSelect-icon": {
      fill: theme.palette.text.primary,
    },

    "& .MuiOutlinedInput-root": {
      "&:hover": {
        border: "none !important",
        outline: "none",
      },
    },
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.text.primary} `,
      "&:hover": {
        borderColor: ` ${theme.palette.primary.main} !important`,
      },
    },

    "& .css-1b9x3a-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.text.primary,
    },
    "& .css-9425fu-MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.text.primary} `,
    },

    "&::placeholder": {
      color: theme.palette.text.primary,
    },
    "& input": {
      color: theme.palette.text.primary,
      "&:hover": {
        border: `none `,
        borderRadius: "5px",
      },
    },
    "&.css-1sdtvsx-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: ` ${theme.palette.text.primary} !important`,
    },
  },
}));
function CreateProject() {
  return (
    <SectionWrapper>
      <Container maxWidth="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} mt={0} sx={{ justifyContent: "center" }}>
            <Grid item xs={12}>
              <BackButton />
              <BoxWrapper>
                <Typography component="h3">Create a project</Typography>
                <Typography component="p" className="Description">
                  Set up your website to start analyzing it.
                </Typography>
                <MemoizedForm />
              </BoxWrapper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
}

export default CreateProject;

function Form() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const { handleOpen } = usePopup();
  const { allQuery } = useQuery();
  const [formState, setFormState] = useState({
    scope: "",
    websiteUrl: "",
    projectName: "",
  });
  const [errorState, setErrorState] = useState({
    scope: false,
    projectName: false,
    websiteUrl: false,
  });
  const { userData } = useSelector(state => state.reducer);
  const [createAPI] = useMutation(createUserVisit, { fetchPolicy: "network-only" });
  const navigate = useNavigate();
  const { scrapyData } = GetScrapyData();

  const [varifiedPrtojectLength, setVarifiedPrtojectLength] = useState(0);

  useEffect(() => {
    if (scrapyData && Array.isArray(scrapyData)) {
      const varifiedProjects = scrapyData.filter(project => project.isVerified);

      setVarifiedPrtojectLength(varifiedProjects.length);
    }
  }, [scrapyData]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const errCheck = async data => {
    const scope = await validateString(data.scope);
    const projectName = await validateString(data.projectName);
    const websiteUrl = await validateString(data.websiteUrl);

    await setErrorState({
      ...errorState,
      scope,

      websiteUrl,
      projectName,
    });
    if (scope || projectName || websiteUrl) {
      return true;
    }
    return false;
  };

  const createOwnerSiteData = async payload => {
    allQuery({
      QUERY: createAPI,
      variables: {
        payload,
      },
      type: "create",
      cacheQueryData: {
        cacheQuery: SCRAPY_DATA,
        variablesCache: {
          params: userData?.id,
        },
      },
      onCompleteFunc: async ({ userWebSite }) => {
        setLoader(false);
        dispatch(ownwerDomainCreate(userWebSite));
        if (userWebSite) {
          navigate(`/verify/${userWebSite.id}`);
        }
      },
      onErrorFunc: err => {
        setLoader(false);
        handleOpen(true, err.message || "something went wrong please try again later");
        console.log("err createApi", err.message);
      },
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (userData?.subscriptionCredits?.projects <= varifiedPrtojectLength) {
      handleOpen(true, "You have reached the limit of creating projects. To create more projects, please purchase or upgrade your plan!");
      return;
    }
    setLoader(true);
    const { projectName, websiteUrl, scope } = formState || {};
    const status = await errCheck(formState);
    if (!status && formState.scope !== null) {
      if (ValidationHttp({ url: websiteUrl })) {
        const hash = await hashCode(websiteUrl, userData.email);
        const str = `dwt-${hash}`;
        const payload = {
          token: str,
          userId: userData.id,
          projectName,
          website: websiteUrl,
        };
        await createOwnerSiteData(payload);
      } else {
        const Createstr = `${scope}${websiteUrl}`;
        if (ValidationUri({ uri: Createstr }) && scope) {
          const hash = await hashCode(Createstr, userData.email);
          const str = `dwt-${hash}`;
          const payload = {
            token: str,
            userId: userData.id,
            website: Createstr,
            projectName,
          };

          await createOwnerSiteData(payload);
        } else {
          setLoader(false);
          setOpen(true);
          setMessage("Please enter valid domain or path");
        }
      }
    } else {
      setLoader(false);
      console.log("this not call");
    }
  };

  const hashCode = (website, email) => {
    const str = `${website}${email}`;
    let hash = 0; // Use 'let' instead of 'var'
    let chr; // Declare 'chr' using 'let'
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = (hash << 5) - hash + chr;
      // eslint-disable-next-line no-bitwise
      hash |= 0;
    }
    return Math.abs(hash);
  };
  return (
    <>
      {open && <MessagePopUp message={message} open={open} setOpen={setOpen} />}

      <Box component="form" onSubmit={event => handleSubmit(event)} sx={{ mb: "0" }}>
        <InputWrapper>
          <FormControl className="InputStyleWrapper">
            <Select className="selectWrapper" value={formState.scope ?? ""} onChange={handleInputChange} displayEmpty error={errorState?.scope} name="scope">
              <MenuItem className="menuitem" value="">
                None
              </MenuItem>
              <MenuItem className="menuitem" value="http://">
                HTTP
              </MenuItem>
              <MenuItem className="menuitem" value="https://">
                HTTPS
              </MenuItem>
            </Select>
            <Typography component="span" sx={{ color: "red", fontSize: "12px", ml: "14px" }}>
              {errorState?.scope && "please select this scope"}
            </Typography>
          </FormControl>

          <TextField
            name="websiteUrl"
            value={formState.websiteUrl}
            onChange={handleInputChange}
            error={errorState?.websiteUrl}
            placeholder="Domain or path"
            className="InputStyleWrapper selectWrapper"
            helperText={errorState.websiteUrl && "Please enter valid domain or path"}
          />

          <TextField
            name="projectName"
            value={formState.projectName}
            placeholder="Project Name"
            onChange={handleInputChange}
            className="InputStyleWrapperFullWidth selectWrapper"
            error={errorState?.projectName}
            helperText={errorState.projectName && "Please enter valid project Name"}
          />
        </InputWrapper>
        <Box sx={ButtonWrapper}>
          <Button className="submitBtn" type="submit" disabled={loader}>
            {loader ? <CircularProgress className="submitLoader" /> : "Submit"}
          </Button>
        </Box>
      </Box>
    </>
  );
}

const MemoizedForm = memo(Form);
