/* eslint-disable */

import * as React from "react";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Box, Typography, styled } from "@mui/material";

const BoxWrapper = styled(Box)(({ theme }) => ({
  width: 200,
  height: 200,
  marginLeft: "auto",
  marginRight: "auto",
  "& .CircularProgressbar-path": { stroke: theme.palette.primary.main },
  "& .CircularProgressbar-text": { fill: theme.palette.text.primary, fontSize: "22px" },
}));

export default function CircularStatic({ score }) {
  return (
    <>
      <BoxWrapper>
        <CircularProgressbar
          value={score}
          text={`${score}`}
          circleRatio={0.75}
          styles={buildStyles({
            rotation: 1 / 2 + 1 / 8,
            strokeLinecap: "butt",
            trailColor: "#eee",
            path: {
              stroke: "#000",
            },
            text: {
              // Text color
              fill: "#f88 !important",
              // Text size
              fontSize: "5px",
            },
          })}
          // styles={{
          //   // Customize the root svg element
          //   root: {},
          //   // Customize the path, i.e. the "completed progress"
          //   path: {
          //     // Path color
          //     stroke: `rgba(62, 152, 199, ${percentage / 100})`,
          //     // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          //     strokeLinecap: "butt",
          //     // Customize transition animation
          //     transition: "stroke-dashoffset 0.5s ease 0s",
          //     // Rotate the path
          //     transform: "rotate(0.25turn)",
          //     transformOrigin: "center center",
          //   },
          //   // Customize the circle behind the path, i.e. the "total progress"
          //   trail: {
          //     // Trail color
          //     stroke: "#d6d6d6",
          //     // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          //     strokeLinecap: "butt",
          //     // Rotate the trail
          //     transform: "rotate(0.25turn)",
          //     transformOrigin: "center center",
          //   },
          //   // Customize the text
          //   text: {
          //     // Text color
          //     fill: "#f88",
          //     // Text size
          //     fontSize: "16px",
          //   },
          //   // Customize background - only used when the `background` prop is true
          //   background: {
          //     fill: "#3e98c7",
          //   },
          // }}
        />
      </BoxWrapper>
    </>
  );
}
