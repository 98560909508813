import { Suspense } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import { Page404 } from "./pages/Page404";
import { Spinner } from "./components/Spinner";
import { RequireUnauthenticated } from "./components/RequireUnauthenticated";
import Profile from "./pages/Profile";
import PreSignup from "./components/registerUser/preSignup";
import MyComponent from "./pages/ChatBox";
import { Scrapy } from "./components/Scrapy/Scrapy";
import ListUserWebSite from "./components/User/listUserWebSite";
import AcceptInvitation from "./components/membership/AcceptInvitation";
import Dashboard from "./components/dashboard/Dashboard";
import CreateProject from "./components/NewWebSite/newWebSite";
import "react-toastify/dist/ReactToastify.css";
import { RequireAuthenticated } from "./components/RequireAuthenticated";
import WebsiteSetting from "./pages/WebsiteSetting";
import ProjectDetail from "./components/ProjectDetail/ProjectDetail";
import ProjectDetailDataInner from "./components/ProjectDetail/ProjectDetailDataInner";
import "./style/globel.css";
import VerifyOwnership from "./components/Verify/verifywebsite";
import VerifyOwnerShipp from "./components/VerifyOwnership";
import { ThemeSettings } from "./components/theme/Theme";
import RTL from "./components/customizer/Rtl";
import AccountSetting from "./components/Theme-page/Account-setting";
import NewRegister from "./components/newregister";
// import Report from "./components/Report";
import ForgotPassword from "./components/forgot-password";
import ResetPassword from "./components/rest-password";
import TicketList from "./components/tickets";
import Notes from "./components/notes";
import Chats from "./components/chats";
import TicketAdminList from "./components/Ticket-admin";
import ChatsAdmin from "./components/Chats-admin";
import AdminAuthenticated, { UserAuthenticated } from "./components/User/AdminAuthenticated";
import UptimeMonitorSetting from "./components/uptime-monitoring/UptimeMonitorSetting";
import UptimeMonitoring from "./components/uptime-monitoring/UptimeMonitoring";
import Ecommerce from "./pages/Ecommerce";
import NewLogin from "./components/newlogin";
import LayoutHOC from "./components/NavWrapper/Layout.HOC";
import BuyPage from "./pages/BuyPage";
import SiteAuditPage from "./pages/SiteAuditPage";
import UptimeMonitoringPage from "./pages/UptimeMonitoringPage";
import CrawlLimitTable from "./components/CrawlLimit";
import PerformanceDetail from "./components/performanceDashboard/PerformanceDetail";
import WebsiteUrl from "./components/performanceDashboard/WebsiteUrlList";
import PerformanceDashboard from "./components/performanceDashboard/PerformanceDashboard";
import PerformanceChartboard from "./components/performanceDashboard/performanceChart";
import CrawlProgressReport from "./components/CrawlProgress";
import UrlKeywordinAccordion from "./components/Keywords-dashboard/UrlKeywordinAccordion";
import KeywordsDashboard from "./components/Keywords-dashboard/KeywordsDashboard";
import SignupUser from "./components/Analytic-Dashboard/SignupUser";
import Reports from "./components/Analytic-Dashboard/reports";
import TicketBoard from "./components/Analytic-Dashboard/ticketBoard";
import AllPage from "./components/Analytic-Dashboard/AllPage";
// import SelectedKeywords from "./components/Keywords-dashboard/SelectedKeywords";
// import GenerateBlogs from "./components/Keywords-dashboard/GenerateBlogs";
import GoogleTrendsDashboard from "./components/google-trends/GoogleTrendsDashboard";
import Analytic from "./components/Analytic-Dashboard";

import ScheduledReport from "./components/Analytic-Dashboard/scheduleReport";
// import AdvanceOption from "./components/Keywords-dashboard/AdvanceOption";
import ShoppifyContent from "./components/app-integration";
import UserDetailsForm from "./components/app-integration/UserDetailsForm";
import WebsiteContent from "./components/app-integration/WebsiteContent";
// import EditBlog from "./components/Keywords-dashboard/EditBlog";
import DowntimeMonitoring from "./components/uptime-monitoring/DowntimeMonitoring";
import Payment from "./components/payment/payment";
import PaymentFailed from "./components/payment/paymentFailed";
import SignUpUserProjectResister from "./components/registerUser/SignUpUserProjectResister";
import CreatProject from "./components/ProjectList/CreatProject";
import SiteAudit from "./components/ProjectList/SiteAudit";
import UpdateProjectFirst from "./components/ProjectList/index";
import PaymentSuccess from "./components/payment/PaymentSuccess";
import PaymentError from "./components/payment/paymentError";
import Report from "./components/Report";

export function App() {
  const theme = ThemeSettings();
  const customizer = useSelector(state => state.customizer);

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <LayoutHOC>
          <ToastContainer />
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route
                path="/payments"
                element={
                  <RequireAuthenticated>
                    <Payment />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/register"
                element={
                  <RequireUnauthenticated>
                    <NewRegister />
                  </RequireUnauthenticated>
                }
              />
              <Route path="*" element={<Page404 />} />
              <Route
                path="/profile"
                element={
                  <RequireAuthenticated>
                    <Profile />
                  </RequireAuthenticated>
                }
              />
              <Route path="/forgot" element={<ForgotPassword />} />
              <Route
                path="/scrapy"
                element={
                  <RequireAuthenticated>
                    <Scrapy />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/websitelist"
                element={
                  <RequireAuthenticated>
                    <ListUserWebSite />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/website"
                element={
                  <RequireAuthenticated>
                    <CreateProject />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/project-detail/:sessionId"
                element={
                  <RequireAuthenticated>
                    <ProjectDetail />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/project-inner-detail/:sessionId/:res"
                element={
                  <RequireAuthenticated>
                    <ProjectDetailDataInner />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/verify/:ownerId"
                element={
                  <RequireAuthenticated>
                    <VerifyOwnership />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <RequireAuthenticated>
                    <Dashboard />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/keywordsDashboard"
                element={
                  <RequireAuthenticated>
                    <KeywordsDashboard />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/urlKeywords/:sessionId"
                element={
                  <RequireAuthenticated>
                    <UrlKeywordinAccordion />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/performanceDashboard"
                element={
                  <RequireAuthenticated>
                    <PerformanceDashboard />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/performanceDashboard/:projectid/:sessionId/:type"
                element={
                  <RequireAuthenticated>
                    <Report />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/performanceChartboard"
                element={
                  <RequireAuthenticated>
                    <PerformanceChartboard />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/crawl-table/:authorId"
                element={
                  <RequireAuthenticated>
                    <CrawlLimitTable />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/uptime-monitoring/:projectid"
                element={
                  <RequireAuthenticated>
                    <UptimeMonitoring />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/downtime-monitoring/:projectid"
                element={
                  <RequireAuthenticated>
                    <DowntimeMonitoring />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/uptime-setting/:projectid"
                element={
                  <RequireAuthenticated>
                    <UptimeMonitorSetting />
                  </RequireAuthenticated>
                }
              />
              {/* <Route
                path="/organisationsdashboard"
                element={
                  <RequireAuthenticated>
                    <OrganisationsDashboard />
                  </RequireAuthenticated>
                }
              /> */}
              <Route
                path="/websitesetting/:ownerId"
                element={
                  <RequireAuthenticated>
                    <WebsiteSetting />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/first-project-config/site/:ownerId"
                element={
                  <RequireAuthenticated>
                    <SiteAudit />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/first-project-config"
                element={
                  <RequireAuthenticated>
                    <SignUpUserProjectResister />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/first-project-config/project"
                element={
                  <RequireAuthenticated>
                    <CreatProject />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/first-project-config/update/:ownerId"
                element={
                  <RequireAuthenticated>
                    <UpdateProjectFirst />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/first-project-config/:ownerId"
                element={
                  <RequireAuthenticated>
                    <VerifyOwnerShipp />
                  </RequireAuthenticated>
                }
              />
              {/* <Route
                path="/orgsetting/:orgId"
                element={
                  <RequireAuthenticated>
                    <OrgSetting />
                  </RequireAuthenticated>
                }
              /> */}
              {/* <Route
                path="/membership/organization"
                element={
                  <RequireAuthenticated>
                    <OrganizationForm />
                  </RequireAuthenticated>
                }
              /> */}
              <Route path="/accept-invitation/:token/:otp/:organizationId" element={<AcceptInvitation />} />
              {/* <Route path="/org">
                <Route
                  index
                  element={
                    <RequireAuthenticated>
                      <Organisations />
                    </RequireAuthenticated>
                  }
                />
                <Route
                  path=":id"
                  element={
                    <RequireAuthenticated>
                      <OrganisationDashboard />
                    </RequireAuthenticated>
                  }
                />
              </Route> */}
              <Route path="/forgetPassword/:token" element={<ResetPassword />} />
              <Route path="/chatBox" element={<MyComponent />} />
              <Route path="/preSignUp/:token" element={<PreSignup />} />
              {/* <Route path="/new-header" element={<NewHeader />} /> */}
              <Route path="/projects/:projectid/:sessionId" element={<Ecommerce />} />
              <Route path="/notes" element={<Notes />} />
              <Route path="/app-integrate/website-details/:projectId" element={<UserDetailsForm />} />
              <Route path="/app-integrate/website-urls" element={<WebsiteContent />} />
              <Route path="/app-integration" element={<ShoppifyContent />} />
              {/* <Route path="/projects/report/:type/:sessionId/:projectid" element={<Report />} /> */}
              <Route
                path="/siteAudit"
                element={
                  <RequireAuthenticated>
                    <SiteAuditPage />
                  </RequireAuthenticated>
                }
              />
              {/* <Route
                path="/websitePerformance"
                element={
                  <RequireAuthenticated>
                    <WebsitePerformance />
                  </RequireAuthenticated>
                }
              /> */}
              <Route
                path="/uptimeMonitoring"
                element={
                  <RequireAuthenticated>
                    <UptimeMonitoringPage />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/account-settings"
                element={
                  <RequireAuthenticated>
                    <AccountSetting />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/support"
                element={
                  <UserAuthenticated>
                    <TicketList />
                  </UserAuthenticated>
                }
              />
              <Route
                path="/support/chat/:id"
                element={
                  <UserAuthenticated>
                    <Chats />
                  </UserAuthenticated>
                }
              />
              <Route
                path="/tickets"
                element={
                  <AdminAuthenticated>
                    <TicketAdminList />
                  </AdminAuthenticated>
                }
              />
              <Route
                path="/tickets/chat/:id"
                element={
                  <AdminAuthenticated>
                    <ChatsAdmin />
                  </AdminAuthenticated>
                }
              />
              <Route
                path="/analytic"
                element={
                  <AdminAuthenticated>
                    <Analytic />
                  </AdminAuthenticated>
                }
              />
              <Route
                path="/users"
                element={
                  <AdminAuthenticated>
                    <SignupUser />
                  </AdminAuthenticated>
                }
              />
              <Route
                path="/reports"
                element={
                  <AdminAuthenticated>
                    <Reports />
                  </AdminAuthenticated>
                }
              />
              <Route
                path="/analyticDashboard"
                element={
                  <AdminAuthenticated>
                    <AllPage />
                  </AdminAuthenticated>
                }
              />
              <Route
                path="/ticketList"
                element={
                  <AdminAuthenticated>
                    <TicketBoard />
                  </AdminAuthenticated>
                }
              />
              <Route />
              <Route
                path="/"
                element={
                  <RequireUnauthenticated>
                    <NewLogin />
                  </RequireUnauthenticated>
                }
              />
              <Route
                path="/buypage"
                element={
                  <RequireAuthenticated>
                    <BuyPage />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/payment/success"
                element={
                  <RequireAuthenticated>
                    <PaymentSuccess />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/payment/error"
                element={
                  <RequireAuthenticated>
                    <PaymentError />
                  </RequireAuthenticated>
                }
              />{" "}
              <Route
                path="/payment/failed"
                element={
                  <RequireAuthenticated>
                    <PaymentFailed />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/websitePerformance/:projectId/:Title/:Heading/:index"
                element={
                  <RequireAuthenticated>
                    <PerformanceDetail />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/websiteurllist/:projectId/:status"
                element={
                  <RequireAuthenticated>
                    <WebsiteUrl />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/crawl-progress/:sessionId"
                element={
                  <RequireAuthenticated>
                    <CrawlProgressReport />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/googleTrends"
                element={
                  <RequireAuthenticated>
                    <GoogleTrendsDashboard />
                  </RequireAuthenticated>
                }
              />
              <Route
                path="/analytic"
                element={
                  <AdminAuthenticated>
                    <Analytic />
                  </AdminAuthenticated>
                }
              />
              <Route
                path="/users"
                element={
                  <AdminAuthenticated>
                    <SignupUser />
                  </AdminAuthenticated>
                }
              />
              <Route
                path="/reports"
                element={
                  <AdminAuthenticated>
                    <Reports />
                  </AdminAuthenticated>
                }
              />
              <Route
                path="/analyticDashboard"
                element={
                  <AdminAuthenticated>
                    <AllPage />
                  </AdminAuthenticated>
                }
              />
              <Route
                path="/ticketList"
                element={
                  <AdminAuthenticated>
                    <TicketBoard />
                  </AdminAuthenticated>
                }
              />
              <Route
                path="/scheduled-report-details/:frequency"
                element={
                  <AdminAuthenticated>
                    <ScheduledReport />
                  </AdminAuthenticated>
                }
              />
            </Routes>
          </Suspense>
        </LayoutHOC>
      </RTL>
    </ThemeProvider>
  );
}
