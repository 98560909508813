import { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { Box, Table, TableContainer, TableHead, TableRow, TableCell, Typography, TableBody, IconButton, Chip, Tooltip, TextField, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { searchTicket } from "../../redux/action";
import { deleteTicket } from "../../gql/queries/ticket";
import { usePopup } from "../../context/commonPop";
import useQuery from "../../hooks/allQuery";
import TicketAdminUpdateForm from "./TicketUpdateForm";
import { getTicketsAdmin } from "../../gql/queries/ticketadmin";
import DeletePopup from "../../common/deletePopup";

function TicketAdminListing({ ticketsFetch, setTicketsFetch }) {
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [selectedTicket, setSelectedTicket] = useState({});
  const [deleteDisable, setDeleteDisable] = useState(false);
  const [openChild, setOpenChild] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [GET_ALL_TICKETS] = useLazyQuery(getTicketsAdmin, { fetchPolicy: "network-only" });
  const [DELETE_TICKET] = useLazyQuery(deleteTicket, { fetchPolicy: "network-only" });
  const { allQuery } = useQuery();
  const { handleOpen } = usePopup();
  const navigate = useNavigate();

  const getVisibleTickets = (tickets, filter, ticketSearch) => {
    switch (filter) {
      case "total_tickets":
        return tickets?.filter(c => !c.isDeleted && c.description.toLocaleLowerCase().includes(ticketSearch));

      case "Pending":
        return tickets?.filter(c => !c.isDeleted && c.Status === "Pending" && c.description.toLocaleLowerCase().includes(ticketSearch));

      case "Closed":
        return tickets?.filter(c => !c.isDeleted && c.Status === "Closed" && c.description.toLocaleLowerCase().includes(ticketSearch));

      case "Open":
        return tickets?.filter(c => !c.isDeleted && c.Status === "Open" && c.description.toLocaleLowerCase().includes(ticketSearch));

      default:
        throw new Error(`Unknown filter: ${filter}`);
    }
  };

  const tickets = useSelector(state => getVisibleTickets(ticketsFetch, state.ticketReducer.currentFilter, state.ticketReducer.ticketSearch));
  const ticketBadge = ticket =>
    ticket.Status === "Open"
      ? theme.palette.success.light
      : ticket.Status === "Closed"
      ? theme.palette.error.light
      : ticket.Status === "Pending"
      ? theme.palette.warning.light
      : ticket.Status === "Moderate"
      ? theme.palette.primary.light
      : "primary";

  const handleDelete = useCallback(() => {
    setDeleteDisable(true);
    allQuery({
      QUERY: DELETE_TICKET,
      variables: { ticketIsDeleteId: deleteId },
      type: "delete",
      cacheQueryData: {
        cacheQuery: getTicketsAdmin,
      },
      onCompleteFunc: () => {
        setDeleteDisable(false);
        handleOpen(true, "Ticket deleted successfully");
      },
      onErrorFunc: error => {
        console.log("error", error);
        setDeleteDisable(false);
      },
    });
    setOpenChild(false);
  }, [setDeleteDisable, deleteId, getTicketsAdmin, setOpenChild, allQuery, DELETE_TICKET]);

  const handleOpenPopup = id => {
    setDeleteId(id);
    setOpenChild(true);
  };

  useEffect(() => {
    allQuery({
      QUERY: GET_ALL_TICKETS,
      onCompleteFunc: ({ getTickets }) => {
        setTicketsFetch(getTickets);
      },
    });
  }, []);
  return (
    <Box mt={4}>
      <DeletePopup open={openChild} setOpen={setOpenChild} handleDelete={handleDelete} msg="ticket" />
      <Box sx={{ maxWidth: "260px", ml: "auto" }} mb={3}>
        <TextField size="small" label="Search by description" fullWidth onChange={e => dispatch(searchTicket(e.target.value))} />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6">Id</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Ticket</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Source</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Image</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Status</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Date</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6">Action</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets?.map(ticket => (
              <TableRow key={ticket.ticketId} hover>
                <TableCell
                  sx={{ cursor: "pointer", color: theme.palette.primary.main }}
                  onClick={() => {
                    navigate(`/tickets/chat/${ticket.ticketId}`);
                  }}
                >
                  {ticket.ticketId}
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography variant="h6" fontWeight={600} noWrap>
                      {ticket.type}
                    </Typography>
                    <Typography color="textSecondary" noWrap sx={{ maxWidth: "250px" }} variant="subtitle2" fontWeight={400}>
                      {ticket.description}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{ticket.isDesktop ? "Desktop App" : "Web App"}</Typography>
                </TableCell>
                <TableCell>
                  <img src={ticket?.image} alt="not found" width={35} height={35} />
                </TableCell>
                <TableCell>
                  <Chip
                    sx={{
                      backgroundColor: ticketBadge(ticket),
                    }}
                    size="small"
                    label={ticket.Status}
                  />
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{format(new Date(ticket.createdAt), "E, MMM d")}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Edit Ticket">
                    <IconButton
                      onClick={() => {
                        setSelectedTicket(ticket);
                        setOpen(true);
                      }}
                    >
                      <EditIcon size="18" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Ticket">
                    <IconButton
                      disabled={deleteDisable}
                      onClick={() => {
                        handleOpenPopup(ticket.ticketId);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TicketAdminUpdateForm open={open} setOpen={setOpen} selectedTicket={selectedTicket} />
    </Box>
  );
}

export default TicketAdminListing;
