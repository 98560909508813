import { Backdrop, Box, Button, Fade, Modal, Typography, styled, useTheme } from "@mui/material";
import { createContext, useContext, useMemo, useState } from "react";
import SuccessIcon from "../Images/SuccessIcon.gif";
import ErrorGif from "../Images/errorGif.gif";
import WarningGif from "../Images/warninggif.gif";
import { popupType } from "../common/constant";

const BoxWrapperstyle = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  backgroundColor: theme.palette.background.default,
  padding: "30px 35px 34px",
  borderRadius: "15px",
  textAlign: "center",
  "@media screen and (max-width:600px)": {
    width: "90%",
  },
  "& h3": {
    color: theme.palette.text.primary,
    fontWeight: 600,
    margin: "15px 0",
    border: "1px solid #00000014",
    padding: "10px 0",

    "& .inprogress": {
      color: "green",
      fontSize: "10px",
    },
  },
  "& img": {
    width: "50px",
    mb: "10px",
  },
  "& h2": { fontSize: "18px", fontWeight: "600", color: theme.palette.text.primary, marginBottom: "10px" },
  "& p": { fontSize: "16px", marginBottom: "18px", color: theme.palette.text.primary, fontWeight: "600" },
  "& button": {
    boxShadow: "none",
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    borderRadius: "7px",
    // border: "1px solid #ff642d",
    padding: "6px 24px 6px",
    width: "100%",
    fontSize: "16px",
    "&.ErrorBtn": {
      backgroundColor: "#ff0000",
      color: "#ffffff",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "none",
      color: "#fff",
    },
    "&:focus": {
      outline: "none",
    },
  },
}));

const PopupContext = createContext();

function Popupprovider({ children }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [newPopUpState, setNewPopUpState] = useState({ isOpen: false, type: popupType.success, contentFunc: () => "" });
  const [message, setMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
    setNewPopUpState({ isOpen: false, type: popupType.success, contentFunc: () => "" });
  };

  const handleOpen = (op, msg) => {
    setOpen(op);
    setMessage(msg);
  };

  const customPopupModel = ({ isOpen = true, type = popupType.success, contentFunc = () => "" }) => {
    setNewPopUpState({ isOpen, type, contentFunc });
  };
  const contextData = useMemo(() => ({ handleOpen, customPopupModel }), []);

  return (
    <PopupContext.Provider value={contextData}>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <BoxWrapperstyle>
            <Typography component="p">{message}</Typography>
            <Button variant="contained" className="okButton" onClick={handleClose} sx={{ backgroundColor: theme.palette.primary.main }}>
              ok
            </Button>
          </BoxWrapperstyle>
        </Fade>
      </Modal>
      <Modal
        open={newPopUpState?.isOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={newPopUpState?.isOpen}>
          <BoxWrapperstyle>
            {newPopUpState.type === popupType.success ? <Typography component="img" src={SuccessIcon} alt="successGif" /> : ""}
            {newPopUpState.type === popupType.failed ? <Typography component="img" src={ErrorGif} alt="ErrorGif" /> : ""}
            {newPopUpState.type === popupType.warning ? <Typography component="img" src={WarningGif} alt="WarningGif" /> : ""}
            {newPopUpState?.contentFunc}
            <Button variant="contained" className="okButton" onClick={handleClose} sx={{ backgroundColor: theme.palette.primary.main }}>
              ok
            </Button>
          </BoxWrapperstyle>
        </Fade>
      </Modal>
      {children}
    </PopupContext.Provider>
  );
}

export default Popupprovider;

export const usePopup = () => useContext(PopupContext);
