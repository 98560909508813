export const validateString = data => {
  if (!/\S/.test(data)) {
    return true;
  }
  return false;
};
export const validatePositiveNumber = data => {
  // /^\d*[1-9]\d*$/
  const regex = /^[1-9]\d*$/;

  if (!regex.test(data)) {
    return true;
  }

  return false;
};
export const validateEmail = data => {
  // eslint-disable-next-line
  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data)) {
    return true;
  }
  return false;
};

export const validateContact = data => {
  if (!data.match(/^\d{10}$/)) {
    return true;
  }
  return false;
};

export const validateDate = data => {
  if (new Date(data)?.toString() === "Invalid Date") {
    return true;
  }
  const dobtest = new Date(data).getTime();
  if (dobtest > new Date().getTime()) {
    return true;
  }
  if (new Date().getFullYear() - new Date(data).getFullYear() > 100) {
    return true;
  }
  return false;
};

export const validatePassword = data => {
  if (!data.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/)) {
    return true;
  }
  return false;
};
