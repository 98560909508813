/* eslint-disable */
import { useEffect, useState } from "react";
import moment from "moment";
import { Box } from "@mui/system";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import { useLazyQuery } from "@apollo/client";
import { ALL_USER, GET_ALL_USER_GRAPH_VALUE, ALL_USER_TABLE_DATA, UserBasisFindCrawlimt } from "../gql/queries/users";
import ImageNotFound from "../Images/image-not-found-icon.png";
import { usePopup } from "../context/commonPop";

export const GetUserWebsiteCrawlData = () => {
  const [ProjectData, setProjectData] = useState({ dataArr: [], totalUsedCrawl: 0 });
  const [search, setSearch] = useState(null);
  const [totalUsedCrawls, setTotalUsedCrawls] = useState(0);
  const { handleOpen } = usePopup();

  const [getCrawlLimit] = useLazyQuery(UserBasisFindCrawlimt, { fetchPolicy: "cache-first" });

  function getTableData() {
    getCrawlLimit({
      variables: {
        searchString: search,
      },
      onCompleted: ({ GetAuthorBasisCrawlLimit }) => {
        // eslint-disable-next-line
        let dataArr = [];
        if (GetAuthorBasisCrawlLimit && GetAuthorBasisCrawlLimit) {
          // eslint-disable-next-line
          GetAuthorBasisCrawlLimit.map((project, ind) => {
            const createdDate = project?.createdAt ? new Date(project?.createdAt) : null;

            const formattedDate = createdDate ? moment(createdDate).format("DD-MM-YYYY hh:mm A") : "";
            dataArr.push({
              index: ind + 1 || 0,
              Projects: {
                name: project.project,
                img: project?.imageUrl && project?.imageUrl ? project?.imageUrl : ImageNotFound,
                website: project?.website || 0,
              },
              id: project?.id,
              createdDate: formattedDate,
              CrawlCount: project?.usedCrawl,
              sessionId: project?.sessionId,
              status: project?.isFailed
                ? {
                    status: "Failed",
                    statusIcon: (
                      <Box component="span" sx={{ color: "red" }}>
                        <ClearIcon />
                      </Box>
                    ),
                  }
                : project?.isCompleted
                ? {
                    status: "Completed",
                    statusIcon: (
                      <Box component="span" sx={{ color: "green" }}>
                        <CheckIcon />
                      </Box>
                    ),
                  }
                : project?.basicCompleted || !project?.isCompleted
                ? {
                    status: "Progress",
                    statusIcon: (
                      <Box component="span" sx={{ color: "yellow" }}>
                        <RunningWithErrorsIcon />
                      </Box>
                    ),
                  }
                : {
                    status: "N/A",
                    statusIcon: (
                      <Box component="span" sx={{ color: "red" }}>
                        <ClearIcon />
                      </Box>
                    ),
                  },
            });
          });
        }
        // eslint-disable-next-line
        const totalUsedCrawl = GetAuthorBasisCrawlLimit.reduce((accumulator, project) => {
          return accumulator + (project?.usedCrawl || 0);
        }, 0);
        totalUsedCrawl > totalUsedCrawls && setTotalUsedCrawls(totalUsedCrawl);
        setProjectData({ dataArr });
      },
      onError: err => {
        console.log("getCrawlLimit", err);
        handleOpen(true, err);
      },
    });
  }
  useEffect(() => {
    getTableData();
  }, [search]);

  return { ProjectData, setSearch, totalUsedCrawls };
};
function createSection(arr, key) {
  try {
    const dataPoints = arr.map(user => {
      const createdAt = new Date(user[key]);
      const date = createdAt.toLocaleDateString();
      const time = createdAt.getHours() + createdAt.getMinutes() / 60;
      return { date, time, name: user.name };
    });
    const seriesData = dataPoints.map(data => ({
      x: `${data.date} ${Math.floor(data.time)}:${String(Math.round((data.time % 1) * 60)).padStart(2, "0")}`,
      y: data.time,
      name: data.name,
    }));
    return seriesData;
  } catch (err) {
    console.log("err", err);
    return null;
  }
}

export const useAllUserDetails = (startDate, endDate) => {
  const [signupTableData, setSignupTableData] = useState([]);
  const [signInTableData, setSignInTableData] = useState([]);
  const [loginUserData, setLoginUserData] = useState([]);
  const [uniqueDate, setUniqueDate] = useState([]);
  const [createUser, setCreateUser] = useState([]);
  const [isToggle, setIsToggle] = useState(false);
  const [userCounts, setUserCounts] = useState(0);
  const [loading, setLoading] = useState(true);

  const [alluser] = useLazyQuery(ALL_USER, {
    fetchPolicy: "network-and-cache",
  });

  const getUser = () =>
    alluser({
      variables: {
        timeStamp: {
          endDate: endDate || null,
          startDate: startDate || null,
        },
        //     .filter((user, index, self) => index === self.findIndex(u => u.email === user.email))
        //     .map((userData, index) => ({ ...userData, id: index + 1 })),
        // );
        // console.log({ finalSeriesData, finalUpdatedSeriesData });
        // setUserCounts(usersCount);
        // setCreateUser(finalSeriesData);
        // setLoginUserData(finalUpdatedSeriesData);
        // setLoading(false);
      },
      onError: err => {
        console.log(err);
        setLoading(false);
      },
    });

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  return {
    allUserData: isToggle ? signInTableData : signupTableData,
    loading,
    userCounts,
    getUser,
    loginUserData,
    uniqueDate,
    createUser,
    setIsToggle,
    isToggle,
    loginUserCount: signInTableData ? signInTableData.length : 0,
  };
};

export const useAllUserGraphValue = (startDate, endDate) => {
  const [usersData, setUsersData] = useState({ updatedSeriesData: [], seriesData: [], uniqueDateArray: [] });

  const [allUsersQuery] = useLazyQuery(GET_ALL_USER_GRAPH_VALUE, {
    fetchPolicy: "network-and-cache",
    onCompleted: ({ getAllUserGraphValueForAdminId }) => {
      if (Array.isArray(getAllUserGraphValueForAdminId) && getAllUserGraphValueForAdminId.length === 0) {
        setUsersData({ updatedSeriesData: [], seriesData: [], uniqueDateArray: [] });
      } else {
        setUsersData(getAllUserGraphValueForAdminId);
      }
    },
    onError: err => {
      console.error(err);
      // Handle error (e.g., show a notification)
    },
  });

  const getGraphData = async () => {
    await allUsersQuery({
      variables: {
        timeStamp: {
          startDate,
          endDate,
        },
      },
    });
  };

  useEffect(() => {
    getGraphData();
    // eslint-disable-next-line
  }, []);

  return { usersData, getGraphData };
};

export const useAllUserTableDetails = (startDate, endDate) => {
  const [signupTableData, setSignupTableData] = useState([]);
  const [signInTableData, setSignInTableData] = useState([]);
  const [updateAtPage, setUpdateAtPage] = useState({ UsersCount: 0, totalPage: 0, currentpage: 1 });
  const [createAtPage, setCreateAtPage] = useState({ UsersCount: 0, totalPage: 0, currentpage: 1 });
  const [isToggle, setIsToggle] = useState(false); // toggle between data
  const [userCounts, setUserCounts] = useState(0);
  const [loginCounts, setLoginCounts] = useState(0);
  const [currentCreatePage, setCurrentCreatePage] = useState(1);
  const [currentLoginPage, setCurrentLoginPage] = useState(1);
  const [loading, setLoading] = useState(true);

  // Lazy query for user data
  const [alluser] = useLazyQuery(ALL_USER_TABLE_DATA, {
    fetchPolicy: "network-and-cache",
  });

  // Function to fetch data based on tab (createdAt or updatedAt)
  const fetchData = async tabData => {
    return alluser({
      variables: {
        timeStamp: {
          endDate: endDate || null,
          startDate: startDate || null,
        },
        page: 1,
        tabData,
      },
    });
  };

  const getDataToggleBase = async (tabData, page) => {
    return alluser({
      variables: {
        timeStamp: {
          endDate: endDate || null,
          startDate: startDate || null,
        },
        page,
        tabData,
      },
    });
  };

  // Function to fetch both `createdAt` and `updatedAt` data when component mounts
  const fetchInitialData = async () => {
    // setLoading(true);
    try {
      // const [createdAtData, updatedAtData] = await Promise.all([
      //   fetchData("createdAt"), // Fetch createdAt data
      //   fetchData("updatedAt"), // Fetch updatedAt data
      // ]);
      const createdAtData = await fetchData("createdAt");
      const updatedAtData = await fetchData("updatedAt");
      const createdAtResponse = createdAtData?.data?.getAdminTableData || {};
      const updatedAtResponse = updatedAtData?.data?.getAdminTableData || {};
      // Set data for createdAt
      setSignupTableData(createdAtResponse.users || []);
      setCreateAtPage(createdAtResponse.pageObject || {});

      // Set data for updatedAt
      setSignInTableData(updatedAtResponse.users || []);
      setUpdateAtPage(updatedAtResponse.pageObject || {});

      // Set user counts (assuming you want to display total users based on createdAt)
      setUserCounts(createdAtResponse.usersCount || 0);
      setLoginCounts(updatedAtResponse.LoginCountCount || 0);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch data based on toggle state
  const getUser = async () => {
    // setLoading(true);
    try {
      const { data } = await getDataToggleBase(isToggle ? "updatedAt" : "createdAt", isToggle ? currentLoginPage : currentCreatePage);
      const { users, pageObject, usersCount } = data?.getAdminTableData || {};

      if (isToggle) {
        setSignInTableData(users);
        setUpdateAtPage(pageObject);
      } else {
        setSignupTableData(users);
        setCreateAtPage(pageObject);
      }

      setUserCounts(usersCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Run fetchInitialData on component mount to get both `createdAt` and `updatedAt` data
  useEffect(() => {
    fetchInitialData();
    // eslint-disable-next-line
  }, []);

  // Refetch data when pageNumber, startDate, endDate, or isToggle changes
  useEffect(() => {
    if (currentCreatePage || currentLoginPage || startDate || endDate) {
      getUser();
    }
    // eslint-disable-next-line
  }, [currentLoginPage, currentCreatePage, startDate, endDate, isToggle]);

  return {
    allUserData: isToggle ? signInTableData : signupTableData,
    loading,
    userCounts,
    getUser,
    setIsToggle,
    isToggle,
    loginUserCount: signInTableData ? signInTableData?.length : 0,
    paginationDetail: isToggle ? updateAtPage : createAtPage,
    loginCounts,
    currentCreatePage,
    setCurrentCreatePage,
    currentLoginPage,
    setCurrentLoginPage,
  };
};

// export const useAllUserDetails = (startDate, endDate) => {
//   const [allUserData, setAllUserData] = useState([]);
//   const [userCounts, setUserCounts] = useState(0);
//   const [loading, setLoading] = useState(true);

//   const [alluser] = useLazyQuery(ALL_USER, {
//     fetchPolicy: "network-and-cache",
//   });

//   const getUser = () =>
//     alluser({
//       variables: {
//         timeStamp: {
//           endDate: endDate || null,
//           startDate: startDate || null,
//         },
//       },
//       onCompleted: ({ getAllUserBySuperAdmin }) => {
//         const { users, usersCount } = getAllUserBySuperAdmin || {};
//         setAllUserData(users.map((userData, index) => ({ ...userData, id: index + 1 })));
//         setUserCounts(usersCount);
//         setLoading(false);
//       },
//       onError: err => {
//         console.log(err);
//         setLoading(false);
//       },
//     });

//   useEffect(() => {
//     getUser();
//     // eslint-disable-next-line
//   }, []);

//   return { allUserData, loading, userCounts, getUser };
// };
