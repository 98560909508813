/*eslint-disable */
import { Box, Card, Container, Grid, Typography, styled, useTheme } from "@mui/material";
import shopify from "../../Images/app-integration/shopify.png";
import wordpress from "../../Images/app-integration/wordpress.png";
import kajabi from "../../Images/app-integration/kajabi.png";
import Magento from "../../Images/app-integration/magento.png";
import Wix from "../../Images/app-integration/wix.png";
import Squarespace from "../../Images/app-integration/squarespace.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const SectionWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: "calc(100vh - 64px)",
  "& .MainBoxWrapper": {
    "& .ThumbWrapper": {
      textAlign: "center",
      marginBottom: "25px",
      "& img": {
        width: "100px",
        height: "auto",
        borderRadius: "10px",
      },
    },
    "& .MainTitle": {
      textAlign: "center",
      fontSize: "18px",
      fontWeight: 700,
    },
  },
}));

const ShoppifyContent = () => {
  const theme = useTheme();
  const customizer = useSelector(state => state.customizer);
  const borderColor = theme.palette.divider;
  const navigate = useNavigate();
  const handleNavigateClick = async tool => {
    const pathname = `/app-integrate/website-urls`;
    const state = {
      toolName: tool.name,
    };
    navigate(pathname, { state });
  };
  const toolsList = [
    {
      name: "Shopify",
      image: shopify,
    },
    {
      name: "Wordpress",
      image: wordpress,
    },
    // {
    //   name: "Kajabi",
    //   image: kajabi,
    // },
    {
      name: "Magento",
      image: Magento,
    },
    // {
    //   name: "Wix",
    //   image: Wix,
    // },
    // {
    //   name: "Squarespace",
    //   image: Squarespace,
    // },
  ];

  return (
    <SectionWrapper>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3} mt={0}>
            {toolsList?.map((tool, i) => (
              <Grid item xs={12} md={3}>
                <Card
                  sx={{ padding: "50px 40px 50px 40px", border: customizer.isCardShadow ? `1px solid ${borderColor}` : "none", height: "100%", cursor: "pointer" }}
                  elevation={customizer.isCardShadow ? 9 : 0}
                  variant={!customizer.isCardShadow ? "outlined" : undefined}
                  onClick={() => handleNavigateClick(tool)}
                >
                  <AppContent tool={tool} key={tool._id} />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

export default ShoppifyContent;

const AppContent = ({ tool }) => {
  return (
    <Box className="MainBoxWrapper ">
      <Box className="ThumbWrapper">
        <Typography component="img" src={tool.image} alt="" />
      </Box>
      <Typography className="MainTitle" component="h3">
        {tool?.name}
      </Typography>
    </Box>
  );
};
